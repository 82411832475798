import React from 'react';
import {AthleteModalCardRoute} from '../../../../module/auth/component/athlete/athlete-modal-card.component';
import {IRoute} from '../../../../core/routing';
import {createAccountManageRelationRoute} from '../../../../module/auth/component/account/account-manage-relation-routes.component';
import {
  CoachingZoneAthleteSearchModal,
  CoachingZoneAthleteSeatSearchModal
} from '../../../../module/auth/component/athlete/athlete-seat-search-modal.component';
import {IAuthUserAccount} from '../../../../module/auth';
import {useAuthAccount} from '../../../../module/auth/auth.store';

const ownPath = '/portal/trainer/athletes';

export const CoachingZoneAthletesRoute = createAccountManageRelationRoute(({navigate, translate}) => {
  const t = translate;
  // const goToShop = useShopRedirect();
  const acc = useAuthAccount();
  const hasAthletes = (acc?.athleteCount || 0) > 0;

  const regularSegments = [
    {
      type: 'athlete-card-grid',
      data: {
        title: t('app.account.section.abo-athletes'),
        // actions: [
        //   {
        //     label: t('app.shop.abo.action.manage'),
        //     color: UiColor.accent,
        //     interact: () => goToShop()
        //   }
        // ]
      }
    },
    // {
    //   type: 'athlete-onboarding-tooltips',
    // },
    {
      type: 'athlete-tile-listing',
      title: t('app.account.section.athletes'),
      data: {
        actionTile: {
          title: t('app.account.action.add-relationship.label'),
          icon: 'plus',
          type: 'navigate',
          path: `${ownPath}/search`
        }
      }
    }
  ];

  const connectionlessSegments = [
    {
      type: 'trainer-no-connections-interstitial',
      data: {
        ownPath,
        searchPath: `${ownPath}/search`,
      }
    }
  ];

  return {
    ownPath,
    accountDetailsPath: (acc?: IAuthUserAccount) => acc
      ? `${ownPath}/details/${acc.id}`
      : undefined,
    hideOverflow: !hasAthletes,
    segments: hasAthletes
      ? regularSegments
      : connectionlessSegments,
    modalRoutes: [
      {
        path: `${ownPath}/search`,
        render: (route: IRoute) => {
          return <CoachingZoneAthleteSearchModal
            onClose={() => navigate(ownPath)}
            onAthleteClick={acc => navigate(`${ownPath}/details/${acc.id}`)}
            {...route as any} />
        }
      },
      {
        path: `${ownPath}/seats/search`,
        render: (route: IRoute) => {
          return <CoachingZoneAthleteSeatSearchModal
            onClose={() => navigate(ownPath)}
            onAthleteClick={acc => navigate(`${ownPath}/details/${acc.id}`)}
            {...route as any} />
        }
      },
      {
        path: `${ownPath}/details/:accountId`,
        render: (route: IRoute) => {
          const {match} = route;
          const id = match?.params?.accountId;

          return <AthleteModalCardRoute
            ownPath={`${ownPath}/details/${id}`}
            onClose={() => navigate(ownPath)}
            {...route as any} />
        }
      }
    ]
  } as any;
});

