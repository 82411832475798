import {
  IHttpRequest,
  IHttpRequestHookOptions,
  IPaginatedHttpRequestHookOptions,
  Page,
  toast,
  useApiEndpoint,
  useHttpRequest,
  usePaginatedHttpRequest,
  useTranslator
} from '@bitsolve/react-common';
import {Rooq} from '../../core/domain.model';
import {IAuthUserAccount} from '../auth';
import {ErrorHandler, paginatedOptions, paginator, SuccessHandler, useDeleteConfirmation} from '../../core/api';
import {ITraining, ITrainingInvitation, ITrainingStatus} from './training.model';
import {AxiosError, AxiosResponse} from 'axios';
import {API_PREFIX} from '../../core/constants';
import {isNonEmptyStr} from '@bitsolve/fns';
import {useMemo} from 'react';


const baseUrl = `${API_PREFIX}/v1/account`;
const internalUrl = `${API_PREFIX}/v1/internal`;

export const findMyTrainingsRequest = (filter: Rooq.TrainingType = Rooq.TrainingType.ATHLETE, page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/me/training`,
  params: {
    page: page || 0,
    pageSize: page || 10,
    filter
  }
});

export const findTrainingsRequest = (accountId: string, trainingType: Rooq.TrainingType = Rooq.TrainingType.ATHLETE, page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${accountId}/trainings`,
  params: {
    page: page || 0,
    pageSize: page || 10,
    filter: trainingType
  }
});

export const getTrainingStatusRequest = (accountId: string, requestedAs?: Rooq.TrainingType): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/me/training/status`,
  params: {
    accountId,
    requestedAs: requestedAs || Rooq.TrainingType.TRAINER
  }
});

export const deleteTrainingRequest = (trainingId: string): IHttpRequest => ({
  method: 'delete',
  url: `${baseUrl}/me/training/${trainingId}`,
});

export const deleteInternalTrainingRequest = (trainingId: string): IHttpRequest => ({
  method: 'delete',
  url: `${internalUrl}/training`,
  params: {trainingId}
});

export const findMyTrainingCandidatesRequest = (requestedAs: Rooq.TrainingType = Rooq.TrainingType.ATHLETE,
                                                search?: string,
                                                page?: number,
                                                pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/me/training/candidates`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    requestedAs,
    search
  }
});

export const findMyTrainingSessionFilterCandidatesRequest = (page: number = 0,
                                                             pageSize: number = 100): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/session/trainer/athletes`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
  }
});

export const findInternalTrainingCandidatesRequest = (
  accountId: string,
  requestedAs: Rooq.TrainingType = Rooq.TrainingType.ATHLETE,
  search?: string,
  page?: number,
  pageSize?: number
): IHttpRequest => ({
  method: 'get',
  url: `${internalUrl}/training/candidates`,
  params: {
    accountId,
    page: page || 0,
    pageSize: pageSize || 10,
    requestedAs,
    search: isNonEmptyStr(search) ? search : undefined
  }
});

export const findTrainingInvitationsRequest = (requestedAs: Rooq.TrainingType = Rooq.TrainingType.ATHLETE, page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/me/training/invitation`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    requestedAs
  }
});

export const createTrainingInvitationRequest = (invitedAccountId: string, invitedAs: Rooq.InvitationType): IHttpRequest => ({
  method: 'post',
  url: `${baseUrl}/${invitedAccountId}/training/invitation`,
  data: {invitedAs}
});

export interface ITrainingCreateInternal {
  invitingAccountId: string;
  athleteId: string;
  trainerId: string;
}

export const createInternalTrainingRequest = (invitingAccountId: string, athleteId: string, trainerId: string): IHttpRequest => ({
  method: 'post',
  url: `${internalUrl}/training`,
  data: {invitingAccountId, athleteId, trainerId}
});

export const updateTrainingInvitationRequest = (invitationId: string, data: { status: Rooq.InvitationStatus }): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}/me/training/invitation/${invitationId}`,
  data
});

export const deleteTrainingInvitationRequest = (invitationId: string): IHttpRequest => ({
  method: 'delete',
  url: `${baseUrl}/me/training/invitation/${invitationId}`,
});

export const useFindMyTrainings = (filter?: Rooq.TrainingType, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<ITraining>>(
    findMyTrainingsRequest(filter || Rooq.TrainingType.ATHLETE),
    {paginator, initialPagination: {page: 0, size: 10}, ...opts}
  );

export const useFindTrainings = (accountId: string, filter?: Rooq.TrainingType, page?: number, pageSize?: number, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<ITraining>>(
    findTrainingsRequest(accountId, filter || Rooq.TrainingType.ATHLETE, page || 0, pageSize || 10),
    paginatedOptions(page || 0, pageSize || 10, opts),
  );

export const useGetTrainingStatus = (accountId: string, requestedAs: Rooq.TrainingType = Rooq.TrainingType.TRAINER, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<ITrainingStatus>(
    getTrainingStatusRequest(accountId, requestedAs),
    {initialFetch: true, ...opts}
  );

export const useDeleteTrainingEndpoint = <T = ITraining>(onSuccess?: SuccessHandler<T>,
                                                         onError?: ErrorHandler) =>
  useApiEndpoint<string, T>(
    deleteTrainingRequest,
    onSuccess,
    onError
  );

export const useDeleteInternalTrainingEndpoint = <T = ITraining>(onSuccess?: SuccessHandler<T>,
                                                                 onError?: ErrorHandler) =>
  useApiEndpoint<string, T>(
    deleteInternalTrainingRequest,
    onSuccess,
    onError
  );

export const useDeleteTraining = <T = ITraining>(onSuccess?: SuccessHandler<T>,
                                                 onError?: ErrorHandler) =>
  useDeleteConfirmation<T>({
    endpoint: useDeleteTrainingEndpoint() as any,
    onSuccess,
    onError,
    title: 'app.account.action.delete-relationship.confirm.title',
    message: 'app.account.action.delete-relationship.confirm.message'
  });

export const useDeleteInternalTraining = <T = ITraining>(onSuccess?: SuccessHandler<T>,
                                                         onError?: ErrorHandler) =>
  useDeleteConfirmation<T>({
    endpoint: useDeleteInternalTrainingEndpoint() as any,
    onSuccess,
    onError,
    title: 'Verbindung auflösen',
    message: 'Bist du sicher, dass du die Trainingsverbindung auflösen möchtest? Diese Aktion kann nicht rückgängig gemacht werden.'
  });

export const useFindTrainingCandidates = (initialSearch?: string, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IAuthUserAccount>>(
    findMyTrainingCandidatesRequest(Rooq.TrainingType.TRAINER, initialSearch),
    {paginator, initialPagination: {page: 0, size: 10}, ...opts}
  );

export const useFindTrainingSessionFilterCandidates = (page: number = 0, pageSize: number = 100) =>
  usePaginatedHttpRequest<Page<IAuthUserAccount>>(
    findMyTrainingSessionFilterCandidatesRequest(),
    paginatedOptions(page, pageSize, {initialFetch: true}),
  );


export const useFindInternalTrainingCandidates = (accountId: string, requestedAs: Rooq.TrainingType, initialSearch?: string, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IAuthUserAccount>>(
    findInternalTrainingCandidatesRequest(accountId, requestedAs, initialSearch),
    {...paginatedOptions(0, 10), ...opts}
  );

export const useFindTrainingInvitations = (filter?: Rooq.TrainingType, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<{ elements: ITrainingInvitation[]; }>(
    findTrainingInvitationsRequest(filter || Rooq.TrainingType.TRAINER, 0, 100),
    {paginator, initialPagination: {page: 0, size: 100}, ...opts}
  );

export const useCreateTrainingInvitationEndpoint = <T = ITrainingInvitation>(invitedAs: Rooq.InvitationType,
                                                                             onSuccess?: (res: AxiosResponse<T>) => any,
                                                                             onError?: (err: AxiosError) => any) =>
  useApiEndpoint<string, T>(
    (invitedAccount) => createTrainingInvitationRequest(invitedAccount, invitedAs),
    onSuccess,
    onError
  );

export const useCreateInternalTrainingEndpoint = <T = ITraining>(onSuccess?: (res: AxiosResponse<T>) => any,
                                                                 onError?: (err: AxiosError) => any) =>
  useApiEndpoint<ITrainingCreateInternal, T>(
    (data) => createInternalTrainingRequest(data.invitingAccountId, data.athleteId, data.trainerId),
    onSuccess,
    onError
  );

export const useCreateTrainingInvitation = <T = ITrainingInvitation>(invitedAs: Rooq.InvitationType,
                                                                     onSuccess?: (res: AxiosResponse<T>) => any,
                                                                     onError?: (err: AxiosError) => any) =>
  useDeleteConfirmation<ITraining, any>({
    endpoint: useCreateTrainingInvitationEndpoint<T>(invitedAs, onSuccess, onError) as any,
    title: 'app.account.action.add-relationship.confirm.title',
    message: 'app.account.action.add-relationship.confirm.message',
  });


export const useCreateInternalTraining = <T = ITraining>(onSuccess?: (res: AxiosResponse<any>) => any,
                                                         onError?: (err: AxiosError) => any) =>
  useDeleteConfirmation<T, ITrainingCreateInternal>({
    endpoint: useCreateInternalTrainingEndpoint<ITrainingCreateInternal>(onSuccess, onError) as any,
    title: 'Trainingsverbindung bestätigen',
    message: 'Möchtest du diese beiden Accounts verbinden?',
  });

export const useUpdateTrainingInvitationEndpoint = <T = ITrainingInvitation>(onSuccess?: (res: AxiosResponse<T>) => any,
                                                                             onError?: (err: AxiosError) => any) =>
  useApiEndpoint<{ invitationId: string; status: Rooq.InvitationStatus; }, T>(
    (payload) => updateTrainingInvitationRequest(payload.invitationId, {status: payload.status}),
    onSuccess,
    onError
  );

export const useUpdateTrainingInvitation = (onSuccess?: SuccessHandler,
                                            onError?: ErrorHandler) =>
  useDeleteConfirmation<any, { invitationId: string; status: Rooq.InvitationStatus; }>({
    endpoint: useUpdateTrainingInvitationEndpoint(onSuccess, onError),
    title: 'app.account.action.update-relationship.confirm.title',
    message: 'app.account.action.update-relationship.confirm.message',
  });

export const useDeleteTrainingInvitationEndpoint = <T = ITrainingInvitation>(
  onSuccess?: SuccessHandler,
  onError?: ErrorHandler
) =>
  useApiEndpoint<string, T>(
    deleteTrainingInvitationRequest,
    onSuccess,
    onError
  );

export const useDeleteTrainingInvitation = (
  onSuccess?: SuccessHandler,
  onError?: ErrorHandler
) =>
  useDeleteConfirmation({
    endpoint: useDeleteTrainingInvitationEndpoint(onSuccess, onError),
    title: 'general.confirm.title',
    message: 'general.confirm.message',
    confirmLabel: 'general.confirm.action.confirm',
    cancelLabel: 'general.confirm.action.cancel'
  });


export const useAthleteInvitationActions = (callbacks?: {
  onCreated?: Function;
  onUpdated?: Function;
  onDeleted?: Function;
  onAny?: Function;
}) => {
  const {onCreated, onDeleted, onUpdated, onAny} = callbacks || {};
  const t = useTranslator();

  const createInvitation = useCreateTrainingInvitation(Rooq.InvitationType.ATHLETE,
    (result) => {
      toast.success(t('app.account.action.add-relationship.toast.success'));
      onCreated && onCreated(result as any);
      onAny && onAny(result as any);
    },
    () => {
      toast.error(t('app.account.action.add-relationship.toast.error'));
    });

  const updateInvitation = useUpdateTrainingInvitation(
    (result) => {
      toast.success(t('app.account.action.update-relationship.toast.success'));
      onUpdated && onUpdated(result as any);
      onAny && onAny(result as any);
    },
    () => {
      toast.error(t('app.account.action.update-relationship.toast.error'));
    }
  );

  const deleteInvitation = useDeleteTrainingInvitation(
    (result) => {
      toast.success(t('app.account.action.delete-relationship-invite.toast.success'));
      onDeleted && onDeleted(result as any);
      onAny && onAny(result as any);
    },
    () => {
      toast.error(t('app.account.action.delete-relationship-invite.toast.error'));
    }
  )

  return useMemo(
    () => ({createInvitation, updateInvitation, deleteInvitation}),
    [createInvitation, updateInvitation, deleteInvitation]
  );
};
