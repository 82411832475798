export enum Connectivity {
  UNKNOWN = 'UNKNOWN',
  REACHABLE = 'REACHABLE',
  UNREACHABLE = 'UNREACHABLE',
}

export interface IBackendHealth {
  connectivity: Connectivity;
  busy: boolean;
  lastCheck: number | undefined;
  numChecks: number;
}


export enum IMaintenanceNotificationType {
  version_unsupported = 'version_unsupported',
  service_interruption = 'service_interruption',
  scheduled_maintenance = 'scheduled_maintenance',
  unscheduled_maintenance = 'unscheduled_maintenance',
}

export interface IMaintenanceNotification {
  message: IMaintenanceNotificationType;
  locked: boolean;
  dateFrom?: number;
  dateTo?: number;
  versionFrom?: string;
  versionTo?: string;
}

export interface IMaintenance {
  connectivity: Connectivity;
  lastCheck: number | undefined;
  numChecks: number;
  busy: boolean;
  fileVersion?: string;
  notifications: IMaintenanceNotification[];
}

export interface IMaintenanceFile {
  fileVersion: string;
  notifications: IMaintenanceNotification[];
}
