import React, {useEffect, useMemo, useState} from 'react';
import {classNames, UiBox, useDebounce, useTranslator} from '@bitsolve/react-common';
import {IUiNumberSlider, UiNumberSlider} from './ui-number-slider.component';
import {explodeDuration} from '../../../core/util';

export interface IUiDuration extends IUiNumberSlider {
}

const useExplodedDuration = (initial?: string): [string, string] => {
  return useMemo(() => explodeDuration(initial), [initial]);
};

export const UiDurationSlider: React.FC<IUiDuration> = (props) => {
  const {className, style, controlProps, ...rest} = props;
  const {value, onChange} = rest;

  const t = useTranslator();
  const [initialMinutes, initialSeconds] = useExplodedDuration(value);
  const [minutes, setMinutes] = useState<string>(initialMinutes);
  const [seconds, setSeconds] = useState<string>(initialSeconds);

  const _value = useMemo(
    () => {
      try {
        const m = parseInt(minutes, 10);
        const s = parseInt(seconds, 10);
        return Math.round((m * 60) + s);
      } catch (_) {
        return 0;
      }
    },
    [minutes, seconds]
  );

  const _debValue = useDebounce(_value, 128);

  useEffect(() => {
    const prev = value?.toString();
    const next = _debValue.toString();

    if (onChange && prev !== next) {
      onChange(next);
    }

  }, [_debValue, value, onChange]);

  return <UiBox className={classNames('ui-duration-slider', className)}
                style={style}>
    <UiNumberSlider {...rest}
                    controlProps={{unitText: t('general.unit.minute.medium')}}
                    value={minutes}
                    onChange={setMinutes} />
    <UiBox>
      <em>{':'}</em>
    </UiBox>
    <UiNumberSlider {...rest}
                    controlProps={{unitText: t('general.unit.second.medium'), step: 5, max: 59}}
                    value={seconds}
                    onChange={setSeconds} />
  </UiBox>;
};
