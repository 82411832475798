import React, {useState} from 'react';
import {
  blobAsDataURL,
  classNames,
  IUiFieldProps,
  trapEvent,
  UiIcon,
  UiState,
  useFileDialog
} from '@bitsolve/react-common';
import {AccountPicture, IAccountPicture} from './account-picture.component';
import {usePictureData, useUploadImage} from '../../../image/image.api';
import {hasProp, isFn, isNil, isNonEmptyStr} from '@bitsolve/fns';
import {AppLoadingSpinner} from '../../../../app/common/misc/app-loading-spinner.component';

export interface IAccountPictureFieldControl extends IUiFieldProps<string> {
  controlProps: IAccountPicture;
}

const trapFocus = trapEvent<any>();

export const AccountPictureFieldControl: React.FC<IAccountPictureFieldControl> = (props) => {
  const {value, onChange, style, state} = props;
  const {className, ...controlProps} = props.controlProps;

  const [busy, setBusy] = useState<boolean>(false);
  // eslint-disable-next-line
  const [_, setProgress] = useState<number | undefined>();
  const {setDataUrl, setError} = usePictureData(value);

  const uploadImage = useUploadImage(ev => {
    const {total, loaded} = ev;

    if (isNil(total) || isNil(loaded)) {
      return;
    }

    try {
      const progress = Math.ceil(Math.max(0, Math.min(100, 100 / total * loaded))) / 100;
      setProgress(progress);
    } catch (e) {
      setProgress(undefined);
    }
  });

  const filePicker = useFileDialog({
    multiple: false,
    accept: '.jpg,.jpeg,.png,.gif'
  });

  const hasValue = isNonEmptyStr(value);
  const anyBusy = (busy || uploadImage.busy);

  return <div
    className={classNames(
      className,
      'd-f f-dv f-ai-c f-jc-c'
    )}
    style={{
      ...style,
      border: 0,
      width: '5rem',
      height: '5rem',
      minWidth: 0,
      padding: 0,
      position: 'relative',
    }}
    tabIndex={0}
    onFocus={state !== UiState.active ? trapFocus : undefined}
    onBlur={state !== UiState.active ? trapFocus : undefined}
    onClick={() => {
      if (anyBusy || state !== UiState.active) return;

      filePicker.openDialog()
        .then((result) => {
          if (isNil(result) || !((result instanceof File) || (result instanceof FileList))) {
            return;
          }

          const file = (result instanceof FileList)
            ? result.item(0)
            : result;

          return blobAsDataURL(file as File)
            .then(setDataUrl)
            .then(() => {
              requestAnimationFrame(() => setBusy(true));
              return uploadImage.send(file as File);
            });
        })
        .then(r => {
          if (isNil(r) || !hasProp(r?.data, 'mediaId')) return;
          const mediaId = r?.data?.mediaId;
          return mediaId
            ? Promise.resolve(mediaId as string)
            : Promise.reject(new Error('no media id in response'))
        })
        .then((mediaId) => {
          if (mediaId && isFn(onChange)) {
            onChange(mediaId);
          }
        })
        .catch(setError)
        .finally(() => requestAnimationFrame(() => setBusy(false)));
    }}>
    {hasValue
      ? <AccountPicture {...controlProps}
                        key={value}
                        style={{
                          ...controlProps.style,
                          minWidth: 0,
                          opacity: anyBusy ? 0.5 : 1
                        }}
                        mediaId={value} />
      : <UiIcon name={'userProfile'}
                iconSet={'regular'}
                style={{
                  backgroundColor: 'rgba(20, 34, 45, 50%)',
                  color: '#2D3F4E',
                  border: 0,
                  borderRadius: '50%',
                  width: '5rem',
                  height: '5rem',
                  opacity: anyBusy ? 0.5 : 1,
                  fontSize: '5rem'
                }} />}
    {(busy || uploadImage.busy)
    && <AppLoadingSpinner className={'inverse'}
                          size={'nm'}
                          style={{position: 'absolute'}} />}
  </div>;
};
