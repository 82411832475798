import {blobAsDataURL, IHttpRequest, useApiEndpoint, useApiUploadEndpoint} from '@bitsolve/react-common';
import {IImageUploadResult} from './image.model';
import {AxiosResponse} from 'axios';
import {LRUCache} from '@thi.ng/cache';
import {useEffect, useMemo, useRef, useState} from 'react';
import {isNonEmptyStr, isNotNil} from '@bitsolve/fns';
import {useRevisionState} from '../ui/ui.hooks';

const baseUrl = '/backend/api/v1/picture';

export const mediaUrl = (mediaId: string): string => `${baseUrl}/${mediaId}`;

export const useMediaUrl = (mediaId?: string | null): string | null => {
  return useMemo(
    () => mediaId && isNonEmptyStr(mediaId) ? mediaUrl(mediaId) : null,
    [mediaId]
  )
};

export const findImageRequest = (mediaId: string, opts?: { width?: number; height?: number; }): IHttpRequest => {
  return {
    method: 'get',
    url: mediaUrl(mediaId),
    responseType: 'blob',
    params: opts,
    headers: {
      'accept': 'image/*, */*'
    }
  };
};

export const uploadImageRequest = (file: File): IHttpRequest => {
  const data = new FormData();
  data.set('file', file, file.name);

  return {
    method: 'post',
    url: baseUrl,
    data
  };
};

export const useFindImage = (opts?: { width?: number; height?: number; }, onSuccess?: (result: AxiosResponse<Blob>) => any, onError?: (error?: any) => any) =>
  useApiEndpoint<string, Blob>(id => findImageRequest(id, opts), onSuccess, onError);

export const useUploadImage = (onProgress?: (progress: { loaded: number; total: number; }) => any, onSuccess?: (result: AxiosResponse<IImageUploadResult>) => any, onError?: (error?: any) => any) =>
  useApiUploadEndpoint<File, IImageUploadResult>(
    (file: File) => ({
      ...uploadImageRequest(file),
      onUploadProgress: onProgress
    }),
    onSuccess,
    onError
  );


interface IPictureDataHookResult {
  dataUrl: string | undefined;
  error?: any;
  setError: (error?: any) => void;
  setDataUrl: (dataUrl?: string) => void;
  revision: number;
}

const _imageDataCache = new LRUCache<string, string>(null, {maxlen: 30});

export const usePictureData = (pictureId?: string, opts?: { width?: number; height?: number; }): IPictureDataHookResult => {
  const resolving = useRef(false);
  const resolved = useRef<boolean>(false);
  const {revision, bumpRevision} = useRevisionState();
  const [error, setError] = useState<Error | undefined>();
  const [resolvedPictureId, setResolvedPictureId] = useState<string | undefined>();
  const [dataUrl, setDataUrl] = useState<string | undefined>();
  const findImage = useFindImage(opts);

  useEffect(
    () => {
      if (resolved.current || resolving.current) {
        return;
      }

      resolving.current = true;

      if (isNotNil(pictureId) && pictureId !== resolvedPictureId && !findImage.busy) {
        if (_imageDataCache.has(pictureId as string)) {
          setDataUrl(_imageDataCache.get(pictureId as string));
          setError(undefined);
          resolved.current = true;
          bumpRevision();
        } else {
          resolved.current = true;
          findImage.send(pictureId as string)
            .then(res => blobAsDataURL(res.data))
            .then(dataUrl => {
              _imageDataCache.set(pictureId as string, dataUrl);
              setDataUrl(dataUrl);
              setError(undefined);
              bumpRevision();
            })
            .catch(err => {
              _imageDataCache.delete(pictureId as string);
              setDataUrl(undefined);
              setError(err);
            }).finally(() => {
            setResolvedPictureId(pictureId);
            resolving.current = false;
          });
        }
      } else {
        resolved.current = true;
      }
    },
    [pictureId, resolving, resolved, resolvedPictureId, error, findImage, dataUrl, setError, setDataUrl, bumpRevision]
  );

  useEffect(
    () => {
      if (isNotNil(resolvedPictureId) && isNotNil(pictureId) && !findImage.busy && pictureId !== resolvedPictureId) {
        resolved.current = false;
        _imageDataCache.delete(pictureId as string);
        setResolvedPictureId(undefined);
      }
    },
    [pictureId, findImage, resolvedPictureId, setResolvedPictureId]
  );

  return {error, dataUrl, setError, setDataUrl, revision};
}
