import {Rooq} from '../../../core/domain.model';
import {isNonEmptyStr, isNotNil} from '@bitsolve/fns';

export const addressLine = (address: Rooq.Address): string => {
  const {street, houseNumber, zipCode, city, country} = address || {};

  const _street = isNonEmptyStr(street)
    ? (isNonEmptyStr(houseNumber) ? `${street} ${houseNumber}` : street)
    : null;

  const _city = isNonEmptyStr(city)
    ? (isNonEmptyStr(zipCode) ? `${zipCode} ${city}` : city)
    : null;

  const _country = isNonEmptyStr(country) ? country : null;

  return [_street, _city, _country]
    .filter(isNotNil)
    .join(', ');
};


export const cityLine = (address: Rooq.Address): string => {
  const {city, country} = address || {};

  const _city = isNonEmptyStr(city) ? city : null;
  const _country = isNonEmptyStr(country) ? country : null;

  return [_city, _country]
    .filter(isNotNil)
    .join(', ');
};
