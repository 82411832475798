import React from 'react';
import {
  classNames,
  IUiButton,
  UiActionMenu,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiCustomizable,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiPageSegment,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../app/common/misc/app-banner-pane.component';
import {ClubLogo} from './club-logo.component';
import {IClub, IClubMembership, IClubMembershipInvitation, useBannerPicture} from '../club.model';
import {IUiTooltipMenu, UiTooltipMenu} from '../../ui/component/ui-tooltip-menu.component';
import {Rooq} from '../../../core/domain.model';
import {useNavigator} from '../../../core/routing';
import {useClubMembershipInvitationActions} from '../club.api';
import {useAuthAccount} from '../../auth/auth.store';

export interface IClubCard extends UiCustomizable {
  club: IClub;
  onNameClick?: () => any;
  actions?: IUiButton[];
  actionMenu?: IUiButton[];
  menu?: IUiTooltipMenu;
}

export const ClubCard: React.FC<IClubCard> = (props) => {
  const {club, actions, menu, actionMenu, onNameClick, className, style} = props;

  const clubTitle = <UiTextTitle text={club.name} headingLevel={2} />;
  const {assetSrc, isLogoFallback} = useBannerPicture(club);

  return <AppBannerPane className={classNames('app-club__card', className)}
                        style={style}
                        src={assetSrc as any}
                        blur={isLogoFallback ? 1 : 0.1}
                        aspectRatio={1 / 3}
                        contentAnchor={'bs'}>
    <UiPageSegment>
      <UiFlexRow className={'app-club__card__content'}>
        <UiFlexCol tag={'header'}
                   className={'app-club__card__content__title'}>
          {club.logoPictureId && <UiBox className={'mg-b-sm'}>
            <ClubLogo size={'3.5em'}
                      pictureId={club.logoPictureId} />
          </UiBox>}
          <UiTextLine text={club.customFightStyles} />
          {onNameClick
            ? <div className="ui-fx ui-fx--selectable"
                   onClick={onNameClick}>
              {clubTitle}
            </div>
            : clubTitle}
        </UiFlexCol>
        {actions && <UiFlexRow jc={UiFlexAlign.e}
                               ai={UiFlexAlign.e}
                               className={'app-club__card__content__actions'}>
          {actions.map((action, i) => <UiButton key={i} {...action} />)}
        </UiFlexRow>}
        {actionMenu && <UiFlexCol className={'app-club__card__content__action-menu'}
                                  jc={UiFlexAlign.e}>
          <UiActionMenu
            buttonVariation={UiButtonVariation.semantic}
            primary={{
              label: '⋯',
              interact: (context) => context.toggle()
            }}
            toggleButton={false}
            actions={actionMenu} />
        </UiFlexCol>}
        {menu && <UiFlexCol className={'app-club__card__content__action-menu'}
                            jc={UiFlexAlign.e}>
          <UiTooltipMenu {...menu} />
        </UiFlexCol>}
      </UiFlexRow>
    </UiPageSegment>
  </AppBannerPane>;
};

export const ClubCardGrid: React.FC = (props) => {
  return <UiBox className="app-club__card__grid">
    {props.children}
  </UiBox>;
};


export const ClubMembershipCard: React.FC<{ membership: IClubMembership; onUpdate?: Function; }> = (props) => {
  const {membership} = props;
  const {club, role} = membership;

  const t = useTranslator();
  const nav = useNavigator();

  if (!club) {
    return null;
  }

  return <ClubCard key={club.id}
                   club={club}
                   className={'ui-fx ui-fx__pane-fade-in'}
                   actions={role === Rooq.MembershipRole.ADMIN
                     ? [
                       {
                         label: t('app.club.action.update.label'),
                         icon: 'appClub2',
                         interact: () => nav(`/portal/club/${club.id}/settings`),
                         variation: UiButtonVariation.ghost
                       }
                     ]
                     : undefined}
                   onNameClick={() => nav(`/portal/trainer/clubs/details/${club.id}`)} />;
};

export const ClubMembershipInvitationCard: React.FC<{ onUpdate?: Function; invite: IClubMembershipInvitation; }> = (props) => {
  const {invite, onUpdate} = props;

  const t = useTranslator();
  const nav = useNavigator();
  const user = useAuthAccount();
  const {deleteInvitation, updateInvitation, hideInvitation} = useClubMembershipInvitationActions({onAny: onUpdate});

  if (!invite?.club) {
    return null;
  }

  return <>
    <ClubCard club={invite.club}
              className={'ui-fx ui-fx__pane-fade-in'}
              onNameClick={() => nav(`/portal/trainer/clubs/details/${invite.club.id}`)}
              actions={
                invite.status === Rooq.InvitationStatus.PENDING
                  ? (invite.issuingAccount.id === user?.id
                    ? [
                      {
                        label: t('app.club.action.withdraw-membership-request.label'),
                        color: UiColor.primary,
                        className: 'txt-b',
                        interact: () => deleteInvitation.callback(invite.id)
                      }
                    ]
                    : [
                      {
                        label: t('app.club.action.update-membership.label.accept'),
                        color: UiColor.accent,
                        className: 'txt-b',
                        interact: () => updateInvitation.callback({
                          status: Rooq.InvitationStatus.ACCEPTED,
                          invitationId: invite.id
                        })
                      },
                      {
                        label: t('app.club.action.update-membership.label.reject'),
                        color: UiColor.primary,
                        className: 'mg-l-sm',
                        interact: () => updateInvitation.callback({
                          status: Rooq.InvitationStatus.REJECTED,
                          invitationId: invite.id
                        })
                      }
                    ])
                  : [
                    {
                      label: t('app.club.action.hide-membership-request.label'),
                      color: UiColor.primary,
                      variation: UiButtonVariation.outlines,
                      className: 'txt-b',
                      interact: () => hideInvitation.send(invite.id)
                    }
                  ]
              } />
    {deleteInvitation.modal.element}
    {updateInvitation.modal.element}
  </>;
};
