import React from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {
  UiBox,
  UiButton,
  UiButtonGroup,
  UiColor,
  UiPageContainer,
  UiPageSegment,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {useFindMyTrainingPlans} from '../../../../module/training-plan/training-plan.api';
import {TrainingPlanListing} from '../../../../module/training-plan/component/training-plan-listing.component';
import {AppSpinnerPane} from '../../../common/misc/app-spinner-pane.component';
import {UiModalRouteOutlet} from '../../../../module/ui/component/ui-modal-route.component';
import {TrainingPlanShareModal} from '../../../../module/training-plan/component/training-plan-share-modal.component';
import {TrainingPlanSharedWithModal} from '../../../../module/training-plan/component/training-plan-shared-with-modal.component';

export interface ICoachingZoneTrainingPlanRoute extends IRoute {
}


const TrainingPlanListingCallToAction: React.FC = () => {
  const t = useTranslator({namespace: 'app.training-plan.action.cta'});
  const nav = useNavigator();

  return <UiBox className={'app-training-plan__listing app-training-plan__listing--empty'}>
    <UiTextTitle text={t('title')} />
    <UiTextBlock text={t('teaser')} />
    <UiTextBlock text={t('info')} />
    <UiButton color={UiColor.accent}
              className={'txt-b'}
              label={t('label')}
              interact={() => nav('/portal/training-plan/create')} />
  </UiBox>;
};


export const CoachingZoneTrainingPlanRoute: React.FC<ICoachingZoneTrainingPlanRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const ownPath = '/portal/training-plan';
  const {busy, lastFetchTime, ...plansReq} = useFindMyTrainingPlans(0, 100, {initialFetch: true});
  const plans = plansReq?.response?.data?.elements;
  const hasPlans = !!plans?.length;
  const everLoaded = lastFetchTime && !busy;

  return <UiPageContainer fluid className={'app-coachz app-coachz__home'}
                          style={{position: 'relative', maxHeight: '100vh', minHeight: 0, overflow: 'hidden'}}>
    <AppPageHeader title={t('app.training-plan.title')} />
    <UiPageSegment className={'d-g f-1'} style={{maxHeight: '100%', overflow: 'hidden'}}>
      {!lastFetchTime && busy && <AppSpinnerPane spinner={{size: 'lg'}} />}
      {everLoaded && hasPlans && <TrainingPlanListing busy={busy}
                                                      plans={plans}
                                                      onUpdatePropagation={() => plansReq.load()} />}
      {everLoaded && !hasPlans && <TrainingPlanListingCallToAction />}
    </UiPageSegment>
    {everLoaded && hasPlans && <UiPageSegment style={{flex: '0 0 auto'}}>
      <UiButtonGroup actions={[
        {
          label: t('app.training-plan.action.create.label'),
          icon: 'plus',
          color: UiColor.accent,
          className: 'txt-b',
          interact: () => nav('/portal/training-plan/create')
        }
      ]} />
    </UiPageSegment>}
    <UiModalRouteOutlet ownPath={ownPath}
                        onUpdate={plansReq.load}
                        routes={[
                          {
                            path: `${ownPath}/share/:planId`,
                            component: TrainingPlanShareModal
                          },
                          {
                            path: `${ownPath}/shared-with/:planId`,
                            component: TrainingPlanSharedWithModal
                          }
                        ]} />
  </UiPageContainer>;
};
