import React, {useMemo} from 'react';
import { classNames, UiBox, UiCustomizable, UiInteractive, UiTagOverride } from '@bitsolve/react-common';
import {useImagePreload} from '../../../module/ui/ui.util';

export interface IAppBannerPane extends UiCustomizable, UiTagOverride, Partial<UiInteractive<any>> {
  src?: string | false;
  fgSrc?: string | false;
  preloadSrc?: boolean;
  aspectRatio?: number;
  blur?: number;
  showForegroundImage?: boolean;
  previewOptions?: { width?: number; height?: number; };
  contentAnchor?: 'tl' | 'tr' | 'ts' | 'bl' | 'br' | 'bs' | 'c';
  onPictureLoad?: (src: string) => any;
}

const _baseClass = 'app-banner-pane';
const _decorClass = (a: string) => `${_baseClass}__decor ${_baseClass}__${a}`;

const decorElements = <>
  <UiBox tag={'figure'} className={_decorClass('gradient-1')} />
  <UiBox tag={'figure'} className={_decorClass('gradient-2')} />
  <UiBox tag={'figure'} className={_decorClass('gradient-3')} />
</>;

export const AppBannerPane: React.FC<IAppBannerPane> = (props) => {
  const {
    tag = 'header',
    aspectRatio = 1 / 3,
    blur = 0,
    contentAnchor = 'bl',
    interact,
    src,
    fgSrc,
    preloadSrc = true,
    showForegroundImage = false,
    previewOptions,
    className,
    style,
    children
  } = props;

  const bgPic = useImagePreload(src, {
    preLoadDelay: 32,
    postLoadDelay: 32,
    cache: true,
    enable: preloadSrc,
    ...previewOptions
  });

  const fgActualSrc = fgSrc ?? src;

  const fgPic = useImagePreload(fgActualSrc, {
    preLoadDelay: 32,
    postLoadDelay: 32,
    cache: true,
    enable: preloadSrc,
    ...previewOptions
  });

  const bgStyle = useMemo(
    () => bgPic
      ? {backgroundImage: `url('${bgPic}')`, filter: blur ? `blur(${blur}rem)` : undefined}
      : undefined,
    [bgPic, blur]
  );

  const fgStyle = useMemo(
    () => fgPic
      ? {backgroundImage: `url('${fgPic}')`}
      : undefined,
    [fgPic]
  );

  const _className = classNames(
    _baseClass,
    children && `${_baseClass}--with-children`,
    bgPic && `${_baseClass}--loaded`,
    className
  );

  const paddingBottom = aspectRatio
    ? `${Math.floor(aspectRatio * 100)}%`
    : undefined;

  return <UiBox tag={tag}
                style={{...style, paddingBottom}}
                className={_className}>
    <UiBox tag={'figure'}
           key={bgStyle ? 'bg' : 'nbg'}
           className={_decorClass('image')}
           style={bgStyle} />
    {bgPic ? decorElements : null}
    {showForegroundImage && fgSrc && <UiBox tag={'figure'}
      key={fgStyle ? 'fg' : 'nfg'}
      className={_decorClass('foregroundimage')}
      style={fgStyle} />}
    {children && <UiBox className={`${_baseClass}__content ${_baseClass}__content--${contentAnchor}`}>
      {children}
    </UiBox>}
    {interact && <UiBox style={{zIndex: 20}}
                        interact={interact}
                        className={_decorClass('target')} />}
  </UiBox>;
};
