import {defaultIcons, intlStoreModule, IStoreModule} from '@bitsolve/react-common';
import firebase from 'firebase';
import {isNil, isNonEmptyStr, isNum} from '@bitsolve/fns';
import {authStoreModule} from '../../module/auth/auth.store';
import {FightStyleSelectControl} from './misc/app-fight-style-control.component';
import {TrainerCustomFightStylesControl} from '../../module/auth/component/trainer/trainer-custom-fight-styles-control.component';
import {IAuthAccess} from '../../module/auth';
import {appIcons, brandIcons, formIcons} from './misc/app-icons';
import {AccountPictureFieldControl} from '../../module/auth/component/account/account-picture-form-field.component';
import {UiPictureFieldControl} from '../../module/ui/component/ui-picture-field-control.component';
import {UiAddressFieldControl} from '../../module/ui/component/ui-address-field-control.component';
import {ClubOpeningHoursControl} from '../../module/club/component/club-opening-hours-control.component';
import {ContentSelectControl} from '../../module/content/component/content-select-control.component';
import {TrainingPlanUnitsControl} from '../../module/training-plan/component/training-plan-units-control.component';
import {UiNumberSlider} from '../../module/ui/component/ui-number-slider.component';
import {UiNumberRangeInput} from '../../module/ui/component/ui-number-range-input.component';
import {UiDurationSlider} from '../../module/ui/component/ui-duration-slider.component';
import {socketSoreModule} from '../../module/socket/socket.store';
import {notificationStoreModule} from '../../module/notification/notification.store';
import {PRODUCTION} from '../../core/constants';
import {UiSelectControl} from '../../module/ui/component/ui-select-control.component';
import {UiStarRatingFieldControl} from '../../module/ui/component/ui-star-rating-field-control.component';
import {UiToggleControl} from '../../module/ui/component/ui-toggle-control.component';
import {FirmwareUploadFieldControl} from '../../module/firmware/component/firmware-upload-modal.component';
import {AppLocaleSwitcher} from './misc/app-locale-switcher.component';
import {connectivityStoreModule} from '../../module/connectivity/connectivity.store';
import {http} from '../../core/api';
import {UiDateInputsControl} from '../../module/ui/component/ui-date-input-control.component';


export const storeModules: IStoreModule[] = [
  intlStoreModule,
  connectivityStoreModule,
  authStoreModule,
  socketSoreModule,
  notificationStoreModule,
];


export const fieldTypes = {
  'account-picture': AccountPictureFieldControl,
  'address': UiAddressFieldControl,
  'content-select': ContentSelectControl,
  'custom-fight-styles': TrainerCustomFightStylesControl,
  'duration-slider': UiDurationSlider,
  'fight-style-select': FightStyleSelectControl,
  'firmwareUpload': FirmwareUploadFieldControl,
  'locale-switcher': AppLocaleSwitcher,
  'number-range-input': UiNumberRangeInput,
  'number-slider': UiNumberSlider,
  'opening-hours': ClubOpeningHoursControl,
  'picture': UiPictureFieldControl,
  'select': UiSelectControl,
  'star-rating': UiStarRatingFieldControl,
  'toggle': UiToggleControl,
  'training-plan-units': TrainingPlanUnitsControl,
  'date-inputs': UiDateInputsControl,
};

export const icons = [
  defaultIcons,
  brandIcons,
  appIcons,
  formIcons
];

export const notificationSocket = {
  brokerURL: PRODUCTION
    ? `wss://${window.location.host}/backend/ws`
    : 'ws://localhost:9090/backend/ws'
};

export const defaultConnectivityChecks = {
  backend: {
    url: '/backend/api/v1/content/static/cz/config',
    timeout: 30000
  }
};

let httpId: number | null = null;

export const onAuthAccessChanged = (access: IAuthAccess | null) => {
  const token = access?.token;

  if (isNil(token)) {
    return;
  }

  const delegate = http.getDelegate();

  if (isNum(httpId)) {
    delegate.interceptors.request.eject(httpId);
  }

  httpId = delegate.interceptors.request.use(request => {
    if (isNonEmptyStr(token)) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  });
};

export const onAuthUserChanged = (user: firebase.User | null) => {
  // noop
};
