import React from 'react';
import {UiCustomizable, UiFlexAlign, UiFlexCol} from '@bitsolve/react-common';
import {AccountPicture} from '../../auth/component/account/account-picture.component';

export interface IAnalysisSessionAuthor extends UiCustomizable {
  color: string;
  size?: number;
  mediaId?: string;
}

export const AnalysisSessionAuthor: React.FC<IAnalysisSessionAuthor> = (props) => {
  const {color, size = 2.325, mediaId, className, style} = props;

  return <UiFlexCol ai={UiFlexAlign.c}
                    jc={UiFlexAlign.c}
                    className={className + ' app-analysis-athlete-summary__avatar'}
                    style={style}>
    <AccountPicture size={size}
                    mediaId={mediaId}
                    style={{
                      border: `3px solid ${color}`,
                      // color,
                    }} />
  </UiFlexCol>;
};
