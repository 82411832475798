import React from 'react';
import {UiModalDialog} from '../../ui/component/ui-modal-dialog.component';
import {
  IUiFieldProps,
  IUiModal,
  IUiModalRoute,
  schema,
  toast,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexRow,
  UiForm,
  UiFormButton,
  UiFormField,
  UiPageSegment,
  UiTextLine,
  useFileDialog
} from '@bitsolve/react-common';
import {useUploadFirmware} from '../firmware.api';
import {isNotNil} from '@bitsolve/fns';

export interface IFirmwareUpload {
  onSuccess?: Function;
  onError?: Function;
  onCancel?: Function;
}

const firmwareFields: IUiFieldProps[] = [
  {
    name: 'name',
    fieldType: 'text',
    label: 'Bezeichnung/Version',
    controlProps: {
      autoFocus: true
    }
  },
  {
    name: 'file',
    fieldType: 'firmwareUpload'
  }
];

export const FirmwareUploadFieldControl: React.FC<IUiFieldProps<File>> = (props) => {
  const {value, onChange, controlProps} = props;
  const {className} = controlProps;

  const dialog = useFileDialog({
    multiple: false,
    accept: '.bin'
  });

  return <UiBox className={className}>
    {value
      ? <UiFlexRow ai={UiFlexAlign.c} className={'pd-b-xs'}>
        <UiTextLine className={'f-1'}
                    text={(value && value instanceof File) ? value.name : '—'} />
        <UiButton size={'sm'}
                  icon={'times'}
                  variation={UiButtonVariation.ghost}
                  interact={() => onChange && onChange(null as any)} />
      </UiFlexRow>
      : <UiButton stretch label={'Datei wählen …'}
                  interact={() => {
                    dialog.reset();
                    dialog
                      .openDialog()
                      .then(selection => {
                        if (selection.length === 0) return;
                        const file = selection.item(0);
                        onChange && onChange(file as any);
                      })
                      .catch((e) => console.error('error', e));
                  }} />}
  </UiBox>
};

const firmwareSchema = schema.object({
  name: schema.string().required().min(3),
  file: schema.mixed().required().test({
    name: 'file',
    exclusive: false,
    params: {},
    message: 'must be a file',
    test: (value: any) => isNotNil(value) && (
      value instanceof File
      || value instanceof Blob
    )
  })
});

export const FirmwareUploadModal: React.FC<IUiModalRoute & IUiModal> = (props) => {
  const {onUpdatePropagation, onRequestClose} = props;

  const uploadFirmware = useUploadFirmware(
    () => {
      toast.success('Firmware hochgeladen');
      onUpdatePropagation && onUpdatePropagation();
      onRequestClose && onRequestClose();
    },
    () => {
      toast.error('Fehler beim Hochladen der Firmware-Datei');
    }
  );

  return <UiModalDialog header={{title: 'Firmware hochladen'}}>
    <UiPageSegment>
      <UiForm
        schema={firmwareSchema}
        interact={(vals) => uploadFirmware.send([vals.name, vals.file])
          .then(r => console.log(r))
          .catch(e => console.error(e))}
        fields={firmwareFields}
        value={{
          name: '',
          file: undefined
        }}>
        <UiFormField direction={UiDir.v}
                     name={'name'}
                     className={'mg-b-nm'} />
        <UiFormField direction={UiDir.v}
                     name={'file'} />
        <UiBox className={'mg-t-md'}>
          <UiFormButton stretch label={'Hochladen & Speichern'}
                        color={UiColor.accent}
                        interact={form => form.submit()} />
        </UiBox>
      </UiForm>
    </UiPageSegment>
  </UiModalDialog>;
};
