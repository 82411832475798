import React from 'react';
import {ITrainingPlanPaginated} from '../training-plan.model';
import {
  classNames,
  UiBox,
  UiCustomizable,
  UiFlexAlign,
  UiFlexRow,
  UiTextBlock,
  useTranslator
} from '@bitsolve/react-common';
import {TrainingPlanListingItem} from './training-plan-listing-item.component';
import {Rooq, useShouldShowTour} from '../../../core/domain.model';
import {ArrowSide, UiTourTooltip} from '../../ui/component/ui-tour-tooltip.component';

export interface ITrainingPlanListing extends UiCustomizable {
  plans?: ITrainingPlanPaginated[];
  busy?: boolean;
  onUpdatePropagation?: Function;
}

export const TrainingPlanListing: React.FC<ITrainingPlanListing> = (props) => {
  const {plans = [], onUpdatePropagation, className, style} = props;

  const t = useTranslator();
  const showSampleTour = useShouldShowTour(Rooq.UserTour.SampleDataTrainingPlan);
  const hasPlans = plans?.length > 0;
  const arePlansSamples = true; // useArePlansSampleData(plans);

  if (!hasPlans) {
    return null;
  }

  return <UiBox
    className={classNames(
      'app-training-plan__listing',
      !hasPlans && 'app-training-plan__listing--empty',
      className
    )}
    style={style}>
    {plans.map((plan, index) =>
      <TrainingPlanListingItem className={'app-training-plan__listing__item'}
                               onUpdatePropagation={onUpdatePropagation}
                               index={index}
                               plan={plan}
                               key={plan.id} />)}
    <UiFlexRow jc={UiFlexAlign.c}>
      {arePlansSamples && showSampleTour &&
      <UiTourTooltip arrow arrowSide={ArrowSide.Top}
                     clickToComplete={Rooq.UserTour.SampleDataTrainingPlan}>
        <UiTextBlock
          text={t('app.tour.sample-data-training-plan.message')} />
      </UiTourTooltip>}
    </UiFlexRow>
  </UiBox>;
};
