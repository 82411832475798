import React from 'react';
import {IPaginatedApi, IUiButton, UiButton, UiCustomizable, UiFlexRow, UiState} from '@bitsolve/react-common';

export interface IUiPager extends UiCustomizable {
  api: IPaginatedApi;
  buttons?: Partial<Omit<IUiButton, 'interact'>>;
}

export const UiPager: React.FC<IUiPager> = (props) => {
  const {api: p, buttons, className, ...rest} = props;
  const s = p.state;

  return <UiFlexRow {...rest}
                    className={`ui-pager${className ? ' ' + className : ''}`}>
    <UiButton {...buttons}
              icon={'caret-left'}
              state={s.isFirst ? UiState.disabled : undefined}
              interact={() => p.prev(true)} />
    <UiButton {...buttons}
              icon={'caret-right'}
              state={s.isLast ? UiState.disabled : undefined}
              interact={() => p.next(true)} />
  </UiFlexRow>;
};
