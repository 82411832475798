import {
    blobAsDataURL,
    IHttpRequest,
    IHttpRequestHookOptions,
    IPaginatedHttpRequestHookOptions,
    Page,
    useApiEndpoint,
    useHttpRequest,
    useLocale,
    usePaginatedHttpRequest
} from '@bitsolve/react-common';
import {
    IAuthUserAccount,
    IAuthUserAccountUpdate,
    IAuthUserAthleteProfile,
    IAuthUserAthleteProfileUpdate,
    IAuthUserTrainerProfile,
    IAuthUserTrainerProfileUpdate
} from './auth.model';
import {AxiosResponse} from 'axios';
import {dissoc, isNotNil} from '@bitsolve/fns';
import {useEffect, useState} from 'react';
import {useFindImage} from '../image/image.api';
import {LRUCache} from '@thi.ng/cache';
import {ErrorHandler, paginatedOptions, SuccessHandler, useDeleteConfirmation} from '../../core/api';
import {Rooq} from '../../core/domain.model';
import {useAuthAccount} from './auth.store';
import {useAppData} from "../../core/system";

const baseUrl = `/backend/api/v1/account`;
const internalUrl = `/backend/api/v1/internal/account`;

export const findAccountByIdRequest = (id: string): IHttpRequest => ({
    method: 'get',
    url: `${baseUrl}/${id}`
});


export const findInternalAccountByIdRequest = (id: string): IHttpRequest => ({
    method: 'get',
    url: `${internalUrl}/${id}`
});


export const findMyAccountRequest = (): IHttpRequest => ({
    method: 'get',
    url: `${baseUrl}/me`
});

export const findMyBlockedUsersRequest = (page?: number, pageSize?: number): IHttpRequest => ({
    method: 'get',
    url: `${baseUrl}/me/blocked`,
    params: {
        page: page || 0,
        pageSize: pageSize || 25
    }
});

export const findInternalAccountsRequest = (page?: number, pageSize?: number): IHttpRequest => ({
    method: 'get',
    url: internalUrl,
    params: {
        page: page || 0,
        pageSize: pageSize || 25
    }
});

export const updateAccountRequest = (input: [string, Partial<IAuthUserAccountUpdate>]): IHttpRequest => ({
    method: 'patch',
    url: `${baseUrl}/${input[0]}`,
    data: dissoc(input[1], 'email')
});

export const updateInternalAccountRequest = (input: [string, Partial<IAuthUserAccountUpdate>]): IHttpRequest => ({
    method: 'patch',
    url: `${internalUrl}/${input[0]}`,
    data: dissoc(input[1], 'email')
});

export const updateMyAccountRequest = (data: Partial<IAuthUserAccountUpdate>): IHttpRequest => ({
    method: 'patch',
    url: `${baseUrl}/me`,
    data: dissoc(data, 'email')
});

export const createInternalAccountRequest = (email: string, password: string): IHttpRequest => ({
    method: 'post',
    url: `${internalUrl}`,
    data: {
        email: `${email}`.trim(),
        password: `${password}`.trim()
    }
});


export const deleteInternalAccountRquest = (accountId: string): IHttpRequest => ({
    method: 'delete',
    url: `${internalUrl}/${accountId}`
});

export const blockUserRequest = (id: string): IHttpRequest => ({
    method: 'post',
    url: `${baseUrl}/me/${id}/block`
});

export const unblockUserRequest = (id: string): IHttpRequest => ({
    method: 'post',
    url: `${baseUrl}/me/${id}/unblock`
});

export const sendCustomVerificationRequest = (options: { token: string, continueUrl: string, userLanguage: string }): IHttpRequest => ({
    method: 'post',
    url: `/backend/api/v1/auth/verify?langCode=${options.userLanguage}&continueUrl=${options.continueUrl}`,
    headers: {
        'Authorization': `Bearer ${options.token}`
    }
});



export const useUpdateAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<[string, Partial<IAuthUserAccountUpdate>], R>((input) => updateAccountRequest(input), onSuccess, onError);

export const useUpdateInternalAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<[string, Partial<IAuthUserAccountUpdate>], R>((input) => updateInternalAccountRequest(input), onSuccess, onError);


export const useUpdateMyAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<Partial<IAuthUserAccountUpdate>, R>((input) => updateMyAccountRequest(input), onSuccess, onError);

export const useBlockAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<string, R>(id => blockUserRequest(id), onSuccess, onError);

export const useUnblockAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<string, R>(id => unblockUserRequest(id), onSuccess, onError);


export const useSendCustomVerificationMail = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) => {

    const config = useAppData();
    const userLanguage = useLocale();
    const {continueUrl} = config;

    return useApiEndpoint<string, R>((token) => {

        return sendCustomVerificationRequest({token, userLanguage, continueUrl})
    }, onSuccess, onError);
}

export const useFindAccountById = <R = IAuthUserAccount>(id: string, options?: IHttpRequestHookOptions) =>
    useHttpRequest<R>(findAccountByIdRequest(id), options);

export const useFindInternalAccountById = <R = IAuthUserAccount>(id: string, options?: IHttpRequestHookOptions) =>
    useHttpRequest<R>(findInternalAccountByIdRequest(id), options);

export const useFindMyAccount = <R = IAuthUserAccount>(onSuccess?: (result: AxiosResponse<R>) => any,
                                                       onError?: (error: any) => any) =>
    useApiEndpoint<void, R>(findMyAccountRequest, onSuccess, onError);

export const useFindMyBlockedUsers = (page?: number, pageSize?: number, options?: IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions) =>
    usePaginatedHttpRequest<Page<IAuthUserAccount>>(
        findMyBlockedUsersRequest(page, pageSize),
        options
    );

export const useCompleteMyTour = (onSuccess?: SuccessHandler<any>, onError?: ErrorHandler) => {
    const acc = useAuthAccount();

    return useApiEndpoint<Rooq.UserTour, IAuthUserAccount>(
        (tour) => {
            const completed = new Set(acc?.completedTours);
            completed.add(tour);
            const completedTours: Array<Rooq.UserTour> = Array.from(completed.values());
            return updateMyAccountRequest({completedTours});
        },
        onSuccess,
        onError
    )
}


export const useInternalFindAccounts = (page?: number, pageSize?: number, options?: IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions) =>
    usePaginatedHttpRequest<Page<IAuthUserAccount>>(
        findInternalAccountsRequest(page, pageSize),
        {initialFetch: true, ...paginatedOptions(page || 0, pageSize || 25), ...options}
    );

export const updateTrainerProfileRequest = (input: [string, string, Partial<IAuthUserTrainerProfileUpdate>]): IHttpRequest => {
    let [accountId, profileId, data] = input;

    return {
        method: 'patch',
        url: `${baseUrl}/${accountId}/profile/trainer/${profileId}`,
        data
    };
};

export const updateInternalTrainerProfileRequest = (input: [string, Partial<IAuthUserTrainerProfileUpdate>]): IHttpRequest => {
    let [accountId, data] = input;

    return {
        method: 'patch',
        url: `${internalUrl}/${accountId}/profile/trainer`,
        data
    };
};

export const updateInternalAthleteProfileRequest = (input: [string, Partial<IAuthUserAthleteProfileUpdate>]): IHttpRequest => {
    let [accountId, data] = input;

    return {
        method: 'patch',
        url: `${internalUrl}/${accountId}/profile/athlete`,
        data
    };
};

export const updateMyTrainerProfileRequest = (data: Partial<IAuthUserTrainerProfileUpdate>): IHttpRequest => ({
    method: 'patch',
    url: `${baseUrl}/me/profile/trainer`,
    data
});
export const updateMyAthleteProfileRequest = (data: Partial<IAuthUserAthleteProfileUpdate>): IHttpRequest => ({
    method: 'patch',
    url: `${baseUrl}/me/profile/athlete`,
    data
});

export const useUpdateTrainerProfile = <R = IAuthUserTrainerProfile>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<[string, string, Partial<IAuthUserTrainerProfileUpdate>], R>((input) => updateTrainerProfileRequest(input), onSuccess, onError);

export const useUpdateInternalTrainerProfile = <R = IAuthUserTrainerProfile>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<[string, Partial<IAuthUserTrainerProfileUpdate>], R>(
        updateInternalTrainerProfileRequest,
        onSuccess,
        onError);

export const useUpdateInternalAthleteProfile = <R = IAuthUserAthleteProfile>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<[string, Partial<IAuthUserAthleteProfileUpdate>], R>(
        updateInternalAthleteProfileRequest,
        onSuccess,
        onError);

export const useUpdateMyTrainerProfile = <R = IAuthUserTrainerProfile>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<Partial<IAuthUserTrainerProfileUpdate>, R>(updateMyTrainerProfileRequest, onSuccess, onError);

export const useUpdateMyAthleteProfile = <R = IAuthUserAthleteProfile>(onSuccess?: (result: AxiosResponse<R>) => any, onError?: (error: any) => any) =>
    useApiEndpoint<Partial<IAuthUserAthleteProfileUpdate>, R>(updateMyAthleteProfileRequest, onSuccess, onError);


export const useCreateInternalAccountEndpoint = (onSuccess?: SuccessHandler<any>, onError?: ErrorHandler) =>
    useApiEndpoint<{ email: string; password: string; }, any>(
        data => createInternalAccountRequest(data.email, data.password),
        onSuccess,
        onError
    );

export const useDeleteInternalAccountEndpoint = (onSuccess?: SuccessHandler<any>, onError?: ErrorHandler) =>
    useApiEndpoint<string, any>(
        deleteInternalAccountRquest,
        onSuccess,
        onError
    );

export const useDeleteInternalAccount = (onSuccess?: SuccessHandler<any>, onError?: ErrorHandler) =>
    useDeleteConfirmation({
        endpoint: useDeleteInternalAccountEndpoint(),
        onSuccess,
        onError,
        title: 'general.confirm.title',
        message: 'general.confirm.message',
        confirmLabel: 'general.confirm.action.confirm',
        cancelLabel: 'general.confirm.action.cancel'
    });

interface IAccountProfilePictureDataHookResult {
    dataUrl: string | undefined;
    error?: any;
    setError: (error?: any) => void;
    setDataUrl: (dataUrl?: string) => void;
}

// const lruImageDataCache =
const _imageDataCache = new LRUCache<string, string>(null, {maxlen: 30});

export const useAccountProfilePictureData = (pictureId?: string): IAccountProfilePictureDataHookResult => {
    const [error, setError] = useState<Error | undefined>();
    const [resolvedPictureId, setResolvedPictureId] = useState<string | undefined>();
    const [dataUrl, setDataUrl] = useState<string | undefined>();
    const findImage = useFindImage();

    useEffect(
        () => {
            if (isNotNil(pictureId) && pictureId !== resolvedPictureId && !findImage.busy) {
                if (_imageDataCache.has(pictureId as string)) {
                    setDataUrl(_imageDataCache.get(pictureId as string));
                    setError(undefined);
                } else {
                    findImage.send(pictureId as string)
                        .then(res => blobAsDataURL(res.data))
                        .then(dataUrl => {
                            _imageDataCache.set(pictureId as string, dataUrl);
                            setDataUrl(dataUrl);
                            setError(undefined);
                        })
                        .catch(err => {
                            _imageDataCache.delete(pictureId as string);
                            setDataUrl(undefined);
                            setError(err);
                        }).finally(() => {
                        setResolvedPictureId(pictureId);
                    });
                }
            }
        },
        [pictureId, resolvedPictureId, error, findImage, dataUrl, setError, setDataUrl]
    );

    useEffect(
        () => {
            if (isNotNil(resolvedPictureId) && isNotNil(pictureId) && !findImage.busy && pictureId !== resolvedPictureId) {
                setResolvedPictureId(undefined);
            }
        },
        [pictureId, findImage, resolvedPictureId, setResolvedPictureId]
    );

    return {error, dataUrl, setError, setDataUrl};
}
