import React from 'react';
import {Rooq} from '../../../core/domain.model';
import {UiCustomizable, UiIcon, useTranslator} from '@bitsolve/react-common';
import {isStr} from '@bitsolve/fns';

export interface IAppDisciplineIcon extends UiCustomizable {
  discipline: Rooq.Discipline | string;
  size?: number | string | null;
}

export const AppDisciplineIcon: React.FC<IAppDisciplineIcon> = (props) => {
  const {size = 1, discipline, className, style} = props;
  const t = useTranslator({namespace: 'content.discipline'});
  const _size = isStr(size) ? size : `${size}rem`;

  return <UiIcon name={discipline}
                 className={className}
                 style={{height: _size, width: _size, fontSize: _size, padding: 0, ...style}}
                 aria-label={t(discipline)}
                 iconSet={'regular'} />
};
