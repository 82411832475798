import {CSSProperties} from 'react';
import {ISelectFieldControlOption, IUiFieldProps, UiButtonVariation} from '@bitsolve/react-common';


export interface IUiSelectControlOption<T = any> extends Omit<ISelectFieldControlOption, 'value'> {
  value?: T;
}

export interface IUiSelectControl<T = any> extends IUiFieldProps<T> {
  options: ISelectFieldControlOption[];
  buttonVariation?: UiButtonVariation;
  placeholder?: string;
  busy?: boolean;
}

type UiSelectControlState = { isDisabled: boolean, isFocused: boolean, isSelected: boolean };
type UiSelectControlStyleFn<S = any> = (provided: CSSProperties, state: S & UiSelectControlState) => CSSProperties;
type UiSelectControlStyles<S = any> = Record<string, UiSelectControlStyleFn<S>>;

export const selectControlStyles = <T = any>(props?: IUiSelectControl<T>): UiSelectControlStyles<IUiSelectControl<T>> => {
  return {
    container: (style, props) => ({
      ...style,
      color: 'var(--color-light)',
      borderRadius: 0,
      fontSize: '1rem',
      fontWeight: 400,
      // display: 'flex',
      // flexDirection: 'row',
    }),
    control: (style, props) => ({
      ...style,
      color: 'var(--color-light)',
      backgroundColor: 'transparent',
      borderWidth: 0,
      borderColor: 'transparent',
      borderRadius: 0,
    }),
    indicatorSeparator: () => ({display: 'none'}),
    input: (style, props) => ({
      ...style,
      padding: '0 0.125rem',
      boxShadow: 'none',
      margin: 0,
      color: 'var(--color-light)',
      fontSize: '1rem',
    }),
    menu: (style, props) => ({
      ...style,
      padding: 0,
      marginTop: '1px',
      borderRadius: 0,
      backgroundColor: 'var(--color-rooq--primary-lighter)'
    }),
    menuList: (style, props) => ({
      ...style,
      borderRadius: 0,
      padding: 0,
    }),
    option: (style, props) => ({
      ...style,
      padding: '0.75rem',
      backgroundColor: props.isDisabled
        ? 'rgba(255,255,255,0.125)'
        : (props.isFocused
          ? 'var(--color-rooq--primary-lightest)'
          : 'transparent'),
      color: props.isDisabled
        ? 'var(--color-text--default-dimmer)'
        : (props.isSelected
          ? 'var(--color-light)'
          : 'var(--color-text--default-dim)'),
    }),
    placeholder: (style, props) => ({
      ...style,
      display: 'none'
    }),
    singleValue: (style, props) => ({
      ...style,
      color: 'var(--color-light)',
    }),
    valueContainer: (style, props) => ({
      ...style,
      padding: 0,
    }),
  };
};
