import React, {useMemo} from 'react';
import {classNames, UiCustomizable} from '@bitsolve/react-common';
import {isNil, isNum, isStr} from '@bitsolve/fns';
import {usePictureData} from '../../image/image.api';

export interface IClubLogo extends UiCustomizable {
  pictureId?: string;
  size?: number | string | null;
}

export const ClubLogo: React.FC<IClubLogo> = (props) => {
  const {pictureId, size, className, style} = props;

  const _size = useMemo(() => isNil(size) ? '1rem' : (isStr(size) ? size : `${size}em`), [size]);
  const src = usePictureData(pictureId, {
    width: isNum(size) ? size : 100,
    height: isNum(size) ? size : 100
  });

  return <figure
    role={'presentation'}
    className={classNames('app-club__logo', className)}
    style={{
      width: _size,
      height: _size,
      ...style,
      backgroundImage: src.dataUrl && !src.error ? `url(${src.dataUrl})` : undefined
    }} />;
};
