import {Action} from 'redux';
import {createActionHook, IStoreModule, Page} from '@bitsolve/react-common';
import {IClubMembership} from './club.model';
import {createSelector} from 'reselect';
import {useSelector} from 'react-redux';
import {assoc, keys, prop, update} from '@bitsolve/fns';
import {Rooq} from '../../core/domain.model';
import {findMyClubMembershipsRequest} from './club.api';
import {IAuthUserAccount} from '../auth';
import {AxiosResponse} from 'axios';
import {useMemo} from 'react';


export interface IClubStore {
  administration: Record<string, IClubMembership<IAuthUserAccount>>;
  busy: boolean;
  lastFetch?: number;
}

export const clubStoreKey = '@rooq/club';

export const initialClubState: IClubStore = {
  administration: {},
  busy: false,
  lastFetch: undefined,
};

const selectClubStore = (state: { [clubStoreKey]: IClubStore }): IClubStore => state[clubStoreKey];
const selectClubAdministration = createSelector(selectClubStore, store => store.administration);

export const useClubStore = () => useSelector(selectClubStore);
export const useClubAdministration = () => useSelector(selectClubAdministration);
export const useClubAdministrationListing = () => {
  const record = useClubAdministration();
  return useMemo(
    () => keys(record).map(id => prop(record, id)),
    [record]
  );
};

export enum ClubStoreAction {
  fetchAdministration = '@rooq.club/fetch-administration',
  fetchAdministrationSuccess = '@rooq.club/fetch-administration-success',
  fetchAdministrationError = '@rooq.club/fetch-administration-error',
}

export interface IClubStoreAction<P = any, M = any> extends Action<ClubStoreAction> {
  payload?: P;
  meta?: M;
}

export const fetchAdministration = () =>
  (dispatch: any, _: any) => {
    const request = findMyClubMembershipsRequest(0, 100, Rooq.MembershipType.TRAINER, Rooq.MembershipRole.ADMIN);
    dispatch({type: ClubStoreAction.fetchAdministration, payload: {request}});
  };

export const useClubStoreActions = createActionHook({
  fetchAdministration
});

export const clubStoreReducer = (state: IClubStore = initialClubState, action?: IClubStoreAction) => {
  if (!action) {
    return state;
  }

  const {type, payload} = action;

  switch (type) {
    case ClubStoreAction.fetchAdministration: {
      return assoc(state, 'busy', true);
    }

    case ClubStoreAction.fetchAdministrationSuccess: {
      const {data} = payload as AxiosResponse<Page<IClubMembership>>;
      return update(
        assoc(state, 'busy', false),
        'administration',
        () => (data?.elements || []).reduce(
          (r, el) => el.role !== Rooq.MembershipRole.ADMIN
            ? r
            : assoc(r, el.membershipId, el),
          {}
        )
      );
    }

    case ClubStoreAction.fetchAdministrationError: {
      return assoc(
        assoc(state, 'busy', false),
        'administration',
        {}
      );
    }

    default:
      return state;
  }
};

export const clubStoreModule: IStoreModule = {
  key: clubStoreKey,
  reducer: clubStoreReducer,
  initialState: initialClubState
};
