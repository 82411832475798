import {UiCustomizable, UiIcon, UiTextLine} from '@bitsolve/react-common';
import {AppPageSectionPanel, IAppPageSectionPanel} from './app-page-section-panel.component';
import React from 'react';
import {NavLink} from 'react-router-dom';

export interface IAppPageMenuPanel extends UiCustomizable {
  items: {
    label: string;
    to?: string;
    interact?: () => any,
    target?: string;
    exact?: boolean;
    external?: boolean;
  }[];
  title?: IAppPageSectionPanel['title'];
}

export const AppPageMenuPanel: React.FC<IAppPageMenuPanel> = (props) => {
  const {items, title, className, style} = props;

  return <AppPageSectionPanel title={title}
                              className={className}
                              style={style}>
    <menu className={'app-coachz__settings__master-menu pd-v-sm'}>
      {items.map((item, index) => {
        if (item.external) {
          return <a key={item.to}
                    href={item.to}
                    target={item?.target}
                    className={'pd-v-sm pd-h-nm'}>
            <UiTextLine className={'f-1'} text={item.label} />
            <UiIcon name={'caret-right'} iconSet={'regular'} />
          </a>;
        }

        if (!item.to) {
          if (item.interact) {
            return <span key={index}
                         className={'pd-h-nm pd-v-sm txt-c-defd'}
                         children={item.label}
                         onClick={ev => {
                           ev.preventDefault();
                           ev.stopPropagation();
                           item.interact && item.interact();
                         }} />
          }

          return null;
        }

        return <NavLink key={item.to}
                        to={item.to}
                        exact={item.exact}
                        className={'pd-v-sm pd-h-nm'}>
          <UiTextLine className={'f-1'} text={item.label} />
          <UiIcon name={'caret-right'} iconSet={'regular'} />
        </NavLink>;
      })}
    </menu>
  </AppPageSectionPanel>;
};
