import {API_PREFIX, ASSET_PREFIX} from '../../core/constants';
import {IHttpRequest} from '@bitsolve/react-common';

export const BACKEND_HEALTH_INTERVAL = 7500;
export const BACKEND_HEALTH_THRESHOLD = 5000;
export const MAINTENANCE_INTERVAL = 30000;
export const MAINTENANCE_THRESHOLD = 15000;

export const findBackendHealthRequest = (): IHttpRequest => ({
  method: 'OPTIONS',
  // url: `${API_PREFIX}`,
  url: `${API_PREFIX}/v1/content/static/cz/config?health`,
});

export const findMaintenanceStatusRequest = (url?: string): IHttpRequest => ({
  method: 'GET',
  url: url || `${ASSET_PREFIX}/placeholder/maintenance.json`
});
