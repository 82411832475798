import {IBuilder} from '../../../core/domain.model';
import {IAnalysisChartHeader} from './analysis-panel.component';
import {IRoute} from '../../../core/routing';
import {isNil, isNotNil, StringEncoding} from '@bitsolve/fns';
import {IDrilldownCharts, IDrilldownStage} from './chart/drilldown-charts.component';
import {IAnalysisChartType, IAnalysisDetailsPageSegmentChart, IAnalysisSegmentType} from './analysis-details.model';

class AnalysisDetailsDrilldownChartsBuilder<T extends IAnalysisChartType['props'] = IDrilldownCharts>
  implements IBuilder<T> {
  protected stages: IDrilldownStage[];
  protected header?: {
    title?: string;
  }

  constructor(stages: IDrilldownStage[],
              header?: IDrilldownCharts['header']) {
    this.stages = stages;
    this.header = header;
  }

  withStage(stage: IDrilldownStage): this {
    this.stages.push(stage);
    return this;
  }

  withStages(...stages: IDrilldownStage[]): this {
    this.stages.push(...stages);
    return this;
  }

  stage(routePattern: string, ...segments: IAnalysisSegmentType[]): this {
    return this.withStage({
      route: {pattern: routePattern},
      segments
    });
  }

  title(title: StringEncoding): this {
    if (title) {
      this.header = this.header
        ? {...this.header, title: title.toString()}
        : {title: title.toString()}
    }
    return this;
  }

  build(): T {
    return {
      stages: this.stages,
      header: this.header
    } as unknown as T;
  }
}

class AnalysisDetailsChartBuilder<T extends IAnalysisChartType = IAnalysisChartType>
  implements IBuilder<T> {
  protected type: T['type'];
  protected props: T['props'];
  protected header?: IAnalysisChartHeader;

  constructor(type: T['type'],
              props: T['props'] = {} as any,
              header?: IAnalysisChartHeader) {
    this.type = type;
    this.props = props;
    this.header = header;
  }

  withProps(props: T['props']): this {
    this.props = props;
    return this;
  }

  withHeader(header: IAnalysisChartHeader): this {
    this.header = header;
    return this;
  }

  drilldown(): AnalysisDetailsDrilldownChartsBuilder {
    return new AnalysisDetailsDrilldownChartsBuilder([]);
  }

  build(): T {
    return {
      type: this.type,
      props: this.props,
      header: this.header
    } as any as T;
  }
}

class AnalysisDetailsSegmentBuilder
  implements IBuilder<IAnalysisSegmentType> {
  protected layout: 'single' | 'dual';
  protected charts: IAnalysisDetailsPageSegmentChart[];
  protected route?: IRoute;

  constructor(layout: 'single' | 'dual' = 'single',
              charts: IAnalysisDetailsPageSegmentChart[] = [],
              route?: IRoute) {
    this.layout = layout;
    this.charts = charts;
    this.route = route;
  }

  single(): this {
    this.layout = 'single';
    return this;
  }

  dual(): this {
    this.layout = 'dual';
    return this;
  }

  withChart(chart: IAnalysisDetailsPageSegmentChart): this {
    this.charts.push(chart);
    return this;
  }

  withCharts(...charts: IAnalysisDetailsPageSegmentChart[]): this {
    return charts.reduce((t, c) => t.withChart(c), this);
  }

  chart<T extends IAnalysisChartType = IAnalysisChartType,
    TT extends T['type'] = T['type'],
    B extends IBuilder<T> = IBuilder<T>,
    R = T | B | any>(
    type: TT,
    buildFn: (builder: AnalysisDetailsChartBuilder<T>) => R
  ): this {
    let builder, buildRes;

    builder = new AnalysisDetailsChartBuilder(type, undefined, undefined);
    buildRes = buildFn(builder as any);

    if (isNil(buildRes) || buildRes instanceof AnalysisDetailsChartBuilder) {
      this.charts.push(((buildRes || builder) as unknown as B).build());
      return this;
    } else {
      this.charts.push(buildRes as any);
      return this;
    }
  }

  drilldown(drilldownBuildFn: (builder: AnalysisDetailsDrilldownChartsBuilder, parent: IBuilder<IAnalysisChartType>) => any): this {
    return this.chart('drilldown', builder => {
      const drilldownBuilder = builder.drilldown();
      const drilldownRes = drilldownBuildFn(drilldownBuilder, builder);
      const drilldown = isNotNil(drilldownRes) && !(drilldownRes instanceof AnalysisDetailsDrilldownChartsBuilder)
        ? drilldownRes
        : drilldownBuilder.build();

      return isNotNil(drilldown)
        ? builder.withProps(drilldown)
        : builder;
    });
  }

  build(): IAnalysisSegmentType {
    return {
      layout: this.layout,
      charts: (this.layout === 'single'
        ? this.charts?.slice(0, 1) as any
        : this.charts?.slice(0, 2) as any) || [],
      route: this.route,
    }
  }
}

export const chart = <T extends IAnalysisChartType = IAnalysisChartType>(type: T['type']) =>
  new AnalysisDetailsChartBuilder<T>(type, undefined, undefined);
export const segment = (layout: IAnalysisSegmentType['layout'] = 'single') =>
  new AnalysisDetailsSegmentBuilder(layout, []);
