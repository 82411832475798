import React from 'react';
import {
  classNames,
  IIconName,
  IIconSetName,
  UiCustomizable,
  UiIcon,
  UiTextTitle,
  UiWithIcon
} from '@bitsolve/react-common';
import {isNonEmptyStr} from '@bitsolve/fns';
import {NavLink} from 'react-router-dom';

export interface IAppPageHeader extends UiCustomizable, Partial<UiWithIcon> {
  title?: string;
  subtitle?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  backAction?: {
    icon?: IIconName;
    iconSet?: IIconSetName;
    path?: string;
    exact?: boolean;
  }
}

export const AppPageHeader: React.FC<IAppPageHeader> = (props) => {
  const {children, title, subtitle, headingLevel = 1, icon, iconSet = 'regular', backAction, className, style} = props;
  const titleProps = {text: title, icon, iconSet, headingLevel};

  return <header className={classNames('app-page-header', className)}
                 style={style}>
    {backAction?.path && <NavLink className={'app-page-header__back-action'}
                                  to={backAction.path}
                                  exact={!!backAction?.exact}>
      <UiIcon name={backAction?.icon || 'caret-left'}
              iconSet={backAction?.iconSet || 'regular'} />
    </NavLink>}
    {(isNonEmptyStr(title) || isNonEmptyStr(subtitle)) && <UiTextTitle className={'app-page-header__title'}
                                                                       {...titleProps} />}
    {children}
  </header>;
};
