import {useCallback, useEffect, useMemo, useState} from 'react';
import {debounce} from 'throttle-debounce';

export const useRevisionState = () => {
  const [revision, setRevision] = useState(1);
  const bumpRevision = useCallback(() => setRevision(x => x + 1), [setRevision]);
  return useMemo(() => ({revision, bumpRevision}), [revision, bumpRevision]);
};


export const useWindowSizeKey = (timeout: number = 256) => {
  const [size, setSize] = useState({width: document.body.clientWidth, height: document.body.clientHeight});
  const sizeKey = useMemo(() => `${size.width}x${size.height}`, [size]);

  useEffect(
    () => {
      const listener = debounce(timeout, () => {
        setSize({width: document.body.clientWidth, height: document.body.clientHeight});
      });

      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    },
    [setSize, timeout]
  );

  return sizeKey;
};
