import React, {useMemo} from 'react';
import {UiBox, UiColor, UiFlexCol, UiFlexRow, UiTextLine, UiTextTitle, useTranslator} from '@bitsolve/react-common';
import {SimpleChartBar} from './simple-chart-bar.component';
import {isStr} from '@bitsolve/fns';
import {animated, useTrail} from 'react-spring';
import {UiValueParts} from '../../../ui/component/ui-value-parts.component';
import {TranslateFn} from '../../../../core/util';

export interface ILRUnitChartData {
  leftValue: number;
  rightValue: number;
  unit?: string;
  centerContent?: any;
  color?: UiColor | string;
}

export interface ILRUnitChart {
  leftTitle?: string;
  rightTitle?: string;
  data: ILRUnitChartData[];
  showDataAggregate?: boolean;
  aggregateData?: (data: ILRUnitChartData[], t: TranslateFn) => ILRUnitChartData | undefined;
}

export const aggregateDataMax: ILRUnitChart['aggregateData'] = (data, t) => {
  return {
    leftValue: Math.fround(data.reduce((r, d) => isStr(d.leftValue) ? r : r + d.leftValue, 0)),
    rightValue: Math.fround(data.reduce((r, d) => isStr(d.rightValue) ? r : r + d.rightValue, 0)),
    centerContent: <UiTextLine text={t('app.analysis.details.total')} />,
  };
};

export const aggregateDataAvg: ILRUnitChart['aggregateData'] = (data, t) => {
  return {
    leftValue: Math.fround(data.reduce((r, d) => isStr(d.leftValue) ? r : r + d.leftValue, 0) / data.length),
    rightValue: Math.fround(data.reduce((r, d) => isStr(d.rightValue) ? r : r + d.rightValue, 0) / data.length),
    centerContent: <UiTextLine text={t('app.analysis.details.total')} />,
  };
};

export const LRUnitChart: React.FC<ILRUnitChart> = (props) => {
  const {leftTitle, rightTitle, showDataAggregate = true, aggregateData = aggregateDataMax, data} = props;
  const t = useTranslator();

  const aggregate = useMemo(
    () => showDataAggregate ? aggregateData(data, t) : null,
    [data, showDataAggregate, aggregateData, t]
  );

  const leftMax = useMemo(
    () => Math.max.apply(undefined, data.map(d => d.leftValue)),
    [data]
  );

  const rightMax = useMemo(
    () => Math.max.apply(undefined, data.map(d => d.rightValue)),
    [data]
  );

  const trail = useTrail(data.length, {
    config: {mass: 3, tension: 4500, friction: 200},
    opacity: 1,
    from: {opacity: 0},
  });

  return <UiBox className="app-analysis__chart app-analysis__chart--lr-unit">
    <UiFlexRow className={'app-analysis__chart--lr-unit__title'}>
      <UiTextLine className="f-1 pd-r-sm"
                  text={leftTitle} />
      <UiTextLine className="f-1 pd-l-sm txt-ar"
                  text={rightTitle} />
    </UiFlexRow>
    <UiFlexCol className={'app-analysis__chart--lr-unit__content'}>
      {trail.map((anim, index) => {
        const entry = data[index];

        return <animated.div key={index}
                             className={'d-f fd-h f-ai-c pd-v-sm'}
                             style={anim}>
          <UiBox className={'f-1'}>
            <UiTextTitle text={`${entry.leftValue}`} className={'mg-b-xs'} />
            <SimpleChartBar progress={1 / leftMax * entry.leftValue}
                            direction={'rtl'}
                            color={entry.color || UiColor.accent} />
            {entry.unit && <UiTextLine className={'mg-t-xs txt-sm txt-c-defd'}
                                       text={entry.unit} />}
          </UiBox>
          <UiBox className={'pd-h-nm txt-ac'}
                 style={{width: '12%', minWidth: '5rem'}}>
            {entry.centerContent}
          </UiBox>
          <UiBox className={'f-1 txt-ar'}>
            <UiTextTitle text={`${entry.rightValue}`} className={'f-jc-e mg-b-xs'} />
            <SimpleChartBar progress={1 / rightMax * entry.rightValue}
                            direction={'ltr'}
                            color={entry.color || UiColor.accent} />
            {entry.unit && <UiTextLine className={'mg-t-xs txt-sm txt-c-defd'}
                                       text={entry.unit} />}
          </UiBox>
        </animated.div>;
      })}
    </UiFlexCol>
    {showDataAggregate && aggregate && <UiFlexRow className={'app-analysis__chart--lr-unit__footer'}>
      <UiValueParts value={aggregate.leftValue}
                    className={'f-1 txt-md f-jc-s ui-text ui-text-title'} />
      <UiBox className="txt-c-defd">
        {aggregate?.centerContent || null}
      </UiBox>
      <UiValueParts value={aggregate.rightValue}
                    className={'f-1 txt-md f-jc-e ui-text ui-text-title'} />
    </UiFlexRow>}
  </UiBox>;
};
