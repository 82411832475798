import React from 'react';
import {isNotNil, isTrue, propIn} from '@bitsolve/fns';
import {classNames, UiBox, UiCustomizable, UiFlexRow} from '@bitsolve/react-common';
import {useAppData} from '../../../core/system';

export interface IAppDevtoolShellCommand extends UiCustomizable {
  command: string;
}

export const AppDevtoolShellCommand: React.FC<IAppDevtoolShellCommand> = (props) => {
  const {command, style, className} = props;

  return <UiFlexRow className={classNames('app-devtool__shell-snip', className)}
                    style={style}>
    <code className={'txt-b'}>{'$'}</code>
    <samp style={{userSelect: 'text'}}
          className={'f-1 mg-l-sm'}>{command}</samp>
  </UiFlexRow>;
};

export interface IAppDevtool extends UiCustomizable {
  never?: boolean;
}

export const AppDevtool: React.FC<IAppDevtool> = (props) => {
  const {never, className, style} = props;
  const config = useAppData();

  const showDevTools = isNotNil(config) && propIn(config as any, ['data', 'devTools'], false);

  if (!isTrue(showDevTools) || isTrue(never)) {
    return null;
  }

  return <UiBox className={classNames('app-devtool', className)}
                style={style}
                tag={'aside'}>
    {props.children}
  </UiBox>;
};
