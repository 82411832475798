import {API_PREFIX} from '../../core/constants';
import {IHttpRequest, IHttpRequestHookOptions, useHttpRequest} from '@bitsolve/react-common';
import {useMemo} from 'react';
import {disciplinesExcludedFromTrainingPlans} from '../../core/domain.model';

const baseUrl = `${API_PREFIX}/v1/content/static`;

export const findStaticCountriesRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/countries`});
export const findStaticDisciplinesRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/discipline`});
export const findStaticFeedbackRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/feedback`});
export const findStaticFightStylesRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/fightStyle`});
export const findStaticGendersRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/gender`});
export const findStaticSkillLevelsRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/skillLevel`});
export const findStaticStancesRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/stance`});
export const findStaticTrainerTypesRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/trainerType`});
export const findStaticUnitsRequest = (): IHttpRequest => ({method: 'get', url: `${baseUrl}/units`});


export const useFindStaticCountries = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ name: string; alpha2code: string; }[]>(findStaticCountriesRequest(), opts);

export const useFindStaticDisciplines = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticDisciplinesRequest(), opts);


export const useFindFeedbackReasons = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticFeedbackRequest(), opts);


export const useFindStaticTrainingPlanDisciplines = (opts?: IHttpRequestHookOptions) => {
  const request = useFindStaticDisciplines(opts);
  const values = request?.response?.data?.values;
  const filteredValues = useMemo(() => values
    ? values.filter(v => !disciplinesExcludedFromTrainingPlans.has(v))
    : values, [values]);

  return {
    ...request,
    response: {
      ...request?.response,
      data: {...request?.response?.data, values: filteredValues}
    }
  };
};

export const useFindStaticFightStyles = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticFightStylesRequest(), opts);

export const useFindStaticGenders = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticGendersRequest(), opts);

export const useFindStaticSkillLevels = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticSkillLevelsRequest(), opts);

export const useFindStaticStances = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticStancesRequest(), opts);

export const useFindStaticTrainerTypes = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticTrainerTypesRequest(), opts);

export const useFindStaticUnits = (opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ values: string[]; }>(findStaticUnitsRequest(), opts);


export const staticCountriesControlProps = {
  optionRequest: findStaticCountriesRequest(),
  optionMapper: (data: { name?: string; alpha2code: string }) => ({
    label: data.name || `content.country.${data.alpha2code}`,
    value: data.alpha2code,
  })
};

export const staticFeedbackControlProps = {
  optionRequest: findStaticFeedbackRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.feedback.${val}`,
    value: val,
  }))
};


export const staticGendersControlProps = {
  optionRequest: findStaticGendersRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.gender.${val}`,
    value: val,
  }))
};

export const staticTrainerTypesControlProps = {
  optionRequest: findStaticTrainerTypesRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.trainerType.${val}`,
    value: val,
  }))
};


export const staticStancesControlProps = {
  optionRequest: findStaticStancesRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.stance.${val}`,
    value: val,
  }))
};

export const staticSkilllevelsControlProps = {
  optionRequest: findStaticSkillLevelsRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.skillLevel.${val}`,
    value: val,
  }))
};

export const staticUnitsControlProps = {
  optionRequest: findStaticUnitsRequest(),
  optionMapper: (data: { values: string[] }) => data?.values?.map(val => ({
    label: `content.unitSystem.${val}`,
    value: val,
  }))
};
