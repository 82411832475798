import React from 'react';
import {IUiButton, UiButtonGroup, UiTextBlock, useTranslator} from '@bitsolve/react-common';
import {animated} from 'react-spring';
import {ChangeContextConsumer} from '../../ui/component/ui-change-context.component';

export const ClubChangesBar: React.FC<{ actions: IUiButton[]; }> = (props) => {
  const {actions} = props;

  const t = useTranslator();

  return <ChangeContextConsumer>
    {context => context.dirty && <animated.nav className={'pd-nm d-f fd-h f-ai-c f-jc-e'}
                                               style={{
                                                 backgroundColor: 'rgba(8,25,35,0.825)',
                                                 position: 'absolute',
                                                 bottom: 0,
                                                 right: 0,
                                                 borderTopLeftRadius: '0.325rem'
                                               }}>
      <UiTextBlock className={'txt-sm mg-r-nm'}
                   style={{maxWidth: '10rem'}}
                   text={t('general.changes.title')} />
      <UiButtonGroup actions={actions} />
    </animated.nav>}
  </ChangeContextConsumer>
}
