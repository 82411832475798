import React, {useRef, useState} from 'react';
import {
  blobAsDataURL,
  classNames,
  IUiFieldProps,
  trapEvent,
  UiCustomizable,
  UiState,
  useFileDialog
} from '@bitsolve/react-common';
import {hasProp, isFn, isNil, isNonEmptyStr} from '@bitsolve/fns';
import {useFindImage, usePictureData, useUploadImage} from '../../image/image.api';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';

export interface IUiPictureFieldControl extends IUiFieldProps<string> {
  controlProps: {
    renderPlaceholder: () => any;
    renderValue: (value: any, controlProps: any, idValue?: string) => any;
    previewOptions?: {
      width?: number;
      height?: number;
    };
  } & UiCustomizable;
}

const trapFocus = trapEvent<any>();

export const UiPictureFieldControl: React.FC<IUiPictureFieldControl> = (props) => {
  const {value, onChange, style, state} = props;
  const {className, renderPlaceholder, renderValue, previewOptions, ...controlProps} = props.controlProps;

  const resolving = useRef(false);
  const [busy, setBusy] = useState<boolean>(false);

  // eslint-disable-next-line
  const [_, setProgress] = useState<number | undefined>();
  const {dataUrl, setDataUrl, setError} = usePictureData(value, previewOptions);

  const findImage = useFindImage(previewOptions);
  const uploadImage = useUploadImage(ev => {
    const {total, loaded} = ev;

    if (isNil(total) || isNil(loaded)) {
      return;
    }

    try {
      const progress = Math.ceil(Math.max(0, Math.min(100, 100 / total * loaded))) / 100;
      setProgress(progress);
    } catch (e) {
      setProgress(undefined);
    }
  });

  const filePicker = useFileDialog({
    multiple: false,
    accept: '.jpg,.jpeg,.png,.gif'
  });

  const hasValue = isNonEmptyStr(value);
  const anyBusy = (busy || uploadImage.busy);

  return <div
    key={(dataUrl ? `${value}.${dataUrl.length}` : value) || '_none'}
    className={classNames(className)}
    style={{
      ...style,
      border: 0,
      minWidth: 0,
      padding: 0,
      position: 'relative'
    }}
    tabIndex={0}
    onFocus={state !== UiState.active ? trapFocus : undefined}
    onBlur={state !== UiState.active ? trapFocus : undefined}
    onClick={() => {
      if (resolving.current || anyBusy || state !== UiState.active) return;

      resolving.current = true;

      filePicker.openDialog()
        .then((result) => {
          if (isNil(result) || !((result instanceof File) || (result instanceof FileList))) {
            return;
          }

          const file = (result instanceof FileList)
            ? result.item(0)
            : result;

          return blobAsDataURL(file as File)
            .then(setDataUrl)
            .then(() => {
              requestAnimationFrame(() => setBusy(true));
              return uploadImage.send(file as File);
            });
        })
        .then(r => {
          if (isNil(r) || !hasProp(r?.data, 'mediaId')) return;
          const mediaId = r?.data?.mediaId;
          return mediaId
            ? Promise.resolve(mediaId as string)
            : Promise.reject(new Error('no media id in response'))
        })
        .then((mediaId) => {
          return mediaId
            ? findImage.send(mediaId).then(response => Promise.resolve({response, mediaId}))
            : Promise.reject(new Error('no media id in response'))
        })
        // .then((result) => {
        //   const {mediaId, response} = result;
        //   return blobAsDataURL(response.data).then(dataUrl => ({dataUrl, response, mediaId}));
        // })
        .then(({mediaId}) => {
          setBusy(false);
          if (mediaId && mediaId !== value && isFn(onChange)) onChange(mediaId);
        })
        .catch((e) => {
          setBusy(false);
          setError(e);
        }).finally(() => {
        resolving.current = false;
      });
    }}>
    {hasValue
      ? renderValue(dataUrl, controlProps, value)
      : renderPlaceholder()}
    {(busy || uploadImage.busy)
    && <AppLoadingSpinner style={{position: 'absolute'}} />}
  </div>;
};
