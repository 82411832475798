import React, {createElement, useMemo} from 'react';
import {classNames, UiColor, UiCustomizable, UiTagOverride, UiTextLine} from '@bitsolve/react-common';
import {NavLink} from 'react-router-dom';

export interface IAppTabNavigationItem {
  to: string;
  label?: string;
  exact?: boolean;
}

export interface IAppTabNavigation extends UiCustomizable, UiTagOverride {
  items: IAppTabNavigationItem[];
  color?: UiColor;
}

const _baseClass = `app-tab-navigation`;
const _baseItemClass = `app-tab-navigation__item`;

const renderTabItems = (items: IAppTabNavigationItem[]) =>
  items.map((item, i) => {
    const {to, label, exact} = item;
    return createElement(
      NavLink,
      {to, exact, className: _baseItemClass, key: `${i}.${to}`},
      createElement(UiTextLine, {text: label})
    );
  });

export const AppTabNavigation: React.FC<IAppTabNavigation> = (props) => {
  const {tag = 'menu', items, color = UiColor.accent, className, style} = props;
  const children = useMemo(() => renderTabItems(items), [items]);

  return createElement(
    tag,
    {
      className: classNames(_baseClass, color && `${_baseClass}--${color}`, className),
      style,
      children
    }
  );
};
