import React, {useEffect, useState} from 'react';
import {classNames, IUiFieldProps, UiBox, UiCustomizable, UiIcon, UiState, useDebounce} from '@bitsolve/react-common';
import {UiNumberRangeInput} from './ui-number-range-input.component';
import {isNonEmptyStr} from '@bitsolve/fns';

export interface IUiNumberSlider extends UiCustomizable, Partial<Omit<IUiFieldProps<string>, 'id' | 'fieldType' | 'name'>> {
  name?: string;
  controlProps: {
    unitText?: string;
    step?: number;
    min?: number;
    max?: number;
  };
}

export const UiNumberSlider: React.FC<IUiNumberSlider> = (props) => {
  const {className, style, controlProps, value = '0', onChange, state, ...rest} = props;
  const [_value, setValue] = useState<string>(isNonEmptyStr(value) ? value : '0');
  const _debValue = useDebounce(_value, 64);
  const {min = 0, max = Number.MAX_SAFE_INTEGER, step = 1, unitText} = controlProps;
  const s = state || UiState.active;
  const isActive = s === UiState.active;

  useEffect(() => {
    if (!onChange || value === _debValue || !isActive) {
      return;
    }

    onChange(_debValue);
  }, [_debValue, value, onChange, isActive])

  return <UiBox className={classNames('ui-number-slider', className)}
                style={style}>
    <button
      type={'button'}
      tabIndex={-1}
      disabled={!isActive}
      aria-disabled={!isActive}
      className={classNames(
        'ui-number-slider__stepper',
        'ui-number-slider__stepper--next',
        !isActive && 'ui-number-slider__stepper--disabled',
      )}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!isActive) return;
        const next = Math.min(parseInt(_value, 10) + step, max || Number.MAX_SAFE_INTEGER);
        setValue(`${next}`)
      }}>
      <UiIcon name={'caret-up'}
              iconSet={'regular'} />
    </button>
    <UiBox className={'ui-number-slider__content'}>
      <UiNumberRangeInput {...rest}
                          min={min}
                          max={max}
                          step={step}
                          pattern={''}
                          state={s}
                          value={_value}
                          onChange={setValue} />
      {unitText && <span className="ui-number-slider__unit-text">{unitText}</span>}
    </UiBox>
    <button
      type={'button'}
      tabIndex={-1}
      disabled={!isActive}
      aria-disabled={!isActive}
      className={classNames(
        'ui-number-slider__stepper',
        'ui-number-slider__stepper--prev',
        !isActive && 'ui-number-slider__stepper--disabled',
      )}
      onClick={(ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!isActive) return;
        const next = Math.max(parseInt(_value, 10) - step, min);
        setValue(`${next}`);
      }}>
      <UiIcon name={'caret-down'}
              iconSet={'regular'} />
    </button>
  </UiBox>;
};
