import React, {useMemo} from 'react';
import {IAnalysisDetailsPageSegmentChartOrFn} from './analysis-details.model';
import {IRoute} from '../../../core/routing';
import {isFn, isNotNil} from '@bitsolve/fns';
import {useChartComponent} from './analysis-details.hooks';

export const AnalysisDetailsPageSegmentChart: React.FC<{
  chart: IAnalysisDetailsPageSegmentChartOrFn;
  route?: IRoute;
}> = ({chart, route}) => {

  const _chart = useMemo(
    () => !!chart && isFn(chart) && isNotNil(route)
      ? chart(route as any)
      : chart,
    [chart, route]
  );

  return useChartComponent(
    _chart,
    route
  );
};
