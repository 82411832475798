import {schema, UiDir} from '@bitsolve/react-common';
import {
  staticCountriesControlProps,
  staticGendersControlProps,
  staticTrainerTypesControlProps
} from '../content/content.api';


export const authLoginFields = {
  email: {
    name: 'email',
    label: 'app.login.form.email.label',
    fieldType: 'text',
    direction: UiDir.v,
    controlProps: {autoFocus: true, autoComplete: 'off'}
  },
  password: {
    name: 'password',
    label: 'app.login.form.password.label',
    fieldType: 'password',
    direction: UiDir.v,
    controlProps: {autoComplete: 'off'}
  }
};

export const authRegisterFields = {
  email: {
    name: 'email',
    label: 'app.register.form.email.label',
    fieldType: 'text',
    direction: UiDir.v,
    controlProps: {autoFocus: true}
  },
  password: {
    name: 'password',
    label: 'app.register.form.password.label',
    fieldType: 'password',
    direction: UiDir.v
  },
  password_confirm: {
    name: 'password_confirm',
    label: 'app.register.form.password_confirm.label',
    fieldType: 'password',
    direction: UiDir.v
  },
};

export const authProfileFields = {
  firstName: {
    name: 'firstName',
    label: 'app.account.profile.form.firstName.label',
    fieldType: 'text',
    controlProps: {
      autoFocus: true
    }
  },
  lastName: {
    name: 'lastName',
    label: 'app.account.profile.form.lastName.label',
    fieldType: 'text'
  },
  hometown: {
    name: 'hometown',
    label: 'app.account.profile.form.hometown.label',
    fieldType: 'text'
  },
  birthDay: {
    name: 'birthDay',
    label: 'app.account.profile.form.birthDay.label',
    fieldType: 'date-inputs',
    direction: UiDir.v,
    controlProps: {
      previewIcon: 'appCalendar',
      outputFormat: 'YYYY-MM-DDTHH:mm',
      displayFormat: 'DD.MM.YYYY',
    }
  },
  gender: {
    name: 'gender',
    label: 'app.account.profile.form.gender.label',
    fieldType: 'content-select',
    className: 'hide-placeholder',
    direction: UiDir.v,
    controlProps: staticGendersControlProps,
  },
  countryIsoCode: {
    name: 'countryIsoCode',
    label: 'app.account.profile.form.countryIsoCode.label',
    fieldType: 'content-select',
    className: 'hide-placeholder',
    direction: UiDir.v,
    controlProps: staticCountriesControlProps
  },
};

export const authTrainerProfileFields = {
  trainerType: {
    name: 'trainerType',
    fieldType: 'content-select',
    label: 'app.account.trainerProfile.form.trainerType.label',
    controlProps: staticTrainerTypesControlProps,
  },
  fightStyles: {
    name: 'fightStyles',
    fieldType: 'fight-style-select',
    controlProps: {}
  },
  customFightStyles: {
    name: 'customFightStyles',
    fieldType: 'custom-fight-styles',
    controlProps: {}
  },
};

export const authProfileSchema = {
  firstName: schema.mixed(),
  lastName: schema.mixed(),
  birthDay: schema.date(),
  gender: schema.mixed(),
  hometown: schema.mixed(),
  countryIsoCode: schema.string().nullable(),
};
