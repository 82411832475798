import React, {useCallback, useMemo, useState} from 'react';
import {
  classNames,
  DateFieldControlContent,
  IPaginatedHttpRequestHookResult,
  IUiFieldProps,
  IUiFormContext,
  Page,
  UiBox,
  UiButton,
  UiButtonGroup,
  UiButtonVariation,
  UiColor,
  UiDateDisplay,
  UiDir,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiForm,
  UiIcon,
  UiPageContainer,
  UiPageSegment,
  UiTextBlock,
  UiTextLine,
  UiTextTitle,
  UiToolbar,
  useFormContext,
  useLocale,
  useTranslator
} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {AppPageSectionInset} from '../../../common/page/app-page-section-inset.component';
import {IUiSelectionState, useSelectionState} from '../../../../module/ui/ui.util';
import {animated, useSpring, useTrail} from 'react-spring';
import {AnalysisTrainingSessionBreakdown} from '../../../../module/analysis/component/analysis-training-session-breakdown.component';
import {AppPagePanelModal} from '../../../common/page/app-page-panel-modal.component';
import {isEmpty, isEmptyStr, isFn, isNil, isNonEmptyStr, isNotNil, prop} from '@bitsolve/fns';
import {IAuthUserAccount, useUserAccountName} from '../../../../module/auth';
import {AppPageMenu} from '../../../common/page/app-page-menu.component';
import {Route, Switch} from 'react-router';
import {useNavigator} from '../../../../core/routing';
import {AppLoadingSpinner} from '../../../common/misc/app-loading-spinner.component';
import {useFindSessions} from '../../../../module/analysis/analysis.api';
import {IAnalysisSessionV2} from '../../../../module/analysis/analysis.model';
import {useFormattedDuration, useQueryParam} from '../../../../core/util';
import {Rooq, useShouldShowTour} from '../../../../core/domain.model';
import {UiPager} from '../../../../module/ui/component/ui-pager.component';
import {useFindTrainingSessionFilterCandidates} from '../../../../module/training/training.api';
import {useAuthAccount} from '../../../../module/auth/auth.store';
import {useShopRedirect} from '../../../../module/shop/shop.api';
import moment from 'moment';
import {ArrowSide, UiTourTooltip} from '../../../../module/ui/component/ui-tour-tooltip.component';


const SessionSelectIcon: React.FC<{ active?: boolean; }> = (props) => {
  const {active = false} = props;

  return <svg key={active ? '_s1' : '_s0'}
              width={'1rem'} height={'1rem'}
              viewBox={'0 0 100 100'}>
    {active
      ? <>
        <circle cx="50" cy="50" r="45"
                fill={'#fff'}
                strokeWidth={0} />
        <path d="M76 34L43.208 66 27 50.184"
              strokeWidth={10} stroke={'#0A1F30'} />
      </>
      : <>
        <circle cx="50" cy="50" r="45"
                strokeWidth={5} stroke={'#2D3F4D'} />
        <path d="M76 34L43.208 66 27 50.184"
              strokeWidth={10} stroke={'#2D3F4D'} />
      </>}
  </svg>
};

// <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" stroke="white" stroke-opacity="0.1" />
//   <path d="M16.5001 7.49994L9.39527 14.6047L5.88362 11.0931" stroke="white" stroke-opacity="0.1" stroke-width="2" />
// </svg>


const AnalysisFilterItem: React.FC<{
  label: string;
  value?: any;
  hasValue?: boolean;
  alwaysShowValue?: boolean;
  onClick: Function;
  onUnset: Function;
}> = (props) => {
  const {label, value, hasValue, alwaysShowValue = hasValue, onClick, onUnset} = props;

  return <div className={hasValue
    ? 'app-analysis__filter__item app-analysis__filter__item--active'
    : 'app-analysis__filter__item'}
              onClick={() => onClick && onClick()}>
    <div className="app-analysis__filter__item__label">
      {label}
    </div>
    <div className="app-analysis__filter__item__value">
      {(hasValue || alwaysShowValue) ? value : '–'}
    </div>
    {hasValue && <div className="app-analysis__filter__item__unset"
                      onClick={(ev) => {
                        ev.stopPropagation();
                        onUnset && onUnset();
                      }}>
      {'x'}
    </div>}
  </div>;
};

export interface ICoachingZoneAnalysisFilters {
  athletes: string[] | null;
  date: string | null;
  favoritesOnly: boolean | null;
}

const defaultCoachingZoneAnalysisFilters: ICoachingZoneAnalysisFilters = {
  athletes: null,
  date: null,
  favoritesOnly: false
};

const coachingZoneAnalysisFilterFields: IUiFieldProps<ICoachingZoneAnalysisFilters>[] = [];

type ICoachingZoneAnalysisAthleteFilterModal = {
  athletes: IAuthUserAccount[];
  selected?: string[] | null;
  onSelect?: (selection: Set<string>) => any;
  onReset?: () => any;
};

const CoachingZoneAnalysisAthleteFilterModalList: React.FC<{ selection: IUiSelectionState; athletes: IAuthUserAccount[]; }> = (props) => {
  const {selection, athletes} = props;
  const t = useTranslator();

  return <>
    {isNotNil(athletes) && !isEmpty(athletes)
      ? athletes.map(athlete => {
        const isSelected = selection.selected.has(athlete.id);

        return <div key={athlete.id}
                    className={classNames(`pd-v-sm pd-h-nm d-f fd-h f-ai-c`, !isSelected && 'txt-c-defd')}
                    style={{cursor: 'pointer', borderBottom: '1px solid #2D3F4D'}}
                    onClick={(ev) => {
                      ev.persist();
                      ev.stopPropagation();
                      selection.toggle(athlete.id);
                    }}>
          <UiTextLine className={'pd-r-nm f-1'}
                      text={`${athlete.firstName} ${athlete.lastName}`} />
          <SessionSelectIcon active={isSelected} />
        </div>
      })
      : <UiFlexCol className={'f-1 ui-fx ui-fx__pane-fade-in'}
                   ai={UiFlexAlign.c}
                   jc={UiFlexAlign.c}>
        <UiTextLine className={'txt-sm txt-ac txt-c-defd'}
                    text={t('general.search.empty')} />
      </UiFlexCol>}
  </>;
}

const CoachingZoneAnalysisAthleteFilterModal: React.FC<{
  selection: IUiSelectionState;
  athletes: IAuthUserAccount[];
  selectorOpen: boolean;
  setSelectorOpen: Function;
  onSelect: Function;
  reset: Function;
}> = (props) => {
  const {selection, athletes, selectorOpen, setSelectorOpen, onSelect, reset} = props;
  const t = useTranslator();

  return <AppPagePanelModal
    panel={{
      title: {
        text: t('app.analysis.table.filter.atheletes.modal.title'),
        className: 'd-f fd-h',
        children: <UiBox className="f-1 d-f fd-h f-jc-e">
          <UiButton size={'sm'}
                    icon={'times'}
                    variation={UiButtonVariation.semantic}
                    interact={() => setSelectorOpen(false)} />
        </UiBox>
      },
      style: {
        maxHeight: '100%',
        minHeight: 0,
        overflow: 'hidden',
      }
    }}
    modal={{
      isOpen: selectorOpen,
      onRequestClose: () => setSelectorOpen(false)
    }}>
    <UiBox className={'f-1 d-f fd-v'}
           style={{
             maxHeight: '100%',
             overflow: 'auto',
             backgroundColor: 'rgba(0,0,0,0.1125)',
             borderBottom: '1px solid rgba(0,0,0,0.15)'
           }}>
      <CoachingZoneAnalysisAthleteFilterModalList key={selection.revision}
                                                  selection={selection}
                                                  athletes={athletes} />
    </UiBox>
    <UiButtonGroup direction={UiDir.h}
                   className={'pd-nm'}
                   actions={[
                     {
                       label: t('general.action.reset'),
                       color: UiColor.primary,
                       className: 'f-1',
                       interact: reset as any
                     },
                     {
                       label: t('general.action.apply-changes'),
                       color: UiColor.accent,
                       className: 'txt-b f-1',
                       interact: () => {
                         isFn(onSelect) && onSelect(selection.selected);
                         setSelectorOpen(false);
                       }
                     },
                   ]} />
  </AppPagePanelModal>;
}

const CoachingZoneAnalysisAthleteFilter: React.FC<ICoachingZoneAnalysisAthleteFilterModal> = (props) => {
  const {athletes, selected, onSelect, onReset} = props;

  const t = useTranslator();
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
  const selection = useSelectionState<string>(selected || []);
  const anySelected = (selected?.length || 0) > 0;

  const reset = useCallback(
    () => {
      selection.reset();
      isFn(onReset) && onReset();
      setSelectorOpen(false);
    },
    [selection, onReset]
  );

  return <>
    <AnalysisFilterItem label={t('app.analysis.table.filter.atheletes.label')}
                        hasValue={anySelected}
                        value={anySelected ? `${selection.selected.size}` : undefined}
                        onClick={() => setSelectorOpen(true)}
                        onUnset={reset} />
    <CoachingZoneAnalysisAthleteFilterModal athletes={athletes}
                                            selection={selection}
                                            selectorOpen={selectorOpen}
                                            setSelectorOpen={setSelectorOpen}
                                            onSelect={onSelect as any}
                                            reset={reset} />
  </>
}

type ICoachingZoneAnalysisTimeframeFilterModal = {
  selected?: string | null;
  onSelect?: (selection: string) => any;
  onReset?: () => any;
};

const CoachingZoneAnalysisTimeframeFilterModal: React.FC<ICoachingZoneAnalysisTimeframeFilterModal> = (props) => {
  const {selected, onSelect, onReset} = props;
  const t = useTranslator();
  const locale = useLocale();
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
  const form = useFormContext();

  return <>
    <AnalysisFilterItem label={t('app.analysis.table.filter.date.label')}
                        hasValue={!!selected}
                        value={selected}
                        onClick={() => {
                          setSelectorOpen(!selectorOpen);
                        }}
                        onUnset={() => {
                          isFn(onReset) && onReset();
                          setSelectorOpen(false);
                        }} />
    <AppPagePanelModal
      panel={{
        title: {
          text: t('app.analysis.table.filter.date.title'),
          className: 'd-f fd-h',
          children: <UiBox className="f-1 d-f fd-h f-jc-e">
            <UiButton size={'sm'}
                      icon={'times'}
                      variation={UiButtonVariation.semantic}
                      interact={() => setSelectorOpen(false)} />
          </UiBox>
        },
        style: {
          maxHeight: '100%',
          minHeight: 0,
          overflow: 'hidden',
        }
      }}
      modal={{
        isOpen: selectorOpen,
        onRequestClose: () => setSelectorOpen(false)
      }}>
      <UiBox className={'pd-nm f-1'}
             style={{
               maxHeight: '100%',
               maxWidth: '32rem',
               overflow: 'auto',
               backgroundColor: 'rgba(0,0,0,0.1125)',
               borderBottom: '1px solid rgba(0,0,0,0.15)'
             }}>
        <DateFieldControlContent value={selected as any}
                                 onChange={(sel) => {
                                   form.setFieldValue('date', sel);
                                 }}
                                 open={true}
                                 calendar={{locale}}
                                 outputFormat={'YYYY-MM-DD'}
                                 toggle={() => {
                                 }} />
      </UiBox>
      <UiButtonGroup direction={UiDir.h}
                     className={'pd-nm'}
                     actions={[
                       {
                         label: t('general.action.reset'),
                         color: UiColor.primary,
                         className: 'f-1',
                         interact: () => {
                           form.setFieldValue('date', null);
                           setSelectorOpen(false);
                           isFn(onReset) && onReset();
                         }
                       },
                       {
                         label: t('general.action.apply-changes'),
                         color: UiColor.accent,
                         className: 'txt-b f-1',
                         interact: () => {
                           onSelect && onSelect(prop(form.value, 'date'))
                           setSelectorOpen(false);
                         }
                       },
                     ]} />
    </AppPagePanelModal>
  </>;
};

const CoachingZoneAnalysisFilterToolbar: React.FC<{ value: ICoachingZoneAnalysisFilters; onChange: (value: Partial<ICoachingZoneAnalysisFilters>) => any; }> = (props) => {
  const {value, onChange} = props;
  const req = useFindTrainingSessionFilterCandidates();
  const accs = req?.response?.data?.elements || [];
  const t = useTranslator();
  const selected = useMemo(() => value?.athletes || [], [value]);

  return <UiToolbar className={'f-1'}>
    <UiToolbar.Slot className={'pd-r-nm'}
                    style={{borderRight: '1px solid rgb(53 71 88)', opacity: 0.75}}>
      <UiTextTitle className={'txt-sm txt-uc'}
                   text={t('app.analysis.table.filter.title')} />
    </UiToolbar.Slot>
    <UiToolbar.Slot className={'mg-h-nm'}>
      <CoachingZoneAnalysisAthleteFilter
        athletes={accs}
        selected={selected}
        onSelect={selection => onChange({...value, athletes: Array.from(selection.values()).sort()})}
        onReset={() => onChange({...value, athletes: []})} />
    </UiToolbar.Slot>
    <UiToolbar.Slot className={'mg-r-nm'}>
      <CoachingZoneAnalysisTimeframeFilterModal
        selected={value.date as any}
        onSelect={(date: string) => onChange({...value, date})}
        onReset={() => onChange({...value, date: undefined})} />
    </UiToolbar.Slot>
  </UiToolbar>;
};

const _analysisSessionTitleStyle = {
  gridTemplateColumns: 'repeat(5, minmax(0, min-content))',
  gridColumnGap: '1.25em'
};

type TrainingSessionSelection = IUiSelectionState<IAnalysisSessionV2['id']>;

const SessionScore: React.FC<{ score?: number; }> = (props) => {
  const {score} = props;
  const t = useTranslator();

  return <UiBox className={'d-f fd-v f-ai-e'}>
    <UiTextTitle className={'txt-md txt-c-defd'}
                 text={`${score || '—'}`} />
    <div>{t('app.analysis.details.chart.unit.score')}</div>
  </UiBox>;
};

const CoachingZoneAnalysisSessionRemark: React.FC<{ session: IAnalysisSessionV2; }> = (props) => {
  const {session} = props;

  if (isNil(session?.origin) || isEmptyStr(session.origin)) {
    return null;
  }

  return <UiBox className={'d-if fd-h f-ai-c pd-v-xs pd-h-sm txt-sm mg-t-xs'}
                style={{backgroundColor: 'rgba(255,255,255,0.05)', borderRadius: '0.3875em'}}>
        <span className="txt-c-defd mg-r-sm"
              style={{lineHeight: '1', position: 'relative', top: '-0.075em'}}>
          {'›'}
        </span>
    <UiTextLine className="txt-it txt-c-defd"
                text={`${session.origin}`} />
    <span className="txt-c-defd mg-l-sm"
          style={{lineHeight: '1', position: 'relative', top: '-0.075em'}}>
          {'‹'}
        </span>
  </UiBox>;
};

const CoachingZoneAnalysisSessionListItem: React.FC<{
  session: IAnalysisSessionV2;
  selection: TrainingSessionSelection;
  animation?: any;
}> = (props) => {
  const {session, selection, animation} = props;
  const t = useTranslator();
  const isSelected = selection.selected.has(session.id);
  const duration = useFormattedDuration(session?.duration ? session?.duration / 1000 : undefined);
  const name = useUserAccountName(session.author);

  const breakdownItems = useMemo(
    () => {
      const ds = session?.simpleChart?.disciplines;

      const displayExc = [
        Rooq.DisciplineV2.ROPE_SKIPPING,
        Rooq.DisciplineV2.PARTNER,
        Rooq.DisciplineV2.EQUIPMENT,
        Rooq.DisciplineV2.SHADOW,
      ];

      const data = displayExc.map(d => {
        const entry = ds?.find(({discipline}) => d === discipline);
        return entry || {discipline: d, duration: 0};
      });

      const dataSum = 1 / data.map(d => d.duration).reduce((r, d) => r + d, 0);

      return data.map(d => {
        d.duration *= dataSum;
        return d;
      });
    },
    [session]
  );

  const recorded = session?.recorded
    ? moment(session?.recorded + `Z`).toDate()
    : undefined;

  return <animated.div className={`app-coachz__analysis__session ${isSelected ? 'selected' : ''}`}
                       onClick={(ev) => {
                         ev.persist();
                         ev.stopPropagation();
                         selection.toggle(session.id);
                       }}
                       {...animation}>
    <figure className={'d-f fd-v f-jc-c f-ai-s app-coachz__analysis__session-segment first'}
            style={{
              margin: 0,
              padding: '1rem 0.5rem',
              cursor: 'pointer',
              borderLeft: `0.125em solid ${session.hasBeenSeen ? '#223545' : 'var(--color-bg--accent)'}`,
              backgroundColor: 'rgba(255,255,255,0.05)'
            }}
            role={'presentation'}>
      <UiIcon name={'check'}
              iconSet={'regular'}
              style={{
                color: isSelected
                  ? 'var(--color-text--inverse)'
                  : 'var(--color-text--primary)'
              }} />
    </figure>
    <main className={'pd-l-nm pd-r-md pd-v-nm d-f fd-v f-jc-c f-ai-s app-coachz__analysis__session-segment'}
          style={{cursor: 'pointer',}}
          onClick={(ev) => {
            ev.persist();
            ev.stopPropagation();
            selection.toggle(session.id);
          }}>
      <UiTextTitle style={{fontSize: 'var(--size-font--nm)', opacity: session.name ? 1 : '0.5'}}
                   text={name || '—'} />
      <UiBox className={'mg-t-xs d-g f-ai-c txt-c-defd txt-b txt-sm'}
             style={_analysisSessionTitleStyle}>
        {session.duration && <>
          {recorded && <UiDateDisplay format={t('general.date.medium')}
                                      date={recorded} />}
          {recorded && <UiDateDisplay format={t('general.time.medium')}
                                      date={recorded} />}
          {duration && <UiTextLine text={duration} />}
        </>}
      </UiBox>
    </main>
    <UiBox className={'pd-l-md d-f fd-v f-jc-c app-coachz__analysis__session-segment'}>
      <UiBox>
        <UiIcon name={'comment'}
                iconSet={'regular'}
                style={{opacity: session.hasComments ? 1 : 0.25}}
                className={session.hasComments ? 'txt-c-defd' : undefined} />
      </UiBox>
      <UiBox>
        <CoachingZoneAnalysisSessionRemark session={session} />
      </UiBox>
    </UiBox>
    <UiBox
      className={'pd-r-nm d-f fd-h f-jc-e f-ai-c app-coachz__analysis__session-segment'}>
      <SessionScore score={session?.simpleChart?.rooqScore} />
    </UiBox>
    <UiBox className={'pd-l-nm d-f fd-v app-coachz__analysis__session-segment last'}>
      {breakdownItems && <AnalysisTrainingSessionBreakdown className={'f-1'} items={breakdownItems} />}
      {/*{session.hasAbo && <UiIcon name={'star'}*/}
      {/*                           iconSet={'solid'}*/}
      {/*                           className={'app-coachz__analysis__session__abo-icon'} />}*/}
    </UiBox>
  </animated.div>
}

export interface ICoachingZoneAnalysisRoute {
}

const CoachingZoneAnalysisSelectionBar: React.FC<{
  fx: any;
  selection: Set<string>;
}> = (props) => {
  const {fx, selection} = props;
  const nav = useNavigator();
  const numSelected = selection?.size || 0;
  const t = useTranslator();

  return <animated.nav className={'pd-nm d-f fd-h f-ai-c f-jc-e'}
                       style={{
                         backgroundColor: 'rgba(8,25,35,0.825)',
                         position: 'absolute',
                         bottom: 0,
                         right: 0,
                         borderTopLeftRadius: '0.325rem',
                         ...fx
                       }}>
    <UiTextBlock className={'txt-sm mg-r-nm'}
                 style={{maxWidth: '10rem'}}
                 text={t('app.analysis.details.action.compare.title')} />
    <UiButton variation={UiButtonVariation.default}
              color={UiColor.accent}
              className={'txt-b'}
              style={{minWidth: '10em'}}
              label={t(`app.analysis.details.action.compare.label`, {selected: numSelected, max: 3})}
              interact={() => nav(`/portal/analysis/compare?selection=${Array.from(selection.values()).join(',')}`)} />
  </animated.nav>;
};

const CoachingZoneAnalysisSessionList: React.FC<{ selection: IUiSelectionState; sessions: IAnalysisSessionV2[]; }> = (props) => {
  const {sessions, selection} = props;

  const trail = useTrail(sessions.length, {
    config: {mass: 3, tension: 5000, friction: 200},
    opacity: 1,
    transform: `translateY(0%)`,
    from: {opacity: 0, transform: `translateY(5%)`},
  });

  return <>
    {trail.map((anim, i) => {
      const session = sessions[i];

      return <CoachingZoneAnalysisSessionListItem
        key={session.id}
        animation={{style: anim}}
        selection={selection}
        session={session} />;
    })}
  </>;
};


const CoachingZoneAnalysisSessionsEmpty: React.FC = () => {
  const t = useTranslator({namespace: 'app.analysis.table.empty'});

  return <UiBox className={'app-analysis__empty'}>
    <UiTextTitle text={t('title')} />
    <UiTextBlock text={t('info')} />
  </UiBox>;
}

const useAreSessionsSampleData = (sessions?: IAnalysisSessionV2[]) => {
  return useMemo(() => isNotNil(sessions?.find(s => s.exampleSession)), [sessions]);
}


const CoachingZoneAnalysisSessions: React.FC<{
  filters?: {
    athletes: string[];
    date: null | string;
    favoritesOnly: boolean;
  };
  setFilters: Function;
  request: IPaginatedHttpRequestHookResult<Page<IAnalysisSessionV2>>,
}> = (_props) => {
  const {request} = _props;
  const sessions = request?.response?.data?.elements?.slice(0, 10);
  const showSampleTour = useShouldShowTour(Rooq.UserTour.SampleDataAnalysis);
  const areSessionsSamples = useAreSessionsSampleData(sessions);

  const t = useTranslator();
  const selection: TrainingSessionSelection = useSelectionState([], {max: 3});
  const numSelected = useMemo(() => selection.selected.size, [selection.selected]);
  const hasSelection = useMemo(() => numSelected > 0, [numSelected]);

  const _yOff = 5;
  const fxProps = useSpring({
    config: {
      duration: 150
    },
    opacity: hasSelection ? 1 : 0,
    transform: `translate3d(0, ${hasSelection ? 0 : _yOff}rem, 0)`
  });

  if (isNotNil(request.lastFetchTime) && (isNil(sessions) || isEmpty(sessions as any))) {
    return <CoachingZoneAnalysisSessionsEmpty />;
  }

  return <UiBox style={{minHeight: 0, overflowY: 'auto'}}>
    <AppPageHeader title={t('app.analysis.table.title')}>
      {request.busy && <AppLoadingSpinner size={'sm'} className={'mg-l-sm'} />}
      {request.paginate && <UiBox style={{marginLeft: 'auto'}}>
        <UiPager api={request.paginate}
                 buttons={{
                   variation: UiButtonVariation.outlines,
                   size: 'sm',
                 }} />
      </UiBox>}
    </AppPageHeader>
    {sessions && <UiPageSegment className={'f-1'}
                                style={{
                                  minHeight: 0,
                                  overflow: 'auto',
                                  marginBottom: 0
                                }}>
      <section className={'d-g app-coachz__analysis__session-container'}>
        <CoachingZoneAnalysisSessionList key={request?.paginate?.state?.page?.toString(10) || '-1'}
                                         selection={selection}
                                         sessions={sessions} />
        <UiFlexRow jc={UiFlexAlign.c}>
          {areSessionsSamples && showSampleTour &&
          <UiTourTooltip arrow arrowSide={ArrowSide.Top} clickToComplete={Rooq.UserTour.SampleDataAnalysis}>
            <UiTextBlock
              text={t('app.tour.sample-data-analysis.message')} />
          </UiTourTooltip>}
        </UiFlexRow>
      </section>
    </UiPageSegment>}
    <CoachingZoneAnalysisSelectionBar fx={fxProps} selection={selection.selected} />
  </UiBox>;
};

const dateToDayPeriod = (date?: null | string) => {
  if (isNil(date)) {
    return {from: undefined, to: undefined};
  } else {
    const to = moment(date).add(1, 'day').format('YYYY-MM-DD');
    return {from: date, to};
  }
}

export const CoachingZoneAnalysisRoute: React.FC<ICoachingZoneAnalysisRoute> = (_props) => {
  const queryParam = useQueryParam('athleteFilter');

  const acc = useAuthAccount();
  const [filters, _setFilters] = useState<ICoachingZoneAnalysisFilters>({
    ...defaultCoachingZoneAnalysisFilters,
    athletes: queryParam && isNonEmptyStr(queryParam)
      ? [queryParam.trim()]
      : null,
  });

  const goToShop = useShopRedirect();

  const req = useFindSessions(0, 10, {
    initialFetch: true,
  }, {
    athleteFilter: filters?.athletes || undefined,
  });

  let hasExampleSession = false;
  if (req.response !== null && typeof(req.response.data.elements) === 'object') {
    req.response.data.elements.forEach((session) => {
      if (!hasExampleSession) {
        hasExampleSession = !!session.exampleSession;
      }
    })
  }

  const showBlockScreen = !acc?.ownsSubscription && !hasExampleSession;

  const t = useTranslator();
  const setFilters = useCallback(
    (data) => {
      _setFilters(data);

      req.updateRequestParams(params => {
        const {from: _f, to: _t, ...rest} = params;

        return {
          ...rest,
          athleteFilter: data?.athletes || params?.athleteFilter,
          ...dateToDayPeriod(data?.date)
        }
      }, true);
    },
    [_setFilters, req]
  );

  return <UiForm value={filters}
                 fields={coachingZoneAnalysisFilterFields}
                 onFieldChange={(k, v) => {
                   setFilters({...filters, [k]: v});
                 }}
                 interact={setFilters}
                 className={'f-1 d-f fd-v'}>
    {(context: IUiFormContext) =>
      <UiPageContainer fluid className={'app-coachz app-coachz__analysis f-1 d-f fd-v'}
                       style={{
                         position: 'relative',
                         maxHeight: '100vh',
                         minHeight: 0,
                         overflow: 'hidden',
                       }}>
        <UiBox className={'d-f fd-v'}
               style={{
                 height: '100%',
                 overflow: 'hidden',
                 pointerEvents: !showBlockScreen
                   ? undefined
                   : 'none',
                 filter: !showBlockScreen
                   ? undefined
                   : 'blur(3px)'
               }}>
          {req.firstFetchTime &&
          <AppPageSectionInset className={'d-f fd-h f-ai-c'}>
            <CoachingZoneAnalysisFilterToolbar value={filters}
                                               onChange={(data) => {
                                                 setFilters(data);
                                                 context.setValue(data);
                                               }} />
            <AppPageMenu className={'app-coachz__analysis__tabs'}
                         items={[
                           {
                             label: t('app.analysis.table.short-title'),
                             icon: 'appTrainings',
                             to: '/portal/analysis'
                           },
                         ]} />
          </AppPageSectionInset>}
          <Switch>
            <Route exact
                   path={'/portal/analysis'}
                   render={(route) => {

                     return <CoachingZoneAnalysisSessions {...route}
                                                          request={req}
                                                          filters={filters as any}
                                                          setFilters={setFilters} />;
                   }} />
          </Switch>
        </UiBox>
        {showBlockScreen && <UiBox className={'ui-box app-analysis__subscription-locked'}
                                          style={{
                                            position: 'absolute',
                                            inset: 0,
                                            background: 'radial-gradient(closest-side, rgba(26, 42, 57, 1) 0%, rgba(26, 42, 57, 0.875) 25%, rgba(0, 0, 0, 0) 100%)'
                                          }}>
          <UiTextTitle className={'txt-lg'}
                       text={t('app.analysis.table.shop-interstitial.title')} />
          <UiTextBlock
            text={t('app.analysis.table.shop-interstitial.message')} />
          <UiButton label={t('app.analysis.table.shop-interstitial.action.to-shop')}
                    className={'txt-b'}
                    color={UiColor.accent}
                    interact={() => goToShop()} />
        </UiBox>}
      </UiPageContainer>}
  </UiForm>;
};
