import React, {useEffect, useMemo, useState} from 'react';
import firebase from 'firebase/app';
import {FirebaseContextProvider} from './firebase';
import {useAuthActions} from '../auth.store';
import {IAuthAccess, IAuthUser} from '../auth.model';
import {isNil} from '@bitsolve/fns';
import {IAppDataContext} from '../../../core/system';

export interface IFirebaseProvider {
  config: IAppDataContext;
  onAuthUserChanged?: (user: IAuthUser | null) => any;
  onAuthAccessChanged?: (access: IAuthAccess | null) => any;
}


export const FirebaseProvider: React.FC<IFirebaseProvider> = (props) => {
  const {children, onAuthUserChanged, onAuthAccessChanged} = props;
  const config = props.config.sdkConfig;

  const {busy, authenticate, updateAccess, touch} = useAuthActions();
  const [app, setApp] = useState<firebase.app.App | null>();


  useEffect(() => {
    if (app) {
      return;
    }

    let _app: firebase.app.App;

    try {
      _app = firebase.initializeApp(config);
    } catch (e) {
      _app = firebase.app();
    }

    setApp(_app)
  }, [
    config,
    app,
    setApp
  ]);

  useEffect(
    () => {
      if (!app) {
        return;
      }

      const accessChange = (access: IAuthAccess | null) => {
        onAuthAccessChanged
          ? requestAnimationFrame(() => {
            onAuthAccessChanged(access as any);
            setTimeout(() => updateAccess(access), 64);
          })
          : setTimeout(() => updateAccess(access), 64)
      };

      app.auth().onIdTokenChanged(access => {
        access?.getIdTokenResult(true)
          .then(accessChange)
          .catch(() => accessChange(null));
      });

      app.auth().onAuthStateChanged(user => {
        onAuthUserChanged && requestAnimationFrame(() => onAuthUserChanged(user));
        authenticate(user);

        if (isNil(user)) {
          touch();
        }
      });
    },
    [app, busy, authenticate, updateAccess, touch, onAuthUserChanged, onAuthAccessChanged]
  );

  const value = useMemo(() => ({app}), [app]);

  if (!app) {
    return null;
  }

  return <FirebaseContextProvider value={value as any}>
    {children}
  </FirebaseContextProvider>;
};

