import {
  IHttpRequest,
  IHttpRequestHookOptions,
  IPaginatedHttpRequestHookOptions,
  Page,
  toast,
  useApiEndpoint,
  useHttpRequest,
  usePaginatedHttpRequest,
  useTranslator
} from '@bitsolve/react-common';
import {
  IClub,
  IClubCreate,
  IClubMembership,
  IClubMembershipInvitation,
  IClubRelationshipStatus,
  IClubUpdate
} from './club.model';
import {API_PREFIX} from '../../core/constants';
import {AxiosResponse} from 'axios';
import {dissoc, hasProp, isEmptyStr, isNil, isNonEmptyStr, isNotNil, update} from '@bitsolve/fns';
import {Rooq} from '../../core/domain.model';
import {paginatedOptions, paginator, useDeleteConfirmation} from '../../core/api';
import {IAuthUserAccount} from '../auth';
import {useCallback, useMemo} from 'react';
import {useClubStoreActions} from './club.store';

const baseUrl = `${API_PREFIX}/v1/club`
const internalUrl = `${API_PREFIX}/v1/internal/club`;
const internalMembershipUrl = `${API_PREFIX}/v1/internal/membership`;

export const findClubByIdRequest = (id: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${id}`
});

export const getClubRelationStatus = (clubId: string, requestedAs: Rooq.RelationshipType = Rooq.RelationshipType.TRAINER): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/account/me/membership/status`,
  params: {
    clubId,
    requestedAs
  }
});

export const findClubMembersRequest = (clubId: string,
                                       memberType: Rooq.MembershipType = Rooq.MembershipType.ATHLETE,
                                       page: number = 0,
                                       pageSize: number = 10): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${clubId}/members`,
  params: {page, pageSize, type: memberType}
});

export const findClubMembershipsRequest = (accountId: string, type?: Rooq.MembershipType, page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/account/${accountId}/clubs`,
  params: {
    page,
    pageSize,
    type
  }
});

export const findMyClubMembershipsRequest = (page?: number, pageSize?: number, type?: Rooq.MembershipType, role?: Rooq.MembershipRole): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/account/me/membership`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    type,
    role
  }
});

export const getMembershipStatusRequest = (clubId: string, accountId: string, requestedAs?: Rooq.MembershipType): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${clubId}/membership/status`,
  params: {
    accountId,
    requestedAs: requestedAs || Rooq.MembershipType.TRAINER
  }
});

export const useGetMembershipStatus = (clubId: string, accountId: string, requestedAs: Rooq.MembershipType = Rooq.MembershipType.TRAINER, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<IClubRelationshipStatus>(
    getMembershipStatusRequest(clubId, accountId, requestedAs),
    {initialFetch: true, ...opts}
  );

export const findClubMembershipInvitationsRequest = (clubId: string, memberType: Rooq.MembershipType = Rooq.MembershipType.ATHLETE, page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${clubId}/membership/invitation`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    type: memberType
  }
});

export const findClubMembershipInvitationRequest = (invitationId: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/membership/invitation/${invitationId}`
});

export const findClubMemberCandidatesRequest = (clubId: string, page?: number, pageSize?: number, initialSearch?: string, requestedAs?: Rooq.MembershipType): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${clubId}/candidates`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    search: isNonEmptyStr(initialSearch)
      ? initialSearch.trim()
      : undefined,
    requestedRole: requestedAs,
  }
});

export const findMyClubCandidatesRequest = (page?: number, pageSize?: number, initialSearch?: string, requestedAs?: Rooq.MembershipType): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/account/me/membership/candidates`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    search: isNonEmptyStr(initialSearch)
      ? initialSearch.trim()
      : undefined,
    requestedAs,
  }
});


export const findInternalClubCandidatesRequest = (clubId: string, page?: number, pageSize?: number, initialSearch?: string, requestedAs?: Rooq.MembershipType): IHttpRequest => ({
  method: 'get',
  url: `${internalMembershipUrl}/candidates`,
  params: {
    clubId,
    page: page || 0,
    pageSize: pageSize || 10,
    search: isNonEmptyStr(initialSearch)
      ? initialSearch.trim()
      : undefined,
    requestedAs,
  }
});

export const findInternalClubsRequest = (page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: internalUrl,
  params: {
    page: page || 0,
    pageSize: pageSize || 25,
  }
});

// it's a mess
const normalizeTime = (t: string): string => {
  if (isNil(t) || isEmptyStr(t)) {
    return null as any;
  }
  switch (t.length) {
    case 2:
      return `1970-01-01T${t}:00:00.000Z`;
    case 5:
      return `1970-01-01T${t}:00.000Z`;
    case 8:
      return `1970-01-01T${t}.000Z`;
    case 16:
      return `${t}:00.000Z`;
    default:
      return null as any;
  }
};

const normalizeTimeValue = (val: Rooq.ClubOpeningTimeframeSlot): Rooq.ClubOpeningTimeframeSlot => {
  val.startTime = normalizeTime(val.startTime as any);
  val.endTime = normalizeTime(val.endTime as any)

  return dissoc(val, 'id') as any;
};

const normalizeOpeningHours = (val: Rooq.ClubOpeningTimeframe[]): Rooq.ClubOpeningTimeframe[] => {
  return val
    ?.filter(v => isNotNil(v.times) && v.times.length > 0)
    ?.map(v => {
      v.times = v.times.map(normalizeTimeValue) as any;
      return v;
    })
};

const normalizeClubPayload = <T extends IClubCreate | IClubUpdate>(data: T): T => {
  return hasProp(data, 'openingHours')
    ? update(data, 'openingHours', normalizeOpeningHours)
    : data;
};

export const createClubRequest = (data: IClubCreate): IHttpRequest => ({
  method: 'post',
  url: baseUrl,
  data: normalizeClubPayload(data)
});

const updateClubRequest = (id: string, data: IClubUpdate): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}/${id}`,
  data: normalizeClubPayload(data)
});

const updateInternalClubRequest = (id: string, data: IClubUpdate): IHttpRequest => ({
  method: 'patch',
  url: `${internalUrl}/${id}`,
  data: normalizeClubPayload(data)
});

const createClubMembershipInvitationRequest = (clubId: string, accountId: string, invitedAs: Rooq.MembershipType = Rooq.MembershipType.TRAINER): IHttpRequest => ({
  method: 'post',
  url: `${baseUrl}/${clubId}/membership/invitation`,
  data: {accountId, invitedAs}
});

const updateMyClubMembershipInvitationRequest = (invitationId: string, status: Rooq.InvitationStatus): IHttpRequest => ({
  method: 'patch',
  url: `${API_PREFIX}/v1/account/me/membership/invitation/${invitationId}`,
  data: {status}
});

const updateClubMembershipInvitationRequest = (invitationId: string, status: Rooq.InvitationStatus): IHttpRequest => ({
  method: 'patch',
  url: `${API_PREFIX}/v1/club/membership/invitation/${invitationId}`,
  data: {status}
});

const deleteMyClubMembershipInvitationRequest = (invitationId: string): IHttpRequest => ({
  method: 'delete',
  url: `${API_PREFIX}/v1/account/me/membership/invitation/${invitationId}`,
});

const deleteClubMembershipInvitationRequest = (invitationId: string): IHttpRequest => ({
  method: 'delete',
  url: `${API_PREFIX}/v1/club/membership/invitation/${invitationId}`,
});

const findMyClubMembershipInvitationsRequest = (page?: number, pageSize?: number, requestedAs?: Rooq.MembershipType): IHttpRequest => ({
  method: 'get',
  url: `${API_PREFIX}/v1/account/me/membership/invitation`,
  params: {
    page: page || 0,
    pageSize: pageSize || 10,
    requestedAs: requestedAs || Rooq.MembershipType.TRAINER
  }
});

const deleteInternalClubRequest = (clubId: string): IHttpRequest => ({
  method: 'delete',
  url: `${internalUrl}/${clubId}`
});

const deleteInternalClubMembershipRequest = (membershipId: string): IHttpRequest => ({
  method: 'delete',
  url: `${internalMembershipUrl}`,
  params: {membershipId}
});

const createInternalClubMembershipRequest = (clubId: string, accountId: string, type: Rooq.MembershipType, role: Rooq.MembershipRole): IHttpRequest => ({
  method: 'post',
  url: `${internalMembershipUrl}`,
  data: {clubId, accountId, type, role}
});

export const useFindClubById = (id: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<IClub>(findClubByIdRequest(id), opts);

export const useGetClubRelationStatus = (
  clubId: string,
  requestedAs: Rooq.RelationshipType = Rooq.RelationshipType.TRAINER,
  opts?: IHttpRequestHookOptions
) =>
  useHttpRequest<IClubRelationshipStatus>(
    getClubRelationStatus(clubId, requestedAs),
    {initialFetch: true, ...opts}
  );

export const useFindClubMembers = (clubId: string,
                                   memberType: Rooq.MembershipType = Rooq.MembershipType.ATHLETE,
                                   page: number = 0,
                                   pageSize: number = 10,
                                   opts?: Partial<IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions>) =>
  usePaginatedHttpRequest<Page<IClubMembership<IAuthUserAccount>>>(
    findClubMembersRequest(clubId, memberType, page, pageSize),
    {paginator, initialPagination: {firstPageIndex: 0, page, size: pageSize}, initialFetch: true, ...opts}
  );

export const useFindClubMembershipInvitations = (clubId: string,
                                                 memberType: Rooq.MembershipType = Rooq.MembershipType.ATHLETE,
                                                 page: number = 0,
                                                 pageSize: number = 10,
                                                 opts?: Partial<IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions>) =>
  usePaginatedHttpRequest<Page<IClubMembershipInvitation<IAuthUserAccount>>>(
    findClubMembershipInvitationsRequest(clubId, memberType, page, pageSize),
    {paginator, initialPagination: {firstPageIndex: 0, page, size: pageSize}, ...opts}
  );

export const useFindClubMembershipInvitation = (invitationId: string, opts?: Partial<IHttpRequestHookOptions>) =>
  useHttpRequest<IClubMembershipInvitation>(findClubMembershipInvitationRequest(invitationId), {initialFetch: true, ...opts});

export const useFindMyClubMemberships = (page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IClubMembership>>(
    findMyClubMembershipsRequest(page, pageSize),
    {paginator, initialPagination: {page: page || 0, size: pageSize || 10, firstPageIndex: 0}, ...opts}
  );

export const useFindClubMemberships = (accountId: string, type?: Rooq.MembershipType, page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IClubMembership>>(
    findClubMembershipsRequest(accountId, type, page, pageSize),
    paginatedOptions(page, pageSize, opts)
  );

export const useFindMyClubInvitations = (page?: number,
                                         pageSize?: number,
                                         requestedAs?: Rooq.MembershipType,
                                         opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IClubMembershipInvitation<IClub<IAuthUserAccount>>>>(
    findMyClubMembershipInvitationsRequest(page, pageSize, requestedAs),
    {paginator, initialPagination: {page: page || 0, size: pageSize || 10, firstPageIndex: 0}, ...opts}
  )

export const useFindClubMemberCandidates = (
  clubId: string,
  initialSearch?: string,
  page?: number,
  pageSize?: number,
  requestedAs?: any,
  opts?: IHttpRequestHookOptions
) =>
  usePaginatedHttpRequest<Page<IAuthUserAccount>>(
    findClubMemberCandidatesRequest(clubId, page, pageSize, initialSearch, requestedAs),
    {paginator, initialPagination: {page: page || 0, size: pageSize || 10, firstPageIndex: 0}, ...opts}
  );

export const useFindMyClubCandidates = (initialSearch?: string, requestedAs?: Rooq.MembershipType, page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IClub>>(
    findMyClubCandidatesRequest(page, pageSize, initialSearch, requestedAs),
    {paginator, initialPagination: {page: page || 0, size: pageSize || 10, firstPageIndex: 0}, ...opts}
  );

export const useFindInternalClubCandidates = (clubId: string, initialSearch?: string, requestedAs?: Rooq.MembershipType, page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IAuthUserAccount>>(
    findInternalClubCandidatesRequest(clubId, page, pageSize, initialSearch, requestedAs),
    paginatedOptions(page || 0, pageSize || 10, opts)
  );

export const useFindInternalClubs = (page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IClub>>(
    findInternalClubsRequest(page, pageSize),
    {initialFetch: true, ...paginatedOptions(page, pageSize), ...opts}
  );

export const useCreateClub = (onSuccess?: (response: AxiosResponse<IClub>) => any,
                              onError?: (error: any) => any) => {
  const {fetchAdministration} = useClubStoreActions();

  const _onSuccess = useCallback(
    (result: AxiosResponse<IClub>) => {
      fetchAdministration();
      return onSuccess && onSuccess(result);
    },
    [onSuccess, fetchAdministration]
  );

  return useApiEndpoint<IClubCreate, IClub>(
    createClubRequest,
    _onSuccess,
    onError
  );
};

export const useUpdateClub = (id: string, onSuccess?: (response: AxiosResponse<IClub>) => any,
                              onError?: (error: any) => any) => {
  const {fetchAdministration} = useClubStoreActions();

  const _onSuccess = useCallback(
    (result: AxiosResponse<IClub>) => {
      fetchAdministration();
      return onSuccess && onSuccess(result);
    },
    [onSuccess, fetchAdministration]
  );

  return useApiEndpoint<IClubUpdate, IClub>(
    data => updateClubRequest(id, data),
    _onSuccess,
    onError
  );
};


export const useInternalUpdateClub = (id: string, onSuccess?: (response: AxiosResponse<IClub>) => any,
                                      onError?: (error: any) => any) => {
  const {fetchAdministration} = useClubStoreActions();

  const _onSuccess = useCallback(
    (result: AxiosResponse<IClub>) => {
      fetchAdministration();
      return onSuccess && onSuccess(result);
    },
    [onSuccess, fetchAdministration]
  );

  return useApiEndpoint<IClubUpdate, IClub>(
    data => updateInternalClubRequest(id, data),
    _onSuccess,
    onError
  );
};

export const useCreateClubMembershipInvitation = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any,
                                                  onError?: (error: any) => any) =>
  useApiEndpoint<{ clubId: string, accountId: string, invitedAs: Rooq.MembershipType }, IClubMembershipInvitation>(
    ({clubId, accountId, invitedAs}) => createClubMembershipInvitationRequest(clubId, accountId, invitedAs),
    onSuccess,
    onError
  );

export const useUpdateClubMembershipInvitationEndpoint = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<{ invitationId: string; status: Rooq.InvitationStatus; }, IClubMembershipInvitation>(
    ({invitationId, status}) => updateClubMembershipInvitationRequest(invitationId, status),
    onSuccess,
    onError
  );


export const useUpdateClubMembershipInvitation = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useDeleteConfirmation<{ invitationId: string; status: Rooq.InvitationStatus; }, { invitationId: string; status: Rooq.InvitationStatus; }>({
    endpoint: useUpdateClubMembershipInvitationEndpoint(onSuccess, onError),
    title: 'general.confirm.title',
    message: 'general.confirm.message',
    confirmLabel: 'general.confirm.action.confirm',
    cancelLabel: 'general.confirm.action.cancel'
  })

export const useUpdateMyClubMembershipInvitation = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<{ invitationId: string; status: Rooq.InvitationStatus; }, IClubMembershipInvitation>(
    ({invitationId, status}) => updateMyClubMembershipInvitationRequest(invitationId, status),
    onSuccess,
    onError
  );


export const useDeleteClubMembershipInvitationEndpoint = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<string, IClubMembershipInvitation>(
    deleteClubMembershipInvitationRequest,
    onSuccess,
    onError
  );

export const useDeleteInternalClubMembershipEndpoint = (onSuccess?: (response: AxiosResponse<any>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<string, any>(
    deleteInternalClubMembershipRequest,
    onSuccess,
    onError
  );


export const useDeleteInternalClubEndpoint = (onSuccess?: (response: AxiosResponse<any>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<string, any>(
    deleteInternalClubRequest,
    onSuccess,
    onError
  );

export const useDeleteInternalClubMembership = (onSuccess?: (response: AxiosResponse<any>) => any, onError?: (error: any) => any) =>
  useDeleteConfirmation({
    endpoint: useDeleteInternalClubMembershipEndpoint(onSuccess, onError),
    title: 'general.confirm.title',
    message: 'general.confirm.message',
    confirmLabel: 'general.confirm.action.confirm',
    cancelLabel: 'general.confirm.action.cancel'
  });


export const useDeleteInternalClub = (onSuccess?: (response: AxiosResponse<any>) => any, onError?: (error: any) => any) =>
  useDeleteConfirmation({
    endpoint: useDeleteInternalClubEndpoint(onSuccess, onError),
    title: 'general.confirm.title',
    message: 'general.confirm.message',
    confirmLabel: 'general.confirm.action.confirm',
    cancelLabel: 'general.confirm.action.cancel'
  });

export const useDeleteClubMembershipInvitation = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useDeleteConfirmation({
    endpoint: useDeleteClubMembershipInvitationEndpoint(onSuccess, onError),
    title: 'general.confirm.title',
    message: 'general.confirm.message',
    confirmLabel: 'general.confirm.action.confirm',
    cancelLabel: 'general.confirm.action.cancel'
  });

export const useDeleteMyClubMembershipInvitation = (onSuccess?: (response: AxiosResponse<IClubMembershipInvitation>) => any, onError?: (error: any) => any) =>
  useApiEndpoint<string, IClubMembershipInvitation>(
    deleteMyClubMembershipInvitationRequest,
    onSuccess,
    onError
  );

export interface IMembershipCreateInternal {
  accountId: string;
  clubId: string;
  type: Rooq.MembershipType,
  role: Rooq.MembershipRole,
}

export const useCreateInternalClubMembershipEndpoint = (onSuccess?: (response: AxiosResponse<IClubMembership>) => any,
                                                        onError?: (error: any) => any) =>
  useApiEndpoint<IMembershipCreateInternal, IClubMembership>(
    ({clubId, accountId, type, role}) => createInternalClubMembershipRequest(clubId, accountId, type, role),
    onSuccess,
    onError
  );


export const useClubMembershipInvitationActions = (callbacks?: {
  onCreated?: Function;
  onUpdated?: Function;
  onDeleted?: Function;
  onHidden?: Function;
  onAny?: Function;
}) => {
  const {onCreated, onDeleted, onHidden, onUpdated, onAny} = callbacks || {};
  const t = useTranslator();

  const createInvitation = useCreateClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.request-membership.toast.success'));
      onAny && onAny();
      onCreated && onCreated();
    },
    () => toast.error(t('app.club.action.request-membership.toast.error'))
  );

  const updateInvitation = useUpdateClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.update-membership.toast.success'));
      onAny && onAny();
      onUpdated && onUpdated();
    },
    () => toast.error(t('app.club.action.update-membership.toast.error'))
  );

  const deleteInvitation = useDeleteClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.withdraw-membership-request.toast.success'));
      onAny && onAny();
      onDeleted && onDeleted();
    },
    () => toast.error(t('app.club.action.withdraw-membership-request.toast.error'))
  );

  const hideInvitation = useDeleteMyClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.hide-membership-request.toast.success'));
      onAny && onAny();
      onHidden && onHidden();
    },
    () => toast.error(t('app.club.action.hide-membership-request.toast.error'))
  );

  return useMemo(
    () => ({createInvitation, updateInvitation, deleteInvitation, hideInvitation}),
    [createInvitation, updateInvitation, deleteInvitation, hideInvitation]
  );
};
