import React, {useMemo, useState} from 'react';

import {
  schema,
  toast,
  UiBox,
  UiButtonVariation,
  UiColor,
  UiFlexCol,
  UiForm,
  UiFormButton,
  UiFormField,
  UiState,
  UiTextBlock,
  UiTextTitle,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {
  AppSplashScreen,
  AppSplashScreenContent,
  AppSplashScreenHeader
} from '../../../common/misc/app-splash-screen.component';
import {useFirebaseAppContext, useFirebaseErrorCodeKey} from '../../../../module/auth';
import {AppLogoInitial} from '../../../common/misc/app-logo.component';
import {IRoute} from '../../../../core/routing';
import {authLoginFields} from '../../../../module/auth/auth.forms';
import {useFirebaseActionCodeSettings} from '../../../common/app-firebase.config';
import {isEmptyStr} from '@bitsolve/fns';
import {AppNavButton} from '../../../common/misc/app-nav-button.component';

export interface IResetPasswordRoute extends IRoute {
}

// const createSchemaError = (field: string, formValue: any, );

const useResetPasswordSchema = () => {
  // const t = useTranslator();

  return useMemo(() => schema.object({
    email: schema.string().required()
  }), []);
};

const useResetPasswordFields = () =>
  useTranslatedFields([authLoginFields.email]);

const useResetPasswordForm = () => {
  const fields = useResetPasswordFields();
  const schema = useResetPasswordSchema();

  return useMemo(() => ({fields, schema}), [fields, schema]);
};

export const AppResetPasswordRoute: React.FC<IResetPasswordRoute> = (props) => {
  const t = useTranslator();
  const fb = useFirebaseAppContext();
  const {fields, schema} = useResetPasswordForm();

  const actionCodeSettings = useFirebaseActionCodeSettings();
  const [busy, setBusy] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const [error, setError] = useState<undefined | { code: string; }>(undefined);
  const errorCodeKey = useFirebaseErrorCodeKey(error, {pre: 'app.reset-password.action.send-reset-email.error'});


  return <AppSplashScreen dim className={'app-onboarding app-onboarding__reset-password'}>
    <AppSplashScreenHeader>
      <AppLogoInitial offset />
    </AppSplashScreenHeader>
    <AppSplashScreenContent style={{gridRow: '2/4'}}>
      {emailSent
        ? <>
          <UiTextTitle headingLevel={1}
                       className={'mg-t-md mg-b-nm'}
                       text={t(`app.reset-password.action.send-reset-email.title.success`)} />
          <UiTextBlock
            className={'mg-b-md txt-b'}
            text={t(`app.reset-password.action.send-reset-email.message.success`)} />
        </>
        : (error
          ? <>
            <UiTextTitle headingLevel={1}
                         className={'mg-t-md txt-c-err txt-b' + (errorCodeKey ? '' : ' mg-b-md')}
                         text={t(`app.reset-password.action.send-reset-email.title.error`)} />
            {errorCodeKey && <UiTextBlock
              className={'mg-b-md txt-c-err txt-b'}
              text={t(errorCodeKey)} />}
          </>
          : <>
            <UiTextTitle headingLevel={1}
                         className={'mg-v-nm'}
                         text={t('app.reset-password.title')} />
            <UiBox style={{maxWidth: '21rem'}}
                   className={'mg-b-md'}>
              <UiTextBlock text={t('app.reset-password.message')} />
            </UiBox>
            <UiForm value={{email: '', password: ''}}
                    fields={fields}
                    schema={schema}
                    schemaOptions={{abortEarly: false}}
                    interact={(values, form) => {
                      const auth = fb?.app?.auth();
                      const email = values?.email;

                      if (!auth || !email || isEmptyStr(email)) return;

                      setBusy(true);

                      // sleep(500 + (Math.random() * 500))
                      //   .then(() => Promise.reject({code: 'auth/unknown'}))
                      auth.sendPasswordResetEmail(email, actionCodeSettings)
                        .then(() => {
                          setBusy(false);
                          setEmailSent(true);
                          toast.success(t(`app.reset-password.action.send-reset-email.toast.success`));
                        })
                        .catch((e) => {
                          setBusy(false);
                          setError(e);
                          toast.error(t(`app.reset-password.action.send-reset-email.toast.error`));
                        })
                    }}>
              <UiFormField name={'email'} />
              <UiFlexCol className="mg-t-md" tag={'footer'}>
                <UiFormButton stretch
                              className={emailSent ? undefined : 'txt-b'}
                              color={emailSent ? UiColor.success : UiColor.accent}
                              label={t(busy
                                ? 'general.action.wait'
                                : 'app.settings.account.reset-password.label')}
                              state={(busy || emailSent || error) ? UiState.disabled : UiState.active}
                              interact={form => form.submit()} />
                <AppNavButton stretch
                              variation={UiButtonVariation.ghost}
                              to={'/'}
                              label={t('general.action.back')}
                              className={'mg-t-nm'} />
              </UiFlexCol>
            </UiForm>
          </>)}
    </AppSplashScreenContent>
  </AppSplashScreen>;
};
