import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  classNames,
  ISelectFieldControlOption,
  IUiFormField,
  UiFlexAlign,
  UiFlexRow,
  UiIcon,
  UiTextLine,
  useTranslator
} from '@bitsolve/react-common';
import {Rooq} from '../../../core/domain.model';
import {areSetsEqual} from '../../../core/util';
import {useFindStaticFightStyles} from '../../../module/content/content.api';
import {AppLoadingSpinner} from './app-loading-spinner.component';

export interface ITrainerFightStyleSelectControl extends IUiFormField {
}

const _baseClassName = 'app-fight-style-select-control';

export const FightStyleSelectControl: React.FC<ITrainerFightStyleSelectControl> = (props) => {
  const {controlProps = {}, className, style, value, onChange} = props;

  const t = useTranslator();

  const _optsReq = useFindStaticFightStyles({initialFetch: true});

  useEffect(() => {
    return () => {
      if (_optsReq.busy) {
        // this seems to get rid of react's memory leak warning, and also seems to not
        // break everything ... this'll be the line to remove if stuff goes wrong
        _optsReq.cancel();
      }
    };
  }, [_optsReq]);

  const mounted = useRef(false);
  const [_value, _setValue] = useState<Rooq.FightStyle[]>(value || []);
  const _valueSet: Set<Rooq.FightStyle> = useMemo(() => new Set(_value), [_value]);

  useEffect(
    () => {
      if (mounted.current && onChange && !areSetsEqual(new Set<Rooq.FightStyle>(value), _valueSet)) {
        onChange(Array.from(_valueSet.values()));
      }

      mounted.current = true;
    },
    [_valueSet, onChange, value, mounted]
  );

  const _rawOpts = _optsReq?.response?.data;

  const _opts: ISelectFieldControlOption[] = useMemo(
    () => _rawOpts?.values
      ? _rawOpts?.values?.map(value => ({
        label: t(`content.fightStyle.${value}`),
        value
      }))
      : [],
    [_rawOpts, t]
  );

  return <div className={className}
              style={style}>
    <div className={classNames(_baseClassName, controlProps?.className)}
         style={{borderBottom: 0}}>
      {_optsReq.busy
        ? <UiFlexRow jc={UiFlexAlign.c}
                     className={'pd-v-nm ui-fx ui-fx__pane-fade-in'}>
          <AppLoadingSpinner />
        </UiFlexRow>
        : <>
          {_opts.map(option => {
            const isChecked = _valueSet.has(option.value);

            return <div
              key={option.value}
              className={classNames(
                `${_baseClassName}__option`,
                isChecked && `${_baseClassName}__option--checked`,
              )}
              onClick={() => _setValue(
                isChecked
                  ? _value.filter(v => v !== option.value)
                  : [...Array.from(_valueSet.values()), option.value]
              )}>
              <UiTextLine text={option.label} />
              <UiIcon key={`${option.value}_${isChecked ? '_checked' : '_unchecked'}`}
                      iconSet={isChecked ? 'solid' : 'regular'}
                      name={'radioControlOn'} />
            </div>
          })}
        </>}
    </div>
  </div>;
};
