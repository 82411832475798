import {
  IHttpRequest,
  IHttpRequestHookOptions,
  useApiEndpoint,
  useHttpRequest,
  usePaginatedHttpRequest,
  useTranslator
} from '@bitsolve/react-common';
import {API_PREFIX} from '../../core/constants';
import {ErrorHandler, paginatedOptions, SuccessHandler, useDeleteConfirmation} from '../../core/api';
import {useCallback, useMemo} from 'react';
import {ISeat} from './shop.model';
import {IAuthUserAccount} from '../auth';
import {isNil, isNotNil} from '@bitsolve/fns';
import {useAppData} from '../../core/system';
import {AxiosResponse} from 'axios';

const baseUrl = `${API_PREFIX}/v1/shop`;
const meUrl = `${API_PREFIX}/v1/account/me`;
const clubUrl = `${API_PREFIX}/v1/club`;
const subscriptionUrl = `${API_PREFIX}/v1/subscription`;

export const findMyShopSeatsRequest = (): IHttpRequest => ({
  method: 'get',
  url: `${meUrl}/seats`
});

export const findClubShopSeatsRequest = (clubId: string): IHttpRequest => ({
  method: 'get',
  url: `${clubUrl}/${clubId}/seats`
});

export const findMyShopSeatCandidatesRequest = (page?: number, size?: number): IHttpRequest => ({
  method: 'get',
  url: `${meUrl}/seats/candidates`,
  params: {page, size}
});

export const findClubShopSeatCandidatesRequest = (clubId: string, page?: number, size?: number): IHttpRequest => ({
  method: 'get',
  url: `${clubUrl}/${clubId}/seats/candidates`,
  params: {page, size}
});

export const startShopTrialRequest = (): IHttpRequest => ({
  method: 'post',
  url: `${subscriptionUrl}/trainer/trial`
});

export const startShopTrialAndAssignRequest = (athleteId: string): IHttpRequest => ({
  method: 'post',
  url: `${subscriptionUrl}/trainer/trial/${athleteId}`
});

export const assignMyShopSeatRequest = (accountId: string): IHttpRequest => ({
  method: 'post',
  url: `${meUrl}/seats`,
  data: {athleteId: accountId}
});

export const unassignMyShopSeatRequest = (seatId: string): IHttpRequest => ({
  method: 'delete',
  url: `${meUrl}/seats/${seatId}`
});


export const assignClubShopSeatRequest = (clubId: string, accountId: string): IHttpRequest => ({
  method: 'post',
  url: `${clubUrl}/${clubId}/seats`,
  data: {athleteId: accountId}
});

export enum ShopTokenType {
  TRAINER = 'TRAINER',
  CLUB = 'CLUB',
}

export const generateShopAccessRequest = (tokenType: ShopTokenType, entityId: string): IHttpRequest => ({
  method: 'post',
  url: `${baseUrl}/access/generate`,
  data: {tokenType, entityId}
});

export const applySubsctiptionTokenRequest = (subscriptionId: string): IHttpRequest => ({
  method: 'post',
  url: `${API_PREFIX}/v1/subscription/trainer/connect`,
  data: {subscriptionId}
});

export const useGenerateShopAccess = (tokenType: ShopTokenType, onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<string, { token: string; }>(
    entityId => generateShopAccessRequest(tokenType, entityId),
    onSuccess,
    onError
  );

export const useAssignMyShopSeat = (onSuccess?: SuccessHandler, onError?: ErrorHandler) => {
  return useApiEndpoint<string, ISeat>(
    assignMyShopSeatRequest,
    onSuccess,
    onError
  );
}

export const useAssignClubShopSeat = (onSuccess?: SuccessHandler, onError?: ErrorHandler) => {
  return useApiEndpoint<{ clubId: string; accountId: string; }, ISeat>(
    ({clubId, accountId}) => assignClubShopSeatRequest(clubId, accountId),
    onSuccess,
    onError
  );
}

export const useApplySubscriptionToken = <R = any>(token: string,
                                                   onSuccess?: (result: AxiosResponse<R>) => any,
                                                   onError?: (error: any) => any) => {
  return useApiEndpoint(
    () => applySubsctiptionTokenRequest(token),
    onSuccess,
    onError
  );
};

export const useShopRedirect = (tokenParamName: string = 'czToken') => {
  const config = useAppData();

  return useCallback((token?: string): void => {
    if (!window || !window.location || !config || !config.shopUrl) {
      console.warn('invalid or no redirect target found', config);
      return;
    }

    if (token) {
      window.open(
        `${config.shopUrl}?${tokenParamName}=${token}`,
        '_blank'
      );
    } else {
      window.open(`${config.shopUrl}`, '_blank');
    }
    // window.location.href = `${config.shopUrl}?${tokenParamName}=${token}`
  }, [tokenParamName, config]);
};

export const useShopStartTrial = (onSuccess?: SuccessHandler, onError?: ErrorHandler) => useApiEndpoint(
  () => startShopTrialRequest(),
  onSuccess,
  onError
);

export const useShopStartTrialAndAssign = (onSuccess?: SuccessHandler, onError?: ErrorHandler) => useApiEndpoint(
  startShopTrialAndAssignRequest,
  onSuccess,
  onError
);

export const useFindMyShopSeats = (opts?: Partial<IHttpRequestHookOptions>) =>
  useHttpRequest<{ seats: ISeat[]; }>(
    findMyShopSeatsRequest(),
    {initialFetch: true, ...opts}
  );

export const useFindClubShopSeats = (clubId: string, opts?: Partial<IHttpRequestHookOptions>) =>
  useHttpRequest<{ seats: ISeat[]; }>(
    findClubShopSeatsRequest(clubId),
    {initialFetch: true, ...opts}
  );


export const useFindMyShopSeatCandidates = (page?: number, size?: number, opts?: Partial<IHttpRequestHookOptions>) =>
  usePaginatedHttpRequest<IAuthUserAccount>(
    findMyShopSeatCandidatesRequest(page, size),
    paginatedOptions(page, size, opts)
  );

export const useFindClubShopSeatCandidates = (clubId: string, page?: number, size?: number, opts?: Partial<IHttpRequestHookOptions>) =>
  usePaginatedHttpRequest<IAuthUserAccount>(
    findClubShopSeatCandidatesRequest(clubId, page, size),
    paginatedOptions(page, size, opts)
  );

const useAggregatedSeats = (seats?: ISeat[], reload?: Function, lastFetchTime?: number | null) => {
  return useMemo(
    () => {
      const taken = seats?.filter(s => isNotNil(s.athlete));
      const free = seats?.filter(s => isNil(s.athlete) && s.locked !== true);
      const isTaken = (s: ISeat) => taken?.find(t => s.id === t.id);
      const locked = seats?.filter(s => s.locked === true && !isTaken(s));

      return {
        all: seats || [],
        locked: locked || [],
        taken: taken || [],
        free: free || [],
        reload,
        lastFetchTime,
      };
    },
    [seats, reload, lastFetchTime]
  );
};

export const useMySeats = () => {
  const seatsReq = useFindMyShopSeats();
  const seats = seatsReq?.response?.data?.seats;
  return useAggregatedSeats(seats, seatsReq.load.bind(seatsReq), seatsReq?.lastFetchTime);
};

export const useClubSeats = (clubId: string) => {
  const seatsReq = useFindClubShopSeats(clubId);
  const seats = seatsReq?.response?.data?.seats;
  return useAggregatedSeats(seats, seatsReq.load, seatsReq?.lastFetchTime);
};


export const useUnassignSeatEndpoint = () =>
  useApiEndpoint(
    unassignMyShopSeatRequest
  );


export const useUnassignSeat = (opts?: any) => {
  const t = useTranslator();

  return useDeleteConfirmation({
    title: t(`app.shop.action.unassign-seat.confirm.title`),
    message: t(`app.shop.action.unassign-seat.confirm.message`),
    cancelLabel: t('general.action.cancel'),
    confirmLabel: t('general.action.confirm'),
    endpoint: useUnassignSeatEndpoint(),
    ...opts
  });
};
