import React, {useMemo} from 'react';
import {useTranslator} from '@bitsolve/react-common';

export interface IAppPageToSMessage {
  termsUrl: string;
  privacyTermsUrl: string;
  membershipTermsUrl: string;
}

export const AppPageToSMessage: React.FC<IAppPageToSMessage> = (props) => {
  const {termsUrl, privacyTermsUrl, membershipTermsUrl} = props;
  const t = useTranslator();
  const message = useMemo(
    () => {
      return t('general.tos.message', {
        generalTerms: `<a href="${termsUrl}" target="_blank">${t('general.tos.policy.general')}</a>`,
        privacyTerms: `<a href="${privacyTermsUrl}" target="_blank">${t('general.tos.policy.privacy')}</a>`,
        membershipTerms: `<a href="${membershipTermsUrl}" target="_blank">${t('general.tos.policy.membership')}</a>`,
      });
    },
    [t, termsUrl, privacyTermsUrl, membershipTermsUrl]
  );

  return <div className="app-page-tos-message">
    <span dangerouslySetInnerHTML={{__html: message}} />
  </div>;
};
