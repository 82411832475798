import {
  ICoachingZoneAnalysisDetailsContext,
  RoundDetailsItem,
  RouteExtractedUiExercise,
  RouteExtractedUiRound
} from './coaching-zone-analysis.model';
import {IAnalysisSegmentType} from '../../../../../module/analysis/component/analysis-details.model';
import {IRoute} from '../../../../../core/routing';
import {chartColorWheel, convertVelocityFromMetric, Rooq} from '../../../../../core/domain.model';
import {chart, segment} from '../../../../../module/analysis/component/analysis-details.builder';
import {hasProp, isNil, isNonEmptyStr, isNotNil, prop, propIn} from '@bitsolve/fns';
import {IMultiChartItem} from '../../../../../module/analysis/component/chart/multi-charts.component';
import {IBarChartRoutedFn} from '../../../../../module/analysis/component/chart/bar-chart.component';
import {analysisIcons} from '../../../../../module/analysis/component/analysis-icons';
import {AnalysisPunchType} from '../../../../../module/analysis/component/analysis-punch-type.component';
import React from 'react';
import {sortByPunchType} from '../../../../../module/analysis/component/analysis-details.hooks';
import {UiTextLine} from '@bitsolve/react-common';

const routeParam = (route: IRoute, p: string): any => prop(route?.match?.params, p);
const removeTotalValue = (vals?: null | { label: string; [_: string]: any; }[]) => vals?.filter(v => v.label !== 'TOTAL');
const findTotalValue = (vals?: null | { label: string; [_: string]: any; }[]) => vals?.find(v => v.label === 'TOTAL');

export const createExerciseAnalysisSegmentsFromSessionsV2 = (
  context: ICoachingZoneAnalysisDetailsContext,
  exerciseFromRoute: (route: IRoute) => null | RouteExtractedUiExercise,
  roundFromRoute: (route: IRoute) => null | RouteExtractedUiRound
): IAnalysisSegmentType[] => {
  const {translate: t, navigate: nav} = context;

  return [
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: `${context.pathPrefix}/:tab/:exercise/:metric?/:round?`,
            },
            segments: [
              segment('single')
                .chart('heading', builder => builder.withProps({
                  text: (route: IRoute) => {
                    const ex = exerciseFromRoute(route);

                    if (isNil(ex)) {
                      return '–';
                    } else {
                      return t(`app.analysis.details.exercise.title`, {
                        number: ex.index + 1,
                        discipline: t(`content.discipline.${ex.type}`),
                      });
                    }
                  },
                  headingLevel: 2,
                  onClose: () => nav(`overview`)
                }))
                .build(),
              (route) => {
                const params = route?.match?.params;
                const roundSelected = hasProp(params, 'round') && isNotNil(params?.round);

                return roundSelected
                  ? null
                  : segment('single')
                    .chart('heading', builder => builder.withProps({
                      text: t('app.analysis.details.message.select-round'),
                    }))
                    .build();
              },
              segment('single')
                .chart('multi-routed', builder => builder
                  .withProps({
                    router: {
                      param: 'metric',
                      pattern: `${context.pathPrefix}/:tab/:exercise/:metric?/:round?`,
                      redirect: {
                        exact: true,
                        from: `${context.pathPrefix}/:tab/:exercise`,
                        to: (route: IRoute) => {
                          const tab = routeParam(route, 'tab');
                          const exercise = routeParam(route, 'exercise');
                          // debugger;
                          return `/portal/analysis/compare/${tab}/${exercise}/punch-count`;
                        }
                      }
                    },
                    header: {
                      title: t('app.analysis.details.exercise.chart.round-comparison.title')
                    },
                    items: [
                      ...([
                        {
                          label: 'app.analysis.details.exercise.chart.round-comparison.tab.punch-count',
                          param: 'punch-count',
                          dataBuilder: (route, session, ex) =>
                            ex?.roundsTimelines?.punchCount?.values?.map(value => ({value})) || [],
                        },
                        {
                          label: 'app.analysis.details.exercise.chart.round-comparison.tab.score',
                          param: 'score',
                          dataBuilder: (route, session, ex) =>
                            ex?.roundsTimelines?.rooqScore?.values?.map(value => ({value})) || [],
                        },
                        {
                          label: 'app.analysis.details.exercise.chart.round-comparison.tab.speed',
                          param: 'speed',
                          dataBuilder: (route, session, ex) =>
                            ex?.roundsTimelines?.avgMaxSpeed?.values?.map(value => ({value: convertVelocityFromMetric(value, context.unitSystem)})) || [],
                        },
                        {
                          label: 'app.analysis.details.exercise.chart.round-comparison.tab.power',
                          param: 'power',
                          dataBuilder: (route, session, ex) =>
                            ex?.roundsTimelines?.avgPowerAtMaxSpeed?.values?.map(value => ({value})) || [],
                        }
                      ] as RoundDetailsItem[]).map((item) => {
                        const {label, param, dataBuilder} = item;

                        return {
                          menu: {
                            label: t(label),
                            param,
                            interact: (route: IRoute) => {
                              const tab = routeParam(route, 'tab');
                              const exercise = routeParam(route, 'exercise');
                              const round = routeParam(route, 'round');

                              nav(isNonEmptyStr(round)
                                ? `${tab}/${exercise}/${param}/${round}`
                                : `${tab}/${exercise}/${param}`);
                            },
                            hide: (route: IRoute, item: IMultiChartItem) => {
                              return param === 'power'
                                && exerciseFromRoute(route)?.type === Rooq.DisciplineV2.SHADOW;
                            }
                          },
                          chart: (route: IRoute) => {
                            const ex = exerciseFromRoute(route);

                            if (ex?.type === Rooq.DisciplineV2.SHADOW && routeParam(route, 'metric') === 'power') {
                              return null;
                            }

                            return chart('bar')
                              .withProps({
                                data: dataBuilder(route, ex?.session as any, ex as any),
                                routed: ((route, _, idx) => {
                                  const round = propIn(route, ['match', 'params', 'round']);
                                  return `${idx + 1}` === round;
                                }) as IBarChartRoutedFn,
                                onBarClick: (route, item, idx) => {
                                  const tab = routeParam(route, 'tab');
                                  const exer = routeParam(route, 'exercise');
                                  const metric = routeParam(route, 'metric');
                                  nav(`${tab}/${exer}/${metric}/${idx + 1}`);
                                }
                              })
                              .build()
                          }
                        };
                      }),
                    ]
                  }))
                .build(),
            ],
          },
          {
            route: {
              pattern: `${context.pathPrefix}/:tab/:exercise/:metric`,
              exact: true,
            },
            segments: [
              segment('single')
                .chart('heading', builder => builder.withProps({
                  text: t('app.analysis.details.total'),
                  headingLevel: 2,
                }))
                .build(),
              // segment('single')
              //   .chart('heading', builder => builder.withProps({
              //     text: t('app.analysis.details.message.select-round'),
              //   }))
              //   .build(),
              (route) => {
                const ex = exerciseFromRoute(route);

                if (!ex) return null;

                const totalPunches = ex.roundsTimelines.punchCount.values.reduce((s, v) => s + v, 0);
                const avgMaxSpeed = ex.session.summary.exercises[ex.index].stats.avgMaxSpeed;
                const avgPowerAtMaxSpeed = ex.session.summary.exercises[ex.index].stats.avgPowerAtMaxSpeed;

                return segment('single')
                  .chart('icon-stats', builder => builder
                    .withProps({
                      data: [
                        {
                          icon: analysisIcons.clock,
                          label: t('app.analysis.details.rounds', {count: ex.data.length}),
                          value: ex.data.length - 1,
                        },
                        {
                          icon: analysisIcons.counter,
                          label: t('app.analysis.details.punches', {count: totalPunches}),
                          value: totalPunches || '–',
                        },
                        {
                          icon: analysisIcons.punch_speed,
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.speed.label')}),
                          // value: ex.roundsTimelines.avgMaxSpeed.yAxisValue || '–',
                          value: avgMaxSpeed
                            ? `${convertVelocityFromMetric(avgMaxSpeed, context.unitSystem)} ${t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`)}`
                            : '–'
                        },
                        {
                          icon: analysisIcons.punch_power,
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.power')}),
                          value: avgPowerAtMaxSpeed || '–',
                        },
                      ]
                    }))
                  .build();
              },
            ]
          },
          {
            route: {
              pattern: `${context.pathPrefix}/:tab/:exercise/:metric/:round`,
            },
            segments: [
              segment('single')
                .chart('heading', builder => builder.withProps({
                  text: route => {
                    const round = routeParam(route, 'round');
                    return t(`app.analysis.details.exercise.round.title`, {number: round});
                  },
                  headingLevel: 2,
                  onClose: (route) => {
                    const exercise = propIn(route, ['match', 'params', 'exercise']);
                    const metric = propIn(route, ['match', 'params', 'metric']);
                    nav(`overview/${exercise}/${metric}`);
                  }
                }))
                .build(),
              (route) => {
                const round = roundFromRoute(route);

                if (!round) return null;

                return segment('single')
                  .chart('icon-stats', builder => builder
                    .withProps({
                      data: [
                        {
                          icon: analysisIcons.clock,
                          label: t('general.unit.minute.long', {count: round.duration}),
                          value: round.duration > 1 ? `~${round.duration}` : '< 1'
                        },
                        {
                          icon: analysisIcons.counter,
                          label: t('app.analysis.details.punches', {count: round.stats?.punchCount || 0}),
                          value: round.stats?.punchCount || 0,
                        },
                        {
                          icon: analysisIcons.punch_speed,
                          label: t(`app.analysis.details.chart.unit.avg`, {unit: t(`app.analysis.details.chart.unit.speed.label`)}),
                          value: round.stats?.avgMaxSpeed
                            ? `${convertVelocityFromMetric(round.stats?.avgMaxSpeed, context.unitSystem)} ${t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`)}`
                            : '–'
                        },
                        {
                          icon: analysisIcons.punch_power,
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.power')}),
                          value: round.stats?.avgPowerAtMaxSpeed || '–',
                        },
                      ]
                    }))
                  .build();
              },
              segment('single')
                .chart('heading', builder => builder.withProps({
                  text: t('app.analysis.details.exercise.round.alt-title'),
                  headingLevel: 3,
                }))
                .build(),
              segment('single')
                .chart('multi', builder => builder.withProps({
                  header: {
                    title: t('app.analysis.details.chart.punch-type-distribution.title'),
                  },
                  items: [
                    {
                      menu: {
                        label: t('app.analysis.details.exercise.round.tab.punch-count'),
                        param: 'punch-count',
                      } as any,
                      chart: (route) => {
                        const round = roundFromRoute(route);

                        if (isNil(round)) return null;

                        return chart('lr-unit')
                          .withProps({
                            data: removeTotalValue(round.distributions.punchCount.values)
                              ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              ?.map((v, i) => ({
                                leftValue: v.leftValue || 0,
                                rightValue: v.rightValue || 0,
                                unit: t('app.analysis.details.chart.unit.count'),
                                color: chartColorWheel(i),
                                centerContent: <AnalysisPunchType punchType={v.label as any} />,
                              })) || []
                          })
                          .build();
                      }
                    },
                    {
                      menu: {
                        label: t('app.analysis.details.exercise.round.tab.avg-speed'),
                        param: 'avg-speed',
                      } as any,
                      chart: (route) => {
                        const round = roundFromRoute(route);

                        if (isNil(round)) return null;

                        return chart('lr-unit')
                          .withProps({
                            data: removeTotalValue(round.distributions.avgMaxSpeed.values)
                              ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              ?.map((v, i) => ({
                                leftValue: convertVelocityFromMetric(v.leftValue, context.unitSystem) || 0,
                                rightValue: convertVelocityFromMetric(v.rightValue, context.unitSystem) || 0,
                                color: chartColorWheel(i),
                                unit: t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
                                centerContent: <AnalysisPunchType punchType={v.label as any} />,
                              })) || [],
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = findTotalValue(round.distributions.avgMaxSpeed.values);

                              return row
                                ? {
                                  leftValue: convertVelocityFromMetric(row.leftValue, context.unitSystem),
                                  rightValue: convertVelocityFromMetric(row.rightValue, context.unitSystem),
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build();
                      }
                    },
                    {
                      menu: {
                        label: t('app.analysis.details.exercise.round.tab.max-speed'),
                        param: 'max-speed',
                      } as any,
                      chart: (route) => {
                        const round = roundFromRoute(route);

                        if (isNil(round)) return null;

                        return chart('lr-unit')
                          .withProps({
                            data: removeTotalValue(round.distributions.bestMaxSpeed.values)
                              ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              ?.map((v, i) => ({
                                leftValue: convertVelocityFromMetric(v.leftValue, context.unitSystem) || 0,
                                rightValue: convertVelocityFromMetric(v.rightValue, context.unitSystem) || 0,
                                color: chartColorWheel(i),
                                unit: t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
                                centerContent: <AnalysisPunchType punchType={v.label as any} />,
                              })) || [],
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = findTotalValue(round.distributions.bestMaxSpeed.values);

                              return row
                                ? {
                                  leftValue: convertVelocityFromMetric(row.leftValue, context.unitSystem),
                                  rightValue: convertVelocityFromMetric(row.rightValue, context.unitSystem),
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build();
                      },
                    },
                    {
                      menu: {
                        label: t('app.analysis.details.exercise.round.tab.avg-power'),
                        param: 'avg-power',
                        hide: (route: IRoute, item: IMultiChartItem) => {
                          return exerciseFromRoute(route)?.type === Rooq.DisciplineV2.SHADOW;
                        }
                      } as any,
                      chart: (route) => {
                        const round = roundFromRoute(route);

                        if (isNil(round)) return null;

                        return chart('lr-unit')
                          .withProps({
                            data: removeTotalValue(round.distributions.avgPowerAtMaxSpeed.values)
                              ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              ?.map((v, i) => ({
                                leftValue: v.leftValue || 0,
                                rightValue: v.rightValue || 0,
                                unit: t('app.analysis.table.unit.power'),
                                color: chartColorWheel(i),
                                centerContent: <AnalysisPunchType punchType={v.label as any} />,
                              })) || [],
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = findTotalValue(round.distributions.avgPowerAtMaxSpeed.values);

                              return row
                                ? {
                                  leftValue: row.leftValue,
                                  rightValue: row.rightValue,
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build();
                      },
                    },
                    {
                      menu: {
                        label: t('app.analysis.details.exercise.round.tab.max-power'),
                        param: 'max-power',
                        hide: (route: IRoute, item: IMultiChartItem) => {
                          return exerciseFromRoute(route)?.type === Rooq.DisciplineV2.SHADOW;
                        }
                      } as any,
                      chart: (route) => {
                        const round = roundFromRoute(route);

                        if (isNil(round)) return null;

                        return chart('lr-unit')
                          .withProps({
                            data: removeTotalValue(round.distributions.bestPowerAtMaxSpeed.values)
                              ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              ?.map((v, i) => ({
                                leftValue: v.leftValue || 0,
                                rightValue: v.rightValue || 0,
                                unit: t('app.analysis.table.unit.power'),
                                color: chartColorWheel(i),
                                centerContent: <AnalysisPunchType punchType={v.label as any} />,
                              })) || [],
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = findTotalValue(round.distributions.bestPowerAtMaxSpeed.values);

                              return row
                                ? {
                                  leftValue: row.leftValue,
                                  rightValue: row.rightValue,
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build();
                      },
                    }
                  ]
                }))
                .build(),

              (route) => {
                const round = roundFromRoute(route);
                if (!round) return;

                return segment('dual')
                  .chart('pie', builder => builder
                    .withHeader({title: t('app.analysis.details.chart.punch-count-by-speed-groups.title')})
                    .withProps({
                      data: round.countsGroupedBy.maxSpeed.charts.map((v, i) => ({
                        label: t(`content.speedGroup.${v.label}.${context.unitSystem}`),
                        value: v.count,
                        color: chartColorWheel(i),
                        unit: t('app.analysis.details.chart.punch-count-by-speed-groups.unit', {count: v.count}),
                      })),
                      aggregateUnit: 'app.analysis.details.chart.punch-count-by-speed-groups.aggregate.unit'
                    }))
                  .chart('pie', builder => builder
                    .withHeader({title: t('app.analysis.details.chart.punch-count-by-combo.title')})
                    .withProps({
                      data: round.countsGroupedBy.comboLength.charts.map((v, i) => ({
                        label: t(`content.combo.${v.label}`),
                        value: v.count,
                        unit: t('app.analysis.details.chart.punch-count-by-combo.unit', {count: v.count}),
                        color: chartColorWheel(i),
                      })),
                      aggregateUnit: 'app.analysis.details.chart.punch-count-by-combo.aggregate.unit'
                    }))
                  .build();
              },
            ]
          }
        ]
      }))
      .build()
  ];
};
