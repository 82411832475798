import React, {useEffect} from 'react';
import {AxiosClient, useHttpClient} from '@bitsolve/react-common';
import {useAuthActions} from '../../auth.store';
import {AxiosError, AxiosInstance} from 'axios';
import {useFirebaseAppContext} from '../../firebase';
import {isNotNil} from '@bitsolve/fns';
import {useDispatch} from 'react-redux';
import {ConnectivityAction} from '../../../connectivity/connectivity.store';

export interface IAuthGuardProvider {

}

export const AuthAPIGuardProvider: React.FC<IAuthGuardProvider> = (props) => {
  const {children} = props;

  const http = useHttpClient();
  const fb = useFirebaseAppContext();
  const {unauthenticate, updateAccess} = useAuthActions();
  const d = useDispatch();

  useEffect(
    () => {
      const axios = (http as AxiosClient).getDelegate();

      const iceptId = (axios as AxiosInstance).interceptors.response.use(
        res => res,
        (error: AxiosError) => {
          const status = error?.response?.status;

          if (isNotNil(status)) {
            const sc = `${status}`.substr(0, 1);

            if (sc === '5') {
              console.log('%c[5xx]', 'color:red;font-weight:bold;', 'Server error:', error?.message);

              d({type: ConnectivityAction.checkBackendError, payload: {error}});
            } else if (status === 401) {
              console.log('%c[401]', 'color:red;font-weight:bold;', 'Authentication error:', error?.message);

              fb?.app?.auth()?.currentUser?.getIdTokenResult(true)
                .then((res) => {
                  updateAccess(res);
                })
                .catch(err => {
                  console.log('%c[401]', 'color:red;font-weight:bold;', 'Error after authentication failure ID token refresh:', err);

                  fb?.app.auth()
                    .signOut()
                    .then(() => unauthenticate())
                    .catch(e => console.error('Error in automated signout', e));
                });
            } else {
              console.warn('Unknown error:', error);
            }
          }

          return error;
        }
      );

      return () => {
        (axios as AxiosInstance).interceptors.response.eject(iceptId);
      };
    },
    [http, unauthenticate, updateAccess, fb, d]
  );

  return <>{children}</>;
};
