import React from 'react';
import {
  classNames,
  IUiButton,
  UiBox,
  UiButtonGroup,
  UiButtonVariation,
  UiCustomizable,
  UiDir,
  UiIcon,
  UiTextLine,
  UiWithIcon
} from '@bitsolve/react-common';
import {NavLink} from 'react-router-dom';
import {isEmpty, isNil} from '@bitsolve/fns';

export interface IAppPageMenuItem extends Partial<UiWithIcon> {
  to?: string;
  interact?: (item: IAppPageMenuItem) => any;
  label?: string;
  exact?: boolean;
  disabled?: boolean;
}

export interface IAppPageMenu extends UiCustomizable {
  items: IAppPageMenuItem[];
  actions?: IUiButton[];
}

export const AppPageMenuItem: React.FC<{ item: IAppPageMenuItem; }> = (props) => {
  const {item} = props;

  if (isNil(item.to) || item.interact) {
    return <UiBox className="app-page-menu__item"
                  interact={() => item.interact && item.interact(item)}>
      {item.icon && <UiIcon name={item.icon} iconSet={item.iconSet || 'regular'} />}
      {item.label && <UiTextLine text={item.label} />}
    </UiBox>
  }

  return <NavLink className="app-page-menu__item"
                  to={item.to || '#'}
                  exact={!!item.exact}>
    {item.icon && <UiIcon name={item.icon} iconSet={item.iconSet || 'regular'} />}
    {item.label && <UiTextLine text={item.label} />}
  </NavLink>
}

export const AppPageMenu: React.FC<IAppPageMenu> = (props) => {
  const {items, actions, className, style} = props;

  return <menu className={classNames('app-page-menu', className)}
               style={style}>
    {items.map((item, i) => <AppPageMenuItem item={item}
                                             key={`${i}.${item.to}`} />)}
    {actions && !isEmpty(actions) && <UiBox style={{margin: '0 0 0 auto', padding: '0.625em 0'}}>
      <UiButtonGroup size={'sm'}
                     direction={UiDir.h}
                     variation={UiButtonVariation.outlines}
                     actions={actions} />
    </UiBox>}
  </menu>;
};
