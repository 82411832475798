import {IIntlMessageProvider, IIntlMessages, nestedHTTPIntlMessageProvider} from '@bitsolve/react-common';
import {assoc, isEmpty, isNonEmptyStr, keys, prop} from '@bitsolve/fns';
import preferredLocale from 'preferred-locale';


const mergeMessages = (a: any, b: any): any => {
  return keys(b).reduce(
    (msgs, msg) => assoc(msgs, msg, prop(b, msg)),
    a
  );
};

const mergeAllMessages = (messages: Record<string, string>[]): any => {
  return messages.reduce((r, ms) => mergeMessages(r, ms), {});
};

export const defaultAvailableLocales = ['de', 'en', 'es', 'ru'];


const userLocaleKey = `@rooq/intl/locale`;

const setUserPreferredLocale = (locale: string) =>
  window.localStorage.setItem(userLocaleKey, locale);

const getUserPreferredLocale = () =>
  window.localStorage.getItem(userLocaleKey);

export const getPreferredLocale = (available: string[] = defaultAvailableLocales) => {
  const langs = isEmpty(available) ? ['de'] : available;
  const fallback = available[0];
  const userPreference = getUserPreferredLocale();

  return isNonEmptyStr(userPreference)
    ? userPreference.trim()
    : preferredLocale(langs, fallback);
};

export const changeLocale = (locale: string) => {
  if (locale === getUserPreferredLocale()) return;
  setUserPreferredLocale(locale);
  window.location.reload();
};

export const getMessageProvider = (providers: string[]): IIntlMessageProvider => {
  const mappedProviders = providers?.map(nestedHTTPIntlMessageProvider);

  return {
    isStale(_: string): boolean {
      return true;
    },
    provideMessages(locale: string): Promise<IIntlMessages> {
      return Promise
        .all(mappedProviders.map(provider => provider.provideMessages(locale)))
        .then((results) => Promise.resolve(mergeAllMessages(results)))
    }
  };
};
