import React from 'react';
import {
  classNames,
  UiCustomizable,
  UiDateDisplay,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiTextLine,
  useTranslator
} from '@bitsolve/react-common';
import {Rooq} from '../../../core/domain.model';
import {isEmpty} from '@bitsolve/fns';

export interface IClubOpeningHours extends UiCustomizable {
  items: Rooq.ClubOpeningTimeframe[];
  editable?: boolean;
}

const ClubOpeningTime: React.FC<{ time: Rooq.ClubOpeningTimeframeSlot; }> = (props) => {
  const {time} = props;

  if (!time || (!time.startTime && !time.endTime)) {
    return <div className="txt-c-defd">
      <span>{'—'}</span>
    </div>
  }

  const {startTime, endTime} = time;

  return <div className="txt-c-defd mg-b-xs">
    {startTime && <UiDateDisplay date={startTime} format={'HH:mm'} />}
    {startTime && endTime && <span className={'pd-h-xs'}>{'–'}</span>}
    {endTime && <UiDateDisplay date={endTime} format={'HH:mm'} />}
    {endTime && <span className={'mg-l-xs'}>{'Uhr'}</span>}
  </div>;
};

export const ClubOpeningHoursRow: React.FC<{ item: Rooq.ClubOpeningTimeframe; }> = (props) => {
  const {item} = props;
  const {days, times} = item;

  const t = useTranslator();

  return <UiFlexRow ai={UiFlexAlign.s}
                    className={'app-club__opening-hours__row'}>
    <UiFlexCol className="txt-b f-1 pd-r-nm">
      {days.map((day, i) => <UiTextLine key={i} text={t(`general.date.weekday.${day}`)} />)}
    </UiFlexCol>
    <UiFlexCol className={'mg-t-xs'}>
      {isEmpty(times)
        ? <span className="txt-c-defd mg-b-xs">{'—'}</span>
        : times.map((time, i) => <ClubOpeningTime key={i}
                                                  time={time} />)}
    </UiFlexCol>
  </UiFlexRow>;
};

export const ClubOpeningHours: React.FC<IClubOpeningHours> = (props) => {
  const {items, editable = false, className, style} = props;

  return <UiFlexCol
    className={classNames('app-club__opening-hours', editable && 'app-club__opening-hours--editable', 'txt-sm', className)}
    style={style}>
    {items.map((item, i) => <ClubOpeningHoursRow key={i} item={item} />)}
  </UiFlexCol>;
};
