import React, {useMemo} from 'react';
import {IClub} from '../club.model';
import {IUiButton, UiBox, UiButtonGroup, UiFlexAlign, UiFlexCol, UiFlexRow, UiTextLine} from '@bitsolve/react-common';
import {ClubLogo} from './club-logo.component';
import {IUiTooltipMenu, UiTooltipMenu} from '../../ui/component/ui-tooltip-menu.component';
import {cityLine} from '../../../app/common/misc/app-address';

export interface IClubListItem {
  club: IClub;
  menu?: IUiTooltipMenu;
  interact?: (club: IClub) => any;
  actions?: IUiButton[];
}

export const ClubListItem: React.FC<IClubListItem> = (props) => {
  const {club, interact, actions, menu} = props;
  const {logoPictureId} = club;
  const address = useMemo(() => club.address ? cityLine(club.address) : null, [club]);

  return <UiFlexRow className="f-1 app__list-item app-club__list-item"
                    ai={UiFlexAlign.c}
                    onClick={interact ? () => interact(club) : undefined}>
    <ClubLogo pictureId={logoPictureId}
              className={'app__list-item__avatar'}
              key={club.id}
              size={'3.25em'} />
    <UiFlexCol className="f-1 app__list-item__title">
      <UiTextLine bold text={club.name} />
      {address && <UiTextLine text={address}
                              className={'txt-sm'}
                              style={{color: 'var(--color-text--default-dim)'}} />}
    </UiFlexCol>
    {(menu || actions) && <UiBox interact={ev => ev.stopPropagation()}
                                 className={'app__list-item__menu'}>
      {menu
        ? <UiTooltipMenu {...menu} />
        : (actions ? <UiButtonGroup actions={actions} /> : null)}
    </UiBox>}
  </UiFlexRow>;
};

