import React from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {toast, UiPageContainer, useTranslator} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {TrainingPlanForm} from '../../../../module/training-plan/component/training-plan-form.component';
import {
  useDeleteTrainingPlanConfirmation,
  useFindTrainingPlan,
  useUpdateTrainingPlan
} from '../../../../module/training-plan/training-plan.api';
import {propIn} from '@bitsolve/fns';
import {AppSpinnerPane} from '../../../common/misc/app-spinner-pane.component';

export interface ICoachingZoneTrainingPlanEditRoute extends IRoute {
}


export const CoachingZoneTrainingPlanEditRoute: React.FC<ICoachingZoneTrainingPlanEditRoute> = (props) => {
  const {match} = props;
  const planId = propIn(match as any, ['params', 'planId']);

  const t = useTranslator();
  const nav = useNavigator();
  const planReq = useFindTrainingPlan(planId, {initialFetch: true});
  const plan = planReq?.response?.data;

  const updateTrainingPlan = useUpdateTrainingPlan(
    planId,
    (r) => toast.success(t('app.training-plan.action.update.toast.success')),
    (e) => toast.error(t('app.training-plan.action.update.toast.error')),
  );

  const deleteTrainingPlan = useDeleteTrainingPlanConfirmation(
    () => {
      toast.success(t('app.training-plan.action.delete.toast.success'));
      nav('/portal/training-plan');
    },
    () => {
      toast.error(t('app.training-plan.action.delete.toast.error'));
    },
  );

  return <>
    <UiPageContainer fluid className={'app-coachz app-coachz__home'}
                     style={{position: 'relative', maxHeight: '100vh', minHeight: 0, overflow: 'hidden'}}>
      <AppPageHeader
        title={t('app.training-plan.action.update.label')}
        aria-busy={planReq.busy}
        backAction={{
          path: '/portal/training-plan',
          exact: true
        }} />
      {planReq.busy
        ? <AppSpinnerPane spinner={{size: 'lg'}} />
        : (plan && <TrainingPlanForm key={planId}
                                     value={plan}
                                     busy={updateTrainingPlan.busy}
                                     deleteAction={deleteTrainingPlan}
                                     interact={data => updateTrainingPlan.send(data)} />)}
    </UiPageContainer>
    {deleteTrainingPlan.modal.element}
  </>;
};
