import {AppWelcomeRoute} from './welcome/app-welcome-route.component';
import {AppLoginRoute} from './login/app-login-route.component';
import {AppRegisterRoute} from './register/app-register-route.component';
import {AppVerificationPendingRoute} from './register/app-verification-pending-route.component';
import {useAuthAccount, useAuthenticated, useAuthTouched, useAuthUser} from '../../../module/auth/auth.store';
import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router';
import {isNotNil, isNotTrue, prop} from '@bitsolve/fns';
import {AppResetPasswordRoute} from './reset-password/reset-password-route.component';
import {useSubscriptionToken} from '../../../module/shop/shop.util';
import {AppRegisterCompleteRoute} from './register/app-register-complete-route.component';


const _paths = {
  home: '/portal/trainer',
  adminHome: '/',
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  registerVerificationPending: '/register/verify-email',
  registerCompleteAccount: '/register/complete-profile',
  registerCompleteTrainerProfile: '/register/complete-profile',
};

export const onboardingPath = (k: keyof typeof _paths): string | null => {
  return prop(_paths, k);
};

// this gets your users to 100% the right place 80% of the time
export const useOnboardingRedirects = (preventHomeRedirect?: boolean) => {
  const authd = useAuthenticated();
  const touched = useAuthTouched();
  const user = useAuthUser();
  const account = useAuthAccount();
  const h = useHistory();
  const subToken = useSubscriptionToken();
  const nav = useCallback(
    (path: string) => h.push(subToken
      ? `${path}?subId=${subToken}`
      : path),
    [h, subToken]
  );

  useEffect(
    () => {
      if (isNotTrue(authd) && isNotNil(touched) && isNotTrue(preventHomeRedirect)) {
        nav(_paths.home);
      }
    },
    [nav, authd, touched, preventHomeRedirect]
  );

  useEffect(
    () => {
      if (!authd || !user || !account) {
        return;
      }

      if (!user.emailVerified) {
        nav(_paths.registerVerificationPending);
      } else if (!account.completed) {
        nav(_paths.registerCompleteAccount);
      } else if (!account.trainerProfile.completed && !account.trainerProfile.skippedProfile) {
        nav(_paths.registerCompleteTrainerProfile);
      } else {
        nav(_paths.home);
      }
    },
    [nav, authd, user, account]
  );
};

export const onboardingRoutes = [
  {
    path: _paths.home,
    exact: true,
    component: AppWelcomeRoute
  },
  {
    path: _paths.login,
    component: AppLoginRoute
  },
  {
    path: _paths.register,
    component: AppRegisterRoute,
    exact: true
  },
  {
    path: _paths.resetPassword,
    component: AppResetPasswordRoute
  },
  {
    path: _paths.registerVerificationPending,
    component: AppVerificationPendingRoute
  },
  {
    path: _paths.registerCompleteAccount,
    component: AppRegisterCompleteRoute
  },
  // {
  //   path: _paths.registerCompleteTrainerProfile,
  //   component: AppCompleteTrainerProfileRoute
  // }
];


export const adminOnboardingRoutes = [
  {
    path: _paths.adminHome,
    component: AppLoginRoute
  }
];


