import {assoc, isNil, isNotNil, isNum, keys, prop} from '@bitsolve/fns';
import {useAuthAccount, useAuthUserUnitSystem} from '../module/auth/auth.store';
import {useMemo} from 'react';
import {RooqStyle, style, useRooqStyle} from '../style';
import {IAuthUserAccount} from '../module/auth';

export interface IEntity {
  id: string;
}

export interface IBuilder<T = any> {
  build(): T;
}


export namespace Rooq {

  export const WEIRD_DATE_OFFSET = 946684800000; // ms since epoch to 2000-01-01

  export interface Address extends IEntity {
    country: string;
    street: string;
    zipCode: string;
    city: string;
    houseNumber: string;
  }

  export enum Claim {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_BETA = 'ROLE_BETA',
    ROLE_DEV = 'ROLE_DEV',
  }

  export enum UserTour {
    OnboardingBuySeats = 'CZ_ONBOARDING_BUY_SEATS',
    OnboardingFindAthlete = 'CZ_ONBOARDING_FIND_ATHLETE',
    OnboardingSeeAnalysis = 'CZ_ONBOARDING_SEE_ANALYSIS',
    OnboardingTrialAssignSeat = 'CZ_ONBOARDING_TRIAL_ASSIGN_SEAT',
    OnboardingWelcome = 'CZ_ONBOARDING_WELCOME',
    SampleDataAnalysis = 'CZ_SAMPLE_DATA_ANALYSIS',
    SampleDataTrainingPlan = 'CZ_SAMPLE_DATA_TRAINING_PLAN',
  }

  export interface ChampionshipTitle {
    name: string;
    year: string;
  }

  export enum ClubOpeningDay {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
  }

  export interface ClubOpeningTimeframeSlot {
    id?: string;
    startTime: Date | string | number;
    endTime: Date | string | number;
  }

  export interface ClubOpeningTimeframe {
    days: ClubOpeningDay[];
    times: ClubOpeningTimeframeSlot[];
  }

  export enum Discipline {
    SHADOW = 'SHADOW',
    EQUIPMENT = 'EQUIPMENT',
    ROPE_SKIPPING = 'ROPE_SKIPPING',
    PAUSE = 'PAUSE',
    PARTNER = 'PARTNER',
    CUSTOM = 'CUSTOM',
    UNKNOWN_ACTIVITY = 'UNKNOWN_ACTIVITY'
  }

  export enum DisciplineV2 {
    ROPE = 'ROPE',
    SHADOW = 'SHADOW',
    PARTNER = 'PARTNER',
    EQUIPMENT = 'EQUIPMENT',
    ROPE_SKIPPING = 'ROPE_SKIPPING',
    UNKNOWN_ACTIVITY = 'UNKNOWN_ACTIVITY',
  }

  export enum PunchTypeV2 {
    BODY_SHOT = 'BODY_SHOT',
    UPPERCUT = 'UPPERCUT',
    STRAIGHT = 'STRAIGHT',
    HOOK = 'HOOK',
  }

  export enum FightingClass {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
  }

  export enum FightStyle {
    BOXING = 'BOXING',
    KICK_BOXING = 'KICK_BOXING',
    MMA = 'MMA',
    THAI_BOXING = 'THAI_BOXING',
    OTHER = 'OTHER',
  }

  export enum Gender {
    DIVERSE = 'DIVERSE',
    FEMALE = 'FEMALE',
    MALE = 'MALE',
  }

  export enum CountryCode {
    DE = 'DE',
    CH = 'CH',
    AT = 'AT'
  }

  export enum SkillLevel {
    BEGINNER = 'BEGINNER',
    ADVANCED = 'ADVANCED',
    EXPERT = 'EXPERT',
    PROFESSIONAL = 'PROFESSIONAL',
  }

  export enum MembershipType {
    ATHLETE = 'ATHLETE',
    TRAINER = 'TRAINER',
  }

  export enum MembershipRole {
    MEMBER = 'MEMBER',
    ADMIN = 'ADMIN',
  }

  export enum InvitationStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
  }

  export enum RelationshipStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    NONE = 'NONE',
  }

  export enum RelationshipType {
    ATHLETE = 'ATHLETE',
    TRAINER = 'TRAINER',
  }

  export enum InvitationType {
    ATHLETE = 'ATHLETE',
    TRAINER = 'TRAINER',
  }

  export enum UnitSystem {
    METRIC = 'METRIC',
    IMPERIAL = 'IMPERIAL',
  }

  export interface Notification extends IEntity {
    title: { key: string; params?: any; };
    body: { key: string; params?: any; };
    seen: boolean;
  }

  export enum Stance {
    ORTHODOX = 'ORTHODOX',
    SOUTHPAW = 'SOUTHPAW',
    SWITCH_HITTER = 'SWITCH_HITTER',
  }

  export enum TrainerType {
    PROFESSIONAL_TRAINER = 'PROFESSIONAL_TRAINER',
    AMATEUR_TRAINER = 'AMATEUR_TRAINER',
  }

  export interface Training<T extends IEntity = IEntity, A extends IEntity = T> extends IEntity {
    timestamp: Date;
    trainer: T;
    athlete: A;
  }

  export interface TrainingInvitation<T extends IEntity = IEntity> extends IEntity {
    status: InvitationStatus;
    invitedAs: InvitationType;
    invitedAt: string;
    invitedAccount: T;
    invitingAccount: T;
  }

  export enum TrainingType {
    ATHLETE = 'ATHLETE',
    TRAINER = 'TRAINER',
  }

  export interface TrainingSessionSimpleChart {
    rooqScore: number;
    disciplines: Array<{
      duration: number;
      discipline: DisciplineV2;
    }>;
  }

  export interface TrainingBaseSession extends IEntity {
    name: string;
    origin: string;
    created: Date;
    duration: number;
    favorite: boolean;
    hasBeenSeen: boolean;
  }

  export interface TrainingSessionSimplified extends TrainingBaseSession {
    hasAbo: boolean;
    hasComments: boolean;
    isUploaded: boolean;
    simpleChart: TrainingSessionSimpleChart;
  }

  export interface TrainingSessionChartCoordinate {
    x: number;
    y: number;
  }


  export interface TrainingSessionChartDatum {
    id: string;
    date: string;
  }

  export interface TrainingSessionTimelineChartData extends TrainingSessionAxisVals {
    isVisible: boolean;
    chartData: TrainingSessionChartCoordinate[];
  }

  export interface TrainingSessionChartComparison {
    isVisible: boolean;
    sessions: TrainingSessionChartDatum[];
    values: number[];
    average: number;
    yAxisValue: number;
    extraFlag: string;
  }

  export interface TrainingSessionCategoriesChartData {
    isVisible: boolean;
    data: {
      label: string;
      count: number;
      percent: number;
    }[];
  }

  export interface TrainingSessionCategoriesChart {
    version: number;
    discipline: TrainingSessionCategoriesChartData;
    punchSpeed: TrainingSessionCategoriesChartData;
    punchCombos: TrainingSessionCategoriesChartData;
    totalPuncher: number;
  }

  export interface TrainingSessionPunchDistributionChartData {
    isVisible: boolean;
    data: {
      label: string;
      leftValue: number;
      leftPercent: number;
      rightValue: number;
      rightPercent: number;
    }[];
  }

  export interface TrainingSessionPunchDistributionChart {
    version: number;
    punchCount: TrainingSessionPunchDistributionChartData;
    punchSpeed: TrainingSessionPunchDistributionChartData;
    averagePunchSpeed: TrainingSessionPunchDistributionChartData;
  }

  export interface TrainingSessionPunchStatsChart {
    version: number;
    totalPunchCount: { value: number; };
    averagePunchSpeed: { value: number; };
  }

  export interface TrainingSessionRecentComparisonChart {
    version: number;
    rooqScore: TrainingSessionChartComparison;
    punchCount: TrainingSessionChartComparison;
  }

  export interface TrainingSessionTimelineChart {
    version: number;
    rooqScore: TrainingSessionTimelineChartData;
    punchCount: TrainingSessionTimelineChartData;
  }

  export interface TrainingSessionTimeByDisciplinesChart {
    version: number;
    totalDuration: number;
    disciplines: {
      discipline: Discipline;
      duration: number;
    }[];
  }

  export interface TrainingSessionChart {
    categories: TrainingSessionCategoriesChart;
    punchDistribution: TrainingSessionPunchDistributionChart;
    punchStats: TrainingSessionPunchStatsChart;
    recentSessionsComparison: TrainingSessionRecentComparisonChart;
    sessionTimeline: TrainingSessionTimelineChart;
    trainingTimeByDisciplines: TrainingSessionTimeByDisciplinesChart;
  }

  export interface TrainingSession extends TrainingBaseSession {
    chartData: TrainingSessionChart;
  }

  interface TrainingSessionVersioned {
    version: number;
  }

  interface TrainingSessionId {
    id: string;
  }

  interface TrainingSessionTimed {
    startTime: number;
    endTime: number;
  }

  interface TrainingSessionYAxisVal {
    yAxisValue: number;
  }

  interface TrainingSessionXAxisVal {
    xAxisValue: number;
  }

  interface TrainingSessionAxisVals extends TrainingSessionXAxisVal, TrainingSessionYAxisVal {
  }

  interface TrainingSessionV2Base extends TrainingSessionVersioned, TrainingSessionId, TrainingSessionTimed {
  }

  // export interface TrainingSessionDataV2 extends TrainingSessionV2Base {
  // }

  export interface TrainingSessionMetaVersionsV2 {
    app: string;
    firmware: string;
    lib: string;
    algorithmsHash: string;
  }

  export interface TrainingSessionMetaBiometricsV2 {
    sportsSex: string;
    age: number;
    height: number;
    weight: number;
    stance: string;
  }

  export interface TrainingSessionMetaTagV2 {
    tagId: string;
    parameters: any[];
  }

  export interface TrainingSessionMetaV2 extends TrainingSessionV2Base {
    hidden: boolean;
    versions: TrainingSessionMetaVersionsV2;
    biometrics: TrainingSessionMetaBiometricsV2;
    tags: TrainingSessionMetaTagV2[];
  }

  export interface TrainingSessionPunchStatV2 {
    leftHand: Record<string, number>;
    rightHand: Record<string, number>;
    total: number;
  }

  export interface TrainingSessionSummaryExerciseBlockV2
    extends TrainingSessionId, TrainingSessionTimed {
    number: number;
    duration: number;
    rooqScore: number;
    type: string;
    stats: Record<string, number | string | null>;
  }

  export interface TrainingSessionSummaryExerciseV2 {
    duration: number;
    rooqScore: number;
    type: string;
    stats: {
      punchCount: number | null;
      avgMaxSpeed: number | null;
      bestMaxSpeed: number | null;
      avgPowerAtMaxSpeed: number | null;
      bestPowerAtMaxSpeed: number | null;
    };
    blocks: TrainingSessionSummaryExerciseBlockV2[];
  }

  export interface TrainingSessionSummaryStatsSummaryV2 {
    avgMaxSpeed: number;
    avgPowerAtMaxSpeed: number;
    bestMaxSpeed: number;
    bestPowerAtMaxSpeed: number;
    duration: number;
    durationPercentile: number;
    punchCount: number;
  }

  export interface TrainingSessionSummaryStatsV2 {
    punchCount: TrainingSessionPunchStatV2;
    punchCountPercentile: TrainingSessionPunchStatV2;
    avgMaxSpeed: TrainingSessionPunchStatV2;
    bestMaxSpeed: TrainingSessionPunchStatV2;
    avgPowerAtMaxSpeed: TrainingSessionPunchStatV2;
    bestPowerAtMaxSpeed: TrainingSessionPunchStatV2;
    summarizedStats: Record<string, TrainingSessionSummaryStatsSummaryV2>;
  }

  export interface TrainingSessionSummaryV2 extends TrainingSessionV2Base {
    duration: number;
    rooqScore: number;
    sessionStats: TrainingSessionSummaryStatsV2;
    exercises: TrainingSessionSummaryExerciseV2[];
  }

  export interface TrainingSessionUiChartV2 {
    label: string;
    count: number;
    percent: number;
  }

  export interface TrainingSessionUiChartDataV2<T> {
    isVisible: boolean;
    charts: T[];
  }

  export interface TrainingSessionUiExerciseDataV2 {
    duration: number;
    stats: {
      punchCount: number;
      avgMaxSpeed: number;
      avgPowerAtMaxSpeed: number;
      rooqScore: number;
    } & TrainingSessionVersioned;
    countsGroupedBy: {
      maxSpeed: TrainingSessionUiChartDataV2<TrainingSessionUiChartV2>;
      comboLength: TrainingSessionUiChartDataV2<TrainingSessionUiChartV2>;
    } & TrainingSessionVersioned;
    distributions: {
      punchCount: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2 & TrainingSessionUiDistributionPctV2>;
      avgMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
      bestMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
      avgPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
      bestPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
    } & TrainingSessionVersioned;
  }

  export interface TrainingSessionUiExerciseRoundsTimelinesV2 extends TrainingSessionVersioned {
    rooqScore: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
    punchCount: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
    bestMaxSpeed: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
    avgMaxSpeed: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
    bestPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
    avgPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal;
  }

  export interface TrainingSessionUiExerciseV2 extends TrainingSessionVersioned {
    type: DisciplineV2;
    duration: number;
    rooqScore: number;
    roundCount: number;
    roundsTimelines: TrainingSessionUiExerciseRoundsTimelinesV2;
    data: TrainingSessionUiExerciseDataV2[];
  }


  export interface TrainingSessionUiDistributionV2 {
    label: string;
    leftValue: number;
    rightValue: number;
  }

  export interface TrainingSessionUiDistributionPctV2 {
    leftPercent: number;
    rightPercent: number;
  }

  export interface TrainingSessionUiDistributionDataV2<T> {
    isVisible: boolean;
    values: T[];
  }

  export interface TrainingSessionUiDistributionsV2 extends TrainingSessionVersioned {
    punchCount: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2 & TrainingSessionUiDistributionPctV2>;
    avgMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
    bestMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
    bestPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
    avgPowerAtMaxSpeed: TrainingSessionUiDistributionDataV2<TrainingSessionUiDistributionV2>;
  }

  export interface TrainingSessionUiCountsGroupedV2 extends TrainingSessionVersioned {
    exerciseType: {
      charts: { label: string; percent: number; count: number; }[];
    };
    maxSpeed: {
      charts: { label: string; percent: number; count: number; }[];
    };
    comboLength: {
      charts: { label: string; percent: number; count: number; }[];
    };
  }

  export interface TrainingSessionUiDurationsByExerciseV2 extends TrainingSessionVersioned {
    totalDuration: number;
    values: Array<{
      label: string;
      duration: number;
    }>;
  }

  export interface TrainingSessionUiComparisonsV2 extends TrainingSessionVersioned {
    rooqScore: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal & {
      average: number;
      extraFlag: string;
      sessions: Array<{ id: string; date: string; }>;
    };
    punchCount: TrainingSessionUiDistributionDataV2<number> & TrainingSessionYAxisVal & {
      average: number;
      extraFlag: string;
      sessions: Array<{ id: string; date: string; }>;
    };
  }

  export interface TrainingSessionUiTimelinesV2 extends TrainingSessionVersioned {
    rooqScore: TrainingSessionUiDistributionDataV2<{ x: number; y: number; }> & TrainingSessionAxisVals;
    punchCount: TrainingSessionUiDistributionDataV2<{ x: number; y: number; }> & TrainingSessionAxisVals;
  }

  export interface TrainingSessionUiV2 extends TrainingSessionVersioned {
    stats: {
      punchCount: number;
      avgMaxSpeed: number;
      avgPowerAtMaxSpeed: number;
      rooqScore: number;
    } & TrainingSessionVersioned;
    sessionTimelines: TrainingSessionUiTimelinesV2;
    recentSessionComparisons: TrainingSessionUiComparisonsV2;
    durationByExerciseType: TrainingSessionUiDurationsByExerciseV2;
    countsGroupedBy: TrainingSessionUiCountsGroupedV2;
    distributions: TrainingSessionUiDistributionsV2;
    exercises: TrainingSessionUiExerciseV2[];
  }

  export interface TrainingSessionV2 {
    meta: TrainingSessionMetaV2;
    summary: TrainingSessionSummaryV2;
    ui: TrainingSessionUiV2;
    exampleSession?: boolean;
  }

  export interface TrainingPlanUnit extends IEntity {
    discipline: Discipline | string;
    customDiscipline?: string;
    description: string;
    numberOfRounds: number;
    roundLengthSeconds: number;
    pauseLengthSeconds: number;
    coolDownSeconds: number;
    index?: number;
  }

  export interface TrainingPlan extends IEntity {
    name: string;
    description: string;
    trainingPlanUnits: TrainingPlanUnit[];
    exampleSession?: boolean;
  }

  export interface TrainerProfile {
    trainerType: TrainerType;
    fightStyles: FightStyle[];
    customFightStyles: string[];
    titles: ChampionshipTitle[];
    skippedProfile: boolean;
  }

  export interface AthleteProfile {
    weight: number;
    height: number;
    stance: Stance;
    fitness: SkillLevel;
    sparring: SkillLevel;
    tournament: SkillLevel;
    fightingClass: FightingClass;
    customFightStyles: FightStyle[];
  }

  export interface User extends IEntity {
    aboutMe: String;
    birthDay: Date;
    countryIsoCode: string;
    customClaims: Claim[];
    completedTours: UserTour[],
    email: string;
    firstName: string;
    gender: Gender;
    hometown: string;
    lastName: string;
    profilePictureId: string;
    publicAccount: boolean;
    unit: UnitSystem;
    trainers: Training<IAuthUserAccount>[];
    trainingPlans: Training<IAuthUserAccount>[];
    ownsSubscription: boolean;
    trialAvailable: boolean;
    trainerCount: number,
    athleteCount: number,
    trainerInvCount: number,
  }
}

export const hasCompletedTour = (tours: Rooq.UserTour[], tour: Rooq.UserTour): boolean => {
  return tours.findIndex(t => t === tour) >= 0;
}

export const disciplinesExcludedFromTrainingPlans = new Set(['PAUSE', 'UNKNOWN_ACTIVITY']);
export const disciplinesExcludedFromAnalysis = new Set(['PAUSE', 'UNKNOWN_ACTIVITY']);


export const disciplineColors = {
  [Rooq.Discipline.EQUIPMENT]: style.rooq_chart_4,
  [Rooq.Discipline.PARTNER]: style.rooq_chart_3,
  [Rooq.Discipline.SHADOW]: style.rooq_chart_1,
  [Rooq.Discipline.PAUSE]: '#000',
  [Rooq.Discipline.UNKNOWN_ACTIVITY]: '#000',
  [Rooq.Discipline.CUSTOM as any]: '#cacaca',
  [Rooq.Discipline.ROPE_SKIPPING]: style.rooq_chart_2,
};
export const disciplineColorsV2 = {
  [Rooq.DisciplineV2.EQUIPMENT]: style.rooq_chart_4,
  [Rooq.DisciplineV2.PARTNER]: style.rooq_chart_3,
  [Rooq.DisciplineV2.SHADOW]: style.rooq_chart_1,
  [Rooq.DisciplineV2.UNKNOWN_ACTIVITY]: '#cacaca',
  [Rooq.Discipline.CUSTOM as any]: '#cacaca',
  [Rooq.DisciplineV2.ROPE_SKIPPING]: style.rooq_chart_2,
};

export const chartColorWheel = (i: number): string => {
  const vals = keys(disciplineColorsV2).map(k => prop(disciplineColorsV2, k));
  return vals[i % vals.length];
};


export const trainingSessionColors = [
  style.rooq_session_1,
  style.rooq_session_2,
  style.rooq_session_3,
];

export const trainingSessionColor = (style: RooqStyle, index: number): string => {
  const k = `rooq_session_${index + 1}`;
  return prop(style, k);
}

export const useTrainingSessionColor = (index: number) => {
  const style = useRooqStyle();

  return useMemo(() => trainingSessionColor(style, index), [style, index]);
};

export const disciplineDefaultColor = '#0C1A27';

export const disciplineColor = (
  d: Rooq.Discipline | Rooq.DisciplineV2 | string | undefined | null
): string => d ? (
  prop(disciplineColors, d as any)
  || prop(disciplineColorsV2, d as any)
  || disciplineDefaultColor
) : disciplineDefaultColor;


const disciplineSortPriority = [
  Rooq.Discipline.ROPE_SKIPPING,
  Rooq.Discipline.SHADOW,
  Rooq.Discipline.PARTNER,
  Rooq.Discipline.EQUIPMENT,
  Rooq.Discipline.PAUSE,
  Rooq.Discipline.CUSTOM,
  Rooq.Discipline.UNKNOWN_ACTIVITY,
];

export const byDisciplinePriority = (a?: Rooq.Discipline | string | null, b?: Rooq.Discipline | string | null): -1 | 0 | 1 => {
  if (!a) return -1;
  if (!b) return 1;
  if (a === b) return 0;
  const a_idx = disciplineSortPriority.findIndex(d => d === a);
  const b_idx = disciplineSortPriority.findIndex(d => d === b);
  return a_idx > b_idx ? 1 : -1;
};

export const objByDisciplinePriority = (a: { discipline?: Rooq.Discipline | string | null }, b: { discipline?: Rooq.Discipline | string | null }): -1 | 0 | 1 => {
  return byDisciplinePriority(a.discipline, b.discipline);
};

export const sortByDisciplinePriority = (items: { discipline?: Rooq.Discipline | string | null }[]) => items.sort(objByDisciplinePriority);

const VELOCITY_IMPERIAL_FACTOR = 0.62137119;
const MASS_IMPERIAL_FACTOR = 0.45359237;
// const LENGTH_IMPERIAL_FACTOR = 3.2808399;

const floatRound = (val: number, precision: number = 2): number => {
  if (isNaN(val)) return 0;
  const s = Math.fround(val).toFixed(precision);
  return parseFloat(s);
}

export const convertVelocityFromMetric = (value: number, target: Rooq.UnitSystem): number => {
  switch (target) {
    case Rooq.UnitSystem.IMPERIAL:
      return floatRound(value * VELOCITY_IMPERIAL_FACTOR);
    default:
      return value;
  }
};

export const convertMassFromMetric = (value: number, target: Rooq.UnitSystem): number => {
  switch (target) {
    case Rooq.UnitSystem.IMPERIAL:
      return floatRound(value * MASS_IMPERIAL_FACTOR);
    default:
      return value;
  }
};


export const metricVelocityConverter = (target: Rooq.UnitSystem) =>
  (value?: null | number): number => {
    if (target === Rooq.UnitSystem.METRIC) return value || 0;
    if (isNil(value) || !isNum(value)) return 0;
    return convertVelocityFromMetric(value, target);
  };

export const metricVelocityDataConverter = <T extends any = any>(target: Rooq.UnitSystem) =>
  (datum: T, ...keys: Array<keyof T>) => {
    if (target === Rooq.UnitSystem.METRIC) return datum;

    return keys.reduce(
      (d, k) => {
        const v = prop(d, k);
        if (isNil(v) || !isNum(v)) return d;
        return assoc(d, k, convertVelocityFromMetric(v as number, target));
      },
      datum
    );
  };

export const useVelocity = (value?: number): number | null => {
  const unitSys = useAuthUserUnitSystem();
  return useMemo(
    () => isNil(value) || isNaN(value as number)
      ? null
      : convertVelocityFromMetric(value as number, unitSys),
    [value, unitSys]
  );
};

export const useMass = (value?: number): number | null => {
  const unitSys = useAuthUserUnitSystem();
  return useMemo(
    () => isNil(value) || isNaN(value as number)
      ? null
      : convertMassFromMetric(value as number, unitSys),
    [value, unitSys]
  );
};


export const findMaxDerivativeIndices = <T extends any = any>(
  data: T[],
  getX: (d: T) => number,
  getY: (d: T) => number,
): null | [number, number] => {

  if (data.length < 2) {
    return null;
  } else if (data.length === 2) {
    return [0, 1];
  } else {
    let d_max = 0;
    let i_max = 0;

    for (let i = 0; i < data.length - 1; i++) {
      const next = data[i + 1];
      const cur = data[i];
      const dx = getX(next) - getX(cur);
      const dy = getY(next) - getY(cur);
      const d = dx && dy && !isNaN(dx) && !isNaN(dy)
        ? dy / dx
        : 0;

      if (d > d_max) {
        d_max = d;
        i_max = i;
      }
    }

    return [i_max, i_max + 1];
  }
}

export const shouldShowTour = (acc: IAuthUserAccount | undefined | null, tour: Rooq.UserTour): boolean => {
  return isNotNil(acc) && !hasCompletedTour(acc?.completedTours || [], tour);
}

export const useShouldShowTour = (tour: Rooq.UserTour): boolean => {
  const acc = useAuthAccount();

  return useMemo(
    () => shouldShowTour(acc, tour),
    [acc, tour]
  );
}
