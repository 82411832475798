import React, {useEffect, useRef, useState} from 'react';
import {Rooq} from '../../../core/domain.model';
import {IUiFieldControl, UiFlexCol, UiState, UiTextBlock, UiTextTitle, useTranslator} from '@bitsolve/react-common';
import {TrainingPlanUnit} from './training-plan-unit.component';

export interface ITrainingPlanUnitsControl extends IUiFieldControl<'training-plan-units', Rooq.TrainingPlanUnit[]> {
}

export const TrainingPlanUnitsControl: React.FC<ITrainingPlanUnitsControl> = (props) => {
  const {value, onChange, className, style, controlProps, state} = props;
  const [dragging, setDragging] = useState<boolean>(false);

  const t = useTranslator();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    if (dragging) {
      ref.current.classList.add('dragging');
    } else {
      ref.current.classList.remove('dragging');
    }
  }, [ref, dragging]);

  const isEmpty = (!value || value.length === 0);

  return <div className={className}
              style={isEmpty ? {
                ...style,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              } : {
                ...style,
                minHeight: 0,
                overflow: 'auto',
                flex: 1,
              }}
              ref={ref}>
    {isEmpty && <UiFlexCol className={'app-training-plan__unit__empty'}>
      <UiTextTitle text={t('app.training-plan.unit.placeholder.title')} />
      <UiTextBlock text={t('app.training-plan.unit.placeholder.description')} />
    </UiFlexCol>}
    {value && value.map((unit, index) =>
      <TrainingPlanUnit key={`${index}.${unit.id || unit.discipline}`}
                        index={index}
                        unit={unit}
                        state={state || UiState.active}
                        isFinal={index === (value.length - 1)}
                        onClick={controlProps?.onUnitClick
                          ? unit => controlProps?.onUnitClick(unit, index)
                          : undefined}
                        onDragStart={() => {
                          if (!ref.current) return;
                          setDragging(true);
                        }}
                        onDragEnd={() => {
                          setTimeout(() => {
                            setDragging(false);
                          }, 32)
                        }}
                        onRequestReorder={(prev, next) => {
                          const nextValue = [...value];
                          nextValue[prev] = value[next];
                          nextValue[next] = value[prev];
                          onChange(nextValue);
                        }}
                        onDelete={(u) => {
                          let nextValue = [...value];
                          nextValue.splice(index, 1);
                          onChange(nextValue);
                        }} />)}
  </div>;
};
