import {API_PREFIX} from '../../core/constants';
import {
  IHttpRequest,
  IHttpRequestHookOptions,
  IPaginatedHttpRequestHookOptions,
  Page,
  useApiEndpoint,
  usePaginatedHttpRequest
} from '@bitsolve/react-common';
import {ErrorHandler, paginatedOptions, SuccessHandler} from '../../core/api';
import {IFirmwareEntry} from './firmware.model';

const baseUrl = `${API_PREFIX}/v1/internal/sensor/firmware`;

export const findFirmwareRequest = (page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}`,
  params: {
    page: page || 0,
    pageSize: pageSize || 25
  }
});

export const uploadFirmwareRequest = (name: string, file: File): IHttpRequest => {
  const data = new FormData();
  data.append('file', file, file.name || 'FIRMWARE');

  return {
    method: 'post',
    url: `${baseUrl}/upload`,
    data,
    params: {name}
  };
};

export const updateFirmwareRequest = (firmwareId: string, activatePublic: boolean, activateInternal: boolean): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}`,
  data: {
    firmwareId,
    activatePublic,
    activateInternal
  }
});


export const useFindFirmware = (page?: number, pageSize?: number, opts?: IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<IFirmwareEntry>>(
    findFirmwareRequest(page, pageSize),
    paginatedOptions(page, pageSize, opts)
  );

export const useUploadFirmware = (onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<[string, File], IFirmwareEntry>(
    ([name, file]) => uploadFirmwareRequest(name, file),
    onSuccess,
    onError
  );

export const useUpdateFirmware = (onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<[string, boolean, boolean], IFirmwareEntry>(
    ([id, activatePublic, activateInternal]) => updateFirmwareRequest(id, activatePublic, activateInternal),
    onSuccess,
    onError,
  );
