import {API_PREFIX} from '../../core/constants';
import {
  IHttpRequest,
  IHttpRequestHookOptions,
  IPaginatedHttpRequestHookOptions,
  Page,
  useApiEndpoint,
  useHttpRequest,
  usePaginatedHttpRequest
} from '@bitsolve/react-common';
import {IAnalysisSessionComment, IAnalysisSessionCommentCreate, IAnalysisSessionV2} from './analysis.model';
import {ErrorHandler, paginatedOptions, SuccessHandler} from '../../core/api';

const baseUrl = `${API_PREFIX}/v1/session`;

export const findSessionByIdRequest = (sessionId: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${sessionId}`
});

export const findSampleSessionByIdRequest = (sessionId: string, index: number): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/trainer/${sessionId}`
});

export const findSessionsRequest = (page: number, pageSize: number, filters?: any): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/trainer/simplified`,
  params: {page, pageSize, ...filters}
});

export const findSessionCommentRequest = (sessionId: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/trainer/${sessionId}/comment`,
});

export const createSessionCommentRequest = (sessionId: string, data: IAnalysisSessionCommentCreate): IHttpRequest => ({
  method: 'post',
  url: `${baseUrl}/trainer/${sessionId}/comment`,
  data
});
export const updateSessionCommentRequest = (sessionId: string, data: IAnalysisSessionCommentCreate): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}/trainer/${sessionId}/comment`,
  data
});

export const useFindSessionById = (sessionId: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<IAnalysisSessionV2>(
    findSessionByIdRequest(sessionId),
    {initialFetch: true, ...opts}
  );

export const useFindSessionByIdEndpoint = <R = IAnalysisSessionV2>(onSuccess?: SuccessHandler<R>, onError?: ErrorHandler) =>
  useApiEndpoint<string, R>(
    findSessionByIdRequest,
    onSuccess,
    onError
  );

export const useFindSessions = <R = Page<IAnalysisSessionV2>>(
  page: number = 0,
  pageSize: number = 10,
  opts?: IPaginatedHttpRequestHookOptions & IHttpRequestHookOptions,
  filters?: any,
) =>
  usePaginatedHttpRequest<R>(
    findSessionsRequest(page, pageSize, filters),
    paginatedOptions(page, pageSize, opts)
  );

export const useFindSessionComment = (sessionId: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<IAnalysisSessionComment>(
    findSessionCommentRequest(sessionId),
    {initialFetch: true, ...opts},
  );


export const useCreateSessionComment = (sessionId: string) => useApiEndpoint(
  payload => createSessionCommentRequest(sessionId, payload),
);

export const useUpdateSessionComment = (sessionId: string) => useApiEndpoint(
  payload => updateSessionCommentRequest(sessionId, payload),
);
