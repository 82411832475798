import {UiPanel, useUniqueId} from '@bitsolve/react-common';
import React from 'react';
import {IAnalysisDetailsPage} from './analysis-details.model';
import {AnalysisDetailsSegment} from './analysis-details-segment.component';


export const AnalysisDetailsPage: React.FC<IAnalysisDetailsPage & { open?: boolean; }> = (props) => {
  const {segments} = props;
  const id = useUniqueId(props?.id);

  return <UiPanel className={'app-analysis__details-page'}
                  style={{backgroundColor: 'rgba(22, 42, 58, 1)'}}>
    {segments.map((segment, i) => {
      return <AnalysisDetailsSegment key={`${id}.${i}`}
                                     {...segment} />;
    })}
    {/*<div className={'pd-b-lg'} />*/}
  </UiPanel>;
};
