import React from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {toast, UiPageContainer, useTranslator} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {TrainingPlanForm} from '../../../../module/training-plan/component/training-plan-form.component';
import {useCreateTrainingPlan} from '../../../../module/training-plan/training-plan.api';

export interface ICoachingZoneTrainingPlanCreateRoute extends IRoute {
}

export const CoachingZoneTrainingPlanCreateRoute: React.FC<ICoachingZoneTrainingPlanCreateRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const createTrainingPlan = useCreateTrainingPlan(
    (r) => {
      toast.success(t('app.training-plan.action.create.toast.success'));
      nav('/portal/training-plan');
    },
    (e) => {
      toast.error(t('app.training-plan.action.create.toast.error'));
    },
  );

  return <>
    <UiPageContainer fluid className={'app-coachz app-coachz__home'}
                     style={{position: 'relative', maxHeight: '100vh', minHeight: 0, overflow: 'hidden'}}>
      <AppPageHeader
        title={t('app.training-plan.action.create.label')}
        backAction={{
          path: '/portal/training-plan',
          exact: true
        }} />
      <TrainingPlanForm interact={data => createTrainingPlan.send(data)} />
    </UiPageContainer>
  </>;
};
