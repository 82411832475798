import React, {useCallback, useMemo, useState} from 'react';
import {
  classNames,
  UiBox,
  UiButtonGroup,
  UiButtonVariation,
  UiDir,
  UiFlexRow,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AnalysisDetailsPageSegmentChart} from '../analysis-details-segment-chart.component';
import {IRoute} from '../../../../core/routing';
import {hasProp, isFn, isNil, propIn, StringEncoding} from '@bitsolve/fns';
import {IAnalysisDetailsPageSegmentChartOrFn} from '../analysis-details.model';
import {Switch} from 'react-router-dom';
import {AnalysisRedirect, IAnalysisRedirect} from '../analysis-redirect.component';

export type IMultiChartMenuHideFn = (route: IRoute, item: IMultiChartItem, index: number) => boolean;

export interface IMultiChartItem {
  chart?: IAnalysisDetailsPageSegmentChartOrFn | null;
  menu: {
    label: string;
    param: StringEncoding,
    interact: (route: IRoute, index: number) => any;
    hide?: boolean | IMultiChartMenuHideFn;
  };
}

type IMultiChartRoutedFn = (route: IRoute, item: IMultiChartItem & { router: IMultiRoutedCharts['router']; }, index: number) => boolean;

export interface IMultiCharts {
  items: IMultiChartItem[];
  header?: {
    title?: string;
  };
  routed?: IMultiChartRoutedFn;
  route?: IRoute;
}

export interface IMultiRoutedCharts extends IMultiCharts {
  router: {
    param: StringEncoding;
    pattern: string;
    redirect?: IAnalysisRedirect;
  };
}

const MultiChartItem: React.FC<{
  item: IMultiChartItem;
  route?: IRoute;
}> = (props) => {
  const {item, route} = props;
  const {chart} = item;

  if (!chart || isNil(chart)) {
    return null;
  }

  return <AnalysisDetailsPageSegmentChart route={route}
                                          chart={chart} />
};

const dummyRoutedFn = (route: any, _: any, i: number) => i === 0;
const MultiChartsImpl: React.FC<IMultiCharts & { router?: IMultiRoutedCharts['router']; }> = (props) => {
  const {items, route, routed = dummyRoutedFn, router} = props;
  const t = useTranslator();
  const _title = propIn(props, ['header', 'title']);
  const title = useMemo(
    () => _title ? t(_title) : null,
    [_title, t]
  );
  const isRouted = (item: any, index: number): boolean =>
    !!(isFn(routed) && route && routed(route, {...item, router} as any, index));
  const redir = router?.redirect;

  const buttons = useMemo(
    () => {
      return items.filter((item, index) => hasProp(item.menu, 'hide')
        ? isFn(item.menu.hide)
          ? !item.menu.hide(route as any, item, index)
          : !item.menu.hide
        : true);
    },
    [items, route]
  );

  return <UiBox className={'app-analysis__panel app-analysis__chart app-analysis__chart--multi f-1'}>
    <UiFlexRow tag={'header'}
               className={classNames(
                 'app-analysis__panel__header',
                 title && 'app-analysis__panel__header--titled'
               )}>
      {title && <UiTextTitle text={title}
                             className={'app-analysis__panel__header__title'} />}
      <UiButtonGroup
        variation={UiButtonVariation.semantic}
        direction={UiDir.h}
        actions={buttons
          .map((item, index) => ({
            label: item.menu.label,
            className: isRouted(item, index)
              ? 'active'
              : undefined,
            interact: () => item.menu.interact(route as any, index)
          }))} />
    </UiFlexRow>
    <UiBox>
      <Switch>
        {items.map((item, i) => isRouted(item, i) && <MultiChartItem key={i} item={item} route={route} />)}
        {redir && <AnalysisRedirect {...redir} />}
      </Switch>
    </UiBox>
  </UiBox>;
};

const defaultRoutedFn = ((route: IRoute, item: { router: { param: string; }, menu: { param: string; } }) => {
  const routeParam = propIn(route, ['match', 'params', `${item.router.param}`]);
  return routeParam === item.menu.param;
}) as any;


export const MultiRoutedCharts: React.FC<IMultiRoutedCharts> = (props) => {
  return <UiBox className={'app-analysis__chart app-analysis__chart--multi-routed f-1'}>
    <MultiChartsImpl routed={defaultRoutedFn}
                     {...props} />
  </UiBox>;
}

export const MultiCharts: React.FC<IMultiCharts> = (props) => {
  const {items, ...rest} = props;
  const [active, setActive] = useState(items[0]?.menu?.param);
  const routed: IMultiChartRoutedFn = useCallback(
    ((_, item) => item.menu.param === active),
    [active]
  );

  const _items = useMemo(() => {
    return items.map((item) => ({
      ...item,
      menu: {...item.menu, interact: () => setActive(item.menu.param)}
    }));
  }, [items, setActive]);

  return <MultiChartsImpl routed={routed}
                          items={_items}
                          {...rest} />;
};
