import {
  createAxiosClient,
  IApiEndpointContext,
  IHttpPaginationState,
  IHttpPaginator,
  IHttpRequestHookOptions,
  IModalContext,
  IPaginatedHttpRequestHookOptions,
  IUiForm,
  toast,
  useConfirmationModal,
  useTranslator
} from '@bitsolve/react-common';
import {useCallback} from 'react';
import {isFn, isNil, isNotEmpty, isNotNil, isNotTrue, isStr, prop, props} from '@bitsolve/fns';
import {AxiosError, AxiosResponse} from 'axios';
import {ValidationError} from 'yup';
import {PACKAGE_NAME, PACKAGE_VERSION} from './constants';
import {TranslateFn} from './util';

export type SuccessHandler<R = any> = (res: AxiosResponse<R>) => any;
export type ErrorHandler = (err: AxiosError) => any;
export type ProgressHandler = (ev: ProgressEvent) => any;


export const paginator: IHttpPaginator = {
  responseToState: <R = any>(response: R, prevState?: any): IHttpPaginationState => {
    const state = props(response as any, ['size', 'totalPages', 'totalElements']) as IHttpPaginationState;
    const page = prop<R, number>(response, 'number');
    const lastPageIndex = state.totalPages - 1;

    return {...prevState, ...state, lastPageIndex, page};
  },
  stateToParams: <R = Record<string, string>>(state: IHttpPaginationState): R => {
    return {
      page: state.page.toString(10),
      pageSize: state.size.toString(10)
    } as unknown as R;
  },
  paramsToState: ((params: { page: string; pageSize: string; }): Partial<IHttpPaginationState> => {
    const {page, pageSize} = params as any;

    return {
      page: page ? (isStr(page) ? parseInt(page, 10) : page) : 0,
      size: pageSize ? (isStr(pageSize) ? parseInt(pageSize, 10) : pageSize) : 0,
    };
  }) as any
};

export const paginatedOptions = (page?: number, pageSize?: number, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions): IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions => ({
  paginator,
  initialPagination: {firstPageIndex: 0, page: page || 0, size: pageSize || 10},
  ...opts
});

export interface IDeleteConfirmationHookApi<T = any, C = string> {
  endpoint: IApiEndpointContext<T>;
  modal: IModalContext & { element: any };
  callback: (arg: C) => any;
}

type ConfirmMessageFn = (t: TranslateFn) => string;

export const useDeleteConfirmation = <T = any, C = string>(options: {
  endpoint: IApiEndpointContext<T>;
  onSuccess?: SuccessHandler<T>;
  onError?: ErrorHandler;
  title?: string | ConfirmMessageFn;
  message?: string | ConfirmMessageFn;
  cancelLabel?: string | ConfirmMessageFn;
  confirmLabel?: string | ConfirmMessageFn;
  successToast?: string;
  errorToast?: string;
}): IDeleteConfirmationHookApi<T, C> => {
  const {endpoint, title, message, cancelLabel, confirmLabel, successToast, errorToast, onSuccess, onError} = options;

  const t = useTranslator();

  const modal = useConfirmationModal({
    title: isFn(title) ? title(t) : isNotNil(title) ? t(title as any) : undefined,
    message: isFn(message) ? message(t) : isNotNil(message) ? t(message as any) : undefined,
    cancelLabel: isFn(cancelLabel) ? cancelLabel(t) : t(cancelLabel || 'app.general.cancel' as any),
    confirmLabel: isFn(confirmLabel) ? confirmLabel(t) : t(confirmLabel || 'app.general.confirm' as any),
    interact: (type, context) => {
      if (type === 'cancel' || isNil(endpoint)) {
        context.close();
        return;
      }

      return endpoint
        .send(context.data.id)
        .then((r) => {
          if (prop(r, 'isAxiosError')) {
            throw r;
          } else {
            successToast && toast.success(t(successToast));
            onSuccess && onSuccess(r);
            return r;
          }
        })
        .catch((e) => {
          errorToast && toast.error(t(errorToast));
          onError && onError(e);
          return e;
        })
        .finally(context.close);
    }
  });

  const callback = useCallback((id: string) => {
    modal.setData({id});
    modal.open();
  }, [modal]) as any;

  return {endpoint, modal, callback};
};


interface ApiValidationError {
  codes: string[];
  arguments: any[];
  defaultMessage: string;
  objectName: string;
  field: string;
  rejectedValue: any;
  bindingFailure: boolean;
  code: string;
}

interface ErrorTransformOptions {
  translate?: (key: string, params?: any) => string,
  messagePattern?: string;
}

const apiValidationError = (requestData?: any, opts?: ErrorTransformOptions) =>
  (error: ApiValidationError): ValidationError => {
    const code = error.code;
    const field = error.field;
    const value = error.rejectedValue;

    const defaultMessage = (error.defaultMessage || '')
      .split(/\./)
      .map(s => `${s.substr(0, 1).toLowerCase()}${s.substr(1)}`)
      .join('.');

    let message = opts?.messagePattern
      ? opts.messagePattern
        .replace('{message}', defaultMessage)
        .replace('{code}', code)
        .replace('{field}', field)
      : defaultMessage;

    if (opts?.translate) {
      let params = null;
      try {
        switch (code) {
          case 'Size': {
            // eslint-disable-next-line
            const [_, max, min] = error.arguments;
            params = {max, min};
            break;
          }
          case 'NameConstraint': {
            params = {value};
            break;
          }
          default:
            break;
        }
      } catch (e) {
      }

      message = opts.translate(message, params);
      // console.log('translated error', code, 'for field', field, '->', message, params);
    } else {
      // console.log('no translator for error');
    }

    let err = new ValidationError(message || code, value, field, 'mixed');
    err.params = {originalValue: prop(requestData, field), value, path: field};

    return err;
  }

export const apiErrorToValidationError = (error: AxiosError, requestData?: any, opts?: ErrorTransformOptions): null | ValidationError => {
  if (isNil(error) || isNotTrue(prop(error, 'isAxiosError'))) {
    return null;
  }

  try {
    const value = error?.config?.data
      ? JSON.parse(error.config.data)
      : requestData;

    const responseData = error.response?.data;

    if (isNil(responseData)) {
      return new ValidationError('Unknown API error', undefined, undefined, 'invalid');
    }

    const errors = responseData?.errors?.map(apiValidationError(value, opts));

    return new ValidationError(errors, value, undefined, 'invalid');
  } catch (e) {
    console.error(e);
    return new ValidationError('Unknown API error', undefined, undefined, 'invalid');
  }
}

export const transformValidationError = (error: ValidationError, value?: any, opts?: ErrorTransformOptions): ValidationError => {
  const inner = error?.inner;

  if (inner && isNotEmpty(inner)) {
    const errors = inner.map(error => {
      const code = error.type;
      const field = error.path;
      const value = error.value;

      let message = opts?.messagePattern
        ? opts.messagePattern
          .replace('{message}', error.message)
          .replace('{code}', code || '?')
          .replace('{field}', field || '?')
        : error.message;

      if (opts?.translate) {
        message = opts.translate(message, error.params);
      }

      return new ValidationError(message, value, field, error.type || 'invalid');
    });

    return new ValidationError(errors);
  } else {
    if (opts?.messagePattern) {
      const code = error.type;
      const field = error.path;

      let message = opts?.messagePattern
        ? opts.messagePattern
          .replace('{message}', error.message)
          .replace('{code}', code || '?')
          .replace('{field}', field || '?')
        : error.message;

      if (opts?.translate) {
        message = opts.translate(message, error.params);
      }

      error.message = message;
    }

    return error;
  }

}


export const useSchemaOptions = (messagePattern?: string, abortEarly?: boolean): IUiForm['schemaOptions'] => {
  const translate = useTranslator();
  const transformOpts = {messagePattern, translate};

  return {
    abortEarly: Boolean(abortEarly),
    transformSubmitError: (err: any, data: any) => apiErrorToValidationError(err, data, transformOpts),
    transformValidationError: (err: any, data: any) => transformValidationError(err, data, transformOpts),
  };
};
export const http = createAxiosClient({
  headers: {
    'x-client-name': PACKAGE_NAME,
    'x-client-version': PACKAGE_VERSION,
  }
});
