import React from 'react';
import {IAnalysisChartType, IAnalysisSegmentType} from './analysis-details.model';
import {classNames} from '@bitsolve/react-common';
import {AnalysisPanel} from './analysis-panel.component';
import {AnalysisDetailsPageSegmentChart} from './analysis-details-segment-chart.component';
import {isFn} from '@bitsolve/fns';
import {animated} from 'react-spring';


const nonPanelTypes = new Set<IAnalysisChartType['type']>([
  'drilldown',
  'heading',
  'multi',
  'multi-routed',
]);

export const AnalysisDetailsSegment: React.FC<IAnalysisSegmentType & { animation?: any; }> = (props) => {
  const {layout, charts, route, animation} = props;

  return <animated.div className={'d-f fd-h app-analysis__details-segment'}
                       {...animation}>
    {charts.map((chart, index) => !isFn(chart) && nonPanelTypes.has(chart.type)
      ? <AnalysisDetailsPageSegmentChart key={index}
                                         route={route}
                                         chart={chart} />
      : <AnalysisPanel key={index}
                       className={classNames(
                         'f-1',
                         layout === 'dual' && (
                           index % 2 === 0
                             ? 'mg-r-sm'
                             : 'mg-l-sm'
                         )
                       )}
                       header={chart.header}
                       route={route}>
        <AnalysisDetailsPageSegmentChart
          route={route}
          chart={chart}
        />
      </AnalysisPanel>)}
  </animated.div>;
};
