import {schema} from '@bitsolve/react-common';
import {IFormFieldIndex} from '../../core/util';
import {IClub} from './club.model';

export const clubFields: IFormFieldIndex<IClub> = {
  name: {
    name: 'name',
    fieldType: 'text',
    label: 'app.club.form.name.label',
  },
  foundingYear: {
    name: 'foundingYear',
    fieldType: 'number',
    label: 'app.club.form.foundingYear.label',
    controlProps: {step: 1}
  },
  aboutUs: {
    name: 'aboutUs',
    fieldType: 'textarea',
    label: 'app.club.form.aboutUs.label',
  },
  fightStyles: {
    name: 'fightStyles',
    fieldType: 'fight-style-select',
  },
  memberCount: {
    name: 'memberCount',
    fieldType: 'number',
    label: 'app.club.form.memberCount.label',
    controlProps: {step: 1},
  },
  address: {
    name: 'address',
    fieldType: 'address',
  },
  phoneNumber: {
    name: 'phoneNumber',
    fieldType: 'text',
    label: 'app.club.form.phoneNumber.label',
  },
  email: {
    name: 'email',
    fieldType: 'text',
    label: 'app.club.form.email.label',
  },
  openingHours: {
    name: 'openingHours',
    fieldType: 'opening-hours',
    controlProps: {},
  },
}

export const clubSchema = {
  name: schema.string()
    .min(3, 'error.messages.name.size')
    .required('error.messages.name.required'),
  fightStyles: schema.array()
    .min(1, 'error.messages.fightStyles.size')
    .required('error.messages.fightStyles.required'),
  email: schema.string()
    .required('error.messages.email.required'),
  address: schema.object({
    street: schema.string()
      .min(3, 'error.messages.address.street.size')
      .required('error.messages.address.street.required'),
    houseNumber: schema.string()
      .required('error.messages.address.houseNumber.required'),
    city: schema.string()
      .min(2, 'error.messages.address.city.size')
      .required('error.messages.address.city.required'),
    zipCode: schema.string()
      .min(4, 'error.messages.address.zipCode.size')
      .required('error.messages.address.zipCode.required'),
    country: schema.string()
      .required('error.messages.address.country.required'),
  })
};
