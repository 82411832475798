import React, {useMemo} from 'react';
import {useNavigator} from '../../../../core/routing';
import {isNotNil, prop} from '@bitsolve/fns';
import {
    classNames,
    IUiButton,
    toast,
    UiBox,
    UiButtonGroup,
    UiButtonVariation,
    UiColor,
    UiCustomizable,
    UiFlexAlign,
    UiFlexRow,
    UiTextBlock,
    UiTextTitle,
    useTranslator
} from '@bitsolve/react-common';
import {AthleteStatCard} from './athlete-stat-card.component';
import {UiSplashMessage} from '../../../ui/component/ui-splash-message.component';
import {
    ShopBuySeatsCard,
    ShopFreeSeatExplainerCard,
    ShopFreeSeatsCard,
    ShopTrialSeatCard
} from '../../../shop/component/shop-free-seats-card.component';
import {useMySeats, useShopRedirect, useUnassignSeat} from '../../../shop/shop.api';
import {useAuthAccount} from '../../auth.store';
import {useConfirmation} from '../../../ui/component/ui-confirmation-modal-dialog.component';
import {ISeat} from '../../../shop/shop.model';
import {ShopLockedSeatsCard} from '../../../shop/component/shop-locked-seats-card.component';
import {ArrowSide, UiTourTooltip} from '../../../ui/component/ui-tour-tooltip.component';
import {useStartTrialPrompt} from '../../../shop/shop.util';

export interface IAthleteCardGrid extends UiCustomizable {
    title?: string;
    actions?: Array<IUiButton | undefined | null | false>;
    perRow?: number;
}

const ageYears = (bday: string) => {
    try {
        const d = new Date(bday);
        const n = new Date();
        return n.getFullYear() - d.getFullYear();
    } catch (_) {
        return;
    }
};

export const AthleteCardGrid: React.FC<IAthleteCardGrid> = (props) => {
    const {perRow = 3, title, actions, style, className} = props;

    const acc = useAuthAccount();
    const seats = useMySeats();
    const {all, free, taken, locked, reload} = seats;

    const t = useTranslator();
    const nav = useNavigator();

    const stats = useMemo(() => all?.map((seat) => {

        return seat?.athlete?.birthDay
            ? [
                {
                    value: ageYears(seat.athlete.birthDay as any),
                    label: t('app.account.profile.age.label')
                },
                seat?.athlete?.athleteProfile?.weight
                    ? {
                        value: seat?.athlete?.athleteProfile?.weight,
                        label: t('app.account.profile.weight.label')
                    }
                    : null
            ].filter(isNotNil)
            : [];
    }), [all, t]);

    const goToShop = useShopRedirect();
    // const startTrial = useShopStartTrial();

    const unassignSeat = useUnassignSeat({
        onSuccess: () => {
            reload && reload();
        },
        onError: () => toast.error('...')
    });

    const seatLockedPrompt = useConfirmation({
        title: t('app.shop.message.seat-locked.title'),
        messages: context => ([
            t('app.shop.message.seat-locked.message', context.data)
        ]),
        cancelButton: false as any,
    });

    const startTrialPrompt = useStartTrialPrompt(
        reload as any,
    );

    if (!acc?.ownsSubscription) {
        const manageAboAction = {
            label: t('app.shop.action.manage-abo.label'),
            color: UiColor.primary,
            className: 'txt-b',
            interact: () => goToShop()
        };

        const startTrialAction = {
            label: t('app.shop.action.start-trial.label'),
            color: UiColor.accent,
            className: 'txt-b',
            interact: () => startTrialPrompt.open()
        };

        return acc?.trialAvailable
            ? <UiBox className={classNames('d-g', className)}
                     style={{
                         gridGap: 'var(--size-geo--md)',
                         ...style,
                         gridTemplateColumns: `repeat(${perRow}, minmax(min-content, 1fr))`,
                         gridTemplateRows: `repeat(${Math.ceil((taken?.length || 0) / perRow)}, min-content)`,
                     }}>
                <ShopTrialSeatCard
                    key={`free-seat`}
                    interact={() => nav(`/portal/trainer/athletes/seats/search`)}
                    configureSeats={{
                        interact: () => nav(`/portal/trainer/athletes/seats/search`)
                    }}/>
                <UiBox style={{gridColumn: '2 / 4'}}>
                    <UiTourTooltip arrow arrowSide={ArrowSide.Left}>
                        <UiTextBlock text={t('app.tour.onboarding-assign-trial-seat.message')}/>
                    </UiTourTooltip>
                </UiBox>
                {startTrialPrompt.element}
            </UiBox>
            : <UiSplashMessage title={t('app.shop.cta.title')}
                               messages={[t('app.shop.cta.info')]}
                               actions={
                                   acc?.trialAvailable
                                       ? [startTrialAction, manageAboAction]
                                       : [{...manageAboAction, color: UiColor.accent}]
                               }/>;
    }

    const numTakenSeats = (taken?.length || 0);
    const numLockedSeats = (locked?.length || 0);
    const numFreeSeats = (free?.length || 0);

    const minimumDaysLockedForLockedSeats = (lockedSeats:any) => {
        const sortedSeats = lockedSeats.sort((firstSeat:any, secondSeat:any) => {
            return firstSeat.remainingLockedDays - secondSeat.remainingLockedDays
        })

        return sortedSeats[0].remainingLockedDays;
    }

    return <>
        {(title || actions) && <UiFlexRow ai={UiFlexAlign.c}
                                          className={'mg-b-nm'}>
            {title && <UiTextTitle text={title}
                                   headingLevel={2}
                                   className={'f-1'}/>}
            {actions && <UiButtonGroup actions={actions.filter(a => !!a) as IUiButton[]}/>}
        </UiFlexRow>}
        <UiBox className={classNames('d-g', className)}
               style={{
                   gridGap: 'var(--size-geo--md)',
                   ...style,
                   gridTemplateColumns: `repeat(${perRow}, minmax(min-content, 1fr))`,
                   gridTemplateRows: `repeat(${Math.ceil((taken?.length || 0) / perRow)}, min-content)`,
               }}>
            {(all as ISeat[])
                // .filter(seat => seat.locked || isNotNil(seat.athlete))
                .map((seat, i) => {

                    return <AthleteStatCard key={`${i}.${seat.id}`}
                                            account={seat.athlete as any}
                                            isFavorite={false}
                                            stats={prop(stats, i)}
                                            interact={() => nav(`/portal/trainer/athletes/details/${seat?.athlete?.id}`)}
                                            actions={[
                                                seat.locked
                                                    ? {
                                                        anchor: 'top-left',
                                                        icon: 'lock',
                                                        interact: (ev: any) => {
                                                            ev.persist();
                                                            ev.stopPropagation();
                                                            seatLockedPrompt.setData({
                                                                seat,
                                                                remainingLockedDays: seat.remainingLockedDays || 0
                                                            });
                                                            seatLockedPrompt.open();
                                                        }
                                                    }
                                                    : {
                                                        anchor: 'top-left',
                                                        icon: 'times',
                                                        interact: (ev: any) => {
                                                            ev.persist();
                                                            ev.stopPropagation();

                                                            unassignSeat.callback(seat.id);
                                                        }
                                                    },
                                            ].filter(isNotNil) as any}/>;
                })}
            {numFreeSeats > 0 && <>
                {free?.map(seat => {

                    return <ShopFreeSeatsCard
                        key={`free.${seat.id}`}
                        interact={() => nav(`/portal/trainer/athletes/seats/search`)}
                        freeSeats={1}
                        configureSeats={{
                            interact: () => goToShop()
                        }}/>
                })}
                {numLockedSeats === 0
                    && numTakenSeats === 0
                    && <ShopFreeSeatExplainerCard interact={() => nav(`/portal/trainer/athletes/seats/search`)}/>}
            </>}
            {numLockedSeats > 0 && <ShopLockedSeatsCard lockedSeats={locked?.length || 0}
                                                        actions={[
                                                            {
                                                                icon: 'info',
                                                                variation: UiButtonVariation.ghost,
                                                                interact: () => {
                                                                    seatLockedPrompt.setData({
                                                                        seat: undefined,
                                                                        remainingLockedDays: minimumDaysLockedForLockedSeats(locked)
                                                                    });
                                                                    seatLockedPrompt.open();
                                                                }
                                                            }
                                                        ]}/>}
            {numFreeSeats <= 1 && <ShopBuySeatsCard
                key={`buy-seats`}
                interact={() => goToShop()}
                configureSeats={{
                    interact: () => goToShop()
                }}/>}
            {seatLockedPrompt.element}
            {unassignSeat.modal.element}
        </UiBox>
    </>;
};
