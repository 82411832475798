import React from 'react';
import {AppPageSectionPanel, IAppPageSectionPanel} from './app-page-section-panel.component';
import {classNames, IUiModal, modalClassNames, UiModal, uiModalDefaultDialogStyle} from '@bitsolve/react-common';

export const appPagePanelModalStyles = (style?: any) => ({
  ...uiModalDefaultDialogStyle,
  ...style,
  content: {
    ...uiModalDefaultDialogStyle.content,
    borderRadius: '0.3125rem',
    backgroundColor: 'transparent',
    boxShadow: '0 0 1em rgba(0,0,0,0.25)',
    minWidth: '22.5rem',
    maxWidth: '80vw',
    minHeight: '50vh',
    maxHeight: '90vh',
    ...style?.content,
    display: 'flex',
    flexDirection: 'column'
  }
});

export const AppPagePanelModal: React.FC<{ panel: IAppPageSectionPanel; modal: Partial<IUiModal>; }> = (props) => {
  const {modal, panel, children} = props;

  return <UiModal closeTimeoutMS={17}
                  {...modal}
                  {...modalClassNames('dialog')}
                  style={appPagePanelModalStyles(modal?.style)}>
    <AppPageSectionPanel {...panel}
                         className={classNames('f-1', panel?.className)}>
      {children}
    </AppPageSectionPanel>
  </UiModal>;
};
