import React from 'react';
import {IAnalysisSegmentTypeOrFn} from '../analysis-details.model';
import {AnalysisDetailsSegment} from '../analysis-details-segment.component';
import {UiFlexAlign, UiFlexCol, UiFlexRow, UiTextTitle} from '@bitsolve/react-common';
import {Route} from 'react-router-dom';
import {IRoute} from '../../../../core/routing';
import {hasProp, isFn, isNotNil} from '@bitsolve/fns';
import {AnalysisRedirect, IAnalysisRedirect} from '../analysis-redirect.component';


export interface IDrilldownStageRoute {
  pattern: string;
  exact?: boolean;
  redirect?: IAnalysisRedirect;
}


export interface IDrilldownStage {
  segments: IAnalysisSegmentTypeOrFn[];
  route: IDrilldownStageRoute;
  match?: IRoute;
}

export interface IDrilldownCharts {
  stages: IDrilldownStage[];
  header?: {
    title?: string;
  }
}

const DrilldownStage: React.FC<IDrilldownStage> = (props) => {
  const {segments, match} = props;

  return <>
    {segments.map((segment, index) =>
      <AnalysisDetailsSegment key={index}
                              {...segment as any}
                              route={match as any} />)}
  </>;
}

export const DrilldownCharts: React.FC<IDrilldownCharts> = (props) => {
  const {stages, header} = props;

  return <UiFlexCol f={1} className={'app-analysis__drilldown'}>
    {header && header.title && <UiFlexRow ai={UiFlexAlign.c}
                                          jc={UiFlexAlign.c}
                                          className={'pd-v-nm'}>
      <UiTextTitle text={header.title} headingLevel={2} />
    </UiFlexRow>}
    {stages
      .filter(s => hasProp(s, 'route'))
      .map((stage, index) => {
        const {route, segments, ...rest} = stage;
        const redir = route?.redirect;

        return <React.Fragment key={`${index}.${route.pattern}`}>
          <Route path={route.pattern}
                 exact={!!route.exact}
                 render={(route) => {
                   const segs = segments
                     .map(seg => isFn(seg) ? seg(route) : seg)
                     .filter(isNotNil);

                   return <DrilldownStage {...rest as any}
                                          match={route}
                                          segments={segs} />;
                 }} />
          {redir && <AnalysisRedirect {...redir} />}
        </React.Fragment>;
      })}
  </UiFlexCol>;
};


