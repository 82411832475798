import {hasProp, isEmptyStr, isNil, isNonEmptyStr, keys, range, update} from '@bitsolve/fns';
import {IUiFieldProps, useTranslatedFields, useTranslator} from '@bitsolve/react-common';
import {useLocation} from 'react-router-dom';
import {useMemo} from 'react';
import * as yup from 'yup';

export type TranslateFn = (id: string, values?: any) => string;

export type IFormFieldIndex<T = any> = Partial<Record<keyof T, Partial<IUiFieldProps<T[keyof T]>>>>;

export const useFormIndexFields = <T = any, I = IFormFieldIndex<T>>(index: I, fields?: Array<keyof I>) => {
  const _fields = (fields || keys(index))
    .reduce(((fs: I[keyof I][], f: keyof I) => {
      return hasProp(index, f) ? [...fs, index[f]] : fs;
    }) as any, []);

  return useTranslatedFields<IUiFieldProps<T[keyof T]>>(_fields);
};

export const areSetsEqual = <T = any>(a: Set<T>, b: Set<T>): boolean => {
  for (let _a of Array.from(a.values())) {
    if (!b.has(_a)) return false;
  }
  for (let _b of Array.from(b.values())) {
    if (!a.has(_b)) return false;
  }
  return true;
};


export const parseNumberKey = (x: any, k: string): any =>
  update(x, k, (v: string | undefined | null) => {
    try {
      return isNonEmptyStr(v) ? parseInt(v, 10) : null;
    } catch (_) {
      return null;
    }
  });

export const parseNumberKeys = (x: any, ...ks: string[]): any =>
  ks.reduce(parseNumberKey, x);


export const explodeDuration = (initial?: string): [string, string] => {
  if (isNil(initial) || isEmptyStr(initial) || isNaN(initial as any)) {
    return ['0', '0'];
  }

  try {
    const p = parseInt(initial || '0', 10);
    const s = p % 60;
    const m = Math.round((p - s) / 60);
    return [m.toString(10), s.toString(10)];
  } catch (_) {
    return ['0', '0'];
  }
};

export const useQueryParam = <T = any>(param: string, origin: string = window.location.origin): T | null => {
  const location = useLocation();

  return useMemo(() => {
      const uri = `${location.pathname}${location.search}`;
      const url = new URL(uri, `${origin}${uri}`);
      const q = url.searchParams;

      return q.get(param) as unknown as T || null;
    },
    [location, origin, param]
  );
};


export const useSafeEmailDisplay = (email?: string | null) => {
  const parts = useMemo(() => {
    if (!email || isEmptyStr(email)) return;
    return email.split('@').slice(0, 2);
  }, [email]);

  return useMemo(() => {
    if (!parts) return;
    const [pre, domain] = parts;
    const show = 2;
    const schar = '✷'; // ▤▩▨▧
    return `${pre.substr(0, show)}${range(0, pre.length - show).map(() => schar).join('')}@${domain}`;
  }, [parts]);
};


// just a very basic email address test
export const emailRe = /^[a-zA-Z][a-zA-Z0-9_+-.]+[a-zA-Z0-9_+-]@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+[a-zA-Z0-9]$/;

export const validEmailTester = (t: Function, messagePrefix: string) => ({
  name: 'valid-email',
  test: function (value: string): true | yup.ValidationError {
    if (value.match(emailRe)) {
      return true;
    } else {
      // @ts-ignore
      return this.createError({
        path: 'email',
        message: t(messagePrefix + '.valid-email')
      });
    }
  }
});

export const schemaRef = yup.ref;

export const formatDuration = (t: TranslateFn, duration?: number, format: 'short' | 'medium' | 'long' = 'medium'): string | null => {
  // @ts-ignore
  if (isNil(duration) || isNaN(duration)) {
    return null;
  }

  // @ts-ignore
  const seconds = Math.round(duration % 60);
  // @ts-ignore
  const minutes = Math.round((duration - seconds) / 60);

  return t(`general.duration.${format || 'short'}`, {minutes, seconds});
};

export const useFormattedDuration = (duration?: number, format: 'short' | 'medium' | 'long' = 'medium'): string | null => {
  const t = useTranslator();

  return useMemo(
    () => formatDuration(t, duration, format),
    [t, duration, format]
  );
};
export const tryParseNumber = (x?: string): null | number => {
  try {
    return isNil(x) ? null : parseInt(x as any, 10);
  } catch (_) {
    return null;
  }
};
