import React, {useMemo} from 'react';
import {
  AppSplashScreen,
  AppSplashScreenContent,
  AppSplashScreenHeader
} from '../../../common/misc/app-splash-screen.component';
import {AppLogoInitial} from '../../../common/misc/app-logo.component';
import {
  IUiFieldProps,
  schema,
  toast,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexCol,
  UiForm,
  UiFormButton,
  UiFormField,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {IAuthUserAccount, useFirebaseAppContext} from '../../../../module/auth';
import {authProfileFields, authProfileSchema, authTrainerProfileFields} from '../../../../module/auth/auth.forms';
import {useSchemaOptions} from '../../../../core/api';
import {useNavigator} from '../../../../core/routing';
import {useAuthAccount, useAuthActions} from '../../../../module/auth/auth.store';
import {props} from '@bitsolve/fns';
import {useUpdateMyAccount, useUpdateMyTrainerProfile} from '../../../../module/auth/auth.api';
import {AppPageSectionTitle} from '../../../common/page/app-page-section-title.component';
import {useOnboardingRedirects} from '../onboarding.routes';

export interface AppRegisterCompleteRouteProps {
}

const completeAccountFields: IUiFieldProps<IAuthUserAccount>[] = [
  authProfileFields.firstName,
  authProfileFields.lastName,
  authProfileFields.birthDay,
  authProfileFields.gender,

  authTrainerProfileFields.trainerType,
  authTrainerProfileFields.fightStyles,
  authTrainerProfileFields.customFightStyles,
  // authProfileFields.hometown,
  // authProfileFields.countryIsoCode,
];

const completeAccountSchema = (t: (_: string) => string) => schema.object({
  firstName: authProfileSchema.firstName.required('profile.error.messages.firstName.required'),
  lastName: authProfileSchema.lastName.required('profile.error.messages.lastName.required'),
  birthDay: authProfileSchema.birthDay,
  gender: authProfileSchema.gender.required(t('profile.error.messages.gender.required')),
  trainerType: schema.mixed()
    .required('trainerProfile.error.messages.trainerType.required'),
  fightStyles: schema.array()
    .min(1, 'trainerProfile.error.messages.fightStyles.size')
  // hometown: authProfileSchema.hometown.required(t('error.messages.hometown.required')),
  // countryIsoCode: authProfileSchema.countryIsoCode.required(t('error.messages.countryIsoCode.required')),
});

const today = new Date();

export const AppRegisterCompleteRoute: React.FC<AppRegisterCompleteRouteProps> = (_props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const fb = useFirebaseAppContext();
  const authActions = useAuthActions();
  const account = useAuthAccount();

  const fields = useTranslatedFields(completeAccountFields);
  const schemaOpts = useSchemaOptions('app.account.{message}', false);
  const schema = useMemo(() => completeAccountSchema(t), [t]);

  // const defaults = useMemo(
  //   () => account && props(account, ['lastName', 'firstName', 'birthDay', 'gender']),
  //   [account]
  // );

  useOnboardingRedirects();

  const updateAccount = useUpdateMyAccount(
    result => authActions.updateAccount(result.data),
    e => console.error(e)
  );

  const updateTrainerProfile = useUpdateMyTrainerProfile(
    result => authActions.updateAccountProfile('trainerProfile', result.data),
    e => console.error(e)
  );

  const defaults = useMemo(
    () => {
      const base = {
        trainerType: null,
        fightStyles: [],
        customFightStyles: []
      };

      return account
        ? {
          ...base,
          birthDay: account?.birthDay || today,
          ...props(account.trainerProfile, ['fightStyles', 'customFightStyles', 'trainerType'])
        }
        : {
          ...base,
          birthDay: today
        };
    },
    [account]
  );

  return <AppSplashScreen
    dim wide
    className={'app-onboarding app-onboarding__complete app-onboarding__complete__account'}>
    <AppSplashScreenHeader>
      <AppLogoInitial />
    </AppSplashScreenHeader>
    <AppSplashScreenContent style={{gridRow: '2/4'}}>

      <UiForm
        value={defaults}
        schema={schema}
        schemaOptions={schemaOpts}
        fields={fields}
        interact={(data, form) => {
          const profileData = props(data, ['lastName', 'firstName', 'birthDay', 'gender']);

          return updateAccount
            .send(profileData)
            .then(() => {
              const trainerProfileData = props(data, ['fightStyles', 'customFightStyles', 'trainerType']);
              return updateTrainerProfile.send(trainerProfileData);
            })
            .catch(e => {
              const err = schemaOpts.transformSubmitError(e, data);

              if (err) {
                form.setError(err);
                return Promise.reject(err);
              }
            });
        }}>
        <UiBox className={'d-g'}
               style={{
                 gridTemplateColumns: 'repeat(2, 1fr)',
                 gridColumnGap: '2.5rem'
               }}>

          <UiFlexCol>
            <AppPageSectionTitle dock={'top'}
                                 style={{fontSize: '1.25rem'}}
                                 className={'mg-v-sm'}
                                 text={t('app.account.profile.title')} />

            <UiFormField direction={UiDir.v}
                         className={'mg-b-nm'}
                         name={'firstName'} />
            <UiFormField direction={UiDir.v}
                         className={'mg-b-nm'}
                         name={'lastName'} />
            <UiFormField direction={UiDir.v}
                         className={'mg-b-nm'}
                         name={'birthDay'} />
            <UiFormField direction={UiDir.v}
                         className={'mg-b-nm'}
                         name={'gender'} />
            {/*<UiFormField direction={UiDir.v}*/}
            {/*             className={'mg-v-nm'}*/}
            {/*             name={'hometown'} />*/}
            {/*<UiFormField direction={UiDir.v}*/}
            {/*             className={'mg-v-nm'}*/}
            {/*             name={'countryIsoCode'} />*/}
          </UiFlexCol>

          <UiFlexCol>
            <AppPageSectionTitle dock={'top'}
                                 style={{fontSize: '1.25rem'}}
                                 className={'mg-v-sm'}
                                 text={t('app.account.trainerProfile.form.fightStyle.label')} />
            <UiFormField direction={UiDir.v}
                         className={'mg-b-sm'}
                         name={'fightStyles'} />
            <UiFormField direction={UiDir.v}
                         name={'customFightStyles'} />

            <AppPageSectionTitle dock={'top'}
                                 style={{fontSize: '1.25rem'}}
                                 className={'mg-t-md mg-b-sm'}
                                 text={t('app.account.trainerProfile.form.trainerType.label')} />
            <UiBox className="pd-sm">
              <UiFormField direction={UiDir.v}
                           className={'mg-b-nm'}
                           name={'trainerType'} />
            </UiBox>
          </UiFlexCol>

        </UiBox>

        <UiFlexCol tag={'footer'}
                   className={'mg-t-md'}>
          <UiFormButton stretch
                        color={UiColor.accent}
                        className={'txt-b'}
                        label={t('general.action.next')}
                        interact={(form) => form.submit()} />
          <UiButton stretch
                    variation={UiButtonVariation.ghost}
                    color={UiColor.default}
                    label={t('app.account.profile.action.logout.label')}
                    className={'mg-t-nm'}
                    interact={() => {
                      fb?.app?.auth()?.signOut()
                        .then(() => authActions.unauthenticate())
                        .then(() => nav('/'))
                        .catch(() => {
                          toast.error(t('app.account.profile.action.logout.toast.error'));
                        })
                    }} />
        </UiFlexCol>
      </UiForm>
    </AppSplashScreenContent>
  </AppSplashScreen>;
};
