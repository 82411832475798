import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSubscriptionToken} from '../../../../module/shop/shop.util';

export interface ICoachingZoneDashboardRoute {
}

export const CoachingZoneDashboardRoute: React.FC<ICoachingZoneDashboardRoute> = (props) => {
  const subToken = useSubscriptionToken();

  return <Redirect to={subToken
    ? `/portal/shop/apply-subscription?subId=${subToken}`
    : '/portal/trainer/athletes'} />;
};
