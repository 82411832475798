import {useCallback} from 'react';
import {RouteChildrenProps, useHistory} from 'react-router';
import {LocationState} from 'history';
import {isEmptyStr, isNil} from '@bitsolve/fns';

export type IRouteParams<P> = { [K in keyof P]?: string; };

export interface IRoute<P extends IRouteParams<P> = any, S extends LocationState = LocationState> extends RouteChildrenProps<P, S> {
}

export type NavigateFn = (path: string) => void;

export const useNavigator = () => {
  const h = useHistory();
  return useCallback(
    (path: string) => h.push(path),
    [h]
  );
};

export const navExt = (url?: string): void => {
  if (isNil(url) || isEmptyStr(url)) {
    return;
  }
  // @ts-ignore
  window.location.href = url;
};
