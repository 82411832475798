import React, {RefObject, useCallback, useRef} from 'react';
import {Rooq} from '../../../core/domain.model';
import {
  classNames,
  UiCustomizable,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiIcon,
  UiState,
  useTranslator
} from '@bitsolve/react-common';
import {isNil, isNotNil} from '@bitsolve/fns';
import {DragObjectWithType, DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {AppDisciplineIcon} from '../../../app/common/misc/app-discipline-icon.component';

export interface ITrainingPlanUnit extends UiCustomizable {
  unit: Rooq.TrainingPlanUnit;
  state: UiState;
  index: number;
  isFinal?: boolean;
  onDragStart?: () => any;
  onDragEnd?: () => any;
  onRequestReorder?: (prevIndex: number, nextIndex: number) => any;
  onDelete?: (unit: Rooq.TrainingPlanUnit) => any;
  onClick?: (unit: Rooq.TrainingPlanUnit) => any;
}

const useCalculateDropHover = (ref: RefObject<HTMLDivElement>) => {
  return useCallback(
    (hoverIndex: number, dragIndex: number, monitor: DropTargetMonitor) => {
      if (!ref.current || isNil(hoverIndex) || dragIndex === hoverIndex) {
        return;
      }

      const hoverBounds = ref.current?.getBoundingClientRect();
      // const clientOffset = monitor.getClientOffset();

      // const hoverMiddleY = (hoverBounds.bottom - hoverBounds.top) / 2;
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBounds.top;

      const draggingOOBTop = dragIndex < (hoverIndex as any)// && hoverClientY < hoverMiddleY;
      const draggingOOBBottom = dragIndex > (hoverIndex as any)// && hoverClientY > hoverMiddleY;

      const shouldRequestReorder = (draggingOOBTop || draggingOOBBottom || isNil(dragIndex) || isNil(hoverIndex) || dragIndex === hoverIndex);

      return {
        hoverBounds,
        // hoverMiddleY,
        // hoverClientY,
        hoverIndex,
        dragIndex,
        shouldRequestReorder,
        outOfBounds: {
          top: draggingOOBTop,
          bottom: draggingOOBBottom,
        }
      };
    },
    [ref]
  );
};

const TrainingPlanUnitDetails: React.FC<{ unit: Rooq.TrainingPlanUnit }> = (props) => {
  const {unit} = props;
  const t = useTranslator();

  return <UiFlexCol className="app-training-plan__unit__details">
    <UiFlexRow className="app-training-plan__unit__details__header"
               ai={UiFlexAlign.c}>
      <AppDisciplineIcon discipline={unit.discipline}
                         size={2.25} />
      <span>{t(`content.discipline.${unit.discipline}`)}</span>
    </UiFlexRow>
    <UiFlexRow ai={UiFlexAlign.c}
               className="app-training-plan__unit__details__breakdown">
      <UiFlexRow ai={UiFlexAlign.c}>
        <UiIcon className={'txt-nm'} name={'repeat'} iconSet={'regular'} />
        <span>{t('app.training-plan.breakdown.rounds', {count: unit.numberOfRounds})}</span>
      </UiFlexRow>
      <UiFlexRow ai={UiFlexAlign.c}>
        <UiIcon className={'txt-nm'} name={'stopwatch'} iconSet={'regular'} />
        <span>{t('app.training-plan.breakdown.time-per-round', {seconds: unit.roundLengthSeconds})}</span>
      </UiFlexRow>
      <UiFlexRow ai={UiFlexAlign.c}>
        <UiIcon className={'txt-nm'} name={'pause'} iconSet={'regular'} />
        <span>{t('app.training-plan.breakdown.pause', {seconds: unit.pauseLengthSeconds})}</span>
      </UiFlexRow>
    </UiFlexRow>
    <UiFlexRow className="app-training-plan__unit__details__goal__container">
      {unit.description && <UiFlexRow ai={UiFlexAlign.c}
                                      className="app-training-plan__unit__details__goal">
        <UiIcon name={'appGoal'} iconSet={'regular'} />
        <span>{unit.description}</span>
      </UiFlexRow>}
    </UiFlexRow>
  </UiFlexCol>;
};

export const TrainingPlanUnit: React.FC<ITrainingPlanUnit> = (props) => {
  const {unit, index, onClick, onRequestReorder, onDelete, isFinal = false, state, className, style} = props;

  const t = useTranslator();
  const ref = useRef<HTMLDivElement>(null);
  const calculateDropHover = useCalculateDropHover(ref);

  const [{isOver}, drop] = useDrop({
    accept: 'unit',
    collect: (monitor) => ({isOver: monitor.isOver({shallow: true})}),
    drop: (item: DragObjectWithType & { id: string; index: number; }, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }

      const hover = calculateDropHover(item.index, index, monitor);

      if (!hover?.shouldRequestReorder) {
        return;
      }

      if (onRequestReorder) {
        onRequestReorder(hover?.dragIndex as any, hover?.hoverIndex as any);
      }
    }
  });

  const [{isDragging}, drag, preview] = useDrag({
    previewOptions: {
      anchorY: -25
    },
    item: {type: 'unit', id: unit.id, index},
    begin: () => props.onDragStart && props.onDragStart(),
    end: () => props.onDragEnd && props.onDragEnd(),
    collect: (monitor: DragSourceMonitor) => ({isDragging: monitor.isDragging()})
  });

  const opacity = isDragging ? 0.5 : (style?.opacity || 1);

  preview(drop(ref));

  return <div className={classNames('app-training-plan__unit', className)}
              style={{...style, opacity}}
              ref={ref}
              onClick={onClick ? () => onClick(unit) : undefined}>
    <div ref={preview}
         style={{
           backgroundImage: `url(/asset/brand/discipline-mood/${unit.discipline}.png)`,
           borderStyle: isDragging ? 'solid' : undefined
         }}
         className={classNames(
           'app-training-plan__unit__container',
           isOver && !isDragging && 'drop-highlight',
           !isOver && isDragging && 'drag-highlight',
         )}>

      <UiFlexCol className="app-training-plan__unit__decor"
                 ai={UiFlexAlign.c}>
        {isNotNil(index) && <div className="app-training-plan__unit__decor__step">
          <span>{(index as any) + 1}</span>
        </div>}
        <div className="app-training-plan__unit__decor__line f-1" />
        <UiIcon name={'caret-down'} iconSet={'regular'} />
      </UiFlexCol>
      <TrainingPlanUnitDetails unit={unit} />
      <UiFlexCol className="app-training-plan__unit__handle"
                 ai={UiFlexAlign.c}
                 jc={UiFlexAlign.c}>
        {state === UiState.active && <div className={'app-training-plan__unit__handle__ref'}
              ref={drag}>
          <UiIcon name={'hamburger'} iconSet={'regular'} />
        </div>}
      </UiFlexCol>

    </div>

    {onDelete && state === UiState.active && <UiFlexCol className={'app-training-plan__unit__delete-icon'}
                                                        onClick={(e) => {
                                                          // @ts-ignore
                                                          e.persist && e.persist();
                                                          e.stopPropagation();
                                                          onDelete(unit)
                                                        }}>
      <UiIcon name={'times'} iconSet={'regular'} />
    </UiFlexCol>}

    <UiFlexRow className={'app-training-plan__unit__cooldown'}
               ai={UiFlexAlign.c}
               jc={UiFlexAlign.c}>
      {(unit.coolDownSeconds && unit.coolDownSeconds !== 0)
        ? <span>{t('app.training-plan.breakdown.cooldown', {seconds: unit.coolDownSeconds})}</span>
        : <UiIcon key={`${isFinal}.${index}.${unit.discipline}`}
                  name={isFinal ? 'check' : 'caret-down'} iconSet={'regular'}
                  className={isFinal ? 'txt-c-suc' : 'txt-c-wrn'}
                  style={{transformOrigin: 'center', transform: 'scale(1.25)'}} />}
    </UiFlexRow>
  </div>;
};
