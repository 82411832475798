import React from 'react';
import {IAuthUserAccount, userAccountName} from '../../index';
import {animated} from 'react-spring';

import {classNames, UiCustomizable, UiFlexAlign, UiFlexRow, UiIcon, UiTextTitle} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../../app/common/misc/app-banner-pane.component';
import {mediaUrl} from '../../../image/image.api';

export interface ITrainerCard extends UiCustomizable {
  account: IAuthUserAccount;
  isClubAdmin?: boolean;
  interact?: () => any;
}


export const TrainerCard: React.FC<ITrainerCard> = (props) => {
  const {account, interact, isClubAdmin, className, style} = props;
  const name = userAccountName(account);

  return <animated.section className={classNames('app-trainer-card', className)}
                           style={style}>
    <AppBannerPane className="app-trainer-card__header"
                   aspectRatio={1 / 2}
                   src={account?.profilePictureId
                     ? mediaUrl(account?.profilePictureId)
                     : undefined}
                   interact={interact} />
    <main className="app-trainer-card__content d-f fd-h f-ai-c"
          style={{borderRadius: '0.25em'}}>
      <UiFlexRow f={1} ai={UiFlexAlign.c}>
        {name && <UiTextTitle text={name} className={'f-1'} />}
        {isClubAdmin && <UiIcon name={'star'} iconSet={'solid'} />}
      </UiFlexRow>
    </main>
  </animated.section>;
};
