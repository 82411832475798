import React from 'react';

const clock = <svg viewBox="0 0 128 128">
  <g fill="none" fillRule="evenodd" strokeWidth="3">
    <path
      d="m64.401 36 .198-16m9.83 1h-18.859c-.88 0-1.571-.623-1.571-1.417v-14.163c.001-.797.692-1.42 1.572-1.42h18.859c.879 0 1.57.623 1.57 1.42v14.163c0 .794-.691 1.417-1.571 1.417z"
      stroke="#ca2243" />
    <g stroke="#94adc1">
      <path
        d="m63.5 124c-23.999 0-43.5-19.532-43.5-43.483 0-23.985 19.501-43.517 43.5-43.517s43.5 19.532 43.5 43.517c0 23.95-19.501 43.483-43.5 43.483zm31.5-70 7-7" />
      <path d="m109 50-11-11" strokeLinecap="round" />
      <path d="m34 52-7-7" />
      <path d="m20 49 11-11" strokeLinecap="round" />
    </g>
    <path d="m64 80 15-26" stroke="#ca2243" strokeLinecap="round" />
  </g>
</svg>;

const counter = <svg viewBox="0 0 128 128">
  <g fill="none" fillRule="evenodd">
    <path stroke="#94ADC1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
          d="M101.667 17H26.333C15.932 17 7.5 25.417 7.5 35.8v56.4c0 10.383 8.432 18.8 18.833 18.8h75.334c10.4 0 18.833-8.417 18.833-18.8V35.8c0-10.383-8.432-18.8-18.833-18.8zM7.5 88h113m-85-71v71m28-71v71m28-71v71" />
    <path fill="#94ADC1" fillRule="nonzero"
          d="M27.474 53.997a10.268 10.268 0 0 1-1.476 6.087 5.254 5.254 0 0 1-2.011 1.506c-.78.33-1.628.464-2.47.39a5.177 5.177 0 0 1-2.45-.399 5.255 5.255 0 0 1-1.994-1.497 9.841 9.841 0 0 1-1.55-5.878V51a10.153 10.153 0 0 1 1.475-5.992 5.291 5.291 0 0 1 2.009-1.558 5.213 5.213 0 0 1 2.491-.433c.84-.07 1.683.065 2.46.395.776.33 1.464.845 2.003 1.501a9.861 9.861 0 0 1 1.513 5.822v3.261zm-3.044-3.394a7.878 7.878 0 0 0-.71-3.792 2.407 2.407 0 0 0-.94-.93 2.358 2.358 0 0 0-1.282-.284c-.434-.03-.868.06-1.256.26s-.716.504-.947.878a7.357 7.357 0 0 0-.728 3.603v4.057a8.3 8.3 0 0 0 .71 3.793 2.4 2.4 0 0 0 .937.966c.397.225.85.33 1.303.304.432.024.863-.073 1.244-.28.382-.208.701-.517.922-.895a7.685 7.685 0 0 0 .747-3.679v-4zm31.036 3.396a10.273 10.273 0 0 1-1.493 6.087 5.25 5.25 0 0 1-2.002 1.501 5.18 5.18 0 0 1-2.458.395 5.171 5.171 0 0 1-2.448-.4 5.25 5.25 0 0 1-1.993-1.496 9.845 9.845 0 0 1-1.55-5.86v-3.223a10.156 10.156 0 0 1 1.475-5.992 5.288 5.288 0 0 1 2.008-1.558 5.206 5.206 0 0 1 2.49-.433 5.168 5.168 0 0 1 2.468.39c.78.33 1.47.847 2.01 1.506a9.867 9.867 0 0 1 1.512 5.821L55.466 54zm-3.042-3.394a7.882 7.882 0 0 0-.709-3.793 2.702 2.702 0 0 0-.962-.866 2.653 2.653 0 0 0-2.498 0 2.7 2.7 0 0 0-.963.866 7.36 7.36 0 0 0-.727 3.603v3.982a8.305 8.305 0 0 0 .709 3.792c.228.381.547.698.927.92a2.58 2.58 0 0 0 2.512.055c.39-.206.721-.509.965-.88a7.69 7.69 0 0 0 .746-3.678v-4.001z" />
    <path fill="#CA2243" fillRule="nonzero"
          d="M85.404 45.609L77.904 62h-3.289l7.5-15.651H72.5V44h13zm26.679 2.473c.01.863-.231 1.71-.693 2.438a4.944 4.944 0 0 1-1.874 1.663 5.22 5.22 0 0 1 2.211 1.89c.509.779.776 1.693.769 2.626a4.93 4.93 0 0 1-.382 2.123 4.894 4.894 0 0 1-1.249 1.752 6.412 6.412 0 0 1-4.348 1.417 6.356 6.356 0 0 1-4.366-1.436 4.836 4.836 0 0 1-1.245-1.704 4.872 4.872 0 0 1-.404-2.076 4.62 4.62 0 0 1 .787-2.646 4.883 4.883 0 0 1 2.192-1.89 4.886 4.886 0 0 1-1.874-1.663 4.431 4.431 0 0 1-.674-2.419 4.816 4.816 0 0 1 .33-2.059 4.781 4.781 0 0 1 1.169-1.72A6.727 6.727 0 0 1 106.508 43c1.47 0 2.902.483 4.076 1.377a4.838 4.838 0 0 1 1.5 3.705zm-2.68 8.39a3.058 3.058 0 0 0-.787-2.173 2.885 2.885 0 0 0-2.136-.831 2.806 2.806 0 0 0-2.137.831 3.047 3.047 0 0 0-.805 2.212 3.004 3.004 0 0 0 .787 2.173 3.319 3.319 0 0 0 4.329 0 2.997 2.997 0 0 0 .75-2.211zm-.393-8.277a2.811 2.811 0 0 0-.694-1.984 2.362 2.362 0 0 0-1.874-.775 2.36 2.36 0 0 0-1.874.737 2.81 2.81 0 0 0-.693 2.022 2.925 2.925 0 0 0 .693 2.022 2.397 2.397 0 0 0 1.874.737 2.44 2.44 0 0 0 1.874-.737 2.926 2.926 0 0 0 .713-2.022h-.02z" />
  </g>
</svg>;

const punch_power = <svg viewBox="0 0 128 128">
  <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
    <g stroke="#94adc1">
      <path
        d="m22 84.57v-29.158a13.36 13.36 0 0 1 3.863-9.406 13.133 13.133 0 0 1 9.326-3.896l35.524-5.11a20.217 20.217 0 0 1 14.315 5.972 20.565 20.565 0 0 1 5.972 14.415v25.153c0 5.427-2.137 10.63-5.942 14.467a20.2 20.2 0 0 1 -14.345 5.993l-35.523-5.11c-1.733 0-3.45-.345-5.051-1.015a13.18 13.18 0 0 1 -4.281-2.888 13.308 13.308 0 0 1 -2.858-4.321 13.401 13.401 0 0 1 -1-5.096zm45-38.57a16 16 0 0 1 16 16m-49.952-20.143 12.299-11.747a12.563 12.563 0 0 1 6.684-2.59l6.293-.51a5.083 5.083 0 0 1 3.616 1.202 4.96 4.96 0 0 1 1.731 3.36l.32 3.91a2.517 2.517 0 0 1 -.604 1.846 2.57 2.57 0 0 1 -1.748.883l-28.591 3.787c0 .018-.107-.088 0-.14z" />
      <path
        d="m22 91.456a9.916 9.916 0 0 1 -3.39 2.624 9.644 9.644 0 0 1 -4.145.92h-5.489c-1.32 0-2.585-.54-3.518-1.501a5.204 5.204 0 0 1 -1.458-3.624v-38.75a5.2 5.2 0 0 1 1.458-3.624 4.905 4.905 0 0 1 3.518-1.501h5.489a9.66 9.66 0 0 1 4.156.963 9.939 9.939 0 0 1 3.379 2.672" />
      <path
        d="m20.982 61.018v18.982h-1.768a7.134 7.134 0 0 1 -5.102-2.185 7.544 7.544 0 0 1 -2.112-5.251v-4.128c0-1.972.762-3.863 2.118-5.258a7.133 7.133 0 0 1 5.114-2.178h1.768z" />
    </g>
    <path
      d="m98.842 80.643 8.172 8.315c.427.484.994.822 1.62.965a3.051 3.051 0 0 0 1.874-.166 3.091 3.091 0 0 0 1.43-1.236 3.141 3.141 0 0 0 .452-1.846l-.789-11.882a1.996 1.996 0 0 1 .514-1.448 1.955 1.955 0 0 1 1.386-.635l7.508-.163a3.06 3.06 0 0 0 1.665-.554 3.11 3.11 0 0 0 1.097-1.381 3.146 3.146 0 0 0 -.63-3.337l-6.433-8.658a1.967 1.967 0 0 1 -.107-2.59l5.036-9.782a3.142 3.142 0 0 0 .244-3.581c-.33-.55-.82-.982-1.403-1.24a3.053 3.053 0 0 0 -1.852-.196l-8.458 1.648a1.913 1.913 0 0 1 -1.534-.321 1.952 1.952 0 0 1 -.796-1.364l-1.505-13.494a3.132 3.132 0 0 0 -.711-1.605 3.081 3.081 0 0 0 -1.458-.96 3.05 3.05 0 0 0 -1.74-.01 3.079 3.079 0 0 0 -1.467.944l-7.957 9.728"
      stroke="#ca2243" />
    <path
      d="m97 41.638a1.742 1.742 0 0 1 2.677-.018 1.7 1.7 0 0 1 .388.92l.835 7.356a1.08 1.08 0 0 0 .45.758 1.099 1.099 0 0 0 .869.18l6.705-1.38c.348-.066.71-.026 1.034.116.325.142.599.379.784.678a1.716 1.716 0 0 1 -.11 1.965l-4.791 5.922a1.043 1.043 0 0 0 0 1.416l4.587 4.764a1.705 1.705 0 0 1 .359 1.854c-.131.31-.351.575-.633.762a1.738 1.738 0 0 1 -.952.29l-5.219.091a1.088 1.088 0 0 0 -.784.351 1.063 1.063 0 0 0 -.274.808l.427 6.529"
      stroke="#ca2243" />
  </g>
</svg>;

const punch_speed = <svg viewBox="0 0 128 128">
  <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
    <g stroke="#94adc1">
      <path
        d="m54 81.867v-28.805c0-3.475 1.39-6.807 3.863-9.264a13.235 13.235 0 0 1 9.326-3.836l35.524-4.962c5.38 0 10.54 2.123 14.345 5.902a20.08 20.08 0 0 1 5.942 14.248v24.683a20.098 20.098 0 0 1 -5.94 14.254 20.375 20.375 0 0 1 -14.348 5.913l-35.522-5.033c-3.498 0-6.852-1.38-9.326-3.837a13.055 13.055 0 0 1 -3.864-9.263zm46-38.867a16 16 0 0 1 16 16" />
      <path
        d="m66.048 39.86 12.296-11.693a12.591 12.591 0 0 1 6.683-2.595l6.29-.56c1.32-.088 2.62.34 3.621 1.19 1 .85 1.62 2.053 1.726 3.35l.32 3.874c.075.67-.123 1.34-.55 1.867a2.595 2.595 0 0 1 -1.73.938l-28.657 3.769s-.107-.088 0-.14zm-11.048 48.586a9.96 9.96 0 0 1 -3.381 2.608 9.894 9.894 0 0 1 -4.154.946h-5.488a4.945 4.945 0 0 1 -3.55-1.5 5.016 5.016 0 0 1 -1.427-3.6v-37.89a5.03 5.03 0 0 1 1.457-3.543 4.959 4.959 0 0 1 3.52-1.467h5.488a9.82 9.82 0 0 1 4.157.94 9.89 9.89 0 0 1 3.378 2.614" />
      <path
        d="m54 58.018v18.982h-1.768a7.132 7.132 0 0 1 -5.114-2.178 7.544 7.544 0 0 1 -2.118-5.258v-4.128c0-1.972.762-3.863 2.118-5.258a7.132 7.132 0 0 1 5.114-2.178h1.768z" />
    </g>
    <path d="m60 23h-26m32 82h-15m-12 0h-7m18-73h-11m-12 21h-7m7 9h-15m15 9h-23m24 9h-12" stroke="#ca2243" />
  </g>
</svg>;

export const analysisIcons = {
  clock,
  counter,
  punch_power,
  punch_speed,
};
