import React, {useMemo} from 'react';
import {IAccountActionTile} from '../../auth/component/account/account-tile.component';
import {Rooq} from '../../../core/domain.model';
import {AccountTileListing, IAccountTileListingItem} from '../../auth/component/account/account-tile-listing.component';
import {IAuthUserAccount} from '../../auth';
import {IHttpRequestHookResult, UiColor, useTranslator} from '@bitsolve/react-common';
import {useClubMembershipInvitationActions, useFindClubMembershipInvitations} from '../club.api';
import {useTrainerReportModal} from '../../auth/component/athlete/athlete-report-modal.component';

export interface IClubTrainerTileListing {
  clubId: string;
  actionTile?: IAccountActionTile;
  onAccountClick?: (account: IAuthUserAccount) => any;
}

const accountListingItem = (_: IHttpRequestHookResult, account: IAuthUserAccount, tooltipMenu?: any): IAccountTileListingItem => ({
  type: 'account',
  data: {
    account,
    tooltipMenu
  }
});

export const ClubTrainerTileListing: React.FC<IClubTrainerTileListing> = (props) => {
  const {clubId, actionTile, onAccountClick} = props;

  const t = useTranslator();
  const inviteReq = useFindClubMembershipInvitations(clubId, Rooq.MembershipType.TRAINER, 0, 100, {initialFetch: true});
  const reportModal = useTrainerReportModal();

  const {updateInvitation, deleteInvitation, hideInvitation} = useClubMembershipInvitationActions({
    onAny: inviteReq.load
  });

  const tiles = useMemo(() => {
    const _tiles: IAccountTileListingItem[] = [];


    if (!inviteReq.busy && inviteReq.response?.data?.elements) {
      inviteReq.response?.data?.elements.forEach(invite => {

        const _withdrawAction = {
          label: t('app.account.action.delete-relationship-invite.label'),
          icon: 'times',
          color: UiColor.error,
          interact: () => deleteInvitation.callback(invite.id)
        };

        const _hideAction = {
          label: t('app.account.action.delete-relationship-invite.alt-label'),
          icon: 'times',
          interact: () => hideInvitation.send(invite.id)
        };

        const _reportAction = {
          label: t('app.account.action.report.trainer.label'),
          icon: 'exclamationMark',
          interact: () => {
            reportModal.setData({accountId: invite.account.id});
            reportModal.open();
          }
        };

        const _acceptAction = {
          label: t('app.account.action.update-relationship.label.accept'),
          icon: 'check',
          color: UiColor.success,
          interact: () => updateInvitation.callback({
            invitationId: invite.id,
            status: Rooq.InvitationStatus.ACCEPTED
          })
        };

        const _rejectAction = {
          label: t('app.account.action.update-relationship.label.reject'),
          icon: 'times',
          color: UiColor.error,
          interact: () => updateInvitation.callback({
            invitationId: invite.id,
            status: Rooq.InvitationStatus.REJECTED
          })
        };

        const tooltipMenu = invite.status === Rooq.InvitationStatus.PENDING
          ? {
            items: invite.account.id === invite.issuingAccount.id
              ? [
                _acceptAction,
                _rejectAction,
                _reportAction
              ]
              : [
                _withdrawAction,
                _reportAction
              ]
          }
          : {
            items: [
              _hideAction
            ]
          };

        _tiles.push(accountListingItem(inviteReq, invite.account, tooltipMenu));
      });
    }

    if (actionTile) {
      _tiles.push({type: 'action', data: actionTile});
    }
    return _tiles;
  }, [t, actionTile, inviteReq, deleteInvitation, updateInvitation, hideInvitation, reportModal]);

  return <>
    <AccountTileListing items={tiles}
                        onAccountClick={onAccountClick} />
    {reportModal.element}
    {updateInvitation.modal.element}
    {deleteInvitation.modal.element}
  </>;
};
