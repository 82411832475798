import React from 'react';
import {IUiModalRoute, UiFlexRow} from '@bitsolve/react-common';
import {AccountListItem} from '../account/account-list-item.component';
import {IAuthUserAccount} from '../../index';
import {
  AppSearchModal,
  IAppSearchModal,
  IAppSearchResultProps
} from '../../../../app/common/misc/app-search-modal.component';

export interface IAthleteSearchModal extends Partial<IUiModalRoute>,
  Omit<IAppSearchModal<IAuthUserAccount>, 'resultComponent'>,
  Partial<Pick<IAppSearchModal<IAuthUserAccount>, 'resultComponent'>> {
  onClose?: () => any;
  onAthleteClick?: (account: IAuthUserAccount) => any;
}

const AthleteSearchResult: React.FC<IAppSearchResultProps<IAuthUserAccount>> = (props) => {
  const {data, index, actions, onResultClick} = props;

  return <UiFlexRow className="app-search-result app-search-result--account"
                    style={{animationDelay: `${(index + 1) * 75}ms`}}>
    <AccountListItem
      account={data}
      interact={() => onResultClick(props)}
      actions={actions?.map(action => (action.interact
        ? {...action, interact: () => action.interact(props)}
        : action) as any)} />
  </UiFlexRow>;
};

export const AthleteSearchModal: React.FC<IAthleteSearchModal> = (props) => {
  const {resultComponent, initialTerm, termParamName, children, ...rest} = props;

  return <AppSearchModal termParamName={termParamName || 'search'}
                         initialTerm={initialTerm || ''}
                         resultComponent={resultComponent || AthleteSearchResult}
                         {...rest}>
    {children}
  </AppSearchModal>;
}

