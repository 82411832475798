import React, {useMemo} from 'react';
import {
  IModalHookOptions,
  IUiModalRoute,
  toast,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiForm,
  UiFormField,
  UiPageSegment,
  UiState,
  UiTextBlock,
  UiTextLine,
  useModal,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {ClubCard} from './club-card.component';
import {AppTabNavigation} from '../../../app/common/misc/app-tab-navigation.component';
import {AppPageSectionPanel} from '../../../app/common/page/app-page-section-panel.component';
import {IRoute, useNavigator} from '../../../core/routing';
import {Route, Switch} from 'react-router';
import {
  useCreateClubMembershipInvitation,
  useDeleteMyClubMembershipInvitation,
  useFindClubById,
  useFindClubMembers
} from '../club.api';
import {IClub} from '../club.model';
import {ClubInvitationCTA} from './club-invitation-cta.component';
import {ClubOpeningHours} from './club-opening-hours.component';
import {Rooq} from '../../../core/domain.model';
import {isEmpty, isNil, isNonEmptyStr, update} from '@bitsolve/fns';
import {AccountListItem} from '../../auth/component/account/account-list-item.component';
import {addressLine} from '../../../app/common/misc/app-address';
import {useClubReportModal} from './club-report-modal.component';
import {useAuthUser} from '../../auth/auth.store';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';

export interface IClubModalCard {
  clubId: string;
  ownPath: string;
  onClose?: () => any;
  onUpdatePropagation?: Function;
}

const findTimesForDay = (openingHours: IClub['openingHours'], day: Rooq.ClubOpeningDay): Rooq.ClubOpeningTimeframe['times'] | undefined => {
  return openingHours.find(oh => (oh.days?.length || 0) > 0 && oh.days[0] === day)?.times;
};

const openingHourDays = [
  Rooq.ClubOpeningDay.MONDAY,
  Rooq.ClubOpeningDay.TUESDAY,
  Rooq.ClubOpeningDay.WEDNESDAY,
  Rooq.ClubOpeningDay.THURSDAY,
  Rooq.ClubOpeningDay.FRIDAY,
  Rooq.ClubOpeningDay.SATURDAY,
  Rooq.ClubOpeningDay.SUNDAY,
];

const clubInfoFields = [
  {
    name: 'name',
    fieldType: 'text',
    label: 'app.club.form.name.label'
  },
  {
    name: 'foundingYear',
    fieldType: 'text',
    label: 'app.club.form.foundingYear.label'
  },
  {
    name: 'customFightStyles',
    fieldType: 'text',
    label: 'app.club.form.fightStyles.label'
  },
  {
    name: 'memberCount',
    fieldType: 'number',
    label: 'app.club.form.memberCount.label'
  },
];

const clubContactFields = [
  {
    name: 'address',
    fieldType: 'text',
    label: 'app.club.form.address.label'
  },
  {
    name: 'phone',
    fieldType: 'text',
    label: 'app.club.form.phoneNumber.label'
  },
  {
    name: 'email',
    fieldType: 'text',
    label: 'app.club.form.email.label'
  },
];

const ClubModalCardInfo: React.FC<{ club: IClub; onRelationshipChange?: Function; }> = (props) => {
  const {club, onRelationshipChange} = props;

  const user = useAuthUser();
  const t = useTranslator();
  const infoFields = useTranslatedFields(clubInfoFields);
  const contactFields = useTranslatedFields(clubContactFields);

  const deleteInvitation = useDeleteMyClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.withdraw-membership-request.toast.success'));
      onRelationshipChange && onRelationshipChange();
    },
    () => toast.error(t('app.club.action.withdraw-membership-request.toast.error'))
  );
  const createInvitation = useCreateClubMembershipInvitation(
    () => {
      toast.success(t('app.club.action.request-membership.toast.success'));
      onRelationshipChange && onRelationshipChange();
    },
    () => toast.error(t('app.club.action.request-membership.toast.error'))
  );

  const openingHours = useMemo(
    () => openingHourDays.map(day => ({
      days: [day],
      times: findTimesForDay(club.openingHours, day) || []
    })),
    [club]
  );

  return <>
    <AppPageSectionPanel>
      <UiPageSegment>
        <UiForm value={club}
                interact={() => console.log('unreachable?')}
                fields={infoFields}>
          <UiFormField name={'name'}
                       direction={UiDir.v}
                       state={UiState.disabled}
                       className={'mg-b-nm'} />
          {club.foundingYear && <UiFormField name={'foundingYear'}
                                             direction={UiDir.v}
                                             state={UiState.disabled}
                                             className={'mg-b-nm'} />}
          {club.customFightStyles && <UiFormField name={'customFightStyles'}
                                                  direction={UiDir.v}
                                                  state={UiState.disabled}
                                                  className={'mg-b-nm'} />}
          {club.memberCount && <UiFormField name={'memberCount'}
                                            direction={UiDir.v}
                                            state={UiState.disabled}
                                            className={'mg-b-nm'} />}
        </UiForm>
      </UiPageSegment>
      <UiPageSegment>
        <ClubInvitationCTA clubId={club.id}
                           deleteInvitation={(status) => {
                             if (deleteInvitation.busy) return Promise.resolve();
                             return deleteInvitation
                               .send(status.relationId)
                               .catch(e => console.error(e));
                           }}
                           createInvitation={() => {
                             if (createInvitation.busy) return Promise.resolve();
                             return createInvitation
                               .send({
                                 accountId: user?.uid as any,
                                 clubId: club.id,
                                 invitedAs: Rooq.MembershipType.TRAINER
                               })
                               .catch(e => console.error(e));
                           }} />
      </UiPageSegment>
    </AppPageSectionPanel>
    {(club.address || club.phoneNumber || club.email) &&
    <AppPageSectionPanel title={{text: t('app.club.section.contact')}}
                         rounded={false}>
      <UiPageSegment>
        <UiForm value={update(club, 'address', addressLine)}
                interact={() => console.log('unreachable?')}
                fields={contactFields}>
          {club.address && <UiFormField name={'address'}
                                        direction={UiDir.v}
                                        state={UiState.disabled}
                                        className={'mg-b-nm'} />}
          {club.phoneNumber && <UiFormField name={'phoneNumber'}
                                            direction={UiDir.v}
                                            state={UiState.disabled}
                                            className={'mg-b-nm'} />}
          {club.email && <UiFormField name={'email'}
                                      direction={UiDir.v}
                                      state={UiState.disabled}
                                      className={'mg-b-nm'} />}
        </UiForm>
      </UiPageSegment>
    </AppPageSectionPanel>}
    {openingHours && <AppPageSectionPanel title={{text: t('app.club.section.opening-hours')}}
                                          rounded={false}>
      <UiPageSegment>
        <ClubOpeningHours items={openingHours as any} />
      </UiPageSegment>
    </AppPageSectionPanel>}
    {isNonEmptyStr(club.aboutUs) && <AppPageSectionPanel title={{text: t('app.club.section.about')}}
                                                         rounded={false}>
      <UiPageSegment>
        <UiTextBlock className="txt-sm"
                     text={club.aboutUs} />
      </UiPageSegment>
    </AppPageSectionPanel>}
  </>
};

const ClubModalCardAccounts: React.FC<{ clubId: string; membershipType: Rooq.MembershipType; }> = (props) => {
  const {clubId, membershipType} = props;

  const stepSize = 10;
  const t = useTranslator();
  const accReq = useFindClubMembers(clubId, membershipType, 0, stepSize, {initialFetch: true});
  const data = accReq.response?.data;
  const items = data?.elements;

  return <UiBox className="ui-fx ui-fx__pane-fade-in pd-sm">
    {!accReq.busy && accReq.lastFetchTime && (!items || isEmpty(items)) && <UiFlexRow ai={UiFlexAlign.c}
                                                                                      jc={UiFlexAlign.c}>
      <UiTextLine className="txt-sm txt-it txt-c-defd pd-v-nm txt-ac"
                  text={t('general.table.empty')} />
    </UiFlexRow>}
    {items && items.map((mship, i) => <AccountListItem key={`${i}.${mship.id}`}
                                                       account={mship.account} />)}
    {data && (data?.totalPages > 1) && (data?.number < (data?.totalPages - 1)) && <>
      <UiButton label={t('general.action.load-more')}
                state={accReq.busy ? UiState.disabled : UiState.active}
                color={UiColor.default}
                variation={UiButtonVariation.ghost}
                interact={() => {
                  accReq.paginate.setSize(data?.size + stepSize, true);
                }} />
    </>}
  </UiBox>;
};

export const ClubModalCard: React.FC<IClubModalCard> = (props) => {
  const {clubId, ownPath, onClose, onUpdatePropagation} = props;

  const t = useTranslator();
  const clubReq = useFindClubById(clubId, {initialFetch: true});
  const club = clubReq.response?.data;

  const reportModal = useClubReportModal({onClose: clubReq.load});
  const reportClub = (clubId: string) => {
    reportModal.setData({clubId});
    reportModal.open();
  };

  if (clubReq.busy) {
    return <UiFlexRow ai={UiFlexAlign.c} jc={UiFlexAlign.c}
                      className={'pd-nm'}>
      <AppLoadingSpinner />
    </UiFlexRow>
  } else if (!club) {
    return null;
  }

  return <UiFlexCol className="app-club__details-modal">
    <ClubCard club={club}
              menu={{
                items: [
                  {
                    label: t('app.club.action.report.label'),
                    icon: 'exclamationMark',
                    color: UiColor.warn,
                    interact: () => reportClub(club.id)
                  }
                ]
              }} />
    {onClose && <UiButton className={'app-club__details-modal__close'}
                          icon={'times'}
                          variation={UiButtonVariation.semantic}
                          color={UiColor.default}
                          interact={onClose} />}
    <AppTabNavigation items={[
      {
        label: t('app.club.section.info'),
        to: ownPath,
        exact: true
      },
      {
        label: t('app.club.section.athletes-short', {count: isNil(club.athleteCount) ? 0 : club.athleteCount}),
        to: `${ownPath}/athletes`,
      },
      {
        label: t('app.club.section.trainers-short', {count: isNil(club.trainerCount) ? 0 : club.trainerCount}),
        to: `${ownPath}/trainers`
      },
    ]} />
    <main className="app-club__details-modal__content">
      <Switch>
        <Route path={ownPath} exact
               render={() => <ClubModalCardInfo club={club}
                                                onRelationshipChange={onUpdatePropagation} />} />
        <Route path={`${ownPath}/athletes`}
               render={() => <ClubModalCardAccounts key={`${clubId}.${Rooq.MembershipType.ATHLETE}`}
                                                    clubId={clubId}
                                                    membershipType={Rooq.MembershipType.ATHLETE} />} />
        <Route path={`${ownPath}/trainers`}
               render={() => <ClubModalCardAccounts key={`${clubId}.${Rooq.MembershipType.TRAINER}`}
                                                    clubId={clubId}
                                                    membershipType={Rooq.MembershipType.TRAINER} />} />
      </Switch>
    </main>
    {reportModal.element}
  </UiFlexCol>;
};

export const ClubModalCardRoute: React.FC<IRoute & IUiModalRoute & { ownPath: string; backPath: string; }> = (props) => {
  const {match, ownPath, backPath, onUpdatePropagation} = props;
  const clubId = match?.params?.clubId;
  const nav = useNavigator();

  return <ClubModalCard clubId={clubId}
                        ownPath={ownPath}
                        onUpdatePropagation={onUpdatePropagation}
                        onClose={() => nav(backPath)} />;
};

export const useClubModalCard = (ownPath: string, options?: Partial<Omit<IModalHookOptions, 'content'>>) => {
  const modal = useModal({
    content: context => {
      const id = context?.data?.id;
      return id
        ? <ClubModalCard clubId={id} ownPath={ownPath} />
        : null;
    },
    ...options
  });

  return {
    ...modal,
    open: (id: string) => {
      modal.setData({id});
      modal.open();
    }
  };
};
