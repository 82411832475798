import {AppLogoutRoute} from '../../onboarding/routes/login/app-logout-route.component';
import {CoachingZoneAnalysisDetailsRoute} from './analysis/coaching-zone-analysis-details-route.component';
import {CoachingZoneAnalysisRoute} from './analysis/coaching-zone-analysis-route.component';
import {CoachingZoneAthletesRoute,} from './athletes/coaching-zone-athletes-route.component';
import {CoachingZoneClubsRoute} from './club/coaching-zone-clubs-route.component';
import {CoachingZoneCreateClubRoute} from './club/coaching-zone-create-club-route.component';
import {CoachingZoneDashboardRoute} from './dashboard/coaching-zone-dashboard-route.component';
import {CoachingZoneNavigation} from './coaching-zone-navigation.component';
import {CoachingZoneSettingsRoute} from './settings/coaching-zone-settings-route.component';
import {CoachingZoneTrainingPlanCreateRoute} from './training-plan/coaching-zone-training-plan-create-route.component';
import {CoachingZoneTrainingPlanEditRoute} from './training-plan/coaching-zone-training-plan-edit-route.component';
import {CoachingZoneTrainingPlanRoute} from './training-plan/coaching-zone-training-plan-route.component';
import {ShopSubscriptionCallbackRoute} from './internal/shop-subscription-callback-route.component';
import {IRoute} from '../../../core/routing';
import {Redirect} from 'react-router';
import React from 'react';


export const coachingZoneRoutes = [
  {
    path: '/portal/trainer',
    exact: true,
    component: CoachingZoneDashboardRoute
  },
  // routes shared between trainer/club perspectives:
  {
    path: '/portal/analysis',
    exact: true,
    component: CoachingZoneAnalysisRoute
  },
  {
    path: '/portal/analysis/trainings',
    exact: true,
    component: CoachingZoneAnalysisRoute
  },
  {
    path: '/portal/analysis/compare',
    component: CoachingZoneAnalysisDetailsRoute
  },
  {
    path: '/portal/training-plan/create',
    component: CoachingZoneTrainingPlanCreateRoute
  },
  {
    path: '/portal/training-plan/details/:planId',
    component: CoachingZoneTrainingPlanEditRoute
  },
  {
    path: '/portal/training-plan',
    component: CoachingZoneTrainingPlanRoute
  },
  // routes for trainer perspective only:
  {
    path: '/portal/trainer/athletes',
    component: CoachingZoneAthletesRoute
  },
  {
    path: '/portal/trainer/clubs/create',
    component: CoachingZoneCreateClubRoute
  },
  {
    path: '/portal/trainer/clubs',
    component: CoachingZoneClubsRoute
  },
  {
    path: '/portal/trainer/settings',
    component: CoachingZoneSettingsRoute
  },
  // routes for club perspective:
  // {
  //   path: '/portal/club/:clubId/athletes',
  //   component: CoachingZoneClubAthletesRoute
  // },
  // {
  //   path: '/portal/club/:clubId/trainers',
  //   component: CoachingZoneClubTrainersRoute
  // },
  // {
  //   path: '/portal/club/:clubId/settings',
  //   component: CoachingZoneManageClubRoute
  // },
  // ---
  {
    path: '/portal/shop/apply-subscription',
    component: ShopSubscriptionCallbackRoute,
  },
  {
    path: '/logout',
    component: AppLogoutRoute
  },
  // redirect for old /app routes so links from issues/communication still work
  {
    path: '/app/*',
    component: (props: IRoute) => {
      const redirectPath = props.location.pathname.replace('/app', '/portal');
      return <Redirect from={'/app'} to={redirectPath} />
    }
  }
];


export const coachingZoneNavRoutes = [
  {
    path: '/*',
    component: CoachingZoneNavigation
  }
];
