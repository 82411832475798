import React, {useMemo} from 'react';
import {
    classNames,
    UiBox,
    UiButton,
    UiButtonVariation,
    UiFlexAlign,
    UiFlexRow,
    UiIcon,
    UiTextBlock,
    UiTextTitle,
    useTranslator
} from '@bitsolve/react-common';
import {useNotificationCenterVisibility} from '../../../app/common/misc/app-prefabs';
import {Rooq} from '../../../core/domain.model';
import {NotificationConnector} from './notification-connector.component';
import {useSocketContext} from '../../socket/socket-connection.context';
import {markNotificationAsRead, removeNotification, useNotifications} from '../notification.store';
import {useDispatch} from 'react-redux';
import {hasProp, propIn} from '@bitsolve/fns';

export interface IAppNotificationCenter {
    messagesDestination: string;
    accountIdParameter?: string;
}

const useNotificationTranslation = (notification: Rooq.Notification, key: 'title' | 'body'): string | null => {
    const t = useTranslator();

    return useMemo(
        () => {
            return hasProp(notification, key)
                ? t(propIn(notification, [key, 'key']), propIn(notification, [key, 'params']))
                : null;
        },
        [t, notification, key]
    );
};

const AppNotification: React.FC<{ notification: Rooq.Notification; index: number; }> = (props) => {
    const {notification} = props;

    const t = useTranslator();
    const dispatch = useDispatch();
    const title = useNotificationTranslation(notification, 'title');

    if (!title) {
        return null;
    }

    return <UiBox className={classNames('app-notification', notification.seen && 'app-notification--seen')}
                  interact={() => dispatch(notification.seen
                      ? removeNotification(notification.id)
                      : markNotificationAsRead(notification.id))}>
        <UiBox className="f-1 pd-r-xs d-f fd-v">
            <UiTextTitle className="app-notification__title"
                         style={{maxWidth: '100%'}}
                         text={title}/>
            <UiFlexRow ai={UiFlexAlign.c}
                       className={'app-notification__action txt-c-defd'}
                       style={{display: 'flex'}}>
                {notification.seen && <UiIcon iconSet={'solid'} name={'trash'}/>}
                <UiTextBlock text={t(notification.seen
                    ? 'app.notification.action.hide.label'
                    : 'app.notification.action.mark-seen.label')}/>
            </UiFlexRow>
        </UiBox>
    </UiBox>
};


export const NotificationCenter: React.FC<IAppNotificationCenter> = (props) => {
    const {messagesDestination, accountIdParameter = 'account-id'} = props;

    const t = useTranslator();
    const {visible, setVisible} = useNotificationCenterVisibility();
    const socketContext = useSocketContext();
    const notifications = useNotifications();


    const sortByTimestamp = (notifications: any) => {
        const notificationsSorted =
            notifications.sort(function (firstDate: any, secondDate: any) {
                return new Date(secondDate.timestamp).getTime() - new Date(firstDate.timestamp).getTime();
            })

        return notificationsSorted;
    }

    return <section className={classNames('app-notification-center', visible && 'app-notification-center--visible')}>
        <header className={'app-notification-center__header'}>
            <UiTextTitle headingLevel={3}
                         className={'f-1'}
                         style={{fontSize: '1.25em'}}
                         text={t('app.notification.title')}/>
            <UiButton variation={UiButtonVariation.semantic}
                      iconSet={'regular'}
                      icon={'times'}
                      style={{fontSize: '1.325rem'}}
                      interact={() => setVisible(false)}/>
        </header>
        <main className={'app-notification-center__notification-list ui-fx ui-fx__pane-fade-in'}>
            {(!notifications || notifications.length === 0) && <UiBox className={'pd-h-nm pd-t-nm'}>
                <UiTextBlock text={t('app.notification.empty')}
                             className={'txt-sm txt-c-defd txt-it'}/>
            </UiBox>}
            {sortByTimestamp(notifications).map((notification: any, i: number) => <AppNotification key={notification.id}
                                                                                                   index={i}
                                                                                                   notification={notification}/>)}
        </main>
        {socketContext && <NotificationConnector socketContext={socketContext}
                                                 messagesDestination={messagesDestination}
                                                 accountIdParameter={accountIdParameter}/>}
    </section>;
};
