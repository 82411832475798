import React, {useMemo, useRef} from 'react';
import {useFindStaticTrainingPlanDisciplines} from '../../content/content.api';
import {classNames, UiBox, UiCustomizable, UiInteractive, UiState, useTranslator} from '@bitsolve/react-common';
import {AppDisciplineIcon} from '../../../app/common/misc/app-discipline-icon.component';
import {DragSourceMonitor, useDrag, useDrop} from 'react-dnd';
import {byDisciplinePriority, disciplineColor, Rooq} from '../../../core/domain.model';
import {range} from '@bitsolve/fns';

export interface ITrainingPlanFormDiscipline extends UiCustomizable, UiInteractive<string> {
}


export const TrainingPlanFormDisciplineCard: React.FC<{
  discipline: Rooq.Discipline;
  index?: number;
  interact: (d: Rooq.Discipline) => any;
  state?: UiState;
} & UiCustomizable> = (props) => {
  const {discipline, interact, index = 0, className, style} = props;
  const state = props?.state || UiState.active;

  const t = useTranslator();
  const ref = useRef<HTMLDivElement>();

  // eslint-disable-next-line
  const [{isDragging}, drag, preview] = useDrag({
    previewOptions: {anchorY: -25},
    item: {type: 'discipline', id: discipline, index},
    collect: (monitor: DragSourceMonitor) => ({isDragging: monitor.isDragging()})
  });

  preview(drag(ref));
  const cls = className + '__container';

  return <div key={discipline}
              ref={ref as any}
              className={classNames(
                cls,
                state !== UiState.active && cls + '--disabled',
                isDragging && cls + '--dragging'
              )}
              style={style}
              onClick={state === UiState.active
                ? () => interact(discipline)
                : undefined}>
    <div style={{
      backgroundImage: `url(/asset/brand/discipline-mood/${discipline}.png)`,
      boxShadow: `inset 0 0 1.5px ${disciplineColor(discipline)}`,
    }}
         className={classNames('app-training-plan__discipline-card__item', className)}>
      <AppDisciplineIcon discipline={discipline}
                         className={'pd-xs mg-b-sm'}
                         size={3.125} />
      <span className={'txt-sm txt-b'}>{t(`content.discipline.${discipline}`)}</span>
    </div>
  </div>;
}

const disciplinesInitPlaceholder = <>
  {range(0, 4).map((_, i) =>
    <UiBox key={i}
           className={'app-training-plan__disciplines-grid__item__container empty'} />)}
</>;

export const TrainingPlanFormDisciplinesGrid: React.FC<ITrainingPlanFormDiscipline & {
  state?: UiState;
}> = (props) => {
  const {interact, className, style, state} = props;

  const discsReq = useFindStaticTrainingPlanDisciplines({initialFetch: true});
  const discs = discsReq?.response?.data?.values;
  const _discs = useMemo(() => discs?.sort(byDisciplinePriority), [discs]);

  // eslint-disable-next-line
  const [{isOver}, drop] = useDrop({
    accept: 'discipline',
    collect: (monitor) => ({isOver: monitor.isOver({shallow: true})})
  });

  return <UiBox className={classNames('app-training-plan__disciplines-grid', className)}
                style={style}>
    {discsReq.busy
      ? disciplinesInitPlaceholder
      : _discs?.map((discipline, index) => {
        return <TrainingPlanFormDisciplineCard key={discipline}
                                               index={index}
                                               discipline={discipline as any}
                                               className={'app-training-plan__disciplines-grid__item'}
                                               state={state}
                                               interact={interact} />
      })}
  </UiBox>;
};

