import React, {useMemo} from 'react';
import {UiFlexAlign, UiFlexCol, UiIcon, UiTextLine, useTranslator} from '@bitsolve/react-common';

export interface IAnalysisPunchType {
  punchType: string;
}

const punchTypeIcon = (punchType: string) => {
  switch (punchType?.toUpperCase()) {
    case 'HOOK':
      return 'appPunchHook';
    case 'STRAIGHT':
      return 'appPunchStraight';
    case 'BODY_SHOT':
      return 'appPunchBodyShot';
    case 'UPPERCUT':
      return 'appPunchUppercut';
  }
};

export const AnalysisPunchType: React.FC<IAnalysisPunchType> = (props) => {
  const {punchType} = props;
  const t = useTranslator();
  const icon = useMemo(() => punchTypeIcon(punchType), [punchType]);

  return <UiFlexCol ai={UiFlexAlign.c}>
    {icon && <UiIcon name={icon as any}
                     iconSet={'regular'}
                     className={'txt-lg mg-b-sm'} />}
    <UiTextLine className={'txt-sm txt-c-defd'}
                text={t(`content.punchType.${punchType}`)} />
  </UiFlexCol>;
};
