import {
  IConnectivityGuardItem,
  IConnectivityHealthCheck,
  startMaintenanceChecker,
  stopMaintenanceChecker
} from '../connectivity.store';
import React, {useEffect} from 'react';
import {Connectivity} from '../connectivity.model';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';
import {AppSplashScreen, AppSplashScreenContent} from '../../../app/common/misc/app-splash-screen.component';
import {UiTextTitle} from '@bitsolve/react-common';
import {useDispatch} from 'react-redux';

const useMaintenanceCheckInterval = (id: string, opts: IConnectivityHealthCheck) => {
  const d = useDispatch();
  useEffect(() => {
    d(startMaintenanceChecker(id, opts.url, opts.timeout));

    return () => {
      d(stopMaintenanceChecker(id));
    };
  }, [d, id, opts]);
};

const MaintenanceUnknown: React.FC<IConnectivityGuardItem> = (props) => {
  useMaintenanceCheckInterval('maintenance/unknown', props);

  return <AppSplashScreen dim center>
    <AppSplashScreenContent>
      <AppLoadingSpinner />
    </AppSplashScreenContent>
  </AppSplashScreen>;
};

const MaintenanceUnreachable: React.FC<IConnectivityGuardItem> = (props) => {
  useMaintenanceCheckInterval('maintenance/unreachable', props);

  return <AppSplashScreen>
    <UiTextTitle text={'maintenance/unreachable'} />
  </AppSplashScreen>;
};


const MaintenanceReachable: React.FC<IConnectivityGuardItem> = (props) => {
  const {children, maintenance} = props;

  useMaintenanceCheckInterval('maintenance/reachable', props);

  const fVer = maintenance?.fileVersion;
  const notifs = maintenance?.notifications;
  useEffect(() => {
    console.log(fVer, notifs);
  }, [fVer, notifs])

  return <React.Fragment key={`${maintenance.connectivity}`}>
    {children}
  </React.Fragment>;
};

export const maintenanceHealthGuards = {
  [Connectivity.UNKNOWN]: MaintenanceUnknown,
  [Connectivity.UNREACHABLE]: MaintenanceUnreachable,
  [Connectivity.REACHABLE]: MaintenanceReachable,
};
