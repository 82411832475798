import React, {useMemo} from 'react';
import {IUiFieldProps, UiBox, UiDir, UiFlexRow, UiForm, UiFormField, useTranslatedFields} from '@bitsolve/react-common';
import {Rooq} from '../../../core/domain.model';
import {staticCountriesControlProps} from '../../content/content.api';
import {ValidationError} from 'yup';


export interface IUiAddressFieldControl extends IUiFieldProps<Rooq.Address> {
  error?: any;
}

const addressFields = [
  {
    name: 'street',
    fieldType: 'text',
    label: 'general.address.street.label'
  },
  {
    name: 'houseNumber',
    fieldType: 'text',
    label: 'general.address.houseNumber.label'
  },
  {
    name: 'city',
    fieldType: 'text',
    label: 'general.address.city.label'
  },
  {
    name: 'zipCode',
    fieldType: 'text',
    label: 'general.address.zipCode.label'
  },
  {
    name: 'country',
    fieldType: 'content-select',
    label: 'general.address.country.label',
    controlProps: staticCountriesControlProps
  }
];

export const UiAddressFieldControl: React.FC<IUiAddressFieldControl> = (props) => {
  const {name, value, onChange, error, className, style} = props;

  const _error = useMemo(() => {
    const errors = error?.inner?.map((err: ValidationError) => {
      return new ValidationError(
        err.message,
        err.value,
        err.path?.replace(`${name}.`, ''),
        err.type
      );
    });

    return (error && (errors?.length > 0))
      ? new ValidationError(errors, value, undefined)
      : null;
  }, [error, value, name]);

  const fields = useTranslatedFields(addressFields);

  return <UiBox className={className}
                style={style}>
    <UiForm tag={'address'}
            value={value}
            fields={fields}
            error={_error}
            onChange={onChange}
            interact={() => {
            }}>
      <UiFlexRow className={'mg-b-nm'}>
        <UiFormField name={'street'}
                     direction={UiDir.v}
                     className={'f-3'} />
        <UiFormField name={'houseNumber'}
                     direction={UiDir.v}
                     className={'f-1 mg-l-nm'} />
      </UiFlexRow>
      <UiFlexRow>
        <UiFormField name={'zipCode'}
                     direction={UiDir.v}
                     className={'f-1 mg-r-nm'} />
        <UiFormField name={'city'}
                     direction={UiDir.v}
                     className={'f-2 mg-r-nm'} />
        <UiFormField name={'country'}
                     key={'address.country'}
                     direction={UiDir.v}
                     className={'f-2'} />
      </UiFlexRow>
    </UiForm>
  </UiBox>;
};
