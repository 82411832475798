import React, {useMemo} from 'react';
import {useNavigator} from '../../../core/routing';
import {isNil, isNotNil, prop, randInt} from '@bitsolve/fns';
import {
  classNames,
  IUiButton,
  toast,
  UiBox,
  UiButtonGroup,
  UiColor,
  UiCustomizable,
  UiFlexAlign,
  UiFlexRow,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {UiSplashMessage} from '../../ui/component/ui-splash-message.component';
import {AthleteStatCard} from '../../auth/component/athlete/athlete-stat-card.component';
import {ShopTokenType, useClubSeats, useGenerateShopAccess, useShopRedirect} from '../../shop/shop.api';
import {useAuthAccount} from '../../auth/auth.store';
import {ShopFreeSeatsCard} from '../../shop/component/shop-free-seats-card.component';

export interface IClubAthleteCardGrid extends UiCustomizable {
  clubId: string;
  title?: string;
  actions?: Array<IUiButton | undefined | null | false>;
  perRow?: number;
}


export const ClubAthleteCardGrid: React.FC<IClubAthleteCardGrid> = (props) => {
  const {clubId, perRow = 3, title, actions, style, className} = props;

  const {all, free, taken} = useClubSeats(clubId);

  const t = useTranslator();
  const nav = useNavigator();
  const stats = useMemo(() => taken?.map(() => ([
    {value: randInt(16, 50), label: 'Age'},
    {value: randInt(1100, 3000), label: 'Highest score'},
  ])), [taken]);

  const acc = useAuthAccount();
  const generateAccess = useGenerateShopAccess(ShopTokenType.TRAINER);
  const goToShop = useShopRedirect();

  if (isNil(all) || all?.length === 0) {
    return <UiSplashMessage title={t('app.club.action.shop.cta.title')}
                            messages={[t('app.club.action.shop.cta.message')]}
                            actions={[
                              {
                                label: t('app.club.action.shop.action.book-seats.label'),
                                color: UiColor.accent,
                                className: 'txt-b',
                                interact: () => goToShop()
                              }
                            ]} />;
  }

  return <>
    {(title || actions) && <UiFlexRow ai={UiFlexAlign.c}>
      {title && <UiTextTitle text={title}
                             headingLevel={2}
                             className={'mg-b-nm f-1'} />}
      {actions && <UiButtonGroup actions={actions.filter(a => !!a) as IUiButton[]} />}
    </UiFlexRow>}
    <UiBox className={classNames('d-g', className)}
           style={{
             gridGap: 'var(--size-geo--md)',
             ...style,
             gridTemplateColumns: `repeat(${perRow}, minmax(min-content, 1fr))`,
             gridTemplateRows: `repeat(${Math.ceil((taken?.length || 0) / perRow)}, min-content)`,
           }}>
      {(taken || [])
        .filter(seat => isNotNil(seat.athlete))
        .map((seat, i) =>
          <AthleteStatCard key={`${i}.${seat.id}`}
                           account={seat.athlete as any}
                           isFavorite={false}
                           stats={prop(stats, i)}
                           interact={() => nav(`/portal/club/${clubId}/athletes/details/${seat?.athlete?.id}`)} />)}
      <ShopFreeSeatsCard
        interact={() => nav(`/portal/club/${clubId}/athletes/seats/search`)}
        freeSeats={free?.length}
        configureSeats={{
          interact: () => acc?.id && generateAccess.send(acc.id)
            .then((res) => {
              toast.success(t('app.shop.action.manage-abo.toast.success'));
              goToShop(res.data.token);
            })
            .catch(() => toast.error(t('app.shop.action.manage-abo.toast.error')))
        }} />
    </UiBox>
  </>;
};
