import React, {useMemo} from 'react';
import {classNames, UiBox, UiFlexAlign, UiFlexRow, UiTextLine} from '@bitsolve/react-common';
import {ParentSize} from '@visx/responsive';
import {AreaClosed, LinePath} from '@visx/shape';
import {scaleLinear} from '@visx/scale';
import {max, min, sum} from 'd3-array';
import {Group} from '@visx/group';
import {Axis, Orientation} from '@visx/axis';
import {GridColumns} from '@visx/grid';
import {LinearGradient} from '@visx/gradient';
import {AnalysisSessionAthleteLabel} from '../analysis-session-athlete-label.component';
import {trainingSessionColor} from '../../../../core/domain.model';
import {useRooqStyle} from '../../../../style';
import {animated, useSpring, useTrail} from 'react-spring';

export interface IMultiLineChartData {
  section: number;
  value: number;
}

export interface IMultiLineChartDataset {
  label?: string;
  data: IMultiLineChartData[];
}

export interface IMultiLineChart {
  data: IMultiLineChartDataset[];
  unitX?: string;
  unitY?: string;
}

const getX = (d: IMultiLineChartData): number => d.section;
const getY = (d: IMultiLineChartData): number => d.value;

// @todo move to rooq style export obj
export const background = '#045275';
export const backgroundLight = '#089099';
export const foreground = '#94ADC1';
export const areaGradient = 'rgba(46, 67, 85, 1)';
export const areaGradientHot = 'rgba(97, 35, 58, 1)';


const useAnimatedLinePathCalc = (data: IMultiLineChartDataset['data'],
                                 width: number,
                                 height: number,
                                 scaleFactorY: number,
                                 scaleFactorX: number,) =>
  useMemo(() => {
    const xScale = scaleLinear<number>({
      range: [0, width],
      domain: [0, scaleFactorX as number],
      nice: true,
    });

    const off = height - (scaleFactorY * height);
    const yScale = scaleLinear<number>({
      range: [scaleFactorY * height, 0],
      domain: [
        min(data, getY) as number,
        max(data, getY) as number
      ],
    });

    const dx = (d: IMultiLineChartData) => xScale(getX(d)) ?? 0;
    const dy = (d: IMultiLineChartData) => off + (yScale(getY(d)) ?? 0);

    return {xScale, yScale, dx, dy};
  }, [data, width, height, scaleFactorY, scaleFactorX]);

const areaStyle = {strokeWidth: 1, stroke: 'url(#area-gradient)', fill: 'url(#area-gradient)'};

const AnimatedLinePathDot: React.FC<{ cx: number; cy: number; fill: string; r: number; }> = (props) => {
  const {r, ...rest} = props;

  const anim = useSpring({r, from: {r: 0},});

  return <>
    <animated.circle {...rest} {...anim} strokeWidth={0} />
  </>;
};

const AnimatedLinePath: React.FC<{
  data: IMultiLineChartDataset;
  style: any;
  index: number;
  scaleFactorX: number;
  scaleFactorY: number;
  width: number;
  height: number;
}> = (props) => {
  const {data: {data}, style, width, height, scaleFactorY = 1, scaleFactorX = 1, index} = props;
  const rooqStyle = useRooqStyle();
  const {dx, dy, yScale} = useAnimatedLinePathCalc(data, width, height, scaleFactorY, scaleFactorX);
  const last = data[data.length - 1];

  return <animated.g key={index}
                     className={'segments'}
                     style={style}>
    {scaleFactorY === 1
    && <AreaClosed data={data}
                   x={dx}
                   y={dy}
                   yScale={yScale}
                   {...areaStyle} />}
    <LinePath data={data}
              x={dx}
              y={dy}
              fill={'transparent'}
              strokeWidth={7}
              stroke={rooqStyle.rooq_blue}
              className={classNames(
                'segments__line',
                'app-analysis__chart--multi-line__line'
              )} />
    <AnimatedLinePathDot cx={dx(last)}
                         cy={dy(last)}
                         fill={rooqStyle.rooq_blue}
                         r={6.5} />
    <LinePath data={data}
              x={dx}
              y={dy}
              fill={'transparent'}
              strokeWidth={3}
              className={classNames(
                'segments__line',
                'app-analysis__chart--multi-line__line',
                `app-analysis__chart--multi-line__line--color-${index}`
              )} />
    <AnimatedLinePathDot cx={dx(last)}
                         cy={dy(last)}
                         fill={trainingSessionColor(rooqStyle, index)}
                         r={4} />
  </animated.g>;
}

const getLineScaleFactorY = (lines: IMultiLineChart['data'], maxY: number, index: number): number => {
  const l = lines[index];
  const m = max(l.data.map(d => d.value)) || 0;
  return Math.fround(1 / maxY * m);
}
// const getLineScaleFactorX = (lines: IMultiLineChart['data'], maxX: number, index: number): number => {
//   const l = lines[index];
//   const m = sum(l.data.map(d => d.section));
//   return Math.fround(1 / maxX * m);
// }


export const MultiLineChart: React.FC<IMultiLineChart> = (props) => {
  const {data, unitX, unitY} = props;
  const style = useRooqStyle();

  const trail = useTrail(data.length, {
    opacity: 1,
    transform: 'translateY(0%) scaleY(1)',
    from: {
      opacity: 0,
      transform: 'translateY(7.5%) scaleY(0.75)'
    },
    config: {
      mass: 1,
      tension: 820,
      friction: 50,
      delay: 35,
    },
  });

  return <ParentSize debounceTime={32}>
    {bounds => {
      const width = Math.floor(bounds.width);
      const height = 290;
      const maxY = Math.round(max(data, d => max(d.data, getY)) as number);
      const maxX = Math.round(max(data, d => max(d.data, getX)) as number);
      const maxSumX = Math.round(max(data, d => sum(d.data, getX)) as number);

      const xScale = scaleLinear<number>({
        range: [0, width],
        domain: [0, maxSumX],
        nice: true,
      });

      const gridScale = scaleLinear<number>({
        range: [0, width],
        domain: [0, maxY],
      });

      return <UiBox className="app-analysis__chart app-analysis__chart--multi-line">
        {unitX && <UiFlexRow jc={UiFlexAlign.e}
                             className={'txt-c-defd txt-sm'}
                             style={{
                               float: 'left',
                               transformOrigin: 'left top 0',
                               transform: 'rotate(-90deg) translate3d(-100%, 0, 0)'
                             }}>
          <UiTextLine className={'mg-r-xs'} text={unitY} />
          <UiTextLine className={'txt-b'} text={`${maxY}`} />
        </UiFlexRow>}
        <svg width={width} height={height}
             viewBox={`0 0 ${width} ${height}`}
             style={{shapeRendering: 'geometricPrecision', paddingLeft: '2.5rem'}}>
          <defs>
            <linearGradient gradientUnits="userSpaceOnUse"
                            id={'gridColGradient'}
                            x1={0} x2={0} y1={0} y2={height}>
              <stop stopColor={'rgba(28, 47, 63,0)'} offset={0} />
              <stop stopColor={'rgba(28, 47, 63,1)'} offset={1} />
            </linearGradient>
          </defs>
          <LinearGradient id="area-gradient" from={areaGradient} to={areaGradient} toOpacity={0.05} />
          <LinearGradient id="area-gradient-hot" from={areaGradientHot} to={areaGradientHot} toOpacity={0.05} />
          <Group top={height}>
            <Axis scale={xScale}
                  stroke={'#283A49'}
                  strokeWidth={1}
                  orientation={Orientation.bottom} />
          </Group>
          <Group top={0}
                 className={'grid'}
                 style={{
                   // transformOrigin: 'center',
                   // transform: 'scale(0.9)'
                 }}>
            <GridColumns
              scale={gridScale}
              height={height}
              numTicks={6}
              stroke={'url(#gridColGradient)'}
              strokeWidth={2}
              strokeOpacity={1}
              pointerEvents="none"
            />
            {trail
              .map((animation, index) => ({
                animation,
                scaleX: maxX,
                scaleY: getLineScaleFactorY(data, maxY, index),
                data: data[index],
              }))
              // .sort((a, b) => a.scale > b.scale ? 1 : -1)
              .map(({animation, scaleY, scaleX, data}, i) => {
                return <AnimatedLinePath key={i}
                                         data={data}
                                         style={animation}
                                         index={i}
                                         scaleFactorY={scaleY}
                                         scaleFactorX={scaleX}
                                         height={height}
                                         width={width} />
              })}
          </Group>
        </svg>
        {unitX && <UiFlexRow jc={UiFlexAlign.e}
                             className={'txt-c-defd txt-sm'}>
          <UiTextLine className={'mg-r-xs'} text={unitX} />
          <UiTextLine className={'txt-b'} text={`${maxX}`} />
        </UiFlexRow>}
        <UiFlexRow className="app-analysis__chart--multi-line__legend">
          <UiBox className={'app-analysis__chart--multi-line__legend__item'}>
            {data.map((d, i) => {
              const color = trainingSessionColor(style, i);
              return <AnalysisSessionAthleteLabel key={i}
                                                  color={color}
                                                  label={d.label || '–'} />;
            })}
          </UiBox>
        </UiFlexRow>
      </UiBox>;
    }}
  </ParentSize>;
};
