import {Rooq} from '../../core/domain.model';

/* eslint-disable @typescript-eslint/no-unused-vars */
export interface INotification<T = any> extends Rooq.Notification {
}

export interface INotificationUpdate {
  seen: boolean;
}

export const compareNotifications = (a: INotification, b: INotification) => {
  if (a.id === b.id)
    return 0;

  return a.id > b.id ? -1 : 1;
};
