import React, {useMemo} from 'react';
import ReactSelect from 'react-select';
import {IUiSelectControl, selectControlStyles} from './ui-select-control';
import {classNames, UiState} from '@bitsolve/react-common';
import {dissoc, isNotNil} from '@bitsolve/fns';


export const UiSelectControl: React.FC<IUiSelectControl> = (props) => {
  const {controlProps, options, value, onChange, className, busy, state, ...rest} = props;

  const _options = options || controlProps?.options;
  const _styles = useMemo(() => selectControlStyles(props as any), [props]);
  const _selected = useMemo(() => {
    return _options?.find(opt => value === opt.value);
  }, [_options, value]);

  return <ReactSelect options={_options}
                      {...dissoc(rest, 'label', 'placeholder')}
                      className={classNames(className, controlProps?.className)}
                      classNamePrefix={`form-field__select`}
                      styles={_styles}
                      value={_selected}
                      isLoading={!!busy}
                      isDisabled={!!busy || (isNotNil(state) && state !== UiState.active)}
                      onChange={option => {
                        onChange && onChange(option.value);
                      }}
                      defaultMenuIsOpen={false} />;
};
