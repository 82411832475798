import React, {useEffect, useRef} from 'react';
import {animated} from 'react-spring';
import {
  classNames,
  IUiButton,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiCustomizable,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiIcon,
  UiInteractive,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../app/common/misc/app-banner-pane.component';
import {isNil} from '@bitsolve/fns';
import ReactTooltip from 'react-tooltip';
import {Rooq, useShouldShowTour} from '../../../core/domain.model';
import {ArrowSide, UiTourTooltip} from '../../ui/component/ui-tour-tooltip.component';

export interface IShopFreeSeatExplainerCard extends UiCustomizable, Partial<UiInteractive> {
}

export interface IShopTrialSeatCard extends UiCustomizable, Partial<UiInteractive> {
  configureSeats?: Partial<IUiButton>;
}

export interface IShopBuySeatsCard extends UiCustomizable, Partial<UiInteractive> {
  configureSeats?: Partial<IUiButton>;
}

export interface IShopFreeSeatsCard extends UiCustomizable, Partial<UiInteractive> {
  freeSeats?: number;
  configureSeats?: Partial<IUiButton>;
}

const graphic = `data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjg4IDE4MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjE0NCIgeDI9IjE0NCIgeTE9IjAiIHkyPSIxODAiPjxzdG9wIG9mZnNldD0iMCIgc3RvcC1jb2xvcj0iIzBhMWYzMCIgc3RvcC1vcGFjaXR5PSIwIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMGExZjMwIi8+PC9saW5lYXJHcmFkaWVudD48ZyBvcGFjaXR5PSIuNzUiPjxwYXRoIGQ9Im0wIDVjMC0yLjc2MTQyIDIuMjM4NTgtNSA1LTVoMjc4YzIuNzYxIDAgNSAyLjIzODU4IDUgNXYxNzVoLTI4OHoiIGZpbGw9IiMwYTFmMzAiLz48cGF0aCBkPSJtMCA1YzAtMi43NjE0MiAyLjIzODU4LTUgNS01aDI3OGMyLjc2MSAwIDUgMi4yMzg1OCA1IDV2MTc1aC0yODh6IiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii4xIi8+PC9nPjxwYXRoIGQ9Im0wIDVjMC0yLjc2MTQyIDIuMjM4NTgtNSA1LTVoMjc4YzIuNzYxIDAgNSAyLjIzODU4IDUgNXYxNzVoLTI4OHoiIGZpbGw9InVybCgjYSkiIG9wYWNpdHk9Ii43NSIvPjxnIHN0cm9rZT0iI2ZmZiI+PHBhdGggZD0ibTEwOC41MzMgMTA4LjA0N2MwLTcuNDgxIDUuNzQ3LTEyLjY5MTQgMTMuOTc0LTEyLjY5MTRtMTcuODM2LS4yNDAxYzguMDk5IDAgMTMuOTc0IDUuNDMwNSAxMy45NzQgMTIuOTMxNSIvPjxwYXRoIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0ibTEzMS4zNjQgOTIuOTg0aC0uMzg0Yy00LjkzOCAwLTEwLjkxOC0zLjc2NjEtMTEuNDg5LTguMjkzMi0xLjcwMS0uNzM4OC0xLjY5OS0zLjAxMDctMS42OTktNC43MjY2di0yLjQzOTljMC0xLjA3MTMgMC0yLjc1MDIgMi4wNTItMy4xNTg0di0zLjA4NDZjMC0zLjg3ODggMi4zOTUtNi41MDE2IDUuOTgtNi41NzM2LjcxMy0uMjIzNSAxLjQ1Ni0uNDgyMSAyLjIxMy0uNzM4OCAyLjY4OC0uOTA2OSA1LjQ2Ny0xLjg0ODkgNy45NDYtMS44NDg5IDQuMjcxIDAgNi40MzYgMi44NDYzIDYuNDM2IDguNDc3OXY0LjEwMDRjLjc2NC40ODAzIDIuMDUyIDEuNDk2MSAyLjA1MiAyLjgyNnYyLjMwODhjMCAxLjc1NjUgMCA0LjIyOTctMS44NTUgNC44OTQ2LS41ODEgNC42Mzc5LTYuMzk1IDguMjU2My0xMS4yNTIgOC4yNTYzeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PHBhdGggZD0ibTE3Mi4xNjcgNDcuMTc5OXYyNC45bTEzLjgzMy0xMi40NWgtMjcuNjY3Ii8+PC9nPjwvc3ZnPg==`;

export const ShopFreeSeatExplainerCard: React.FC<IShopFreeSeatExplainerCard> = (props) => {
  const {className, style, interact} = props;
  const t = useTranslator();

  return <animated.section className={classNames('app-shop-free-seat-explainer-card', className)}
                           style={style}>
    <main className="app-shop-free-seat-explainer-card__content f-1 d-f fd-v"
          style={{borderRadius: '0.25em'}}>
      <UiFlexCol className="f-1 pd-h-sm"
                 jc={UiFlexAlign.c}
                 ai={UiFlexAlign.c}>
        <UiTextTitle
          headingLevel={3}
          className={'txt-ac'}
          text={t('app.shop.seats.free-explainer.title')} />
        <UiTextBlock
          breathe
          className={'pd-t-nm txt-sm txt-c-defd txt-ac'}
          text={t('app.shop.seats.free-explainer.message')} />
      </UiFlexCol>
      {interact && <UiButton label={t('app.shop.seats.free-explainer.action.label')}
                             color={UiColor.accent}
                             className={'txt-b'}
                             interact={interact} />}
    </main>
  </animated.section>
}

export const ShopFreeSeatsCard: React.FC<IShopFreeSeatsCard> = (props) => {
  const {interact, freeSeats, configureSeats, className, style} = props;

  const t = useTranslator();

  if (isNil(freeSeats)) {
    return null;
  }

  return <animated.section className={classNames('app-shop-free-seat-card', className)}
                           style={style}>
    <AppBannerPane className="app-shop-free-seat-card__header"
                   aspectRatio={1 / 1.625}
                   interact={interact}
                   src={graphic}
                   preloadSrc={false}
                   contentAnchor={'bs'}>
      <UiTextTitle className={'f-jc-c'}
                   text={t(`app.shop.action.assign-athlete.label`)} />
    </AppBannerPane>
    <main className="app-shop-free-seat-card__content d-f fd-h f-ai-c"
          style={{borderRadius: '0.25em'}}>
      <UiFlexRow f={1} ai={UiFlexAlign.c}>
        <UiTextTitle text={t(`app.shop.seats.free`, {count: freeSeats})}
                     className={'txt-md'} />
      </UiFlexRow>
      {configureSeats && <UiFlexRow>
        <UiButton {...configureSeats}
                  icon={'appSettings'}
                  variation={UiButtonVariation.ghost}
                  className={'icon-scale mg-l-sm'}
                  interact={e => {
                    e && e.stopPropagation();
                    configureSeats?.interact && configureSeats.interact(e);
                  }} />
      </UiFlexRow>}
    </main>
  </animated.section>;
};

const shopTrialGiftIndicator = <UiFlexRow
  className={'app-shop-trial-gift-card__indicator'}
  jc={UiFlexAlign.e}>
  <UiIcon iconSet={'regular'}
          name={'appGift'} />
</UiFlexRow>;

export const ShopTrialSeatCard: React.FC<IShopTrialSeatCard> = (props) => {
  const {interact, configureSeats, className, style} = props;

  const t = useTranslator();

  return <animated.section className={classNames('app-shop-trial-gift-card', className)}
                           style={style}>
    <AppBannerPane className="app-shop-trial-gift-card__header"
                   aspectRatio={1 / 1.625}
                   interact={interact}
                   src={'/asset/placeholder/rooq-spint-open.jpg'}
                   preloadSrc={false}
                   contentAnchor={'tr'}>
      {/*<UiTextTitle className={'f-jc-c'}*/}
      {/*             text={t(`app.shop.action.assign-athlete.label`)} />*/}
      {shopTrialGiftIndicator}
    </AppBannerPane>
    <main className="app-shop-trial-gift-card__content d-f fd-h f-ai-c"
          style={{borderRadius: '0.25em'}}>
      <UiFlexRow f={1} ai={UiFlexAlign.c}>
        <UiTextTitle text={t('app.shop.seats.unoccupied')} />
      </UiFlexRow>
      {configureSeats && <UiFlexRow>
        <UiButton {...configureSeats}
                  icon={'plus'}
                  variation={UiButtonVariation.ghost}
                  className={'icon-scale mg-l-sm'}
                  interact={e => {
                    e && e.stopPropagation();
                    configureSeats?.interact && configureSeats.interact(e);
                  }} />
      </UiFlexRow>}
    </main>
  </animated.section>;
};


export const ShopBuySeatsCard: React.FC<IShopBuySeatsCard> = (props) => {
  const {interact, configureSeats, className, style} = props;
  // const acc = useAuthAccount();
  const showStartAboTour = useShouldShowTour(Rooq.UserTour.OnboardingBuySeats);
  const t = useTranslator();
  // const t = useTranslator();

  const shopButton = <UiButton {...configureSeats}
                               icon={'appBuySpints'}
                               variation={UiButtonVariation.default}
                               color={UiColor.accent}
                               className={'icon-scale mg-l-sm'}
                               interact={e => {
                                 e && e.stopPropagation();
                                 configureSeats?.interact && configureSeats.interact(e);
                               }} />;

  const ttId = `tour-tooltip-${Rooq.UserTour.OnboardingBuySeats}`;
  const ttShowRef = useRef(false);
  useEffect(() => () => {
    ttShowRef.current = false
  }, [ttShowRef]);

  return <animated.section className={classNames('app-shop-trial-gift-card', className)}
                           style={style}>
    <AppBannerPane className="app-shop-trial-gift-card__header"
                   aspectRatio={1 / 1.625}
                   interact={interact}
                   src={'/asset/placeholder/rooq-spint-closed-dim.jpg'}
                   preloadSrc={false}
                   contentAnchor={'c'}>
      <img src="/asset/placeholder/rooq-buy-seats.svg"
           style={{
             width: '33.34%',
             // height: '7.5rem',
           }}
           alt="" />
      {/*<UiTextTitle className={'f-jc-c'}*/}
      {/*             text={t(`app.shop.action.assign-athlete.label`)} />*/}
      {/*{shopTrialGiftIndicator}*/}
    </AppBannerPane>
    <main className="app-shop-trial-gift-card__content d-f fd-h f-ai-c"
          style={{borderRadius: '0.25em'}}>
      <UiFlexRow f={1} ai={UiFlexAlign.c}>
        <UiTextTitle text={t('app.shop.action.buy-seats.label')} />
      </UiFlexRow>
      {configureSeats && <UiFlexRow>
        {showStartAboTour
          ? <>
            <div data-tip={'data-tip'}
                 data-for={ttId}
                 ref={ref => {
                   if (!ref || ttShowRef.current) return;
                   ttShowRef.current = true;
                   setTimeout(() => {
                     ReactTooltip.show(ref as any);
                     // ReactTooltip.rebuild();
                     // console.log('shoed')
                   }, 350);
                 }}>
              {shopButton}
            </div>
            <ReactTooltip
              clickable
              place={'top'}
              effect={'solid'}
              wrapper={'div'}
              globalEventOff={'click'}
              backgroundColor={'transparent'}
              delayHide={1000}
              id={ttId}>
              <UiTourTooltip
                arrow
                arrowSide={ArrowSide.Bottom}
                clickToComplete={Rooq.UserTour.OnboardingBuySeats}>
                <UiTextBlock text={t('app.tour.onboarding-buy-seats.message')} />
              </UiTourTooltip>
            </ReactTooltip>
          </>
          : shopButton}
      </UiFlexRow>}
    </main>
  </animated.section>;
};
