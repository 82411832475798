import React from 'react';
import {IUiFieldProps} from '@bitsolve/react-common';

export interface IUiToggleControl extends IUiFieldProps<boolean> {
}


const UiToggleControlSVG: React.FC<{ value: boolean; }> = ({value}) =>
  <svg width="39" height="22"
       viewBox="0 0 39 22"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       style={{marginBottom: '0.25rem'}}>
    <rect width="39" height="22" rx="11"
          style={{
            transition: 'fill 0.2s ease-in-out',
            transitionDelay: '50ms',
          }}
          fill={value ? '#3DC583' : '#223545'} />
    <circle r={11} cx={11} cy={11}
            fill={'#fff'}
            style={{
              transition: 'transform 0.2s ease-in-out',
              transform: `translate3d(${value ? '17px' : '0'}, 0, 0)`
            }} />
  </svg>;

export const UiToggleControl: React.FC<IUiToggleControl> = (props) => {
  const {value, onChange, controlProps} = props;
  const {className, style} = controlProps;

  return <div className={className}
              style={{
                ...style,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
              onClick={ev => {
                ev.persist();
                ev.stopPropagation();

                onChange && onChange(!value);
              }}>
    <UiToggleControlSVG value={!!value} />
  </div>;
};
