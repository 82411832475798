import React from 'react';
import {
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppLogoCustom} from "./app-logo.component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick"

/**
 * Component to be used for split-screen background
 * Has defined a slider on the left side
 * Right side to be filled as children
 */

export const WelcomeSplashScreenBackground = (props:any) => {
  const {children} = props;
  const t = useTranslator();

  const slides = [
    {
      image: '/asset/brand/welcome/welcome-bg-img_01@2x.jpg',
      headline: t('app.welcomeScreen.slide1.headline'),
      text: t('app.welcomeScreen.slide1.text')
    },
    {
      image: '/asset/brand/welcome/welcome-bg-img_02@2x.jpg',
      headline: t('app.welcomeScreen.slide2.headline'),
      text: t('app.welcomeScreen.slide2.text')
    },
    {
      image: '/asset/brand/welcome/welcome-bg-img_03@2x.jpg',
      headline: t('app.welcomeScreen.slide3.headline'),
      text: t('app.welcomeScreen.slide3.text')
    }
  ];

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return <div className={'welcome-background-container'}>
    <div className={'welcome-left-side-container'}>
      <AppLogoCustom className={'welcome-left-side-logo'} src={"/asset/brand/rooq-logo.svg"} alt={t('app.info.full-title')} />
      <AppLogoCustom className={'welcome-left-side-subline'} src={"/asset/brand/rooq-brand-subline.svg"} alt={t('app.info.full-title')} />
      {/*slider*/}
      <Slider {...settings}>
        {slides.map((slide, i) =>
          <div key={'slide_' + i}>
            <div className={'welcome-left-side-slide'} style={{backgroundImage: `url(${slide.image})`}}>
              <div className="welcome-left-side-content">
                <UiTextTitle headingLevel={3}
                  className={'welcome-left-side-content-headline'}
                  text={slide.headline} />
                <UiTextBlock text={slide.text} className={'welcome-left-side-content-text'} />
              </div>
            </div>
          </div>
        )}
      </Slider>
    </div>
    <div className={'welcome-right-side-container'}>
      {children}
    </div>
  </div>
};
