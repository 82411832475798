import React, {useState} from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {
  AppSplashScreenContent
} from '../../../common/misc/app-splash-screen.component';
import {
  toast,
  UiBox,
  UiButtonGroup,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {useOnboardingRedirects} from '../onboarding.routes';
import {useFirebaseAppContext} from '../../../../module/auth/firebase';
import {useFirebaseActionCodeSettings} from '../../../common/app-firebase.config';
import {useAuthActions, useAuthUser} from '../../../../module/auth/auth.store';
import {useSafeEmailDisplay} from '../../../../core/util';
import {AppDevtool, AppDevtoolShellCommand} from '../../../common/misc/app-devtool.component';
import {useDispatch} from 'react-redux';
import { WelcomeSplashScreenBackground } from "../../../common/misc/app-welcome-screen.component";
import {useSendCustomVerificationMail} from "../../../../module/auth/auth.api";

export interface IAppVerificationPendingRoute extends IRoute {
}

export const AppVerificationPendingRoute: React.FC<IAppVerificationPendingRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const firebase = useFirebaseAppContext();
  const actionCodeSettings = useFirebaseActionCodeSettings();

  const d = useDispatch();
  const {authenticate, unauthenticate, updateAccess} = useAuthActions();
  const authUser = useAuthUser();
  const authEmail = useSafeEmailDisplay(authUser?.email);
  const [error, setError] = useState<any>();
  const sendVerificationMail = useSendCustomVerificationMail();

  useOnboardingRedirects();

  const actions = [
    {
      label: t('app.verification-pending.action.refresh.label'),
      color: UiColor.accent,
      className: 'txt-b txt-md',
      interact: () => {
        // nav('/')
        const auth = firebase?.app.auth();

        auth?.currentUser?.reload()
          .then(() => {
            if (auth?.currentUser) {
              setError(undefined);
              authenticate(auth.currentUser);

              if (auth.currentUser.emailVerified) {
                auth.currentUser
                  .getIdTokenResult(true)
                  .then(res => {
                    updateAccess(res);
                    nav('/');
                  }).catch((e) => {
                  console.error(e);
                  auth.signOut()
                    .then(() => nav('/'))
                    .catch(e => console.error(e))
                });
              } else {
                toast.info(t('app.verification-pending.action.refresh.toast.info', {email: authEmail}));
              }
            } else {
              return Promise.reject(new Error('no firebase user object'))
            }
          })
          .catch((e) => {
            if (error) {
              console.warn('overwriting previous error', error.message);
            }
            setError(e);
            toast.warning(t('general.toast.error.unknown'));
          });
      }
    },
    {
      label: t('app.verification-pending.action.resend-email.label'),
      color: UiColor.primary,
      interact: () => {
        const user = firebase?.app?.auth()?.currentUser;

        if (!user) {
          return;
        }


        user?.getIdToken(true).then(token => {
        sendVerificationMail.send(token)
          .then(() => toast.success(t('app.verification-pending.action.resend-email.toast.success')))
          .catch(() => toast.error(t('app.verification-pending.action.resend-email.toast.error')));
        });
      },
      className: 'mg-t-sm txt-md'
    },
    {
      label: t('app.account.profile.action.logout.label'),
      variation: UiButtonVariation.semantic,
      interact: () => {
        firebase?.app.auth()
          .signOut()
          .then(() => {
            d(unauthenticate())
          });
      },
      className: 'mg-t-nm'
    }
  ];

  return <WelcomeSplashScreenBackground>
    {/*<AppSplashScreenHeader>*/}
    {/*  <AppLogoInitial />*/}
    {/*</AppSplashScreenHeader>*/}
    <AppSplashScreenContent style={{gridRow: '2/4'}}>
      <UiTextTitle headingLevel={1}
                   className={'mg-v-nm txt-xl welcome-right-side-confirm-email-headline'}
                   text={t('app.verification-pending.title')} />
      <UiBox className={'mg-b-md'}>
        <UiTextBlock
          className={'welcome-right-side-confirm-email-text'}
          text={t('app.verification-pending.feedback')} />
      </UiBox>
      <UiButtonGroup direction={UiDir.v}
                     actions={actions} />
      <AppDevtool>
        <UiTextBlock text={'Copy this shell command to verify the account manually via the CLI tools:'}
                     className={'mg-t-md mg-b-nm'} />
        <AppDevtoolShellCommand command={`./bin/run verify -s local -u ${authUser?.uid}`} />
      </AppDevtool>
    </AppSplashScreenContent>
  </WelcomeSplashScreenBackground>;
};
