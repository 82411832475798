import React, {useState} from 'react';
import {UiModalDialog} from '../../ui/component/ui-modal-dialog.component';
import {
  IUiField,
  UiBox,
  UiColor,
  UiDir,
  UiForm,
  UiFormButton,
  UiFormContextConsumer,
  UiFormField,
  UiState,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {assoc, isNum, prop, randomUUID} from '@bitsolve/fns';
import {Rooq} from '../../../core/domain.model';
import {AppDisciplineIcon} from '../../../app/common/misc/app-discipline-icon.component';
import {AppPageSectionPanel} from '../../../app/common/page/app-page-section-panel.component';
import {parseNumberKeys} from '../../../core/util';
import {trainingPlanUnitSchema} from '../training-plan.model';
import {useSchemaOptions} from '../../../core/api';

export interface ITrainingsPlanAddUnitModal {
  discipline: string;
  isOpen: boolean;
  data: Partial<Rooq.TrainingPlanUnit> & {
    index?: number;
    state?: UiState;
  };
  setData: (data: any) => any;
  open: () => any;
  close: () => any;
}

const TrainingPlanBannerDecor: React.FC<{ index?: number; discipline: Rooq.Discipline; }> = (props) => {
  const {discipline} = props;

  return <UiBox className="app-training-plan__banner-decor"
                style={{backgroundImage: `url(/asset/brand/discipline-mood/${discipline}.png)`}}>
    <UiBox>
      <AppDisciplineIcon discipline={discipline} size={3.125} />
    </UiBox>
  </UiBox>;
};


const unitFormFields: IUiField<Rooq.TrainingPlanUnit>[] = [
  {
    name: 'customDisciplineName',
    label: 'app.training-plan.form.customDisciplineName.label',
    fieldType: 'text',
    direction: UiDir.v,
  },
  {
    name: 'description',
    label: 'app.training-plan.form.description.label',
    fieldType: 'text',
    direction: UiDir.v,
  },
  {
    name: 'numberOfRounds',
    label: 'app.training-plan.form.numberOfRounds.label',
    fieldType: 'number-slider',
    direction: UiDir.v
  },
  {
    name: 'coolDownSeconds',
    label: 'app.training-plan.form.coolDownSeconds.label',
    fieldType: 'duration-slider',
    direction: UiDir.v,
  },
  {
    name: 'roundLengthSeconds',
    label: 'app.training-plan.form.roundLengthSeconds.label',
    fieldType: 'duration-slider',
    direction: UiDir.v,
  },
  {
    name: 'pauseLengthSeconds',
    label: 'app.training-plan.form.pauseLengthSeconds.label',
    fieldType: 'duration-slider',
    direction: UiDir.v,
  },
];


const emptyUnit = (data: Partial<Rooq.TrainingPlanUnit>): Rooq.TrainingPlanUnit => ({
  id: data.id || `0000000-0000-0000${randomUUID().substr(18)}`,
  discipline: data.discipline as any,
  description: data.description || '',
  customDiscipline: (data.discipline as any) === Rooq.Discipline.CUSTOM ? data?.customDiscipline : undefined,
  numberOfRounds: (data.numberOfRounds || 1).toString() as any,
  coolDownSeconds: (data.coolDownSeconds || 0).toString() as any,
  pauseLengthSeconds: (data.pauseLengthSeconds || 0).toString() as any,
  roundLengthSeconds: (data.roundLengthSeconds || 0).toString() as any,
});

export const TrainingPlanUnitModal: React.FC<ITrainingsPlanAddUnitModal> = (props) => {
  const {data, close} = props;
  const discipline = data?.discipline;
  const state = data?.state || UiState.active;

  const [unit, setUnit] = useState<Partial<Rooq.TrainingPlanUnit>>(emptyUnit(data));

  const fields = useTranslatedFields(unitFormFields);
  const t = useTranslator();
  const schemaOpts = useSchemaOptions('app.training-plan.{message}', true);

  if (!discipline) {
    return null;
  }

  const isCustomDiscipline = discipline === Rooq.Discipline.CUSTOM;

  return <UiFormContextConsumer>
    {trainingPlanContext => {
      const currentUnits: any[] = prop(trainingPlanContext?.value, 'trainingPlanUnits') || [];

      return <UiModalDialog
        className={'app-training-plan__unit-modal'}
        header={{
          title: t(`content.discipline.${discipline}`),
          onClose: close
        }}>
        <TrainingPlanBannerDecor discipline={discipline as any}
                                 index={data?.index} />
        <UiForm fields={fields}
                value={unit}
                schema={trainingPlanUnitSchema}
                schemaOptions={schemaOpts}
                transform={data => ({...data, discipline})}
                onFieldChange={(k, v) => setUnit(u => assoc(u, k, v))}
                interact={setUnit}>
          {isCustomDiscipline && <UiBox className="pd-nm">
            <UiFormField name={'customDisciplineName'}
                         controlProps={{autoFocus: true}}
                         state={state} />
          </UiBox>}
          <AppPageSectionPanel title={{text: t('app.training-plan.unit.form.section.description')}} rounded={false}>
            <UiBox className="pd-nm">
              <UiFormField name={'description'}
                           controlProps={{autoFocus: !isCustomDiscipline}}
                           state={state} />
            </UiBox>
          </AppPageSectionPanel>
          <AppPageSectionPanel title={{text: t('app.training-plan.unit.form.section.rounds')}} rounded={false}>
            <UiBox className="app-training-plan__number-widget__container">
              <UiFormField name={'numberOfRounds'}
                           controlProps={{
                             unitText: t('app.training-plan.unit.form.number-of-rounds.unit'),
                             min: 1,
                           }}
                           state={state} />
              <UiBox className="app-training-plan__number-widget__decor" />
              <UiFormField name={'roundLengthSeconds'}
                           state={state} />
            </UiBox>
          </AppPageSectionPanel>
          <AppPageSectionPanel title={{text: t('app.training-plan.unit.form.section.breaks')}} rounded={false}>
            <UiBox className="app-training-plan__number-widget__container">
              <UiFormField name={'pauseLengthSeconds'}
                           state={state} />
              <UiBox className="app-training-plan__number-widget__decor" />
              <UiFormField name={'coolDownSeconds'}
                           state={state} />
            </UiBox>
          </AppPageSectionPanel>

          <UiBox className="pd-nm">
            <UiFormButton
              stretch
              label={t('general.action.save')}
              color={UiColor.accent}
              state={state === UiState.readonly ? UiState.disabled : state}
              interact={unitContext => {
                const newUnit = {
                  ...parseNumberKeys(
                    unitContext.value,
                    'numberOfRounds',
                    'roundLengthSeconds',
                    'pauseLengthSeconds',
                    'coolDownSeconds'
                  ),
                  discipline
                };

                unitContext.submit()
                  .then(() => {
                    if (isNum(data.index)) {
                      currentUnits[data.index] = newUnit;
                    } else {
                      currentUnits.push(newUnit as any);
                    }

                    trainingPlanContext.setFieldValue('trainingPlanUnits', currentUnits);
                    close();
                  })
                  .catch((e) => {
                    console.warn('validation error', e);
                  })
              }} />
          </UiBox>
        </UiForm>
      </UiModalDialog>
    }}
  </UiFormContextConsumer>;
};
