import React from 'react';
import {classNames, UiBox, UiCustomizable, UiTextTitle} from '@bitsolve/react-common';
import {isEmptyStr, isNil} from '@bitsolve/fns';

export interface IAppPageSectionTitle extends UiCustomizable {
  text?: string;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  dock?: 'top' | 'bottom';
}

export const AppPageSectionTitle: React.FC<IAppPageSectionTitle> = (props) => {
  const {text, headingLevel, dock, className, style, children} = props;

  if (isNil(text) || isEmptyStr(text)) {
    return null;
  }

  const _className = classNames(
    'app-page-section-title',
    dock && `app-page-section-title--dock-${dock}`,
    className
  );

  const titleElement = <UiTextTitle
    className={children ? 'app-page-section-title__title' : _className}
    style={style}
    headingLevel={headingLevel}
    text={text} />;

  return children
    ? <UiBox className={_className}>
      {titleElement}
      {children}
    </UiBox>
    : titleElement;
};
