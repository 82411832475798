import {API_PREFIX, PACKAGE_NAME, PACKAGE_VERSION} from '../../core/constants';
import {IReportClientInfo, ReportReason} from './report.model';
import {IHttpRequest, useApiEndpoint} from '@bitsolve/react-common';
import {AxiosError, AxiosResponse} from 'axios';
import {isStr} from '@bitsolve/fns';

export const baseUrl = `${API_PREFIX}/v1/content/report`;

export const getReportClientInfo = (extra?: any): IReportClientInfo => {
  return {
    clientName: PACKAGE_NAME,
    clientVersion: PACKAGE_VERSION,
    userAgent: window.navigator.userAgent,
    userAgentVendor: window.navigator.vendor,
    userAgentSub: window.navigator.vendorSub,
    origin: window.location.host,
    extra
  };
};

export const reportContentRequest = (reason: ReportReason, message?: string, context?: any, extraInfo?: any): IHttpRequest => ({
  method: 'post',
  url: baseUrl,
  data: {
    reason,
    message: message || '',
    context: context ? (isStr(context) ? context : JSON.stringify(context)) : '',
    clientInfo: JSON.stringify(getReportClientInfo(extraInfo)),
  }
});

export const useReportContent = (onSuccess?: (r: AxiosResponse<any>) => any,
                                 onError?: (e: AxiosError) => any) =>
  useApiEndpoint<{ reason: ReportReason, message?: string; context?: any; extraInfo?: any; }, any>(
    ({reason, message, context, extraInfo}) =>
      reportContentRequest(reason, message, context, extraInfo),
    onSuccess,
    onError
  );
