import React, {useCallback} from 'react';
import {classNames, IUiButton, UiButton} from '@bitsolve/react-common';
import {isFn} from '@bitsolve/fns';
import {useHistory} from 'react-router';

export interface INavButton extends IUiButton {
  to: string | URL;
  pushState?: any;
}

export const useNavInteract = (to: string | URL, pushState?: any, interact?: (ev: MouseEvent) => any) => {
  const h = useHistory();

  return useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      h.push(to.toString(), pushState);
      isFn(interact) && interact(ev);
    },
    [interact, h, to, pushState]
  );
};

export const useNavButtonProps = (props: INavButton): IUiButton => {
  const {to, interact, pushState, ...buttonProps} = props;
  const _interact = useNavInteract(to, pushState, interact);

  return {
    ...buttonProps,
    interact: _interact,
    type: 'button'
  };
};


export const AppNavButton: React.FC<INavButton> = (props) => {
  const {className, ...button} = useNavButtonProps(props);

  return <UiButton {...button}
                   className={classNames('app-nav-button', className)} />;
};
