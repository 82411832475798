import React, {useMemo, useState} from 'react';
import {
    schema,
    toast,
    UiButtonVariation,
    UiColor,
    UiForm,
    UiFormButton,
    UiFormError,
    UiFormField,
    UiState,
    UiTextBlock,
    UiTextTitle, useLocale,
    useTranslatedFields,
    useTranslator
} from '@bitsolve/react-common';
import {firebaseErrorCodeKey, useFirebaseAppContext} from '../../../../module/auth/firebase';
import {AppSplashScreenContent, AppSplashScreenHeader} from '../../../common/misc/app-splash-screen.component';
import {AppNavButton} from '../../../common/misc/app-nav-button.component';
import {IRoute} from '../../../../core/routing';
import {useOnboardingRedirects} from '../onboarding.routes';
import {useFirebaseActionCodeSettings, useFirebaseSocialProviders} from '../../../common/app-firebase.config';
import {authRegisterFields} from '../../../../module/auth/auth.forms';
import {AppLogoFirstLetter} from '../../../common/misc/app-logo.component';
import {schemaRef} from '../../../../core/util';
import {ValidationError} from 'yup';
import {useSchemaOptions} from '../../../../core/api';
import {AppPageToSMessage} from '../../../common/page/app-page-tos-message.component';
import {AuthSocialLoginButton} from '../../../../module/auth/component/auth/auth-social-login-button.component';
import {useSubscriptionToken} from '../../../../module/shop/shop.util';
import {WelcomeSplashScreenBackground} from "../../../common/misc/app-welcome-screen.component";
import {useSendCustomVerificationMail} from "../../../../module/auth/auth.api";
import {useAppData} from "../../../../core/system";


export interface IAppRegisterRoute extends IRoute {
}

const registerFields = [
    authRegisterFields.email,
    authRegisterFields.password,
    authRegisterFields.password_confirm,
];

const registerSchema = schema.object({
    email: schema.string()
        .required(`error.messages.email.required`)
        .min(3, `error.messages.email.min`),
    password: schema.string()
        .required(`error.messages.password.required`)
        .min(6, `error.messages.password.min`),
    password_confirm: schema.string()
        .required(`error.messages.password_confirm.required`)
        .oneOf(
            [schemaRef('password'), null],
            `error.messages.password_confirm.matches`
        )
});

const useRegisterForm = () => {
    const fields = useTranslatedFields(registerFields);
    const schemaOptions = useSchemaOptions('app.register.{message}');

    return useMemo(() => ({fields, schema: registerSchema, schemaOptions}), [fields, schemaOptions]);
};

export const AppRegisterRoute: React.FC<IAppRegisterRoute> = (props) => {
    const t = useTranslator();
    const firebase = useFirebaseAppContext();
    const socialProviders = useFirebaseSocialProviders();
    const actionCodeSettings = useFirebaseActionCodeSettings();
    const subToken = useSubscriptionToken();

    const [busy, setBusy] = useState<boolean>(false);
    const {fields, schema, schemaOptions} = useRegisterForm();

    const sendVerificationMail = useSendCustomVerificationMail();

    useOnboardingRedirects(true);

    return <WelcomeSplashScreenBackground>
        <AppSplashScreenHeader>
            <AppLogoFirstLetter inverted className={'welcome-right-side-initial-logo'}/>
        </AppSplashScreenHeader>
        <AppSplashScreenContent style={{gridRow: '2/4'}}>
            <UiTextTitle headingLevel={1}
                         className={'mg-v-nm'}
                         text={t('app.register.title')}/>
            <UiForm value={{email: '', password: '', password_confirm: ''}}
                    fields={fields}
                    schema={schema}
                    schemaOptions={schemaOptions}
                    interact={(values, form) => {
                        if (!firebase || busy) {
                            return;
                        }

                        const {email, password} = values;

                        setBusy(true);

                        firebase.app.auth()
                            .createUserWithEmailAndPassword(email, password)
                            .then((res) => {
                                return res?.user?.getIdToken(true);
                            })
                            .then((res) => {
                                const token = res;
                                if (token) {
                                    // return res?.user.sendEmailVerification(actionCodeSettings as any);
                                    return sendVerificationMail.send(token);
                                } else {
                                    return Promise.reject(new Error('no user account in firebase result'));
                                }
                            })
                            .then(() => toast.success(t('app.verification-pending.action.resend-email.toast.success')))
                            .catch(e => {
                                console.error(e);
                                form.setError(e);
                            })
                            .finally(() => setBusy(false));
                    }}>
                <UiFormField name={'email'}/>
                <UiFormField className={'mg-t-nm'} name={'password'}/>
                <UiFormField className={'mg-t-nm'} name={'password_confirm'}/>
                <UiFormError>
                    {(error: { code?: string } | ValidationError) => {
                        if (!error) {
                            return null;
                        }
                        const errorKey = firebaseErrorCodeKey(error as any);

                        return errorKey
                            ? <UiTextBlock className={'txt-c-err txt-b txt-md mg-t-nm'}
                                           text={t(`app.register.error.${errorKey}`)}/>
                            : null
                    }}
                </UiFormError>
                <footer className={'mg-t-md'}>
                    <UiFormButton stretch
                                  label={t('app.register.action.register.label')}
                                  className={'txt-uc txt-md txt-b'}
                                  state={firebase ? UiState.active : UiState.disabled}
                                  color={UiColor.accent}
                                  interact={(form) => form.submit()}/>
                    {socialProviders.map((p, i) => {
                        const {successRedirect, ...rest} = p;
                        const redirect = successRedirect || '/';
                        return <AuthSocialLoginButton key={`${i}.${p.provider.type}`}
                                                      useFor={'register'}
                                                      className={'mg-t-sm txt-md'}
                                                      successRedirect={subToken
                                                          ? `${redirect}?subId=${subToken}`
                                                          : redirect}
                                                      {...rest as any} />;
                    })}
                    <AppNavButton stretch
                                  variation={UiButtonVariation.ghost}
                                  to={'/'}
                                  label={t('general.action.back')}
                                  className={'mg-t-nm'}/>
                    <AppPageToSMessage termsUrl={'https://rooq.de/legal-information/'}
                                       privacyTermsUrl={'https://rooq.de/privacy-policy/'}
                                       membershipTermsUrl={'https://rooq.de/terms-conditions/'}/>
                </footer>
            </UiForm>
        </AppSplashScreenContent>
    </WelcomeSplashScreenBackground>;
};
