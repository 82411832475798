import React, {useEffect, useState} from 'react';
import {isStr, prop} from '@bitsolve/fns';
import {useDispatch} from 'react-redux';
import {ISocketConnectionContext} from '../../socket/socket.model';
import {useAuthAccount} from '../../auth/auth.store';
import {fetchNotifications} from '../notification.store';

export interface INotificationConnector {
  socketContext: ISocketConnectionContext;
  messagesDestination?: string;
  accountIdParameter?: string;
}

export enum NotificationConnectorPayloadType {
  PUSH_NOTIFICATION_FETCH = 'PUSH_NOTIFICATION_FETCH'
}

export const NotificationConnector: React.FC<INotificationConnector> = (props) => {
  const {socketContext, messagesDestination = '/user/queue/messages', accountIdParameter = 'account-id'} = props;

  const profile = useAuthAccount();
  const accountId = prop(profile, 'id');
  const d = useDispatch();

  const [subscribed, setSubscribed] = useState(false);

  useEffect(
    () => {
      if (subscribed || !accountId || !socketContext.connected) {
        return;
      }

      d(fetchNotifications());

      socketContext.subscribe(
        messagesDestination,
        message => {
          try {
            let payload;
            try {
              payload = JSON.parse(message.body);
            } catch (e) {
              payload = isStr(message.body) ? {type: message.body} : message.body;
            }

            switch (payload.type) {
              case NotificationConnectorPayloadType.PUSH_NOTIFICATION_FETCH:
                return d(fetchNotifications());
              default:
                console.warn(`I don't know what the websocket wants from me:`, payload);
            }
          } catch (e) {
            console.error(`Error parsing messages subscription frame`, e);
          }
        },
        {[accountIdParameter]: accountId} as any
      );

      setSubscribed(true);
    },
    [d, subscribed, socketContext, messagesDestination, accountIdParameter, accountId]
  );

  return null;
};
