import React from 'react';
import {UiBox, UiTextTitle, useTranslator} from '@bitsolve/react-common';
import {useNavigator} from '../../../../core/routing';
import {ArrowSide, UiTourTooltip} from '../../../ui/component/ui-tour-tooltip.component';
import {AthleteTileListing} from '../athlete/athlete-tile-listing.component';
import {CoachingZoneAthleteSearchModal} from '../athlete/athlete-seat-search-modal.component';
import {useRevisionState} from '../../../ui/ui.hooks';
import {Rooq, useShouldShowTour} from '../../../../core/domain.model';
import {useCompleteMyTour} from '../../auth.api';

export interface TrainerNoConnectionsInterstitialProps {
  ownPath: string;
  searchPath: string;
}

export const TrainerNoConnectionsInterstitial: React.FC<TrainerNoConnectionsInterstitialProps> = (props) => {
  const {ownPath} = props;
  const t = useTranslator();
  const nav = useNavigator();
  const {revision, bumpRevision} = useRevisionState();
  const showFindAthleteTour = useShouldShowTour(Rooq.UserTour.OnboardingFindAthlete);
  const completeTour = useCompleteMyTour();
  // const searchReq = use

  console.log(props);

  return <UiBox className="app-trainer-no-connections-interstitial">
    <UiBox className="app-trainer-no-connections-interstitial__cta">
      <UiTourTooltip arrow={showFindAthleteTour}
                     arrowSide={showFindAthleteTour ? ArrowSide.Right : undefined}>
        {showFindAthleteTour
          ? <p>{t('app.tour.onboarding-find-athlete.message')}</p>
          : <p>{t('app.tour.onboarding-find-athlete.completed-message')}</p>}
      </UiTourTooltip>
    </UiBox>
    <UiBox className="app-trainer-no-connections-interstitial__pending">
      <UiTextTitle text={t('app.account.section.athletes')}
                   className={'mg-b-nm'}
                   headingLevel={2} />
      <AthleteTileListing
        key={revision}
        revision={revision}
        onAccountClick={acc => nav(`${ownPath}/details/${acc.id}`)}
        actionTile={{
          title: t('app.account.action.add-relationship.label'),
          icon: 'plus',
          type: 'click',
          onClick: () => {
            const searchInput = document.querySelector<HTMLInputElement>('.ui-modal-dialog__search > input');

            if (searchInput) {
              searchInput.focus();
            }
          }
        }} />
    </UiBox>
    <UiBox className="app-trainer-no-connections-interstitial__search">
      <CoachingZoneAthleteSearchModal
        onAthleteClick={acc => console.log(acc)}

        onUpdatePropagation={() => {
          if (showFindAthleteTour) {
            // console.log('completing tour', Rooq.UserTour.OnboardingFindAthlete, '...')
            completeTour.send(Rooq.UserTour.OnboardingFindAthlete).then()
          }
          bumpRevision();
        }} />
    </UiBox>
  </UiBox>

  // return <UiFlexRow style={{height: '100%'}}
  //                   jc={UiFlexAlign.c}
  //                   ai={UiFlexAlign.s}>
  //   <UiFlexCol f={1}>
  //
  //   </UiFlexCol>
  //   <UiFlexCol f={1}>
  //   </UiFlexCol>
  //   {/*<UiFlexCol style={{maxWidth: '36rem', width: '65vw'}}*/}
  //   {/*           ai={UiFlexAlign.c}>*/}
  //   {/*  <UiTextTitle headingLevel={1}*/}
  //   {/*               text={t('app.account.no-connections-interstitial.title')} />*/}
  //   {/*  <UiTextBlock*/}
  //   {/*    className={'txt-ac pd-t-md pd-b-nm txt-c-defd'}*/}
  //   {/*    text={t('app.account.no-connections-interstitial.message-0')} />*/}
  //   {/*  <UiTextBlock*/}
  //   {/*    className={'txt-ac pd-b-md  txt-c-defd'}*/}
  //   {/*    text={t('app.account.no-connections-interstitial.message-1')} />*/}
  //   {/*  <UiButton stretch*/}
  //   {/*            color={UiColor.accent}*/}
  //   {/*            label={t('app.account.no-connections-interstitial.action.label')}*/}
  //   {/*            interact={() => nav(searchPath)} />*/}
  //   {/*</UiFlexCol>*/}
  // </UiFlexRow>
};
