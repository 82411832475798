import {Rooq} from '../../core/domain.model';
import {schema} from '@bitsolve/react-common';


export interface ITrainingPlanUnit extends Rooq.TrainingPlanUnit {
}

export interface ITrainingPlan extends Rooq.TrainingPlan {
  favorite: boolean;
  hasBeenSeen: boolean;
  trainedCount: number;
  creationDate: string;
  trainingPlanUnits: ITrainingPlanUnit[];
}

export interface ITrainingPlanPaginated extends ITrainingPlan {
  totalRoundCount: number;
  totalSharedCount: number | null;
  totalDuration: number;
}


export interface ITrainingPlanUnitCreate extends Omit<ITrainingPlanUnit, 'id'> {
}

export interface ITrainingPlanUnitUpdate extends Omit<ITrainingPlanUnit, 'id'> {
}

export interface ITrainingPlanCreate extends Omit<ITrainingPlan, 'id' | 'trainingPlanUnits'> {
  trainingPlanUnits: ITrainingPlanUnitCreate[];
}

export interface ITrainingPlanUpdate extends Omit<ITrainingPlan, 'id' | 'trainingPlanUnits'> {
  trainingPlanUnits: ITrainingPlanUnitUpdate[];
}


export const trainingPlanUnitSchema = schema.object({
  discipline: schema.string(),
  customDiscipline: schema.string(),
  description: schema.string(),
  numberOfRounds: schema.number()
    .min(0, 'error.messages.trainingPlanUnit.numberOfRounds.size')
    .required('error.messages.trainingPlanUnit.numberOfRounds.required'),
  roundLengthSeconds: schema.number()
    .min(1, 'error.messages.trainingPlanUnit.roundLengthSeconds.size')
    .required('error.messages.trainingPlanUnit.roundLengthSeconds.required'),
  pauseLengthSeconds: schema.number()
    .min(0, 'error.messages.trainingPlanUnit.pauseLengthSeconds.size')
    .required('error.messages.trainingPlanUnit.pauseLengthSeconds.required'),
  coolDownSeconds: schema.number()
    .min(0, 'error.messages.trainingPlanUnit.coolDownSeconds.size')
    .required('error.messages.trainingPlanUnit.coolDownSeconds.required'),
});

export const trainingPlanSchema = schema.object({
  name: schema.string()
    .required('error.messages.name.required'),
  description: schema.string(),
  trainingPlanUnits: schema.array()
    .of(trainingPlanUnitSchema)
    .required('error.messages.trainingPlanUnits.required')
    .min(1, 'error.messages.trainingPlanUnits.size')
    .max(20, 'error.messages.trainingPlanUnits.size')
});
