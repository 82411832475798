import {BrowserRouter} from 'react-router-dom';
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend';
import {
  AxiosClient,
  FormFieldTypeProvider,
  HttpClientProvider,
  IntlProvider,
  StoreProvider,
  UiScreenSizeProvider,
  UiSimpleToastProvider
} from '@bitsolve/react-common';

import {
  defaultConnectivityChecks,
  fieldTypes,
  icons,
  notificationSocket,
  onAuthAccessChanged,
  onAuthUserChanged,
  storeModules
} from '../common/app-config';
import AppCoachingZone from './app-coaching-zone.component';
import {FirebaseProvider} from '../../module/auth/firebase';
import {getMessageProvider, getPreferredLocale} from '../common/app-intl.config';
import {AuthAccountProvider} from '../../module/auth/component/auth/auth-account-provider.component';
import {SocketConnectionProvider} from '../../module/socket/component/socket-connection-provider.component';
import {AuthAPIGuardProvider} from '../../module/auth/component/auth/auth-guard-provider.component';
import {clubStoreModule} from '../../module/club/club.store';
import {AppDataProvider} from '../../core/system';
import {AppStyleProvider} from '../../style';
import {ConnectivityGuard} from '../../module/connectivity/component/connectivity-guard.component';
import {backendHealthGuards} from '../../module/connectivity/component/connectivity-backend-health.component';
import {maintenanceHealthGuards} from '../../module/connectivity/component/connectivity-maintenance.component';

export const createCZAppData = (http: AxiosClient, config: any) => {
  const localeProviders = config?.localeProviders || [];

  return {
    icons,
    ui: {
      container: '#root',
      component: AppCoachingZone
    },
    providers: [
      {provide: HttpClientProvider, props: {client: http}},
      {provide: StoreProvider, props: {modules: [...storeModules, clubStoreModule]}},
      {
        provide: IntlProvider,
        props: {
          defaultLocale: getPreferredLocale(),
          messageProvider: getMessageProvider(localeProviders),
          onError: (_: any) => {
          },
        }
      },
      {
        provide: ConnectivityGuard,
        props: {
          checks: config?.health || defaultConnectivityChecks,
          components: {
            backend: backendHealthGuards,
            maintenance: maintenanceHealthGuards
          }
        }
      },
      {provide: AppDataProvider, props: config},
      {provide: AppStyleProvider},
      {provide: FirebaseProvider, props: {config: config, onAuthUserChanged, onAuthAccessChanged}},
      {provide: UiScreenSizeProvider},
      {provide: UiSimpleToastProvider},
      {provide: FormFieldTypeProvider, props: {fieldTypes}},
      {provide: AuthAPIGuardProvider},
      {provide: AuthAccountProvider},
      {provide: SocketConnectionProvider, props: {alias: 'notifications', config: notificationSocket}},
      {provide: BrowserRouter},
      {provide: DndProvider, props: {backend: HTML5Backend, options: {}}},
    ]
  };
};

