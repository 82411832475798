import React from 'react';
import {
  toast,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiFlexAlign,
  UiFlexRow,
  UiPageContent,
  UiState,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {userAccountName} from '../../index';
import {AppBannerPane} from '../../../../app/common/misc/app-banner-pane.component';
import {AppTabNavigation} from '../../../../app/common/misc/app-tab-navigation.component';
import {UiDataItemset} from '../../../ui/component/ui-data-item.component';
import {Route, Switch} from 'react-router';
import {IRoute, useNavigator} from '../../../../core/routing';
import {useFindAccountById} from '../../auth.api';
import {AccountInvitationCTA} from '../account/account-invitation-cta.component';
import {UiTooltipMenu} from '../../../ui/component/ui-tooltip-menu.component';
import {useAthleteReportModal} from './athlete-report-modal.component';
import {useDeleteTraining, useFindTrainings, useGetTrainingStatus} from '../../../training/training.api';
import {Rooq} from '../../../../core/domain.model';
import {mediaUrl} from '../../../image/image.api';
import {useAuthAccount} from '../../auth.store';
import {AccountListItem} from '../account/account-list-item.component';
import {isEmpty} from '@bitsolve/fns';
import {useFindClubMemberships} from '../../../club/club.api';
import {ClubListItem} from '../../../club/component/club-list-item.component';
import {AppLoadingSpinner} from '../../../../app/common/misc/app-loading-spinner.component';

export interface IAthleteCard extends IRoute {
  ownPath: string;
  onClose?: () => any;
  onUpdatePropagation?: () => any;
}

const AthleteModalCardClubs: React.FC<{ accountId: string; }> = (props) => {
  const {accountId} = props;

  const stepSize = 10;
  const t = useTranslator();
  const clubReq = useFindClubMemberships(accountId, Rooq.MembershipType.ATHLETE, 0, stepSize, {initialFetch: true});
  const data = clubReq.response?.data;
  const items = data?.elements;

  return <UiBox className="ui-fx ui-fx__pane-fade-in pd-sm">
    {!clubReq.busy && clubReq.lastFetchTime && (!items || isEmpty(items)) && <UiFlexRow ai={UiFlexAlign.c}
                                                                                        jc={UiFlexAlign.c}>
      <UiTextLine className="txt-sm txt-it txt-c-defd pd-v-nm txt-ac"
                  text={t('general.table.empty')} />
    </UiFlexRow>}
    {items && items.filter(mship => mship.club)
      .map((mship, i) => <ClubListItem key={`${i}.${mship.id}`}
                                       club={mship.club as any} />)}
    {data && (data?.totalPages > 1) && (data?.number < (data?.totalPages - 1)) && <>
      <UiButton label={t('general.action.load-more')}
                state={clubReq.busy ? UiState.disabled : UiState.active}
                color={UiColor.default}
                variation={UiButtonVariation.ghost}
                interact={() => {
                  clubReq.paginate.setSize(data?.size + stepSize, true);
                }} />
    </>}
  </UiBox>;
}

const AthleteModalCardAccounts: React.FC<{ trainingType: Rooq.TrainingType; accountId: string; }> = (props) => {
  const {accountId, trainingType} = props;

  const stepSize = 10;
  const t = useTranslator();
  const accReq = useFindTrainings(accountId, trainingType, 0, stepSize, {initialFetch: true});
  const data = accReq.response?.data;
  const items = data?.elements;

  return <UiBox className="ui-fx ui-fx__pane-fade-in pd-sm">
    {!accReq.busy && accReq.lastFetchTime && (!items || isEmpty(items)) && <UiFlexRow ai={UiFlexAlign.c}
                                                                                      jc={UiFlexAlign.c}>
      <UiTextLine className="txt-sm txt-it txt-c-defd pd-v-nm txt-ac"
                  text={t('general.table.empty')} />
    </UiFlexRow>}
    {items && items.map((training, i) => <AccountListItem key={`${i}.${training.id}`}
                                                          account={trainingType === Rooq.TrainingType.ATHLETE
                                                            ? training.athlete
                                                            : training.trainer} />)}
    {data && (data?.totalPages > 1) && (data?.number < (data?.totalPages - 1)) && <>
      <UiButton label={t('general.action.load-more')}
                state={accReq.busy ? UiState.disabled : UiState.active}
                color={UiColor.default}
                variation={UiButtonVariation.ghost}
                interact={() => {
                  accReq.paginate.setSize(data?.size + stepSize, true);
                }} />
    </>}
  </UiBox>;
};

export const AthleteModalCard: React.FC<IAthleteCard> = (props) => {
  const {ownPath, match, onClose, onUpdatePropagation} = props;
  const id = match?.params?.accountId;
  const clubId = match?.params?.clubId;

  const t = useTranslator();
  const nav = useNavigator();
  const user = useAuthAccount();
  const reportModal = useAthleteReportModal(id, () => nav(ownPath));
  const accReq = useFindAccountById(id, {initialFetch: true});
  const trainingStatusReq = useGetTrainingStatus(id, Rooq.TrainingType.TRAINER, {initialFetch: true});

  const propagateUpdate = () => {
    !accReq.busy && accReq.load();
    onUpdatePropagation && onUpdatePropagation();
  };

  const deleteTraining = useDeleteTraining(
    () => {
      toast.success(t('app.account.action.delete-relationship.toast.success'));
      propagateUpdate();
    },
    () => toast.error(t('app.account.action.delete-relationship.toast.error')),
  );

  const acc = accReq?.response?.data;
  const trainingStatus = trainingStatusReq?.response?.data;

  const name = acc ? userAccountName(acc) : null;
  const fightStyles = acc ? acc.athleteProfile?.customFightStyles?.join(', ') : null;

  if (accReq.busy) {
    return <div className={'app-athlete-modal-card d-f f-dh f-ai-c f-jc-c pd-md'}>
      <AppLoadingSpinner />
    </div>;
  }

  if (!acc || !name) {
    return null;
  }

  const picId = acc.profilePictureId;

  return <div className={'app-athlete-modal-card'}>
    <AppBannerPane contentAnchor={'bs'}
                   blur={0.9}
                   src={picId ? mediaUrl(picId) : '/asset/placeholder/athlete-banner-default.png'}
      fgSrc={picId ? mediaUrl(picId) : '/asset/brand/account-card-empty-profile-pic.png'}
      showForegroundImage={!!picId}
                   aspectRatio={1 / 2}>
      <UiFlexRow ai={UiFlexAlign.e}>
        <UiPageContent className={'f-1'} style={{maxWidth: '100%', overflow: 'hidden'}}>
          <UiTextTitle text={name}
                       headingLevel={2} />
          {fightStyles && <UiTextLine className="txt-sm txt-c-defd d-b"
                                      style={{maxWidth: '100%', textOverflow: 'ellipsis', overflowX: 'hidden'}}
                                      text={fightStyles} />}
        </UiPageContent>
        <UiPageContent>
          <UiTooltipMenu
            items={[
              trainingStatus?.status === Rooq.RelationshipStatus.ACCEPTED && {
                label: t('app.account.action.delete-relationship.label'),
                icon: 'times',
                iconSet: 'regular',
                interact: () => deleteTraining.callback(trainingStatus.relationId)
              },
              {
                label: t('app.account.action.report.label'),
                icon: 'exclamationMark',
                interact: reportModal.open
              },
            ].filter(x => !!x) as any} />
          {trainingStatus?.status === Rooq.RelationshipStatus.ACCEPTED && deleteTraining.modal.element}
        </UiPageContent>
      </UiFlexRow>
    </AppBannerPane>
    {onClose && <UiButton className={'app-athlete-modal-card__close'}
                          icon={'times'}
                          variation={UiButtonVariation.semantic}
                          color={UiColor.default}
                          interact={onClose} />}
    <AppTabNavigation items={[
      {
        label: t('app.account.section.info'),
        to: `${ownPath}`,
        exact: true
      },
      {
        label: t('app.account.section.clubs'),
        to: `${ownPath}/clubs`
      },
      {
        label: t('app.account.section.trainers'),
        to: `${ownPath}/trainers`
      },
    ]} />
    <Switch>
      <Route path={ownPath} exact
             render={() => <>
               <UiPageContent>
                 <UiDataItemset
                   className={user ? 'mg-b-nm' : undefined}
                   items={[
                     {
                       label: t('app.account.profile.form.name.label'),
                       data: <UiTextLine text={name} />
                     },
                     fightStyles && {
                       label: t('app.account.trainerProfile.form.fightStyle.alt-label'),
                       data: <UiTextLine text={fightStyles} />
                     },
                   ].filter(item => !!item) as any} />
                 {user && <AccountInvitationCTA
                   accountId={id}
                   account={user}
                   clubId={clubId}
                   onInvitationCreated={propagateUpdate}
                   onInvitationDeleted={propagateUpdate}
                   onInvitationUpdated={propagateUpdate} />}
               </UiPageContent>
             </>} />
      <Route path={`${ownPath}/clubs`}
             render={() => <AthleteModalCardClubs accountId={id} />} />
      <Route path={`${ownPath}/trainers`}
             render={() => <AthleteModalCardAccounts key={`${id}.${Rooq.TrainingType.TRAINER}`}
                                                     accountId={id}
                                                     trainingType={Rooq.TrainingType.TRAINER} />} />
    </Switch>
    {reportModal.element}
  </div>;
};

export const AthleteModalCardRoute: React.FC<IRoute & { ownPath: string; onClose?: () => any; }> = (props) => {
  const {ownPath, onClose, ...route} = props;

  return <AthleteModalCard ownPath={ownPath}
                           onClose={onClose}
                           {...route} />
};
