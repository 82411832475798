import React from 'react';
import {AthleteSearchModal, IAthleteSearchModal} from './athlete-search-modal.component';
import {sleep, toast, UiButtonVariation, useTranslator} from '@bitsolve/react-common';
import {findMyTrainingCandidatesRequest, useCreateTrainingInvitationEndpoint} from '../../../training/training.api';
import {Rooq} from '../../../../core/domain.model';
import {useConfirmation} from '../../../ui/component/ui-confirmation-modal-dialog.component';
import {findMyShopSeatCandidatesRequest, useAssignMyShopSeat} from '../../../shop/shop.api';
import {useAuthAccount, useAuthActions} from '../../auth.store';
import {useFindMyAccount} from '../../auth.api';
import {useStartTrialPrompt} from '../../../shop/shop.util';

export const CoachingZoneAthleteSearchModal: React.FC<Partial<IAthleteSearchModal>> = (props) => {
  const {onAthleteClick} = props;
  const t = useTranslator();

  const authAction = useAuthActions();
  const fetchMe = useFindMyAccount();

  const createInvitation = useCreateTrainingInvitationEndpoint(Rooq.InvitationType.ATHLETE);

  const initTerm = '';
  const baseReq = findMyTrainingCandidatesRequest(Rooq.TrainingType.TRAINER, initTerm, 0, 100);

  const confirm = useConfirmation({
    onConfirm: ({data, reload, onUpdatePropagation}: any) => createInvitation
      .send(data.id)
      .then((res) => {
        toast.success(t('app.account.action.add-relationship.toast.success'));
        reload && reload();
        onUpdatePropagation && onUpdatePropagation();

        // sleep for a bit first to ensure server has definitely updated entities
        // the issue occurs for example when updating completedTours on an account
        return sleep(128)
          .then(() => fetchMe.send())
          .then(acc => {
            authAction.updateAccount(acc.data);
            return Promise.resolve(res);
          })
      })
      .catch(() => {
        toast.error(t('app.account.action.add-relationship.toast.error'));
      }),
    title: t('app.account.action.add-relationship.confirm.title'),
    messages: [t('app.account.action.add-relationship.confirm.message')]
  });

  return <AthleteSearchModal {...props}
                             baseRequest={baseReq}
                             initialTerm={initTerm}
                             onResultClick={res => onAthleteClick && onAthleteClick(res.data)}
                             resultActions={[
                               {
                                 icon: 'plus',
                                 size: 'sm',
                                 variation: UiButtonVariation.ghost,
                                 interact: (res) => {
                                   confirm.setData(res);
                                   confirm.open();
                                 }
                               }
                             ]}>
    {confirm.element}
  </AthleteSearchModal>
};

export const CoachingZoneAthleteSeatSearchModal: React.FC<Partial<IAthleteSearchModal>> = (props) => {
  const {onAthleteClick, onUpdatePropagation} = props;
  const t = useTranslator();

  const initTerm = '';
  const assignSeat = useAssignMyShopSeat();
  const acc = useAuthAccount();
  const baseReq = findMyShopSeatCandidatesRequest(0, 100);

  const confirm = useConfirmation({
    onConfirm: ({data, reload, onUpdatePropagation}: any) => assignSeat
      .send(data.id)
      .then((res) => {
        toast.success(t('app.account.action.assign-seat.toast.success'));
        reload && reload();
        onUpdatePropagation && onUpdatePropagation();
        return Promise.resolve(res);
      })
      .catch(() => {
        toast.error(t('app.account.action.assign-seat.toast.error'));
      }),
    title: t('app.account.action.assign-seat.confirm.title'),
    messages: [t('app.account.action.assign-seat.confirm.message')]
  });

  const startTrial = useStartTrialPrompt(
    ({reload}: any) => {
      toast.success(t('app.account.action.assign-seat.toast.success'));
      reload && reload();
      onUpdatePropagation && onUpdatePropagation();
    },
    () => {
      toast.error(t('app.account.action.assign-seat.toast.error'));
    }
  );

  return <AthleteSearchModal {...props}
                             baseRequest={baseReq}
                             initialTerm={initTerm}
                             onResultClick={res => onAthleteClick && onAthleteClick(res.data)}
                             resultActions={[
                               {
                                 icon: 'plus',
                                 size: 'sm',
                                 variation: UiButtonVariation.ghost,
                                 interact: (res) => {
                                   if (acc?.trialAvailable) {
                                     startTrial.setData(res);
                                     startTrial.open();
                                   } else {
                                     confirm.setData(res);
                                     confirm.open();
                                   }
                                 }
                               }
                             ]}>
    {acc?.trialAvailable && startTrial.element}
    {confirm.element}
  </AthleteSearchModal>
};
