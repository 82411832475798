import React, {useMemo} from 'react';
import {IAccountActionTile} from '../../auth/component/account/account-tile.component';
import {Rooq} from '../../../core/domain.model';
import {AccountTileListing, IAccountTileListingItem} from '../../auth/component/account/account-tile-listing.component';
import {IAuthUserAccount} from '../../auth';
import {IHttpRequestHookResult, toast, UiColor} from '@bitsolve/react-common';
import {
  useDeleteClubMembershipInvitation,
  useFindClubMembers,
  useFindClubMembershipInvitations,
  useUpdateClubMembershipInvitation
} from '../club.api';
import {
  IAtheleteReportModalContext,
  useAthleteReportModal
} from '../../auth/component/athlete/athlete-report-modal.component';
import {isFn} from '@bitsolve/fns';
import {IClubMembershipInvitation} from '../club.model';
import {IDeleteConfirmationHookApi} from '../../../core/api';

export interface IClubAthleteTileListing {
  clubId: string;
  actionTile?: IAccountActionTile;
  onAccountClick?: (account: IAuthUserAccount) => any;
  revision?: any;
}

const receivedInviteTileTooltipMenu = (
  invite: IClubMembershipInvitation,
  reportModal: IAtheleteReportModalContext,
  updateInvite: IDeleteConfirmationHookApi<{ invitationId: string; status: Rooq.InvitationStatus; }, { invitationId: string; status: Rooq.InvitationStatus; }>,
  deleteInvite: IDeleteConfirmationHookApi<string>,
  req: IHttpRequestHookResult,
  account: IAuthUserAccount
) => ([
  {
    label: 'Anfrage akzeptieren',
    icon: 'check',
    color: UiColor.success,
    interact: () => updateInvite.callback({invitationId: invite.id, status: Rooq.InvitationStatus.ACCEPTED})
  },
  {
    label: 'Anfrage ablehnen',
    icon: 'times',
    color: UiColor.error,
    interact: () => updateInvite.callback({invitationId: invite.id, status: Rooq.InvitationStatus.REJECTED})
  },
  {
    label: 'Athlet melden',
    icon: 'exclamationMark',
    interact: () => {
      reportModal.setData({accountId: account.id});
      reportModal.open();
    }
  },
]);

const sentInviteTileTooltipMenu = (
  invite: IClubMembershipInvitation,
  reportModal: IAtheleteReportModalContext,
  updateInvite: IDeleteConfirmationHookApi<{ invitationId: string; status: Rooq.InvitationStatus; }, { invitationId: string; status: Rooq.InvitationStatus; }>,
  deleteInvite: IDeleteConfirmationHookApi<string>,
  req: IHttpRequestHookResult,
  account: IAuthUserAccount
) => ([
  {
    label: 'Anfrage zurückziehen',
    icon: 'times',
    color: UiColor.error,
    interact: () => deleteInvite.callback(invite.id)
  },
  {
    label: 'Athlet melden',
    icon: 'exclamationMark',
    interact: () => {
      reportModal.setData({accountId: account.id});
      reportModal.open();
    }
  },
]);

const inviteTileTooltipMenu = (
  invite: IClubMembershipInvitation,
  reportModal: IAtheleteReportModalContext,
  updateInvite: IDeleteConfirmationHookApi<{ invitationId: string; status: Rooq.InvitationStatus; }, { invitationId: string; status: Rooq.InvitationStatus; }>,
  deleteInvite: IDeleteConfirmationHookApi<string>,
) =>
  (req: IHttpRequestHookResult, account: IAuthUserAccount) =>
    ({
      place: 'right',
      items: invite.account?.id === invite.issuingAccount?.id
        ? receivedInviteTileTooltipMenu(invite, reportModal, updateInvite, deleteInvite, req, account)
        : sentInviteTileTooltipMenu(invite, reportModal, updateInvite, deleteInvite, req, account)
    });

const accountListingItem = (_: IHttpRequestHookResult, account: IAuthUserAccount, tooltipFn?: any): IAccountTileListingItem => ({
  type: 'account',
  data: {
    account,
    tooltipMenu: isFn(tooltipFn) ? tooltipFn(_, account) : tooltipFn
  }
});

export const ClubAthleteTileListing: React.FC<IClubAthleteTileListing> = (props) => {
  const {clubId, actionTile, onAccountClick} = props;

  const req = useFindClubMembers(clubId, Rooq.MembershipType.ATHLETE, 0, 100, {initialFetch: true});
  const inviteReq = useFindClubMembershipInvitations(clubId, Rooq.MembershipType.ATHLETE, 0, 100, {initialFetch: true});

  const reportModal = useAthleteReportModal();

  const updateInvitation = useUpdateClubMembershipInvitation(
    () => {
      toast.success('Anfrage aktualisiert');
      req.load();
      inviteReq.load();
    },
    () => toast.error('Anfrage konnte nicht aktualisiert werden')
  );

  const deleteInvitation = useDeleteClubMembershipInvitation(
    (result) => {
      toast.success('Anfrage zurückgezogen');
      req.load();
      inviteReq.load();
    },
    () => {
      toast.error('Anfrage konnte nicht zurückgezogen werden');
    }
  );

  const tiles = useMemo(() => {
    const _tiles: IAccountTileListingItem[] = [];
    const _elements = req?.response?.data?.elements;
    const _inviteElements = inviteReq?.response?.data?.elements;

    if (!req.busy && _elements) {
      _elements.forEach(membership => {
        _tiles.push(accountListingItem(req, membership.account));
      });
    }

    if (!inviteReq.busy && _inviteElements) {
      _inviteElements.forEach(invite => {
        const tooltipFn = inviteTileTooltipMenu(invite, reportModal, updateInvitation, deleteInvitation);
        _tiles.push(accountListingItem(inviteReq, invite.account, tooltipFn));
      });
    }

    if (actionTile) {
      _tiles.push({type: 'action', data: actionTile});
    }
    return _tiles;
  }, [actionTile, req, inviteReq, reportModal, deleteInvitation, updateInvitation]);

  return <>
    <AccountTileListing items={tiles}
                        onAccountClick={onAccountClick} />
    {reportModal.element}
    {updateInvitation.modal.element}
    {deleteInvitation.modal.element}
  </>;
};
