export enum ReportReason {
  ILLEGAL = 'ILLEGAL',
  OFFENDING = 'OFFENDING',
  FEEDBACK = 'FEEDBACK',
  BUG = 'BUG',
  OTHER = 'OTHER',
}

export interface IReport {
  reason: ReportReason,
  message?: string;
  context?: string;
  clientInfo?: string;
}

export interface IReportClientInfo {
  clientName: string;
  clientVersion: string;
  userAgent: string;
  userAgentVendor: string;
  userAgentSub: string;
  origin: string;
  extra?: any;
}
