import React from 'react';
import {animated} from 'react-spring';
import {
  classNames,
  IUiButton,
  UiButtonGroup,
  UiCustomizable,
  UiDir,
  UiFlexAlign,
  UiFlexRow,
  UiInteractive,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../app/common/misc/app-banner-pane.component';
import {isNil} from '@bitsolve/fns';

export interface IShopLockedSeatsCard extends UiCustomizable, Partial<UiInteractive> {
  lockedSeats?: number;
  actions?: Partial<IUiButton>[];
}

const graphic = `data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE4MCIgdmlld0JveD0iMCAwIDI4OCAxODAiIHdpZHRoPSIyODgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjUwJSIgeDI9IjUwJSIgeTE9IjAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZmEzNzI0IiBzdG9wLW9wYWNpdHk9IjAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNmZjAzMmEiLz48L2xpbmVhckdyYWRpZW50PjxsaW5lYXJHcmFkaWVudCBpZD0iYiIgeDE9IjUwJSIgeDI9IjUwJSIgeTE9IjAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjMGExZjMwIiBzdG9wLW9wYWNpdHk9IjAiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMwYTFmMzAiLz48L2xpbmVhckdyYWRpZW50PjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgZmlsbC1ydWxlPSJub256ZXJvIiBvcGFjaXR5PSIuNzUiPjxwYXRoIGQ9Im0wIDVhNSA1IDAgMCAxIDUtNWgyNzhhNSA1IDAgMCAxIDUgNXYxNzVoLTI4OHoiIGZpbGw9IiMwYTFmMzAiLz48cGF0aCBkPSJtMCA1YTUgNSAwIDAgMSA1LTVoMjc4YTUgNSAwIDAgMSA1IDV2MTc1aC0yODh6IiBmaWxsPSIjZmZmIiBmaWxsLW9wYWNpdHk9Ii4xIi8+PHBhdGggZD0ibTAgNWE1IDUgMCAwIDEgNS01aDI3OGE1IDUgMCAwIDEgNSA1djE3NWgtMjg4eiIgZmlsbD0idXJsKCNhKSIvPjxwYXRoIGQ9Im0wIDVhNSA1IDAgMCAxIDUtNWgyNzhhNSA1IDAgMCAxIDUgNXYxNzVoLTI4OHoiIGZpbGw9InVybCgjYikiLz48L2c+PHBhdGggZD0ibTE1OS44MzMgMTE1aC0zMS42NjZjLTEuNzUgMC0zLjE2Ny0xLjQwMS0zLjE2Ny0zLjEzdi0xOS43NGMwLTEuNzI5IDEuNDE4LTMuMTMgMy4xNjctMy4xM2gzMS42NjZjMS43NSAwIDMuMTY3IDEuNDAxIDMuMTY3IDMuMTN2MTkuNzRjMCAxLjcyOS0xLjQxOCAzLjEzLTMuMTY3IDMuMTN6bS0yNS4wMjMtNDUuNjc0Yy0yLjQ0MiAyLjc3LTMuODEgNi41MjctMy44MSAxMC40NDR2OS4yM2gyNnYtOS4yM2MwLTMuOTE3LTEuMzctNy42NzEtMy44MDktMTAuNDQ0LTIuNDM4LTIuNzctNS43NDEtNC4zMjYtOS4xOTEtNC4zMjYtMy40NDkgMC02Ljc1MyAxLjU1Ny05LjE5MSA0LjMyNnoiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L2c+PC9zdmc+`;

export const ShopLockedSeatsCard: React.FC<IShopLockedSeatsCard> = (props) => {
  const {interact, lockedSeats, actions, className, style} = props;

  const t = useTranslator();

  if (isNil(lockedSeats) || lockedSeats === 0) {
    return null;
  }

  return <animated.section className={classNames('app-shop-locked-seat-card', 'd-f fd-v', className)}
                           style={style}>
    <AppBannerPane className="app-shop-locked-seat-card__header"
                   aspectRatio={1 / 1.625}
                   interact={interact}
                   src={graphic}
                   preloadSrc={false}
                   contentAnchor={'bs'}>
      {/*<UiTextTitle className={'f-jc-c'}*/}
      {/*             text={t(`app.shop.action.assign-athlete.label`)} />*/}
    </AppBannerPane>
    <main className="app-shop-locked-seat-card__content d-f fd-h f-ai-c"
          style={{borderRadius: '0.25em'}}>
      <UiFlexRow f={1} ai={UiFlexAlign.c}>
        <UiTextTitle text={t(`app.shop.seats.locked`, {count: lockedSeats})}
                     className={'txt-md'} />
      </UiFlexRow>
      {actions && <UiButtonGroup direction={UiDir.h}
                                 actions={actions} />}
    </main>
  </animated.section>;
};
