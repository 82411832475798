import React from 'react';
import {classNames, UiBox, UiFlexRow} from '@bitsolve/react-common';
import {useCompleteMyTour, useFindMyAccount} from '../../auth/auth.api';
import {ErrorHandler, SuccessHandler} from '../../../core/api';
import {Rooq} from '../../../core/domain.model';
import {useAuthActions} from '../../auth/auth.store';

export enum TooltipVariation {
  Default = 'default',
}

export enum ArrowSide {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export interface IUiTourTooltip {
  arrow?: boolean;
  arrowSide?: ArrowSide;
  variation?: TooltipVariation;
  clickToComplete?: Rooq.UserTour;
  onCompleteSuccess?: SuccessHandler;
  onCompleteError?: ErrorHandler;
}

const arrowTranslate = (dir?: ArrowSide) => {
  switch (dir) {
    case ArrowSide.Top:
      return `translate(25, 0)`;
    case ArrowSide.Bottom:
      return `translate(-25, 0)`;
    case ArrowSide.Left:
      return `translate(0, -25)`;
    case ArrowSide.Right:
      return `translate(0, 25)`;
    default:
      return undefined;
  }
}

export const UiTourTooltip: React.FC<IUiTourTooltip> = (props) => {
  const {
    arrow, arrowSide,
    clickToComplete,
    onCompleteSuccess,
    onCompleteError,
    variation = TooltipVariation.Default
  } = props;
  const fetchMe = useFindMyAccount();
  const {updateAccount} = useAuthActions();

  const completeTour = useCompleteMyTour(() => {
    fetchMe
      .send()
      .then(res => {
        updateAccount(res.data);
        onCompleteSuccess && onCompleteSuccess(res);
      })
      .catch(onCompleteError)
  }, onCompleteError)

  return <UiFlexRow
    className={classNames(
      'ui-tour-tooltip',
      `ui-tour-tooltip--${variation}`,
      arrow && arrowSide && `ui-tour-tooltip--arrow`,
      arrow && arrowSide && `ui-tour-tooltip--arrow-${arrowSide}`,
      clickToComplete && `ui-tour-tooltip--dismissable`
    )}>
    <div className={'ui-tour-tooltip__content'}
         onClick={clickToComplete
           ? () => completeTour.send(clickToComplete)
           : undefined}>
      {props.children}
    </div>
    {arrow && <UiBox className="ui-tour-tooltip__arrow">
      <svg version={'1.1'}
           xmlns="http://www.w3.org/2000/svg"
           viewBox={'0 0 100 100'}>
        <g transform={arrowTranslate(arrowSide)}>
          <path className={'ui-tour-tooltip__arrow__path'}
            // x={arrowDir === TooltipDir.Top || arrowDir === TooltipDir.Bottom ? 25 : 0}
            // y={arrowDir === TooltipDir.Top || arrowDir === TooltipDir.Bottom ? 0 : 25}
            // x={'50%'} y={0}
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="6"
                d="M26 3l22.883 22.842M26 3v88m0-88L3.044 25.86" />
        </g>
      </svg>
    </UiBox>}
  </UiFlexRow>;
};
