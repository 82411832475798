import React, {useMemo} from 'react';
import {IUiFieldProps, UiCustomizable, useLocale, useTranslator} from '@bitsolve/react-common';
import {UiSelectControl} from '../../../module/ui/component/ui-select-control.component';

export interface IAppLocaleSwitcher extends IUiFieldProps<string> {
  controlProps: {
    availableLocales: string[];
    namespace: string;
  } & UiCustomizable;
}

export const AppLocaleSwitcher: React.FC<IAppLocaleSwitcher> = (props) => {
  const t = useTranslator();
  const locale = useLocale();
  const {controlProps, value, onChange, ...rest} = props;
  const {className, style, namespace, availableLocales = [locale]} = controlProps;

  const options = useMemo(
    () => availableLocales.map(value => ({value, label: t(`${namespace}.${value}`)})),
    [availableLocales, namespace, t]
  );

  return <UiSelectControl {...rest}
                          value={value || locale}
                          className={className}
                          style={style}
                          onChange={onChange}
                          options={options} />;
};
