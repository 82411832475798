import React from 'react';
import {Rooq} from '../../../core/domain.model';
import {classNames, UiCustomizable, UiFlexAlign, UiFlexCol} from '@bitsolve/react-common';
import {AnalysisTrainingSessionPieChart} from './analysis-training-session-pie-chart.component';


export interface IAnalysisDisciplineBreakdownItem {
  discipline: Rooq.DisciplineV2;
  duration: number;
}

export interface IAnalysisDisciplineBreakdown extends UiCustomizable {
  items: IAnalysisDisciplineBreakdownItem[];
}

const AnalysisDisciplineBreakdownItem: React.FC<IAnalysisDisciplineBreakdownItem> = (props) => {
  const {discipline, duration} = props;

  return <UiFlexCol className={'app-analysis-training-session-breakdown__item2'}
                    ai={UiFlexAlign.c}
                    jc={UiFlexAlign.c}>
    <AnalysisTrainingSessionPieChart
      discipline={discipline}
      centralClipStyle={{
        transformOrigin: 'center',
        transform: 'scale(1.325)'
      }}
      data={[
        {discipline, duration},
        {discipline: null, duration: 1 - duration}
      ]} />
  </UiFlexCol>;
}

export const AnalysisTrainingSessionBreakdown: React.FC<IAnalysisDisciplineBreakdown> = (props) => {
  const {items, className, style} = props;

  return <div className={classNames('app-analysis-training-session-breakdown', className)}
              style={style}>
    {items.map((item, index) => <AnalysisDisciplineBreakdownItem key={index} {...item} />)}
  </div>;
};
