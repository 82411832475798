import React, {useMemo} from 'react';
import {IAnalysisDetailsPageSegmentHeading} from '../analysis-details.model';
import {IRoute} from '../../../../core/routing';
import {isStr} from '@bitsolve/fns';
import {UiButton, UiButtonVariation, UiTextTitle} from '@bitsolve/react-common';

export const AnalysisDetailsPageHeadingSegment: React.FC<IAnalysisDetailsPageSegmentHeading['props'] & { route?: IRoute; }> = (props) => {
  const {text, headingLevel = 3, route, onClose} = props;

  const headingText = useMemo(
    () => isStr(text) ? text : text(route as any),
    [text, route]
  );

  return <>
    <UiTextTitle text={headingText}
                 headingLevel={headingLevel as any}
                 className={'f-1 f-jc-c txt-c-defd'}
                 style={onClose
                   ? {paddingLeft: '2.25rem'}
                   : undefined} />
    {onClose && <UiButton variation={UiButtonVariation.semantic}
                          color={'_nop' as any}
                          style={{
                            width: '2.25rem',
                            height: '2.25rem',
                            fontSize: '1.75rem',
                            padding: 'var(--size-geo)',
                            color: 'var(--color-text--inverse-dim)'
                          }}
                          icon={'times'}
                          interact={() => onClose && onClose(route as any)} />}
  </>
}
