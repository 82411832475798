import {IEntity, Rooq} from '../../core/domain.model';
import {mediaUrl} from '../image/image.api';
import {useMemo} from 'react';

/* eslint-disable @typescript-eslint/no-unused-vars */
export interface IClub<T extends IEntity = IEntity> extends IEntity {
  aboutUs: string;
  address: Rooq.Address;
  bannerPictureId: string;
  email: string;
  fightStyles: Rooq.FightStyle[];
  customFightStyles: string;
  foundingYear: number;
  logoPictureId: string;
  memberCount: number | null;
  athleteCount: number;
  trainerCount: number;
  name: string;
  openingHours: Rooq.ClubOpeningTimeframe[];
  phoneNumber: string;
}

export interface IClubCreate {
  aboutUs: string;
  address: Rooq.Address;
  bannerPictureId: string;
  email: string;
  fightStyles: Rooq.FightStyle[];
  customFightStyles: string;
  foundingYear: number;
  logoPictureId: string;
  memberCount: number;
  name: string;
  openingHours: Rooq.ClubOpeningTimeframe[];
  phoneNumber: string;
}


export interface IClubUpdate {
  aboutUs: string;
  address: Rooq.Address;
  bannerPictureId: string;
  email: string;
  fightStyles: Rooq.FightStyle[];
  customFightStyles: string;
  foundingYear: number;
  logoPictureId: string;
  memberCount: number;
  name: string;
  openingHours: Rooq.ClubOpeningTimeframe[];
  phoneNumber: string;
}

export interface IPrivateClub {
  name: string;
  membershipType: Rooq.MembershipType;
}

export interface IClubRelationshipStatus {
  status: Rooq.RelationshipStatus;
  type: Rooq.MembershipType;
  relationId: string;
  loggedInAuthor: boolean;
}

export interface IClubMembership<T extends IEntity = IEntity> extends IEntity {
  club?: IClub<T>;
  account: T;
  membershipId: string;
  type: Rooq.MembershipType;
  role: Rooq.MembershipRole;
  vip: any | null;
}

export interface IClubMembershipInvitation<T extends IEntity = IEntity> extends IEntity {
  club: IClub;
  issuingAccount: T;
  account: T;
  status: Rooq.InvitationStatus;
  invitedAs: Rooq.InvitationType;
  invitedAt: string;
}

interface IClubBannerPicture {
  assetId: string | null | undefined;
  assetSrc: string | null | undefined;
  isLogoFallback: boolean;
}

export const useBannerPicture = (club?: Pick<IClub, 'bannerPictureId' | 'logoPictureId'>): IClubBannerPicture =>
  useMemo(() => {
    let context: any = {assetId: null, isLogoFallback: false, assetSrc: null};

    if (club?.bannerPictureId) {
      context.assetId = club.bannerPictureId;
      context.assetSrc = mediaUrl(context.assetId);
    } else if (club?.logoPictureId) {
      context.assetId = club.logoPictureId;
      context.isLogoFallback = true;
      context.assetSrc = mediaUrl(context.assetId);
    } else {
      context.assetSrc = undefined;
    }

    return context;
  }, [club]);



