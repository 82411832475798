import React from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {
  classNames,
  UiBox,
  UiButton,
  UiColor,
  UiPageContainer,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../common/misc/app-banner-pane.component';
import {useIsAdmin, userAccountName} from '../../../../module/auth';
import {useAuthAccount} from '../../../../module/auth/auth.store';
import {Redirect, Route, Switch} from 'react-router-dom';
import {isNotNil} from '@bitsolve/fns';
import {useMediaUrl} from '../../../../module/image/image.api';
import {AppPageMenuPanel} from '../../../common/page/app-page-menu-panel.component';
import {CoachingZoneSettingsProfileRouteComponent} from './coaching-zone-settings-profile-route.component';
import {CoachingZoneSettingsInfoRouteComponent} from './coaching-zone-settings-info-route.component';
import {CoachingZoneSettingsAccountRoute} from './coaching-zone-settings-account-route.component';
import {useShopRedirect} from '../../../../module/shop/shop.api';

export interface ICoachingZoneSettingsRoute extends IRoute {
}

const _baseClass = 'app-coachz__settings';


export const CoachingZoneSettingsRoute: React.FC<ICoachingZoneSettingsRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const acc = useAuthAccount();
  const isAdmin = useIsAdmin();
  const bannerPic = useMediaUrl(acc?.profilePictureId);
  const goToShop = useShopRedirect();

  if (!acc) {
    return null;
  }

  const basePath = '/portal/trainer/settings';

  return <UiPageContainer fluid className={classNames('app-coachz', _baseClass)}>
    <UiBox className={classNames(`${_baseClass}__container`)}>
      <UiBox tag={'nav'}
             className={classNames(`${_baseClass}__master`)}
             style={{
               minHeight: 0,
               maxHeight: '100%',
               overflowY: 'auto'
             }}>
        <AppBannerPane className={`${_baseClass}__master__header`}
                       aspectRatio={1 / 1.5}
                       blur={0.9}
                      showForegroundImage={!!bannerPic}
                       src={bannerPic || false}
                       fgSrc={bannerPic || false}>
          <UiBox className="pd-nm d-f fd-v">
            <UiTextLine text={t('app.settings.title')}
                        className={'txt-xs txt-uc txt-c-defd'} />
            <UiTextTitle headingLevel={2}
                         text={userAccountName(acc)} />
          </UiBox>
        </AppBannerPane>
        <UiBox className={'mg-h-nm mg-v-nm'}>
          <UiButton stretch
                    icon={'signOut'}
                    iconSet={'regular'}
                    label={t('app.settings.action.logout.label')}
                    color={UiColor.primary}
                    interact={() => nav('/logout')} />
        </UiBox>
        <AppPageMenuPanel className={'mg-h-nm'}
                          title={{text: t('app.settings.menu.account.title')}}
                          items={[
                            {
                              to: `${basePath}/account`,
                              label: t('app.settings.menu.account.item.account')
                            },
                            {
                              to: `${basePath}/profile`,
                              label: t('app.settings.menu.account.item.profile'),
                              exact: true
                            },
                            {
                              label: t('app.settings.menu.account.item.shop'),
                              interact: () => goToShop()
                            },
                            // {
                            //   to: `${basePath}/system/blocked-users`,
                            //   label: t('app.settings.menu.system.item.blocked-users'),
                            // },
                          ]} />
        <AppPageMenuPanel className={'mg-nm'}
                          title={{text: t('app.settings.menu.system.title')}}
                          items={[
                            isAdmin && {
                              to: '/admin',
                              label: t('app.settings.menu.system.item.admin'),
                              external: true
                            },
                            {
                              external: true,
                              target: '_blank',
                              to: t('general.tos.policy.generallink'),
                              label: t('general.tos.policy.general'),
                            },
                            {
                              external: true,
                              target: '_blank',
                              to: t('general.tos.policy.privacylink'),
                              label: t('general.tos.policy.privacy'),
                            },
                            {
                              external: true,
                              target: '_blank',
                              to: t('general.tos.policy.membershiplink'),
                              label: t('general.tos.policy.membership'),
                            },
                            {
                              to: `${basePath}/system`,
                              label: t('app.settings.menu.system.item.info'),
                              exact: true
                            },
                          ].filter(isNotNil) as any} />
      </UiBox>
      <UiBox tag={'main'}
             className={classNames(`${_baseClass}__detail`)}
             style={{
               minHeight: 0,
               maxHeight: '100%',
               overflowY: 'auto'
             }}>
        <Switch>
          <Route exact path={`${basePath}/profile`}
                 component={CoachingZoneSettingsProfileRouteComponent} />
          <Route exact path={`${basePath}/account`}
                 component={CoachingZoneSettingsAccountRoute} />
          {/*<Route exact path={`${basePath}/system/blocked-users`}*/}
          {/*       component={CoachingZoneSettingsBlocklistRouteComponent} />*/}
          <Route exact path={`${basePath}/system`}
                 component={CoachingZoneSettingsInfoRouteComponent} />
          {/* dummies: */}
          {[`/legal`, `/data-protection`].map(to => <Route key={to} path={`${basePath}${to}`} exact={true}
                                                           render={() => {
                                                             return <UiBox
                                                               className={'pd-nm txt-sm ui-fx ui-fx--fast ui-fx__pane-fade-left'}>
                                                               <span className="mg-r-sm txt-c-defd">Route path:</span>
                                                               <code>{to}</code>
                                                             </UiBox>;
                                                           }} />)}
          <Redirect from={basePath} to={`${basePath}/profile`} />
        </Switch>
      </UiBox>
    </UiBox>
  </UiPageContainer>;
};
