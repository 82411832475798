import React, {useState} from 'react';
import {SocialProviderType, useFirebaseAppContext, useSocialProvider} from '../../index';
import {UiColor, UiCustomizable, UiFormButton, UiState, useLocale} from '@bitsolve/react-common';
import {useNavigator} from '../../../../core/routing';
import {prop} from '@bitsolve/fns';

export interface IAuthSocialLoginButton extends UiCustomizable {
  useFor?: 'login' | 'register';
  icon?: string;
  label?: {
    login: string;
    register: string;
  }
  successRedirect?: string;
  provider: {
    type: SocialProviderType,
    options?: any;
  };
}

export const AuthSocialLoginButton: React.FC<IAuthSocialLoginButton> = (props) => {
  const {useFor = 'login', provider, icon, label, style, className, successRedirect = '/'} = props;
  const {type, options} = provider;

  const fb = useFirebaseAppContext();
  const nav = useNavigator();
  const [busy, setBusy] = useState<boolean>(false);

  const locale = useLocale();
  const providerInstance = useSocialProvider(type, {...options, locale});

  if (!providerInstance) {
    return null;
  }

  return <UiFormButton stretch
                       color={UiColor.primary}
                       label={prop(label, useFor)}
                       icon={icon}
                       className={className}
                       style={style}
                       state={busy ? UiState.disabled : UiState.active}
                       interact={(form) => {
                         if (!fb || busy) {
                           return;
                         }

                         setBusy(true);

                         if (form.error) {
                           form.setError(undefined);
                         }

                         try {
                           providerInstance.signIn(form.value)
                             .then(() => {
                               successRedirect && nav(successRedirect);
                             })
                             .catch(err => {
                               form.setError(err);
                             })
                             .finally(() => setBusy(false));
                         } catch (e) {
                           setBusy(false);
                         }
                       }} />;
};
