import React, {useEffect} from 'react';
import {AppSplashScreen, AppSplashScreenContent} from './app-splash-screen.component';
import {AppLogo} from './app-logo.component';
import {
  AxiosClient,
  HttpClientProvider,
  IntlProvider,
  StoreProvider,
  UiColor,
  UiScreenSizeProvider,
  UiSimpleToastProvider,
  useTranslator
} from '@bitsolve/react-common';
import {UiSplashMessage} from '../../../module/ui/component/ui-splash-message.component';
import {icons, storeModules} from '../app-config';
import {clubStoreModule} from '../../../module/club/club.store';
import {AppDataProvider} from '../../../core/system';
import {AppStyleProvider} from '../../../style';
import {AxiosError} from 'axios';
import {getMessageProvider, getPreferredLocale} from '../app-intl.config';


export const createFatalErrorAppData = (http: AxiosClient, error: any) => {
  return {
    icons,
    ui: {
      container: '#root',
      component: AppFatalErrorRoot
    },
    providers: [
      {provide: HttpClientProvider, props: {client: http}},
      {provide: StoreProvider, props: {modules: [...storeModules, clubStoreModule]}},
      {
        provide: IntlProvider,
        props: {
          defaultLocale: getPreferredLocale(),
          messageProvider: getMessageProvider([
            '/asset/locale/{locale}.json'
          ]),
          onError: (_: any) => {
          },
        },
      },
      {provide: AppDataProvider, props: {error, renderError: AppFatalError}},
      {provide: AppStyleProvider},
      {provide: UiScreenSizeProvider},
      {provide: UiSimpleToastProvider},
    ]
  };
}

export interface IAppFatalError {
  error?: number | string | Partial<Error> | AxiosError;
}

export const AppFatalErrorRoot: React.FC<IAppFatalError> = (props) => {
  return <>{props.children}</>
}

export const AppFatalError: React.FC<IAppFatalError> = (props) => {
  const t = useTranslator();
  useEffect(() => console.error('Fatal application error:', props.error));

  return <AppSplashScreen dim center>
    <AppSplashScreenContent>
      <AppLogo
        style={{
          width: '8rem',
          margin: 'var(--size-geo--md) auto'
        }} />
      <UiSplashMessage
        title={t('general.error.fatal.title')}
        messages={[
          t('general.error.fatal.message-0'),
          t('general.error.fatal.message-1')
        ]}
        actions={[
          {
            label: t('general.error.fatal.action.reload.label'),
            className: 'txt-b',
            color: UiColor.accent,
            interact: () => window.location.reload()
          }
        ]} />
    </AppSplashScreenContent>
  </AppSplashScreen>;
};
