import React from 'react';
import {UiButton, UiButtonGroup, UiButtonVariation, UiColor, useTranslator} from '@bitsolve/react-common';
import {useGetClubRelationStatus} from '../club.api';
import {Rooq} from '../../../core/domain.model';
import {IClubRelationshipStatus} from '../club.model';

export interface IClubInvitationCTA {
  clubId: string;
  createInvitation?: (status: IClubRelationshipStatus) => Promise<any>;
  deleteInvitation?: (status: IClubRelationshipStatus) => Promise<any>;
}

export const ClubInvitationCTA: React.FC<IClubInvitationCTA> = (props) => {
  const {clubId, createInvitation, deleteInvitation} = props;
  const t = useTranslator();
  const statusReq = useGetClubRelationStatus(clubId, Rooq.RelationshipType.TRAINER);
  const status = statusReq?.response?.data;

  if (!status) {
    return null;
  }

  return <div className="app-club__invitation-cta">
    {status.status === Rooq.RelationshipStatus.NONE
    && <UiButton stretch
                 label={t('app.club.action.request-membership.label')}
                 className={'txt-b'}
                 variation={UiButtonVariation.default}
                 color={UiColor.accent}
                 interact={() => createInvitation && createInvitation(status)
                   .then(statusReq.load)} />}
    {status.status === Rooq.RelationshipStatus.PENDING
    && (status.loggedInAuthor
      ? <UiButton stretch
                  label={t('app.club.action.withdraw-membership-request.label')}
                  variation={UiButtonVariation.default}
                  color={UiColor.primary}
                  interact={() => deleteInvitation && deleteInvitation(status)
                    .then(statusReq.load)} />
      : <UiButtonGroup stretch
                       variation={UiButtonVariation.default}
                       actions={[
                         {
                           label: t('app.club.action.update-membership.label.accept'),
                           icon: 'check',
                           color: UiColor.accent,
                         },
                         {
                           label: t('app.club.action.update-membership.label.reject'),
                           icon: 'times',
                           color: UiColor.primary,
                         },
                       ]} />)}
  </div>;
};
