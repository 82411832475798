import React, {useMemo, useRef} from 'react';
import {classNames, IUiFieldControl, UiCustomizable, UiState} from '@bitsolve/react-common';
import {dissoc, isNil, isNonEmptyStr} from '@bitsolve/fns';

export interface IUiNumberRangeInput extends UiCustomizable, Partial<Omit<IUiFieldControl<string, string>, 'id' | 'fieldType' | 'name'>> {
  name?: string;
  step?: number;
  min?: number;
  max?: number;
  pattern?: string;
  onFocus?: (e: FocusEvent) => any;
  onBlur?: (e: FocusEvent) => any;
}


export const UiNumberRangeInput: React.FC<IUiNumberRangeInput> = (props) => {
  // eslint-disable-next-line
  const {
    step = 1,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    className,
    onChange,
    onBlur,
    onFocus,
    pattern,
    state,
    ...rest
  } = props;
  const ref = useRef<HTMLInputElement>(document.createElement('input'));
  const minStr = `${min}`;
  const maxStr = `${max}`;
  const inputProps = useMemo(() => dissoc.apply(
      null,
      [
        Object.assign({value: '', onChange: (_: any) => _}, rest),
        'controlProps',
        'helpText',
        'hintText',
        'changeDebounceTime',
        'icon',
        'label',
        'state',
        'fieldType',
        'buttonVariation'
      ] as any,
    ),
    [rest]
  );

  // @ts-ignore
  return <input {...inputProps}
                pattern={isNonEmptyStr(pattern) ? pattern : undefined}
                className={classNames('ui-number-range-input', className)}
                ref={ref}
                type="text"
                disabled={state !== UiState.active}
                aria-disabled={state !== UiState.active}
                onKeyDown={ev => {
                  const el = ev.target as HTMLInputElement;
                  let next;
                  switch (ev.key) {
                    case 'Enter':
                      ev.preventDefault();
                      return;
                    case 'Esc':
                      // @ts-ignore
                      ev.target.blur();
                      return;
                    case 'ArrowUp':
                      next = `${Math.min(parseInt(el.value, 10) + step, max)}`;
                      break;
                    case 'ArrowDown':
                      next = `${Math.max(parseInt(el.value, 10) - step, min)}`;
                      break;
                    default:
                      return;
                  }
                  props.onChange && props.onChange(next);
                }}
                onBlur={(_) => {
                  if (!props.onChange) return;
                  try {
                    const next = parseInt(ref.current.value, 10);
                    const cur = parseInt(props.value || '', 10);
                    if (next !== cur) {
                      props.onChange(next && !isNaN(next) ? `${next}` : '');
                    } else {
                      _.stopPropagation();
                    }
                  } catch (_) {
                    props.onChange(`0`);
                  }
                }}
                onChange={ev => {
                  try {
                    if (!props.onChange) return;
                    const next = parseInt((ev.target as HTMLInputElement).value, 10);
                    if (isNil(next) || isNaN(next)) {
                      props.onChange(``);
                      return;
                    }
                    props.onChange(`${next}`);
                  } catch (_) {
                    if (!props.onChange) return;
                    props.onChange(`0`);
                  }
                }}
                minLength={minStr.length}
                maxLength={maxStr.length} />;
};
