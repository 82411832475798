import React, {useMemo} from 'react';
import {IDashboardCardProgressIndicator} from '../dashboard.model';
import {constrain} from '@bitsolve/fns';
import {classNames} from '@bitsolve/react-common';

const _baseProgressClass = `app-dashboard__card__progress-indicator`;
const _progressBarStyle = {
  height: '100%',
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0
};

export const DashboardCardProgressIndicator: React.FC<IDashboardCardProgressIndicator> = (props) => {
  const {color, progress = 0, hideFull = true} = props;
  const _percentage = useMemo(() => constrain(Math.floor(100 * progress), 0, 100), [progress]);

  return <div style={{width: '100%', height: '0.5em', position: 'relative'}}
              className={classNames(
                _baseProgressClass,
                _percentage <= 1 && `${_baseProgressClass}--empty`,
                hideFull && _percentage >= 99 && `${_baseProgressClass}--complete`
              )}>
    <figure
      style={{
        ..._progressBarStyle as any,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.075)'
      }}
      className={`${_baseProgressClass}__bg-bar`} />
    <figure
      style={{
        ..._progressBarStyle as any,
        width: `${_percentage}%`
      }}
      className={classNames(`${_baseProgressClass}__value-bar`, color && `${_baseProgressClass}__value-bar--${color}`)} />
  </div>;
};
