import React from 'react';
import { classNames, UiIcon, UiOptionallyLabelled, UiTextLine } from '@bitsolve/react-common';
import {NavLink} from 'react-router-dom';
import {useNotificationCenterVisibility} from '../misc/app-prefabs';
import {useAuthAccount} from '../../../module/auth/auth.store';
import {isArray, keys, prop} from '@bitsolve/fns';
import {NotificationBell} from '../../../module/notification/component/notification-bell.component';
import {useUnreadNotificationsCount} from '../../../module/notification/notification.store';

export interface IAppLayoutNavigationItem extends UiOptionallyLabelled {
  to: string | [string, any | undefined | null];
  exact?: boolean;
  disabled?: boolean;
  external?: boolean;
}

export interface IAppLayoutNavigation {
  showLogo?: boolean;
  showNotifications?: boolean;
  perspectiveMenu: IAppLayoutNavigationItem[];
  mainMenu: IAppLayoutNavigationItem[];
  sessionMenu: IAppLayoutNavigationItem[];
}


const replacePathParams = (path: string, params: any): string => {
  return keys(params)
    .reduce(
      (p: string, k: keyof typeof params) => {
        const v = prop(params, k);
        return v ? p.replaceAll(`:${k as any}`, v) : p;
      },
      path
    );
};

const coerceToPath = (to: string | [string, any]): string => {
  const [_to, _params] = isArray(to) ? to : [to, null];
  return replacePathParams(_to, _params);
};

const AppLayoutNavigationItem: React.FC<IAppLayoutNavigationItem> = (props) => {
  const {...item} = props;
  const slotClass = `app-nav__decor`;

  return <NavLink exact={!!item?.exact}
                  to={item.external ? {pathname: coerceToPath(item.to)} : coerceToPath(item.to)}
                  className={item.disabled ? 'disabled' : ''}
                  target={item.external ? '_blank': '_self'}
                  aria-disabled={item.disabled}
                  title={item.label}
                  onClick={ev => {
                    if (!item?.disabled) {
                      return;
                    }

                    ev.persist();
                    ev.preventDefault();
                  }}>
    <span className={`${slotClass} ${slotClass}--before`} />
    {item.icon && <UiIcon name={item.icon} iconSet={'regular'} />}
    {item.label && <UiTextLine text={item.label} />}
    <span className={`${slotClass} ${slotClass}--after`} />
  </NavLink>;
};

export const AppLayoutNavigation: React.FC<IAppLayoutNavigation> = (props) => {
  const {perspectiveMenu, mainMenu, sessionMenu, showLogo = true, showNotifications = true} = props;

  const {visible, setVisible} = useNotificationCenterVisibility();
  const unreadCount = useUnreadNotificationsCount();
  const account = useAuthAccount();

  return <>
    {showLogo && <UiIcon className={'app-nav-logo'}
                         name={'appLogoInitial'}
                         iconSet={'solid'} />}
    {showNotifications && <NotificationBell interact={() => setVisible(!visible)}
                                            unreadCount={unreadCount}
                                            className={classNames('app-nav-notifications-toggle', visible && 'active')} />}
    <menu className={'app-nav app-nav__perspective'}
          onClick={() => visible && setVisible(false)}>
      {perspectiveMenu.map((item, index) => <AppLayoutNavigationItem key={`${index}.${item.to}`} {...item} />)}
    </menu>
    <menu className={'app-nav app-nav__main'}
          onClick={() => visible && setVisible(false)}>
      {mainMenu.map((item, index) => <AppLayoutNavigationItem key={`${index}.${item.to}`} {...item} />)}
    </menu>
    {account && <footer className="app-nav app-nav__session"
                        onClick={() => visible && setVisible(false)}>
      {sessionMenu.map((item, index) => <AppLayoutNavigationItem key={`${index}.${item.to}`} {...item} />)}
      {/*<AppLayoutNavigationSession />*/}
    </footer>}
  </>;
};
