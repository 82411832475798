import React, {useMemo} from 'react';
import {UiCustomizable} from '@bitsolve/react-common';
import {appLoadingSpinnerimageData} from './app-loading-spinner';

export interface IAppLoadingSpinner {
  size?: 'sm' | 'nm' | 'lg';
}

const sizes = ['sm', 'nm', 'lg'];


export const AppLoadingSpinner: React.FC<IAppLoadingSpinner & UiCustomizable> = (props) => {
  const {className, style, size = 'nm'} = props;

  const sizePx = useMemo(() => {
    const si = sizes.findIndex(s => s === size);
    return 16 * (si + 1);
  }, [size]);

  const dims = {width: sizePx, height: sizePx};

  return <div
    style={{
      top: `calc(50% - ${sizePx / 2}px)`,
      left: `calc(50% - ${sizePx / 2}px)`,
      ...style,
      ...dims,
      backgroundImage: `url(${appLoadingSpinnerimageData})`
    }}
    className={'app-loading-spinner ' + (className || '')}
  />;
};
