import {IAnalysisSessionV2} from '../../../../../module/analysis/analysis.model';
import {IAnalysisSegmentType} from '../../../../../module/analysis/component/analysis-details.model';
import {dissoc, isNotNil, isNum, prop, propIn} from '@bitsolve/fns';
import React from 'react';
import {
  chartColorWheel,
  disciplineColor,
  metricVelocityDataConverter,
  Rooq,
  trainingSessionColor
} from '../../../../../core/domain.model';
import {AnalysisSessionAuthor} from '../../../../../module/analysis/component/analysis-session-author.component';
import {IRoute} from '../../../../../core/routing';
import {ILRUnitChart} from '../../../../../module/analysis/component/chart/lr-unit-chart.component';
import {chart, segment} from '../../../../../module/analysis/component/analysis-details.builder';
import {
  ICoachingZoneAnalysisDetailsContext,
  RouteExtractedUiExercise,
  RouteExtractedUiRound
} from './coaching-zone-analysis.model';
import {tryParseNumber} from '../../../../../core/util';
import {createExerciseAnalysisSegmentsFromSessionsV2} from './coaching-zone-exercise-analysis';
import {sortByPunchType} from 'src/module/analysis/component/analysis-details.hooks';


const createPunchPowerAverageData = (
  context: ICoachingZoneAnalysisDetailsContext,
  session: IAnalysisSessionV2,
  index: number,
  punchType: string
) => {
  if (!session) {
    return null;
  }

  const punch = session.ui.distributions.avgPowerAtMaxSpeed.values.find(val => {
    return val.label === punchType;
  });

  if (!punch) {
    return null;
  }

  const {leftValue, rightValue} = punch;
  const color = trainingSessionColor(context.style, index);

  return {
    leftValue,
    rightValue,
    unit: context.translate('app.analysis.table.unit.power'),
    color,
    centerContent: <AnalysisSessionAuthor color={color}
                                          mediaId={session.author.profilePictureId}
                                          size={3} />,
  };
};

const createPunchPowerMaxData = (
  context: ICoachingZoneAnalysisDetailsContext,
  session: IAnalysisSessionV2,
  index: number,
  punchType: string
) => {
  if (!session) {
    return null;
  }

  const punch = session.ui.distributions.bestPowerAtMaxSpeed.values.find(val => {
    return val.label === punchType;
  });

  if (!punch) {
    return null;
  }

  const {leftValue, rightValue} = punch;
  const color = trainingSessionColor(context.style, index);

  return {
    leftValue,
    rightValue,
    unit: context.translate('app.analysis.table.unit.power'),
    color,
    centerContent: <AnalysisSessionAuthor color={color}
                                          mediaId={session.author.profilePictureId}
                                          size={3} />,
  };
};

const createPunchSpeedAverageData = (
  context: ICoachingZoneAnalysisDetailsContext,
  session: IAnalysisSessionV2,
  index: number,
  punchType: string
) => {
  if (!session) {
    return null;
  }

  const punch = session.ui.distributions.avgMaxSpeed.values.find(val => {
    return val.label === punchType;
  });

  if (!punch) {
    return null;
  }

  const {leftValue, rightValue} = punch;
  const color = trainingSessionColor(context.style, index);

  return {
    leftValue,
    rightValue,
    unit: context.translate(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
    color,
    centerContent: <AnalysisSessionAuthor color={color}
                                          mediaId={session.author.profilePictureId}
                                          size={3} />,
  };
};

const createPunchSpeedMaxData = (
  context: ICoachingZoneAnalysisDetailsContext,
  session: IAnalysisSessionV2,
  index: number,
  punchType: string
) => {
  if (!session) {
    return null;
  }

  const punch = session.ui.distributions.bestMaxSpeed.values.find(val => {
    return val.label === punchType;
  });

  if (!punch) {
    return null;
  }

  const {leftValue, rightValue} = punch;
  const color = trainingSessionColor(context.style, index);

  return {
    leftValue,
    rightValue,
    unit: context.translate(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
    color,
    centerContent: <AnalysisSessionAuthor color={color}
                                          mediaId={session.author.profilePictureId}
                                          size={3} />,
  };
};

const createPunchCountData = (
  context: ICoachingZoneAnalysisDetailsContext,
  session: IAnalysisSessionV2,
  index: number,
  punchType: string
) => {
  if (!session) {
    return null;
  }

  const punch = session.ui.distributions.punchCount.values.find(val => {
    return val.label === punchType;
  });

  if (!punch) {
    return null;
  }

  const {leftValue, rightValue} = punch;
  const color = trainingSessionColor(context.style, index);

  return {
    leftValue,
    rightValue,
    unit: context.translate(`app.analysis.details.chart.unit.count`),
    color,
    centerContent: <AnalysisSessionAuthor color={color}
                                          mediaId={session.author.profilePictureId}
                                          size={3} />,
  };
};


const createPunchCountDistributionDataItem = (
  context: ICoachingZoneAnalysisDetailsContext,
  index: number,
  punchType: string,
  param: string,
  extra: Partial<ILRUnitChart> = {
    leftTitle: context.translate('app.analysis.details.chart.left'),
    rightTitle: context.translate('app.analysis.details.chart.right'),
    showDataAggregate: false
  }
) => {
  const {translate: t, navigate: nav, selection, sessions} = context;

  return {
    menu: {
      label: t(`content.punchType.${punchType}`),
      param,
      interact: (route: IRoute) => {
        const tab = propIn(route, ['match', 'params', 'tab']);
        nav(`${tab}/${param}`);
      }
    },
    chart: chart('lr-unit')
      .withProps({
        data: selection
          .map((sid, sidx) => {
            const session = prop(sessions, sid);
            return createPunchCountData(context, session, sidx, punchType);
          }).filter(isNotNil) as any,
        ...extra
      })
      .build(),
  }
};

const excercisesForSession = (sess?: IAnalysisSessionV2, base: Set<any> = new Set()) => {
  return sess?.ui?.exercises
    ?.filter(e => e.type !== Rooq.DisciplineV2.UNKNOWN_ACTIVITY)
    ?.reduce(
      (s, e) => {
        s.add(e.type);
        return s;
      },
      base
    );
};

const excercisesForSessions = (sessions?: IAnalysisSessionV2[]) => {
  return sessions?.reduce(
    (s, sess) => excercisesForSession(sess, s) as any,
    new Set()
  );
}

const detailDrilldownExcerciseLegend = (
  context: ICoachingZoneAnalysisDetailsContext,
  sess?: IAnalysisSessionV2,
  ...rest: IAnalysisSessionV2[]
) => {
  const exercs = excercisesForSessions(sess ? [sess, ...rest] : rest);

  return exercs
    ? Array.from(exercs.values())
      .map(type => ({
        label: context.translate(`content.discipline.${type}`),
        color: disciplineColor(type as any)
      }))
    : undefined;
};

const routeParam = (route: IRoute, p: string): any => prop(route?.match?.params, p);

const createOverviewSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {selection, sessions, navigate: nav, translate: t} = context;
  const selectedSessions = selection.map(s => prop(sessions, s)).filter(isNotNil);

  const exerciseFromRoute = (route: IRoute): null | RouteExtractedUiExercise => {
    const e = routeParam(route, 'exercise');
    if (!e) return null;
    const [si, ei] = e.split(';');
    const sessIdx = tryParseNumber(si);
    const exIdx = tryParseNumber(ei);

    if (isNum(sessIdx) && isNum(exIdx)) {
      const sessId = selection[sessIdx - 1];
      const sess = sessions[sessId];
      const ex = sess.ui.exercises[exIdx - 1];

      return ex
        ? {
          ...ex,
          index: exIdx - 1,
          sessionIndex: sessIdx - 1,
          session: sess
        }
        : null;
    } else {
      return null;
    }
  };
  const roundIndexFromRoute = (route: IRoute): null | number => {
    const p = routeParam(route, 'round');
    const pn = tryParseNumber(p);
    return pn ? Math.max(0, pn - 1) : null;
  }

  const roundFromRoute = (route: IRoute): null | RouteExtractedUiRound => {
    const ex = exerciseFromRoute(route);
    const roundIdx = roundIndexFromRoute(route);
    const round = ex?.data && isNum(roundIdx) ? prop(ex.data, roundIdx + 1) : null;

    return round && ex ? {
      ...round as any,
      index: roundIdx,
      session: ex.session,
      sessionIndex: ex.sessionIndex,
      exercise: dissoc(ex, 'session', 'sessionIndex'),
    } : null;
  }

  return [
    segment('single')
      .chart('multi-line', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.score.title')
        })
        .withProps({
          data: selectedSessions.map((session) => {
            return {
              label: session.author.firstName,
              data: session.ui.sessionTimelines.rooqScore.values.map((val) => ({
                section: val.x,
                value: val.y
              })),
            };
          }),
          unitY: t('app.analysis.details.chart.score.title'),
          unitX: t('general.unit.minute.medium')
        }))
      .build(),
    segment('single')
      .chart('drilldown', drilldown => drilldown
        .withProps({
          stages: [
            {
              route: {
                pattern: '/portal/analysis/compare/:tab/:exercise?/:metric?/:round?',
              },
              segments: [
                segment('single')
                  .chart('heading', builder => builder.withProps({
                    text: t('app.analysis.details.title'),
                    headingLevel: 2
                  }))
                  .build(),
                segment('single')
                  .chart('bar-segments', builder => builder
                    .withHeader({
                      title: t('app.analysis.details.chart.discipline-timing.title')
                    })
                    .withProps({
                      data: selectedSessions.map((sess, sessionIndex) => {
                        return {
                          label: sess?.author?.firstName,
                          segments: sess?.ui?.exercises?.map((ex, exIndex) => {

                            return {
                              label: ex.type,
                              value: ex.duration,
                              color: disciplineColor(ex.type),
                              routable: ex.type !== Rooq.DisciplineV2.ROPE_SKIPPING,
                              routed: (route: IRoute) => {
                                const ex = exerciseFromRoute(route);
                                return ex?.sessionIndex === sessionIndex
                                  && ex?.index === exIndex;
                              },
                              sessionIndex
                            }
                          })
                        }
                      }),
                      legend: detailDrilldownExcerciseLegend(context, selectedSessions[0], ...selectedSessions.slice(1)),
                      segmentStyle: 'regular',
                      showDataAggregate: false,
                      showDataLabel: true,
                      onSegmentClick: (seg, idx, route) => {
                        const exercise = `${(seg.sessionIndex || 0) + 1};${idx + 1}`;
                        const tab = routeParam(route, 'tab');
                        nav(`${tab}/${exercise}`);
                      }
                    }))
                  .build(),
              ]
            },
            {
              route: {
                pattern: '/portal/analysis/compare/:tab',
                exact: true,
              },
              segments: [
                segment('single')
                  .chart('heading', builder => builder.withProps({
                    text: t('app.analysis.details.message.select-exercise'),
                  }))
                  .build(),
              ]
            }
          ]
        }))
      .build(),
    ...createExerciseAnalysisSegmentsFromSessionsV2(
      context,
      exerciseFromRoute,
      roundFromRoute,
    )
  ];
};

const createPunchesSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {selection, sessions, translate: t} = context;
  const selectedSessions = selection.map(s => prop(sessions, s)).filter(isNotNil);
  return [
    segment('single')
      .chart('multi-line', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count.title')
        })
        .withProps({
          data: selectedSessions.map(sess => {

            return {
              label: sess.author.firstName,
              data: sess.ui.sessionTimelines.punchCount.values.map(({y, x}) => ({
                section: x,
                value: y
              }))
            };
          }),
          unitY: t('app.analysis.details.chart.unit.count'),
          unitX: t('general.unit.minute.medium')
        }))
      .build(),
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: '/portal/analysis/compare/:tab/:punchType?'
            },
            segments: [
              segment('single')
                .chart('multi-routed', builder => builder.withProps({
                  router: {
                    param: 'punchType',
                    pattern: '/portal/analysis/compare/:tab/:punchType?',
                    redirect: {
                      exact: true,
                      from: '/portal/analysis/compare/:tab',
                      to: '/portal/analysis/compare/punches/straight'
                    }
                  },
                  header: {
                    title: t('app.analysis.details.chart.punch-count-by-type.title')
                  },
                  items: [
                    ['STRAIGHT', 'straight'],
                    ['BODY_SHOT', 'body-shot'],
                    ['HOOK', 'hook'],
                    ['UPPERCUT', 'uppercut'],
                  ]
                    .sort(([a], [b]) => sortByPunchType(a as any, b as any))
                    .map(([type, param], i) => createPunchCountDistributionDataItem(context, i, type, param))
                }))
                .build()
            ]
          }
        ]
      }))
      .build(),
    segment('single')
      .chart('bar-segments', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count-by-discipline.title')
        })
        .withProps({
          data: selection
            .map(sid => prop(sessions, sid))
            .filter(isNotNil)
            .map((session) => {

              return {
                label: session?.author?.firstName,
                segments: session?.ui?.countsGroupedBy?.exerciseType?.charts?.map((chart) => {
                  return {
                    label: chart.label,
                    value: chart.count,
                    color: disciplineColor(chart.label),
                  };
                }) || [],
              };
            }),
          legend: selectedSessions[0]?.ui?.countsGroupedBy?.exerciseType?.charts?.map(({label}) => ({
            label: t(`content.discipline.${label}`),
            color: disciplineColor(label),
          })),
          unit: t('app.analysis.details.chart.unit.count'),
          segmentStyle: 'regular',
          showDataLabel: true,
          showDataAggregate: true,
        }))
      .build(),
    segment('single')
      .chart('bar-segments', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count-by-combo.title')
        })
        .withProps({
          data: selectedSessions
            .filter(isNotNil)
            .map((session) => {

              return {
                label: session?.author?.firstName,
                segments: session?.ui?.countsGroupedBy?.comboLength?.charts?.map((chart, i) => {
                  return {
                    label: chart.label,
                    value: chart.count,
                    color: chartColorWheel(i),
                  };
                }) || [],
              };
            }),
          legend: selectedSessions[0]?.ui?.countsGroupedBy?.comboLength?.charts?.map(({label}, i) => ({
            label: t(`content.combo.${label}`),
            color: chartColorWheel(i)
          })),
          unit: t('app.analysis.details.chart.unit.count'),
          segmentStyle: 'regular',
          showDataLabel: true,
          showDataAggregate: true,
        }))
      .build()
  ];
};

const createSpeedSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {selection, sessions, translate: t, navigate: nav} = context;
  const selectedSessions = selection.map(s => prop(sessions, s)).filter(isNotNil);
  const convertSpeedData = metricVelocityDataConverter(context.unitSystem);

  return [
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: '/portal/analysis/compare/:tab/:metric?/:punchType?',
            },
            segments: [
              segment('single')
                .chart('multi-routed', builder => builder
                  .withProps({
                    router: {
                      param: 'metric',
                      pattern: '/portal/analysis/compare/:tab/:metric?/:punchType?',
                      redirect: {
                        exact: true,
                        from: '/portal/analysis/compare/:tab',
                        to: '/portal/analysis/compare/speed/average'
                      }
                    },
                    items: [
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.speed.label')}),
                          interact: (route) => {
                            const tab = routeParam(route, 'tab');
                            nav(`${tab}/average/straight`);
                          },
                          param: 'average',
                        },
                        chart: chart('multi-routed')
                          .withProps({
                            header: {
                              title: t('app.analysis.details.chart.punch-type-distribution.title')
                            },
                            router: {
                              param: 'punchType',
                              pattern: '/portal/analysis/compare/:tab/:metric/:punchType?',
                              redirect: {
                                exact: true,
                                from: '/portal/analysis/compare/:tab/:metric',
                                to: route => {
                                  const tab = routeParam(route, 'tab');
                                  const metric = routeParam(route, 'metric');
                                  console.log(tab, metric);
                                  return `/portal/analysis/compare/${tab}/${metric}/straight`;
                                }
                              }
                            },
                            items: [
                              {
                                menu: {
                                  label: t(`content.punchType.STRAIGHT`),
                                  param: 'straight',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/straight`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedAverageData(context, session, sidx, 'STRAIGHT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.HOOK`),
                                  param: 'hook',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/hook`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedAverageData(context, session, sidx, 'HOOK');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.UPPERCUT`),
                                  param: 'uppercut',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/uppercut`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedAverageData(context, session, sidx, 'UPPERCUT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.BODY_SHOT`),
                                  param: 'body-shot',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/body-shot`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedAverageData(context, session, sidx, 'BODY_SHOT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                            ]
                          })
                          .build()
                      },
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.max', {unit: t('app.analysis.details.chart.unit.speed.label')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/max`);
                          },
                          param: 'max'
                        },
                        chart: chart('multi-routed')
                          .withProps({
                            header: {
                              title: t('app.analysis.details.chart.punch-type-distribution.title')
                            },
                            router: {
                              param: 'punchType',
                              pattern: '/portal/analysis/compare/:tab/:metric/:punchType?',
                              redirect: {
                                exact: true,
                                from: '/portal/analysis/compare/:tab/:metric',
                                to: route => {
                                  const tab = routeParam(route, 'tab');
                                  const metric = routeParam(route, 'metric');
                                  console.log(tab, metric);
                                  return `/portal/analysis/compare/${tab}/${metric}/straight`;
                                }
                              }
                            },
                            items: [
                              {
                                menu: {
                                  label: t(`content.punchType.STRAIGHT`),
                                  param: 'straight',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/straight`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedMaxData(context, session, sidx, 'STRAIGHT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.HOOK`),
                                  param: 'hook',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/hook`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedMaxData(context, session, sidx, 'HOOK');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.UPPERCUT`),
                                  param: 'uppercut',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/uppercut`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedMaxData(context, session, sidx, 'UPPERCUT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.BODY_SHOT`),
                                  param: 'body-shot',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/body-shot`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchSpeedMaxData(context, session, sidx, 'BODY_SHOT');
                                      })
                                      .map(d => convertSpeedData(d, 'leftValue', 'rightValue'))
                                      .filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                            ]
                          })
                          .build()
                      }
                    ]
                  }))
                .build(),
              segment('single')
                .chart('bar-segments', builder => builder
                  .withHeader({
                    title: t('app.analysis.details.chart.punch-count-by-speed-groups.title')
                  })
                  .withProps({
                    data: selection
                      .map(sid => prop(sessions, sid))
                      .filter(isNotNil)
                      .map((session) => {

                        return {
                          label: session.author.firstName,
                          segments: session.ui.countsGroupedBy.maxSpeed.charts.map(({label, count}, i) => ({
                            discipline: label,
                            value: count,
                            color: chartColorWheel(i),
                            routed: false,
                          } as any))
                        };
                      }),
                    legend: selectedSessions[0]?.ui?.countsGroupedBy?.maxSpeed?.charts?.map(({label}, i) => {
                      return {
                        label: `content.speedGroup.${label}.${context.unitSystem}`,
                        color: chartColorWheel(i)
                      }
                    }),
                    unit: t('app.analysis.details.chart.unit.count'),
                    segmentStyle: 'regular',
                    showDataLabel: true,
                    showDataAggregate: true,
                  }))
                .build()
            ]
          }
        ]
      }))
      .build(),
  ];
};

const createPowerSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {selection, sessions, translate: t, navigate: nav} = context;
  return [
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: '/portal/analysis/compare/:tab/:metric?/:punchType?',
              redirect: {
                exact: true,
                from: '/portal/analysis/compare/:tab',
                to: '/portal/analysis/compare/power/average'
              }
            },
            segments: [
              segment('single')
                .chart('multi-routed', builder => builder
                  .withProps({
                    router: {
                      param: 'metric',
                      pattern: '/portal/analysis/compare/:tab/:metric?/:punchType?'
                    },
                    items: [
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.power')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/average/straight`);
                          },
                          param: 'average',
                        },
                        chart: chart('multi-routed')
                          .withProps({
                            header: {
                              title: t('app.analysis.details.chart.punch-type-distribution.title')
                            },
                            router: {
                              param: 'punchType',
                              pattern: '/portal/analysis/compare/:tab/:metric/:punchType?',
                              redirect: {
                                exact: true,
                                from: '/portal/analysis/compare/:tab/:metric',
                                to: route => {
                                  const tab = routeParam(route, 'tab');
                                  const metric = routeParam(route, 'metric');
                                  return `/portal/analysis/compare/${tab}/${metric}/straight`;
                                }
                              }
                            },
                            items: [
                              {
                                menu: {
                                  label: t(`content.punchType.STRAIGHT`),
                                  param: 'straight',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/straight`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerAverageData(context, session, sidx, 'STRAIGHT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.HOOK`),
                                  param: 'hook',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/hook`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerAverageData(context, session, sidx, 'HOOK');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.UPPERCUT`),
                                  param: 'uppercut',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/uppercut`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerAverageData(context, session, sidx, 'UPPERCUT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.BODY_SHOT`),
                                  param: 'body-shot',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/body-shot`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerAverageData(context, session, sidx, 'BODY_SHOT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                            ]
                          })
                          .build()
                      },
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.max', {unit: t('app.analysis.details.chart.unit.power')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/max`);
                          },
                          param: 'max'
                        },
                        chart: chart('multi-routed')
                          .withProps({
                            header: {
                              title: t('app.analysis.details.chart.punch-type-distribution.title')
                            },
                            router: {
                              param: 'punchType',
                              pattern: '/portal/analysis/compare/:tab/:metric/:punchType?',
                              redirect: {
                                exact: true,
                                from: '/portal/analysis/compare/:tab/:metric',
                                to: route => {
                                  const tab = routeParam(route, 'tab');
                                  const metric = routeParam(route, 'metric');
                                  return `/portal/analysis/compare/${tab}/${metric}/straight`;
                                }
                              }
                            },
                            items: [
                              {
                                menu: {
                                  label: t(`content.punchType.STRAIGHT`),
                                  param: 'straight',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/straight`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerMaxData(context, session, sidx, 'STRAIGHT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.HOOK`),
                                  param: 'hook',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/hook`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerMaxData(context, session, sidx, 'HOOK');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.UPPERCUT`),
                                  param: 'uppercut',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/uppercut`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerMaxData(context, session, sidx, 'UPPERCUT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                              {
                                menu: {
                                  label: t(`content.punchType.BODY_SHOT`),
                                  param: 'body-shot',
                                  interact: route => {
                                    const tab = propIn(route, ['match', 'params', 'tab']);
                                    const metric = propIn(route, ['match', 'params', 'metric']);
                                    nav(`${tab}/${metric}/body-shot`);
                                  }
                                },
                                chart: chart('lr-unit')
                                  .withProps({
                                    data: selection
                                      .map((sid, sidx) => {
                                        const session = prop(sessions, sid);
                                        return createPunchPowerMaxData(context, session, sidx, 'BODY_SHOT');
                                      }).filter(isNotNil) as any,
                                    leftTitle: t('app.analysis.details.chart.left'),
                                    rightTitle: t('app.analysis.details.chart.right'),
                                    showDataAggregate: false
                                  })
                                  .build(),
                              },
                            ]
                          })
                          .build()
                      }
                    ]
                  }))
                .build()
            ]
          }
        ]
      }))
      .build(),
  ];
};

export const createMultiAnalysisSegmentsFromSessionsV2 = (
  context: ICoachingZoneAnalysisDetailsContext
): Record<'overview' | 'punches' | 'speed' | 'power', IAnalysisSegmentType[]> => {
  return {
    overview: createOverviewSegments(context),
    punches: createPunchesSegments(context),
    speed: createSpeedSegments(context),
    power: createPowerSegments(context),
  };
}
