import React, {useEffect, useMemo} from 'react';
import {
  IHttpRequest,
  ISelectFieldControlOption,
  IUiFieldProps,
  useHttpRequest,
  useTranslator
} from '@bitsolve/react-common';
import {isArray, isFn, isNil, update} from '@bitsolve/fns';
import {UiSelectControl} from '../../ui/component/ui-select-control.component';

export interface IContentSelectControl extends IUiFieldProps<string[]> {
  controlProps: {
    optionRequest: IHttpRequest | (() => IHttpRequest);
    optionMapper?: (data: any) => ISelectFieldControlOption;
    className?: string;
    style?: any;
  }
}

export const ContentSelectControl: React.FC<IContentSelectControl> = (props) => {
  const {controlProps, value, onChange, ...rest} = props;
  const {optionRequest, optionMapper, className, style} = controlProps || {};

  const t = useTranslator();

  const _optsReq = useHttpRequest<any[]>(
    isFn(optionRequest) ? optionRequest() : optionRequest,
    {initialFetch: true}
  );

  useEffect(() => {
    return () => {
      if (_optsReq.busy) {
        // this seems to get rid of react's memory leak warning, and also seems to not
        // break everything ... this'll be the line to remove if stuff goes wrong
        _optsReq.cancel();
      }
    };
  }, [_optsReq]);

  const _rawOpts = _optsReq?.response?.data;

  const _opts = useMemo(
    () => {
      const opts = optionMapper && _rawOpts
        ? (isArray(_rawOpts)
          ? _rawOpts?.map(optionMapper)
          : (optionMapper(_rawOpts) as unknown as ISelectFieldControlOption[]))
        : [];

      return opts && isArray(opts)
        ? opts.map(o => update(o, 'label', (label: string) => t(label)))
        : [];
    },
    [t, _rawOpts, optionMapper]
  );

  if (isNil(optionRequest)) {
    return null;
  }

  return <UiSelectControl {...rest}
                          busy={_optsReq.busy}
                          className={className}
                          style={style}
                          value={value}
                          onChange={onChange}
                          options={_opts} />
};
