import {UiColor} from '@bitsolve/react-common';
import React, {createContext, useContext} from 'react';
import {UiSplashMessage} from '../../../module/ui/component/ui-splash-message.component';
import {AppLoadingSpinner} from './app-loading-spinner.component';

export const appInitializing = <div style={{height: '100%', flex: 1}}
                                    className={'d-f fd-v f-ai-c f-jc-c'}>
  <AppLoadingSpinner />
</div>;

export const appNetworkError = <div style={{height: '100%', flex: 1}}
                                    className={'d-f fd-v f-ai-c f-jc-c'}>
  <UiSplashMessage title={'Netzwerkproblem'}
                   messages={[
                     'Der Applikationsserver konnte nicht erreicht werden.',
                     'Falls das Problem bestehen bleibt, kontaktiere uns bitte unter support@rooq.de.'
                   ]}
                   actions={[
                     {
                       label: 'Erneut versuchen',
                       color: UiColor.accent,
                       interact: () => window.location.reload()
                     }
                   ]} />
</div>;


export const NotificationCenterVisibleContext = createContext<[boolean, (x: boolean) => any]>([false, () => null]);

export const useNotificationCenterVisibility = () => {
  const [visible, setVisible] = useContext(NotificationCenterVisibleContext);
  return {visible, setVisible};
};
