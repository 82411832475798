import {ILifecycle, System} from '@thi.ng/system';
import {AxiosClient, go} from '@bitsolve/react-common';
import {hasProp, isNil, isNotNil, keys, prop} from '@bitsolve/fns';
import {API_PREFIX} from './constants';
import {createContext, createElement, useContext} from 'react';
import {http} from './api';


export interface IAppDataAuthSDKConfig {
  apiKey: string;
  appId: string;
  authDomain: string;
  databaseURL: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
}

export interface IAppDataHealthCheck {
  url: string;
  timeout?: number;
}

export interface IAppDataContext {
  host: string;
  continueUrl: string;
  shopUrl: string;
  sdkConfig: IAppDataAuthSDKConfig;
  localeProviders: string[];
  data?: {
    devTools?: boolean;
  };
  health?: Record<'backend' | 'maintenance', IAppDataHealthCheck>;
}

type IAppData = IAppDataContext
  | { error: any; renderError: React.FC<{ error: any; }>; }
  | null;

export const AppDataContext = createContext<IAppData>(null);
export const AppDataConsumer = AppDataContext.Consumer;

export const useAppData = () => useContext<IAppDataContext>(AppDataContext as any);

export const AppDataProvider: React.FC<IAppData> = (props) => {
  const {children, ...rest} = props;

  if (hasProp(rest, 'error')) {
    const render = prop(rest, 'renderError');

    if (isNil(render)) {
      return null;
    } else {
      const _props = {...rest, key: 'error'};
      return createElement(render, _props as any);
    }
  } else {
    const _props = {...rest, key: 'ok'};

    return createElement(
      AppDataContext.Provider,
      {value: _props},
      children
    );
  }
};

export type AppDataLoader<C = any> = () => Promise<C>;

const registerIconReporter = <T = any>(system: System<T>) => {
  const iconManager = prop<any, { icons: { regular: any; solid: any }[]; }>(system.components, '@bitsolve/system/ui-icon-manager');
  const icons = iconManager.icons;
  const reportIcons = () => {
    const allRegular = icons.map(ics => ics.regular).reduce((r, i) => ([...r, ...keys(i)]), []);
    console.groupCollapsed('icon set: regular');
    console.log(allRegular.join('\n'));
    console.groupEnd();

    const allSolid = icons.map(ics => ics.regular).reduce((r, i) => ([...r, ...keys(i)]), []);
    console.groupCollapsed('icon set: solid');
    console.log(allSolid.join('\n'));
    console.groupEnd();
  };
  // @ts-ignore
  window.reportIcons = reportIcons;
  console.log(`Type 'reportIcons()' to get a listing of registered icons`);
}

export const APP_GLOBAL_KEY = 'rooq';

export class AppDataComponent<C = any, T = any> implements ILifecycle {

  protected config?: C;

  protected system?: System<T>;

  protected enableIconReporter = false;

  protected enableGlobalHook = false;

  private alias: string = APP_GLOBAL_KEY;

  constructor(protected configLoader: AppDataLoader<C>) {
  }

  start(): Promise<boolean> {
    return this.configLoader()
      .then(config => {
        this.config = config;
        return go(config);
      })
      .then(system => {
        this.system = system;
        if (this.enableIconReporter) {
          registerIconReporter(system);
        }
        if (this.enableGlobalHook) {
          // @ts-ignore
          window[this.alias] = {
            instance: () => this.instance(),
            stop: () => isNil(this.system)
              ? Promise.resolve(true)
              : this.stop(),
            restart: () => isNil(this.system)
              ? this.start()
              : this.stop().then(() => this.start())
          };
        }
        return Promise.resolve(true);
      })
      .catch(e => {
        console.error(e);
        this.config = undefined;
        this.system = undefined;
        if (this.enableGlobalHook) {
          // @ts-ignore
          window[this.alias] = undefined;
        }
        return Promise.reject(e);
      });
  }

  stop(): Promise<boolean> {
    return isNotNil(this.system)
      ? (this.system as System<T>).stop()
      : Promise.resolve(true);
  }

  withIconReporter(): AppDataComponent<C, T> {
    this.enableIconReporter = true;
    return this as any;
  }

  withGlobalHook(): AppDataComponent<C, T> {
    this.enableGlobalHook = true;
    return this as any;
  }

  instance(): System<T> | undefined {
    return this.system;
  }
}

type AppGlobal = {
  instance: () => System<any>;
  stop: () => Promise<boolean>;
  restart: () => Promise<boolean>;
};

export const getAppGlobal = (): AppGlobal | null => {
  const i = prop(window, APP_GLOBAL_KEY);
  return i && hasProp(i, 'instance')
    ? i as AppGlobal
    : null;
};

const defaultAppDataUrl = `${API_PREFIX}/v1/content/static/cz/config`;

export const createAppDataLoader = <C = any>(http: AxiosClient, url: string = defaultAppDataUrl): AppDataLoader<C> => {
  return () => http.get<C>(url).then(res => {
    if (!res || !hasProp(res, 'data')) {
      return Promise.reject(new Error('no response data'));
    }
    return prop(res, 'data');
  });
};

export const createAppDataComponent = <C = any>(loader: AppDataLoader<C>): AppDataComponent<C> =>
  new AppDataComponent<C>(loader);

export type IAppDataFn = (http: AxiosClient, config: IAppDataContext) => any;

export type IAppFatalErrorFn = (http: AxiosClient, error: any) => any;

export const createApp = <C = any>(
  dataFn: IAppDataFn,
  fatalErrorFn: IAppFatalErrorFn,
): AppDataComponent<C> => {
  const baseLoader = createAppDataLoader(http);

  const loader = () => baseLoader()
    .then(config => dataFn(http, config))
    .catch(error => fatalErrorFn(http, error));

  return createAppDataComponent(loader);
};
