import React from 'react';
import {
  UiBox,
  UiButtonGroup,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexCol,
  UiFlexRow,
  UiPageContainer,
  UiPageSegment,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {
  ClubCardGrid,
  ClubMembershipCard,
  ClubMembershipInvitationCard
} from '../../../../module/club/component/club-card.component';
import {IRoute, useNavigator} from '../../../../core/routing';
import {ClubModalCardRoute} from '../../../../module/club/component/club-modal-card.component';
import {UiModalRouteOutlet} from '../../../../module/ui/component/ui-modal-route.component';
import {ClubSearchModal} from '../../../../module/club/component/club-search-modal.component';
import {
  findMyClubCandidatesRequest,
  useClubMembershipInvitationActions,
  useFindMyClubInvitations,
  useFindMyClubMemberships
} from '../../../../module/club/club.api';
import {Rooq} from '../../../../core/domain.model';
import ReactTooltip from 'react-tooltip';
import {useClubReportModal} from '../../../../module/club/component/club-report-modal.component';
import {useAuthAccount} from '../../../../module/auth/auth.store';
import {ClubCTACard} from '../../../../module/club/component/club-call-to-action-card.component';
import {useConfirmation} from '../../../../module/ui/component/ui-confirmation-modal-dialog.component';

export interface ICoachingZoneClubsRoute {
}


export const CoachingZoneClubsRoute: React.FC<ICoachingZoneClubsRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const user = useAuthAccount();

  const clubMembershipsReq = useFindMyClubMemberships(0, 10, {
    initialFetch: true,
    onData: () => ReactTooltip.rebuild()
  });
  const clubInvsReq = useFindMyClubInvitations(0, 10, Rooq.MembershipType.TRAINER, {initialFetch: true});
  const clubInvsReq2 = useFindMyClubInvitations(0, 10, Rooq.MembershipType.ATHLETE, {initialFetch: true});
  const reportModal = useClubReportModal({onClose: clubMembershipsReq.load});

  const memberships = clubMembershipsReq.response?.data?.elements;
  const invitedClubs = clubInvsReq?.response?.data?.elements;
  const invitedClubs2 = clubInvsReq2?.response?.data?.elements;
  const hasMemberships = (memberships?.length || 0) > 0;
  const hasInvs = (invitedClubs?.length || 0) > 0;
  const hasInvs2 = (invitedClubs2?.length || 0) > 0;
  const isClubsReady = !clubMembershipsReq.busy && !!clubMembershipsReq.lastFetchTime;
  const isClubInvsReady = !clubInvsReq.busy && !!clubInvsReq.lastFetchTime;
  const isClubInvsReady2 = !clubInvsReq2.busy && !!clubInvsReq2.lastFetchTime;
  const isOneReady = isClubsReady || isClubInvsReady;


  const loadAll = () => {
    !clubMembershipsReq.busy && clubMembershipsReq.load();
    !clubInvsReq.busy && clubInvsReq.load();
    !clubInvsReq2.busy && clubInvsReq2.load();
  };

  const {createInvitation} = useClubMembershipInvitationActions({onAny: loadAll});

  const createInvConfirmation = useConfirmation({
    title: t('app.club.action.request-membership.confirm.title'),
    messages: [t('app.club.action.request-membership.confirm.message')],
    onConfirm: club => createInvitation.send({
      clubId: club.id,
      accountId: user?.id as any,
      invitedAs: Rooq.MembershipType.TRAINER
    })
  });

  return <UiPageContainer fluid className={'app-coachz app-coachz__clubs'}>
    <AppPageHeader title={t('app.club.title')} />
    <UiPageSegment
      className={isOneReady
        ? 'app-coachz__clubs__cards'
        : 'app-coachz__clubs__cards app-coachz__clubs__cards--initializing'}>
      <UiFlexCol f={isOneReady || hasMemberships ? 'auto' : 1}>
        {isClubsReady && !hasMemberships && <ClubCTACard src={'/asset/brand/app/training-plan-mood-alt-sd.png'}>
          <UiTextTitle headingLevel={2}
                       className={'txt-b'}
                       text={t('app.club.action.cta.title')} />
          <UiTextBlock
            className={'pd-t-nm pd-b-md txt-ac'}
            text={t('app.club.action.cta.message')}
            style={{margin: '0 15%'}} />
          <UiButtonGroup actions={[
            {
              label: t('app.club.action.request-membership.label'),
              color: UiColor.accent,
              className: 'txt-b',
              interact: () => nav('/portal/trainer/clubs/add-membership')
            },
            {
              label: t('app.club.action.create.label'),
              color: UiColor.primary,
              interact: () => nav('/portal/trainer/clubs/create')
            },
          ]} />
        </ClubCTACard>}
        {isClubsReady && hasMemberships && <>
          <UiBox className={'app-coachz__clubs__manager mg-v-nm'}>
            <UiFlexRow>
              <UiButtonGroup
                stretch
                className={'f-1'}
                direction={UiDir.v}
                color={UiColor.primary}
                actions={[
                  {
                    label: t('app.club.action.create.label'),
                    interact: () => nav('/portal/trainer/clubs/create')
                  }
                ]} />
              <UiBox className="f-1 pd-l-nm">
                <UiButtonGroup
                  stretch
                  className={'f-1'}
                  direction={UiDir.v}
                  color={UiColor.primary}
                  actions={[
                    {
                      label: t('app.club.action.request-membership.label'),
                      icon: 'plus',
                      color: UiColor.accent,
                      className: 'txt-b',
                      interact: () => nav('/portal/trainer/clubs/add-membership')
                    }
                  ]} />
              </UiBox>
            </UiFlexRow>
          </UiBox>
          <ClubCardGrid>
            {memberships?.map((mship) =>
              <ClubMembershipCard key={mship.membershipId}
                                  membership={mship}
                                  onUpdate={loadAll} />)}
          </ClubCardGrid>
        </>}
        {isClubInvsReady && hasInvs && <>
          <UiTextTitle className={'mg-t-md mg-b-nm'}
                       headingLevel={2}
                       text={t('app.club.open-requests.as-trainer.title')} />
          <ClubCardGrid>
            {invitedClubs?.map((invite) =>
              <ClubMembershipInvitationCard key={invite.id}
                                            invite={invite}
                                            onUpdate={loadAll} />)}
          </ClubCardGrid>
        </>}
        {isClubInvsReady2 && hasInvs2 && <>
          <UiTextTitle className={'mg-t-md mg-b-nm'}
                       headingLevel={2}
                       text={t('app.club.open-requests.as-athlete.title')} />
          <ClubCardGrid>
            {invitedClubs2?.map((invite) =>
              <ClubMembershipInvitationCard key={invite.id}
                                            invite={invite}
                                            onUpdate={loadAll} />)}
          </ClubCardGrid>
        </>}
      </UiFlexCol>
    </UiPageSegment>
    <UiModalRouteOutlet ownPath={'/portal/trainer/clubs'}
                        onUpdate={() => {
                          clubMembershipsReq.load();
                          clubInvsReq.load();
                        }}
                        routes={[
                          {
                            path: '/portal/trainer/clubs/details/:clubId',
                            render: ((route: IRoute) => {
                              const {match} = route;
                              const id = match?.params?.clubId;
                              return <ClubModalCardRoute {...route as any}
                                                         backPath={`/portal/trainer/clubs`}
                                                         ownPath={`/portal/trainer/clubs/details/${id}`} />;
                            }),
                          } as any,
                          {
                            path: '/portal/trainer/clubs/add-membership',
                            render: (route: IRoute) => {
                              const initTerm = '';
                              const baseSearch = findMyClubCandidatesRequest(0, 100, initTerm, Rooq.MembershipType.TRAINER);

                              return <ClubSearchModal
                                {...route}
                                onClose={() => route.history.push('/portal/trainer/clubs')}
                                onClubClick={(club) => route.history.push(`/portal/trainer/clubs/details/${club.id}`)}
                                initialTerm={initTerm}
                                baseRequest={baseSearch}
                                resultActions={[
                                  {
                                    icon: 'plus',
                                    size: 'sm',
                                    variation: UiButtonVariation.ghost,
                                    interact: (res) => {
                                      createInvConfirmation.setData(res.data);
                                      createInvConfirmation.open();
                                    }
                                  }
                                ]}>
                                {createInvConfirmation.element}
                              </ClubSearchModal>
                            }
                          }
                        ]} />
    {reportModal.element}
  </UiPageContainer>;
};
