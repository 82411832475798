import React, {useMemo} from 'react';
import {isNonEmptyStr, isNum, StringEncoding} from '@bitsolve/fns';
import {animated, useTrail} from 'react-spring';
import {UiCustomizable} from '@bitsolve/react-common';

export interface IUiValueParts extends UiCustomizable {
  value?: StringEncoding;
  splitFn?: (v: StringEncoding) => string[];
}

const defaultSplitFn: IUiValueParts['splitFn'] = (v) => isNonEmptyStr(v) || isNum(v)
  ? v.toString().split('')
  : ['–'];

export const UiValueParts: React.FC<IUiValueParts> = (props) => {
  const {value, splitFn, className, style} = props;
  const chars = useMemo(() => (splitFn || defaultSplitFn)(value), [splitFn, value]);
  const trail = useTrail(chars.length, {
    transform: 'translateY(0%)',
    opacity: 1,
    from: {transform: 'translateY(-15%)', opacity: 0},
    config: {
      mass: 1,
      tension: 170 * chars.length,
      friction: 16 * (chars.length / 2),
    }
  });

  return <div className={className}
              style={style}>
    {trail.map((anim, i) => {
      const char = chars[i];
      return <animated.span key={`${i}.${char}`}
                            style={anim}>
        {char === ' '
          ? <span className={'d-ib'} style={{width: '0.325em'}} />
          : char}
      </animated.span>;
    })}
  </div>;
};
