import React from 'react';
import {AppBannerPane} from '../../../app/common/misc/app-banner-pane.component';
import {classNames, UiCustomizable} from '@bitsolve/react-common';

export interface IClubCTACard extends UiCustomizable {
  src: string;
}

export const ClubCTACard: React.FC<IClubCTACard> = (props) => {
  const {src, children, style, className} = props;

  return <AppBannerPane className={classNames('app-club__card app-club__cta-card', className)}
                        style={style}
                        contentAnchor={'c'}
                        src={src}>
    {children}
  </AppBannerPane>;
};
