import React, {useState} from 'react';
import {IRoute} from '../../../../core/routing';
import {AppPageSectionPanel} from '../../../common/page/app-page-section-panel.component';
import {
  IUiFieldProps,
  toast,
  UiBox,
  UiButton,
  UiColor,
  UiDir,
  UiForm,
  UiFormField,
  UiPageSegment,
  UiState,
  UiTextBlock,
  UiTextTitle,
  useLocale,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {useAuthAccount, useAuthActions, useAuthUser} from '../../../../module/auth/auth.store';
import {IAuthUserAccount, useFirebaseAppContext, useFirebaseErrorCodeKey} from '../../../../module/auth';
import {isEmptyStr} from '@bitsolve/fns';
import {useFirebaseActionCodeSettings} from '../../../common/app-firebase.config';
import {useSafeEmailDisplay} from '../../../../core/util';
import {staticUnitsControlProps} from '../../../../module/content/content.api';
import {useUpdateMyAccount} from '../../../../module/auth/auth.api';
import {Rooq} from '../../../../core/domain.model';
import {changeLocale} from '../../../common/app-intl.config';

export interface ICoachingZoneSettingsAccountRoute extends IRoute {
}

const accountProfileCardFields: IUiFieldProps<IAuthUserAccount>[] = [
  {
    name: 'email',
    fieldType: 'text',
    label: 'app.account.profile.form.email.label',
    direction: UiDir.v,
    state: UiState.readonly
  },
  {
    name: 'locale',
    fieldType: 'locale-switcher',
    label: 'app.settings.account.form.locale.label',
    direction: UiDir.v,
    controlProps: {
      namespace: 'content.locale',
      availableLocales: ['de', 'en', 'es', 'ru'],
    }
  },
  {
    name: 'unit',
    fieldType: 'content-select',
    label: 'app.account.form.units.label',
    direction: UiDir.v,
    controlProps: staticUnitsControlProps,
  },
];

export const CoachingZoneSettingsAccountRoute: React.FC<ICoachingZoneSettingsAccountRoute> = (props) => {
  const u = useAuthUser();
  const ua = useAuthAccount();
  const t = useTranslator();
  const fb = useFirebaseAppContext();
  const locale = useLocale();
  const fields = useTranslatedFields(accountProfileCardFields);

  const [busy, setBusy] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<undefined | { code: string; }>(undefined);

  const actionCodeSettings = useFirebaseActionCodeSettings();
  const errorCodeKey = useFirebaseErrorCodeKey(error, {pre: 'app.settings.account.reset-password.error'});

  const accountActions = useAuthActions();
  const updateMyAccount = useUpdateMyAccount(
    () => toast.success(t('app.account.form.units.toast.success')),
    () => toast.error(t('app.account.form.units.toast.error')),
  );

  const rawEmail = u?.email as any as string;
  const email = useSafeEmailDisplay(rawEmail);

  if (!email || isEmptyStr(email)) {
    return null;
  }

  const i18nParams = {email};

  return <UiPageSegment>
    <AppPageSectionPanel className={'pd-nm ui-fx ui-fx--fast ui-fx__pane-fade-left'}
                         style={{overflow: 'visible', position: 'relative', zIndex: 10}}>
      <UiTextTitle text={t(`app.settings.account.title`)}
                   headingLevel={2} />
      <UiForm id={'account-card-form'}
              value={{
                email,
                locale,
                unit: ua?.unit || Rooq.UnitSystem.METRIC
              }}
              fields={fields}
              onFieldChange={(f, v) => {
                switch (f) {
                  case 'locale': {
                    changeLocale(v);
                    return;
                  }
                  case 'unit': {
                    updateMyAccount.send({unit: v} as any)
                      .then(r => accountActions.updateAccount(r.data));
                    return;
                  }
                  default: {
                    return;
                  }
                }
              }}
              interact={() => {
              }}>
        <UiBox className="mg-t-md mg-b-nm">
          <UiFormField name={'email'} />
        </UiBox>
        <UiBox className="mg-t-md mg-b-nm">
          <UiFormField name={'locale'} />
        </UiBox>
        <UiBox className="mg-t-md mg-b-nm">
          <UiFormField name={'unit'} />
        </UiBox>
      </UiForm>
    </AppPageSectionPanel>
    <AppPageSectionPanel className={'mg-t-md pd-nm ui-fx ui-fx--fast ui-fx__pane-fade-left'}
                         style={{overflow: 'visible', position: 'relative', zIndex: 5}}>
      <UiTextTitle text={t(`app.settings.account.reset-password.title`)}
                   headingLevel={2} />
      {emailSent
        ? <UiTextBlock
          className={'mg-v-md'}
          text={t(`app.settings.account.reset-password.success.message`, i18nParams)} />
        : (error
          ? <>
            <UiTextBlock
              className={'mg-t-md txt-c-err txt-b' + (errorCodeKey ? '' : ' mg-b-md')}
              text={t(`app.settings.account.reset-password.error.message`, i18nParams)} />
            {errorCodeKey && <UiTextBlock
              className={'mg-b-md txt-c-err txt-b'}
              text={t(errorCodeKey, i18nParams)} />}
          </>
          : <UiTextBlock
            className={'mg-v-md'}
            text={t(`app.settings.account.reset-password.message`, i18nParams)} />)}
      {!error && <UiButton className={emailSent ? undefined : 'txt-b'}
                           color={emailSent ? UiColor.success : UiColor.accent}
                           label={t(emailSent
                             ? 'app.settings.account.reset-password.success.label'
                             : (busy
                               ? 'general.action.wait'
                               : 'app.settings.account.reset-password.label'))}
                           state={(busy || emailSent || error) ? UiState.disabled : UiState.active}
                           interact={() => {
                             const auth = fb?.app?.auth();

                             if (!auth) return;

                             setBusy(true);

                             auth.sendPasswordResetEmail(rawEmail, actionCodeSettings as any)
                               .then(() => {
                                 setBusy(false);
                                 setEmailSent(true);
                                 toast.success(t(`app.settings.account.reset-password.toast.success`));
                               })
                               .catch((e) => {
                                 setBusy(false);
                                 setError(e);
                                 toast.error(t(`app.settings.account.reset-password.toast.error`));
                               })
                           }} />}
    </AppPageSectionPanel>
  </UiPageSegment>;
};
