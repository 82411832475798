import {API_PREFIX} from '../../core/constants';
import {
  IHttpRequest,
  IHttpRequestHookOptions,
  Page,
  useApiEndpoint,
  usePaginatedHttpRequest
} from '@bitsolve/react-common';
import {INotification, INotificationUpdate} from './notification.model';
import {ErrorHandler, SuccessHandler} from '../../core/api';

export const baseUrl = `${API_PREFIX}/v1/notification`;

export const findNotificationsRequest = (page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: baseUrl,
  params: {page: page || 0, pageSize: pageSize || 100}
});

export const updateNotificationRequest = (id: string, data?: INotificationUpdate): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}/${id}`,
  data
});

export const deleteNotificationRequest = (id: string): IHttpRequest => ({
  method: 'delete',
  url: `${baseUrl}/${id}`
});

export const useFindNotifications = (page?: number, pageSize?: number, opts?: IHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<INotification>>(
    findNotificationsRequest(page, pageSize),
    {initialFetch: true, ...opts}
  );

export const useUpdateNotification = (id: string, onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<INotificationUpdate, INotification>(
    data => updateNotificationRequest(id, data),
    onSuccess,
    onError
  );

export const useDeleteNotification = (onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<string, any>(
    deleteNotificationRequest,
    onSuccess,
    onError
  );
