import React from 'react';
import {classNames, UiCustomizable} from '@bitsolve/react-common';

export interface IAppSplashScreen extends UiCustomizable {
  dim?: boolean;
  wide?: boolean;
  footer?: boolean;
  center?: boolean;
}

export const AppSplashScreenHeader: React.FC<UiCustomizable> = (props) => {
  const {children, className, style} = props;

  return <header className={classNames('app-splash-screen__header', 'ui-fx ui-fx__pane-fade-down', className)}
                 style={{maxWidth: '90vw', ...style}}>
    {children}
  </header>;
};
export const AppSplashScreenFooter: React.FC<UiCustomizable> = (props) => {
  const {children, className, style} = props;

  return <footer className={classNames('app-splash-screen__footer', 'ui-fx ui-fx__pane-fade-up', className)}
                 style={{maxWidth: '90vw', ...style}}>
    {children}
  </footer>;
};

export const AppSplashScreenContent: React.FC<UiCustomizable> = (props) => {
  const {children, className, style} = props;

  return <main className={classNames('app-splash-screen__content', 'ui-fx ui-fx--slo ui-fx__pane-fade-in', className)}
               style={{maxWidth: '90vw', ...style}}>
    {children}
  </main>;
};


export const AppSplashScreen: React.FC<IAppSplashScreen> = (props) => {
  const {children, className, wide = false, dim = false, footer = false, center = false, style} = props;

  const containerClassName = classNames(
    'app-splash-screen__container',
    dim && 'app-splash-screen__container--dim',
    'ui-scroll-host',
    className
  );

  const splashClassName = classNames(
    'app-splash-screen',
    dim && 'app-splash-screen--dim',
    wide && 'app-splash-screen--wide',
    footer && 'app-splash-screen--footer',
    center && 'app-splash-screen--center',
  );

  return <section style={style}
                  className={containerClassName}>
    {children && <div className={splashClassName}>
      {children}
    </div>}
  </section>;
};
