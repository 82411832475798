import React, {createElement, useMemo} from 'react';
import {BarChart} from './chart/bar-chart.component';
import {BarSegmentChart} from './chart/bar-segment-chart.component';
import {AnalysisDetailsPageHeadingSegment} from './chart/heading.component';
import {IconStatChart} from './chart/icon-stat-chart.component';
import {LineChart} from './chart/line-chart.component';
import {LRUnitChart} from './chart/lr-unit-chart.component';
import {PieChart} from './chart/pie-chart.component';
import {DrilldownCharts} from './chart/drilldown-charts.component';
import {MultiCharts, MultiRoutedCharts} from './chart/multi-charts.component';
import {MultiLineChart} from './chart/multi-line-chart.component';
import {isFn, isNil, isNotNil, prop} from '@bitsolve/fns';
import {IRoute} from '../../../core/routing';
import {
  IAnalysisChartType,
  IAnalysisDetailsPageSegmentChart,
  IAnalysisDetailsPageSegmentChartOrFn
} from './analysis-details.model';
import {Rooq} from '../../../core/domain.model';

export const getChartComponentByType = <T extends IAnalysisChartType = IAnalysisChartType>(
  t: IAnalysisChartType['type']
): null | React.FC<T> => {
  switch (t) {
    case 'bar':
      return BarChart as any;
    case 'bar-segments':
      return BarSegmentChart as any;
    case 'heading':
      return AnalysisDetailsPageHeadingSegment as any;
    case 'icon-stats':
      return IconStatChart as any;
    case 'line':
      return LineChart as any;
    case 'lr-unit':
      return LRUnitChart as any;
    case 'pie':
      return PieChart as any;
    case 'drilldown':
      return DrilldownCharts as any;
    case 'multi':
      return MultiCharts as any;
    case 'multi-routed':
      return MultiRoutedCharts as any;
    case 'multi-line':
      return MultiLineChart as any;
    default:
      return null;
  }
};

export const getChartComponent = <T extends IAnalysisChartType = IAnalysisChartType>(
  chart: IAnalysisDetailsPageSegmentChart
): null | React.FC<T> => {
  if (isFn(chart)) {
    return null;
  }

  const {type} = chart;

  return getChartComponentByType(type);
};

export const useChartComponent = (
  chart?: IAnalysisDetailsPageSegmentChartOrFn | null,
  route?: IRoute,
) => {
  return useMemo(
    () => {
      const _chart = isNotNil(chart) && isFn(chart) ? chart(route as any) : chart;
      const el = isNil(_chart) ? null : getChartComponent(_chart as any);

      return isNil(_chart)
        ? null
        : createElement(el as any, {...(_chart as any)?.props, route})
    },
    [chart, route]
  );
};

export const punchTypeOrdering = {
  [Rooq.PunchTypeV2.STRAIGHT]: 100,
  [Rooq.PunchTypeV2.HOOK]: 200,
  [Rooq.PunchTypeV2.UPPERCUT]: 300,
  [Rooq.PunchTypeV2.BODY_SHOT]: 400,
};


export const sortByPunchType = (a: Rooq.PunchTypeV2, b: Rooq.PunchTypeV2): -1 | 0 | 1 => {
  const av = prop(punchTypeOrdering, a, 0);
  const bv = prop(punchTypeOrdering, b, 0);

  if (av === bv) {
    return 0;
  } else {
    return av > bv
      ? 1
      : -1;
  }
}

