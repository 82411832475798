import React from 'react';
import {UiBox, UiTextBlock, useTranslator} from '@bitsolve/react-common';
import {Rooq, useShouldShowTour} from '../../../../core/domain.model';
import {ArrowSide, UiTourTooltip} from 'src/module/ui/component/ui-tour-tooltip.component';
import {useAuthAccount} from '../../auth.store';
import {isNil} from '@bitsolve/fns';

export interface IAthleteOnboadingTooltips {

}

export const AthleteOnboadingTooltips: React.FC<IAthleteOnboadingTooltips> = (props) => {
  const showAnalyseTour = useShouldShowTour(Rooq.UserTour.OnboardingSeeAnalysis);
  const acc = useAuthAccount();
  const t = useTranslator();

  if (isNil(acc) || acc?.trialAvailable) {
    return null;
  }

  return <UiBox className={'app-athlete-onboading-tooltips'}>
    {showAnalyseTour && <UiTourTooltip arrow arrowSide={ArrowSide.Left}
                                       clickToComplete={Rooq.UserTour.OnboardingSeeAnalysis}>
      <UiTextBlock text={t('app.tour.onboarding-see-analysis.message')} />
    </UiTourTooltip>}
  </UiBox>;
};
