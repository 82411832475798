import React from 'react';

export interface IAnalysisSessionAthleteLabel {
  color?: string;
  label: string;
}

export const AnalysisSessionAthleteLabel: React.FC<IAnalysisSessionAthleteLabel> = (props) => {
  const {color, label} = props;

  return <div className="app-analysis__session-athlete-label">
    {color && <svg viewBox={`0 0 100 100`}
                   width={'1rem'}
                   height={'1rem'}
                   className={'app-analysis__session-athlete-label__dot'}>
      <circle cx={50} cy={50} r={22}
              stroke={'none'}
              fill={color} />
    </svg>}
    <span className={'app-analysis__session-athlete-label__label'}>{label}</span>
  </div>;
};
