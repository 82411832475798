import {Route, Switch, useHistory} from 'react-router';
import {createUiModalRoute, IUiModalRoute, uiModalDefaultDialogStyle} from '@bitsolve/react-common';
import React from 'react';
import {isEmpty, isNil} from '@bitsolve/fns';
import {IRoute} from '../../../core/routing';


export const UiModalRoute = createUiModalRoute(Route);


export interface IUiModalRouteOutletRoute extends Omit<IUiModalRoute, 'render'> {
  side?: 'left' | 'right';
  render?: (route: IRoute & Pick<IUiModalRoute, 'onUpdatePropagation'>) => any;
}

export interface IUiModalRouteOutlet {
  ownPath: string;
  onUpdate?: Function;
  routes?: IUiModalRouteOutletRoute[];
}

export const modalRouteStyle = (side: IUiModalRouteOutletRoute['side']) => {
  const {overlay, content} = uiModalDefaultDialogStyle;

  return {
    overlay: {
      ...overlay,
      backgroundColor: 'rgba(10, 31, 48, 0.75)',
      backdropFilter: 'blur(0.325rem)'
    },
    content: {
      ...content,
      backgroundColor: 'var(--color-bg--primary-dark)',
    }
  };
};

export const UiModalRouteOutlet: React.FC<IUiModalRouteOutlet> = (props) => {
  const {routes, ownPath, onUpdate} = props;
  const h = useHistory();

  if (isNil(routes) || isEmpty(routes as any)) {
    return null;
  }

  return <Switch>
    {routes?.map((route) => {
      const {side = 'right', ..._route} = route;

      return <UiModalRoute key={route.path}
                           {..._route as any}
                           closeTimeoutMS={250}
                           onRequestClose={() => h.push(ownPath)}
                           onUpdatePropagation={onUpdate}
                           style={modalRouteStyle(side)} />
    })}
  </Switch>;
};
