import {User} from 'firebase';
import {Rooq} from '../../core/domain.model';
import {IPrivateClub} from '../club/club.model';
import {isNil, isNonEmptyStr} from '@bitsolve/fns';
import {useMemo} from 'react';
import {useAuthAccess} from './auth.store';

export interface IAuthOptions {
  url: string | URL;
  realm: string;
  clientId: string;
  flow?: string;
  'public-client'?: boolean;
  credentials?: {
    secret?: string;
  };
}

export interface IAuthUserInfo extends User {
}

export interface IAuthUser extends IAuthUserInfo {
}

export interface IAuthAccess extends firebase.auth.IdTokenResult {
}

interface IAuthUserCompletable {
  completed: boolean;
}

export interface IAuthUserAthleteProfile extends Rooq.AthleteProfile, IAuthUserCompletable {
}

export interface IAuthUserTrainerProfile extends Rooq.TrainerProfile, IAuthUserCompletable {
}


export interface IAuthUserAccount extends Rooq.User, IAuthUserCompletable {
  trainerProfile: IAuthUserTrainerProfile;
  athleteProfile: IAuthUserAthleteProfile;
  privateClubs: IPrivateClub[];
}

export interface IAuthUserAccountUpdate {
  aboutMe: String;
  birthDay: Date;
  countryIsoCode: string;
  email: string;
  firstName: string;
  gender: Rooq.Gender;
  hometown: string;
  lastName: string;
  publicAccount: boolean;
  customClaims: Rooq.Claim[];
  completedTours?: Rooq.UserTour[];
}


export interface IAuthUserTrainerProfileUpdate {
  trainerType: Rooq.TrainerType;
  fightStyles: Rooq.FightStyle[];
  customFightStyles: string[];
  titles: Rooq.ChampionshipTitle[];
  skippedProfile: boolean;
}

export interface IAuthUserAthleteProfileUpdate {
  skippedProfile?: boolean;
  height: number;
  weight: number;
  fightStyles: Rooq.FightStyle[];
  stance: Rooq.SkillLevel;
  fitness: Rooq.SkillLevel;
  sparring: Rooq.SkillLevel;
  tournament: Rooq.SkillLevel;
}


// Utils

export const hasRole = (roles: Rooq.Claim[], claim: Rooq.Claim) =>
  (roles?.findIndex(role => role === claim) >= 0) || false;

export const useIsAdmin = () => {
  const access = useAuthAccess();

  return useMemo(
    () => hasRole(access?.claims?.roles, Rooq.Claim.ROLE_ADMIN),
    [access]
  );
};

export const userAccountName = (acc: IAuthUserAccount, rtl: boolean = false): string => {
  let parts = [acc.firstName, acc.lastName].filter(isNonEmptyStr);
  if (rtl) parts = parts.reverse();
  return parts.join(rtl ? ', ' : ' ');
}

export const useUserAccountName = (acc?: IAuthUserAccount, rtl: boolean = false): string | null =>
  useMemo(
    () => acc ? userAccountName(acc, rtl) : null,
    [acc, rtl]
  );

export const userAccountOrigin = (acc?: IAuthUserAccount): string | null => {
  if (!acc) return null;

  const {hometown} = acc;

  return hometown;
}

export const userAccountActivities = (acc?: IAuthUserAccount): string | null => {
  if (!acc) return null;

  const {athleteProfile, trainerProfile} = acc;

  if (isNil(athleteProfile) && isNil(trainerProfile)) {
    return null;
  }

  const s = new Set<string>(trainerProfile?.fightStyles);
  for (let fs of athleteProfile?.customFightStyles) {
    s.add(fs);
  }
  const a = Array.from(s.values());

  if (a.length > 1) {
    return `${a[0]} + ${a.length - 1}`
  } else if (a.length > 0) {
    return a[0];
  } else {
    return null;
  }
};


export const useUserAccountTagline = (acc?: IAuthUserAccount): string | null => {
  const activs = userAccountActivities(acc);
  const origin = userAccountOrigin(acc);

  return useMemo(() => activs
    ? origin ? `${activs} in ${origin}` : activs
    : origin, [activs, origin]);
}
