import React, {useMemo} from 'react';
import {IUiModalDialog, UiModalDialog} from './ui-modal-dialog.component';
import {dissoc, isFn, isNotNil} from '@bitsolve/fns';
import {
  IModalContext,
  IUiButton,
  UiBox,
  UiButtonGroup,
  UiColor,
  uiModalDefaultDialogStyle,
  UiTextBlock,
  useModal,
  useTranslator
} from '@bitsolve/react-common';

type ConfirmationModalMessagesFn = (context: IModalContext) => string[];

export interface IUiConfirmationModalDialog extends IUiModalDialog {
  messages?: string[] | ConfirmationModalMessagesFn;
  confirmButton?: IUiButton | false;
  cancelButton?: IUiButton | false;
  onCancel?: () => any;
  onConfirm?: (data: any) => any;
  context: IModalContext;
}

export const UiConfirmationModalDialog: React.FC<IUiConfirmationModalDialog> = (props) => {
  const {children, messages, context, onCancel, onConfirm, className, confirmButton, cancelButton, ...rest} = props;

  const t = useTranslator();

  const _messages = useMemo(
    () => isFn(messages)
      ? messages(context)
      : messages,
    [context, messages]
  );

  return <UiModalDialog {...dissoc(rest, 'search') as any}
                        className={'ui-modal-dialog__confirmation'}>
    <UiBox style={{padding: '1.25rem'}}>
      {_messages?.map((msg, i) => <UiTextBlock key={i} text={msg}
                                               style={{paddingBottom: '1.25rem'}} />)}
    </UiBox>
    <UiBox style={{padding: '0 1.25rem 1.25rem 1.25rem'}}>
      <UiButtonGroup stretch
                     actions={[
                       cancelButton === false ? null : {
                         label: t('general.action.cancel'),
                         color: UiColor.primary,
                         ...cancelButton,
                         interact: onCancel
                       },
                       confirmButton === false ? null : {
                         label: t('general.action.confirm'),
                         color: UiColor.accent,
                         className: 'txt-b', ...confirmButton,
                         interact: onConfirm
                       },
                     ].filter(isNotNil) as any} />
    </UiBox>
  </UiModalDialog>;
};

export interface IUiConfirmationOptions {
  onConfirm?: (data: any) => any,
  onCancel?: () => any,
  title?: string,
  messages?: IUiConfirmationModalDialog['messages'],
  cancelButton?: IUiButton | false;
  confirmButton?: IUiButton | false;
}

export const useConfirmation = (options: IUiConfirmationOptions) => {
  const {onConfirm, onCancel, title, messages, cancelButton, confirmButton} = options;

  return useModal({
    content: context => <UiConfirmationModalDialog header={{title, onClose: context.close}}
                                                   messages={messages}
                                                   cancelButton={cancelButton as any}
                                                   confirmButton={confirmButton as any}
                                                   context={context}
                                                   onCancel={() => {
                                                     context.close();
                                                     onCancel && onCancel();
                                                   }}
                                                   onConfirm={() => {
                                                     context.close();
                                                     onConfirm && onConfirm(context.data);
                                                   }} />,
    closeTimeoutMS: 0,
    style: {
      ...uiModalDefaultDialogStyle,
      content: {
        ...uiModalDefaultDialogStyle.content,
        minWidth: '22rem',
        maxWidth: '28rem',
        backgroundColor: 'transparent'
      }
    }
  });
}
