import React, {useEffect, useMemo, useRef, useState} from 'react';
import {constrain} from '@bitsolve/fns';
import {classNames, UiColor} from '@bitsolve/react-common';
import {animated, useSpring} from 'react-spring';

export interface ISimpleChartBar {
  progress: number;
  animate?: boolean;
  color?: UiColor | string;
  direction?: 'ltr' | 'rtl';
}

const _baseProgressClass = `app-analysis__chart__simple-bar`;

const bgBarAnim = {
  clamp: true,
  mass: 1,
  tension: 220,
  friction: 56
};

const valueBarAnim = {
  clamp: true,
  mass: 2,
  tension: 260,
  friction: 64
}

const SimpleBar: React.FC<{ animate?: any; name: string; percent: number; color?: any; }> = (props) => {
  const {name, percent, color, animate} = props;

  const r = useRef(!animate);
  const [ready, setReady] = useState(!animate);

  const fillStatus = useMemo(
    () => percent < 1
      ? 'empty'
      : (percent > 99 ? 'full' : 'intermediate'),
    [percent]
  );

  const onRef = animate
    ? (ref?: HTMLSpanElement) => {
      if (ref && ready && !r.current) {
        ref.style.transform = `scaleX(1)`;
        r.current = true;
      }
    }
    : undefined;

  useEffect(() => {
    const t = setTimeout(() => setReady(true), 640);
    return () => clearTimeout(t);
  }, [setReady]);

  const anim = useSpring({
    from: {transform: animate ? `scaleX(0)` : `scaleX(1)`},
    to: {transform: 'scaleX(1)'},
    config: animate
  });

  return <animated.span
    key={percent}
    style={{backgroundColor: color, width: `${percent}%`, ...anim}}
    ref={onRef as any}
    data-fill-status={fillStatus}
    className={`${_baseProgressClass}__${name}-bar`} />;
};

export const SimpleChartBar: React.FC<ISimpleChartBar> = (props) => {
  const {color = UiColor.info, direction = 'ltr', progress = 0, animate = true} = props;
  const _percentage = useMemo(() => constrain(Math.floor(100 * progress), 0, 100), [progress]);

  return <figure
    key={`${color}.${Math.floor(_percentage)}`}
    className={classNames(
      _baseProgressClass,
      `${_baseProgressClass}--${direction}`,
      _percentage <= 1 && `${_baseProgressClass}--empty`,
      _percentage >= 99 && `${_baseProgressClass}--complete`
    )}>
    {_percentage !== 100 && <SimpleBar key={'bg'} name={'bg'}
                                       animate={animate ? bgBarAnim : false}
                                       percent={100} />}
    <SimpleBar key={'value'} name={'value'}
               animate={animate ? valueBarAnim : false}
               color={color}
               percent={_percentage} />
  </figure>;
};
