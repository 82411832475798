import React from 'react';
import {classNames, UiCustomizable, UiFlexAlign, UiFlexCol, useTranslator} from '@bitsolve/react-common';

export interface IAppLogoInitial extends UiCustomizable {
  offset?: boolean;
}

export const AppLogoInitial: React.FC<IAppLogoInitial> = (props) => {
  const {className, style} = props;
  const t = useTranslator();

  return <img className={classNames('app-logo-initial-image', className)}
              style={{...style, margin: '0 auto'}}
              src="/asset/brand/rooq-logo-reg.svg"
              alt={t('app.info.full-title')} />;
};

export interface IAppLogoFirstLetter extends UiCustomizable {
  inverted?: boolean;
}

export const AppLogoFirstLetter: React.FC<IAppLogoFirstLetter> = (props) => {
  const {className, style, inverted} = props;
  const t = useTranslator();

  return <img className={classNames('app-logo-first-letter', className)}
    style={{...style}}
    src={"/asset/brand/" + (inverted ? "rooq-logo-initial-inv.svg" : "rooq-logo-initial.svg")}
    alt={t('app.info.full-title')} />;
};

export interface IAppLogoCustom extends UiCustomizable {
  src?: string;
  alt?: string;
}

export const AppLogoCustom: React.FC<IAppLogoCustom> = (props) => {
  const {className, style, src = '/asset/brand/rooq-logo.svg', alt = src} = props;

  return <img className={classNames('app-logo-custom', className)}
    style={{...style}}
    src={src}
    alt={alt} />;
};

export interface IAppLogo extends UiCustomizable {
  src?: string;
  alt?: string;
}

export const AppLogo: React.FC<IAppLogo> = (props) => {
    const {className, style, src = '/asset/brand/rooq-logo-reg.svg', alt = src} = props;

    return <UiFlexCol className={className}
                      style={style}
                      ai={UiFlexAlign.c}
                      jc={UiFlexAlign.c}>
      <img className={'app-logo-image'}
           src={src}
           alt={alt} />
    </UiFlexCol>;
  }
;
