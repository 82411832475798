import React from 'react';
import {
  classNames,
  UiBox,
  UiCustomizable,
  UiOptionallyIdentified,
  UiOptionallyLabelled,
  UiTextLine,
  uniqueId
} from '@bitsolve/react-common';

export interface IUiDataItem extends UiCustomizable, UiOptionallyLabelled, UiOptionallyIdentified {
  data?: any;
}

export const UiDataItem: React.FC<IUiDataItem> = (props) => {
  const {label, id, className, style, data} = props;
  const _id = uniqueId(id, 5);
  return <UiBox className={classNames('ui-data-item', className)}
                style={style}>
    {label && <label className="ui-data-item__label"
                     htmlFor={_id}>
      <UiTextLine text={label} />
    </label>}
    {data && <div className="ui-data-item__data"
                  id={_id}>
      {data}
    </div>}
  </UiBox>;
};

export const UiDataItemset: React.FC<{ items: IUiDataItem[]; } & UiCustomizable> = (props) => {
  const {items, className, style} = props;
  return <UiBox className={classNames('ui-data-itemset', className)}
                style={style}>
    {items.map((item, index) =>
      <UiDataItem key={item?.id || index} {...item} />)}
  </UiBox>;
};
