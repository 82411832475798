import React from 'react';
import {IRoute} from '../../../../core/routing';
import {PACKAGE_VERSION} from '../../../../core/constants';
import {UiFlexAlign, UiFlexCol} from '@bitsolve/react-common';
import {UiSplashMessage} from '../../../../module/ui/component/ui-splash-message.component';

export interface ICoachingZoneSettingsInfoRouteComponent extends IRoute {
}

export const CoachingZoneSettingsInfoRouteComponent: React.FC<ICoachingZoneSettingsInfoRouteComponent> = (props) => {
  const {match} = props;

  if (!match) return null;

  return <UiFlexCol key={match.path}
                    style={{height: '100%'}}
                    ai={UiFlexAlign.c}
                    jc={UiFlexAlign.c}
                    className={'f-1'}>
    <UiSplashMessage
      className={'mg-t-lg'}
      style={{minWidth: '66.5%'}}
      title={{text: 'ROOQ Coaching Zone'}}
      messages={[
        {text: 'v' + PACKAGE_VERSION, className: 'txt-nm'},
        {
          text: 'Copyright © ' + (new Date()).getFullYear() + ' ROOQ',
          className: 'mg-t-nm txt-c-defd txt-nm'
        },
      ]} />
  </UiFlexCol>;
};
