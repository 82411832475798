import React, {useEffect, useMemo, useState} from 'react';
import {
  socketConnect,
  socketDisconnect,
  socketPush,
  socketSubscribe,
  socketUnsubscribe,
  useSocketConnection,
  useSocketSubscription,
  useSocketSubscriptionMessages
} from '../socket.store';
import {useDispatch} from 'react-redux';
import {Client, StompHeaders} from '@stomp/stompjs';
import {hasProp, isFn, isNotNil, isStr, prop} from '@bitsolve/fns';
import {ISocketConnection, SocketMessageHandler} from '../socket.model';
import {useAuthAccess} from '../../auth/auth.store';
import {SocketContextProvider} from '../socket-connection.context';


export const SocketConnectionProvider: React.FC<ISocketConnection> = (props) => {
  const {alias, config, children, onConnect, onDisconnect} = props;

  const d = useDispatch();
  const authAccess = useAuthAccess();
  const conn = useSocketConnection(alias);
  const client = prop(conn, 'client');

  const [connectState, setConnectState] = useState({
    connecting: false,
    connected: isNotNil(client)
      && client instanceof Client
      && client.active
  });

  const context = useMemo(() => ({
    connection: {alias, config},
    dispatch: d,
    client,
    connected: connectState.connected,
    reconnect: () => d(socketDisconnect(alias)),
    subscribe: (destination: string, onMessage?: SocketMessageHandler, headers?: StompHeaders) =>
      d(socketSubscribe(alias, destination, onMessage, headers, client)),
    unsubscribe: (destination: string) =>
      d(socketUnsubscribe(alias, destination)),
    // eslint-disable-next-line
    messages: (destination: string) => useSocketSubscriptionMessages(alias, destination),
    subscribed: (destination: string) => {
      // eslint-disable-next-line
      const subscription = useSocketSubscription(alias, destination);
      return hasProp(subscription, 'id') && isStr(prop(subscription, 'id'));
    },
    send: (destination: string, message: any, headers?: StompHeaders) =>
      d(socketPush(alias, destination, message, headers, client))
  }), [d, client, alias, config, connectState]);


  useEffect(
    () => {
      const {connected, connecting} = connectState;

      if (connecting || connected || !authAccess) {
        return;
      }

      const {connection} = context;
      const {alias, config} = connection;

      const connectionConfig = {
        ...config,
        onConnect: () => {
          setConnectState({
            connecting: false,
            connected: true
          });
          isFn(onConnect) && onConnect(context);
        },
        onDisconnect: () => {
          setConnectState({
            connecting: false,
            connected: false
          });
          isFn(onDisconnect) && onDisconnect();
        }
      };

      setConnectState({
        connecting: true,
        connected: false
      });

      d(socketConnect(alias, connectionConfig, authAccess.token));
    },
    [d, connectState, context, authAccess, onConnect, onDisconnect]
  );

  const _children = useMemo(
    () => isFn(children)
      ? children(context)
      : children,
    [children, context]
  );

  return <SocketContextProvider value={context}>
    {_children}
  </SocketContextProvider>
};
