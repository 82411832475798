import React from 'react';
import {classNames, UiCustomizable} from '@bitsolve/react-common';
import {AppLoadingSpinner, IAppLoadingSpinner} from './app-loading-spinner.component';

export const AppSpinnerPane: React.FC<UiCustomizable & { spinner?: Partial<IAppLoadingSpinner>; }> = (props) => {
  const {spinner, className, style} = props;

  return <div className={classNames(`app-spinner__pane`, className)}
              style={style}>
    <AppLoadingSpinner {...spinner} />
  </div>
};

