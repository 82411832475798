import React, { useMemo } from 'react';
import {useAuthActions} from '../../auth.store';
import {Rooq, useShouldShowTour} from '../../../../core/domain.model';
import {
  IUiButton,
  modalClassNames,
  toast,
  UiBox, UiButtonGroup,
  UiColor, UiDir,
  UiFlexAlign,
  UiFlexCol,
  UiIcon,
  UiModal,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../../app/common/misc/app-banner-pane.component';
import {appPagePanelModalStyles} from '../../../../app/common/page/app-page-panel-modal.component';
import {useCompleteMyTour} from '../../auth.api';
import { useShopRedirect } from "../../../shop/shop.api";

export interface AccountWelcomeTourProps {

}

const welcomeTourItems = [
  {
    icon: 'appAthletes',
    title: 'app.tour.onboarding-welcome.items.athlete-seats.title',
    message: 'app.tour.onboarding-welcome.items.athlete-seats.message'
  },
  {
    icon: 'appAnalysis',
    title: 'app.tour.onboarding-welcome.items.analysis.title',
    message: 'app.tour.onboarding-welcome.items.analysis.message'
  },
  {
    icon: 'appTrainingPlan',
    title: 'app.tour.onboarding-welcome.items.training-plans.title',
    message: 'app.tour.onboarding-welcome.items.training-plans.message'
  }
];

export const AccountWelcomeTour: React.FC<AccountWelcomeTourProps> = (props) => {
  const t = useTranslator();
  const authAction = useAuthActions();
  const completeTour = useCompleteMyTour(
    res => {
      authAction.updateAccount(res.data);
    },
    err => {
      console.error(err);
      toast.error('An unknown problem occured');
    }
  );

  const goToShop = useShopRedirect();

  const welcomeTourActions: IUiButton[] = useMemo(() => ([
    {
      className: '',
      color: UiColor.primary,
      label: t('app.tour.onboarding-welcome.action.complete.label'),
      interact: () => completeTour.send(Rooq.UserTour.OnboardingWelcome)
    },
    {
      className: 'txt-b',
      color: UiColor.accent,
      label: t('app.tour.onboarding-welcome.action.shop.label'),
      interact: () => {
        completeTour.send(Rooq.UserTour.OnboardingWelcome).then(() => {goToShop()});
      }
    },
  ]), [t, completeTour, goToShop]);

  return <UiBox>
    <AppBannerPane contentAnchor={'bs'}
                   blur={0}
                   src={'/asset/placeholder/tour-welcome-banner-default.jpg'}
                   aspectRatio={0.275}
                   style={{background: '#0F1F2F'}}>
      <UiBox className="pd-nm">
        <UiTextTitle headingLevel={2}
                     text={t('app.tour.onboarding-welcome.title')} />
      </UiBox>
    </AppBannerPane>
    <UiBox className="pd-v-nm pd-h-md"
           style={{
             background: '#1B2939'
           }}>
      <UiTextBlock
        breathe
        className={'mg-b-md'}
        style={{fontSize: 16}}
        text={t('app.tour.onboarding-welcome.message')} />
      <UiBox className={'d-g'}
             style={{
               gridTemplateColumns: '5rem 1fr',
               gridRowGap: '1.5rem',
               alignItems: 'flex-start',
             }}>
        {welcomeTourItems.map((item: any, index) => {

          return <React.Fragment key={index}>
            <UiFlexCol ai={UiFlexAlign.s} jc={UiFlexAlign.s}>
              <div className={'d-f f-dv f-ai-c f-jc-c'}
                   style={{
                     background: '#263443',
                     borderRadius: '50%',
                     width: '3.6rem',
                     height: '3.6rem',
                   }}>
                <UiIcon iconSet={item?.iconSet || 'regular'}
                        className={'txt-c-defd'}
                        style={{
                          width: '1.65rem',
                          height: '1.65rem',
                        }}
                        name={item.icon} />
              </div>
            </UiFlexCol>
            <div>
              <UiTextTitle text={t(item.title)}
                           className={'txt-b mg-b-sm'} />
              <UiTextBlock breathe
                           text={t(item.message)}
                           className={'txt-sm txt-c-defd'} />
            </div>
          </React.Fragment>;
        })}
      </UiBox>
      <UiButtonGroup stretch
        className={'app-welcome-tour-buttons'}
        direction={UiDir.h}
        actions={welcomeTourActions}/>
    </UiBox>
  </UiBox>;
};


let styles = appPagePanelModalStyles({
  content: {
    width: '50vw',
    minWidth: '30rem',
    maxWidth: '45rem',
  }
});

export const AccountWelcomeTourModal: React.FC<AccountWelcomeTourProps> = (props) => {
  const isOpen = useShouldShowTour(Rooq.UserTour.OnboardingWelcome);

  return <UiModal isOpen={isOpen}
                  style={styles}
                  {...modalClassNames('dialog')}>
    <AccountWelcomeTour />
  </UiModal>;
}
