import React, {useCallback, useMemo, useState} from 'react';
import {
  IUiButton,
  IUiForm,
  IUiFormContext,
  schema,
  UiBox,
  UiDir,
  UiFlexRow,
  UiForm,
  UiFormContextConsumer,
  UiFormField,
  UiInteractive,
  UiPageContent,
  UiPanel,
  useTranslator
} from '@bitsolve/react-common';
import {IClub, IClubUpdate} from '../club.model';
import {ClubCardEditable} from './club-card-editable.component';
import {AppTabNavigation} from '../../../app/common/misc/app-tab-navigation.component';
import {isNonEmptyStr} from '@bitsolve/fns';
import {AppPageSectionPanel} from '../../../app/common/page/app-page-section-panel.component';
import {ChangeContextProvider} from '../../ui/component/ui-change-context.component';
import {IChangeContext} from '../../ui/ui-change-context';
import {useFormIndexFields} from '../../../core/util';
import {ClubChangesBar} from './club-changes-bar.component';
import {clubFields, clubSchema} from '../club.forms';
import {useSchemaOptions} from '../../../core/api';

export interface IClubForm extends Omit<IUiForm<Partial<IClub>>, 'fields'> {
  mode?: 'auto' | 'create' | 'update';
  perspective?: 'trainer' | 'club';
  ownPath?: string;
  changeActions?: Array<UiInteractive<{ changes: IChangeContext<Partial<IClub>>; form: IUiFormContext<Partial<IClub>> }> & Omit<IUiButton, 'interact'>>;
}

const useClubChangeContext = (data?: Partial<IClub>): { onFieldChange: (k: string, v: any) => any } & IChangeContext<Partial<IClub> | undefined> => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [changeData, setData] = useState<Partial<IClubUpdate>>();

  const onFieldChange = useCallback((k: string | number | symbol, v: any) => {
    if (!dirty) {
      setDirty(true);
    }

    setData(d => ({...d, [k]: v}));
  }, [setDirty, setData, dirty]);

  const api = useMemo(() => ({
    setData,
    setDirty,
    setError,
    onFieldChange
  }), [setData, setDirty, setError, onFieldChange]);

  return {...api, dirty, error, data: changeData as any} as any;
};

const _schema = schema.object(clubSchema);

export const ClubForm: React.FC<IClubForm> = (props) => {
  const {changeActions, className, style, perspective, mode = 'auto', ownPath, ...rest} = props;

  const clubId = props.value?.id;
  const clubExists = mode !== 'create' && clubId && isNonEmptyStr(clubId);
  const formMode = mode === 'auto' ? (clubExists ? 'update' : 'create') : mode;

  const t = useTranslator();
  const clubChanges = useClubChangeContext(props.value);
  const formFields = useFormIndexFields(clubFields);
  const schemaOpts = useSchemaOptions('app.club.{message}', false);

  const _ownPath = ownPath || (
    perspective === 'club'
      ? (clubExists
        ? `/portal/club/${clubId}/settings`
        : `/portal/club/create`)
      : (clubExists
        ? `/portal/club/${clubId}/settings`
        : `/portal/trainer/clubs/create`)
  );

  return <UiBox className={'app-club__details'}>
    <ChangeContextProvider value={clubChanges}>
      <UiForm className={'app-club__form'}
              fields={formFields}
              schema={_schema}
              schemaOptions={schemaOpts}
              onFieldChange={clubChanges.onFieldChange as any}
              {...rest}>
        <UiPageContent style={{paddingTop: 0, paddingBottom: 0}}>
          <ClubCardEditable club={props.value as any} />
          <AppTabNavigation items={[
            {
              label: t('app.club.menu.info.label'),
              to: _ownPath,
              exact: true
            },
            ...(mode === 'update' && _ownPath?.match(/^\/app/)
              ? [
                {
                  label: t('app.club.menu.athletes.label'),
                  to: `/portal/club/${clubId}/athletes`,
                  exact: true
                },
                {
                  label: t('app.club.menu.trainers.label'),
                  to: `/portal/club/${clubId}/trainers`,
                  exact: true
                }
              ]
              : [])
          ]} />
        </UiPageContent>
        <UiPageContent style={{paddingTop: 0, paddingBottom: 0}}
                       className={'mg-b-xl'}>
          <UiPanel className={'pd-nm'}>
            <UiFlexRow>
              <UiBox className={'f-1 pd-r-sm'}>
                <AppPageSectionPanel title={{text: t('app.club.section.info')}}>
                  <UiBox className="pd-nm">
                    <UiFormField
                      direction={UiDir.v}
                      name={'name'}
                      className={'mg-b-nm'} />
                    <UiFormField
                      direction={UiDir.v}
                      name={'foundingYear'}
                      className={'mg-b-nm'} />
                    <UiFormField
                      direction={UiDir.v}
                      name={'memberCount'}
                      className={'mg-b-nm'} />
                  </UiBox>
                </AppPageSectionPanel>
                <AppPageSectionPanel title={{text: t('app.club.section.about')}} className={'mg-t-nm'}>
                  <UiBox className="pd-nm">
                    <UiFormField
                      direction={UiDir.v}
                      name={'aboutUs'} />
                  </UiBox>
                </AppPageSectionPanel>
                <AppPageSectionPanel title={{text: t('app.club.section.sports')}} className={'mg-t-nm'}>
                  <UiFormField
                    direction={UiDir.v}
                    name={'fightStyles'} />
                </AppPageSectionPanel>
              </UiBox>
              <UiBox className={'f-1 pd-l-sm'}>
                <AppPageSectionPanel title={{text: t('app.club.section.contact')}}>
                  <UiBox className="pd-nm">
                    <UiFormField
                      direction={UiDir.v}
                      name={'address'} />
                  </UiBox>
                  <UiBox className={'pd-h-nm'}>
                    <UiFormField
                      direction={UiDir.v}
                      name={'phoneNumber'}
                      className={'mg-b-nm'} />
                    <UiFormField
                      direction={UiDir.v}
                      name={'email'}
                      className={'mg-b-nm'} />
                  </UiBox>
                </AppPageSectionPanel>
                <AppPageSectionPanel title={{text: t('app.club.section.opening-hours')}} className={'mg-t-nm'}>
                  <UiFormField
                    direction={UiDir.v}
                    name={'openingHours'} />
                </AppPageSectionPanel>
              </UiBox>
            </UiFlexRow>
          </UiPanel>
        </UiPageContent>
        {formMode === 'update' && changeActions && <UiFormContextConsumer>
          {form => {
            return <ClubChangesBar
              key={`${formMode}.${clubId || '_none'}`}
              actions={changeActions.map(action => ({
                ...action,
                interact: () => action.interact && action.interact({
                  changes: clubChanges as any,
                  form
                })
              }))} />
          }}
        </UiFormContextConsumer>}
      </UiForm>
    </ChangeContextProvider>
  </UiBox>;
};
