import React, {useMemo} from 'react';
import {UiBox, UiFlexAlign, UiFlexCol, UiFlexRow, UiTextLine} from '@bitsolve/react-common';
import {ParentSize} from '@visx/responsive';
import {AreaClosed, LinePath} from '@visx/shape';
import {scaleLinear} from '@visx/scale';
import {max, min} from 'd3-array';
import {Group} from '@visx/group';
import {Axis, Orientation} from '@visx/axis';
import {GridColumns} from '@visx/grid';
import {isNil, isNotEmpty, isNum, range} from '@bitsolve/fns';
import {LinearGradient} from '@visx/gradient';
import {animated, useTrail} from 'react-spring';
import {ScaleLinear} from 'd3';
import {findMaxDerivativeIndices} from '../../../../core/domain.model';

export interface ILineChartLegendItem {
  color?: string;
  title?: string;
  label: string;
}

export interface ILineChartData {
  section: number;
  value: number;
}

export interface ILineChart {
  data: ILineChartData[];
  legend?: ILineChartLegendItem[];
  unitX?: string;
  xMax?: number;
  unitY?: string;
  yMax?: number;
}

const getX = (d: ILineChartData): number => d?.section || 0;
const getY = (d: ILineChartData): number => d?.value || 0;


export const background = '#045275';
export const backgroundLight = '#089099';
export const foreground = '#94ADC1';
export const areaGradient = 'rgba(46, 67, 85, 1)';
export const areaGradientHot = 'rgba(97, 35, 58, 1)';

const segmentStyles = [
  {fill: 'transparent', stroke: '#94ADC1', strokeWidth: 3},
  {fill: 'transparent', stroke: 'rgba(202, 34, 67, 1)', strokeWidth: 3},
  {fill: 'transparent', stroke: '#94ADC1', strokeWidth: 3},
];

const segmentAreaStyles = [
  {strokeWidth: 1, stroke: 'url(#area-gradient)', fill: 'url(#area-gradient)'},
  {strokeWidth: 1, stroke: 'url(#area-gradient-hot)', fill: 'url(#area-gradient-hot)'},
  {strokeWidth: 1, stroke: 'url(#area-gradient)', fill: 'url(#area-gradient)'},
];


const rebalance = (data: ILineChartData[][]): ILineChartData[][] => {
  const [l, c, r] = data.length < 3
    ? [...data, ...range(0, 3 - data.length).map(() => ([]))]
    : (data.length > 3
      ? data.slice(0, 3)
      : data);

  const c0 = c[0];
  const cN = c[c.length - 1];

  return [
    l.length
      ? (l[l.length - 1].section === c0.section ? l : [...l, c0])
      : l,
    c,
    r.length
      ? (r[0].section === cN.section ? r : [cN, ...r])
      : r,
  ];
}

const partition = (data: ILineChartData[]): ILineChartData[][] => {
  const derivs = findMaxDerivativeIndices(data, getX, getY);

  if (!derivs) return [data];

  const [a, b] = derivs;
  const da = data[a];
  const db = data[b];
  const center = [da, db];

  if (isNil(da) || isNil(db)) return [data];

  // console.log('derivs:', derivs);
  // console.log('center:', center);

  if (b <= 1) {
    const segs = [[da, da], center, data.slice(b)];
    // console.log('in first 2 segs:', segs);
    return segs;
  } else {
    const segs = rebalance([data.slice(0, a), center, data.slice(b + 1)]);
    // console.log('segs:', segs);
    return segs;
  }
};


const LineChartLegendDot: React.FC<{ color: string; }> = (props) => {
  const {color} = props;

  return <svg width={'0.375rem'} height={'0.375rem'}
              viewBox={'0 0 100 100'}>
    <circle cx={50} cy={50} r={50} strokeWidth={0} fill={color} />
  </svg>;
}

type ILineChartContext = { x: any; y: any; xScale: any; yScale: any; }


const LineChartLineSegment: React.FC<{ style: any; segment: ILineChartData[]; index: number; area: any; line: any; }> = (props) => {
  const {segment, area, line, style} = props;


  return <animated.g style={style}
                     className={'segments__line'}>
    <AreaClosed data={segment} {...area} />
    <LinePath data={segment} {...line} />
  </animated.g>;
};

const LineChartLine: React.FC<{ context: ILineChartContext; segments: ILineChartData[][] }> = (props) => {
  const {segments, context} = props;
  const {x, y, yScale} = context;

  const segs = segments.filter(isNotEmpty);
  const trail = useTrail(segs.length, {
    opacity: 1,
    transform: 'translateY(0%) scaleY(1)',
    from: {
      opacity: 0,
      transform: 'translateY(7.5%)  scaleY(0.75)',
    },
    config: {
      mass: 1,
      tension: 420,
      friction: 50,
      delay: 35,
    }
  });

  return <Group top={0}
                className={'segments'}>
    {trail.map((anim, index) => {
      const segment = segs[index];
      const lineStyle = segmentStyles[index % segmentStyles.length];
      const areaStyle = segmentAreaStyles[index % segmentAreaStyles.length];

      return <LineChartLineSegment key={index}
                                   index={index}
                                   segment={segment}
                                   style={anim}
                                   line={{x, y, ...lineStyle}}
                                   area={{x, y, yScale, ...areaStyle}} />;
    })}
  </Group>;
};

export const LineChartDots: React.FC<{ data: ILineChart['data']; xScale: ScaleLinear<any, any>; yScale: ScaleLinear<any, any>; }> = (props) => {
  const {data, xScale, yScale} = props;

  const dotTrail = useTrail(data.length, {
    opacity: 1,
    r: 4,
    from: {
      opacity: 0,
      r: 0,
    },
    config: {
      mass: 1,
      tension: 520,
      friction: 42,
      delay: 5,
    }
  });

  return <Group top={0}
                className={'dots'}>
    {dotTrail
      .map((anim, i) => {
        const d = data[i];

        return <animated.circle key={i}
                                {...anim}
                                strokeWidth={2.5}
                                cx={xScale(getX(d))}
                                cy={yScale(getY(d))}
                                stroke="#102535"
                                fill="#94ADC1" />;
      })}
  </Group>;
};

export const LineChartLegend: React.FC<{ items: ILineChartLegendItem[]; }> = (props) => {
  const {items} = props;

  return <UiFlexRow className="app-analysis__chart--line__legend">
    {items?.map((item, index) => {
      const {color, label, title} = item;
      const titleClass = 'txt-b';

      return <UiBox key={index} className={'app-analysis__chart--line__legend__item'}>
        <UiFlexCol ai={UiFlexAlign.c}
                   jc={UiFlexAlign.c} className={'pd-t-xs'}>
          {color && <LineChartLegendDot color={color} />}
        </UiFlexCol>
        <UiFlexCol className={'txt-sm'}>
          {title
            ? <>
              <UiTextLine className={titleClass} text={title} />
              <UiTextLine text={label}
                          className={'txt-c-defd'} />
            </>
            : <UiTextLine className={titleClass} text={label} />}
        </UiFlexCol>
      </UiBox>;
    })}
  </UiFlexRow>;
}

export const LineChart: React.FC<ILineChart> = (props) => {
  const {data, unitX, unitY, xMax, yMax, legend} = props;

  const segments = useMemo(() => partition(data), [data]);
  const dataSumY = useMemo(() => isNum(yMax) ? yMax : data.reduce((s, d) => s + getY(d), 0), [yMax, data]);
  const dataSumX = useMemo(() => isNum(xMax) ? xMax : data.reduce((s, d) => s + getX(d), 0), [xMax, data]);

  const height = 290;

  return <UiBox className="app-analysis__chart app-analysis__chart--line"
                style={{position: 'relative'}}>
    {unitY && <UiFlexRow jc={UiFlexAlign.e}
                         className={'txt-c-defd txt-sm'}
                         style={{
                           position: 'absolute',
                           top: 0,
                           left: 0,
                           transformOrigin: 'left top 0',
                           transform: 'rotate(-90deg) translate3d(-100%, 0, 0)'
                         }}>
      <UiTextLine className={'mg-r-xs'} text={unitY} />
      <UiTextLine className={'txt-b'} text={`${dataSumY}`} />
    </UiFlexRow>}
    <ParentSize debounceTime={32}
                parentSizeStyles={{height}}>
      {bounds => {
        const width = Math.floor(bounds.width);

        const xScale = scaleLinear<number>({
          range: [0, width],
          domain: [0, max(data, getX) as number],
          nice: true,
        });
        const yScale = scaleLinear<number>({
          range: [height, 0],
          domain: [min(data, getY) as number, max(data, getY) as number],
        });

        const gridScale = scaleLinear<number>({
          range: [0, width],
          domain: [0, max(data, getX) as number],
        });

        const dx = (d: ILineChartData) => xScale(getX(d)) ?? 0;
        const dy = (d: ILineChartData) => yScale(getY(d)) ?? 0;

        return <svg width={width} height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    style={{
                      shapeRendering: 'geometricPrecision',
                      overflow: 'visible',
                      padding: '0 2rem',
                    }}>
          <defs>
            <linearGradient gradientUnits="userSpaceOnUse"
                            id={'gridColGradient'}
                            x1={0} x2={0} y1={0} y2={height}>
              <stop stopColor={'rgba(28, 47, 63,0)'} offset={0} />
              <stop stopColor={'rgba(28, 47, 63,1)'} offset={1} />
            </linearGradient>
          </defs>
          <LinearGradient id="area-gradient" from={areaGradient} to={areaGradient} toOpacity={0.05} />
          <LinearGradient id="area-gradient-hot" from={areaGradientHot} to={areaGradientHot} toOpacity={0.05} />
          <Group top={height}>
            <Axis scale={xScale}
                  stroke={'#283A49'}
                  strokeWidth={1}
                  orientation={Orientation.bottom} />
          </Group>
          <Group top={0}
                 className={'grid'}
                 style={{
                   // transformOrigin: 'center',
                   // transform: 'scale(0.9)'
                 }}>
            <GridColumns
              height={height}
              scale={gridScale}
              numTicks={6}
              // scale={xScale}
              // tickValues={data.map(d => d.section)}
              stroke={'url(#gridColGradient)'}
              strokeWidth={2}
              strokeOpacity={1}
              pointerEvents="none"
            />
            <LineChartLine context={{x: dx, y: dy, xScale, yScale}}
                           segments={segments} />
            <LineChartDots data={data}
                           xScale={xScale}
                           yScale={yScale} />
          </Group>
        </svg>;
      }}
    </ParentSize>
    {unitX && <UiFlexRow jc={UiFlexAlign.e}
                         className={'txt-c-defd txt-sm'}>
      <UiTextLine className={'mg-r-xs'} text={unitX} />
      <UiTextLine className={'txt-b'} text={`${dataSumX}`} />
    </UiFlexRow>}
    {legend && <LineChartLegend items={legend} />}
  </UiBox>;
};
