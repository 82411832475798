import {createContext, useContext, useEffect, useMemo, useRef} from 'react';
import {isFn, isNil, isNonEmptyStr, keys, propIn} from '@bitsolve/fns';
import firebase from 'firebase/app';
import 'firebase/auth';
import {authStoreKey, IAuthStore} from '../auth.store';
import {defmulti} from '@thi.ng/defmulti';
import {useQueryParam} from '../../../core/util';


export interface IFirebaseContext {
  app: firebase.app.App;
}


export const FirebaseContext = createContext<IFirebaseContext | null>(null);
export const FirebaseContextProvider = FirebaseContext.Provider;
export const FirebaseConsumer = FirebaseContext.Consumer;

export const useFirebaseAppContext = (): IFirebaseContext | null => useContext(FirebaseContext);


export const getFirebaseTokenFromStore = (store: { [authStoreKey]: IAuthStore }) => {
  return propIn(store, [authStoreKey, 'access', 'token']);
};

export const getFirebaseToken = (): Promise<string> => new Promise((yep, nope) => {
  try {
    const user = firebase.auth().currentUser;

    if (isNil(user)) {
      nope();
    }

    return (user as firebase.User).getIdTokenResult()
      .then(result => yep(result.token))
      .catch(nope);
  } catch (e) {
    nope(e);
  }
});


export type SocialProviderType = 'google' | 'apple' | 'facebook';

export interface ISocialProvider<P, R> {
  type: SocialProviderType;

  signIn(payload: P): Promise<R>;

  // register(payload: P): Promise<R>;

  updateProvider<X>(f: (p: X) => X): void;
}

export type SocialProviderFactoryMethod<P, R> = (type: SocialProviderType, payload: P) => ISocialProvider<P, R>;
export type SocialProviderFactoryDispatch<P = any> = (type: SocialProviderType, payload: P) => SocialProviderType;

const socialProviderFactoryDispatch: SocialProviderFactoryDispatch = (type: SocialProviderType) => type
const socialProviderFactory = defmulti<SocialProviderType, any, ISocialProvider<any, any>>(socialProviderFactoryDispatch as any);


abstract class AbstractBaseSocialProvider<P = any, R = firebase.auth.UserCredential, X extends firebase.auth.AuthProvider = firebase.auth.AuthProvider>
  implements ISocialProvider<P, R> {

  public abstract type: SocialProviderType;

  protected _provider: X;

  constructor(protected _locale?: string) {
    this._provider = this.createDelegate();
  }

  protected abstract createDelegate(): X;

  protected reset(): void {
    this._provider = this.createDelegate();
  }

  public updateProvider<X2 = X>(f: (p: X2) => X2): void {
    // @ts-ignore
    this._provider = f(this._provider);
  }

  public signIn(payload: P): Promise<R> {
    return firebase.auth()
      .signInWithPopup(this._provider)
      .then(r => {
        return Promise.resolve(r as any as R);
      })
      .catch((e) => {
        this.reset();
        return Promise.reject(e);
      });
  }

  // public register(payload: P): Promise<R> {
  // }
}


class AppleSocialProvider extends AbstractBaseSocialProvider<void, firebase.auth.UserCredential, firebase.auth.OAuthProvider>
  implements ISocialProvider<void, firebase.auth.UserCredential> {
  type: SocialProviderType = 'facebook';

  protected createDelegate(): firebase.auth.OAuthProvider {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.setCustomParameters({locale: this._locale || 'de'});
    return provider;
  }
}

class FacebookSocialProvider extends AbstractBaseSocialProvider<void, firebase.auth.UserCredential, firebase.auth.FacebookAuthProvider>
  implements ISocialProvider<void, firebase.auth.UserCredential> {
  type: SocialProviderType = 'facebook';

  protected createDelegate(): firebase.auth.FacebookAuthProvider {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    provider.setCustomParameters({locale: this._locale || 'de'});
    return provider;
  }
}

class GoogleSocialProvider extends AbstractBaseSocialProvider<void, firebase.auth.UserCredential, firebase.auth.GoogleAuthProvider>
  implements ISocialProvider<void, firebase.auth.UserCredential> {
  type: SocialProviderType = 'google';

  protected createDelegate(): firebase.auth.GoogleAuthProvider {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    provider.setCustomParameters({locale: this._locale || 'de'});
    return provider;
  }
}


socialProviderFactory.add('apple', (_, opts?: { locale?: string }) => new AppleSocialProvider(opts?.locale));
socialProviderFactory.add('google', (_, opts?: { locale?: string }) => new GoogleSocialProvider(opts?.locale));
socialProviderFactory.add('facebook', (_, opts?: { locale?: string }) => new FacebookSocialProvider(opts?.locale));


export const createSocialProvider = <P = any, R = any>(
  type: SocialProviderType,
  payload?: P
): ISocialProvider<P, R> | null => {
  try {
    return socialProviderFactory(type, payload);
  } catch (e) {
    console.error(e);
    return null;
  }
};


export const useSocialProvider = <P = any, R = any, X = any>(
  type: SocialProviderType,
  payload?: P, bootstrap?: (p: X) => X
): ISocialProvider<P, R> | null => {
  const bootstrapped = useRef(false);

  const provider = useMemo(
    () => {
      bootstrapped.current = false;
      return createSocialProvider<P, R>(type, payload);
    },
    [bootstrapped, type, payload]
  );

  useEffect(() => {
    if (!bootstrapped.current && provider && isFn(bootstrap)) {
      bootstrapped.current = true;
      provider.updateProvider(bootstrap);
    }
  }, [bootstrapped, bootstrap, provider]);

  return provider;
};


export enum EmailActionMode {
  verifyEmail = 'verifyEmail',
  resetPassword = 'resetPassword',
}

export const validEmailActionModes = new Set<EmailActionMode>(keys(EmailActionMode) as any);

export interface EmailActionParams {
  oobCode: string;
  continueUrl: string;
  apiKey: string;
  mode: EmailActionMode | null;
  lang: string;
}

export const useFirebaseEmailActionParams = (): EmailActionParams & { raw: string; } => {
  const oobCode = useQueryParam('oobCode');
  const apiKey = useQueryParam('apiKey');
  const continueUrl = useQueryParam('continueUrl');
  const lang = useQueryParam('lang') || 'en';

  let mode = useQueryParam<EmailActionMode>('mode');

  if (!mode || !validEmailActionModes.has(mode)) {
    mode = null;
  }

  return useMemo(
    () => ({mode, oobCode, continueUrl, lang, apiKey, raw: window.location.search}),
    [mode, oobCode, continueUrl, lang, apiKey]
  );
};

export const firebaseErrorCodeKey = (error?: { code: string; }) => error && error?.code
  ? error.code.replace(/^auth\//, '')
  : null;

export const useFirebaseErrorCodeKey = (error?: { code: string; }, opts?: { pre?: string; post?: string; }): string | null => {
  const code = useMemo(() => firebaseErrorCodeKey(error), [error]);
  const {pre, post} = opts || {};

  return useMemo(() => {
    if (isNil(code)) {
      return null;
    }

    let c = code;

    if (isNonEmptyStr(pre)) {
      c = `${pre}.${c}`;
    }

    if (isNonEmptyStr(post)) {
      c = `${c}.${post}`;
    }

    return c;
  }, [code, pre, post]);
};
