import React, {useEffect, useRef} from 'react';
import {IAuthUserAccount, useUserAccountName} from '../../index';
import {animated} from 'react-spring';

import {
  classNames,
  UiButton,
  UiButtonVariation,
  UiCustomizable,
  UiFlexRow,
  UiTextBlock,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../../app/common/misc/app-banner-pane.component';
import {useNavigator} from '../../../../core/routing';
import {isNil} from '@bitsolve/fns';
import {Rooq, useShouldShowTour} from '../../../../core/domain.model';
import ReactTooltip from 'react-tooltip';
import {ArrowSide, UiTourTooltip} from '../../../ui/component/ui-tour-tooltip.component';
import { useMediaUrl } from "../../../image/image.api";

export interface IAthleteStatCard extends UiCustomizable {
  account: IAuthUserAccount;
  isFavorite?: boolean;
  stats: {
    value: string | number;
    label: string;
  }[];
  interact?: () => any;
  actions?: Array<{
    anchor: string;
    icon: string;
    iconSet?: string;
    interact?: (ev: MouseEvent | any) => any;
  }>;
}

//
// const useAthleteProfileImage = (account?: IAuthUserAccount) => {
//   const profilePicture = useMediaUrl(account?.profilePictureId);
//   return useMemo(
//     () => profilePicture || '/asset/brand/account-card-empty-profile-pic.png',
//     [profilePicture]
//   );
// };

export const AthleteStatCard: React.FC<IAthleteStatCard> = (props) => {
  const {account, stats, interact, className, style, actions = []} = props;

  const t = useTranslator();
  const nav = useNavigator();
  const name = useUserAccountName(account);
  const bannerFgPic = useMediaUrl(account?.profilePictureId);
  const showSeeAnalysisTour = useShouldShowTour(Rooq.UserTour.OnboardingSeeAnalysis);

  const ttId = `tour-tooltip-${Rooq.UserTour.OnboardingSeeAnalysis}`;
  const ttShowRef = useRef(false);
  useEffect(() => () => {
    ttShowRef.current = false
  }, [ttShowRef]);

  const analysisButton = <UiButton icon={'appAnalysis'}
                                   variation={UiButtonVariation.ghost}
                                   className={'icon-scale mg-l-sm'}
                                   interact={() => nav(`/portal/analysis?athleteFilter=${account.id}`)} />;

  if (isNil(account)) {
    return null;
  }

  return <animated.section className={classNames('app-athlete-stat-card', className)}
                           style={style}>
    <AppBannerPane className="app-athlete-stat-card__header"
                   aspectRatio={1 / 1.625}
                   src={'/asset/placeholder/rooq-spint-closed.jpg'}
                  fgSrc={bannerFgPic || '/asset/brand/account-card-empty-profile-pic.png'}
                  showForegroundImage={true}
                   interact={interact}
                   contentAnchor={'bs'}>
      <UiTextTitle text={`${name}`} />
    </AppBannerPane>
    <main className="app-athlete-stat-card__content d-f fd-h f-ai-c"
          style={{borderRadius: ' 0 0 0.25em 0.25em'}}>
      <ul className={'f-1 d-g'}
          style={{
            gridTemplateColumns: `repeat(${stats?.length || 0}, minmax(min-content, 1fr))`,
            gridColumnGap: `var(--size-geo--nm)`
          }}>
        {stats?.map((stat, i) => {
          return <li key={i} className="d-f fd-v">
            <UiTextTitle text={`${stat.value}`} />
            <UiTextLine className="d-b txt-sm txt-c-defd"
                        text={stat.label} />
          </li>;
        })}
      </ul>
      <UiFlexRow>
        {/*{analysisButton}*/}

        {showSeeAnalysisTour
          ? <>
            <div data-tip={'data-tip'}
                 data-for={ttId}
                 ref={ref => {
                   if (!ref || ttShowRef.current) return;
                   ttShowRef.current = true;
                   setTimeout(() => {
                     ReactTooltip.show(ref as any);
                     // ReactTooltip.rebuild();
                     // console.log('shoed')
                   }, 350);
                 }}>
              {analysisButton}
            </div>
            <ReactTooltip
              clickable
              place={'bottom'}
              effect={'solid'}
              wrapper={'div'}
              globalEventOff={'click'}
              backgroundColor={'transparent'}
              delayHide={1000}
              id={ttId}>
              <UiTourTooltip
                arrow
                arrowSide={ArrowSide.Top}
                clickToComplete={Rooq.UserTour.OnboardingSeeAnalysis}>
                <UiTextBlock text={t('app.tour.onboarding-see-analysis.message')} />
              </UiTourTooltip>
            </ReactTooltip>
          </>
          : analysisButton}
      </UiFlexRow>
    </main>
    {actions?.map((action, index) =>
      <UiButton key={index}
                variation={UiButtonVariation.semantic}
                icon={action.icon}
                iconSet={(action.iconSet || 'regular') as any}
                interact={action.interact}
                className={`app-athlete-stat-card__action app-athlete-stat-card__action--${action.anchor}`} />)}
  </animated.section>;
};
