import React from 'react';
import {IAuthUserAccount, userAccountName, userAccountOrigin} from '../../index';
import {IUiButton, UiBox, UiButtonGroup, UiFlexAlign, UiFlexCol, UiFlexRow, UiTextLine} from '@bitsolve/react-common';
import {IUiTooltipMenu, UiTooltipMenu} from '../../../ui/component/ui-tooltip-menu.component';
import {AccountAvatar} from '../../../../app/common/misc/app-avatar.component';


export const AccountListItem: React.FC<{
  account: IAuthUserAccount;
  interact?: (acc: IAuthUserAccount) => any;
  menu?: IUiTooltipMenu;
  actions?: IUiButton[];
}> = (props) => {
  const {account, interact, menu, actions} = props;
  const origin = userAccountOrigin(account);

  return <UiFlexRow ai={UiFlexAlign.c}
                    className="app__list-item app-account__list-item"
                    onClick={interact ? () => interact(account) : undefined}>
    <AccountAvatar account={account}
                   className={'app__list-item__avatar'}
                   size={10} />
    <UiFlexCol className={'f-1 app__list-item__title'}>
      <UiTextLine text={userAccountName(account)} />
      {origin && <UiTextLine text={origin}
                             className={'txt-sm txt-c-defd'} />}
    </UiFlexCol>
    {(menu || actions) && <UiBox interact={ev => ev.stopPropagation()}
                                 className={'app__list-item__menu'}>
      {menu
        ? <UiTooltipMenu {...menu} />
        : (actions ? <UiButtonGroup actions={actions} /> : null)}
    </UiBox>}
  </UiFlexRow>;
};
