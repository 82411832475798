import React from 'react';
import {
  UiButton,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiFormField,
  UiIcon,
  UiPageSegment,
  UiTextTitle,
  useFormContext,
  useTranslator
} from '@bitsolve/react-common';
import {AppBannerPane} from '../../../app/common/misc/app-banner-pane.component';
import {IClub, useBannerPicture} from '../club.model';
import {isNonEmptyStr} from '@bitsolve/fns';
import {ClubAvatar} from '../../../app/common/misc/app-avatar.component';

export interface IClubCardEditable {
  club?: IClub;
}

export const ClubCardEditable: React.FC<IClubCardEditable> = (props) => {
  const {club} = props;

  const t = useTranslator();
  const form = useFormContext();
  const {assetId, assetSrc, isLogoFallback} = useBannerPicture({
    logoPictureId: club?.logoPictureId as any,
    bannerPictureId: form.value?.bannerPictureId || club?.bannerPictureId as any,
  });

  if (!form) {
    return null;
  }

  return <AppBannerPane key={assetId || '_none'}
                        className={'app-club__card'}
                        src={assetSrc ? `${assetSrc}?width=720` : undefined}
                        blur={isLogoFallback ? 1 : 0.1}>
    <UiFlexRow className={'app-club__card__content'}>
      <UiFlexCol tag={'header'}
                 jc={UiFlexAlign.e}
                 className={'f-1 app-club__card__content__title'}>
        <UiPageSegment>
          <UiFlexCol ai={UiFlexAlign.s}>
            <UiButton variation={UiButtonVariation.semantic}
                      style={{color: `var(--color-text--inverse-dim)`}}
                      className={'mg-b-nm'}
                      color={UiColor.primary}
                      label={t('app.club.form.logoPicture.label')} />
            <UiFormField name={'logoPictureId'}
                         fieldType={'picture'}
                         controlProps={{
                           previewOptions: {width: 80, height: 80},
                           renderValue: (_: string, controlProps: any, value: string) =>
                             <ClubAvatar key={value || '_none'}
                                         club={{logoPictureId: value}}
                                         size={5} />,
                           renderPlaceholder: () => club?.logoPictureId
                             ? <ClubAvatar key={`${club?.logoPictureId}`}
                                           club={club}
                                           size={5} />
                             : <UiIcon name={'edit'} iconSet={'regular'}
                                       className={'app-club__logo-picture__placeholder'} />
                         }} />
          </UiFlexCol>
        </UiPageSegment>
        <UiPageSegment>
          {isNonEmptyStr(club?.id)
            ? <UiTextTitle text={club?.name}
                           headingLevel={2} />
            : <UiButton variation={UiButtonVariation.outlines}
                        color={UiColor.primary}
                        label={t('app.club.action.create.label')}
                        interact={() => form.submit()} />}
        </UiPageSegment>
      </UiFlexCol>
      <UiFlexCol className="f-1"
                 ai={UiFlexAlign.c}
                 style={{paddingBottom: '10%'}}>
        <UiFormField name={'bannerPictureId'}
                     fieldType={'picture'}
                     direction={UiDir.v}
                     controlProps={{
                       className: 'd-f fd-v f-ai-c',
                       previewOptions: {
                         width: 720
                       },
                       renderValue: (value: any, props: any) => {
                         return <>
                           <UiIcon className={'txt-md mg-b-nm'}
                                   name={'edit'}
                                   iconSet={'regular'} />
                           <UiButton variation={UiButtonVariation.semantic}
                                     style={{color: `var(--color-text--inverse-dim)`}}
                                     color={UiColor.primary}
                                     className={'txt-b'}
                                     label={t('app.club.form.bannerPicture.label')} />
                         </>;
                       },
                       renderPlaceholder: () => {
                         return <>
                           <UiIcon className={'txt-md mg-b-nm'}
                                   name={'edit'}
                                   iconSet={'regular'} />
                           <UiButton variation={UiButtonVariation.semantic}
                                     style={{color: `var(--color-text--inverse-dim)`}}
                                     color={UiColor.primary}
                                     className={'txt-b'}
                                     label={t('app.club.form.bannerPicture.label')} />
                         </>
                       }
                     }} />
      </UiFlexCol>
      <UiFlexCol className="f-1">
        {/* empty cell */}
      </UiFlexCol>
    </UiFlexRow>
  </AppBannerPane>;
};
