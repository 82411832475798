import React from 'react';
import {IRoute} from '../../../core/routing';
import {IClub} from '../club.model';
import {UiFlexRow} from '@bitsolve/react-common';
import {ClubListItem} from './club-list-item.component';
import {
  AppSearchModal,
  IAppSearchModal,
  IAppSearchResultProps
} from '../../../app/common/misc/app-search-modal.component';


export interface IClubSearchModal
  extends IRoute,
    Omit<IAppSearchModal<IClub>, 'resultComponent'>,
    Partial<Pick<IAppSearchModal<IClub>, 'resultComponent'>> {
  showCreateAction?: boolean;
  onClubClick?: (club: IClub) => any;
  onClose?: () => any;
}


const ClubSearchResult: React.FC<IAppSearchResultProps<IClub>> = (props) => {
  const {data, index, actions} = props;

  return <UiFlexRow className="app-search-result app-club__search-result"
                    style={{animationDelay: `${(index + 1) * 75}ms`}}>
    <ClubListItem club={data}
                  interact={x => console.log(x)}
                  actions={actions?.map(action => ({
                    ...action,
                    interact: () => action?.interact && action.interact(props)
                  }))} />
  </UiFlexRow>;
};

export const ClubSearchModal: React.FC<IClubSearchModal> = (props) => {
  const {resultComponent, initialTerm, termParamName, children, ...rest} = props;

  return <AppSearchModal termParamName={termParamName || 'search'}
                         initialTerm={initialTerm || ''}
                         resultComponent={resultComponent || ClubSearchResult}
                         {...rest}>
    {children}
  </AppSearchModal>;
}

