import React from 'react';
import {AppLayoutNavigation, IAppLayoutNavigationItem} from '../../common/layout/app-layout-navigation.component';
import {AppPerspectiveSwitch} from '../../common/perspective/app-perspective-switch.component';
import { useTranslator } from "@bitsolve/react-common";

export interface ICoachingZoneNavigation {
}

export const CoachingZoneNavigation: React.FC<ICoachingZoneNavigation> = (props) => {
  const t = useTranslator();
  const czTrainerNav: Record<string, IAppLayoutNavigationItem[]> = {
    perspective: [
      { to: '/portal/trainer/athletes', icon: 'appAthletes', label: t('app.navigation.athletes.label') },
      // {to: '/portal/trainer/clubs', icon: 'appClub2'},
      // {to: ['/portal/club/:clubId/trainers', {clubId: '-'}], icon: 'appTrainer', disabled: true},
    ],
    main:
      [
        { to: '/portal/analysis', icon: 'appAnalysis', label: t('app.navigation.analysis.label') },
        { to: '/portal/training-plan', icon: 'appTrainingPlan', label: t('app.navigation.trainingplan.label') },
        {to: t('app.navigation.shop.link'), icon: 'appShop', external: true, label: t('app.navigation.shop.label')},
        { to: t('app.navigation.contact.link'), icon: 'letter', external: true, label: t('app.navigation.contact.label') },
      ],
    session:
      [
        { to: '/portal/trainer/settings', icon: 'appSettings', label: t('app.navigation.settings.label') },
        { to: '/logout', icon: 'appLogout', label: t('app.navigation.logout.label') },
      ]
  };

  return <AppPerspectiveSwitch
    trainer={() => <AppLayoutNavigation showLogo={true}
                                        showNotifications={true}
                                        perspectiveMenu={czTrainerNav.perspective}
                                        mainMenu={czTrainerNav.main}
                                        sessionMenu={czTrainerNav.session} />}
    club={clubId => {
      const menus: Record<string, IAppLayoutNavigationItem[]> = {
        perspective: [
          {to: ['/portal/club/:clubId/athletes', {clubId}], icon: 'appAthletes', label: t('app.navigation.athletes.label')},
          {to: ['/portal/club/:clubId/settings', {clubId}], icon: 'appClub2', label: t('app.navigation.settings.label')},
          {to: ['/portal/club/:clubId/trainers', {clubId}], icon: 'appTrainer', label: t('app.navigation.trainers.label')},
        ],
        main: [
          {to: '/portal/analysis', icon: 'appAnalysis', label: t('app.navigation.analysis.label')},
          {to: '/portal/training-plan', icon: 'appTrainingPlan', label: t('app.navigation.trainingplan.label')},
          {to: t('app.navigation.shop.link'), icon: 'appShop', external: true, label: t('app.navigation.shop.label')},
          {to: t('app.navigation.contact.link'), icon: 'letter', external: true, label: t('app.navigation.contact.label')}
        ],
        session: [
          {to: '/portal/trainer/settings', icon: 'appSettings', label: t('app.navigation.settings.label')}
        ]
      };

      return <AppLayoutNavigation showLogo={true}
                                  showNotifications={true}
                                  perspectiveMenu={menus.perspective}
                                  mainMenu={menus.main}
                                  sessionMenu={menus.session} />;
    }} />;
};
