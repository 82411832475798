import React, {createContext, createElement, useContext} from 'react';

const exportedStyle = require('./_export.scss');

export interface RooqStyle {
  rooq_white: string;

  rooq_blue: string;
  rooq_blue_darker: string;
  rooq_blue_lighter: string;
  rooq_blue_lightest: string;

  rooq_red: string;
  rooq_red_darker: string;
  rooq_red_lighter: string;
  rooq_red_lightest: string;

  rooq_session_1: string;
  rooq_session_2: string;
  rooq_session_3: string;

  rooq_chart_1: string;
  rooq_chart_2: string;
  rooq_chart_3: string;
  rooq_chart_4: string;
  rooq_chart_5: string;
  rooq_chart_6: string;

  rooq_text_def: string;
  rooq_text_defb: string;
  rooq_text_defd: string;
  rooq_text_inv: string;
  rooq_text_invb: string;
  rooq_text_invd: string;
  rooq_text_pri: string;
  rooq_text_acc: string;
  rooq_text_suc: string;
  rooq_text_inf: string;
  rooq_text_wrn: string;
  rooq_text_err: string;
}

export const style: RooqStyle = exportedStyle;

const StyleContext = createContext<RooqStyle>(undefined as any);

export const useRooqStyle = () => useContext(StyleContext);

export const AppStyleProvider: React.FC = (props) =>
  createElement(StyleContext.Provider, {value: style}, props?.children);

