import React from 'react';
import {
  classNames,
  IUiButton,
  IUiTextBlock,
  IUiTextTitle,
  UiButtonGroup,
  UiCustomizable,
  UiFlexAlign,
  UiFlexCol,
  UiTextBlock,
  UiTextTitle
} from '@bitsolve/react-common';
import {isStr} from '@bitsolve/fns';

export interface IUiSplashMessage extends UiCustomizable {
  title?: string | IUiTextTitle;
  messages?: Array<string | IUiTextBlock>;
  actions?: Array<IUiButton | undefined | null | false>;
}

export const UiSplashMessage: React.FC<IUiSplashMessage> = (props) => {
  const {title, messages, actions, className, style} = props;

  return <UiFlexCol className={classNames('ui-splash-message', className)}
                    style={style}
                    ai={UiFlexAlign.c}
                    jc={UiFlexAlign.c}>
    {title && <UiTextTitle headingLevel={2}
                           className={'ui-splash-message__title'}
                           {...(isStr(title) ? {text: title} : title as IUiTextTitle)} />}
    {messages?.map((message, i) => message &&
      <UiTextBlock key={i}
                   className={'ui-splash-message__item'}
                   {...(isStr(message) ? {text: message} : message as IUiTextBlock)} />)}
    {actions && <UiButtonGroup className={'ui-splash-message__actions'}
                               actions={actions.filter(a => !!a) as IUiButton[]} />}
  </UiFlexCol>;
};
