import React, {useMemo} from 'react';
import {classNames, UiBox, UiColor, UiCustomizable, UiIcon, UiWithIcon, useTranslator} from '@bitsolve/react-common';
import {useNavigator} from '../../../../core/routing';
import {IAuthUserAccount} from '../../index';
import {AccountPicture} from './account-picture.component';
import {IUiTooltipMenu, UiTooltipMenu} from '../../../ui/component/ui-tooltip-menu.component';
import {isFn} from '@bitsolve/fns';

export interface IAccountTile extends UiCustomizable {
  account: IAuthUserAccount;
  onAccountClick?: (account: IAuthUserAccount) => any;
  tooltipMenu?: IUiTooltipMenu;
}


export const AccountTile: React.FC<IAccountTile> = (props) => {
  const {account, tooltipMenu, onAccountClick, style, className} = props;
  // const {error, dataUrl} = usePictureData(account.profilePictureId);
  // const accountName = userAccountName(account);

  if (!account) {
    return null;
  }


  return <UiBox className={classNames('app-account__tile', className)}
                style={style}
                interact={onAccountClick ? () => onAccountClick(account) : undefined}>
    {account?.profilePictureId
      ? <AccountPicture size={10}
                        mediaId={account.profilePictureId} />
      : <UiIcon name={'userProfile'} iconSet={'regular'}
                style={{
                  borderRadius: '50%',
                  width: '3.5rem',
                  height: '3.5rem',
                  backgroundColor: 'rgba(0,0,0,0.1)'
                }} />}
    {account?.firstName && <div className={'pd-t-nm txt-sm txt-c-defd'}>{account?.firstName}</div>}
    {tooltipMenu && <UiBox className="app-account__tile__tooltip-menu"
                           interact={ev => {
                             ev.stopPropagation();
                           }}>
      <UiTooltipMenu toggleIcon={'questionMark'}
                     toggleIconColor={UiColor.warn}
                     {...tooltipMenu} />
    </UiBox>}
  </UiBox>;
};


export interface IAccountActionTile extends UiCustomizable, Partial<UiWithIcon> {
  type: 'navigate' | 'click'; // amend if needed
  title?: string;
  path?: string;
  onClick?: () => any;
}

const useAccountActionTileAction = (props: IAccountActionTile) => {
  const {type, path, onClick} = props;
  const nav = useNavigator();

  return useMemo(
    () => {
      switch (type) {
        case 'navigate':
          return () => path && nav(path);
        case 'click':
          return () => isFn(onClick) ? onClick() : null;
        default:
          return () => {
          };
      }
    },
    [type, path, nav, onClick]
  );
};

export const AccountActionTile: React.FC<IAccountActionTile> = (props) => {
  const {title, icon, iconSet = 'regular', style, className} = props;
  const translate = useTranslator();
  const interact = useAccountActionTileAction(props);

  return <UiBox className={classNames('app-account__tile app-account__tile--action', className)}
                style={{
                  color: '#94ADC1',
                  ...style
                }}
                interact={interact}>
    {icon && <UiIcon name={icon}
                     iconSet={iconSet}
                     className={'mg-h-sm mg-b-sm mg-t-xs txt-md ' + (title ? 'mg-b-sm' : '')}
                     style={{
                       width: '3rem',
                       height: '3rem',
                     }} />}
    {title && <span className={'txt-sm d-ib pd-t-sm'}>{translate(title)}</span>}
  </UiBox>;
};
