import React, {useMemo} from 'react';
import {classNames, UiCustomizable, UiIcon} from '@bitsolve/react-common';
import {isNil, isNum, isStr} from '@bitsolve/fns';
import {usePictureData} from '../../../image/image.api';


export interface IAccountPicture extends UiCustomizable {
  mediaId?: string;

  size?: number | string | null;
}

export const AccountPicture: React.FC<IAccountPicture> = (props) => {
  const {mediaId, size, className, style} = props;
  const _size = useMemo(() => isNil(size) ? '1rem' : (isStr(size) ? size : `${size}em`), [size]);

  const bgPic = usePictureData(mediaId, {
    width: Math.ceil(isNum(size) ? size * 16 : 80),
    height: Math.ceil(isNum(size) ? size * 16 : 80),
  });

  const hasData = mediaId && bgPic.dataUrl;
  const baseStyle = {
    '--width': _size,
    '--height': _size,
    ...style,
  };

  if (!hasData) {
    return <UiIcon iconSet={'regular'}
                   name={'userProfile'}
                   style={{
                     ...baseStyle,
                     borderRadius: '50%',
                     overflow: 'hidden',
                     transformOrigin: 'center',
                     boxSizing: 'border-box'
                   }} />
  }

  return <figure
    key={bgPic.revision}
    role={'presentation'}
    className={classNames('app-user-account-picture', className)}
    style={{
      ...baseStyle,
      backgroundImage: hasData
        ? `url(${bgPic.dataUrl})`
        : undefined
    }} />;
};
