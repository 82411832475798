import React, {useMemo} from 'react';
import {
  classNames,
  UiBox,
  UiColor,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiTextBlock,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {disciplineColor} from '../../../../core/domain.model';
import {SimpleChartBar} from './simple-chart-bar.component';
import {disciplineIcons} from '../../../../app/common/misc/app-discipline-icons';
import {hasProp, isFn, isNil, isNotFalse, isNotNil, prop} from '@bitsolve/fns';
import {IRoute} from '../../../../core/routing';
import {animated, useSpring, useTrail} from 'react-spring';

export type IBarSegmentChartDataSegmentRoutedFn = (route: IRoute, index: number) => boolean;

export interface IBarSegmentChartDataSegment {
  label: string;
  value: number;
  color?: string;
  routable?: boolean;
  routed?: boolean | IBarSegmentChartDataSegmentRoutedFn;
  sessionIndex?: number;
}

export interface IBarSegmentChartData {
  label?: string;
  color?: UiColor | string;
  segments: IBarSegmentChartDataSegment[];
}

type IBarSegmentClickHandler = (segment: IBarSegmentChartDataSegment, index: number, route: IRoute) => any;

export interface IBarSegmentChart {
  data: IBarSegmentChartData[];
  legend?: { label: string; color?: string; }[];
  unit?: string;
  segmentStyle?: 'regular' | 'solid';
  showDataLabel?: boolean;
  showDataAggregate?: boolean;
  onSegmentClick?: IBarSegmentClickHandler;
}


// const segmentAnimCalc = (x: number, y: number) => [-(y - window.innerHeight / 2) / 100, (x - window.innerWidth / 2) / 100, 1.025];
// const segmentAnimTrans = (...args: number[]) => {
//   const [x, y, s] = args;
//   return `perspective(${s * 5}rem) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
// };
export const BarSegmentChartDataSegment: React.FC<{
  datum: IBarSegmentChartData;
  segmentStyle: 'regular' | 'solid';
  segment: IBarSegmentChartDataSegment;
  index: number;
  onSegmentClick?: IBarSegmentClickHandler;
  route: IRoute;
  animate?: any;
}> = (props) => {
  const {segment, route, index, segmentStyle, onSegmentClick, animate} = props;
  // const [anim, set] = useSpring(() => ({xys: [0, 0, 1], config: {mass: 6, tension: 350, friction: 50}}));
  const color = segment.color || disciplineColor(segment.label);

  const {routable} = segment;
  const isClickable = useMemo(() => isNotFalse(routable) && isNotNil(onSegmentClick), [routable, onSegmentClick]);
  const isRouted = useMemo(
    () => {
      if (!hasProp(segment, 'routed') || isNil(segment.routed)) {
        return false;
      }

      return isFn(segment.routed)
        ? segment.routed(route, index)
        : !!segment.routed;
    },
    [segment, route, index]
  );

  if (!segment.value) {
    return null;
  }

  return <animated.div
    className={classNames(
      'app-analysis__chart--bar-segment__container__item',
      `app-analysis__chart--bar-segment__container__item--${segmentStyle}`,
      isClickable && 'clickable',
      isRouted && 'routed',
    )}
    // onMouseMove={({ clientX: x, clientY: y }) => set({ xys: segmentAnimCalc(x, y) })}
    // onMouseLeave={() => set({ xys: [0, 0, 1] })}
    data-discipline={segment.label}
    data-discipline-color={color}
    style={{
      flex: segment.value,
      backgroundColor: segmentStyle === 'regular'
        ? 'transparent'
        : color,
      ...animate,
    }}
    onClick={() => {
      if (!isClickable) {
        return;
      }

      onSegmentClick && onSegmentClick(segment, index, route);
    }}>
    <UiTextBlock text={`${segment.value}`}
                 className={'txt-ac txt-b txt-sm'}
                 style={segmentStyle === 'regular' ? {color} : undefined} />
    {segmentStyle === 'regular' && <SimpleChartBar animate={false} progress={1} color={color} />}
  </animated.div>;
};


export const BarSegmentChartDataSegments: React.FC<{
  fractionOfMax: number;
  datum: IBarSegmentChartData;
  segmentStyle: 'regular' | 'solid';
  onSegmentClick?: IBarSegmentClickHandler;
  route: IRoute;
}> = (props) => {
  const {datum, route, fractionOfMax, segmentStyle, onSegmentClick} = props;
  const segs = datum.segments;

  const trail = useTrail(segs.length, {
    config: {mass: 1, tension: 300, friction: 32, clamp: true, delay: 50},
    to: {
      opacity: 1,
      transform: `translateX(0)`,
    },
    from: {
      opacity: 0,
      transform: `translateX(-10%)`,
    },
  });

  return <UiFlexRow className={'app-analysis__chart--bar-segment__container'}
                    style={{
                      paddingRight: fractionOfMax < 1 ? `${(1 - fractionOfMax) * 100}%` : 0,
                      overflow: 'hidden'
                    }}>
    {trail.map((anim, i) => {
      const seg = segs[i];
      return <BarSegmentChartDataSegment key={i}
                                         animate={anim}
                                         index={i}
                                         route={route}
                                         datum={datum}
                                         segmentStyle={segmentStyle}
                                         onSegmentClick={onSegmentClick}
                                         segment={seg} />;
    })}
  </UiFlexRow>;
}

const BarSegmentChartLegendDot: React.FC<{ color: string; }> = (props) => {
  const {color} = props;

  return <svg width={'0.375rem'}
              height={'0.375rem'}
              className={'mg-r-xs'}
              viewBox={'0 0 100 100'}>
    <circle cx={50} cy={50} r={50} strokeWidth={0} fill={color} />
  </svg>;
}

const BarSegmentChartDisciplineLegend: React.FC<{ label: string; color?: string; }> = (props) => {
  const {label, color} = props;
  const icon = prop(disciplineIcons, label);
  const t = useTranslator();

  return <UiFlexRow ai={UiFlexAlign.c}
                    className={'app-analysis__chart--bar-segment__legend__disciplines__item'}>
    {color && <BarSegmentChartLegendDot color={color} />}
    {icon && <UiBox style={{width: '2em'}}
                    className={'mg-l-xs'}>
      {icon}
    </UiBox>}
    <UiTextLine text={t(label)}
                className={'mg-l-xs'} />
  </UiFlexRow>;
};

const useBarSegmentDataInfo = (data: IBarSegmentChart['data']): {
  sums: number[];
  maxSum: number;
} => {
  const sums = useMemo(() => data.map(d => d.segments.reduce((r, s) => r + s.value, 0)), [data]);
  const maxSum = useMemo(() => Math.max.apply(Math.max, sums), [sums]);

  return {sums, maxSum};
};

const BarSegmentsAggregate: React.FC<{ value?: any; show: boolean; }> = (props) => {
  const {value, show} = props;

  const anim = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? `translate3d(0,0,0)` : `translate3d(10%,0,0)`,
    config: {mass: 1, tension: 220, friction: 38, clamp: true, delay: 100}
  });

  return <UiFlexCol jc={UiFlexAlign.c}
                    tag={animated.div}
                    style={anim}>
    <UiTextTitle text={`${value}`}
                 className={'txt-b'} />
  </UiFlexCol>;
};

export const BarSegmentChart: React.FC<IBarSegmentChart & { route: IRoute; }> = (props) => {
  const {
    data,
    legend,
    unit,
    route,
    segmentStyle = 'solid',
    showDataLabel = true,
    showDataAggregate = false,
    onSegmentClick
  } = props;
  const {maxSum, sums} = useBarSegmentDataInfo(data);

  return <UiBox className={classNames(
    'app-analysis__chart',
    'app-analysis__chart--bar-segment',
    `app-analysis__chart--bar-segment--${segmentStyle}`,
    showDataLabel && 'with-data-label',
    showDataAggregate && 'with-data-aggregate',
  )}>
    {/*<UiBox className="app-analysis__chart--bar-segment__decor" />*/}
    {data.map((d, i) => <React.Fragment key={i}>
      {showDataLabel &&
      <UiFlexCol jc={UiFlexAlign.c}>
        <UiTextLine text={d.label} className={'txt-sm txt-b'} />
      </UiFlexCol>}
      <BarSegmentChartDataSegments route={route}
                                   datum={d}
                                   fractionOfMax={1 / maxSum * sums[i]}
                                   segmentStyle={segmentStyle}
                                   onSegmentClick={onSegmentClick} />
      {showDataAggregate && <BarSegmentsAggregate value={sums[i]}
                                                  show={showDataAggregate} />}
    </React.Fragment>)}
    <UiFlexCol className={'app-analysis__chart--bar-segment__legend'}
               style={{gridColumn: `${showDataLabel ? 2 : 1} / ${showDataLabel ? 3 : 2}`}}>
      {unit && <UiFlexRow jc={UiFlexAlign.e}
                          className={'txt-c-defd txt-sm'}>
        <UiTextLine className={'mg-r-xs'} text={unit} />
        <UiTextLine className={'txt-b'} text={`${maxSum}`} />
      </UiFlexRow>}
      {legend && <UiFlexRow ai={UiFlexAlign.c}
                            className={'app-analysis__chart--bar-segment__legend__disciplines'}>
        {legend.map((item, i) =>
          <BarSegmentChartDisciplineLegend key={i} {...item} />)}
      </UiFlexRow>}
    </UiFlexCol>
  </UiBox>;
};
