import React, {ReactElement} from 'react';
import {classNames, UiFlexRow} from '@bitsolve/react-common';
import {animated, useSpring, useTrail} from 'react-spring';
import {isStr} from '@bitsolve/fns';
import {UiValueParts} from '../../../ui/component/ui-value-parts.component';

export interface IIconStatChartData {
  icon: string | ReactElement;
  label: string;
  value: string | number;
}

export interface IIconStatChart {
  data: IIconStatChartData[];
}

// const IconStatChartItemValue: React.FC<{ value: any; }> = (props) => {
//   const {value} = props;
//   const chars = useMemo(() => `${value}`.split(''), [value]);
//
//   const trail = useTrail(chars.length, {
//     transform: 'translateY(0%)',
//     from: {transform: 'translateY(-15%)'},
//     config: {
//       mass: 1,
//       tension: 340,
//       friction: 16,
//     }
//   })
//
//   return <div className={'d-if f-dh f-ai-c ui-text ui-text-title app-analysis__chart--icon-stats__item__value'}>
//     {trail.map((anim, i) => {
//       const char = chars[i];
//       return <animated.span key={`${i}.${char}`} style={anim}>{char}</animated.span>;
//     })}
//   </div>;
// };

const IconStatChartItem: React.FC<{ style: any; data: IIconStatChartData; }> = (props) => {
  const {data: d, style} = props;

  const a = useSpring({
    transform: 'scale(1)',
    opacity: 1,
    from: {transform: 'scale(0.75)', opacity: 0.5},
    config: {
      mass: 1,
      tension: 320,
      friction: 14,
    }
  });

  const text = d.value.toString();
  const icon = d.icon;

  const iconEl = isStr(icon)
    ? <animated.img src={d.icon as string}
                    alt={`${text} (${d.label})`}
                    style={a}
                    className={'app-analysis__chart--icon-stats__item__image'} />
    : <animated.div style={a}
                    className={'app-analysis__chart--icon-stats__item__image'}>
      {icon}
    </animated.div>;

  return <animated.div
    className={'app-analysis__chart--icon-stats__item'}
    style={style}>
    {iconEl}
    <UiValueParts value={d.value === 0 ? '–' : d.value}
                  className={'d-if f-dh f-ai-c ui-text ui-text-title app-analysis__chart--icon-stats__item__value'} />
    <animated.span className={'ui-text ui-text-block app-analysis__chart--icon-stats__item__label'}
                   style={{opacity: a.opacity}}>
      {d.label}
    </animated.span>
  </animated.div>;
};

export const IconStatChart: React.FC<IIconStatChart> = (props) => {
  const {data} = props;

  const trail = useTrail(data.length, {
    config: {
      mass: 2,
      tension: 200,
      friction: 28,
    },
    opacity: 1,
    from: {opacity: 0},
  });

  return <UiFlexRow
    className={classNames(
      'app-analysis__chart',
      'app-analysis__chart--icon-stats',
    )}>
    {trail.map((anim, i) => {
      const d = data[i];
      const k = `${i}.${d.value.toString()}`

      return <IconStatChartItem data={d}
                                key={k}
                                style={anim} />;
    })}
  </UiFlexRow>;
};
