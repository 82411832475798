import React from 'react';
import {
  IUiModalRoute,
  trapEvent,
  UiBox,
  UiButton,
  UiColor,
  UiFlexAlign,
  UiFlexRow,
  useTranslator
} from '@bitsolve/react-common';
import {IRoute} from '../../../core/routing';
import {propIn} from '@bitsolve/fns';
import {UiModalDialog, UiModalDialogHeader} from '../../ui/component/ui-modal-dialog.component';
import {AccountListItem} from '../../auth/component/account/account-list-item.component';
import {useMyTrainingPlanShares} from '../training-plan.api';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';

export interface ITrainingPlanSharedWithModal extends IUiModalRoute, IRoute {
  onRequestClose?: Function;
}

export const TrainingPlanSharedWithModal: React.FC<ITrainingPlanSharedWithModal> = (props) => {
  const {match, onRequestClose} = props;
  const t = useTranslator();

  const planId = propIn(match as any, ['params', 'planId']);
  const sharesReq = useMyTrainingPlanShares(planId);
  const shares = sharesReq?.response?.data?.accounts;

  return <UiModalDialog style={{minHeight: 0, maxHeight: '100%', overflow: 'hidden', flex: 1}}>
    <UiModalDialogHeader title={t('app.training-plan.shared-with.title')}
                         onClose={() => onRequestClose && onRequestClose()} />
    <UiBox className="f-1"
           style={{
             minHeight: '100%',
             maxHeight: '100%',
             overflowY: 'auto'
           }}>
      {sharesReq?.busy && <UiFlexRow ai={UiFlexAlign.c}
                                     jc={UiFlexAlign.c}>
        <AppLoadingSpinner />
      </UiFlexRow>}
      {shares?.map((acc, index) => {
        return <UiFlexRow key={`${acc.id}.${index}`}
                          className="app-search-result app-search-result--account app-search-result--athlete"
                          style={{animationDelay: `${(index + 1) * 75}ms`}}
                          onClick={ev => {
                            trapEvent()(ev);
                          }}>
          <AccountListItem
            account={acc}
            actions={[]} />
        </UiFlexRow>;
      })}
    </UiBox>
    <UiFlexRow className="pd-sm">
      <UiButton stretch
                className={'txt-b'}
                color={UiColor.accent}
                label={t('general.action.close')}
                interact={(e) => {
                  trapEvent()(e);
                  onRequestClose && onRequestClose();
                }} />
    </UiFlexRow>
  </UiModalDialog>;
};
