import React, {useCallback, useMemo} from 'react';
import {ParentSize} from '@visx/responsive';
import {scaleBand, scaleLinear} from '@visx/scale';
import {isFn, isNil, isNotNil, range} from '@bitsolve/fns';
import {AxisBottom} from '@visx/axis';
import {LinearGradient} from '@visx/gradient';
import {IRoute} from '../../../../core/routing';
import {classNames, UiFlexAlign, UiFlexRow, UiTextLine} from '@bitsolve/react-common';
import {Group} from '@visx/group';
import {ScaleBand, ScaleLinear} from 'd3';
import {animated, useSpring} from 'react-spring';
import {GridColumns} from '@visx/grid';
import {max} from 'd3-array';

export interface IBarChartData {
  value: number;
}

export type IBarChartRoutedFn = (route: IRoute, item: IBarChartData, index: number) => boolean;

export interface IBarChart {
  data: IBarChartData[];
  routed?: boolean | IBarChartRoutedFn;
  onBarClick?: (route: IRoute, item: IBarChartData, index: number) => any;
}

export const BarChartItem: React.FC<{
  route?: IRoute;
  yScale: ScaleLinear<any, any>;
  xScale: ScaleBand<any>;
  yMax: number;
  item: IBarChartData;
  index: number;
  minClickableHeight: number;
  isClickable: boolean;
  isRouted: (item: IBarChartData, index: number) => boolean;
  onBarClick?: (route: IRoute, item: IBarChartData, index: number) => any;
}> = (props) => {
  const {index: i, minClickableHeight, yScale, xScale, yMax, route, isRouted, isClickable, onBarClick} = props;
  const d = props.item;

  const value: number = yScale(d.value);

  // @ts-ignore
  const barWidth = xScale.bandwidth();
  const barHeight = Math.max(minClickableHeight, value);

  const _routed = useMemo(() => isRouted(d, i), [isRouted, d, i]);
  const anim = useSpring({
    opacity: 1,
    transform: 'translateY(0%)  scaleY(1)',
    from: {opacity: 0, transform: 'translateY(12.5%) scaleY(0.75)'},
    config: {mass: 1, delay: 35 * i, tension: 200, friction: 35 + (i * 7.5)}
  });

  if (isNil(value) || isNaN(value as any)) {
    return null;
  }

  const barProps = {
    x: xScale(i.toString(10)),
    y: (yMax - barHeight) + 1,
    width: barWidth,
    height: barHeight - 1,
    fill: _routed
      ? 'url(#barGradientActive)'
      : 'url(#barGradient)',
    rx: 4,
    onClick: () => {
      if (!route || !isFn(onBarClick)) {
        return;
      }

      onBarClick(route, d, i);
    },
    className: classNames(
      'app-analysis__chart--bar__svg__bar',
      isClickable && 'clickable',
      _routed && 'active',
    ),
    style: anim
  };

  const ts = 12;
  const label = `${d.value}`;
  const w2 = barProps.width / 2;
  const tx = barProps.x as any + w2;
  const ty = barProps.y + (2 * ts);

  return <React.Fragment key={`${i}.${value}`}>
    <animated.rect {...barProps} />
    <animated.text x={tx}
                   y={ty}
                   style={{
                     fontSize: ts,
                     ...anim
                   }}
                   strokeWidth={1}
                   stroke={'currentColor'}
                   textAnchor={'middle'}>{label}</animated.text>
  </React.Fragment>;
};

export const BarChart: React.FC<IBarChart & { route?: IRoute; }> = (props) => {
  const {data, route, routed, onBarClick} = props;

  const isClickable = useMemo(
    () => isNotNil(route) && isNotNil(routed),
    [route, routed]
  );

  const isRouted = useCallback(
    (item: IBarChartData, index: number) => {

      return isNil(routed) || isNil(route)
        ? false
        : isFn(routed) ? routed(route as any, item, index) : !!routed;
    },
    [routed, route]
  );

  const height = 280;
  const topMargin = 48;
  const yMax = height - topMargin;
  const minClickableHeight = yMax * 0.05;

  const maxY = Math.max(...data.map(d => d.value));

  return <>
    {maxY && <UiFlexRow jc={UiFlexAlign.e}
                        className={'txt-c-defd txt-sm'}
                        style={{
                          float: 'left',
                          transformOrigin: 'left top 0',
                          transform: 'rotate(-90deg) translate3d(-100%, 0, 0)',
                          paddingTop: 'var(--size-geo--nm)',
                          paddingRight: 'var(--size-geo--md)',
                        }}>
      <UiTextLine className={'mg-r-xs'} text={`Max.`} />
      <UiTextLine className={'txt-b'} text={`${maxY}`} />
    </UiFlexRow>}
    <ParentSize debounceTime={32}
                parentSizeStyles={{height}}
                className={'app-analysis__chart app-analysis__chart--bar mg-t-nm pd-h-nm'}>
      {bounds => {
        const n = data.length;
        const width = Math.floor(bounds.width);
        const maxVal = max(data, d => d.value) || 0;

        const xScale = scaleBand<string>({
          range: [0, width],
          round: true,
          domain: range(0, n, 1).map(i => i.toString(10)),
          padding: 0.4,
        });
        const yScale = scaleLinear<number>({
          range: [0, yMax],
          domain: [0, maxVal],
          round: true,
        });

        const gridColOffX = ((width - (n * xScale.bandwidth())) / n) / 2;

        // const gridScale = scaleLinear<number>({
        //   range: [0, width],
        //   domain: [0, maxVal],
        // });

        return <svg width={width} height={height - 32}
                    viewBox={`0 0 ${width} ${height}`}
                    className={'app-analysis__chart--bar__svg'}
                    style={{shapeRendering: 'geometricPrecision'}}>
          <defs>
            <linearGradient gradientUnits="userSpaceOnUse"
                            id={'gridColGradient'}
                            x1={0} x2={0} y1={0} y2={height}>
              <stop stopColor={'rgba(28, 47, 63,0)'} offset={0} />
              <stop stopColor={'rgba(28, 47, 63,1)'} offset={1} />
            </linearGradient>
          </defs>
          <LinearGradient id={'barGradient'}
                          from="rgba(148, 173, 193, 0.9)"
                          to="rgba(148, 173, 193, 0.1)" />
          <LinearGradient id={'barGradientActive'}
                          from="rgba(202, 34, 67, 1)"
                          to="rgba(202, 34, 67, 0.25)" />
          <Group top={0}
                 left={-1 * gridColOffX}
                 className={'grid'}>
            <GridColumns
              height={yMax}
              scale={xScale}
              numTicks={n + 1}
              stroke={'url(#gridColGradient)'}
              strokeWidth={2}
              strokeOpacity={1}
              pointerEvents="none"
              className={'grid__columns'}
            />
          </Group>
          <Group top={0}>
            {data.map((d, i) => {

              return <BarChartItem key={`${i}.${d.value}`}
                                   minClickableHeight={minClickableHeight}
                                   xScale={xScale}
                                   yScale={yScale}
                                   yMax={yMax}
                                   route={route}
                                   isRouted={isRouted}
                                   isClickable={isClickable}
                                   onBarClick={onBarClick}
                                   item={d}
                                   index={i} />;
            })}
          </Group>
          <AxisBottom
            top={yMax}
            scale={xScale}
            tickFormat={(_, i) => `${i + 1}`}
            stroke={'rgba(255,255,255,0.1)'}
            tickStroke={'rgba(255,255,255,0.1)'}
            tickLabelProps={() => ({
              y: 24,
              fill: '#5D6F7E',
              fontSize: 12,
              textAnchor: 'middle',
            })}
          />
        </svg>;
      }}
    </ParentSize>
  </>
};
