import {
  IConnectivityGuardItem,
  IConnectivityHealthCheck,
  startBackendChecker,
  stopBackendChecker
} from '../connectivity.store';
import React, {useEffect} from 'react';
import {Connectivity} from '../connectivity.model';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';
import {AppSplashScreen, AppSplashScreenContent} from '../../../app/common/misc/app-splash-screen.component';
import {useDispatch} from 'react-redux';
import {AppFatalError} from '../../../app/common/misc/app-fatal-error.component';


// @todo intl
const useBackendCheckInterval = (id: string, opts: IConnectivityHealthCheck) => {
  const d = useDispatch();
  useEffect(() => {
    d(startBackendChecker(id, opts.url, opts.timeout));

    return () => {
      d(stopBackendChecker(id));
    };
  }, [d, id, opts]);
};

const BackendUnknown: React.FC<IConnectivityGuardItem> = (props) => {
  useBackendCheckInterval('backend/unknown', props);

  return <AppSplashScreen dim center>
    <AppSplashScreenContent>
      <AppLoadingSpinner />
    </AppSplashScreenContent>
  </AppSplashScreen>;
};

const BackendUnreachable: React.FC<IConnectivityGuardItem> = (props) => {
  useBackendCheckInterval('backend/unreachable', props);

  return <AppFatalError error={{
    name: 'backend-unreachable',
    message: 'Backend not reachable'
  }} />;
  // return <AppSplashScreen dim center>
  //   <AppSplashScreenContent>
  //     <AppLogo
  //       style={{
  //         width: '8rem',
  //         margin: 'var(--size-geo--md) auto'
  //       }} />
  //     <UiSplashMessage
  //       title={'Netzwerkproblem'}
  //       messages={[
  //         'Sorry, es scheint ein Problem mit unseren Servern vorzuliegen.',
  //         'Falls das Problem weiterhin bestehen bleibt, melde dich gerne bei uns unter support@rooq.de.'
  //       ]}
  //       actions={[
  //         {
  //           label: 'Erneut versuchen',
  //           className: 'txt-b',
  //           color: UiColor.accent,
  //           interact: () => window.location.reload()
  //         }
  //       ]} />
  //   </AppSplashScreenContent>
  // </AppSplashScreen>;
};


const BackendReachable: React.FC<IConnectivityGuardItem> = (props) => {
  const {children} = props;

  useBackendCheckInterval('backend/reachable', props);

  return <>{children}</>;
};

export const backendHealthGuards = {
  [Connectivity.UNKNOWN]: BackendUnknown,
  [Connectivity.UNREACHABLE]: BackendUnreachable,
  [Connectivity.REACHABLE]: BackendReachable,
};
