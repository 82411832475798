import React, {useMemo} from 'react';
import {IAccountActionTile} from '../account/account-tile.component';
import {Rooq} from '../../../../core/domain.model';
import {
  useDeleteTrainingInvitation,
  useFindMyTrainings,
  useFindTrainingInvitations,
  useUpdateTrainingInvitation
} from '../../../training/training.api';
import {AccountTileListing, IAccountTileListingItem} from '../account/account-tile-listing.component';
import {IAuthUserAccount} from '../../index';
import {IHttpRequestHookResult, toast, UiColor, useTranslator} from '@bitsolve/react-common';
import {isFn} from '@bitsolve/fns';
import {ITrainingInvitation} from '../../../training/training.model';
import {useAuthUser} from '../../auth.store';
import {IAtheleteReportModalContext, useAthleteReportModal} from './athlete-report-modal.component';
import {TranslateFn} from '../../../../core/util';
import {IUiTooltipMenu} from '../../../ui/component/ui-tooltip-menu.component';

export interface IAthleteTileListing {
  revision?: any;
  actionTile?: IAccountActionTile;
  onAccountClick?: (account: IAuthUserAccount) => any;
}

const receivedInviteTooltipMenu = (invite: ITrainingInvitation, reportModal: IAtheleteReportModalContext, updateInvitationCallback: (_: any) => any) =>
  (listingReq: IHttpRequestHookResult, account: IAuthUserAccount) =>
    ({
      place: 'right',
      items: [
        {
          label: 'app.club.action.update-membership.label.accept',
          icon: 'check',
          color: UiColor.success,
          interact: () => updateInvitationCallback({invitationId: invite.id, status: Rooq.InvitationStatus.ACCEPTED})
        },
        {
          label: 'app.club.action.update-membership.label.reject',
          icon: 'times',
          color: UiColor.error,
          interact: () => updateInvitationCallback({invitationId: invite.id, status: Rooq.InvitationStatus.REJECTED})
        },
        {
          label: 'app.account.action.report.athlete.label',
          icon: 'exclamationMark',
          interact: () => {
            reportModal.setData({accountId: account.id});
            reportModal.open();
          }
        },
      ]
    });

const sentInviteTooltipMenu = (invite: ITrainingInvitation, reportModal: IAtheleteReportModalContext, deleteInvitationCallback: (_: string) => any) =>
  (listingReq: IHttpRequestHookResult, account: IAuthUserAccount) =>
    ({
      place: 'right',
      items: [
        {
          label: 'app.club.action.withdraw-membership-request.label',
          icon: 'times',
          color: UiColor.error,
          interact: () => deleteInvitationCallback(invite.id)
        },
        {
          label: 'app.account.action.report.athlete.label',
          icon: 'exclamationMark',
          interact: () => {
            reportModal.setData({accountId: account.id});
            reportModal.open();
          }
        },
      ]
    });

const rejectedInviteTooltipMenu = (invite: ITrainingInvitation, reportModal: IAtheleteReportModalContext, deleteInvitationCallback: (_: string) => any) =>
  (listingReq: IHttpRequestHookResult, account: IAuthUserAccount) =>
    ({
      place: 'right',
      toggleIcon: 'times',
      toggleIconColor: UiColor.error,
      items: [
        {
          label: 'app.club.action.hide-membership-request.label',
          icon: 'times',
          color: UiColor.error,
          interact: () => deleteInvitationCallback(invite.id)
        },
        {
          label: 'app.account.action.report.athlete.label',
          icon: 'exclamationMark',
          interact: () => {
            reportModal.setData({accountId: account.id});
            reportModal.open();
          }
        },
      ]
    });

const accountListingItem = (
  t: TranslateFn,
  _: IHttpRequestHookResult,
  account: IAuthUserAccount,
  tooltipMenu?: any
): IAccountTileListingItem => {
  let menu: IUiTooltipMenu = isFn(tooltipMenu) ? tooltipMenu(_, account) : tooltipMenu;

  if (menu?.items) {
    menu.items = menu?.items?.map(item => ({
      ...item,
      label: item.label ? t(item.label) : undefined,
    }));
  }

  return {
    type: 'account',
    data: {
      account,
      tooltipMenu: menu
    }
  };
};

export const AthleteTileListing: React.FC<IAthleteTileListing> = (props) => {
  const {actionTile, onAccountClick} = props;

  const user = useAuthUser();
  const t = useTranslator();
  const req = useFindMyTrainings(Rooq.TrainingType.ATHLETE, {initialFetch: true});
  const inviteReq = useFindTrainingInvitations(Rooq.TrainingType.TRAINER, {initialFetch: true});
  const reportModal = useAthleteReportModal();

  const deleteInvitation = useDeleteTrainingInvitation(
    () => {
      toast.success(t('app.account.action.delete-relationship-invite.toast.success'));
      req.load();
      inviteReq.load();
    },
    () => toast.error(t('app.account.action.delete-relationship-invite.toast.error')),
  );

  const updateInvitation = useUpdateTrainingInvitation(
    () => {
      toast.success(t('app.account.action.update-relationship.toast.success'));
      req.load();
      inviteReq.load();
    },
    () => toast.error(t('app.account.action.update-relationship.toast.error')),
  );

  const tiles = useMemo(() => {
    const _tiles: IAccountTileListingItem[] = [];

    if (!req.busy && req.response?.data?.elements) {
      req.response?.data?.elements.forEach(invite => {
        _tiles.push(accountListingItem(t, req, invite.athlete));
      });
    }

    if (!inviteReq.busy && inviteReq.response?.data?.elements) {
      inviteReq.response?.data?.elements.forEach(invite => {
        const isPending = invite.status === Rooq.InvitationStatus.PENDING;
        const isMe = invite.invitingAccount.id === user?.uid;

        const tooltipMenuFactory = isPending
          ? (isMe
            ? sentInviteTooltipMenu(invite, reportModal, deleteInvitation.callback)
            : receivedInviteTooltipMenu(invite, reportModal, updateInvitation.callback))
          : rejectedInviteTooltipMenu(invite, reportModal, deleteInvitation.callback);

        const targetAcc = isMe ? invite.invitedAccount : invite.invitingAccount;

        _tiles.push(accountListingItem(t, inviteReq, targetAcc, tooltipMenuFactory));
      });
    }

    if (actionTile) {
      _tiles.push({type: 'action', data: actionTile});
    }

    return _tiles;
  }, [t, actionTile, req, inviteReq, user, updateInvitation, reportModal, deleteInvitation.callback]);

  return <>
    <AccountTileListing items={tiles}
                        onAccountClick={onAccountClick} />
    {reportModal.element}
    {updateInvitation.modal.element}
    {deleteInvitation.modal.element}
  </>;
};
