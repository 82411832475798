import React, {useMemo, useState} from 'react';
import {
  IUiFieldProps,
  UiBox,
  UiFlexRow,
  useFormContext,
  useLocale,
  useMomentDate,
  useTranslator
} from '@bitsolve/react-common';
import {range} from '@bitsolve/fns';
import {UiSelectControl} from './ui-select-control.component';
import moment from 'moment';
import {TranslateFn} from '../../../core/util';


export interface UiDateInputControlComponentProps extends IUiFieldProps<string> {
}

const today = new Date();

export const UiDateInputsControl: React.FC<UiDateInputControlComponentProps> = (props) => {
  const {value, onChange, name} = props;

  const t = useTranslator();
  const form = useFormContext();
  const date = useMomentDate(value || today);

  const [day, setDay] = useState(date.date());
  const [month, setMonth] = useState(date.month());
  const [year, setYear] = useState(date.year());

  const currentValue = useMemo(
    () => `${year}-${(month + 1).toString(10).padStart(2, '0')}-${day.toString(10).padStart(2, '0')}T00:00`,
    [year, month, day]
  );

  const setDaySafe = (day: number) => {
    const nextValue = ymdIso(year, month, day);
    if (currentValue !== nextValue) {
      const parsed = moment(nextValue);

      if (parsed.isValid()) {
        setDay(day);
        onChange && onChange(nextValue);
      } else {
        form.setError(createDateValidationError(t, name, nextValue));
      }
    }
  }

  const setMonthSafe = (month: number) => {
    const nextValue = ymdIso(year, month, day);
    if (currentValue !== nextValue) {
      const parsed = moment(nextValue);

      if (parsed.isValid()) {
        setMonth(month);
        onChange && onChange(nextValue);
      } else {
        form.setError(createDateValidationError(t, name, nextValue));
      }
    }
  }

  const setYearSafe = (year: number) => {
    const nextValue = ymdIso(year, month, day);
    if (currentValue !== nextValue) {
      const parsed = moment(nextValue);

      if (parsed.isValid()) {
        setYear(year);
        onChange && onChange(nextValue);
      } else {
        form.setError(createDateValidationError(t, name, nextValue));
      }
    }
  }

  return <UiFlexRow className={props.className}>
    <UiDateDayInput value={day} onChange={setDaySafe} />
    <UiDateMonthInput value={month} onChange={setMonthSafe} />
    <UiDateYearInput value={year} onChange={setYearSafe} />
  </UiFlexRow>;
};

const ymdIso = (year: number, month: number, day: number): string =>
  `${year}-${(month + 1).toString(10).padStart(2, '0')}-${day.toString(10).padStart(2, '0')}T00:00`;

const createDateValidationError = (t: TranslateFn, fieldName: string, value: string) => ({
  name: 'ValidationError',
  path: fieldName,
  type: 'mixed',
  message: t(`app.account.profile.form.${fieldName}.invalid`),
  errors: [t(`app.account.profile.form.${fieldName}.invalid`)],
  inner: [],
  value,
});

type UiDatePartialInputProps = Partial<IUiFieldProps<number>>;

const daySelectOptions = range(1, 32).map(d => ({value: d, label: `${d}`}));

const yearSelectOptions = range(1900, today.getFullYear() + 1).map(y => ({value: y, label: y}));


const UiDateDayInput: React.FC<UiDatePartialInputProps> = (props) => {
  return <UiBox className="form-field__control f-1 mg-r-xs">
    <UiSelectControl name="day"
                     controlProps={{}}
                     fieldType={'date-input-part-day'}
                     value={props.value}
                     onChange={props.onChange}
                     options={daySelectOptions} />
  </UiBox>;
};

const useMonthSelectOptions = () => {
  const locale = useLocale();

  return useMemo(
    () => {
      const format = new Intl.DateTimeFormat(locale, {month: 'long'}).format;
      return (new Array(12))
        .fill('')
        .map((value, index) => (
          {'label': format(new Date(Date.UTC(2021, index))), 'value': index}
        ));
    },
    [locale]
  );
};

const UiDateMonthInput: React.FC<UiDatePartialInputProps> = (props) => {
  const monthSelectOptions = useMonthSelectOptions();

  return <UiBox className="form-field__control f-1 mg-h-xs">
    <UiSelectControl name="month"
                     controlProps={{}}
                     fieldType={'date-input-part-month'}
                     value={props.value}
                     onChange={props.onChange}
                     options={monthSelectOptions} />
  </UiBox>;
};

const UiDateYearInput: React.FC<UiDatePartialInputProps> = (props) => {
  return <UiBox className="form-field__control f-1 mg-l-xs">
    <UiSelectControl name="year"
                     controlProps={{}}
                     fieldType={'date-input-part-year'}
                     value={props.value}
                     onChange={props.onChange}
                     options={yearSelectOptions} />
  </UiBox>;
};
