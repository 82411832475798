import React, {useEffect, useMemo, useState} from 'react';
import {useAuthAccess, useAuthAccount, useAuthActions, useAuthBusy, useAuthUser} from '../../auth.store';
import {isEvery, isNil, isNotNil} from '@bitsolve/fns';
import {useFindMyAccount} from '../../auth.api';
import {AppFatalError} from '../../../../app/common/misc/app-fatal-error.component';

export interface IAuthAccountProvider {
}

// let busy: boolean = false; // for an edge case I encountered …

export const AuthAccountProvider: React.FC<IAuthAccountProvider> = (props) => {
  const {children} = props;

  const user = useAuthUser();
  const access = useAuthAccess();
  const account = useAuthAccount();
  const isBusy = useAuthBusy();
  const {updateAccount, touch, busy, unbusy} = useAuthActions();
  const [error, setError] = useState<any>();

  const findAccount = useFindMyAccount(
    res => {
      updateAccount(res.data);
      touch();
    },
    () => {
      updateAccount(null);
      touch();
    }
  );

  const hasAccess = useMemo(
    () => isEvery(isNotNil, [user, access]),
    [user, access]
  );

  const isResolved = useMemo(
    () => isEvery(isNotNil, [user, access, account])
      && isNil(error),
    [user, access, account, error]
  );

  useEffect(
    () => {
      if (!hasAccess || isResolved || isBusy || error) {
        return;
      }
      busy();

      findAccount.send()
        .then(() => setError(undefined))
        .catch(setError)
        .finally(unbusy);
    },
    [hasAccess, isResolved, findAccount, user, error, setError, isBusy, busy, unbusy]
  );

  if (error) {
    return <AppFatalError error={{
      name: 'backend-unreachable',
      message: 'Auth backend is unreachable'
    }} />;
  }

  return <>{children}</>;
};
