import React, {CSSProperties} from 'react';
import {classNames, UiBox, UiCustomizable} from '@bitsolve/react-common';
import {isNil, prop} from '@bitsolve/fns';
import {Pie} from '@visx/shape';
import {Group} from '@visx/group';
import {PieArcDatum, ProvidedProps} from '@visx/shape/lib/shapes/Pie';
import {animated, interpolate, useTransition} from 'react-spring';
import {disciplineColor, Rooq} from '../../../core/domain.model';
import {disciplineIcons} from '../../../app/common/misc/app-discipline-icons';

type TrainingSessionPieChartData = {
  duration: number;
  discipline: string | Rooq.Discipline | Rooq.DisciplineV2 | null;
};

export interface IAnalysisTrainingSessionPieChart extends UiCustomizable {
  size?: number;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  discipline?: string | Rooq.Discipline | Rooq.DisciplineV2;
  centralImage?: string;
  centralClipStyle?: Partial<CSSProperties>;
  data: TrainingSessionPieChartData[];
}

const accessValue = (d: TrainingSessionPieChartData) => d.duration;
const accessLabel = (d: TrainingSessionPieChartData) => d.discipline || '_default';

const defaultMargin = {top: 0, right: 0, bottom: 0, left: 0};

const getColor = (arc: { data: TrainingSessionPieChartData }) => {
  const d = accessLabel(arc.data);
  return isNil(d)
    ? 'rgba(0,0,0,0.325)'
    : disciplineColor(d);
};

export const AnalysisTrainingSessionPieChart: React.FC<IAnalysisTrainingSessionPieChart> = (props) => {
  const {centralImage, discipline, centralClipStyle, data, className, style, size = 3, margin = defaultMargin} = props;
  const animate = true;
  const _size = size ? `${size}rem` : 0;
  const _sizeRem = size ? size * 16 : 0;

  if (isNil(_size)) {
    return null;
  }

  const innerWidth = _sizeRem - margin.left - margin.right;
  const innerHeight = _sizeRem - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 3;

  const hasAnyValue = data?.filter(x => !!x.discipline)
      .reduce((r, x) => r + x.duration, 0)
    > 0;

  const disciplineIcon = prop(disciplineIcons, discipline as any);

  return <UiBox className={classNames('app-analysis-training-session-pie-chart', className)}
                style={{
                  ...style,
                  fontSize: '16px',
                  paddingLeft: '2px',
                  paddingTop: '2px',
                  opacity: hasAnyValue ? 1 : 0.5
                }}>
    <svg width={_sizeRem}
         height={_sizeRem}>
      <defs>
        <clipPath id={'avatar'}>
          <circle cx={centerX} cy={centerY}
                  r={_sizeRem * 0.35}
                  style={centralClipStyle} />
        </clipPath>
      </defs>
      <Group top={centerY + margin.top}
             left={centerX + margin.left}>
        <Pie data={data}
             pieValue={accessValue}
             startAngle={0}
             outerRadius={radius}
             innerRadius={radius - donutThickness}
             cornerRadius={0}
             padAngle={0.005}>
          {pie => {
            return <AnimatedPie {...pie}
                                animate={animate}
                                getKey={arc => accessLabel(arc.data)}
                                getColor={getColor} />;
          }}
        </Pie>
      </Group>
      {disciplineIcon && <g clipPath={'url(#avatar)'}
                            width={_sizeRem}
                            height={_sizeRem}>
        <g style={{transformOrigin: 'center', transform: 'scale(0.825)'}}>
          {disciplineIcon}
        </g>
      </g>}
      {centralImage && <image width={_sizeRem}
                              height={_sizeRem}
                              xlinkHref={centralImage}
                              clipPath="url(#avatar)" />}
    </svg>
  </UiBox>;
};

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({endAngle}: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({startAngle, endAngle}: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum?: (d: PieArcDatum<Datum>) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
                              animate,
                              arcs,
                              path,
                              getKey,
                              getColor,
                              onClickDatum,
                            }: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    getKey,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  );
  return (
    <>
      {transitions.map(
        ({
           item: arc,
           props,
           key,
         }: {
          item: PieArcDatum<Datum>;
          props: AnimatedStyles;
          key: string;
        }) => {
          const [centroidX, centroidY] = path.centroid(arc);
          const hasSpaceForLabel = false; //arc.endAngle - arc.startAngle >= 0.1;

          return (
            <g key={key}>
              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  }),
                )}
                fill={getColor(arc)}
                onClick={() => onClickDatum && onClickDatum(arc)}
                onTouchStart={() => onClickDatum && onClickDatum(arc)}
              />
              {hasSpaceForLabel && (
                <animated.g style={{opacity: props.opacity}}>
                  <text
                    fill="white"
                    x={centroidX}
                    y={centroidY}
                    dy=".33em"
                    fontSize={9}
                    textAnchor="middle"
                    pointerEvents="none"
                  >
                    {getKey(arc)}
                  </text>
                </animated.g>
              )}
            </g>
          );
        },
      )}
    </>
  );
}
