import React, {createElement} from 'react';
import {Rooq} from '../../../core/domain.model';
import {isArray, isEmpty, isFalse, isNil} from '@bitsolve/fns';

type HiccupNonNil = [string] | [string, any] | [string, any, Hiccup[]];
type Hiccup = HiccupNonNil | false | undefined | null;

export const hiccup = (el: Hiccup, index: number = 0): React.ReactNode => {
  if (isFalse(el) || isNil(el) || !isArray(el) || isEmpty(el as any)) {
    return null;
  }
  const [type, props, children] = el as HiccupNonNil;
  return createElement(
    type === '<>' ? React.Fragment : type,
    {
      ...props,
      key: index,
      children: children?.map(hiccup)
    }
  );
};

// const svgBaseProps = {xmlns: 'http://www.w3.org/2000/svg', version: '1.1'};
const svgBaseProps = {};

const createDisciplineIconSvg = (children: Array<Hiccup | React.ReactNode>, props?: { viewBoxSize: number, fill?: string, stroke?: string }) => {
  const {viewBoxSize, fill, stroke} = {...props};
  const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;

  return createElement(
    'svg',
    {
      ...svgBaseProps,
      viewBox,
      fill,
      stroke,
      children: children.map((child, index) => isArray(child) ? hiccup(child as HiccupNonNil, index) : child)
    }
  );
};


// const boxing_children = [
//   ['path', {
//     key: 0,
//     stroke: 'currentColor',
//     d: 'M14.0786 16.5821C14.0786 15.8462 14.5521 15.1938 15.2518 14.9657C18.5853 13.879 22.1778 13.879 25.5113 14.9657C26.211 15.1938 26.6845 15.8462 26.6845 16.5821V17.3305C26.6845 18.5869 25.7937 19.6669 24.5603 19.9059C22.628 20.2805 21.3481 22.129 21.6774 24.0695L21.9319 25.5689C22.0963 26.5369 21.3503 27.4201 20.3684 27.4201C19.3933 27.4201 18.6492 26.5484 18.8022 25.5854L19.0517 24.0157C19.3564 22.0982 18.0891 20.2833 16.1839 19.909C14.9607 19.6686 14.0786 18.5963 14.0786 17.3497L14.0786 16.5821Z'
//   }],
//   ['path', {
//     key: 1,
//     stroke: 'currentColor',
//     d: 'M11.0068 21.0588C9.10804 16.6433 11.6076 11.5461 16.2545 10.2819C19.0454 9.52255 22.0151 9.52614 24.7991 10.3044C29.3125 11.5662 31.6861 16.5102 29.848 20.8212L27.2403 26.9374C26.7654 28.0513 25.9859 29.0088 24.9914 29.6998C23.9127 30.4493 22.6306 30.8511 21.3171 30.8511H19.4629C18.1452 30.8511 16.8592 30.4473 15.778 29.694C14.791 29.0064 14.0161 28.0564 13.5409 26.9514L11.0068 21.0588Z'
//   }]
// ];
// const boxing = createDisciplineIconSvg(boxing_children, {viewBoxSize: 40});

const rope_children = [
  <g key={0} fill={'currentColor'} fillRule={'evenodd'} transform={'translate(3, 2.5)'}>
    <path
      d={'m3.4198725 10.3788667h-.47541083c-.92180917 0-1.61690834-.74653337-1.61690834-1.7399342v-3.09321833c0-.12258584.0984225-.21968834.21968834-.21968834.121275 0 .21968833.0971025.21968833.21968834v3.09321833c0 .7561675.49518333 1.30057583 1.17753167 1.30057583h.47541083c.7574875 0 1.42270333-.606375 1.42270333-1.30057583v-6.18643667c0-.99298833.69509917-1.7399305 1.61690834-1.7399305h.47541083c.99210833 0 1.86208.8128505 1.86208 1.7399305v3.09321834c0 .12302583-.0984225.21968833-.21968833.21968833-.121275 0-.21968834-.0966625-.21968834-.21968833v-3.09321834c0-.69377-.66521583-1.3005575-1.42270333-1.3005575h-.47541083c-.68234834 0-1.17753167.54527-1.17753167 1.3005575v6.18643667c0 .92712583-.8699625 1.7399342-1.86208 1.7399342z'} />
    <g stroke={'currentColor'} fill={'none'} strokeWidth={'.5'}>
      <path
        d={'m2.39975083 5.33764h-1.67391308c-.07700092 0-.13949375-.10721333-.13949375-.24366833v-3.89872084c0-.13596916.06249283-.24366833.13949375-.24366833h1.67391308c.077 0 .13949834.10769917.13949834.24366833v3.89872084c0 .136455-.06249834.24366833-.13949834.24366833z'} />
      <path
        d={'m9.35825 9.93171667h-1.67393417c-.077 0-.13949833-.10725-.13949833-.24365v-3.89873917c0-.13596917.06249833-.2436775.13949833-.2436775h1.67393417c.077 0 .13951667.10770833.13951667.2436775v3.89873917c0 .1364-.06251667.24365-.13951667.24365z'} />
    </g>
  </g>,
];
const rope = createDisciplineIconSvg(rope_children, {viewBoxSize: 16});

// const custom_children = [
//   <g key={'0awd1'}>
//     <path d="M12.8364 51.7578C14.5163 50.3351 16.8941 49.6029 19.911 49.6029H22.0218L24.8037 45.7583"
//           stroke="currentColor"
//           strokeWidth="1.5" strokeLinecap="round" />
//     <path d="M35.1606 45.7583L38.0135 49.6029H40.1341C43.2833 49.6029 45.7174 50.4086 47.3752 52.0003"
//           stroke="currentColor"
//           strokeWidth="1.5" strokeLinecap="round" />
//     <path fillRule="evenodd" clipRule="evenodd"
//           d="M29.9477 47.1969C24.1759 47.1969 19.2171 39.9264 19.08 33.8755C16.5014 27.7559 18.2401 22.2462 18.6857 21.0193C18.7323 17.3216 19.9591 13.9423 27.7756 13.8444C32.7638 10.8813 37.872 11.4935 40.3159 15.0443C41.5819 16.8809 41.9248 19.1827 41.2636 21.2887C41.8023 23.2746 43.6291 30.9638 40.8032 34.1448C40.529 40.1468 35.6338 47.1969 29.9477 47.1969V47.1969Z"
//           stroke="currentColor" strokeWidth="1.5" />
//   </g>
// ];
// const custom = createDisciplineIconSvg(custom_children, {viewBoxSize: 60});
const custom_children = [
  <g key={'0awd1'}>
    <path d="m6 0v6m0 6v-6m0 0h-6m6 0h6" fill="none" stroke="currentColor" strokeWidth="1"
          style={{transformOrigin: 'center'}}
          transform="translate(2 2) scale(0.75)"/>
  </g>
];
const custom = createDisciplineIconSvg(custom_children, {viewBoxSize: 16});

const unknownActivity_children = [
  <path key={'0awd2'}
        fillRule="evenodd" clipRule="evenodd"
        d="M25.2127 37.1908C22.1205 37.1908 19.0283 36.0201 16.6736 33.6858C16.4997 33.5163 16.4988 33.2323 16.671 33.0557C16.844 32.8861 17.1254 32.8861 17.2985 33.0557C21.6625 37.3869 28.7629 37.3869 33.127 33.0557C35.9423 30.259 37.0518 26.1505 36.024 22.3341C35.9601 22.0935 36.1003 21.8539 36.3373 21.7918C36.5725 21.7208 36.8175 21.8628 36.8814 22.1033C37.9917 26.2295 36.7926 30.6673 33.7519 33.6858C31.3981 36.0201 28.305 37.1908 25.2127 37.1908Z"
        fill="currentColor" />,
  <path key={1}
        fillRule="evenodd" clipRule="evenodd"
        d="M13.973 28.6351C13.7777 28.6351 13.5985 28.5108 13.5452 28.3067C12.434 24.1805 13.634 19.7427 16.6738 16.7242C21.3823 12.0477 29.0445 12.0477 33.7521 16.7242C33.9269 16.8928 33.9278 17.1768 33.7547 17.3543C33.5825 17.5239 33.3003 17.5239 33.1272 17.3543C28.7631 13.0231 21.6627 13.0231 17.2986 17.3543C14.4842 20.1501 13.3748 24.2604 14.4026 28.0759C14.4656 28.3165 14.3254 28.5552 14.0893 28.6271C14.0502 28.6351 14.0112 28.6351 13.973 28.6351Z"
        fill="currentColor" />,
  <path key={2}
        fillRule="evenodd" clipRule="evenodd"
        d="M16.4226 38.4061C16.4049 38.4061 16.3871 38.4061 16.3685 38.4061C16.1253 38.3706 15.9522 38.1488 15.9824 37.91L16.546 33.3116C16.5682 33.135 16.6951 32.9921 16.8682 32.9388L21.3565 31.6972C21.5926 31.6341 21.8376 31.7682 21.9033 32.0078C21.9689 32.2386 21.8305 32.4862 21.5944 32.5483L17.391 33.7208L16.8629 38.0165C16.8354 38.2384 16.6436 38.4061 16.4226 38.4061Z"
        fill="currentColor" />,
  <path key={3}
        fillRule="evenodd" clipRule="evenodd"
        d="M28.9506 18.7301C28.7571 18.7301 28.5778 18.6068 28.5236 18.4115C28.458 18.171 28.5964 17.9225 28.8325 17.8603L33.036 16.6888L33.5649 12.3939C33.5942 12.1534 33.8126 11.9768 34.0593 12.0025C34.3025 12.0389 34.4756 12.2599 34.4454 12.5004L33.8809 17.097C33.8587 17.2754 33.7318 17.4174 33.5587 17.4698L29.0704 18.7133C29.0304 18.7301 28.9905 18.7301 28.9506 18.7301Z"
        fill="currentColor" />,
];
const unknownActivity = createDisciplineIconSvg(unknownActivity_children, {viewBoxSize: 50});

const partner_children = [
  <path
    key={'0awd3'}
    d="M15.9533 29.4902V21.1258L20.2966 24.2573M15.9533 29.4902L20.5409 35.9269M15.9533 29.4902L11.25 35.9269M20.2966 24.2573L22.4344 20.5313M20.2966 24.2573H24.9999"
    stroke="currentColor" strokeLinecap="round" />,
  <path
    key={1}
    d="M17.991 14.6484C17.991 15.9028 16.9741 16.9198 15.7196 16.9198C14.4652 16.9198 13.4482 15.9028 13.4482 14.6484C13.4482 13.3939 14.4652 12.377 15.7196 12.377C16.9741 12.377 17.991 13.3939 17.991 14.6484Z"
    stroke="currentColor" />,
  <path
    key={2}
    d="M35.2967 29.4905V21.1261L30.9534 24.2576M35.2967 29.4905L30.7091 35.9272M35.2967 29.4905L40 35.9272M30.9534 24.2576L27.5 20.625M30.9534 24.2576L27.5 26.25"
    stroke="currentColor" strokeLinecap="round" />,
  <path
    key={3}
    d="M33.259 14.6484C33.259 15.9028 34.2759 16.9198 35.5304 16.9198C36.7848 16.9198 37.8018 15.9028 37.8018 14.6484C37.8018 13.3939 36.7848 12.377 35.5304 12.377C34.2759 12.377 33.259 13.3939 33.259 14.6484Z"
    stroke="currentColor" />,
];
const partner = createDisciplineIconSvg(partner_children, {viewBoxSize: 50});

const equipment_children = [
  <rect key={'0awd4'} x="15.5508" y="14.3143" width="8.89863" height="15.4977" rx="0.5" stroke="currentColor" />,
  <path key={1} d="M13.4009 10H26.5991" stroke="currentColor" />,
  <path key={2} d="M23.2995 14.1244L20 10.8248" stroke="currentColor" />,
  <path key={3} d="M16.7002 14.1244L19.9997 10.8248" stroke="currentColor" />,
  <line key={4} x1="15.7344" y1="24.3479" x2="24.2652" y2="24.3479" stroke="currentColor" />,
  <line key={5} x1="15.7344" y1="19.3987" x2="24.2652" y2="19.3987" stroke="currentColor" />,
];
const equipment = createDisciplineIconSvg(equipment_children, {viewBoxSize: 40});

const shadow_children = [
  <path
    key={'0awd5'}
    d="M18.7626 24.5925V17.901L22.2372 20.4061M18.7626 24.5925L22.4327 29.7418M18.7626 24.5925L15 29.7418M22.2372 20.4061L23.9476 17.4254M22.2372 20.4061H25.9999"
    stroke="currentColor" strokeLinecap="round" />,
  <circle key={1} cx="18.5755" cy="12.7185" r="1.71712" stroke="currentColor" />,
];
const shadow = createDisciplineIconSvg(shadow_children, {viewBoxSize: 40});

const pause_children = [
  <g key={0} fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth=".5" transform="translate(5 2)">
    <path
      d="m4.82981 13.6281c-2.66457 0-4.82981-2.0662-4.82981-4.59978 0-2.53728 2.16524-4.60352 4.82981-4.60352 2.66458 0 4.82979 2.06624 4.82979 4.60352 0 2.53358-2.16521 4.59978-4.82979 4.59978z" />
    <path d="m8.37957 6.15855.77863-.74156" />
    <path d="m9.9209 5.89936-1.27344-1.21284" strokeLinecap="round" />
    <path d="m1.54231 6.02964-.77864-.74155" />
    <path d="m.000939 5.77045 1.273471-1.21283" strokeLinecap="round" />
    <path d="m4.88805 9.02629 1.68715-2.74455" strokeLinecap="round" />
    <path d="m4.94182 4.36664.01863-1.67475" />
    <path
      d="m6.03259 2.6918h-2.14442c-.10007 0-.1787-.06553-.1787-.14892v-1.48888c0-.083691.07863-.149215.1787-.149215h2.14442c.10007 0 .1787.065524.1787.149215v1.48888c0 .08339-.07863.14892-.1787.14892z" />
  </g>
];
const pause = createDisciplineIconSvg(pause_children, {viewBoxSize: 20});


export const disciplineIcons = {
  [Rooq.Discipline.ROPE_SKIPPING]: rope,
  [Rooq.Discipline.CUSTOM]: custom,
  [Rooq.Discipline.UNKNOWN_ACTIVITY]: unknownActivity,
  [Rooq.Discipline.PARTNER]: partner,
  [Rooq.Discipline.EQUIPMENT]: equipment,
  [Rooq.Discipline.SHADOW]: shadow,
  [Rooq.Discipline.PAUSE]: pause,
}
