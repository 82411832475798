import React from 'react';
import {classNames, IUiFieldProps, UiBox, UiFlexAlign, UiFlexRow, UiIcon} from '@bitsolve/react-common';

export interface IUiStarRatingFieldControl extends IUiFieldProps<number> {
}

export const UiStarRatingFieldControl: React.FC<IUiStarRatingFieldControl> = (props) => {
  const {value, onChange, className, style, controlProps} = props;

  return <UiFlexRow ai={UiFlexAlign.c}
                    className={classNames(controlProps?.className, className)}
                    style={style}
                    onClick={e => {
                      // @ts-ignore
                      e.persist();
                      e.stopPropagation();
                    }}>
    {[1, 2, 3, 4, 5].map((rating, index) => {
      const isActive = value ? rating <= value : false;

      return <UiBox
        key={`${rating}`}
        className={'form-field__control__subcontrol'}
        interact={() => {
          if (value !== rating) {
            onChange && onChange(rating);
          }
        }}>
        <UiIcon key={`${index}.${rating}.${isActive}`}
                className={isActive ? 'active' : 'inactive'}
                iconSet={isActive ? 'solid' : 'regular'}
                name={'star'} />
      </UiBox>
    })}
  </UiFlexRow>;
};
