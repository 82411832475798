import React, {useEffect} from 'react';
import {useFirebaseAppContext} from '../../../../module/auth/firebase';
import {useAuthActions, useAuthBusy, useAuthTouched} from '../../../../module/auth/auth.store';
import {appInitializing} from '../../../common/misc/app-prefabs';

export interface IAppLoginRoute {
}


export const AppLogoutRoute: React.FC<IAppLoginRoute> = (props) => {
  const firebase = useFirebaseAppContext();
  const {busy, unbusy, unauthenticate, touch} = useAuthActions();
  const isBusy = useAuthBusy();
  const touched = useAuthTouched();

  useEffect(
    () => {
      if (isBusy || !touched || !firebase) {
        return;
      }

      let innerTimer: any;

      let timer = setTimeout(
        () => {
          busy();
          requestAnimationFrame(() => {
            firebase.app.auth()
              .signOut()
              .then(() => {
                unauthenticate();
              })
              .catch(() => {
              })
              .finally(() => {
                innerTimer = setTimeout(unbusy, 250);
              });
          });
        },
        250
      );

      return () => {
        clearTimeout(timer);
        innerTimer && clearTimeout(innerTimer);
      };
    },
    [firebase, isBusy, touched, busy, unbusy, unauthenticate, touch]
  );

  return appInitializing;
};
