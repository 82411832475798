import React from 'react';
import {UiModalDialog} from '../../../ui/component/ui-modal-dialog.component';
import {
  toast,
  UiBox,
  UiButtonGroup,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexRow,
  UiForm,
  UiFormContextConsumer,
  UiFormField,
  uiModalDefaultDialogStyle,
  UiState,
  useModal,
  useTranslator
} from '@bitsolve/react-common';
import {ReportReason} from '../../../report/report.model';
import {useFindAccountById} from '../../auth.api';
import {IAuthUserAccount, userAccountName} from '../../index';
import {useReportContent} from '../../../report/report.api';
import {staticFeedbackControlProps} from '../../../content/content.api';
import {AppLoadingSpinner} from '../../../../app/common/misc/app-loading-spinner.component';

export interface IAthleteReportModal {
  accountId: string;
  onClose: () => any;
}

const athleteReportFields = [
  {
    label: 'Athlet',
    name: 'account',
    fieldType: 'text',
  },
  {
    label: 'Grund',
    name: 'reason',
    fieldType: 'content-select',
    direction: UiDir.v,
    controlProps: staticFeedbackControlProps,
  },
  {
    label: 'Anmerkung(en)',
    name: 'message',
    fieldType: 'textarea',
  },
];

const formToReport = (account: IAuthUserAccount, data: { reason: ReportReason; message: string; }) => ({
  reason: data.reason,
  message: data.message,
  context: {athleteAccountId: account.id},
});

export const AthleteReportModal: React.FC<IAthleteReportModal> = (props) => {
  const {accountId, onClose} = props;

  const t = useTranslator();
  const accReq = useFindAccountById(accountId, {initialFetch: true});
  const acc = accReq?.response?.data;
  const name = acc ? userAccountName(acc as any) : null;
  const createReport = useReportContent();

  return <UiModalDialog className={'app-athlete-report-modal'}
                        header={{
                          title: t('app.account.action.report.label'),
                          onClose
                        }}>
    {accReq.busy
      ? <UiFlexRow className="pd-nm" jc={UiFlexAlign.c}>
        <AppLoadingSpinner />
      </UiFlexRow>
      : acc && <UiBox style={{padding: '1.25rem'}}>
      <UiForm
        value={{
          account: name,
          reason: ReportReason.OTHER,
          message: '',
        }}
        interact={(vals) => createReport
          .send(formToReport(acc, vals))
          .then(() => {
            onClose && onClose();
            toast.success(t('app.account.action.report.toast.success'));
          })
          .catch(() => toast.error(t('app.account.action.report.toast.error')))}
        fields={athleteReportFields}>
        <UiFormField name={'account'}
                     state={UiState.readonly}
                     direction={UiDir.v} />
        <UiFormField name={'reason'}
                     className={'mg-t-nm'}
                     direction={UiDir.v} />
        <UiFormField name={'message'}
                     className={'mg-t-nm'}
                     direction={UiDir.v} />
        <footer className={'mg-t-md'}>
          <UiFormContextConsumer>
            {context => <UiButtonGroup stretch
                                       direction={UiDir.h}
                                       actions={[
                                         onClose && {
                                           label: t('app.general.cancel'),
                                           color: UiColor.primary,
                                           interact: onClose,
                                           disabled: createReport.busy,
                                         },
                                         {
                                           label: t('app.account.action.report.confirm'),
                                           className: 'txt-b',
                                           color: UiColor.accent,
                                           interact: context.submit,
                                           disabled: createReport.busy,
                                         },
                                       ]} />}
          </UiFormContextConsumer>
        </footer>
      </UiForm>
    </UiBox>}
  </UiModalDialog>;
};

export interface IAtheleteReportModalContext {
  open(): void;

  close(): void;

  setData(data: any): void;

  element: any;
}

export const useAthleteReportModal = (accountId?: string, onClose?: Function): IAtheleteReportModalContext => {
  return useModal({
    content: context => <AthleteReportModal accountId={accountId}
                                            {...context.data}
                                            onClose={() => {
                                              context.close();
                                              onClose && onClose();
                                            }} />,
    closeTimeoutMS: 0,
    style: {
      ...uiModalDefaultDialogStyle,
      content: {
        ...uiModalDefaultDialogStyle.content,
        minWidth: '28rem',
        maxWidth: '32rem',
        backgroundColor: '#1A2A39',
        color: '#fff',
        filter: 'drop-shadow(0 0.5em 1.5em rgba(0,0,0,0.325))'
      }
    }
  });
};

export const useTrainerReportModal = useAthleteReportModal;
