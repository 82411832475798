import {createContext, useContext} from 'react';

export interface IChangeContext<T = any | null | undefined> {
  dirty: boolean;
  setDirty: (dirty: boolean) => any;
  data: T;
  setData: (payload: Partial<T>) => any;
  error?: any;
  setError: (error: any) => any;
}

const stubChangeContext: IChangeContext = {
  dirty: false,
  setDirty: (_) => null,
  data: null,
  setData: (_) => null,
  error: undefined,
  setError: (_) => null,
};

export const changeContext = createContext<IChangeContext>(stubChangeContext);

export const useChangeContext = <T = any>(): IChangeContext<T> => useContext(changeContext);
