import {IAnalysisSessionV2} from '../../../../../module/analysis/analysis.model';
import {IAnalysisSegmentType} from '../../../../../module/analysis/component/analysis-details.model';
import {createSingleAnalysisSegmentsFromSessionsV2} from './coaching-zone-single-analysis';
import {createMultiAnalysisSegmentsFromSessionsV2} from './coaching-zone-multi-analysis';
import {useQueryParam} from '../../../../../core/util';
import {useMemo} from 'react';
import {
  ICoachingZoneAnalysisDetailsContext,
  ICoachingZoneAnalysisDetailsOptions,
  useAnalysisDetailsContext
} from './coaching-zone-analysis.model';


export const createSegmentsFromSessionsV2 = (
  context: ICoachingZoneAnalysisDetailsContext
): Record<'overview' | 'punches' | 'speed' | 'power', IAnalysisSegmentType[]> => {
  const {selection, sessions} = context;

  const isSingle = selection.length === 1;
  const singleSelection = isSingle ? sessions[selection[0]] : null;

  return isSingle && singleSelection
    ? createSingleAnalysisSegmentsFromSessionsV2(context)
    : createMultiAnalysisSegmentsFromSessionsV2(context);
};


export const useAnalysisSessionSelection = (key: string): string[] => {
  const selectionParam = useQueryParam(key);

  return useMemo(
    () => selectionParam
      ? selectionParam.split(',')
      : [],
    [selectionParam]
  );
};


export const useAnalysisSegments = (sessions: Record<string, IAnalysisSessionV2>, opts?: ICoachingZoneAnalysisDetailsOptions) => {
  const context = useAnalysisDetailsContext(sessions, opts);

  return useMemo(
    () => createSegmentsFromSessionsV2(context),
    [context]
  );
};
