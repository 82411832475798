import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  classNames,
  IUiFieldProps,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  useTranslator
} from '@bitsolve/react-common';
import {Rooq} from '../../../core/domain.model';
import {assoc, isNil, isStr, prop, randomUUID, update} from '@bitsolve/fns';
import {UiNumberRangeInput} from '../../ui/component/ui-number-range-input.component';


export interface IClubOpeningHourControl extends Omit<IUiFieldProps<Rooq.ClubOpeningTimeframeSlot['startTime'] | Rooq.ClubOpeningTimeframeSlot['endTime']>, 'fieldType' | 'name' | 'label'> {
}

export interface IClubOpeningDayControl extends Omit<IUiFieldProps<Rooq.ClubOpeningTimeframe>, 'fieldType' | 'name' | 'label'> {
}

export interface IClubOpeningHoursControl extends IUiFieldProps<Rooq.ClubOpeningTimeframe[]> {
}

const findOpeningDay = (day: Rooq.ClubOpeningDay, value?: Rooq.ClubOpeningTimeframe[]): Rooq.ClubOpeningTimeframe | undefined => {
  return isNil(value)
    ? undefined
    : value?.find(entry => prop(entry.days, 0) === day);
};

const openingHoursMasterState = (initValue?: Rooq.ClubOpeningTimeframe[]): Rooq.ClubOpeningTimeframe[] => {
  const days = [
    Rooq.ClubOpeningDay.MONDAY,
    Rooq.ClubOpeningDay.TUESDAY,
    Rooq.ClubOpeningDay.WEDNESDAY,
    Rooq.ClubOpeningDay.THURSDAY,
    Rooq.ClubOpeningDay.FRIDAY,
    Rooq.ClubOpeningDay.SATURDAY,
    Rooq.ClubOpeningDay.SUNDAY
  ];

  return days.reduce(
    (r, d) => {
      const times = (findOpeningDay(d, initValue)?.times || [])
        .map(t => t.id ? t : assoc(t, 'id', randomUUID()));

      // @ts-ignore
      r.push({days: [d], times});
      return r;
    },
    []
  );
};

const updateMasterDay = (state: Rooq.ClubOpeningTimeframe[], entry: Rooq.ClubOpeningTimeframe): Rooq.ClubOpeningTimeframe[] => {
  return state.map(tf => {
    const d1 = prop(tf.days, 0);
    const d2 = prop(entry.days, 0);

    return d1 === d2
      ? entry
      : tf;
  });
};

const timeRe = /T(\d\d):(\d\d)\.\d\d\dZ/;
const timeRe2 = /T(\d\d):(\d\d)Z?/;
const timeRe3 = /^(\d\d):(\d\d)$/;

const splitTime = (time: string) => {
  const matches = time.match(timeRe) || time.match(timeRe2) || time.match(timeRe3);
  return matches?.slice(1, 3);
};

export const ClubOpeningHourControl: React.FC<IClubOpeningHourControl> = (props) => {
  const {value, onChange, className, style} = props;

  const splits = value && isStr(value) ? splitTime(value) : [];

  const [hours, setHours] = useState(prop(splits, 0, ''));
  const [minutes, setMinutes] = useState(prop(splits, 1, ''));

  const localValue = useMemo(() => `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`, [hours, minutes]);

  const propagate = useCallback(() => {
    if (!onChange || value === localValue) return;
    onChange(localValue);
  }, [value, localValue, onChange]);

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      propagate();
    } else {
      mounted.current = true;
    }
  }, [mounted, propagate]);

  return <UiFlexRow className={className}
                    style={style}
                    ai={UiFlexAlign.c}
                    onBlur={(e) => {
                      e.stopPropagation();
                      propagate();
                    }}>
    <UiNumberRangeInput
      className={'tiny'}
      value={hours}
      max={23}
      onChange={setHours} />
    <span className={'pd-h-xs'}>{':'}</span>
    <UiNumberRangeInput
      className={'tiny'}
      value={minutes}
      max={59}
      step={5}
      onChange={setMinutes}
    />
  </UiFlexRow>;
};


export const ClubOpeningDayControl: React.FC<IClubOpeningDayControl> = (props) => {
  const {value, onChange, className, style} = props;

  const {days = [], times = []} = value || {};
  const hasTimes = times.length > 0;

  const t = useTranslator();

  const updateTimeEntry = useCallback(
    (id: string, key: string) => (value: any) => {
      console.log(id, key, value);
      if (isNil(id)) return;
      const _time = times.find(t => t.id === id);
      if (prop(_time, key) === value) return;
      const _times = times.map(t => t.id === id ? {...t, [key]: value} : t);
      onChange && onChange({days, times: _times as any});
    },
    [onChange, days, times]
  );

  return <div className={className}
              style={style}
              onFocus={(ev) => ev.stopPropagation()}
              onBlur={(ev) => ev.stopPropagation()}>
    <UiFlexRow ai={UiFlexAlign.c}
               style={{cursor: 'pointer'}}>
      <UiFlexRow f={1}
                 ai={UiFlexAlign.c}
                 className={classNames('txt-b', hasTimes ? 'txt-c-inv' : 'txt-c-defd', hasTimes && 'mg-b-sm')}>
        {days?.map(day => <span key={day}>{t(`general.date.weekday.${day}`)}</span>)}
      </UiFlexRow>
      <UiButton variation={UiButtonVariation.semantic}
                icon={'plus'}
                interact={() => onChange && onChange(value
                  ? update(value, 'times', (t: Rooq.ClubOpeningTimeframeSlot[]) => ([
                    ...t,
                    {startTime: null, endTime: null, id: randomUUID()}
                  ]))
                  : {days: [], times: []}
                )} />
    </UiFlexRow>
    {times.map((time, i) => {
      const updateStart = updateTimeEntry(time.id as any, 'startTime');
      const updateEnd = updateTimeEntry(time.id as any, 'endTime');

      return <UiFlexRow key={time.id}
                        className={'mg-b-xs'}
                        ai={UiFlexAlign.c}>
        <ClubOpeningHourControl value={time.startTime}
                                onChange={updateStart} />
        <span className={'pd-h-sm txt-sm txt-c-defd'}>{'bis'}</span>
        <ClubOpeningHourControl value={time.endTime}
                                onChange={updateEnd} />
        <aside className="f-1" />
        <UiButton variation={UiButtonVariation.semantic}
                  color={UiColor.error}
                  icon={'minus'}
                  interact={() => {
                    const next = [...times].filter(t => t.id !== time.id);
                    onChange && onChange(assoc(value, 'times', next));
                  }} />
      </UiFlexRow>;
    })}
  </div>;
};


export const ClubOpeningHoursControl: React.FC<IClubOpeningHoursControl> = (props) => {
  const {value, onChange, className, style} = props;

  const [master, setMaster] = useState(openingHoursMasterState(value as any));

  return <UiFlexCol className={classNames(className, 'pd-h-nm pd-v-sm')}
                    style={style}>
    {master.map((m, i) =>
      <ClubOpeningDayControl key={i}
                             className={'pd-v-sm'}
                             style={i < (master.length - 1) ? {borderBottom: '1px solid #273949'} : undefined}
                             value={m}
                             onChange={entry => {
                               console.log('change:', entry);
                               const next = updateMasterDay(master, entry as any);
                               setMaster(next);
                               onChange && onChange(next);
                             }} />)}
  </UiFlexCol>;
};
