import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import {classNames, UiBox, UiIcon, useFormattedDate, useTranslator, useUniqueId} from '@bitsolve/react-common';
import {IAnalysisSessionV2} from '../analysis.model';
import {AnalysisSessionAthleteLabel} from './analysis-session-athlete-label.component';
import {Rooq, trainingSessionColor, useTrainingSessionColor} from '../../../core/domain.model';
import {prop} from '@bitsolve/fns';
import {useRooqStyle} from '../../../style';
import {useFormattedDuration} from '../../../core/util';
import {useNavigator} from '../../../core/routing';
import {useAnalysisSessionSelection} from '../../../app/coaching-zone/routes/analysis/details/coaching-zone-analysis';

export interface IAnalysisSessionSelector {
  id?: string;
  selection: string[];
  sessions: Record<string, IAnalysisSessionV2>;
}

const ToggleIcon: React.FC<{ shown: boolean; }> = (props) => {
  const {shown} = props;

  return <>
    <UiIcon name={'times'} iconSet={'regular'}
            style={{
              display: !shown ? 'none' : undefined,
            }} />
    <UiIcon name={'caret-down'} iconSet={'regular'}
            style={{
              display: shown ? 'none' : undefined,
            }} />
  </>
};

export const AnalysisSessionSelectorToggle: React.FC<IAnalysisSessionSelector & { shown: boolean; }> = (props) => {
  const {id, shown = false, sessions, selection} = props;
  const style = useRooqStyle();

  return <div className={classNames('app-analysis__session-select__toggle', shown && 'show')}
              data-tip={'data-tip'}
              data-for={id}
              data-event={'click'}
              data-scroll-hide={'true'}>
    {selection?.map((sid, i) => {
      const session = prop(sessions, sid);
      const color = trainingSessionColor(style, i);

      return <AnalysisSessionAthleteLabel key={sid}
                                          color={color}
                                          label={session.author.firstName} />
    })}
    <UiBox className="app-analysis__session-select__toggle__icon">
      <ToggleIcon shown={shown} />
    </UiBox>
  </div>;
}

const gridItemClass = (col: string): string =>
  `app-analysis__session-select__tooltip__grid__col app-analysis__session-select__tooltip__grid__col--${col}`;

const AnalysisSessionSelectorTooltipGridItem: React.FC<{ session: IAnalysisSessionV2; index: number; }> = (props) => {
  const {session, index} = props;
  const t = useTranslator();

  const selection = useAnalysisSessionSelection('selection');
  const nav = useNavigator();

  const color = useTrainingSessionColor(index);
  const duration = useFormattedDuration(session.summary.duration / 1000);
  const startTime = Rooq.WEIRD_DATE_OFFSET + session.summary.startTime;
  const endTime = Rooq.WEIRD_DATE_OFFSET + session.summary.endTime;

  const date = useFormattedDate(startTime, t('general.date.medium'))
  const time = useFormattedDate(startTime, t('general.time.medium'))
  const time2 = useFormattedDate(endTime, t('general.time.medium'))

  return <>
    <div className={gridItemClass('name')}
         data-deletable={selection.length > 1 ? 'yes' : 'no'}
         onClick={() => {
           if (selection.length <= 1) return;
           const p = window.location.pathname;
           const q = `selection=${selection.filter(s => s !== session.id).join(',')}`;
           nav(`${p}?${q}`);
         }}>
      <AnalysisSessionAthleteLabel color={color} label={session.author.firstName} />
    </div>
    <div className={gridItemClass('score')}>
      {session.summary.rooqScore}
    </div>
    <div className={gridItemClass('duration')}>
      {duration}
    </div>
    <div className={gridItemClass('date')}>
      {date}
    </div>
    <div className={gridItemClass('time')}>
      {`${time} – ${time2}`}
    </div>
  </>;
}

export const AnalysisSessionSelectorTooltip: React.FC<IAnalysisSessionSelector> = (props) => {
  const {selection, sessions} = props;
  const t = useTranslator();

  return <div className="app-analysis__session-select__tooltip">
    <div className="app-analysis__session-select__tooltip__grid">
      <div className={gridItemClass('header')}>{t('app.analysis.details.selection.column.athlete')}</div>
      <div className={gridItemClass('header')}>{t('app.analysis.details.selection.column.score')}</div>
      <div className={gridItemClass('header')}>{t('app.analysis.details.selection.column.duration')}</div>
      <div className={gridItemClass('header')}>{t('app.analysis.details.selection.column.date')}</div>
      <div className={gridItemClass('header')}>{t('app.analysis.details.selection.column.time')}</div>
      {selection.map((id, index) => {
        const session = prop(sessions, id);

        return <AnalysisSessionSelectorTooltipGridItem
          key={id}
          session={session}
          index={index} />;
      })}
    </div>
  </div>;
}

export const AnalysisSessionSelector: React.FC<IAnalysisSessionSelector> = (props) => {
  const {id} = props;
  const [shown, setShown] = useState(false);
  const _id = useUniqueId(id || 'ana_sess_sel', 3);

  return <div className="app-analysis__session-select__container">
    <div className="app-analysis__session-select">
      <AnalysisSessionSelectorToggle {...props}
                                     shown={shown}
                                     id={_id} />
      <ReactTooltip id={_id}
                    uuid={_id}
                    className={'app-analysis__session-select__tooltip-container'}
                    place={'bottom'}
                    effect={'solid'}
                    afterHide={() => setShown(false)}
                    afterShow={() => setShown(true)}
                    backgroundColor={'transparent'}
                    borderColor={'transparent'}
                    border={false}
                    clickable={true}
                    textColor={'inherit'}
                    overridePosition={({left, top, ...bounds}, currentEvent, currentTarget, node) => {

                      const d = document.documentElement;

                      if (!node) {
                        console.warn('tooltip pos override bail (no node)');
                        return {top, left};
                      }

                      left = Math.min(d.clientWidth - (node.clientWidth || 0), left);
                      top = Math.min(d.clientHeight - (node.clientHeight || 0), top);

                      const t = currentTarget as HTMLElement;
                      const rect = t?.getBoundingClientRect();

                      if (!rect) {
                        console.warn('tooltip pos override bail (no rect)');
                        return {top, left};
                      }

                      return {
                        left: 0,
                        top: rect.height - 1,
                      };
                    }}>
        <AnalysisSessionSelectorTooltip {...props} id={_id} />
      </ReactTooltip>
    </div>
  </div>;
};
