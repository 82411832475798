import React from 'react';
import {
  classNames,
  UiButton,
  UiButtonGroup,
  UiButtonVariation,
  UiColor,
  UiCustomizable,
  UiState,
  useTranslator
} from '@bitsolve/react-common';
import {ITrainingStatus} from '../../../training/training.model';
import {IEntity, Rooq} from '../../../../core/domain.model';
import {useAthleteInvitationActions, useGetTrainingStatus} from '../../../training/training.api';
import {AppPerspectiveSwitch} from '../../../../app/common/perspective/app-perspective-switch.component';
import {IClubRelationshipStatus} from '../../../club/club.model';
import {
  useClubMembershipInvitationActions,
  useFindClubMembershipInvitation,
  useGetMembershipStatus
} from '../../../club/club.api';
import {isNil, isNonEmptyStr, isNotNil} from '@bitsolve/fns';
import {IAuthUserAccount} from '../../index';

export interface IAccountInvitationCTA extends UiCustomizable {
  accountId: string;
  account: IAuthUserAccount;
  clubId?: string;
  onInvitationCreated?: (result: IEntity) => any;
  onInvitationDeleted?: () => any;
  onInvitationUpdated?: () => any;
}

export interface IAccountTrainingInvitationCTA extends IAccountInvitationCTA {
  status: ITrainingStatus;
}

export interface IAccountClubInvitationCTA extends IAccountInvitationCTA {
  status: IClubRelationshipStatus;
}

export const AccountTrainingInvitationCTA: React.FC<IAccountTrainingInvitationCTA> = (props) => {
  const {accountId, status, onInvitationCreated, onInvitationDeleted, onInvitationUpdated, className, style} = props;

  const t = useTranslator();

  const {createInvitation, deleteInvitation, updateInvitation} = useAthleteInvitationActions({
    onCreated: onInvitationCreated,
    onUpdated: onInvitationUpdated,
    onDeleted: onInvitationDeleted,
  });

  return <div className={classNames('app-account__invitation-cta', 'ui-fx ui-fx__pane-fade-in', className)}
              style={style}>
    {createInvitation.modal.element}
    {deleteInvitation.modal.element}
    {updateInvitation.modal.element}

    {status.status === Rooq.RelationshipStatus.NONE
    && <UiButton stretch
                 label={t('app.account.action.add-relationship.alt-label')}
                 variation={UiButtonVariation.default}
                 color={UiColor.accent}
                 className={'txt-b'}
                 interact={() => createInvitation.callback(accountId)} />}
    {status.status === Rooq.RelationshipStatus.PENDING
    && (status.loggedInAuthor
      ? <UiButton stretch
                  label={t('app.account.action.delete-relationship-invite.label')}
                  variation={UiButtonVariation.default}
                  color={UiColor.primary}
                  interact={() => deleteInvitation.callback(status.relationId)} />
      : <UiButtonGroup stretch
                       variation={UiButtonVariation.default}
                       actions={[
                         {
                           label: t('app.account.action.update-relationship.label.accept'),
                           icon: 'check',
                           color: UiColor.accent,
                           interact: () => updateInvitation.callback({
                             invitationId: status.relationId,
                             status: Rooq.InvitationStatus.ACCEPTED
                           })
                         },
                         {
                           label: t('app.account.action.update-relationship.label.reject'),
                           icon: 'times',
                           color: UiColor.primary,
                           interact: () => updateInvitation.callback({
                             invitationId: status.relationId,
                             status: Rooq.InvitationStatus.REJECTED
                           })
                         },
                       ]} />)}
  </div>;
};

export const AccountClubInvitationCTA: React.FC<IAccountClubInvitationCTA> = (props) => {
  const {
    status,
    clubId,
    accountId,
    account,
    onInvitationCreated,
    onInvitationDeleted,
    onInvitationUpdated,
    className,
    style
  } = props;

  const canFetchInv = status.status !== Rooq.RelationshipStatus.ACCEPTED
    && isNonEmptyStr(status.relationId);

  const t = useTranslator();
  const invReq = useFindClubMembershipInvitation(status.relationId, {
    initialFetch: canFetchInv
  });
  const inv = invReq.response?.data;

  const {createInvitation, updateInvitation, deleteInvitation} = useClubMembershipInvitationActions({
    onCreated: onInvitationCreated,
    onUpdated: onInvitationUpdated,
    onDeleted: onInvitationDeleted
  });

  const isInvByMe = isNotNil(invReq?.lastFetchTime) && account.id === inv?.issuingAccount?.id;

  if (isNil(clubId) || (canFetchInv && invReq.busy)) {
    return null;
  }

  return <div className={classNames('app-account__invitation-cta', 'ui-fx ui-fx__pane-fade-in', className)}
              style={style}>
    {deleteInvitation.modal.element}
    {updateInvitation.modal.element}

    {status.status === Rooq.RelationshipStatus.NONE
    && <UiButton stretch
                 label={t('app.club.action.request-membership.alt-label')}
                 variation={UiButtonVariation.default}
                 color={UiColor.accent}
                 className={'txt-b'}
                 state={createInvitation.busy ? UiState.disabled : UiState.active}
                 interact={() => !createInvitation.busy && createInvitation.send({
                   invitedAs: Rooq.MembershipType.ATHLETE,
                   clubId: clubId as any,
                   accountId
                 })} />}
    {status.status === Rooq.RelationshipStatus.PENDING
    && (isInvByMe
      ? <UiButton stretch
                  label={t('app.club.action.withdraw-membership-request.alt-label')}
                  variation={UiButtonVariation.default}
                  color={UiColor.primary}
                  interact={() => inv?.id && !deleteInvitation.endpoint.busy && deleteInvitation.callback(inv?.id)} />
      : <UiButtonGroup stretch
                       variation={UiButtonVariation.default}
                       actions={[
                         {
                           label: t('app.club.action.update-membership.alt-label.accept'),
                           icon: 'check',
                           color: UiColor.accent,
                           interact: () => inv?.id && !updateInvitation.endpoint.busy && updateInvitation.callback({
                             invitationId: inv?.id,
                             status: Rooq.InvitationStatus.ACCEPTED
                           })
                         },
                         {
                           label: t('app.club.action.update-membership.alt-label.reject'),
                           icon: 'times',
                           color: UiColor.primary,
                           interact: () => inv?.id && !updateInvitation.endpoint.busy && updateInvitation.callback({
                             invitationId: inv?.id,
                             status: Rooq.InvitationStatus.REJECTED
                           })
                         },
                       ]} />)}
  </div>;
};

export const AccountInvitationCTA: React.FC<IAccountInvitationCTA> = (props) => {
  const {accountId, clubId, onInvitationCreated, onInvitationDeleted, onInvitationUpdated} = props;

  const trainingStatusReq = useGetTrainingStatus(
    accountId,
    Rooq.TrainingType.TRAINER,
    {initialFetch: true}
  );

  const membershipStatusReq = useGetMembershipStatus(
    clubId as any,
    accountId,
    Rooq.MembershipType.ATHLETE,
    {initialFetch: isNonEmptyStr(clubId)}
  );

  const trainingStatus = trainingStatusReq?.response?.data;
  const membershipStatus = membershipStatusReq?.response?.data;

  return <AppPerspectiveSwitch
    club={(clubId, account) => membershipStatus
      ? <AccountClubInvitationCTA {...props}
                                  clubId={clubId}
                                  account={account}
                                  status={membershipStatus}
                                  onInvitationCreated={x => {
                                    membershipStatusReq.load();
                                    onInvitationCreated && onInvitationCreated(x);
                                  }}
                                  onInvitationDeleted={() => {
                                    membershipStatusReq.load();
                                    onInvitationDeleted && onInvitationDeleted();
                                  }}
                                  onInvitationUpdated={() => {
                                    membershipStatusReq.load();
                                    onInvitationUpdated && onInvitationUpdated();
                                  }} />
      : null}
    trainer={(account) => trainingStatus
      ? <AccountTrainingInvitationCTA {...props}
                                      account={account}
                                      status={trainingStatus}
                                      onInvitationCreated={x => {
                                        trainingStatusReq.load();
                                        onInvitationCreated && onInvitationCreated(x);
                                      }}
                                      onInvitationDeleted={() => {
                                        trainingStatusReq.load();
                                        onInvitationDeleted && onInvitationDeleted();
                                      }}
                                      onInvitationUpdated={() => {
                                        trainingStatusReq.load();
                                        onInvitationUpdated && onInvitationUpdated();
                                      }} />
      : null} />;
};
