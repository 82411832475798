import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSubscriptionToken} from '../../../../module/shop/shop.util';
import {AppSplashScreen} from '../../../common/misc/app-splash-screen.component';
import {
  toast,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiFlexAlign,
  UiFlexRow,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {isNil, isNotNil} from '@bitsolve/fns';
import {useApplySubscriptionToken} from '../../../../module/shop/shop.api';
import {useNavigator} from '../../../../core/routing';
import {AppLoadingSpinner} from '../../../common/misc/app-loading-spinner.component';

export interface IShopSubscriptionCallbackRoute {
}

const ShopSubscriptionTokenMissing: React.FC = () => {

  return <p>no token</p>;
}

const ShopSubscriptionCallback: React.FC<{ token: string; }> = (props) => {
  const {token} = props;

  const ref = useRef(false);
  const t = useTranslator();
  const nav = useNavigator();
  const [res, setRes] = useState<any>(null);

  const goHome = useCallback(
    () => nav('/portal/trainer'),
    [nav]
  );

  const applySubscriptionToken = useApplySubscriptionToken(
    token,
    () => {
      toast.success(t(`app.shop.abo.connect.toast.success`));
      setTimeout(goHome, 1000);
    },
    () => {
      toast.error(t(`app.shop.abo.connect.toast.error`));
    }
  );

  const doApply = useCallback(
    () => {
      if (token) {
        applySubscriptionToken.send(null)
          .then(success => setRes({success}))
          .catch(error => setRes({error}));
      }
    },
    [token, applySubscriptionToken, setRes]
  );

  useEffect(
    () => {
      if (ref.current) return;
      ref.current = true;
      doApply();
    },
    [ref, doApply]
  );

  return <>
    {(isNil(res) || applySubscriptionToken.busy) && <UiTextBlock className={'txt-ac mg-b-sm'}
                                                                 text={t('app.shop.abo.connect.message.busy')} />}
    {!applySubscriptionToken.busy && isNotNil(res?.error) && <UiTextBlock className={'txt-ac mg-b-sm'}
                                                                          text={t('app.shop.abo.connect.message.error')} />}
    {!applySubscriptionToken.busy && isNotNil(res?.success) && <UiTextBlock className={'txt-ac mg-b-sm'}
                                                                            text={t(`app.shop.abo.connect.message.success`)} />}
    {applySubscriptionToken.busy
      ? <UiFlexRow jc={UiFlexAlign.c}>
        <AppLoadingSpinner />
      </UiFlexRow>
      : (isNil(res?.error)
        ? <UiButton stretch
                    label={t(`app.shop.abo.action.proceed`)}
                    variation={UiButtonVariation.default}
                    color={UiColor.accent}
                    interact={goHome} />
        : <UiButton stretch
                    label={t(`app.shop.abo.action.retry`)}
                    variation={UiButtonVariation.default}
                    color={UiColor.accent}
                    interact={doApply} />)}
  </>;
};

export const ShopSubscriptionCallbackRoute: React.FC<IShopSubscriptionCallbackRoute> = (props) => {
  const t = useTranslator();
  const token = useSubscriptionToken();

  return <AppSplashScreen center dim
                          style={{width: 'auto'}}
                          className={'mg-nm'}>
    <UiBox>
      <UiTextTitle headingLevel={2}
                   className={'txt-ac mg-b-nm'}
                   text={t('app.shop.abo.connect.title')} />
      {isNil(token)
        ? <ShopSubscriptionTokenMissing />
        : <ShopSubscriptionCallback token={token} />}
    </UiBox>
  </AppSplashScreen>;
};
