import React, {useMemo} from 'react';
import {
  IUiButton,
  UiButtonGroup,
  UiColor,
  UiDir,
  UiTextBlock,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import {useNavInteract} from '../../../common/misc/app-nav-button.component';
import {useOnboardingRedirects} from '../onboarding.routes';
import {useSubscriptionToken} from '../../../../module/shop/shop.util';
import { WelcomeSplashScreenBackground } from "../../../common/misc/app-welcome-screen.component";

export interface IAppWelcomeRoute {
}

export const AppWelcomeRoute: React.FC<IAppWelcomeRoute> = (props) => {
  useOnboardingRedirects(true);

  const t = useTranslator();
  const subToken = useSubscriptionToken();
  const navLogin = useNavInteract(subToken ? `/login?subId=${subToken}` : '/login');
  const navRegister = useNavInteract('/register');

  const welcomeActions: IUiButton[] = useMemo(() => ([
    {label: t('app.welcome.action.register.label'), color: UiColor.accent, interact: navRegister, className: 'txt-b'},
    {label: t('app.welcome.action.login.label'), color: UiColor.default, interact: navLogin}
  ]), [t, navLogin, navRegister]);

  return <WelcomeSplashScreenBackground>

    <UiTextTitle headingLevel={1}
      className={'mg-v-nm txt-xl welcome-right-side-h1'}
      text={t('app.welcome.welcomeheadline')} />
    <UiTextBlock
      className={'welcome-right-side-p txt-ac'}
      style={{fontFamily: 'Roboto'}}
      text={t(`app.welcome.welcometext`)}
    >
    </UiTextBlock>
    <div style={{width: '70%', height: 60, backgroundColor: 'yellow'}}>
      <UiButtonGroup stretch
        // style={{height: 60}}
        className={'welcome-right-side-buttons'}
        direction={UiDir.h}
        actions={welcomeActions}/>
    </div>
  </WelcomeSplashScreenBackground>
};
