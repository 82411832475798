import {createApp} from './core/system';
import {createCZAppData} from './app/coaching-zone/app-config-coaching-zone';
import {createFatalErrorAppData} from './app/common/misc/app-fatal-error.component';

import './style/_app-coaching-zone.scss';

createApp(createCZAppData, createFatalErrorAppData)
  .withGlobalHook()
  .withIconReporter()
  .start()
  .then(() => console.log('[ROOQ] %cgood to go', 'color:darkgreen;font-weight:bold;'))
  .catch((e) => console.error('[ROOQ] %cerror', 'color:darkred;font-weight:bold;', e));
