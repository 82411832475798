import React, {useMemo, useState} from 'react';
import {
  schema,
  UiButtonVariation,
  UiColor,
  UiFlexCol,
  UiForm,
  UiFormButton,
  UiFormError,
  UiFormField,
  UiState,
  UiTextBlock,
  UiTextTitle,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {firebaseErrorCodeKey, useFirebaseAppContext} from '../../../../module/auth/firebase';
import {
  AppSplashScreenContent,
  AppSplashScreenHeader
} from '../../../common/misc/app-splash-screen.component';
import {AppNavButton} from '../../../common/misc/app-nav-button.component';
import {IRoute, useNavigator} from '../../../../core/routing';
import {useOnboardingRedirects} from '../onboarding.routes';
import {authLoginFields} from '../../../../module/auth/auth.forms';
import {AuthSocialLoginButton} from '../../../../module/auth/component/auth/auth-social-login-button.component';
import {useFirebaseSocialProviders} from '../../../common/app-firebase.config';
import {AppLogoFirstLetter} from '../../../common/misc/app-logo.component';
import {ValidationError} from 'yup';
import {AppPageToSMessage} from '../../../common/page/app-page-tos-message.component';
import {useSubscriptionToken} from '../../../../module/shop/shop.util';
import { WelcomeSplashScreenBackground } from "../../../common/misc/app-welcome-screen.component";

export interface IAppLoginRoute extends IRoute {
}

const loginFields = [
  authLoginFields.email,
  authLoginFields.password
];


const useLoginSchema = () => {
  const t = useTranslator();
  const min = 3;

  return useMemo(() => schema.object({
    email: schema.string()
      .required(t(`app.login.form.email.schema.required`))
      .min(min, t(`app.login.form.email.schema.min`, {min})),
    password: schema.string()
      .required(t(`app.login.form.password.schema.required`))
  }), [t, min]);
};

const useLoginForm = () => {
  const fields = useTranslatedFields(loginFields);
  const schema = useLoginSchema();

  return useMemo(() => ({fields, schema}), [fields, schema]);
};

export const AppLoginRoute: React.FC<IAppLoginRoute> = (props) => {
  const fb = useFirebaseAppContext();
  const nav = useNavigator();
  const [busy, setBusy] = useState<boolean>(false);

  const t = useTranslator();
  const {fields, schema} = useLoginForm();
  const socialProviders = useFirebaseSocialProviders();
  const subToken = useSubscriptionToken();

  useOnboardingRedirects(true);

  return <WelcomeSplashScreenBackground>
    <AppSplashScreenHeader>
      <AppLogoFirstLetter inverted className={'welcome-right-side-initial-logo'} />
    </AppSplashScreenHeader>
    <AppSplashScreenContent style={{gridRow: '2/4'}}>
      <UiTextTitle headingLevel={1}
                   className={'mg-v-nm'}
                   text={t('app.login.title')} />
      <UiForm value={{email: '', password: ''}}
              fields={fields}
              schema={schema}
              schemaOptions={{abortEarly: false}}
              interact={(values, form) => {
                if (!fb || busy) {
                  return;
                }

                const {email, password} = values;
                setBusy(true);

                fb.app.auth()
                  .signInWithEmailAndPassword(email, password)
                  .then((res) => {
                    const path = subToken
                      ? `/portal/shop/apply-subscription?subId=${subToken}`
                      : res.user?.emailVerified
                        ? `/`
                        : `/register/verify-email`;

                    nav(path);
                  })
                  .catch((err) => {
                    setBusy(false);
                    form.setError(err);
                  });
              }}>
        <UiFormField name={'email'} />
        <UiFormField className={'mg-t-nm'} name={'password'} />
        <UiFormError>
          {(error: { code?: string } | ValidationError) => {
            if (!error) {
              return null;
            }
            const errorKey = firebaseErrorCodeKey(error as any);

            return errorKey
              ? <UiTextBlock className={'txt-c-err txt-b mg-t-nm'}
                             text={t(`app.login.error.${errorKey}`)} />
              : null
          }}
        </UiFormError>
        <UiFlexCol tag={'footer'}
                   className={'mg-t-md'}>
          <UiFormButton stretch
                        label={t('app.login.action.login.label')}
                        className={'txt-uc txt-b txt-md'}
                        state={!fb || busy ? UiState.disabled : UiState.active}
                        color={UiColor.accent}
                        interact={(form) => form.submit()} />
          {socialProviders.map((p, i) => {
            const {successRedirect, ...rest} = p;
            const redirect = successRedirect || '/';
            return <AuthSocialLoginButton key={`${i}.${p.provider.type}`}
                                          useFor={'login'}
                                          className={'mg-t-sm txt-md'}
                                          successRedirect={subToken
                                            ? `${redirect}?subId=${subToken}`
                                            : redirect}
                                          {...rest as any} />;
          })}
          <AppNavButton stretch
                        variation={UiButtonVariation.ghost}
                        to={'/reset-password'}
                        label={t('app.login.action.reset-password.label')}
                        className={'mg-t-nm'} />
          <AppNavButton stretch
                        variation={UiButtonVariation.ghost}
                        to={'/'}
                        label={t('general.action.back')} />
          <AppPageToSMessage termsUrl={'https://rooq.de/legal-information/'}
                             privacyTermsUrl={'https://rooq.de/privacy-policy/'}
                             membershipTermsUrl={'https://rooq.de/terms-conditions/'} />
        </UiFlexCol>
      </UiForm>
    </AppSplashScreenContent>
  </WelcomeSplashScreenBackground>;
};
