import {keys, prop} from '@bitsolve/fns';
import {IAuthSocialLoginButton} from '../../module/auth/component/auth/auth-social-login-button.component';
import {useMemo} from 'react';
import {useTranslator} from '@bitsolve/react-common';
import {useAppData} from '../../core/system';
import firebase from 'firebase/app';


const firebaseSocialProviders: IAuthSocialLoginButton[] = [
  {
    provider: {type: 'apple'},
    label: {
      register: 'app.register.social.apple',
      login: 'app.login.social.apple'
    },
    icon: 'appSocialApple'
  },
  {
    provider: {type: 'google'},
    label: {
      register: 'app.register.social.google',
      login: 'app.login.social.google'
    },
    icon: 'appSocialGoogle'
  },
  {
    provider: {type: 'facebook'},
    label: {
      register: 'app.register.social.facebook',
      login: 'app.login.social.facebook'
    },
    icon: 'appSocialFacebook'
  }
];


export const useFirebaseSocialProviders = () => {
  const t = useTranslator();

  return useMemo(() => {
    return firebaseSocialProviders.map(p => ({
      ...p,
      label: p.label
        ? keys(p.label).reduce(
          (r, k) => ({...r, [k]: t(prop(p.label, k))}),
          {}
        )
        : undefined
    }));
  }, [t]);
};


export const useFirebaseActionCodeSettings = (): firebase.auth.ActionCodeSettings => {
  const config = useAppData();

  return {
    url: prop(config, 'continueUrl')
  };
};
