import React from 'react';
import {AccountActionTile, AccountTile, IAccountActionTile, IAccountTile} from './account-tile.component';
import {classNames, UiBox, UiCustomizable} from '@bitsolve/react-common';
import {IAuthUserAccount} from '../../index';

export interface IAccountTileListingItem {
  type: 'account' | 'action';
  data: IAccountTile | IAccountActionTile;
}

export interface IAccountTileListing extends UiCustomizable {
  items: IAccountTileListingItem[];
  onAccountClick?: (account: IAuthUserAccount) => any;
}

export const AccountTileListing: React.FC<IAccountTileListing> = (props) => {
  const {items, onAccountClick, style, className} = props;

  return <UiBox className={classNames('app-account__tile-listing', className)}
                style={style}>
    {items.map((item, i) => {
      const tile = item.data;
      const k = `${i}.${item.type}`;

      switch (item.type) {
        case 'account':
          return <AccountTile key={k}
                              onAccountClick={onAccountClick}
                              {...tile as IAccountTile} />;
        case 'action':
          return <AccountActionTile key={k} {...tile as IAccountActionTile} />;
        default:
          return null;
      }
    })}
  </UiBox>;
};
