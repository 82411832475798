import React, {useMemo} from 'react';
import {
    IModalContext,
    modalClassNames,
    toast,
    UiBox,
    UiButtonGroup,
    UiColor,
    UiDir,
    UiForm,
    UiFormContextConsumer,
    UiFormField,
    UiState,
    useFormattedDate,
    useModal,
    useTranslator
} from '@bitsolve/react-common';
import {modalRouteStyle} from '../../ui/component/ui-modal-route.component';
import {UiModalDialog} from '../../ui/component/ui-modal-dialog.component';
import {IAnalysisSessionV2} from '../analysis.model';
import {useCreateSessionComment, useFindSessionComment, useUpdateSessionComment} from '../analysis.api';
import {isNil, isNonEmptyStr} from '@bitsolve/fns';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';
import {Rooq} from '../../../core/domain.model';

export interface IAnalysisCommentModal {
    context: {
        data: {
            session: IAnalysisSessionV2;
            rating?: number;
            comment?: string;
        }
    } & Omit<IModalContext, 'data'>;
}

export const AnalysisCommentModal: React.FC<IAnalysisCommentModal> = (props) => {
        const {context} = props;
        const {data} = context;

        const t = useTranslator();
        const startTime = Rooq.WEIRD_DATE_OFFSET + data?.session?.summary?.startTime;
        const createdDateFmt = useFormattedDate(startTime, t('general.date.medium'));
        const createdTimeFmt = useFormattedDate(startTime, t('general.time.medium'));

        const sessionId = data?.session?.id;

        const createComment = useCreateSessionComment(sessionId);
        const updateComment = useUpdateSessionComment(sessionId);

        const req = useFindSessionComment(sessionId, {initialFetch: isNonEmptyStr(sessionId)});

        const comment = req?.response?.data;

        const value = useMemo(
            () => ({
                session: startTime && createdTimeFmt && createdDateFmt
                    ? `${createdDateFmt} ${createdTimeFmt}`
                    : '–',
                rating: comment?.rating || 0,
                text: comment?.text
            }),
            [startTime, createdDateFmt, createdTimeFmt, comment]
        );

        if (req.busy && isNil(req.firstFetchTime)) {
            return <UiModalDialog
                className={'app-analysis-comment-modal'}
                header={{
                    title: t('app.analysis.details.comment.title'),
                    onClose: context.close
                }}>
                <AppLoadingSpinner/>
            </UiModalDialog>;
        }

        return <UiModalDialog
            className={'app-analysis-comment-modal'}
            header={{
                title: t('app.analysis.details.comment.title'),
                onClose: context.close
            }}>
            <UiForm
                key={req.lastFetchTime || '_'}
                value={value}
                interact={(vals) => {
                    if (!isNonEmptyStr(vals.text)) {
                        toast.error(t('app.analysis.details.action.commentempty.toast.error'))
                        return;
                    }
                    const data = {
                        text: vals?.text,
                        rating: vals?.rating,
                    };

                    const persist = isNonEmptyStr(comment?.id)
                        ? updateComment.send(data)
                        : createComment.send(data);

                    return persist
                        .then(() => {
                            context.close && context.close();
                            toast.success(t('app.analysis.details.action.comment.toast.success'));
                        })
                        .catch(() => {
                            toast.error(t('app.analysis.details.action.comment.toast.error'));
                        });
                }}
                fields={[
                    {name: 'session', fieldType: 'text', label: t('app.analysis.details.comment.form.training.label')},
                    {name: 'rating', fieldType: 'star-rating', label: t('app.analysis.details.comment.form.rating.label')},
                    {name: 'text', fieldType: 'textarea', label: t('app.analysis.details.comment.form.comment.label')},
                ]}>
                <UiBox className={'pd-nm'}>
                    <UiFormField state={UiState.readonly}
                                 direction={UiDir.v}
                                 className={'mg-b-nm txt-b'}
                                 name={'session'}/>
                    <UiFormField direction={UiDir.v}
                                 className={'mg-b-nm'}
                                 name={'rating'}/>
                    <UiFormField direction={UiDir.v}
                                 name={'text'}/>
                </UiBox>
                <UiBox className="pd-nm">
                    <UiFormContextConsumer>
                        {form => <UiButtonGroup stretch actions={[
                            {
                                label: t('general.action.cancel'),
                                color: UiColor.primary,
                                interact: context.close
                            },
                            {
                                label: t('general.action.save'),
                                color: UiColor.accent,
                                className: 'txt-b',
                                interact: form.submit,
                            },
                        ]}/>}
                    </UiFormContextConsumer>
                </UiBox>
            </UiForm>
        </UiModalDialog>;
};

export const useAnalysisCommentModal = () => {
    return useModal({
        content: context => <AnalysisCommentModal key={context?.data?.session?.id}
                                                  context={context}/>,
        style: modalRouteStyle('left'),
        ...modalClassNames('dialog')
    });
};
