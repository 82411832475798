import React from 'react';
import {
  classNames,
  modalClassNames,
  UiBox,
  UiButton,
  UiColor,
  UiDir,
  UiForm,
  UiFormButton,
  UiFormField,
  UiPageSegment,
  UiState,
  useModal,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {AppPageSectionPanel} from '../../../app/common/page/app-page-section-panel.component';
import {TrainingPlanFormDisciplinesGrid} from './training-plan-form-disciplines-grid.component';
import {TrainingPlanUnitModal} from './training-plan-unit-modal.component';
import {modalRouteStyle} from '../../ui/component/ui-modal-route.component';
import {ITrainingPlan, ITrainingPlanCreate, ITrainingPlanUpdate, trainingPlanSchema} from '../training-plan.model';
import {isNonEmptyStr} from '@bitsolve/fns';
import {useDrop} from 'react-dnd';
import {IDeleteConfirmationHookApi, useSchemaOptions} from '../../../core/api';
import {Rooq} from '../../../core/domain.model';

export interface ITrainingPlanForm {
  interact: (data: ITrainingPlanCreate | ITrainingPlanUpdate | ITrainingPlan) => any;
  value?: ITrainingPlan | ITrainingPlanCreate | ITrainingPlanUpdate;
  deleteAction?: IDeleteConfirmationHookApi;
  busy?: boolean;
}

const trainingPlanFormFields = [
  {
    name: 'name',
    label: 'app.training-plan.form.name.label',
    fieldType: 'text',
    direction: UiDir.v,
    controlProps: {autoFocus: true}
  },
  {
    name: 'description',
    label: 'app.training-plan.form.description.label',
    fieldType: 'text',
    direction: UiDir.v,
  },
  {
    name: 'trainingPlanUnits',
    fieldType: 'training-plan-units',
    direction: UiDir.v,
  }
];

const trainingPlanUnitModal = {
  data: {},
  content: (context: any) => {
    return <TrainingPlanUnitModal {...context} />;
  },
  ariaHideApp: false,
  closeTimeoutMS: 100,
  style: modalRouteStyle('right'),
  ...modalClassNames('dialog')
};

// const dummyUnits = randomTrainingPlanUnits(5);

export const TrainingPlanForm: React.FC<ITrainingPlanForm> = (props) => {
  const {interact, busy = false, deleteAction, value} = props;

  const t = useTranslator();
  const modal = useModal(trainingPlanUnitModal);
  const fields = useTranslatedFields(trainingPlanFormFields);
  const planId = (value as ITrainingPlan)?.id;

  const schemaOpts = useSchemaOptions('app.training-plan.{message}', false);

  // eslint-disable-next-line
  const [{isOver}, disciplineDrop] = useDrop({
    accept: 'discipline',
    collect: (monitor) => ({isOver: monitor.isOver({shallow: true})}),
    drop: (item, monitor) => {
      // @ts-ignore
      modal.setData({discipline: item.id});
      modal.open();
    },
  });

  const controlState = value?.exampleSession ? UiState.disabled : UiState.active;

  return <>
    <UiForm className={'app-training-plan__form'}
            id={'training-plan'}
            fields={fields}
            schema={trainingPlanSchema}
            schemaOptions={schemaOpts}
            value={{
              name: '',
              description: '',
              trainingPlanUnits: [],
              ...value
            }}
            interact={interact}>
      <UiPageSegment className={'app-training-plan__form__master'}>
        <UiBox style={{minHeight: 0, flex: 1, overflow: 'auto'}}>
          <AppPageSectionPanel title={{text: t('app.training-plan.info-title')}}
                               className={'mg-b-nm'}>
            <UiBox className="pd-nm">
              <UiFormField name={'name'}
                           className={'mg-b-nm'}
                           state={controlState} />
              <UiFormField name={'description'}
                           state={controlState} />
            </UiBox>
          </AppPageSectionPanel>
          <AppPageSectionPanel title={{text: t('app.training-plan.action.add-unit.label')}}
                               className={'mg-b-nm'}>
            <UiBox className="pd-nm">
              <TrainingPlanFormDisciplinesGrid
                state={controlState}
                interact={(discipline) => {
                  modal.setData({discipline});
                  modal.open();
                }} />
            </UiBox>
          </AppPageSectionPanel>
          <UiFormButton stretch
                        key={`${busy}`}
                        state={controlState !== UiState.active
                          ? controlState
                          : (!busy && (deleteAction ? !deleteAction.endpoint.busy : true)
                            ? UiState.active
                            : UiState.disabled)}
                        color={UiColor.accent}
                        label={t('general.action.save')}
                        icon={busy ? 'appSpinner' : undefined}
                        className={'txt-b'}
                        interact={(form) => form.submit()} />
          {isNonEmptyStr(planId) && deleteAction && <UiButton stretch
                                                              state={controlState !== UiState.active
                                                                ? controlState
                                                                : (busy || deleteAction.endpoint.busy
                                                                  ? UiState.disabled
                                                                  : UiState.active)}
                                                              color={UiColor.primary}
                                                              label={t('general.action.delete')}
                                                              className={'txt-b mg-t-nm'}
                                                              interact={() => deleteAction.callback(planId)} />}
        </UiBox>
      </UiPageSegment>
      <div ref={disciplineDrop}
           className={classNames('ui-page-segment app-training-plan__form__detail', isOver && 'discipline-drag-over')}>
        <UiFormField name={'trainingPlanUnits'}
                     controlProps={{
                       onUnitClick: (unit: Rooq.TrainingPlanUnit, index: number) => {
                         modal.setData({...unit, index, state: controlState});
                         modal.open();
                       }
                     }}
                     state={controlState} />
      </div>
      {modal.element}
    </UiForm>
  </>;
};
