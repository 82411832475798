import React, {useState} from 'react';
import {classNames, UiCustomizable, useScreenOrientationClass} from '@bitsolve/react-common';
import {isNotNil} from '@bitsolve/fns';
import {animated, useSpring} from 'react-spring';
import {NotificationCenterVisibleContext} from '../misc/app-prefabs';
import {NotificationCenter} from '../../../module/notification/component/notification-center.component';

export interface IAppLayout extends UiCustomizable {
  navigation?: any;
  showNotifications?: boolean;
}


export const AppLayout: React.FC<IAppLayout> = (props) => {
  const {navigation, showNotifications = true, className, style, children} = props;

  const orientationClassName = useScreenOrientationClass('app-layout--%s');
  const visibleState = useState<boolean>(false);
  const [ncVis] = visibleState;

  const _yOff = 22.5;

  const ncProps = useSpring({
    opacity: ncVis ? 1 : 0,
    transform: `translate3d(${ncVis ? 0 : -1 * _yOff}rem, 0, 0)`
  });

  const vpProps = useSpring({
    transform: `translate3d(${ncVis ? _yOff : 0}rem, 0, 0)`
  });

  const layoutClassName = classNames(
    'app-layout',
    orientationClassName,
    isNotNil(navigation) && 'app-layout--with-navigation',
    showNotifications && ncVis && 'app-layout--with-notification-center',
    showNotifications ? 'app-layout--with-notification-toggle' : 'app-layout--no-notifications',
    'ui-scroll-host',
    className
  );

  return <NotificationCenterVisibleContext.Provider value={visibleState}>
    <div className={layoutClassName}
         style={style}>
      {navigation && <nav className="app-layout__navigation">
        {navigation}
      </nav>}
      <animated.main className="app-layout__viewport"
                     style={vpProps}>
        {children}
      </animated.main>
      {showNotifications && <animated.aside className="app-layout__notification-center"
                                            style={ncProps}>
        <NotificationCenter messagesDestination={'/user/queue/messages'}
                            accountIdParameter={'account-id'} />
      </animated.aside>}
    </div>
  </NotificationCenterVisibleContext.Provider>;
};
