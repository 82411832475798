import React, {Suspense, useMemo} from 'react';
import {AppLayout} from '../common/layout/app-layout.component';
import {AppRouterOutlet} from '../common/misc/app-router-outlet.component';
import {coachingZoneNavRoutes, coachingZoneRoutes} from './routes/coaching-zone.routes';
import {onboardingPath, onboardingRoutes} from '../onboarding/routes/onboarding.routes';
import {useAuthAccount, useAuthBusy, useAuthTouched, useAuthUser} from '../../module/auth/auth.store';
import {IAuthUser, IAuthUserAccount} from '../../module/auth';
import {isNil, isNotNil} from '@bitsolve/fns';
import {appInitializing} from '../common/misc/app-prefabs';
import {useLocale} from '@bitsolve/react-common';
import {useSubscriptionToken} from '../../module/shop/shop.util';
import {AccountWelcomeTourModal} from '../../module/auth/component/tours/account-welcome-tour.component';

const isOnboardingLocked = (user: IAuthUser | null, account: IAuthUserAccount | null) => {
  return isNil(user)
    || isNil(account)
    || !user.emailVerified
    || !(account?.completed)
    || (!(account?.trainerProfile?.completed) && !(account?.trainerProfile?.skippedProfile))
}

export const AppCoachingZone: React.FC = () => {

  const user = useAuthUser();
  const account = useAuthAccount();
  const busy = useAuthBusy();
  const touched = useAuthTouched();
  const locale = useLocale();

  const subToken = useSubscriptionToken();
  const onboardingHomePath = onboardingPath('home');
  const onboardingHomeRoute = isNil(subToken)
    ? onboardingHomePath
    : `${onboardingHomePath}?subId=${subToken}`

  const onboardingLocked = useMemo(() => user && account
    ? isOnboardingLocked(user, account)
    : true, [user, account]);

  const initializing = isNil(user) && isNil(account) && (isNil(touched) || busy);

  if (initializing) {
    return appInitializing;
  } else if (!busy && isNotNil(touched) && onboardingLocked) {
    return <AppRouterOutlet
      redirect={onboardingHomeRoute}
      routes={onboardingRoutes} />;
  } else {
    return <>
      <AppLayout key={locale}
                 showNotifications={!onboardingLocked}
                 navigation={<AppRouterOutlet routes={coachingZoneNavRoutes} />}>
        {!onboardingLocked && <Suspense fallback={appInitializing}>
          <AppRouterOutlet
            redirect={busy ? undefined : onboardingHomeRoute}
            routes={coachingZoneRoutes} />
        </Suspense>}
      </AppLayout>
      <AccountWelcomeTourModal />
    </>;
  }
}

export default AppCoachingZone;
