import {Action, AnyAction} from 'redux';
import {Client, IMessage, StompConfig, StompHeaders, StompSubscription} from '@stomp/stompjs';
import {assocIn, dissoc, hasProp, isFn, isNil, keys, prop, propIn, randomUUID, update} from '@bitsolve/fns';
import {useSelector} from 'react-redux';
import {IStoreModule} from '@bitsolve/react-common';
import {SocketMessageHandler} from './socket.model';
import {PACKAGE_NAME, PACKAGE_VERSION} from '../../core/constants';

export interface ISocketState {
  connections?: any;
}

export enum SocketAction {
  connect = '@rooq.socket/connect',
  disconnect = '@rooq.socket/disconnect',
  disconnectError = '@rooq.socket/disconnect-error',
  subscribe = '@rooq.socket/subscribe',
  unsubscribe = '@rooq.socket/unsubscribe',
  unsubscribeAll = '@rooq.socket/unsubscribe-all',
  inboundMessage = '@rooq.socket/inbound',
  push = '@rooq.socket/push'
}

export const initialSocketState: ISocketState = {};

export const socketReducerKey = '@rooq/socket';

const baseConnectHeaders = {
  'x-client-name': PACKAGE_NAME,
  'x-client-version': PACKAGE_VERSION
};

// dirty mutable haxx
let stompClients: Map<string, Client> = new Map<string, Client>();

export const socketConnect = (alias: string, connectionConfig: StompConfig, authToken?: string) =>
  (dispatch: (action: { type: SocketAction; payload: { alias: string; connectionConfig: StompConfig; client: Client; }; }) => void, _: any) => {
    const previousClient = stompClients.get(alias);

    if (!isNil(previousClient)) {
      // previousClient?.activate();
      return;
    }

    const {connectHeaders, ...restConfig} = connectionConfig;
    const client = new Client({
      ...restConfig,
      connectHeaders: authToken
        ? {...connectHeaders, ...baseConnectHeaders, firebaseToken: authToken}
        : {...connectHeaders, ...baseConnectHeaders},
      reconnectDelay: 0,
      onWebSocketError: (err) => {
        console.error('Websocket connection error:', err);
      },
      onStompError: (err) => {
        console.error('Stomp error:', err);
      },
      onUnhandledMessage: (err) => {
        console.error('Unhandled message:', err);
      },
      onUnhandledReceipt: (err) => {
        console.error('Unhandled socket receipt:', err);
      }
    });

    stompClients.set(alias, client);
    client.activate();

    dispatch({
      type: SocketAction.connect,
      payload: {alias, connectionConfig, client}
    });
  };

export const socketUnsubscribeAll = (alias: string) =>
  (dispatch: (_: { (dispatch: any, getState: any): void; type?: SocketAction; payload?: { alias: string; unsubscribed: (string | number | symbol)[]; }; }) => void, getState: () => object) => {
    const subscriptions = propIn(getState(), [socketReducerKey, 'connections', alias, 'subscriptions']);
    const subscriptionKeys = keys(subscriptions);

    if (subscriptionKeys.length > 0) {
      subscriptionKeys.forEach(((destination: string) => {
        dispatch(socketUnsubscribe(alias, destination));
      }) as any);
    }

    dispatch({
      type: SocketAction.unsubscribeAll,
      payload: {alias, unsubscribed: subscriptionKeys}
    } as any);
  };

export const socketDisconnect = (alias: string) =>
  (dispatch: any, _: any) => {
    console.log('received disconnect action for', alias);
    dispatch(socketUnsubscribeAll(alias));

    const client = stompClients.get(alias);

    if (client) {
      client.deactivate()
        .then(() => {
          stompClients.delete(alias);

          dispatch({
            type: SocketAction.disconnect,
            payload: {alias}
          });
        })
        .catch(e => {
          console.error('failed disconnecting stomp client', e);
          dispatch({
            type: SocketAction.disconnectError,
            payload: {alias}
          });
        });
    }
  };

export const socketPush = (alias: string, destination: string, message: any, headers?: StompHeaders, _client?: Client) => {
  const client = _client || stompClients.get(alias);

  if (!client) {
    return;
  }

  client.publish({
    destination,
    headers: {
      'content-type': 'application/json',
      ...headers
    },
    body: JSON.stringify(message)
  });

  return {
    type: SocketAction.push,
    payload: {alias, message}
  }
};

export const socketInboundMessage = (alias: string, destination: string, message: IMessage) => ({
  type: SocketAction.inboundMessage,
  payload: {
    alias,
    destination,
    message: {
      timestamp: Date.now(),
      command: message?.command,
      body: message?.body,
      headers: message?.headers,
      id: prop(message?.headers, 'message-id')
    }
  }
});


export const socketSubscribe = (alias: string, destination: string, onMessage?: SocketMessageHandler, headers?: StompHeaders, _client?: Client) =>
  (dispatch: any, getState: any) => {
    const client = _client || stompClients.get(alias);

    if (!client || isNil(client) || !(client instanceof Client)) {
      console.log('[WS] invalid socket client instance, will retry');
      return;
    } else {
      console.log('[WS] subscribing to socket');
    }

    try {
      const subscription = client?.subscribe && client.subscribe(
        destination,
        (message: IMessage) => {
          try {
            dispatch(socketInboundMessage(alias, destination, message));

            if (isFn(onMessage)) {
              onMessage(message, {dispatch, getState});
            }
          } catch (e) {
            console.error(e);
          }
        },
        {
          receipt: `${randomUUID()}`,
          ...headers,
        }
      );

      dispatch({
        type: SocketAction.subscribe,
        payload: {alias, destination, headers, subscription}
      });
    } catch (e) {
      console.error('Subscribe error:', e);
    }
  };

export const socketUnsubscribe = (alias: string, destination: string, headers?: StompHeaders) =>
  (dispatch: any, getState: any) => {
    const subInfo = propIn(getState(), [socketReducerKey, 'connections', alias, 'subscriptions', destination]);

    if (subInfo && hasProp(subInfo, 'subscription')) {
      prop<any, StompSubscription>(subInfo, 'subscription')
        .unsubscribe(headers);
    }

    dispatch({
      type: SocketAction.unsubscribe,
      payload: {alias, destination, headers}
    });
  };


export const useSocketConnection = (alias: string) => {
  const conn = useSelector(state => propIn(state, [socketReducerKey, 'connections', alias]));

  return isNil(conn)
    ? null
    : {...conn, client: stompClients.get(alias)}
};

export const useSocketSubscription = (alias: string, destination: string) =>
  useSelector(
    state => propIn(state, [socketReducerKey, 'connections', alias, 'subscriptions', destination, 'subscription'])
  );

export const useSocketSubscriptionMessages = (alias: string, destination: string): IMessage[] =>
  useSelector(
    state => propIn(state, [socketReducerKey, 'connections', alias, 'subscriptions', destination, 'messages'], [])
  );

export const socketReducer = (state: ISocketState = initialSocketState, action?: Action & AnyAction) => {
  if (!action) {
    return state;
  }

  const {type, payload} = action;

  switch (type) {
    case SocketAction.connect: {
      const {alias, connectionConfig} = payload;
      return assocIn(state, ['connections', alias], {connectionConfig});
    }

    case SocketAction.disconnect: {
      return update(state, 'connections', (conns: any) => conns
        ? dissoc(conns, prop(payload, 'alias'))
        : {});
    }

    case SocketAction.subscribe: {
      const {alias, destination, headers, subscription} = payload;
      return assocIn(state, ['connections', alias, 'subscriptions', destination], {
        headers,
        subscription
      });
    }

    case SocketAction.unsubscribe: {
      const {alias, destination} = payload;
      const subscriptions = propIn(state, ['connections', alias, 'subscriptions']);
      return assocIn(state, ['connections', alias, 'subscriptions'], dissoc(subscriptions, destination));
    }

    case SocketAction.inboundMessage: {
      const {alias, destination, message} = payload;
      const subscription = propIn(state, ['connections', alias, 'subscriptions', destination]);
      return assocIn(
        state,
        ['connections', alias, 'subscriptions', destination],
        update(subscription, 'messages', (msgs: any[]) => ([...(msgs || []), message]))
      );
    }

    default:
      return state;
  }
};


export const socketSoreModule: IStoreModule = {
  key: socketReducerKey,
  reducer: socketReducer,
  initialState: initialSocketState
};
