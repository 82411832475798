import React from 'react';
import { classNames, UiCustomizable, UiIcon, UiInteractive, useTranslator } from '@bitsolve/react-common';


export interface INotificationBell extends UiCustomizable, UiInteractive<any> {
  unreadCount?: number;
}


export const NotificationBell: React.FC<INotificationBell> = (props) => {
  const {interact, unreadCount, className, style} = props;
  const t = useTranslator();

  return <div className={classNames('app-notification__bell', className)}
                style={style}
                title={t('app.navigation.notifications.label')}
                onClick={interact}>
    <UiIcon name={'appBell'}
            iconSet={'regular'} />
    {(!!unreadCount && unreadCount > 0) && (
      <span className={'app-notification__bell__unread-count'}>{unreadCount < 10 ? unreadCount : '···'}</span>
    )}
  </div>;
};

