import React from 'react';
import {UiBox, UiFlexAlign, UiFlexCol, UiFlexRow, UiTextLine, useTranslator} from '@bitsolve/react-common';
import {disciplineColor} from '../../../../core/domain.model';
import {Group} from '@visx/group';
import {Pie} from '@visx/shape';
import {PieArcDatum, ProvidedProps} from '@visx/shape/lib/shapes/Pie';
import {animated, interpolate, useTransition} from 'react-spring';
import {isNonEmptyStr} from '@bitsolve/fns';

export interface IPieChartData {
  label: string;
  value: number;
  unit?: string;
  color?: string;
}

const accessValue = (d: IPieChartData) => d.value;
const accessLabel = (d: IPieChartData) => d.label;

const defaultMargin = {top: 0, right: 0, bottom: 0, left: 0};

const getColor = (arc: { data: IPieChartData }) => {
  const d = accessLabel(arc.data);
  const c = arc.data.color || disciplineColor(d);
  return c || 'rgba(240,240,240,0.325)';
};

export interface IPieChart {
  data: IPieChartData[];
  aggregateUnit?: string;
}

const PieChartLegendDot: React.FC<{ data: IPieChartData; }> = (props) => {
  const color = getColor(props);

  return <svg width={'0.375rem'} height={'0.375rem'}
              viewBox={'0 0 100 100'}>
    <circle cx={50} cy={50} r={50} strokeWidth={0} fill={color} />
  </svg>;
}

export const PieChart: React.FC<IPieChart> = (props) => {
  const {data, aggregateUnit} = props;

  const t = useTranslator();

  const _size = 12;
  const _sizeRem = _size * 16;
  const margin = defaultMargin;
  const innerWidth = _sizeRem - margin.left - margin.right;
  const innerHeight = _sizeRem - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 8;

  const dataSum = data.map(d => d.value).reduce((r, d) => r + d, 0);
  const dataUnit = data.length && aggregateUnit ? aggregateUnit : undefined;

  return <UiFlexRow className="app-analysis__chart app-analysis__chart--double-pie">
    <UiFlexCol f={1}
               ai={UiFlexAlign.e}
               jc={UiFlexAlign.c}
               className={'mg-r-sm'}>
      <svg width={`${_size}rem`}
           height={`${_size}rem`}>
        <Group top={centerY + margin.top}
               left={centerX + margin.left}>
          <Pie data={data}
               pieValue={accessValue}
               outerRadius={radius}
               innerRadius={radius - donutThickness}
               cornerRadius={0}
               padAngle={0.005}>
            {pie => {
              return <AnimatedPie {...pie}
                                  animate={true}
                                  getKey={arc => accessLabel(arc.data)}
                                  getColor={getColor} />;
            }}
          </Pie>
          <Pie data={data}
               pieValue={accessValue}
               outerRadius={radius - donutThickness}
               innerRadius={radius - (5 * donutThickness)}
               cornerRadius={0}
               padAngle={0.005}>
            {pie => {
              return <AnimatedPie {...pie}
                                  animate={true}
                                  opacity={0.3}
                                  getKey={arc => accessLabel(arc.data)}
                                  getColor={getColor} />;
            }}
          </Pie>
        </Group>
        <Group top={centerY * 1.05}
               left={centerX}>
          <text stroke={'#fff'}
                textAnchor={'middle'}
                strokeWidth={1.5}
                x={0}
                y={-10}
                style={{fontSize: '20px', fontFamily: 'var(--font-title)'}}>{dataSum}</text>
          <text stroke={'#fff'}
                strokeWidth={0.5}
                textAnchor={'middle'}
                x={0}
                y={10}>{dataUnit ? t(dataUnit, {count: dataSum}) : '–'}</text>
        </Group>
      </svg>
    </UiFlexCol>
    <PieChartLegend {...props} />
  </UiFlexRow>;
};

const PieChartLegend: React.FC<IPieChart> = (props) => {
  const {data} = props;
  const t = useTranslator();

  return <UiFlexCol f={1}
                    className={'app-analysis__chart--double-pie__legend mg-l-sm'}
                    style={{position: 'relative'}}>
    {data.map((d, i) => {
      const valText = isNonEmptyStr(d.unit)
        ? d.unit
        : null;

      return <UiBox key={`${i}.${valText}`}
                    className={'app-analysis__chart--double-pie__legend__item f-ai-s'}>
        <UiFlexCol ai={UiFlexAlign.c}
                   jc={UiFlexAlign.c}
                   className={'pd-t-xs'}>
          <PieChartLegendDot data={d} />
        </UiFlexCol>
        <UiFlexCol ai={UiFlexAlign.s}>
          <UiTextLine text={t(d.label)}
                      className={'txt-sm txt-b'} />
          {valText && <UiTextLine text={valText}
                                  className={'txt-sm txt-c-defd mg-r-sm'} />}
        </UiFlexCol>
      </UiBox>;
    })}
  </UiFlexCol>;
};


type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({endAngle}: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({startAngle, endAngle}: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum?: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  opacity?: number;
};

function AnimatedPie<Datum>({
                              animate,
                              arcs,
                              path,
                              getKey,
                              getColor,
                              onClickDatum,
                              opacity = 1,
                            }: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
    arcs,
    getKey,
    // @ts-ignore react-spring doesn't like this overload
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    },
  );
  return (
    <>
      {transitions.map(
        ({
           item: arc,
           props,
           key,
         }: {
          item: PieArcDatum<Datum>;
          props: AnimatedStyles;
          key: string;
        }) => {
          const [centroidX, centroidY] = path.centroid(arc);
          const hasSpaceForLabel = false; //arc.endAngle - arc.startAngle >= 0.1;

          return (
            <g key={key}>
              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={interpolate([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                  path({
                    ...arc,
                    startAngle,
                    endAngle,
                  }),
                )}
                fill={getColor(arc)}
                style={{opacity}}
                onClick={() => onClickDatum && onClickDatum(arc)}
                onTouchStart={() => onClickDatum && onClickDatum(arc)}
              />
              {hasSpaceForLabel && (
                <animated.g style={{opacity: props.opacity}}>
                  <text
                    fill="white"
                    x={centroidX}
                    y={centroidY}
                    dy=".33em"
                    fontSize={9}
                    textAnchor="middle"
                    pointerEvents="none"
                  >
                    {getKey(arc)}
                  </text>
                </animated.g>
              )}
            </g>
          );
        },
      )}
    </>
  );
}
