import React, {createElement} from 'react';
import {IConnectivityGuardItem, useConnectivity} from '../connectivity.store';
import {prop} from '@bitsolve/fns';
import {IAppDataContext} from '../../../core/system';
import {Connectivity} from '../connectivity.model';


export type IConnectivityGuard = {
  checks: IAppDataContext['health'];
  components: Record<'backend' | 'maintenance', Record<Connectivity, React.FC<IConnectivityGuardItem>>>;
};
// export interface IConnectivityGuard extends IAppDataContext['health'] {}

// export const configuredConnectivityChecks = (context: IAppDataContext): IConnectivityGuard => {
//   return context?.health;
// };

export const ConnectivityGuard: React.FC<IConnectivityGuard> = (props) => {
  const {checks, components, children} = props;
  const {backend, maintenance} = checks || {};

  const conn = useConnectivity();

  const backendGuard = backend
    ? prop(components.backend, conn.backend.connectivity)
    : undefined;

  const backendProps: IConnectivityGuardItem = backend
    ? {...conn, ...backend} as any
    : undefined;

  const maintenanceGuard = maintenance
    ? prop(components.maintenance, conn.maintenance.connectivity)
    : undefined;

  if (maintenanceGuard) {
    const maintenanceProps: IConnectivityGuardItem = {...conn, ...maintenance} as any;
    const maintenanceEl = createElement(maintenanceGuard, maintenanceProps, children);

    return backendGuard
      ? createElement(backendGuard, backendProps, maintenanceEl)
      : maintenanceEl;
  } else if (backendGuard) {
    return createElement(backendGuard, backendProps, children);
  } else {
    return <>{children}</>;
  }
};
