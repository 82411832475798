import {AthleteCardGrid, IAthleteCardGrid} from '../athlete/athlete-card-grid.component';
import {AccountTileListing, IAccountTileListing} from './account-tile-listing.component';
import {IAuthUserAccount} from '../../index';
import React from 'react';
import {AthleteTileListing} from '../athlete/athlete-tile-listing.component';
import {ClubAthleteTileListing} from '../../../club/component/club-athlete-tile-listing.component';
import {ClubTrainerTileListing} from '../../../club/component/club-trainer-tile-listing.component';
import {IUiModalRouteOutletRoute, UiModalRouteOutlet} from '../../../ui/component/ui-modal-route.component';
import {RouteComponentProps} from 'react-router';
import {IRoute, useNavigator} from '../../../../core/routing';
import {isFalse, isFn, isNonEmptyStr, isStr, propIn} from '@bitsolve/fns';
import {UiPageContainer, UiPageSegment, UiTextTitle, useTranslator} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../../app/common/page/app-page-header.component';
import {ClubAthleteCardGrid} from '../../../club/component/club-athlete-card-grid.component';
import {ClubTrainerCardGrid} from '../../../club/component/club-trainer-card-grid.component';
import {useRevisionState} from '../../../ui/ui.hooks';
import {TrainerNoConnectionsInterstitial} from '../trainer/trainer-no-connections-interstitial.component';
import {AthleteOnboadingTooltips} from '../athlete/athlete-onboading-tooltips.component';

export interface IAccountManageRelationRouteSegment {
  type: 'athlete-card-grid'
    | 'athlete-tile-listing'
    | 'athlete-onboarding-tooltips'
    | 'club-athlete-card-grid'
    | 'club-athlete-tile-listing'
    | 'club-trainer-tile-listing'
    | 'club-trainer-card-grid'
    | 'trainer-tile-listing'
    | 'trainer-no-connections-interstitial',
  title?: string;
  data?: IAthleteCardGrid | IAccountTileListing | any; // @todo
  onAccountClick?: (account: IAuthUserAccount) => any;
  match: RouteComponentProps['match'];
  revision?: any;
}

export const AccountManageRelationRouteSegment: React.FC<IAccountManageRelationRouteSegment> = (props) => {
  const {type, data, onAccountClick, match, revision} = props;

  switch (type) {
    case 'athlete-card-grid':
      return <AthleteCardGrid {...data} />;
    case 'athlete-tile-listing':
      return <AthleteTileListing onAccountClick={onAccountClick}
                                 revision={revision}
                                 {...data} />;
    case 'athlete-onboarding-tooltips':
      return <AthleteOnboadingTooltips {...data} />;
    case 'club-athlete-card-grid':
      return <ClubAthleteCardGrid clubId={propIn(match, ['params', 'clubId'])}
                                  {...data} />;
    case 'club-athlete-tile-listing':
      return <ClubAthleteTileListing clubId={propIn(match, ['params', 'clubId'])}
                                     onAccountClick={onAccountClick}
                                     revision={revision}
                                     {...data} />;
    case 'club-trainer-tile-listing':
      return <ClubTrainerTileListing clubId={propIn(match, ['params', 'clubId'])}
                                     onAccountClick={onAccountClick}
                                     revision={revision}
                                     {...data} />;
    case 'club-trainer-card-grid':
      return <ClubTrainerCardGrid clubId={propIn(match, ['params', 'clubId'])}
                                  {...data} />;
    case 'trainer-tile-listing':
      return <AccountTileListing {...data} />;
    case 'trainer-no-connections-interstitial':
      return <TrainerNoConnectionsInterstitial {...data} />;
    default:
      return null;
  }
};

const AccountManageRelationRouteSegments: React.FC<{
  revision: any;
  match?: any;
  accountDetailsPath?: any;
  segments: IAccountManageRelationRouteSegment[];
  hideOverflow?: boolean
}> = (props) => {
  const {segments, revision, accountDetailsPath, match, hideOverflow = false} = props;
  const navigate = useNavigator();

  return <>
    {segments.map((segment, i) => <UiPageSegment key={`${revision}.${i}.${segment.type}`}
                                                 style={hideOverflow ? {overflow: 'hidden'} : undefined}>
      {segment.title && !isFalse(propIn(segment, ['data', 'titleOutside'])) && <UiTextTitle text={segment.title}
                                                                                            className={'mg-b-nm'}
                                                                                            headingLevel={2} />}
      <AccountManageRelationRouteSegment {...segment}
                                         revision={revision}
                                         match={match as any}
                                         onAccountClick={acc => {
                                           const path = isFn(accountDetailsPath)
                                             ? accountDetailsPath(acc)
                                             : accountDetailsPath as string;

                                           if (path) {
                                             navigate(path);
                                           }
                                         }} />
    </UiPageSegment>)}
  </>;
}


interface IPartialContext {
  navigate: Function,
  translate: Function
}

export type IUiModalRouteOutletRouteFactory = (options: IAccountManageRelationRoute & IPartialContext) => IUiModalRouteOutletRoute;
export type IRoutePathFactory = (match: RouteComponentProps['match']) => string | false | null | undefined;
export type IAccountRoutePathFactory = (account: IAuthUserAccount) => string | false | null | undefined;

export interface CreateAccountManageRelationRouteOptions {
  title?: string;
  ownPath?: string | IRoutePathFactory;
  segments: Omit<IAccountManageRelationRouteSegment, 'match'>[];
  modalRoutes?: Array<IUiModalRouteOutletRoute | IUiModalRouteOutletRouteFactory>;
  accountDetailsPath?: string | IAccountRoutePathFactory;
  hideOverflow?: boolean;
}

export type CreateAccountManageRelationRouteOptionsFactory = (context: IPartialContext & RouteComponentProps['match']) => CreateAccountManageRelationRouteOptions;

export interface IAccountManageRelationRoute extends IRoute {
}

export const createAccountManageRelationRoute = (
  options: CreateAccountManageRelationRouteOptions | CreateAccountManageRelationRouteOptionsFactory
): React.FC<IAccountManageRelationRoute> =>
  (props) => {
    const {match} = props;
    const navigate = useNavigator();
    const translate = useTranslator();
    const {revision, bumpRevision} = useRevisionState();

    const {title, modalRoutes, segments, ownPath, accountDetailsPath, hideOverflow} = isFn(options)
      ? options({...props.match, navigate, translate} as any)
      : options;

    const _ownPath = isStr(ownPath) ? ownPath : (isFn(ownPath) ? (ownPath as any)(props.match) : null);

    return <UiPageContainer fluid className={'app-coachz app-coachz__athletes'}>
      {isNonEmptyStr(title) && <AppPageHeader title={title} />}
      <AccountManageRelationRouteSegments key={`${revision}`}
                                          match={match}
                                          segments={segments as any}
                                          accountDetailsPath={accountDetailsPath}
                                          revision={revision}
                                          hideOverflow={hideOverflow} />
      {_ownPath && modalRoutes && <UiModalRouteOutlet ownPath={_ownPath}
                                                      routes={modalRoutes.map(route => isFn(route)
                                                        ? route({...props, navigate, translate})
                                                        : route)}
                                                      onUpdate={() => {
                                                        bumpRevision();
                                                      }} />}
    </UiPageContainer>;
  };


