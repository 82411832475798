import {Action, AnyAction, Dispatch, Reducer} from 'redux';
import {IStoreModule} from '@bitsolve/react-common';
import {Connectivity, IBackendHealth, IMaintenance, IMaintenanceFile} from './connectivity.model';
import {useSelector} from 'react-redux';
import {isNil, prop} from '@bitsolve/fns';
import {
  BACKEND_HEALTH_INTERVAL,
  BACKEND_HEALTH_THRESHOLD,
  findBackendHealthRequest,
  findMaintenanceStatusRequest,
  MAINTENANCE_INTERVAL,
  MAINTENANCE_THRESHOLD
} from './connectivity.api';
import {ThunkAction} from 'redux-thunk';
import {getAppGlobal} from '../../core/system';


export interface IConnectivityState {
  backend: IBackendHealth;
  maintenance: IMaintenance;
}


export interface IConnectivityHealthCheck {
  url: string;
  timeout?: number;
}

export interface IConnectivityGuardItem extends IConnectivityState, IConnectivityHealthCheck {
}

export enum ConnectivityAction {
  checkBackend = '@rooq.connectivity/check-backend',
  checkBackendBail = '@rooq.connectivity/check-backend-bail',
  checkBackendSuccess = '@rooq.connectivity/check-backend-success',
  checkBackendError = '@rooq.connectivity/check-backend-error',
  startBackendChecker = '@rooq.connectivity/start-backend-checker',
  stopBackendChecker = '@rooq.connectivity/stop-backend-checker',

  checkMaintenance = '@rooq.connectivity/check-maintenance',
  checkMaintenanceSuccess = '@rooq.connectivity/check-maintenance-success',
  checkMaintenanceError = '@rooq.connectivity/check-maintenance-error',
  startMaintenanceChecker = '@rooq.connectivity/start-maintenance-checker',
  stopMaintenanceChecker = '@rooq.connectivity/stop-maintenance-checker',
}

type IRegularAction = Action<ConnectivityAction> & { payload?: any; meta?: any; };
type IAction = IRegularAction | ThunkAction<any, IConnectivityStateSlice, any, any>;

const connectivityInitialState: IConnectivityState = {
  backend: {
    connectivity: Connectivity.UNKNOWN,
    busy: false,
    lastCheck: undefined,
    numChecks: 0,
  },
  maintenance: {
    connectivity: Connectivity.UNKNOWN,
    busy: false,
    lastCheck: undefined,
    numChecks: 0,
    notifications: []
  },
};

export const connectivityStoreKey = '@rooq/connectivity';

export type IConnectivityStateSlice = { [connectivityStoreKey]: IConnectivityState };

export const useConnectivity = (): IConnectivityState => useSelector(
  state => prop(state, connectivityStoreKey)
);


const _ivals: Map<number, string> = new Map<number, string>();

const _findIval = (id: string): number | null => {
  let x: number | null = null;
  _ivals.forEach((v, k) => {
    if (v === id) {
      x = k;
    }
  });
  // console.log('check lookup:', id, Array.from(_ivals.entries()));
  return x;
};
const _addIval = (tout: number, id: string, f: Function) => {
  // console.log('adding check:', id);
  const ival = setInterval(f, tout);
  _ivals.set(ival as any as number, id);
  return {ival, id};
};

const _clearIval = (id: string) => {
  // console.log('clearing check:', id);
  let del: number[] = [];
  _ivals.forEach((v, k) => {
    if (v === id) {
      del.push(k);
      _ivals.delete(k);
    }
  });
  return del;
};

export const checkBackend = (url: string, threshold = BACKEND_HEALTH_THRESHOLD): IAction => {
  return ((dispatch: Dispatch, getState: () => { [connectivityStoreKey]: IConnectivityState }) => {
    const s = getState()[connectivityStoreKey];
    const delta = s.backend.lastCheck
      ? Date.now() - s.backend.lastCheck
      : Infinity;

    if (s.backend.busy || delta <= threshold) {
      // console.log('backend check bail', s.backend.busy, delta);
      dispatch({
        type: ConnectivityAction.checkBackendBail,
        payload: {delta}
      });
    } else {
      // console.log('backend checking');
      dispatch({
        type: ConnectivityAction.checkBackend,
        payload: {
          request: findBackendHealthRequest()
        }
      });
    }
  }) as any;
};

export const startBackendChecker = (id: string, url: string, tout: number = BACKEND_HEALTH_INTERVAL): IAction => {
  const prev = _findIval(id);

  if (prev !== null) {
    return {
      type: ConnectivityAction.startBackendChecker,
      payload: {timer: prev, id, url}
    };
  } else {
    return ((dispatch: Dispatch<any>) => {
      const check = () => dispatch(checkBackend(url));
      const {ival} = _addIval(tout, id, check);

      dispatch({
        type: ConnectivityAction.startBackendChecker,
        payload: {timer: ival, id, url}
      });
      check();
    }) as any;
  }
};

export const stopBackendChecker = (id: string = 'backend'): AnyAction => {
  const prev = _findIval(id);

  if (id) {
    _clearIval(id).forEach(tid => clearInterval(tid));
  }

  return {
    type: ConnectivityAction.stopBackendChecker,
    payload: {timer: prev, id}
  };
}


export const checkMaintenance = (url: string, threshold = MAINTENANCE_THRESHOLD): IAction => {
  return (dispatch, getState) => {
    const s = getState()[connectivityStoreKey];
    const delta = s.maintenance.lastCheck
      ? Date.now() - s.maintenance.lastCheck
      : Infinity;

    if (s.maintenance.busy || delta <= threshold) {
      // console.log('maintenance check bail', s.maintenance.busy, delta);
      dispatch({
        type: ConnectivityAction.checkMaintenance,
        payload: {delta}
      });
    } else {
      // console.log('maintenance checking');
      dispatch({
        type: ConnectivityAction.checkMaintenance,
        payload: {
          request: findMaintenanceStatusRequest(url)
        }
      });
    }
  };
};


export const startMaintenanceChecker = (id: string, url: string, tout: number = MAINTENANCE_INTERVAL): IAction => {
  const prev = _findIval(id);

  if (prev !== null) {
    return {
      type: ConnectivityAction.startMaintenanceChecker,
      payload: {timer: prev, id}
    };
  } else {
    return ((dispatch: Dispatch<any>) => {
      const check = () => dispatch(checkMaintenance(url));
      const {ival} = _addIval(tout, id, check);

      dispatch({
        type: ConnectivityAction.startMaintenanceChecker,
        payload: {timer: ival, id, url}
      });
      check();
    }) as any;
  }
};

export const stopMaintenanceChecker = (id: string = 'maintenance'): AnyAction => {
  const prev = _findIval(id);
  _clearIval(id);
  return {
    type: ConnectivityAction.stopMaintenanceChecker,
    payload: {timer: prev, id}
  };
}


export const connectivityReducer: Reducer<IConnectivityState, AnyAction> = (
  state: IConnectivityState = connectivityInitialState,
  action: AnyAction
): IConnectivityState => {
  const {type, payload} = action;

  switch (type) {
    case ConnectivityAction.checkBackend:
      return {
        ...state,
        backend: {...state.backend, busy: true}
      };
    case ConnectivityAction.checkBackendBail:
      // bailed out due to unmet threshold (delta)
      return state;
    case ConnectivityAction.checkBackendSuccess:
      const resOk = payload.status
        ? payload.status.toString().substr(0, 1) === '2'
        : false;

      const g = getAppGlobal();
      if (g && resOk && state.backend.connectivity === Connectivity.UNREACHABLE) {
        // if we have an app global object, the current connectivity is UNREACHABLE,
        // and the check reponse is okay, we'll hard restart the app to trigger
        // config refetch; if all fails we hard reload the window, which could
        // potentially be somewhat of an issue (@fixme this is a bit whack)
        g.restart().catch(e => window.location.reload());
      }

      return {
        ...state,
        backend: {
          ...state.backend,
          numChecks: state.backend.numChecks + 1,
          lastCheck: Date.now(),
          busy: false,
          connectivity: resOk
            ? Connectivity.REACHABLE
            : Connectivity.UNREACHABLE,
        },
      };
    case ConnectivityAction.checkBackendError:
      return {
        ...state,
        backend: {
          ...state.backend,
          numChecks: state.backend.numChecks + 1,
          lastCheck: Date.now(),
          busy: false,
          connectivity: Connectivity.UNREACHABLE,
        },
      };

    case ConnectivityAction.checkMaintenance:
      return isNil(prop(payload, 'delta'))
        ? {
          ...state,
          maintenance: {...state.maintenance, busy: true}
        }
        : state;
    case ConnectivityAction.checkMaintenanceSuccess:
      const data = prop<any, IMaintenanceFile | null>(payload, 'data');

      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          numChecks: state.maintenance.numChecks + 1,
          lastCheck: Date.now(),
          busy: false,
          connectivity: Connectivity.REACHABLE,
          fileVersion: data?.fileVersion,
          notifications: data?.notifications
            ? [...data.notifications]
            : []
        },
      };
    case ConnectivityAction.checkMaintenanceError:
      return {
        ...state,
        maintenance: {
          ...state.maintenance,
          numChecks: state.maintenance.numChecks + 1,
          lastCheck: Date.now(),
          busy: false,
          connectivity: Connectivity.UNREACHABLE,
        },
      };
    default:
      return state;
  }
}

export const connectivityStoreModule: IStoreModule<IConnectivityState> = {
  key: connectivityStoreKey,
  reducer: connectivityReducer,
  initialState: connectivityInitialState,
};
