import {useQueryParam} from '../../core/util';
import {useConfirmation} from '../ui/component/ui-confirmation-modal-dialog.component';
import {toast, useTranslator} from '@bitsolve/react-common';
import {useShopStartTrialAndAssign} from './shop.api';
import {useAuthActions} from '../auth/auth.store';
import {ErrorHandler, SuccessHandler} from '../../core/api';
import {isNil} from '@bitsolve/fns';


export const SUBSCRIPTION_TOKEN_PARAM = 'subId';

export const useSubscriptionToken = () => useQueryParam(SUBSCRIPTION_TOKEN_PARAM);


export const useStartTrialPrompt = (
  onSuccess?: SuccessHandler,
  onError?: ErrorHandler,
) => {
  const {updateAccount} = useAuthActions();
  const startTrialAndAssign = useShopStartTrialAndAssign();
  const t = useTranslator();

  return useConfirmation({
    title: t('app.shop.action.start-trial.confirm.title'),
    messages: [t('app.shop.action.start-trial.confirm.message')],
    cancelButton: {
      label: t('app.shop.action.start-trial.confirm.action.cancel')
    },
    confirmButton: {
      label: t('app.shop.action.start-trial.confirm.action.confirm')
    },
    onConfirm: (data) => {
      const id = data?.data?.id;
      if (isNil(id)) {
        return;
      }
      startTrialAndAssign
        .send(data?.data?.id)
        .then(() => {
          toast.success(t('app.shop.action.start-trial.toast.success'));
          updateAccount && updateAccount();
          onSuccess && onSuccess(data);
        })
        .catch((e) => {
          toast.error(t('app.shop.action.start-trial.toast.error'));
          onError && onError(e);
        })
    }
  });
}
