import React from 'react';
import {UiModalDialog} from '../../ui/component/ui-modal-dialog.component';
import {
  toast,
  UiBox,
  UiButtonGroup,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexRow,
  UiForm,
  UiFormContextConsumer,
  UiFormField,
  uiModalDefaultDialogStyle,
  UiState,
  useModal
} from '@bitsolve/react-common';
import {ReportReason} from '../../report/report.model';
import {useReportContent} from '../../report/report.api';
import {useFindClubById} from '../club.api';
import {IClub} from '../club.model';
import {staticFeedbackControlProps} from '../../content/content.api';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';

export interface IClubReportModal {
  clubId: string;
  onClose: () => any;
}

const clubReportFields = [
  {
    label: 'Verein',
    name: 'club',
    fieldType: 'text',
  },
  {
    label: 'Grund',
    name: 'reason',
    fieldType: 'content-select',
    direction: UiDir.v,
    controlProps: staticFeedbackControlProps,
  },
  {
    label: 'Anmerkung(en)',
    name: 'message',
    fieldType: 'textarea',
    controlProps: {
      autoFocus: true,
    }
  },
];

const formToReport = (club: IClub, data: { reason: ReportReason; message: string; }) => ({
  reason: data.reason,
  message: data.message,
  context: {clubId: club.id},
});

export const ClubReportModal: React.FC<IClubReportModal> = (props) => {
  const {clubId, onClose} = props;

  const clubReq = useFindClubById(clubId, {initialFetch: true});
  const club = clubReq?.response?.data;
  const createReport = useReportContent();

  return <UiModalDialog className={'app-athlete-report-modal'}
                        header={{
                          title: 'Verein melden',
                          onClose
                        }}>
    {clubReq.busy
      ? <UiFlexRow className="pd-nm" jc={UiFlexAlign.c}>
        <AppLoadingSpinner />
      </UiFlexRow>
      : club && <UiBox style={{padding: '1.25rem'}}>
      <UiForm
        value={{
          club: club.name,
          reason: ReportReason.OTHER,
          message: '',
        }}
        interact={(vals) => createReport
          .send(formToReport(club, vals))
          .then(() => {
            onClose && onClose();
            toast.success('Verein gemeldet');
          })
          .catch(() => toast.error('Beim Melden dieses Vereins ist ein Problem aufgetreten'))}
        fields={clubReportFields}>
        <UiFormField name={'club'}
                     className={'txt-b'}
                     state={UiState.readonly}
                     direction={UiDir.v} />
        <UiFormField name={'reason'}
                     className={'mg-t-nm'}
                     direction={UiDir.v} />
        <UiFormField name={'message'}
                     className={'mg-t-nm'}
                     direction={UiDir.v} />
        <footer className={'mg-t-md'}>
          <UiFormContextConsumer>
            {context => <UiButtonGroup stretch
                                       direction={UiDir.h}
                                       actions={[
                                         onClose && {
                                           label: 'Abbrechen',
                                           color: UiColor.primary,
                                           interact: onClose
                                         },
                                         {
                                           label: 'Verein melden',
                                           className: 'txt-b',
                                           color: UiColor.accent,
                                           interact: context.submit
                                         },
                                       ]} />}
          </UiFormContextConsumer>
        </footer>
      </UiForm>
    </UiBox>}
  </UiModalDialog>;
};

export const useClubReportModal = (options: { clubId?: string; onClose?: Function; }) => {
  const {clubId, onClose} = options;

  return useModal({
    content: context => <ClubReportModal clubId={clubId}
                                         {...context.data}
                                         onClose={() => {
                                           context.close();
                                           onClose && onClose();
                                         }} />,
    closeTimeoutMS: 0,
    style: {
      ...uiModalDefaultDialogStyle,
      content: {
        ...uiModalDefaultDialogStyle.content,
        minWidth: '28rem',
        maxWidth: '32rem',
        backgroundColor: '#1A2A39',
        color: '#fff',
        filter: 'drop-shadow(0 0.5em 1.5em rgba(0,0,0,0.325))'
      }
    }
  });
};
