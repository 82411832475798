import React from 'react';
import {classNames, UiCustomizable} from '@bitsolve/react-common';

export interface IAppPageSectionInset extends UiCustomizable {
}

export const AppPageSectionInset: React.FC<IAppPageSectionInset> = (props) => {
  const {className, style} = props;

  return <section className={classNames('app-page-section-inset', className)}
                  style={style}>
    {props.children}
  </section>;
};
