import {API_PREFIX} from '../../core/constants';
import {
  IHttpRequest,
  IHttpRequestHookOptions,
  IPaginatedHttpRequestHookOptions,
  Page,
  useApiEndpoint,
  useHttpRequest,
  usePaginatedHttpRequest
} from '@bitsolve/react-common';
import {ITrainingPlan, ITrainingPlanCreate, ITrainingPlanPaginated, ITrainingPlanUpdate} from './training-plan.model';
import {ErrorHandler, paginatedOptions, SuccessHandler, useDeleteConfirmation} from '../../core/api';
import {AxiosError, AxiosResponse} from 'axios';
import {dissoc, update} from '@bitsolve/fns';
import {IAuthUserAccount} from '../auth';

const baseUrl = `${API_PREFIX}/v1/trainingPlan`;


export const findTrainingPlanRequest = (id: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${id}`
});


export const findMyTrainingPlansRequest = (page?: number, pageSize?: number): IHttpRequest => ({
  method: 'get',
  url: baseUrl,
  params: {
    page: page || 0,
    pageSize: pageSize || 10
  }
});

export const findMyTrainingPlanShares = (planId: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${planId}/shared`
});

export const findMyTrainingPlanSharesCandidates = (planId: string): IHttpRequest => ({
  method: 'get',
  url: `${baseUrl}/${planId}/candidates`
});

export const shareTrainingPlan = (planId: string, accountIds: string[]): IHttpRequest => ({
  method: 'post',
  url: `${baseUrl}/${planId}/share`,
  data: {accountIds}
});

const cleanupUnitsTempids = <T = ITrainingPlanUpdate | ITrainingPlanCreate>(data: T): T => {
  return update(data, 'trainingPlanUnits', (units: ITrainingPlanUpdate['trainingPlanUnits']) => units
    ? units.map(u => dissoc(u, 'id' as any))
    : []);
}

export const createTrainingPlanRequest = (data: ITrainingPlanCreate): IHttpRequest => ({
  method: 'post',
  url: baseUrl,
  data: cleanupUnitsTempids(data)
});

export const updateTrainingPlanRequest = (planId: string, data: ITrainingPlanUpdate): IHttpRequest => ({
  method: 'patch',
  url: `${baseUrl}/${planId}`,
  data: cleanupUnitsTempids(data)
});

export const deleteTrainingPlanRequest = (id: string): IHttpRequest => ({
  method: 'delete',
  url: `${baseUrl}/${id}`
});

export const useShareTrainingPlan = (onSuccess?: SuccessHandler, onError?: ErrorHandler) =>
  useApiEndpoint<{ planId: string; accountIds: string[]; }>(
    ({planId, accountIds}) => shareTrainingPlan(planId, accountIds),
    onSuccess,
    onError
  );

export const useMyTrainingPlanShares = (planId: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ accounts: IAuthUserAccount[]; }>(
    findMyTrainingPlanShares(planId),
    {initialFetch: true, ...opts}
  );

export const useMyTrainingPlanShareCandidates = (planId: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<{ accounts: IAuthUserAccount[]; }>(
    findMyTrainingPlanSharesCandidates(planId),
    {initialFetch: true, ...opts}
  );

export const useCreateTrainingPlan = <R = ITrainingPlan>(onSuccess?: (r: AxiosResponse<R>) => any, onError?: (r: AxiosError) => any) =>
  useApiEndpoint(
    createTrainingPlanRequest,
    onSuccess,
    onError
  );

export const useUpdateTrainingPlan = <R = ITrainingPlan>(id: string, onSuccess?: (r: AxiosResponse<R>) => any, onError?: (r: AxiosError) => any) =>
  useApiEndpoint(
    updateTrainingPlanRequest.bind(null, id),
    onSuccess,
    onError
  );

export const useDeleteTrainingPlan = <R = any>(id?: string, onSuccess?: (r: AxiosResponse<R>) => any, onError?: (r: AxiosError) => any) =>
  useApiEndpoint(
    id ? deleteTrainingPlanRequest.bind(null, id) : deleteTrainingPlanRequest,
    onSuccess,
    onError
  );

export const useDeleteTrainingPlanConfirmation = <R = any>(onSuccess?: (r: AxiosResponse<R>) => any, onError?: (r: AxiosError) => any) => {
  return useDeleteConfirmation({
    endpoint: useDeleteTrainingPlan(undefined, onSuccess, onError),
    title: 'app.training-plan.action.delete.confirm.title',
    message: 'app.training-plan.action.delete.confirm.message',
    cancelLabel: 'general.action.cancel',
    confirmLabel: 'general.action.confirm',
  });
};

export const useFindTrainingPlan = (id: string, opts?: IHttpRequestHookOptions) =>
  useHttpRequest<ITrainingPlan>(findTrainingPlanRequest(id), opts);

export const useFindMyTrainingPlans = (page?: number, pageSize?: number, opts?: IHttpRequestHookOptions & IPaginatedHttpRequestHookOptions) =>
  usePaginatedHttpRequest<Page<ITrainingPlanPaginated>>(
    findMyTrainingPlansRequest(page, pageSize),
    paginatedOptions(page, pageSize, opts)
  );

