import React, {useCallback} from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {props as selectProps} from '@bitsolve/fns';
import {IUiFormContext, toast, UiPageContainer, useTranslator} from '@bitsolve/react-common';
import {AppPageHeader} from '../../../common/page/app-page-header.component';
import {useCreateClub} from '../../../../module/club/club.api';
import {ClubForm} from '../../../../module/club/component/club-form.component';
import {IClub} from '../../../../module/club/club.model';
import {apiErrorToValidationError} from '../../../../core/api';

export interface ICoachingZoneCreateClubRoute extends IRoute {
}

export const CoachingZoneCreateClubRoute: React.FC<ICoachingZoneCreateClubRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();

  const createClub = useCreateClub(
    response => {
      toast.success(t('app.club.action.create.toast.success'));
      nav(`/portal/club/${response?.data?.id}/settings`);
    },
    () => {
      toast.error(t('app.club.action.create.toast.error'));
    }
  );

  const submitForm = useCallback(
    (form: IUiFormContext<Partial<IClub>>) => {
      const data = selectProps(form.value, [
        'name',
        'foundingYear',
        'memberCount',
        'email',
        'aboutUs',
        'address',
        'phoneNumber',
        'fightStyles',
        'openingHours',
        'logoPictureId',
        'bannerPictureId',
      ] as any);

      createClub.send(data as any)
        .then((r) => {
        })
        .catch((e) => form.setError(apiErrorToValidationError(e)));
    },
    [createClub]
  );

  return <UiPageContainer fluid className={'app-coachz app-coachz__manage-club'}>
    <AppPageHeader className={'pd-r-nm'}
                   title={t('app.club.action.create.label')}
                   backAction={{
                     path: '/portal/trainer/clubs',
                     exact: true
                   }} />
    <ClubForm id={'create-club'}
              mode={'create'}
              perspective={'trainer'}
              value={{name: ''}}
              interact={(_, form) => submitForm(form)}
              changeActions={undefined} />
  </UiPageContainer>;
};
