import {IAuthUserAccount} from '../../../module/auth';
import React, {useMemo} from 'react';
import {AccountPicture} from '../../../module/auth/component/account/account-picture.component';
import {IUiIcon, UiCustomizable, UiIcon} from '@bitsolve/react-common';
import {isStr} from '@bitsolve/fns';
import {IClub} from '../../../module/club/club.model';


export const AvatarIcon: React.FC<{ size: number | string; } & Partial<IUiIcon> & UiCustomizable> = (props) => {
  const {size, name, iconSet = 'regular', className, style} = props;
  const _size = isStr(size) ? size : `${size}rem`;

  return <UiIcon name={name as any}
                 iconSet={iconSet}
                 className={className}
                 style={{
                   fontSize: _size,
                   borderRadius: '50%',
                   background: 'hsla(208,24%,21%, 0.5)',
                   ...style
                 }} />;
};


export interface IAccountAvatar extends UiCustomizable {
  account?: Partial<IAuthUserAccount>;
}

export const AccountAvatar: React.FC<{ size?: number | string; } & IAccountAvatar> = (props) => {
  const {account, size = 5, className, style} = props;
  const pictureId = useMemo(() => account?.profilePictureId, [account]);

  return pictureId
    ? <AccountPicture key={account?.profilePictureId}
                      className={className}
                      style={style}
                      size={size}
                      mediaId={pictureId} />
    : <AvatarIcon name={'userProfile'}
                  className={className}
                  style={style}
                  size={size} />;
};


export interface IClubAvatar {
  club?: Partial<IClub>;
}

export const ClubAvatar: React.FC<{ size?: number | string; } & IClubAvatar> = (props) => {
  const {club, size = 5} = props;
  const pictureId = useMemo(() => club?.logoPictureId || undefined, [club]);

  return pictureId
    ? <AccountPicture key={pictureId}
                      size={size}
                      mediaId={pictureId} />
    : <AvatarIcon name={'clubProfile'}
                  size={size} />;
};
