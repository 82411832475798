import React from 'react';
import {
  classNames,
  IUiButton,
  IUiTextTitle,
  UiButtonGroup,
  UiCustomizable,
  UiFlexAlign,
  UiFlexRow,
  UiPanel,
  UiTextTitle
} from '@bitsolve/react-common';
import {AppLoadingSpinner} from '../misc/app-loading-spinner.component';

export interface IAppPageSectionPanel extends UiCustomizable {
  title?: IUiTextTitle & { children?: any; };
  busy?: boolean;
  actions?: IUiButton[];
  rounded?: boolean;
}

export const AppPageSectionPanel: React.FC<IAppPageSectionPanel> = (props) => {
  const {title, actions, busy = false, rounded = true, className, style} = props;

  return <UiPanel
    className={classNames('app-page-section-panel', rounded && 'app-page-section-panel--rounded', className)}
    style={style}>
    {(title || actions) && <UiFlexRow ai={UiFlexAlign.c}
                                      className={'app-page-section-panel__title'}>
      {title && <UiTextTitle {...title}
                             className={classNames('f-1', title.className)} />}
      <AppLoadingSpinner size={'sm'}
                         style={{
                           animationPlayState: busy ? undefined : 'paused',
                           transition: 'opacity 0.125s ease, transform 0.125s ease',
                           opacity: busy ? 1 : 0,
                           transform: `scale(${busy ? 1 : 0.1})`,
                           transformOrigin: 'center'
                         }} />
      {actions && <UiButtonGroup actions={actions} />}
    </UiFlexRow>}
    {props.children}
  </UiPanel>;
};
