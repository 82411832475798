import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {
  IHttpClient,
  IHttpRequest,
  IUiButton,
  IUiModalRoute,
  UiCustomizable,
  UiFlexAlign,
  UiFlexCol,
  UiInteractive,
  UiTextLine,
  useDebounce,
  useHttpClient,
  useTranslator
} from '@bitsolve/react-common';
import {
  UiModalDialog,
  UiModalDialogHeader,
  UiModalDialogSearch
} from '../../../module/ui/component/ui-modal-dialog.component';
import {AppPageSectionPanel} from '../page/app-page-section-panel.component';
import {assoc, dissoc, keys, prop, propIn} from '@bitsolve/fns';
import * as axios from 'axios';

export type IAppSearchCallback = () => void;

export type IAppSearchResultAction<T = any> =
  Omit<IUiButton, 'interact'>
  & UiInteractive<IAppSearchResult<T> & { reload: IAppSearchCallback; }>;

export interface IAppSearchModal<T = any> extends UiCustomizable, Partial<IUiModalRoute> {
  resultComponent: any;
  baseRequest: Pick<IHttpRequest, 'url'> & Partial<Omit<IHttpRequest, 'url'>>;
  initialTerm?: string;
  termParamName?: string;
  debounceTime?: number;
  namespace?: string;
  onResultClick?: (result: IAppSearchResult<T>) => any;
  onClose?: () => any;
  emptyActions?: IUiButton[];
  resultActions?: IAppSearchResultAction<T>[];
}


interface IAppSearchState {
  term: string;
  busy: boolean;
  results: Record<string, {
    busy: boolean;
    data?: any[];
    error?: any;
    firstLoad?: number;
    lastLoad?: number;
  }>;
}

export interface IAppSearchResult<T = any> {
  index: number;
  data: T;
}

export interface IAppSearchResultProps<T = any> extends IAppSearchResult<T> {
  actions?: IAppSearchResultAction[];
  reload: IAppSearchCallback;
  onResultClick: (result: IAppSearchResult<T>) => any;
  onUpdatePropagation: IAppSearchCallback;
}

enum AppSearchAction {
  setTerm = 'setTerm',
  loadData = 'loadData',
  loadDataSuccess = 'loadDataSuccess',
  loadDataError = 'loadDataError',
}


const setTerm = (term: string) => ({type: AppSearchAction.setTerm, payload: {term}});

const loadData = (dispatch: React.Dispatch<IAppSearchAction>, props: IAppSearchModal, http: IHttpClient, fetcher: React.MutableRefObject<any>) => (term: string) => {
  if (fetcher.current) {
    console.log('cancelling');
    fetcher.current.cancel();
  }

  // @ts-ignore
  const source = axios.CancelToken.source();
  const {termParamName = 'search', baseRequest} = props;
  const request = {
    method: 'get',
    ...baseRequest,
    params: term.trim() === ''
      ? dissoc(baseRequest.params, termParamName)
      : {...baseRequest.params, [termParamName]: term.trim()},
    cancelToken: source.token
  };

  try {
    http.send(request as any)
      .then((response) => dispatch({
        type: AppSearchAction.loadDataSuccess,
        payload: assoc(response, 'via', term)
      }))
      .catch((error) => {
        // @ts-ignore
        if (axios.isCancel(error)) {
          console.log('cancelled');
        } else {
          dispatch({
            type: AppSearchAction.loadDataError,
            payload: assoc(error, 'via', term)
          });
        }
      })
      .finally(() => {
        if (fetcher.current === source) {
          fetcher.current = undefined;
        }
      });

    fetcher.current = source;
  } catch (e) {
    return undefined;
  }

  return {type: AppSearchAction.loadData, payload: {request, via: term}};
};

const useSearchActions = (dispatch: React.Dispatch<IAppSearchAction>, props: IAppSearchModal) => {
  // const {} = props;
  const http = useHttpClient();
  const fetcher = useRef();
  const _loadData = loadData(dispatch, props, http, fetcher);

  return useMemo(
    () => ({
      setTerm: (term: string) => {
        if (term.length > 2) {
          const action = _loadData(term);
          action && dispatch(action);
          dispatch(setTerm(term));
        }
      },
      loadData: (term: string) => {
        const action = _loadData(term);
        action && dispatch(action);
      },
    }),
    [dispatch, _loadData]
  );
};

interface IAppSearchAction<T = any> {
  type: AppSearchAction;
  payload?: T;
}

const numBusy = (state: IAppSearchState) => {
  return keys(state.results)
    .filter(k => state.results[k]?.busy)
    .length;
};

const searchReducer = (state: IAppSearchState, action: IAppSearchAction): IAppSearchState => {
  const {type, payload} = action;
  const nb = numBusy(state);

  // console.log('action', action.type, action.payload);

  switch (type) {
    case AppSearchAction.setTerm: {
      const term = payload?.term?.trimStart();
      return term !== state.term
        ? {...state, term}
        : state;
    }
    case AppSearchAction.loadData: {
      return {
        ...state,
        results: {
          ...state.results,
          [payload.via]: {
            ...state.results[payload.via],
            busy: true,
          }
        },
        busy: true
      };
    }
    case AppSearchAction.loadDataSuccess: {
      return {
        ...state,
        busy: (nb - 1) > 0,
        results: {
          ...state.results,
          [payload.via]: {
            ...state.results[payload.via],
            busy: false,
            data: payload?.data?.elements || []
          }
        }
      };
    }
    case AppSearchAction.loadDataError: {
      return {
        ...state,
        busy: (nb - 1) > 0,
        results: {
          ...state.results,
          [payload.via]: {
            ...state.results[payload.via],
            busy: false,
            error: payload
          }
        }
      };
    }
    default:
      return state;
  }

};

const useSearchTranslator = (namespace?: string) => {
  return useTranslator({namespace: namespace || 'general.search.modal'});
};


const initialSearchState = {
  term: '',
  results: {},
  busy: false,
};

const createInitialState = (props: IAppSearchModal): IAppSearchState => ({
  ...initialSearchState,
  term: props?.initialTerm || initialSearchState.term
} as any as IAppSearchState);


const AppSearchControl: React.FC<{ onChange: (term: string) => any; currentTerm: string; } & Pick<IAppSearchModal, 'debounceTime' | 'initialTerm' | 'namespace'>> = (props) => {
  const {onChange, initialTerm, currentTerm, namespace, debounceTime} = props;
  const t = useSearchTranslator(namespace);
  const [term, setTerm] = useState(initialTerm || '');
  const _term = useDebounce(term, debounceTime || 250);

  useEffect(() => {
    if (currentTerm !== _term) {
      // console.log('propagate', currentTerm, '->', _term)
      onChange(_term);
    }
  }, [_term, currentTerm, onChange]);

  return <UiModalDialogSearch value={term}
                              onChange={x => setTerm(x.trimStart())}
                              placeholder={t('placeholder')} />;
};

const resultColStyle = {minHeight: '0', overflow: 'auto', flex: 1, maxHeight: '100%'};

export const AppSearchModal: React.FC<IAppSearchModal> = (props) => {
  const {
    onClose,
    resultComponent,
    resultActions,
    namespace,
    initialTerm,
    onResultClick,
    onUpdatePropagation,
    children
  } = props;
  const t = useSearchTranslator(namespace);
  const [state, disp] = useReducer(searchReducer, props, createInitialState);

  const search = useSearchActions(disp, props);
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      // search.loadData(initialTerm || '');
      mounted.current = true;
    }
  }, [search, mounted, initialTerm]);

  const term = state.term;
  const reload = useCallback(() => {
    search.loadData(term);
  }, [term, search]);

  const result = propIn(state, ['results', state.term]);
  const busy = prop(result, 'busy');
  const data = prop(result, 'data');

  const resultComponents = useMemo(
    () => data?.map((data: any, index: number) =>
      React.createElement<IAppSearchResult & {
        onResultClick: (res: IAppSearchResult) => any;
        onUpdatePropagation: IAppSearchCallback;
        reload: IAppSearchCallback;
        actions?: IAppSearchResultAction[];
      }>(
        resultComponent,
        {
          data,
          index,
          actions: resultActions,
          reload,
          onResultClick,
          onUpdatePropagation,
          key: data?.id
            ? `${index}.${data.id}`
            : `${index}`
        } as any
      )),
    [data, resultComponent, resultActions, reload, onResultClick, onUpdatePropagation]
  );

  return <UiModalDialog className={'app-search-modal'}>
    <UiModalDialogHeader title={t('title')} onClose={onClose} />
    <AppSearchControl {...props}
                      currentTerm={state.term}
                      onChange={search.setTerm} />
    <AppPageSectionPanel rounded={false}
                         className={undefined}
                         busy={busy}
                         style={{overflow: 'hidden', flex: 1}}
                         title={{text: t('results.title')}}>
      {data && data?.length
        ? <UiFlexCol style={resultColStyle}>
          {resultComponents}
        </UiFlexCol>
        : <UiFlexCol className={'f-1 txt-nm ui-fx ui-fx__pane-fade-in'}
                     style={{...resultColStyle, color: '#94ADC1'}}
                     ai={UiFlexAlign.c}
                     jc={UiFlexAlign.c}>
          {busy
            ? <UiTextLine text={t('results.loading')} className={'txt-sm'} />
            : <UiTextLine text={t('results.empty')} className={'txt-sm'} />}
        </UiFlexCol>}
    </AppPageSectionPanel>
    {children}
  </UiModalDialog>;
};
