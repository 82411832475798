import {ITrainingPlanPaginated} from '../training-plan.model';
import {
  classNames,
  toast,
  UiBox,
  UiButtonGroup,
  UiButtonVariation,
  UiCustomizable,
  UiFlexCol,
  UiState,
  UiTextLine,
  UiTextTitle,
  useTranslator
} from '@bitsolve/react-common';
import React, {useMemo} from 'react';
import {useNavigator} from '../../../core/routing';
import {dissoc, isNil, isNonEmptyStr, isPos} from '@bitsolve/fns';
import {
  AnalysisTrainingSessionBreakdown,
  IAnalysisDisciplineBreakdownItem
} from '../../analysis/component/analysis-training-session-breakdown.component';
import {objByDisciplinePriority, Rooq} from '../../../core/domain.model';
import {useCreateTrainingPlan, useFindTrainingPlan} from '../training-plan.api';
import {AppSpinnerPane} from '../../../app/common/misc/app-spinner-pane.component';
import {useFormattedDuration} from '../../../core/util';

export interface ITrainingPlanListingItem extends UiCustomizable {
  plan: ITrainingPlanPaginated;
  index?: number;
  onUpdatePropagation?: Function;
}

const useTrainingPlanBreakdownText = (plan: ITrainingPlanPaginated) => {
  const t = useTranslator();
  const duration = useFormattedDuration(plan.totalDuration, 'medium');
  const roundCount = plan.totalRoundCount;
  const unitCount = plan?.trainingPlanUnits?.length || 0;

  return useMemo(
    () => t('app.training-plan.breakdown.short', {roundCount, duration, unitCount}),
    [t, duration, roundCount, unitCount]
  );
};

const unitDuration = (unit: Rooq.TrainingPlanUnit): number => {
  const {
    numberOfRounds = 0,
    roundLengthSeconds = 0,
    pauseLengthSeconds = 0,
    coolDownSeconds = 0
  } = unit;

  return (numberOfRounds * (roundLengthSeconds + pauseLengthSeconds)) + coolDownSeconds;
};

const unitDurationNoPause = (unit: Rooq.TrainingPlanUnit): number => {
  const {
    numberOfRounds = 0,
    roundLengthSeconds = 0,
    coolDownSeconds = 0
  } = unit;

  return (numberOfRounds * roundLengthSeconds) + coolDownSeconds;
};


// const unitPauseDuration = (unit: Rooq.TrainingPlanUnit): number => {
//   const {
//     numberOfRounds = 0,
//     pauseLengthSeconds = 0,
//     coolDownSeconds = 0
//   } = unit;
//
//   return (numberOfRounds * pauseLengthSeconds) + coolDownSeconds;
// };

const sumDurations = (durationObjs: Array<{ duration: number } & any>): number => {
  return durationObjs.reduce((r, o) => r + o.duration, 0);
};

const sessionDisciplineBreakdown = (
  totalDuration: number,
  discipline: Rooq.DisciplineV2 | Rooq.Discipline | string,
  items: IAnalysisDisciplineBreakdownItem[]
): IAnalysisDisciplineBreakdownItem => {
  const ditems = items.filter(i => i.discipline === discipline);
  const ddur = sumDurations(ditems);

  return {discipline: discipline as any, duration: (1 / totalDuration) * ddur};
};

const sessionBreakdownFromPlan = (planlike: { trainingPlanUnits?: Rooq.TrainingPlanUnit[] }): IAnalysisDisciplineBreakdownItem[] => {
  const units = planlike.trainingPlanUnits;
  if (!units || isNil(units)) return [];

  const totalDuration = units.reduce((r, u) => r + unitDuration(u), 0);
  const items = units.map(unit => ({
    discipline: unit.discipline as Rooq.DisciplineV2,
    duration: unitDurationNoPause(unit)
  }));

  const breakdown = [
    Rooq.DisciplineV2.ROPE_SKIPPING,
    Rooq.DisciplineV2.PARTNER,
    Rooq.DisciplineV2.EQUIPMENT,
    Rooq.DisciplineV2.SHADOW,
    Rooq.Discipline.CUSTOM as any,
  ]
    .map((discipline) => sessionDisciplineBreakdown(totalDuration, discipline, items));

  return breakdown.sort(objByDisciplinePriority);
};

const TrainingPlanBreakdown: React.FC<{ planId: string; }> = (props) => {
  const {planId} = props;

  const planReq = useFindTrainingPlan(planId, {initialFetch: true});
  const plan = planReq?.response?.data;
  const sessionBreakdown = useMemo(() => plan && sessionBreakdownFromPlan(plan), [plan]);

  return planReq.busy
    ? <AppSpinnerPane style={{minWidth: '6rem', height: '100%'}} />
    : <>
      {sessionBreakdown && <AnalysisTrainingSessionBreakdown className={'ui-fx ui-fx__pane-fade-in'}
                                                             items={sessionBreakdown} />}
    </>;
};

export const TrainingPlanListingItem: React.FC<ITrainingPlanListingItem> = (props) => {
  const {plan, onUpdatePropagation, className, style} = props;
  const createPlan = useCreateTrainingPlan();
  const breakdown = useTrainingPlanBreakdownText(plan);
  const planReq = useFindTrainingPlan(plan.id, {initialFetch: true});
  const nav = useNavigator();
  const t = useTranslator();

  const totalShares = plan.totalSharedCount;

  return <UiBox className={classNames('app-training-plan__listing__item', className)}
                style={style}
                key={plan.id}>
    <UiFlexCol tag={'header'}
               className={'app-training-plan__listing__item__header'}>
      <UiTextTitle text={isNonEmptyStr(plan.name) ? plan.name : '–'}
                   className={'txt-nm mg-b-xs'} />
      {isNonEmptyStr(breakdown) && <UiTextLine className={'txt-sm txt-b txt-c-defd'}
                                               text={breakdown} />}
    </UiFlexCol>
    <UiBox className={'app-training-plan__listing__item__breakdown'}>
      {plan && <TrainingPlanBreakdown planId={plan.id} />}
    </UiBox>
    <UiBox className={'app-training-plan__listing__item__actions'}>
      <UiButtonGroup
        variation={UiButtonVariation.ghost}
        className={'icon-scale'}
        actions={[
          totalShares && isPos(totalShares)
            ? {
              icon: 'userProfileOutlines',
              iconSet: 'regular',
              className: 'ui-button--badged',
              interact: () => nav(`/portal/training-plan/shared-with/${plan.id}`),
              label: `${totalShares}`
            }
            : {
              icon: 'edit',
              interact: () => nav(`/portal/training-plan/details/${plan.id}`),
            },
          {
            icon: 'share',
            state: plan.exampleSession
              ? UiState.disabled
              : UiState.active,
            interact: () => nav(`/portal/training-plan/share/${plan.id}`),
          },
          {
            icon: 'duplicate',
            state: plan.exampleSession || planReq.busy || isNil(planReq?.response?.data)
              ? UiState.disabled
              : UiState.active,
            interact: () => {
              try {
                if (!planReq.response) {
                  return;
                }

                const plan = planReq.response.data;
                const copy = JSON.parse(JSON.stringify(plan));

                const duplicate = {
                  name: t('app.training-plan.action.duplicate.name-template', {originalName: copy.name}),
                  description: copy.description,
                  trainingPlanUnits: copy.trainingPlanUnits?.map((u: any) => dissoc(u, 'id')),
                };

                createPlan.send(duplicate as any)
                  .then(() => {
                    onUpdatePropagation && onUpdatePropagation();
                    toast.success(t('app.training-plan.action.duplicate.toast.success'));
                  })
                  .catch((err) => {
                    console.error(err);
                    toast.error(t('app.training-plan.action.duplicate.toast.error'));
                  });
              } catch (e) {
                console.error(e);
              }
            }
          },
        ]} />
    </UiBox>
  </UiBox>;
};
