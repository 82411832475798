import React from 'react';
import {
  IUiModalRoute,
  toast,
  trapEvent,
  UiBox,
  UiButton,
  UiColor,
  UiFlexAlign,
  UiFlexCol,
  UiFlexRow,
  UiIcon,
  UiState,
  UiTextBlock,
  UiTextLine,
  useTranslator
} from '@bitsolve/react-common';
import {IRoute} from '../../../core/routing';
import {isNotNil, propIn} from '@bitsolve/fns';
import {UiModalDialog, UiModalDialogHeader} from '../../ui/component/ui-modal-dialog.component';
import {AccountListItem} from '../../auth/component/account/account-list-item.component';
import {useSelectionState} from '../../ui/ui.util';
import {useMyTrainingPlanShareCandidates, useShareTrainingPlan} from '../training-plan.api';
import {AppLoadingSpinner} from '../../../app/common/misc/app-loading-spinner.component';
import {useAuthAccount} from '../../auth/auth.store';

export interface ITrainingPlanShareModal extends IUiModalRoute, IRoute {
  onRequestClose?: Function;
}

export const TrainingPlanShareModal: React.FC<ITrainingPlanShareModal> = (props) => {
  const {match, onRequestClose, onUpdatePropagation} = props;
  const t = useTranslator();
  const acc = useAuthAccount();
  const sel = useSelectionState<string>([]);
  const planId = propIn(match as any, ['params', 'planId']);
  const candsReq = useMyTrainingPlanShareCandidates(planId);
  const sharePlan = useShareTrainingPlan(
    () => {
      toast.success(t('app.training-plan.share.action.share.toast.success'));
      onUpdatePropagation && onUpdatePropagation();
      onRequestClose && onRequestClose();
    },
    (err) => {
      console.error(err);
      toast.error(t('app.training-plan.share.action.share.toast.error'));
      sel.reset();
    },
  );
  const candidates = candsReq?.response?.data?.accounts;


  return <UiModalDialog style={{minHeight: 0, maxHeight: '100%', overflow: 'hidden', flex: 1}}>
    <UiModalDialogHeader title={t('app.training-plan.share.title')}
                         onClose={() => onRequestClose && onRequestClose()} />
    <UiBox className="f-1 d-f fd-v"
           style={{
             minHeight: '100%',
             maxHeight: '100%',
             overflowY: 'auto'
           }}>
      {candsReq?.busy && <UiFlexRow ai={UiFlexAlign.c}
                                    jc={UiFlexAlign.c}>
        <AppLoadingSpinner />
      </UiFlexRow>}
      {(!!acc?.ownsSubscription)
        ? <>
          {(candidates?.length || 0) > 0
            ? candidates?.map((cand, index) => {
              const isSelected = sel.selected.has(cand.id);

              return <UiFlexRow key={index}
                                className="app-search-result app-search-result--account app-search-result--athlete"
                                style={{animationDelay: `${(index + 1) * 75}ms`}}
                                onClick={ev => {
                                  trapEvent()(ev);
                                  if (isSelected) {
                                    sel.deselect(cand.id);
                                  } else {
                                    sel.select(cand.id);
                                  }
                                }}>
                <AccountListItem
                  account={cand}
                  actions={[]} />
                <UiFlexCol className="pd-v-sm pd-h-nm"
                           ai={UiFlexAlign.c}
                           jc={UiFlexAlign.c}>
                  <UiIcon key={`${index}.${isSelected ? 'sel' : 'unsel'}`}
                          iconSet={isSelected ? 'solid' : 'regular'}
                          name={'radioControlOn'}
                          style={{
                            fontSize: '1.25rem',
                            color: isSelected ? undefined : 'var(--color-text--default-dimmer)',
                            opacity: isSelected ? 1 : 0.5
                          }} />
                </UiFlexCol>
              </UiFlexRow>;
            })
            : (!candsReq?.busy && isNotNil(candsReq?.lastFetchTime) && <UiFlexCol style={{height: '100%'}}
                                                                                  className={'f-1 txt-nm ui-fx ui-fx__pane-fade-in'}
                                                                                  ai={UiFlexAlign.c}
                                                                                  jc={UiFlexAlign.c}>
              <UiTextLine className={'txt-sm txt-ac txt-c-defd'}
                          text={t('general.search.empty')} />
            </UiFlexCol>)}
        </>
        : <UiFlexCol style={{height: '100%'}}
                     className={'f-1 ui-fx ui-fx__pane-fade-in'}
                     ai={UiFlexAlign.c}
                     jc={UiFlexAlign.c}>
          <UiTextBlock className={'txt-sm txt-ac txt-c-defd'}
                       text={t('app.training-plan.share.action.share.error.no-subscription')} />
        </UiFlexCol>}
    </UiBox>
    <UiFlexRow className="pd-sm">
      <UiButton stretch
                className={'txt-b'}
                color={UiColor.accent}
                state={sel.selected.size > 0 ? UiState.active : UiState.disabled}
                label={t('app.training-plan.share.action.share.label', {numAthletes: sel.selected.size})}
                interact={(e) => {
                  trapEvent()(e);

                  sharePlan
                    .send({planId, accountIds: Array.from(sel.selected)})
                    .then();
                }} />
    </UiFlexRow>
  </UiModalDialog>;
};
