import {Action, AnyAction, Dispatch} from 'redux';
import {compareNotifications, INotification} from './notification.model';
import {assoc, prop, propIn, update} from '@bitsolve/fns';
import {useSelector} from 'react-redux';
import {IStoreModule} from '@bitsolve/react-common';
import {deleteNotificationRequest, findNotificationsRequest, updateNotificationRequest} from './notification.api';

export interface INotificationsState {
  items: INotification[];
}

export enum NotificationAction {
  push = '@rooq.notification/push',
  remove = '@rooq.notification/remove',
  removeSuccess = '@rooq.notification/remove-success',
  removeError = '@rooq.notification/remove-error',
  fetch = '@rooq.notification/fetch',
  fetchSuccess = '@rooq.notification/fetch-success',
  fetchError = '@rooq.notification/fetch-error',
  removeAll = '@rooq.notification/remove-all',
  replaceAll = '@rooq.notification/replace-all',
  markAsRead = '@rooq.notification/mark-read',
  markAsReadSuccess = '@rooq.notification/mark-read-success',
  markAsReadError = '@rooq.notification/mark-read-error',
  markAllAsRead = '@rooq.notification/mark-all-read',
  error = '@rooq.notification/error',
}

export const notificationStoreKey = '@rooq/notification';

// const notificationError = (error: any, originatingAction?: AnyAction) =>
//   ({type: NotificationAction.error, payload: {error}, meta: {originatingAction}});

// const selectNotifications = (state: any) => propIn<INotification[]>(state, [notificationStoreKey, 'items'], []);
// const selectNotificationIds = (state: any) => selectNotifications(state).map(n => n.id);

// export const pushNotification = (notification: INotification) => ({
//   type: NotificationAction.push,
//   payload: {notification},
//   meta: {
//     receivedAt: Date.now()
//   }
// });

export const markNotificationAsRead = (id: string) =>
  (dispatch: any, getState: any) => {
    const request = updateNotificationRequest(id, {seen: true});
    dispatch({type: NotificationAction.markAsRead, payload: {id, request}})
  };
// (dispatch: any, getState: any) => getFirebaseToken()
//   .then(token => NotificationApi
//     .withAccessToken(token)
//     .markNotificationAsRead(id)
//     .then(() => dispatch({
//       type: NotificationAction.markAsRead,
//       payload: {id}
//     }))
//     .catch((err) => dispatch(notificationError(err))))
//   .catch((err) => console.error(err));

export const removeNotification = (id: string) =>
  (dispatch: any, _: any) => {
    const request = deleteNotificationRequest(id);
    dispatch({type: NotificationAction.remove, payload: {id, request}})
  };
//   .then(token => NotificationApi
//     .withAccessToken(token)
//     .hideNotification(id)
//     .then(() => dispatch({
//       type: NotificationAction.remove,
//       payload: {id}
//     }))
//     .catch((err) => dispatch(notificationError(err))))
//   .catch(err => console.error(err));

export const removeAllNotifications = () => null
// (dispatch, getState) => {
//   const notifications = selectNotificationIds(getState());
//
//   notifications.forEach(id => dispatch(removeNotification(id)));
//
//   dispatch({
//     type: NotificationAction.removeAll,
//     payload: {notifications}
//   });
// };

export const markAllNotificationsAsRead = () => null
// (dispatch, getState) => {
//   const notifications = selectNotificationIds(getState());
//
//   notifications.forEach(id => dispatch(markNotificationAsRead(id)));
//
//   dispatch({
//     type: NotificationAction.removeAll,
//     payload: {notifications}
//   });
// };

export const fetchNotifications = () =>
  (dispatch: Dispatch<any>, _: Function) => {
    const request = findNotificationsRequest(0, 100);
    dispatch({type: NotificationAction.fetch, payload: {request}});
  };
//   .then(token => NotificationApi.withAccessToken(token).fetchNotifications())
//   .then(result => dispatch(replaceNotifications(propIn(result, ['data', 'elements'], []))))
//   .catch(err => console.error(err));


export const replaceNotifications = (currentNotifications: INotification[]) => null;
// (dispatch: Dispatch<any>, getState: Function) => {
//   const existingNotifications = selectNotifications(getState());
//
//   if (hasNewNotifications(existingNotifications, currentNotifications)) {
//     const n = dispatch(addToast({
//       status: 'info',
//       dismissAfter: 5000,
//       closeButton: false,
//       dismissible: false,
//       title: 'Neue Benachrichtigung',
//       buttons: [
//         {
//           name: 'ok', primary: true, onClick: () => dispatch(removeToast(n))
//         }
//       ]
//     })) as any;
//   }
//
//   dispatch({
//     type: NotificationAction.replaceAll,
//     payload: {notifications: currentNotifications}
//   })
// };

export const notificationInitialState = {
  items: []
};


export const useNotifications = (): INotification[] => useSelector(
  state => propIn(state, [notificationStoreKey, 'items'], [])
);

export const useUnreadNotificationsCount = () => {
  const notifications = useNotifications();

  return notifications
    .filter((item: INotification) => !item.seen)
    .length
};


// const hasNewNotifications = (old: INotification[], cur: INotification[]): boolean => {
//   const existingIds = new Set<string>(old.map(n => prop(n, 'id')).filter(isStr));
//
//   return cur.reduce(
//     (res: boolean, n) => res || !existingIds.has(n.id),
//     false
//   );
// };

export const notificationReducer = (state: INotificationsState = notificationInitialState,
                                    action?: Action & AnyAction): INotificationsState => {
  if (!action) {
    return state;
  }

  const {type, payload, meta} = action;

  switch (type) {
    case NotificationAction.fetchSuccess: {
      const {data} = payload;
      const items = (data?.elements || []).sort(compareNotifications);
      return assoc(state, 'items', items);
    }

    case NotificationAction.push:
      return update(state, 'items', (items: any[]) =>
        ([...items, prop(payload, 'notification')]
          .sort(compareNotifications)));

    case NotificationAction.removeSuccess: {
      const removedId = propIn(meta, ['previousAction', 'payload', 'id']);
      return update(state, 'items', (items: any[]) => items
        .filter(item => item.id !== removedId)
        .sort(compareNotifications));
    }

    case NotificationAction.markAsReadSuccess: {
      const {data} = payload;
      return update(state, 'items', (items: any[]) => items
        .map(item => {
          if (item.id === data?.id) {
            return data;
          } else {
            return item;
          }
        })
        .sort(compareNotifications));
    }

    case NotificationAction.replaceAll:
      return assoc(state, 'items', prop(payload, 'notifications', []));

    case NotificationAction.removeAll:
      return state;

    case NotificationAction.markAllAsRead:
      return state;

    default:
      return state;
  }
};


export const notificationStoreModule: IStoreModule<INotificationsState> = {
  key: notificationStoreKey,
  reducer: notificationReducer,
  initialState: notificationInitialState
};
