import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IRoute, useNavigator} from '../../../../core/routing';
import {
  toast,
  UiBox,
  UiFlexAlign,
  UiFlexRow,
  UiIcon,
  UiPageContainer,
  UiPageContent,
  UiTextLine,
  UiTextTitle,
  useHttpClient,
  useTranslator
} from '@bitsolve/react-common';
import {AppPageSectionInset} from '../../../common/page/app-page-section-inset.component';
import {NavLink} from 'react-router-dom';
import {AppBannerPane} from '../../../common/misc/app-banner-pane.component';
import {AccountPicture} from '../../../../module/auth/component/account/account-picture.component';
import {DashboardCardProgressIndicator} from '../../../../module/dashboard/component/dashboard-card-progress-indicator.component';
import {hasProp, isEmpty, isFn, isNil, isNotNil, prop, propIn} from '@bitsolve/fns';
import {Redirect, Route, Switch} from 'react-router';
import {AppPageMenu} from '../../../common/page/app-page-menu.component';
import {useAnalysisCommentModal} from '../../../../module/analysis/component/analysis-comment-modal.component';
import {findSampleSessionByIdRequest, findSessionByIdRequest} from '../../../../module/analysis/analysis.api';
import {IAnalysisSessionV2} from '../../../../module/analysis/analysis.model';
import {AxiosResponse} from 'axios';
import {AppLoadingSpinner} from '../../../common/misc/app-loading-spinner.component';
import {useAnalysisSegments, useAnalysisSessionSelection} from './details/coaching-zone-analysis';
import {AnalysisSessionSelector} from '../../../../module/analysis/component/analysis-session-selector.component';
import {useDispatch} from 'react-redux';
import {AnalysisDetailsPage} from '../../../../module/analysis/component/analysis-details-page.component';
import {useAuthAccount} from '../../../../module/auth/auth.store';
import {metricVelocityConverter, Rooq} from '../../../../core/domain.model';
import {useWindowSizeKey} from '../../../../module/ui/ui.hooks';
import {UiValueParts} from '../../../../module/ui/component/ui-value-parts.component';

export interface ICoachingZoneAnalysisDetailsRoute extends IRoute {
}

interface ICoachingZoneAnalysisSessionSummary {
  id: string;
  name: string;
  profilePictureId?: string;
  value: any;
  fractionValue: number;
  unit: string;
  color: any;
}

const CoachingZoneAnalysisSessionSummary: React.FC<ICoachingZoneAnalysisSessionSummary> = (props) => {
  const {name, profilePictureId, value, fractionValue = 1, unit, color} = props;
  const t = useTranslator();

  return <UiBox className={`app-analysis-athlete-summary app-analysis-athlete-summary--${color}`}>
    <UiBox className="app-analysis-athlete-summary__avatar"
           style={{height: '3rem', width: '3rem'}}>
      <AccountPicture size={3} mediaId={profilePictureId} />
    </UiBox>
    <UiFlexRow className="app-analysis-athlete-summary__stats"
               ai={UiFlexAlign.e}>
      <UiTextLine className="f-1 txt-b txt-sm" text={name} />
      <UiValueParts value={value}
                    className={'ui-text ui-text-title'} />
      {/*<UiTextTitle text={`${value}`} />*/}
    </UiFlexRow>
    <UiBox className="app-analysis-athlete-summary__progressbar">
      <DashboardCardProgressIndicator hideFull={false}
                                      progress={fractionValue} />
    </UiBox>
    <UiFlexRow className="app-analysis-athlete-summary__branding"
               ai={UiFlexAlign.s}
               jc={UiFlexAlign.e}>
      <UiTextLine className="txt-c-defd txt-sm"
                  text={t(unit)} />
    </UiFlexRow>
  </UiBox>
};

const CoachingZoneAnalysisDetailsTabs: React.FC = () => {
  const t = useTranslator();
  const selection = useAnalysisSessionSelection('selection');

  return <UiBox className={'app-analysis-details-tabs'}
                tag={'menu'}>
    {[
      {
        to: 'overview',
        label: t('app.analysis.details.section.overview.title'),
        unit: t('app.analysis.details.chart.unit.score')
      },
      {
        to: 'punches',
        label: t('app.analysis.details.section.punches.title'),
        unit: t('app.analysis.details.chart.unit.count')
      },
      {
        to: 'speed',
        label: t('app.analysis.details.section.speed.title'),
        unit: t('app.analysis.details.chart.unit.speed')
      },
      {
        to: 'power',
        label: t('app.analysis.details.section.power.title'),
        unit: t('app.analysis.details.chart.unit.power')
      },
    ].map(item => {
      const path = item.to
        ? `/portal/analysis/compare/${item.to}`
        : `/portal/analysis/compare`;

      const pathWithQuery = `${path}?selection=${selection.join(',')}`;

      return <NavLink key={path}
                      className={'app-analysis-details-tabs__item'}
                      to={pathWithQuery}>
        <UiTextTitle text={item.label}
                     className={'app-analysis-details-tabs__item__title f-jc-c'} />
        <UiFlexRow jc={UiFlexAlign.c}
                   className={'app-analysis-details-tabs__item__content'}>
          <UiIcon className="app-analysis-details-tabs__item__content__active"
                  name={'caret-down'} iconSet={'regular'} />
        </UiFlexRow>
      </NavLink>;
    })}
  </UiBox>
};


const getScore = (sess: IAnalysisSessionV2): number => sess.summary.rooqScore;
const getPunchCount = (sess: IAnalysisSessionV2): number => sess.summary.sessionStats.punchCount.total;
const getAvgSpeed = (sess: IAnalysisSessionV2): number => sess.summary.sessionStats.avgMaxSpeed.total;
const getAvgPower = (sess: IAnalysisSessionV2): number => sess.summary.sessionStats.avgPowerAtMaxSpeed.total;
const orZero = (v: number) => v || 0;

const baseSummary = (sess: IAnalysisSessionV2, idx: number): ICoachingZoneAnalysisSessionSummary => {
  return {
    id: sess.id,
    color: idx,
    name: sess.author.firstName,
    profilePictureId: sess.author.profilePictureId,
  } as any;
};

const analysisSessionSummaryTabs = (selection: string[], sessions: Record<string, IAnalysisSessionV2>, units: Rooq.UnitSystem): Record<'overview' | 'punches' | 'speed' | 'power',
  () => ICoachingZoneAnalysisSessionSummary[]> | null => {
  if (isNil(selection) || isEmpty(selection)) {
    return null;
  }

  const selectedSessions = selection.map(sid => prop(sessions, sid));

  const computeSummaries = (unit: string, acc: (sess: IAnalysisSessionV2) => number): () => ICoachingZoneAnalysisSessionSummary[] => {
    const max = Math.max.apply(undefined, selectedSessions.map(s => orZero(acc(s))));

    return () => selectedSessions.map((sess, idx) => {
      const value = acc(sess) || 0;
      const fractionValue = 1 / max * value;

      return {
        ...baseSummary(sess, idx),
        value,
        fractionValue,
        unit,
      };
    });
  };

  const convertSpeed = metricVelocityConverter(units);

  return {
    overview: computeSummaries('app.analysis.details.section.overview.unit', getScore),
    punches: computeSummaries('app.analysis.details.section.punches.unit', getPunchCount),
    speed: computeSummaries(`app.analysis.details.section.speed.unit.${units}`, s => convertSpeed(getAvgSpeed(s))),
    power: computeSummaries('app.analysis.details.section.power.unit', getAvgPower),
  };
};

const CoachingZoneAnalysisDetailsHeader: React.FC<{ selection: string[]; sessions: Record<string, IAnalysisSessionV2>; }> = (props) => {
  const {selection, sessions} = props;

  const basePattern = `/portal/analysis/compare`;

  const t = useTranslator();
  const user = useAuthAccount();
  const units = user?.unit || Rooq.UnitSystem.METRIC;

  const summaryTabs = useMemo(
    () => analysisSessionSummaryTabs(selection, sessions, units),
    [selection, sessions, units]
  );

  if (isNil(selection) || isEmpty(selection)) {
    return null;
  }

  return <AppBannerPane contentAnchor={'bs'}
                        aspectRatio={null as any}
                        className={'app-analysis-details-header'}
                        src={'/asset/brand/app/training-plan-mood-alt-sd.png'}>
    <UiPageContent className={'app-analysis-details-header__title'}>
      <UiTextLine text={t('app.analysis.details.section.session-count', {count: selection?.length || 0})} />
      <Switch>
        <Route path={`${basePattern}/overview`}
               render={() => <UiTextTitle headingLevel={1}
                                          text={t('app.analysis.details.section.overview.title')} />} />
        <Route path={`${basePattern}/punches`}
               render={() => <UiTextTitle headingLevel={1}
                                          text={t('app.analysis.details.section.punches.title')} />} />
        <Route path={`${basePattern}/speed`}
               render={() => <UiTextTitle headingLevel={1}
                                          text={t('app.analysis.details.section.speed.title')} />} />
        <Route path={`${basePattern}/power`}
               render={() => <UiTextTitle headingLevel={1}
                                          text={t('app.analysis.details.section.power.title')} />} />
      </Switch>
    </UiPageContent>
    <UiPageContent className={'app-analysis-details-header__summaries'}>
      <Route path={`${basePattern}/:tab`}
             render={({match}) => {
               const tab = propIn(match, ['params', 'tab']);
               const activeFactory = prop(summaryTabs, tab);

               if (!isFn(activeFactory)) {
                 return null;
               } else {
                 const result: ICoachingZoneAnalysisSessionSummary[] = (activeFactory as Function)();

                 return <>
                   {result.map(r => <CoachingZoneAnalysisSessionSummary key={r.id} {...r} />)}
                 </>;
               }
             }} />
    </UiPageContent>
  </AppBannerPane>;
};

const dev = true;
const sessionsParam = 'selection';
const pathPrefix = `/portal/analysis/compare`;

const contextOpts = {
  pathPrefix,
  selectionParam: sessionsParam,
};

const useAnalysisSelectionState = () => {
  const http = useHttpClient();
  const d = useDispatch();
  const ref = useRef(false);
  const [sessions, setSessions] = useState<Record<string, IAnalysisSessionV2>>({});

  const selection = useAnalysisSessionSelection(sessionsParam);
  const segments = useAnalysisSegments(sessions, contextOpts);

  useEffect(
    () => {
      if (ref.current) {
        return;
      }

      Promise
        .all(
          selection
            .filter(sessionId => !hasProp(sessions, sessionId))
            .map((sessionId, index) => http
              .send<AxiosResponse<IAnalysisSessionV2>>(
                dev
                  ? findSampleSessionByIdRequest(sessionId, index + 1)
                  : findSessionByIdRequest(sessionId)
              )
              .then(res => {
                // @ts-ignore
                res.dummySessionId = sessionId;
                return res;
              }))
        )
        .then(res => {
          setSessions(sessions => res
            .filter(r => !prop(r, 'isAxiosError'))
            .reduce(
              // @ts-ignore
              (ss, r) => ({...ss, [r.dummySessionId]: r.data}),
              sessions
            ));
        })
        .catch(err => {
          console.error(err);
        });

      ref.current = true;
    },
    [ref, selection, sessions, d, setSessions, http]
  );

  const selectionReady = useMemo(() => {
    return selection.reduce(
      (r, s) => r
        && hasProp(sessions, s)
        && isNotNil(sessions[s])
        && isNotNil(segments),
      true
    );
  }, [selection, sessions, segments]);

  return useMemo(
    () => ({sessions, selection, segments, ref, selectionReady}),
    [sessions, selection, segments, ref, selectionReady]
  );
}

const useOnScroll = () => {
  const lastHeight = useRef(0);

  return useCallback((ev: React.UIEvent<HTMLDivElement>) => {
    ev.persist();
    const tgt = ev.currentTarget;
    if (!tgt) return console.log('no target');
    const header: HTMLDivElement | null = tgt.querySelector('.app-analysis-details-header');
    if (!header) return console.log('no header');
    const headerH = header.clientHeight + 24;
    const CLASS = 'sticky';
    if (tgt.scrollTop > headerH * 1.1) {
      if (!tgt.classList.contains(CLASS)) {
        tgt.classList.add(CLASS)
        // tgt.scrollTop += 8;
      }
      if (!header.classList.contains(CLASS)) {
        lastHeight.current = headerH;
        header.classList.add(CLASS);
      }
    } else if (tgt.scrollTop < (lastHeight.current || headerH) * 0.9) {
      if (tgt.classList.contains(CLASS)) {
        tgt.classList.remove(CLASS);
        // tgt.scrollTop -= 16;
      }
      if (header.classList.contains(CLASS)) header.classList.remove(CLASS);
    }
  }, [lastHeight]);
};

export const CoachingZoneAnalysisDetailsRoute: React.FC<ICoachingZoneAnalysisDetailsRoute> = (props) => {
  const t = useTranslator();
  const nav = useNavigator();
  const {selectionReady, segments, selection, sessions} = useAnalysisSelectionState();
  const selectionQuery = useMemo(() => sessionsParam + '=' + selection.join(','), [selection]);
  const commentModal = useAnalysisCommentModal();
  const onScroll = useOnScroll();
  const sizeKey = useWindowSizeKey();

  useEffect(() => {
    if (isEmpty(selection)) {
      toast.warning('No session selected');
      nav('/portal/analysis');
    }
  }, [selectionReady, selection, nav]);

  if (!selectionReady) {
    return <UiPageContainer fluid className={'app-coachz app-coachz__analysis f-1 d-f fd-v f-ai-c'}
                            style={{position: 'relative', maxHeight: '100vh', minHeight: 0, overflow: 'hidden'}}>
      <AppLoadingSpinner />
    </UiPageContainer>
  }

  return <UiPageContainer fluid
                          key={sizeKey}
                          style={{position: 'relative', maxHeight: '100vh', minHeight: 0, overflow: 'hidden'}}
                          className={'app-coachz app-coachz__analysis f-1 d-f fd-v'}>
    <AppPageSectionInset className={'d-f fd-h f-ai-c'}>
      <NavLink className={'app-page-header__back-action'}
               to={`/portal/analysis`}
               exact={true}>
        <UiIcon name={'caret-left'}
                iconSet={'regular'} />
      </NavLink>
      {sessions && <AnalysisSessionSelector selection={selection}
                                            sessions={sessions} />}
      <UiBox className="f-1" />
      <AppPageMenu className={'app-coachz__analysis__tabs'}
                   items={selection.length > 1
                     ? []
                     : [
                       {
                         label: t('app.analysis.details.action.comment.label'),
                         icon: 'comment',
                         interact: () => {
                           const data = {
                             session: sessions && selection && selection.length
                               ? sessions[selection[0]]
                               : undefined,
                             rating: 0,
                             comment: ''
                           };
                           commentModal.setData(data);
                           commentModal.open();
                         }
                       },
                     ]} />
    </AppPageSectionInset>
    <div
      className={'app-analysis__details__container d-f fd-v'}
      onScroll={onScroll}>
      <CoachingZoneAnalysisDetailsHeader
        selection={selection}
        sessions={sessions} />
      <CoachingZoneAnalysisDetailsTabs />
      <Switch>
        <Redirect exact
                  from={pathPrefix}
                  to={`${pathPrefix}/overview?${selectionQuery}`} />

        <Route path={`${pathPrefix}/overview`}
               render={() =>
                 <AnalysisDetailsPage id={'overview'}
                                      segments={segments.overview} />} />

        <Route path={`${pathPrefix}/punches`}
               render={() =>
                 <AnalysisDetailsPage id={'punches'}
                                      segments={segments.punches} />} />

        <Route path={`${pathPrefix}/speed`}
               render={() =>
                 <AnalysisDetailsPage id={'speed'}
                                      segments={segments.speed} />} />

        <Route path={`${pathPrefix}/power`}
               render={() =>
                 <AnalysisDetailsPage id={'power'}
                                      segments={segments.power} />} />
      </Switch>
    </div>
    {commentModal.element}
  </UiPageContainer>;
};
