import {Action, Reducer} from 'redux';
import {createSelector} from 'reselect';
import {actionCreator, createActionHook, createReducer, IStoreModule} from '@bitsolve/react-common';
import {assoc, hasProp, isNotNil, propIn} from '@bitsolve/fns';
import {IAuthAccess, IAuthUser, IAuthUserAccount} from './auth.model';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {Rooq} from '../../core/domain.model';


export interface IAuthStore<U extends IAuthUser = IAuthUser, A extends IAuthAccess = IAuthAccess, A2 extends IAuthUserAccount = IAuthUserAccount> {
  busy: boolean;
  authenticated: boolean;
  loginTime: number | null;
  lastCheckTime: number | null;
  user: U | null;
  access: A | null;
  account: A2 | null;
}

export const authStoreKey = '@rooq/auth';

const initialState: IAuthStore = {
  busy: false,
  authenticated: false,
  loginTime: null,
  lastCheckTime: null,
  user: null,
  access: null,
  account: null,
};

const selectAuthStore = (state: { [authStoreKey]: IAuthStore }): IAuthStore => state[authStoreKey];
const selectAuthenticated = createSelector(selectAuthStore, auth => auth.authenticated);
const selectAuthUser = createSelector(selectAuthStore, auth => auth.user);
const selectAuthBusy = createSelector(selectAuthStore, auth => auth.busy);
const selectAuthAccess = createSelector(selectAuthStore, auth => auth.access);
const selectAuthAccount = createSelector(selectAuthStore, auth => auth.account);
const selectAuthTouched = createSelector(selectAuthStore, auth => auth.lastCheckTime);

export const useAuthStore = () => useSelector(selectAuthStore);
export const useAuthenticated = () => useSelector(selectAuthenticated)
export const useAuthBusy = () => useSelector(selectAuthBusy);
export const useAuthUser = () => useSelector(selectAuthUser);
export const useAuthAccess = () => useSelector(selectAuthAccess);
export const useAuthAccount = () => useSelector(selectAuthAccount);
export const useAuthTouched = () => useSelector(selectAuthTouched);

export const useAuthUserUnitSystem = () => {
  const acc = useAuthAccount();
  return useMemo(
    () => acc?.unit || Rooq.UnitSystem.METRIC,
    [acc]
  );
};

enum AuthStoreAction {
  busy = '@rooq.auth/busy',
  unbusy = '@rooq.auth/unbusy',
  touch = '@rooq.auth/touch',
  updateAccess = '@rooq.auth/update-access',
  updateAccount = '@rooq.auth/update-account',
  updateAccountProfile = '@rooq.auth/update-account-profile',
  authenticate = '@rooq.auth/authenticate',
  unauthenticate = '@rooq.auth/unauthenticate'
}

const createAuthenticateAction = actionCreator(AuthStoreAction.authenticate, (args: [IAuthUser]) => ({user: args[0]}));
const createUnauthenticateAction = actionCreator(AuthStoreAction.unauthenticate);
const createUpdateAccessAction = actionCreator(AuthStoreAction.updateAccess, (args: [IAuthAccess]) => ({access: args[0]}));
const createUpdateAccountAction = actionCreator(AuthStoreAction.updateAccount, (args: [IAuthUserAccount]) => ({account: args[0]}));
const createUpdateAccountProfileAction = actionCreator(AuthStoreAction.updateAccountProfile, (args: ['trainerProfile' | 'athleteProfile', IAuthUserAccount['trainerProfile'] | IAuthUserAccount['athleteProfile']]) => ({
  key: args[0],
  profile: args[1]
}));
const createBusyAction = actionCreator(AuthStoreAction.busy);
const createUnbusyAction = actionCreator(AuthStoreAction.unbusy);
const createTouchAction = actionCreator(AuthStoreAction.touch);

export const useAuthActions = createActionHook({
  authenticate: createAuthenticateAction,
  unauthenticate: createUnauthenticateAction,
  updateAccess: createUpdateAccessAction,
  updateAccount: createUpdateAccountAction,
  updateAccountProfile: createUpdateAccountProfileAction,
  touch: createTouchAction,
  busy: createBusyAction,
  unbusy: createUnbusyAction,
});

const reducer: Reducer<IAuthStore, Action<AuthStoreAction>> = createReducer(
  {
    [AuthStoreAction.busy]: (state) => assoc(state, 'busy', true),
    [AuthStoreAction.unbusy]: (state) => assoc(state, 'busy', false),
    [AuthStoreAction.touch]: (state) => assoc(state, 'lastCheckTime', Date.now()),
    [AuthStoreAction.updateAccess]: (state, action) => assoc(state, 'access', propIn(action, ['payload', 'access'])),
    [AuthStoreAction.updateAccount]: (state, action) => assoc(state, 'account', propIn(action, ['payload', 'account'])),
    [AuthStoreAction.updateAccountProfile]: (state, action) => ({
      ...state,
      account: {
        ...state.account,
        [action.payload.key]: action.payload.profile
      }
    }),
    [AuthStoreAction.authenticate]: (state, action) => {
      const user = propIn(action, ['payload', 'user']);

      return {
        ...state,
        authenticated: isNotNil(user) && hasProp(user, 'uid'),
        loginTime: isNotNil(user) ? Date.now() : null,
        user
      };
    },
    [AuthStoreAction.unauthenticate]: (state) => ({
      ...state,
      access: null,
      authenticated: false,
      user: null,
      userInfo: null,
      account: null
    })
  },
  initialState
);

export const authStoreModule: IStoreModule<IAuthStore> = {
  key: authStoreKey,
  reducer,
  initialState
};
