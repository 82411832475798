import React from 'react';
import {
  classNames,
  UiBox,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiCustomizable,
  UiFlexCol,
  UiFlexRow,
  UiTextTitle
} from '@bitsolve/react-common';

export interface IUiModalDialog extends UiCustomizable {
  header?: {
    title?: string;
    onClose?: () => any;
  },
  search?: {
    placeholder?: string;
    value?: string;
    onChange?: (value: string) => any;
  }
}

export const UiModalDialogHeader: React.FC<IUiModalDialog['header']> = (props) => {
  const {title, onClose} = props;

  return <UiFlexRow tag={'header'}
                    className="ui-modal-dialog__header">
    <UiTextTitle headingLevel={3}
                 text={title}
                 className={'ui-modal-dialog__header__title f-1'} />
    {onClose && <UiButton icon={'times'}
                          className={'ui-modal-dialog__header__close'}
                          color={UiColor.default}
                          variation={UiButtonVariation.semantic}
                          interact={onClose} />}
  </UiFlexRow>;
}

export const UiModalDialogSearch: React.FC<IUiModalDialog['search']> = (props) => {
  const {value, placeholder, onChange} = props;

  return <UiBox className={'ui-modal-dialog__search'}>
    {onChange && <input type="text"
                        value={value}
                        max={256}
                        placeholder={placeholder}
                        onChange={ev => onChange(ev.target.value)} />}
  </UiBox>;
};

export const UiModalDialog: React.FC<IUiModalDialog> = (props) => {
  const {header, search, children, className, style} = props;

  return <UiFlexCol className={classNames('ui-modal-dialog', className)}
                    style={style}>
    {header && <UiModalDialogHeader {...header} />}
    {search && <UiModalDialogSearch {...search} />}
    {children}
  </UiFlexCol>;
};
