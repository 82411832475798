import {IAnalysisDetailsContext} from '../../../../../module/analysis/component/analysis-details.model';
import {RooqStyle, useRooqStyle} from '../../../../../style';
import {useTranslator} from '@bitsolve/react-common';
import {IRoute, useNavigator} from '../../../../../core/routing';
import {useAnalysisSessionSelection} from './coaching-zone-analysis';
import {useCallback, useMemo} from 'react';
import {useAuthAccount} from '../../../../../module/auth/auth.store';
import {Rooq} from '../../../../../core/domain.model';
import {IAnalysisSessionV2} from '../../../../../module/analysis/analysis.model';
import {IBarChartData} from '../../../../../module/analysis/component/chart/bar-chart.component';

export interface ICoachingZoneAnalysisDetailsOptions {
  selectionParam: string;
  pathPrefix: string;
}

export interface ICoachingZoneAnalysisDetailsContext extends IAnalysisDetailsContext, ICoachingZoneAnalysisDetailsOptions {
  unitSystem: Rooq.UnitSystem;
  style: RooqStyle;
}

export type RoundDetailsChartDataBuilder = (route: IRoute, session: IAnalysisSessionV2, exercise?: Rooq.TrainingSessionUiExerciseV2) => IBarChartData[];

export type RoundDetailsItem = {
  label: string;
  param: string;
  dataBuilder: RoundDetailsChartDataBuilder;
};

export interface RouteExtractedUiExercise extends Rooq.TrainingSessionUiExerciseV2 {
  index: number;
  sessionIndex: number;
  session: IAnalysisSessionV2;
}

export interface RouteExtractedUiRound extends Rooq.TrainingSessionUiExerciseDataV2 {
  index: number;
  exerciseIndex: number;
  exercise: Rooq.TrainingSessionUiExerciseV2;
  sessionIndex: number;
  session: IAnalysisSessionV2;
}


const defaultOpts: ICoachingZoneAnalysisDetailsOptions = {
  selectionParam: 'selection',
  pathPrefix: '/'
};

export const useAnalysisDetailsContext = (sessions: IAnalysisDetailsContext['sessions'], opts: ICoachingZoneAnalysisDetailsOptions = defaultOpts): ICoachingZoneAnalysisDetailsContext => {
  const {selectionParam, pathPrefix} = opts;
  const user = useAuthAccount();
  const unitSystem = user?.unit || Rooq.UnitSystem.METRIC;
  const style = useRooqStyle();
  const translate = useTranslator();
  const nav = useNavigator();
  const selection = useAnalysisSessionSelection(selectionParam);
  const navigate = useCallback(
    path => nav(`${pathPrefix}/${path}?${selectionParam}=${selection.join(',')}`),
    [nav, pathPrefix, selectionParam, selection]
  );

  return useMemo(
    () => ({translate, navigate, selection, style, sessions, unitSystem, ...opts}),
    [translate, navigate, selection, style, sessions, opts, unitSystem]
  );
};
