import React from 'react';
import {IRoute} from '../../../core/routing';
import {isFn, isNonEmptyStr, isNotFalse} from '@bitsolve/fns';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router';

export type IAnalysisRedirectFn = (route: IRoute) => string;

export interface IAnalysisRedirect {
  exact?: boolean;
  from: string;
  to: string | IAnalysisRedirectFn;
}

export const AnalysisRedirect: React.FC<IAnalysisRedirect> = (props) => {
  const {to} = props;
  const exact = isNotFalse(props.exact);
  const path = props.from;
  const query = isNonEmptyStr(window.location.search) ? window.location.search : null;

  return isFn(to)
    ? <Route path={path} exact={exact}
             render={route => {
               const _to = to(route);
               return <Redirect to={query ? `${_to}${query}` : _to} />;
             }} />
    : <Redirect from={path} exact={exact}
                to={query ? `${to}${query}` : to} />;
};
