import React from 'react';
import {Rooq} from '../../../core/domain.model';
import {isNil} from '@bitsolve/fns';
import {classNames, UiBox, UiColor, UiCustomizable, UiTextTitle} from '@bitsolve/react-common';
import {UiSplashMessage} from '../../ui/component/ui-splash-message.component';
import {useFindClubMembers} from '../club.api';
import {TrainerCard} from '../../auth/component/trainer/trainer-card.component';
import {useNavigator} from '../../../core/routing';

export interface IClubTrainerCardGrid extends UiCustomizable {
  clubId: string;
  title?: string;
  perRow?: number;
}


export const ClubTrainerCardGrid: React.FC<IClubTrainerCardGrid> = (props) => {
  const {clubId, perRow = 3, title, style, className} = props;

  const nav = useNavigator();
  const membersReq = useFindClubMembers(clubId, Rooq.MembershipType.TRAINER);
  const memberships = membersReq?.response?.data?.elements;

  if (isNil(memberships) || memberships?.length === 0) {
    return <UiSplashMessage title={'Noch keine Trainer'}
                            messages={[
                              'Füge jetzt den ersten Trainer zu diesem Verein hinzu.',
                            ]}
                            actions={[
                              {
                                label: 'Nach Trainern suchen',
                                color: UiColor.accent,
                                className: 'txt-b'
                              }
                            ]} />;
  }

  return <>
    {title && <UiTextTitle text={title}
                           headingLevel={2}
                           className={'mg-b-nm'} />}
    <UiBox className={classNames('d-g', className)}
           style={{
             gridGap: 'var(--size-geo--md)',
             ...style,
             gridTemplateColumns: `repeat(${perRow}, minmax(min-content, 1fr))`,
             gridTemplateRows: `repeat(${Math.ceil((memberships?.length || 0) / perRow)}, min-content)`,
           }}>
      {memberships?.map((membership, i) =>
        <TrainerCard key={`${i}.${membership.id}`}
                     isClubAdmin={membership.role === Rooq.MembershipRole.ADMIN}
                     account={membership.account}
                     interact={() => nav(`/portal/club/${clubId}/trainers/details/${membership.account.id}`)} />)}
    </UiBox>
  </>;
};
