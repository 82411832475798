import React, {Children} from 'react';
import {classNames, UiBox, UiCustomizable, UiTextTitle} from '@bitsolve/react-common';
import {isTrue} from '@bitsolve/fns';
import {IRoute} from '../../../core/routing';
import {animated, useSpring, useTrail} from 'react-spring';

export interface IAnalysisChartHeader {
  title: string;
  interact?: (route: undefined | IRoute, ev: MouseEvent) => any;
}

const headerAnim = {tension: 140, friction: 16};
const contentAnim = {tension: 120, friction: 14};

const AnalysisPanelHeader: React.FC<IAnalysisChartHeader & { route?: IRoute; }> = (props) => {
  const {title, interact, route} = props;
  const anim = useSpring({
    config: headerAnim,
    from: {opacity: 0},
    to: {opacity: 1}
  });

  return <animated.header
    style={anim}
    className={'app-analysis__panel__header'}
    onClick={interact ? (ev) => interact(route, ev as any) : undefined}>
    <UiTextTitle text={title} />
  </animated.header>
};


export const AnalysisPanel: React.FC<UiCustomizable & { collapse?: boolean; header?: IAnalysisChartHeader; route?: IRoute; }> = (props) => {
  const {collapse, header, className, route, style, children} = props;
  const _children = !isTrue(collapse) ? Children.toArray(children) : [];
  const showContent = !isTrue(collapse);
  const trail = useTrail(_children.length, {
    config: contentAnim,
    from: {opacity: 0},
    to: {opacity: 1},
  });
  const content = trail.map((c, i) => {
    const child = _children[i];
    return <animated.div key={i}
                         className={'app-analysis__panel__body f-1'}
                         style={c}>{child}</animated.div>;
  });

  return <UiBox className={classNames('app-analysis__panel', className)}
                style={style}>
    {header && <AnalysisPanelHeader {...header} route={route} />}
    {showContent ? content : null}
  </UiBox>
};
