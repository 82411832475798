import {IRoute} from '../../../../core/routing';
import React from 'react';
import {
  classNames,
  IUiFieldProps,
  schema,
  toast,
  UiBox,
  UiColor,
  UiCustomizable,
  UiDir,
  UiForm,
  UiFormButton,
  UiFormField,
  UiPageSegment,
  UiState,
  useTranslatedFields,
  useTranslator
} from '@bitsolve/react-common';
import {IAuthUserAccount, IAuthUserTrainerProfile} from '../../../../module/auth';
import {staticGendersControlProps, staticTrainerTypesControlProps} from '../../../../module/content/content.api';
import {useAuthAccount, useAuthActions, useAuthUser} from '../../../../module/auth/auth.store';
import {useUpdateMyAccount, useUpdateMyTrainerProfile} from '../../../../module/auth/auth.api';
import {AppPageSectionPanel} from '../../../common/page/app-page-section-panel.component';
import {useSafeEmailDisplay} from '../../../../core/util';
import {AppPageSectionTitle} from '../../../common/page/app-page-section-title.component';
import {useSchemaOptions} from '../../../../core/api';
import {authProfileSchema} from '../../../../module/auth/auth.forms';


const accountProfileCardSchema = schema.object({
  firstName: schema.string().required('error.messages.firstName.required'),
  lastName: schema.string().required('error.messages.lastName.required'),
  birthDay: authProfileSchema.birthDay,
});

const accountProfileCardFields: IUiFieldProps<IAuthUserAccount>[] = [
  {
    name: 'profilePictureId',
    fieldType: 'account-picture',
    label: 'app.account.profile.form.profilePicture.label',
    direction: UiDir.v
  },
  {
    name: 'firstName',
    fieldType: 'text',
    label: 'app.account.profile.form.name.label',
    direction: UiDir.v,
  },
  {
    name: 'lastName',
    fieldType: 'text',
    label: 'app.account.profile.form.lastName.label',
    direction: UiDir.v,
  },
  // {
  //   name: 'hometown',
  //   label: 'app.account.profile.form.hometown.label',
  //   fieldType: 'text',
  //   direction: UiDir.v
  // },
  {
    name: 'aboutMe',
    label: 'app.account.profile.form.aboutMe.label',
    fieldType: 'textarea',
    direction: UiDir.v
  },
  {
    name: 'birthDay',
    label: 'app.account.profile.form.birthDay.label',
    fieldType: 'date-inputs',
    direction: UiDir.v,
    controlProps: {
      outputFormat: 'YYYY-MM-DDTHH:mm',
      displayFormat: 'DD.MM.YYYY',
      previewIcon: 'appCalendar'
    }
  },
  {
    name: 'gender',
    label: 'app.account.profile.form.gender.label',
    fieldType: 'content-select',
    className: 'hide-placeholder pd-sm',
    direction: UiDir.v,
    controlProps: staticGendersControlProps,
  },
  // {
  //   name: 'countryIsoCode',
  //   label: 'app.account.profile.form.countryIsoCode.label',
  //   fieldType: 'content-select',
  //   className: 'hide-placeholder pd-sm',
  //   direction: UiDir.v,
  //   controlProps: staticCountriesControlProps
  // }
];

const AccountProfileCardFieldset: React.FC<UiCustomizable> = ({className, style, children}) => {
  return <UiBox className={classNames('d-f mg-v-nm mg-h-md', className)}
                style={{...style, flexWrap: 'wrap'}}>
    {children}
  </UiBox>
}

const AccountProfileCard: React.FC = () => {
  const t = useTranslator();
  const user = useAuthUser();
  const acc = useAuthAccount();
  const fields = useTranslatedFields(accountProfileCardFields);
  const authAction = useAuthActions();
  const updateAccount = useUpdateMyAccount(
    () => toast.success(t('app.account.profile.action.update.toast.success')),
    () => toast.error(t('app.account.profile.action.update.toast.error'))
  );
  const schemaOpts = useSchemaOptions('app.account.profile.{message}', false);
  const email = useSafeEmailDisplay(user?.email);

  if (!acc) {
    return null;
  }

  return <AppPageSectionPanel className={'account-profile-card pd-v-nm ui-fx ui-fx--fast ui-fx__pane-fade-left'}>
    <UiForm id={'account-card-form'}
            value={{...acc, email}}
            fields={fields}
            schema={accountProfileCardSchema}
            schemaOptions={schemaOpts}
            interact={(data, form) => {
              if (updateAccount.busy) {
                return;
              }

              return updateAccount.send(data)
                .then(result => authAction.updateAccount(result?.data))
                .catch(e => {
                  const err = schemaOpts?.transformSubmitError(e, data);

                  if (err) {
                    form.setError(err);
                    return Promise.reject(err);
                  }
                });
            }}>
      <UiBox className="d-f fd-v f-ai-c f-jc-c mg-t-md mg-b-md">
        <UiFormField name={'profilePictureId'}
                     controlProps={{
                       size: 10
                     }} />
      </UiBox>
      <AccountProfileCardFieldset>
        <UiFormField name={'firstName'} className={'pd-sm'} style={{flex: '1 1 12rem'}} />
        <UiFormField name={'lastName'} className={'pd-sm'} style={{flex: '1 1 12rem'}} />
      </AccountProfileCardFieldset>
      <AccountProfileCardFieldset>
        <UiFormField name={'gender'} style={{flex: '1 1 12rem'}} />
        <UiFormField name={'birthDay'} className={'pd-sm'} style={{flex: '1 1 12rem'}} />
      </AccountProfileCardFieldset>
      <AccountProfileCardFieldset>
        <UiFormField name={'aboutMe'} className={'pd-sm'} style={{flex: '1 1 12rem'}} />
      </AccountProfileCardFieldset>
      {/*<AccountProfileCardFieldset>*/}
      {/*  <UiBox className={'pd-sm'} style={{flex: '1 1 12rem'}}>*/}
      {/*<UiFormField name={'hometown'} />*/}
      {/*</UiBox>*/}
      {/*<UiBox style={{flex: '1 1 12rem'}}>*/}
      {/*<UiFormField name={'countryIsoCode'} />*/}
      {/*</UiBox>*/}
      {/*</AccountProfileCardFieldset>*/}
      <UiBox tag={'footer'}
             className={'d-f f-jc-c mg-h-md mg-t-lg mg-b-md'}>
        <UiFormButton
          label={t('general.action.save')}
          icon={'save'}
          color={UiColor.accent}
          state={updateAccount.busy ? UiState.disabled : UiState.active}
          interact={(form) => form.submit()} />
      </UiBox>
    </UiForm>
  </AppPageSectionPanel>
};


const accountTrainerProfileCardFields: IUiFieldProps<IAuthUserTrainerProfile>[] = [
  {
    name: 'trainerType',
    fieldType: 'content-select',
    label: 'app.account.trainerProfile.form.trainerType.placeholder',
    controlProps: staticTrainerTypesControlProps
  },
  {
    name: 'fightStyles',
    fieldType: 'fight-style-select',
    controlProps: {}
  },
  {
    name: 'customFightStyles',
    fieldType: 'custom-fight-styles',
    controlProps: {}
  }
];

const accountTrainerProfileSchema = schema.object({
  trainerType: schema.mixed()
    .required('error.messages.trainerType.required'),
  fightStyles: schema.array()
    .min(1, 'error.messages.fightStyles.size')
});

const AccountTrainerProfileCard: React.FC = () => {
  const t = useTranslator();
  const acc = useAuthAccount();

  const fields = useTranslatedFields(accountTrainerProfileCardFields);
  const authAction = useAuthActions();
  const schemaOpts = useSchemaOptions('app.account.trainerProfile.{message}', false);

  const updateTrainerProfile = useUpdateMyTrainerProfile(
    () => toast.success(t('app.account.trainerProfile.action.update.toast.success')),
    () => toast.error(t('app.account.trainerProfile.action.update.toast.error'))
  );

  if (!acc) {
    return null;
  }

  return <AppPageSectionPanel
    className={'account-trainer-profile-card pd-v-nm ui-fx ui-fx--fast ui-fx__pane-fade-left'}>
    <UiForm id={'account-card-form'}
            value={acc.trainerProfile}
            fields={fields}
            schema={accountTrainerProfileSchema}
            schemaOptions={schemaOpts}
            interact={(data, form) => {
              if (updateTrainerProfile.busy) {
                return;
              }

              return updateTrainerProfile.send(data)
                .then(result => authAction.updateAccount({...acc, trainerProfile: result?.data}))
                .catch(e => {
                  const err = schemaOpts?.transformSubmitError(e, data);

                  if (err) {
                    form.setError(err);
                    return Promise.reject(err);
                  }
                });
            }}>
      <AccountProfileCardFieldset className={'f-ai-s'}>
        <UiBox className={'pd-sm d-f fd-v'} style={{flex: '1 1 12rem'}}>
          <AppPageSectionTitle dock={'top'}
                               style={{fontSize: '1.25rem'}}
                               className={'mg-b-sm'}
                               text={t('app.account.trainerProfile.form.fightStyle.label')} />
          <UiFormField name={'fightStyles'} />
          <UiFormField name={'customFightStyles'} className={'mg-t-nm'} />
        </UiBox>
        <UiBox className={'pd-sm d-f fd-v'} style={{flex: '1 1 12rem'}}>
          <AppPageSectionTitle dock={'top'}
                               style={{fontSize: '1.25rem'}}
                               className={'mg-b-sm'}
                               text={t('app.account.trainerProfile.form.trainerType.label')} />
          <UiFormField name={'trainerType'}
                       direction={UiDir.v}
                       style={{width: '100%'}} />
        </UiBox>
      </AccountProfileCardFieldset>
      <UiBox tag={'footer'}
             className={'d-f f-jc-c mg-h-md mg-t-lg mg-b-md'}>
        <UiFormButton
          label={t('general.action.save')}
          icon={'save'}
          color={UiColor.accent}
          state={updateTrainerProfile.busy ? UiState.disabled : UiState.active}
          interact={(form) => form.submit()} />
      </UiBox>
    </UiForm>
  </AppPageSectionPanel>;
};

export interface ICoachingZoneSettingsProfileRouteComponent extends IRoute {
}

export const CoachingZoneSettingsProfileRouteComponent: React.FC<ICoachingZoneSettingsProfileRouteComponent> = (props) => {
  const {match} = props;

  if (!match) return null;

  return <>
    <UiPageSegment key={`account.${match.path}`}>
      <AccountProfileCard />
    </UiPageSegment>
    <UiPageSegment key={`account.trainerProfile.${match.path}`}>
      <AccountTrainerProfileCard />
    </UiPageSegment>
  </>;
};
