import React, {ReactNode} from 'react';
import {IAuthUserAccount} from '../../../module/auth';
import {Route, RouteComponentProps, Switch} from 'react-router';
import {useAuthAccount} from '../../../module/auth/auth.store';
import {isFn, propIn} from '@bitsolve/fns';
import {IRoute} from '../../../core/routing';
import {IAppClubPerspective, IAppPerspective, IAppTrainerPerspective} from './app-perspective';


export interface IAppPerspectiveSwitch {
  trainer: (account: IAuthUserAccount) => any;
  club: (clubId: string, account: IAuthUserAccount) => any;
}

const clubPerspectiveRender = (render: IAppPerspectiveSwitch['club'], acc: IAuthUserAccount) =>
  (route: RouteComponentProps) => {
    const clubId = propIn(route, ['match', 'params', 'clubId']);
    return render(clubId, acc);
  };

const trainerPerspectiveRender = (render: IAppPerspectiveSwitch['trainer'], acc: IAuthUserAccount) =>
  (_: RouteComponentProps) => {
    return render(acc);
  };

export const AppPerspectiveSwitch: React.FC<IAppPerspectiveSwitch> = (props) => {
  const account = useAuthAccount();

  return account
    ? <Switch>
      <Route path={'/portal/club/:clubId'} exact={false}
             render={clubPerspectiveRender(props.club, account)} />
      <Route path={'/portal'} exact={false}
             render={trainerPerspectiveRender(props.trainer, account)} />
    </Switch>
    : null;
};


export const WithAppPerspective: React.FC<{ children?: (_: Partial<IRoute> & { perspective: IAppPerspective }) => ReactNode }> = (props) => {
  const {children} = props;

  if (!children || !isFn(children)) {
    return null;
  }

  return <AppPerspectiveSwitch
    trainer={(account) => {
      const perspective: IAppTrainerPerspective = {alias: 'trainer', data: {account}};
      return children({perspective});
    }}
    club={(clubId, account) => {
      const perspective: IAppClubPerspective = {alias: 'club', data: {clubId, account}};
      return children({perspective})
    }} />;
};
