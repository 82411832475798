import {
  IUiButton,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiCustomizable,
  UiIcon,
  UiTextLine,
  useUniqueId
} from '@bitsolve/react-common';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {isFn, isNotNil, prop} from '@bitsolve/fns';
import {NavLink} from 'react-router-dom';

type IUiTooltipMenuLinkItem = {
  to: string;
  exact?: boolean;
};

export type IUiTooltipMenuItemType = IUiButton | IUiTooltipMenuLinkItem;

export interface IUiTooltipMenuItem extends UiCustomizable {
  label?: string;
  icon?: string;
  iconSet?: string;
}

export interface IUiTooltipMenu extends UiCustomizable {
  id?: string;
  items: (IUiTooltipMenuItem & IUiTooltipMenuItemType)[];
  place?: 'top' | 'right' | 'bottom' | 'left';
  effect?: 'float' | 'solid';
  clickable?: boolean;
  globalEventOff?: string;
  toggleIcon?: string;
  toggleIconSet?: 'regular' | 'solid';
  toggleIconColor?: UiColor;
}

export const UiTooltipMenu: React.FC<IUiTooltipMenu> = (props) => {
  const {
    items,
    toggleIcon = 'ellipsisH',
    toggleIconSet = 'regular',
    globalEventOff = 'click',
    clickable = true,
    place = 'left',
    effect = 'solid',
    toggleIconColor,
  } = props;

  const id = useUniqueId(props.id, 4);

  return <>
    <span className={'ui-tooltip-menu__toggle'}
          data-tip={'data-tip'}
          data-for={id}
          data-event={'click'}
          data-scroll-hide={'true'}
          onMouseUp={() => ReactTooltip.hide()}>
      <UiIcon name={toggleIcon}
              iconSet={toggleIconSet as any}
              style={{color: `var(--color-text--${toggleIconColor || 'inverse'})`}} />
    </span>
    <ReactTooltip id={id}
                  className={'ui-tooltip-menu__container'}
                  place={place}
                  effect={effect}
                  clickable={clickable}
                  globalEventOff={globalEventOff}
                  backgroundColor={'#223545'}>
      <menu className={'ui-tooltip-menu'}>
        {items?.map((item, i) => {
          if (isNotNil(prop(item, 'to'))) {
            const to = prop(item, 'to') as any;

            return <NavLink key={`${to}.${i}`}
                            className="ui-tooltip-menu__item ui-tooltip-menu__item--link"
                            to={prop(item, 'to') as any}
                            exact={!!prop(item, 'exact')}>
              {item.icon && <UiIcon name={item.icon}
                                    iconSet={item.iconSet as any || 'regular'} />}
              {item.label && <UiTextLine text={item.label} />}
            </NavLink>;
          } else {
            return <UiButton key={i}
                             className="ui-tooltip-menu__item ui-tooltip-menu__item--button"
                             variation={UiButtonVariation.semantic}
                             color={UiColor.default}
                             {...item as any}
                             interact={(ev) => {
                               const interact = prop(item, 'interact');
                               if (isFn(interact)) {
                                 (interact as any)(ev, item);
                               }
                             }} />;
          }
        })}
      </menu>
    </ReactTooltip>
  </>;
};
