import React from 'react';
import {IAnalysisSegmentType} from '../../../../../module/analysis/component/analysis-details.model';
import {IRoute} from '../../../../../core/routing';
import {dissoc, hasProp, isEmpty, isNil, isNotNil, isNum, prop, propIn} from '@bitsolve/fns';
import {AnalysisPunchType} from '../../../../../module/analysis/component/analysis-punch-type.component';
import {UiTextLine} from '@bitsolve/react-common';
import {
  chartColorWheel,
  disciplineColor,
  findMaxDerivativeIndices,
  metricVelocityConverter,
  metricVelocityDataConverter,
  Rooq
} from '../../../../../core/domain.model';
import {chart, segment} from '../../../../../module/analysis/component/analysis-details.builder';
import {
  ICoachingZoneAnalysisDetailsContext,
  RouteExtractedUiExercise,
  RouteExtractedUiRound
} from './coaching-zone-analysis.model';
import {tryParseNumber} from '../../../../../core/util';
import {createExerciseAnalysisSegmentsFromSessionsV2} from './coaching-zone-exercise-analysis';
import {sortByPunchType} from '../../../../../module/analysis/component/analysis-details.hooks';


const punchTypeColors = {
  'STRAIGHT': '#CA2243',
  'HOOK': '#4A9DF4',
  'UPPERCUT': '#75133D',
  'BODY_SHOT': '#1C446D',
};

// const RE_DASH = /-+/g;
// const normalizeExerciseParam = (x?: string) => (x || 'unknown-activity').toUpperCase().replace(RE_DASH, '_');
// const RE_USCORE = /_+/g;
// const urlizeExerciseParam = (x?: string) => (x || 'UNKNOWN_ACTIVITY').toLowerCase().replace(RE_USCORE, '-');


const selectedSession = (context: ICoachingZoneAnalysisDetailsContext) => prop(context.sessions, context.selection[0]);
const removeTotalValue = (vals?: null | { label: string; [_: string]: any; }[]) => vals?.filter(v => v.label !== 'TOTAL');


const exercisesWithAccumulatedValues = (exs: Rooq.TrainingSessionUiExerciseV2[]): Rooq.TrainingSessionUiExerciseV2[] => {
  return exs.reduce(
    (r, e) => {
      const _e = {...e};
      if (r.length) {
        const rp = r[r.length - 1];
        _e.duration += rp.duration;
        _e.rooqScore += rp.rooqScore;
      }
      r.push(_e);
      return r;
    },
    [
      {duration: 0, rooqScore: 0} as any
    ] as Rooq.TrainingSessionUiExerciseV2[]
  );
};

const exerciseByMaxScoreGain = (exs: Rooq.TrainingSessionUiExerciseV2[]): null | { index: number; exercise: Rooq.TrainingSessionUiExerciseV2; } => {
  const derivs = findMaxDerivativeIndices(
    exercisesWithAccumulatedValues(exs),
    e => e.duration,
    e => e.rooqScore,
  );

  if (isNil(derivs) || isNil(derivs[0])) return null;

  // console.log('strongest phase:', derivs, derivs.map(i => props(exs[i], ['rooqScore', 'duration'])));

  return {
    index: derivs[0],
    exercise: exs[derivs[0]],
  };
}

const dataByMaxGain = (
  data?: null | { x: number; y: number; }[]
): null | { fromIndex: number; toIndex: number; from: any; to: any; } => {
  if (isNil(data) || isEmpty(data as any)) {
    return null;
  }

  const derivs = findMaxDerivativeIndices(
    data as any,
    e => e.x,
    e => e.y,
  );

  if (isNil(derivs) || isNil(derivs[0])) {
    return null;
  }

  return {
    fromIndex: derivs[0],
    toIndex: derivs[1],
    from: (data as any)[derivs[0]],
    to: (data as any)[derivs[1]],
  };
};

const createOverviewSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {translate: t, navigate: nav} = context;
  const sessionData = selectedSession(context);

  const detailDrilldownExcerciseLegend = () => {
    const exercs = sessionData?.ui?.exercises
      ?.filter(e => e.type !== Rooq.DisciplineV2.UNKNOWN_ACTIVITY)
      ?.reduce(
        (s, e) => s.add(e.type),
        new Set()
      );

    return Array.from(exercs.values())
      .map(type => ({
        label: type as any,
        color: disciplineColor(type as any)
      }))
  };

  const routeParam = (route: IRoute, p: string): any => prop(route?.match?.params, p);
  const roundIndexFromRoute = (route: IRoute): null | number => {
    const p = routeParam(route, 'round');
    const pn = tryParseNumber(p);
    return pn ? Math.max(0, pn - 1) : null;
  }

  const exerciseFromRoute = (route: IRoute): null | RouteExtractedUiExercise => {
    const exIdx = tryParseNumber(routeParam(route, 'exercise'));
    const ex = isNum(exIdx) ? sessionData.ui.exercises[exIdx - 1] : null;

    return ex
      ? {
        ...ex,
        index: (exIdx as number) - 1,
        session: sessionData,
        sessionIndex: 0
      }
      : null;
  };

  const roundFromRoute = (route: IRoute): null | RouteExtractedUiRound => {
    const ex = exerciseFromRoute(route);
    const roundIdx = roundIndexFromRoute(route);
    const round = ex?.data && isNum(roundIdx) ? prop(ex.data, roundIdx + 1) : null;

    return round && ex ? {
      ...round as any,
      index: roundIdx,
      session: ex.session,
      sessionIndex: ex.sessionIndex,
      exercise: dissoc(ex, 'session', 'sessionIndex'),
    } : null;
  }

  const strongest = exerciseByMaxScoreGain(sessionData.ui.exercises);

  return [
    segment('dual')
      .chart('line', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.score.title')
        })
        .withProps({
          data: sessionData.ui.sessionTimelines.rooqScore.values.map(v => ({
            section: v.x,
            value: v.y,
          })),
          legend: strongest
            ? [
              {
                title: t('app.analysis.details.chart.score.feature.label'),
                label: t(
                  `app.analysis.details.chart.score.feature.value`,
                  {
                    discipline: t(`content.discipline.${strongest.exercise.type}`),
                    duration: t('general.duration.medium', {
                      minutes: strongest.exercise.duration,
                      seconds: 0,
                    }),
                    rounds: t('app.analysis.details.rounds', {
                      count: strongest.exercise.roundCount
                    }),
                    score: t('app.analysis.details.score', {score: strongest.exercise.rooqScore}),
                    scorePerMin: t('app.analysis.details.score-per-min', {
                      score: t('app.analysis.details.score', {score: Math.fround(strongest.exercise.rooqScore / strongest.exercise.duration).toFixed(1)})
                    })
                  }
                ),
                color: context.style.rooq_red,
              }
            ]
            : undefined,
          unitY: t('app.analysis.details.chart.unit.score'),
          unitX: t('general.unit.minute.medium'),
          yMax: sessionData.ui.sessionTimelines.rooqScore.yAxisValue,
          xMax: sessionData.ui.sessionTimelines.rooqScore.xAxisValue
        }))
      .chart('pie', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.discipline-distribution.title')
        })
        .withProps({
          data: sessionData.ui.durationByExerciseType.values
            .filter(v => v.label !== Rooq.DisciplineV2.UNKNOWN_ACTIVITY)
            .map(v => ({
              label: t(`content.discipline.${v.label}`),
              color: disciplineColor(v.label),
              value: v.duration,
              unit: t('general.duration.long', {minutes: v.duration, seconds: 0})
            })),
          aggregateUnit: t('general.unit.minute.medium')
        }))
      .build(),
    segment('single')
      .chart('drilldown', drilldown => drilldown
        .withProps({
          stages: [
            {
              route: {
                pattern: '/portal/analysis/compare/:tab/:exercise?/:metric?/:round?',
              },
              segments: [
                segment('single')
                  .chart('heading', builder => builder.withProps({
                    text: t('app.analysis.details.title'),
                    headingLevel: 2,
                  }))
                  .build(),
                (route) => {
                  const params = route?.match?.params;
                  const exerciseSelected = hasProp(params, 'exercise') && isNotNil(params?.exercise);

                  return exerciseSelected
                    ? null
                    : segment('single')
                      .chart('heading', builder => builder.withProps({
                        text: t('app.analysis.details.message.select-exercise'),
                      }))
                      .build();
                },
                segment('single')
                  .chart('bar-segments', builder => builder
                    .withHeader({
                      title: t('app.analysis.details.chart.discipline-timing.title')
                    })
                    .withProps({
                      data: [
                        {
                          label: sessionData?.author?.firstName,
                          segments: sessionData?.ui?.exercises?.map((exer) => {

                            return {
                              label: exer.type,
                              value: exer.duration,
                              color: disciplineColor(exer.type),
                              routable: exer.type !== Rooq.DisciplineV2.ROPE_SKIPPING,
                              routed: exer.type === Rooq.DisciplineV2.ROPE_SKIPPING
                                ? false
                                : (route, idx) => `${idx + 1}` === routeParam(route, 'exercise'),
                            }
                          })
                        },
                      ] || [],
                      legend: detailDrilldownExcerciseLegend().map(item => {
                        item.label = t(`content.discipline.${item.label}`);
                        return item;
                      }),
                      segmentStyle: 'regular',
                      showDataAggregate: false,
                      showDataLabel: true,
                      onSegmentClick: (_, idx, route) => {
                        const exer = sessionData?.ui?.exercises[idx];
                        if (!exer) return;
                        const tab = routeParam(route, 'tab');
                        const exercise = `${idx + 1}`;
                        nav(`${tab}/${exercise}`);
                      }
                    }))
                  .build(),
              ]
            }
          ]
        }))
      .build(),
    ...createExerciseAnalysisSegmentsFromSessionsV2(
      context,
      exerciseFromRoute,
      roundFromRoute,
    )
  ];
};

const createPunchesSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {translate: t} = context;
  const sessionData = selectedSession(context);

  const sessionDuration = (sessionData?.summary?.duration || 0) / 1000;
  const sessionDurationSecs = Math.round(sessionDuration % 60);
  const sessionDurationMins = Math.round((sessionDuration - sessionDurationSecs) / 60);

  const strongest = dataByMaxGain(sessionData?.ui?.sessionTimelines?.punchCount?.values);

  let delta: number;

  if (strongest) {
    const dy = strongest.to.y - strongest.from.y;
    const dx = strongest.to.x - strongest.from.x;

    delta = dx > 0 ? dy / dx : 0;
  }

  return [
    segment('single')
      .chart('line', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count.title')
        })
        .withProps({
          data: sessionData?.ui?.sessionTimelines?.punchCount?.values?.map((val) => {
            return {
              section: val.x,
              value: val.y,
            }
          }),
          legend: strongest
            ? [
              {
                title: t('app.analysis.details.chart.punch-count.feature.label'),
                label: t('app.analysis.details.chart.punch-count.feature.value', {
                  countPerMin: delta.toFixed(1)
                }),
                color: context.style.rooq_red,
              }
            ]
            : undefined,
          unitY: t('app.analysis.details.punches', {count: 0}),
          unitX: t('general.unit.minute.medium'),
          yMax: sessionData?.summary?.sessionStats?.punchCount?.total || 0,
          xMax: sessionDurationMins,
        }))
      .build(),
    segment('single')
      .chart('lr-unit', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count-by-type.title')
        })
        .withProps({
          data: removeTotalValue(sessionData?.ui?.distributions?.punchCount?.values)
            ?.filter(val => val.label !== 'TOTAL')
            ?.sort((a, b) => sortByPunchType(a.label as any, b.label as any))
            ?.map(val => {
              const {label, leftValue, rightValue} = val;

              return {
                leftValue,
                rightValue,
                color: prop(punchTypeColors, label, '#1C446D'),
                unit: t('app.analysis.details.chart.unit.count'),
                centerContent: <AnalysisPunchType punchType={label} />
              }
            }) || [],
          leftTitle: t('app.analysis.details.chart.left'),
          rightTitle: t('app.analysis.details.chart.right'),
          showDataAggregate: true,
        }))
      .build(),
    segment('single')
      .chart('bar-segments', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count-by-discipline.title')
        })
        .withProps({
          data: [
            {
              label: sessionData?.author?.firstName,
              segments: sessionData?.ui?.countsGroupedBy?.exerciseType?.charts?.map((chart) => {
                return {
                  label: chart.label,
                  value: chart.count,
                  color: disciplineColor(chart.label),
                };
              }) || [],
            }
          ],
          legend: sessionData?.ui?.countsGroupedBy?.exerciseType?.charts?.map(({label}) => ({
            label: t(`content.discipline.${label}`),
            color: disciplineColor(label)
          })),
          unit: t('app.analysis.details.chart.unit.count'),
          segmentStyle: 'solid',
          showDataLabel: false,
          showDataAggregate: true,
        }))
      .build(),
    segment('single')
      .chart('bar-segments', builder => builder
        .withHeader({
          title: t('app.analysis.details.chart.punch-count-by-combo.title')
        })
        .withProps({
          data: [
            {
              label: sessionData?.author?.firstName,
              segments: sessionData?.ui?.countsGroupedBy?.comboLength?.charts?.map((chart, i) => {
                return {
                  label: chart.label,
                  value: chart.count,
                  color: chartColorWheel(i),
                };
              }) || [],
            }
          ],
          legend: sessionData?.ui?.countsGroupedBy?.comboLength?.charts?.map(({label}, i) => ({
            label: t(`content.combo.${label}`),
            color: chartColorWheel(i)
          })),
          unit: t('app.analysis.details.chart.unit.count'),
          segmentStyle: 'solid',
          showDataLabel: false,
          showDataAggregate: true,
        }))
      .build()
  ];
};

const createSpeedSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {translate: t, navigate: nav} = context;
  const sessionData = selectedSession(context);

  const convertSpeedData = metricVelocityDataConverter(context.unitSystem);
  const convertSpeed = metricVelocityConverter(context.unitSystem);

  return [
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: '/portal/analysis/compare/:tab/:metric?',
            },
            segments: [
              segment('single')
                .chart('multi-routed', builder => builder
                  .withProps({
                    header: {
                      title: t('app.analysis.details.chart.punch-type-distribution.title')
                    },
                    router: {
                      param: 'metric',
                      pattern: '/portal/analysis/compare/:tab/:metric?',
                      redirect: {
                        exact: true,
                        from: '/portal/analysis/compare/:tab',
                        to: '/portal/analysis/compare/speed/average'
                      }
                    },
                    items: [
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.speed.label')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/average`);
                          },
                          param: 'average',
                        },
                        chart: chart('lr-unit')
                          .withProps({
                            data: sessionData.ui.distributions.avgMaxSpeed.values
                              .filter(val => val.label !== 'TOTAL')
                              .sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              .map((val) => {
                                const {label, leftValue, rightValue} = val;
                                return {
                                  leftValue,
                                  rightValue,
                                  color: prop(punchTypeColors, label, '#1C446D'),
                                  unit: t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
                                  centerContent: <AnalysisPunchType punchType={label} />
                                };
                              })
                              .map(d => convertSpeedData(d, 'leftValue', 'rightValue')),
                            leftTitle: t('app.analysis.details.chart.left'),
                            rightTitle: t('app.analysis.details.chart.right'),
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = sessionData.ui.distributions.avgMaxSpeed.values
                                .find(val => val.label === 'TOTAL');

                              return row
                                ? {
                                  leftValue: convertSpeed(row.leftValue),
                                  rightValue: convertSpeed(row.rightValue),
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build()
                      },
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.max', {unit: t('app.analysis.details.chart.unit.speed.label')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/max`);
                          },
                          param: 'max'
                        },
                        chart: chart('lr-unit')
                          .withProps({
                            data: sessionData.ui.distributions.bestMaxSpeed.values
                              .filter(val => val.label !== 'TOTAL')
                              .sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              .map((val) => {
                                const {label, leftValue, rightValue} = val;

                                return {
                                  leftValue,
                                  rightValue,
                                  color: prop(punchTypeColors, label, '#1C446D'),
                                  unit: t(`app.analysis.details.chart.unit.speed.${context.unitSystem}`),
                                  centerContent: <AnalysisPunchType punchType={label} />
                                };
                              })
                              .map(d => convertSpeedData(d, 'leftValue', 'rightValue')),
                            leftTitle: t('app.analysis.details.chart.left'),
                            rightTitle: t('app.analysis.details.chart.right'),
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = sessionData.ui.distributions.bestMaxSpeed.values
                                .find(val => val.label === 'TOTAL');

                              return row
                                ? {
                                  leftValue: convertSpeed(row.leftValue),
                                  rightValue: convertSpeed(row.rightValue),
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build()
                      }
                    ]
                  }))
                .build(),
              segment('single')
                .chart('bar-segments', builder => builder
                  .withHeader({
                    title: t('app.analysis.details.chart.punch-count-by-speed-groups.title')
                  })
                  .withProps({
                    data: sessionData
                      ? [
                        {
                          label: sessionData.author.firstName,
                          segments: sessionData.ui.countsGroupedBy.maxSpeed.charts.map(({label, count}, i) => ({
                            discipline: label,
                            value: count,
                            color: chartColorWheel(i),
                            routed: false,
                          } as any))
                        }
                      ]
                      : [],
                    legend: sessionData?.ui?.countsGroupedBy?.maxSpeed?.charts
                      ?.map(({label}, i) => {
                        return {
                          label: t(`content.speedGroup.${label}.${context.unitSystem}`),
                          color: chartColorWheel(i)
                        }
                      }),
                    segmentStyle: 'solid',
                    showDataLabel: false,
                    showDataAggregate: true,
                  }))
                .build()
            ]
          }
        ]
      }))
      .build(),
  ];
};

const createPowerSegments = (context: ICoachingZoneAnalysisDetailsContext) => {
  const {translate: t, navigate: nav} = context;
  const sessionData = selectedSession(context);

  return [
    segment('single')
      .chart('drilldown', drilldown => drilldown.withProps({
        stages: [
          {
            route: {
              pattern: '/portal/analysis/compare/:tab/:metric?'
            },
            segments: [
              segment('single')
                .chart('multi-routed', builder => builder
                  .withProps({
                    header: {
                      title: t('app.analysis.details.chart.punch-type-distribution.title')
                    },
                    router: {
                      param: 'metric',
                      pattern: '/portal/analysis/compare/:tab/:metric?',
                      redirect: {
                        exact: true,
                        from: '/portal/analysis/compare/:tab',
                        to: '/portal/analysis/compare/power/average'
                      }
                    },
                    items: [
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.avg', {unit: t('app.analysis.details.chart.unit.power')}),
                          interact: (route) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/average`);
                          },
                          param: 'average',
                        },
                        chart: chart('lr-unit')
                          .withProps({
                            data: sessionData.ui.distributions.avgPowerAtMaxSpeed.values
                              .filter(val => val.label !== 'TOTAL')
                              .sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              .map((val) => {
                                const {label, leftValue, rightValue} = val;

                                return {
                                  leftValue,
                                  rightValue,
                                  color: prop(punchTypeColors, label, '#1C446D'),
                                  unit: t('app.analysis.table.unit.power'),
                                  centerContent: <AnalysisPunchType punchType={label} />
                                };
                              }),
                            leftTitle: t('app.analysis.details.chart.left'),
                            rightTitle: t('app.analysis.details.chart.right'),
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = sessionData.ui.distributions.avgPowerAtMaxSpeed.values
                                .find(val => val.label === 'TOTAL');

                              return row
                                ? {
                                  leftValue: row.leftValue,
                                  rightValue: row.rightValue,
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build()
                      },
                      {
                        menu: {
                          label: t('app.analysis.details.chart.unit.max', {unit: t('app.analysis.details.chart.unit.power')}),
                          interact: (route, index) => {
                            const tab = propIn(route, ['match', 'params', 'tab']);
                            nav(`${tab}/max`);
                          },
                          param: 'max'
                        },
                        chart: chart('lr-unit')
                          .withProps({
                            data: sessionData.ui.distributions.bestPowerAtMaxSpeed.values
                              .filter(val => val.label !== 'TOTAL')
                              .sort((a, b) => sortByPunchType(a.label as any, b.label as any))
                              .map((val) => {
                                const {label, leftValue, rightValue} = val;

                                return {
                                  leftValue,
                                  rightValue,
                                  color: prop(punchTypeColors, label, '#1C446D'),
                                  unit: t('app.analysis.table.unit.power'),
                                  centerContent: <AnalysisPunchType punchType={label} />
                                };
                              }),
                            leftTitle: t('app.analysis.details.chart.left'),
                            rightTitle: t('app.analysis.details.chart.right'),
                            showDataAggregate: true,
                            aggregateData: () => {
                              const row = sessionData.ui.distributions.bestPowerAtMaxSpeed.values
                                .find(val => val.label === 'TOTAL');

                              return row
                                ? {
                                  leftValue: row.leftValue,
                                  rightValue: row.rightValue,
                                  centerContent: <UiTextLine text={t('app.analysis.details.total')} />
                                }
                                : undefined
                            },
                          })
                          .build()
                      }
                    ]
                  }))
                .build()
            ]
          }
        ]
      }))
      .build(),
  ];
};


export const createSingleAnalysisSegmentsFromSessionsV2 = (
  context: ICoachingZoneAnalysisDetailsContext
): Record<'overview' | 'punches' | 'speed' | 'power', IAnalysisSegmentType[]> => ({
  overview: createOverviewSegments(context),
  punches: createPunchesSegments(context),
  speed: createSpeedSegments(context),
  power: createPowerSegments(context),
});
