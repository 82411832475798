import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {isNonEmptyStr, prop} from '@bitsolve/fns';

export interface IAppRouterOutletRoute {
  path?: string;
  redirect?: string;
  render?: Function;
  component?: any;
  exact?: boolean;
}

export interface IAppRouterOutlet {
  routes: IAppRouterOutletRoute[];
  redirect?: string | null;
}

const _routeKey = (route: IAppRouterOutletRoute, index: number): string => `${index}.${prop(route, 'path', '/')}`;

export const AppRouterOutlet: React.FC<IAppRouterOutlet> = (props) => {
  const {routes, redirect} = props;

  return <Switch>
    {routes.map((route, index) => route?.redirect
      ? <Redirect key={_routeKey(route, index)}
                  path={route.path}
                  exact={!!route.exact}
                  to={route.redirect} />
      : <Route key={_routeKey(route, index)} {...route as any} />)}
    {isNonEmptyStr(redirect) && <Redirect to={redirect} />}
  </Switch>;
};
