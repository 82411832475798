import {useCallback, useEffect, useMemo, useState} from 'react';
import {isNotNil} from '@bitsolve/fns';
import {blobAsDataURL, useDebounce, useHttpClient} from '@bitsolve/react-common';
import {TLRUCache} from '@thi.ng/cache';
import {useRevisionState} from './ui.hooks';

export interface IUiSelectionState<T = any> {
  selected: Set<T>;

  select(val: T): void;

  deselect(val: T): void;

  toggle(val: T): void;

  reset(): void;

  revision: number;
}

const copySet = <T = any>(x: Set<T>): Set<T> => {
  return new Set<T>(x.values());
};

export const useSelectionState = <T = any>(initialSelection?: T[], opts?: { max?: number; }): IUiSelectionState<T> => {
  const maxSelections = opts?.max || Number.MAX_SAFE_INTEGER;
  const rev = useRevisionState();
  const [selected, setSelected] = useState<Set<T>>(new Set<T>([...(initialSelection || [])]) as any);

  const reset = useCallback(() => {
    setSelected(new Set<T>(initialSelection || []));
    rev.bumpRevision();
  }, [setSelected, initialSelection, rev]);

  const select = useCallback(
    (val: T) => {
      if (selected.size >= maxSelections) {
        return;
      }

      setSelected(copySet(selected).add(val));
      rev.bumpRevision();
    },
    [selected, setSelected, maxSelections, rev]
  );

  const deselect = useCallback((val: T) => {
    const _s = copySet(selected);
    _s.delete(val);
    setSelected(_s);
    rev.bumpRevision();
  }, [selected, setSelected, rev]);

  const toggle = useCallback(
    (val: T) => {
      setSelected((s) => {
        const _s = copySet(s);

        if (_s.has(val)) {
          _s.delete(val)
        } else if (_s.size < maxSelections) {
          _s.add(val);
        }

        return _s;
      });

      rev.bumpRevision();
    },
    [setSelected, maxSelections, rev]
  );

  return useMemo<IUiSelectionState<T>>(
    (): IUiSelectionState<T> => ({selected, reset, select, deselect, toggle, revision: rev.revision}),
    [selected, reset, select, deselect, toggle, rev]
  );
};


export const requestAnimationFramesSync = (callback: Function, n: number = 1): void => {

  if (n === 1) {
    requestAnimationFrame(callback as any);
  } else {
    requestAnimationFrame(() => requestAnimationFramesSync(callback, n - 1));
  }
};


export const requestAnimationFrames = (n: number = 1): Promise<any> => {
  return new Promise<any>((y) => requestAnimationFramesSync(y, n));
};

// cache for the last 30 image resources for up to 1 minute:
const imagePreloadCache = new TLRUCache(null, {ttl: 60000});

export const useImagePreload = (src?: string | null | false, opts?: { width?: number; height?: number; preLoadDelay?: number; postLoadDelay?: number; cache?: boolean; enable?: boolean; }): string | null => {
  const {preLoadDelay, postLoadDelay, cache = false, enable = true} = {
    preLoadDelay: 100,
    postLoadDelay: 100,
    enable: true, ...opts
  };
  const http = useHttpClient();
  const img = useMemo(() => new Image(), []);
  const [loadedSrc, setLoadedSrc] = useState<string | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);

  useEffect(
    () => {
      if (!enable || !src || loadedSrc === src) {
        return;
      }

      if (cache && imagePreloadCache.has(src)) {
        setLoadedSrc(src);
        setDataUrl(imagePreloadCache.get(src));
        return;
      }

      let gate = true;

      const load = () => {
        // findImage.send
        http
          .send({
            method: 'get',
            url: src,
            responseType: 'blob'
          })
          .then(r => blobAsDataURL(r.data))
          .then((dataUrl) => {
            if (!gate) {
              return;
            }

            imagePreloadCache.set(src, dataUrl);
            setLoadedSrc(src);
            setDataUrl(dataUrl);
          })
          .catch(() => {
            if (!gate) {
              return;
            }

            setLoadedSrc(null);
            setDataUrl(null);
            imagePreloadCache.delete(src);
          });
      };

      setTimeout(load, preLoadDelay);

      return () => {
        if (gate) {
          gate = false;
        }
      };
    },
    [enable, cache, http, img, setLoadedSrc, src, loadedSrc, setDataUrl, preLoadDelay, postLoadDelay]
  );

  useEffect(() => {
    if (enable && isNotNil(loadedSrc) && loadedSrc !== src) {
      setLoadedSrc(null);
    }
  }, [enable, src, loadedSrc, setLoadedSrc]);

  return useDebounce(enable ? dataUrl : src, enable ? 130 : 10);
};
