import React, {useEffect, useMemo, useReducer} from 'react';
import {
  classNames,
  IUiFieldProps,
  TextualFieldControl,
  UiButton,
  UiButtonVariation,
  UiColor,
  UiDir,
  UiFlexAlign,
  UiFlexRow,
  UiState,
  useTranslator
} from '@bitsolve/react-common';
import {isEmpty, isEmptyStr, isNil, randomUUID} from '@bitsolve/fns';

export interface ITrainerCustomFightStylesControl extends IUiFieldProps<string[]> {
}

interface CustomFightStyleItem {
  id: string;
  value: string;
}

const customFightStyleReducer = (state: CustomFightStyleItem[] = [], action: { type: string, payload: any }) => {
  switch (action?.type) {
    case 'add-item':
      return [...state, ...valueToItems([''])]
    case 'remove-item':
      return state.filter(item => item.id !== action.payload.id);
    case 'update-item': {
      const {id, value} = action.payload;
      return state.map(item => item.id === id ? {...item, value} : item);
    }
    default:
      return state;
  }
};

const valueToItems = (value?: string[]): CustomFightStyleItem[] => value?.map(value => ({
  value,
  id: randomUUID()
})) || [];

export const TrainerCustomFightStylesControl: React.FC<ITrainerCustomFightStylesControl> = (props) => {
  const {value, onChange, className, style} = props;

  const t = useTranslator();
  const [state, dispatch] = useReducer(customFightStyleReducer, value, valueToItems);

  const canAddNew = useMemo(
    () => isNil(state)
      || isEmpty(state)
      || (state?.filter(item => isEmptyStr(item.value)).length === 0),
    [state]
  );

  useEffect(
    () => {
      const next = state.map(i => i.value);
      if (JSON.stringify(next) === JSON.stringify(value)) {
        return;
      }
      onChange && onChange(next as any);
    },
    [value, onChange, state]
  );

  return <div className={classNames(className, 'form-field--type-custom-fight-styles__items')}
              style={style}>
    {state?.map(item => <UiFlexRow key={item.id}
                                   className={'form-field--type-custom-fight-styles__items__item'}
                                   ai={UiFlexAlign.c}>
      <div className="f-1">
        <TextualFieldControl name={'customFightingStyle'}
                             controlProps={{
                               className: 'f-1',
                               placeholder: t('app.account.trainerProfile.form.customFightStyles.placeholder')
                             }}
                             fieldType={'text'}
                             value={item.value}
                             onChange={value => dispatch({type: 'update-item', payload: {...item, value}})}
                             direction={UiDir.h}
                             state={UiState.active} />
      </div>
      <UiButton icon={'times'}
                variation={UiButtonVariation.semantic}
                interact={() => dispatch({type: 'remove-item', payload: item})} />
    </UiFlexRow>)}
    <UiButton stretch
              className={'mg-t-sm'}
              color={UiColor.primary}
              state={canAddNew ? UiState.active : UiState.disabled}
              label={t('app.account.trainerProfile.form.customFightStyles.action.add.label')}
              interact={() => dispatch({type: 'add-item', payload: {}})} />
  </div>;
};
